import { IValueLabel } from 'oat-common-ui';
import { FEATURE_OR_2477, FEATURE_OR_4154 } from '../../../../constants/global';
import { Offer, SaveAprOfferInput } from '../../../../gql/generated';

export const buildSaveAprInput = (aprPayload: SaveAprOfferInput, enhCostSharePayload: SaveAprOfferInput, enhTfsCostSharePayload: SaveAprOfferInput) => {
  let input = aprPayload;

  if (FEATURE_OR_2477) {
    input = enhCostSharePayload;
  }

  if (FEATURE_OR_4154) {
    input = enhTfsCostSharePayload;
  }

  return input;
};

export const updateAprEnhFields = (aprOffer: Offer, setNewAprEnhFields: (aprOffer: Offer) => void, setNewAprEnhTfsFields: (aprOffer: Offer) => void) => {
  if (FEATURE_OR_2477) {
    setNewAprEnhFields(aprOffer);
  }

  if (FEATURE_OR_4154) {
    setNewAprEnhTfsFields(aprOffer);
  }
};

export const createUniqueItems = <T, K extends keyof T>(
  items: T[] | undefined,
  key?: K,
  createLabel: (keyValue: T | T[K]) => string = keyValue => `${keyValue}`,
): IValueLabel[] => {
  const uniqueKeys = new Set(items?.map(item => key ? item[key] : item));
  return Array.from(uniqueKeys).map(uniqueKey => ({
    label: createLabel(uniqueKey),
    value: `${uniqueKey}`,
  }));
}
