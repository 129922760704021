import { Status } from '../../constants/global';
import { Offering } from '../../gql/generated';

export const getStatusCounts = (regions: Offering[]) => {
  const counts: Record<string, number> = {
    [Status.REVISED]: 0,
    [Status.SUBMITTED]: 0,
    [Status.NEEDS_REVISIONS]: 0,
    [Status.NO_RESPONSE]: 0,
    [Status.MEETS_GUIDELINES]: 0,
    [Status.PUBLISH_IN_PROGRESS]: 0,
  };

  regions.forEach(region => {
    counts[region.status as Status] += 1;
  });

  return counts;
};
