import { observer } from 'mobx-react-lite';
import { BlockInputLabel, Button, Dropdown, DropdownItem, OATIcon, SeriesCodeToNameMap, formatDollars } from 'oat-common-ui';
import { useState } from 'react';
import NumberInput from '../../../../../components/NumberInput';
import { FEATURE_OR_2477, FEATURE_OR_4154, FEATURE_OR_5646, Status } from '../../../../../constants/global';
import useUrlParams from '../../../../../hooks/useUrlParams';
import useStores from '../../../../../stores/useStores';
import getAnnualMileagesList from '../../../../../utils/getAnnualMileagesList';
import useGetTfsLabel from '../../../../../utils/useGetTfsLabel';
import LeaseCardModel from '../../../models/LeaseCardModel';
import LeaseFormModel from '../../../models/LeaseFormModel';
import LeaseTermModel from '../../../models/LeaseTermModel';
import EnhCostShareModal from '../../EnhCostShareModal';
import useSaveLeaseData from '../LeaseAdForm/useSaveLeaseData';
import LeaseCardBase from '../LeaseCardBase';
import LeaseDates from '../components/LeaseDates';
import { LeaseFormLayoutColumn } from '../components/LeaseFormLayout';
import LeaseFundingSplitModal from '../components/LeaseFundingSplitModal';
import LeaseInput, { LeaseInputDescription, LeaseInputLabel, LeaseInputWrapper } from '../components/LeaseInput';
import LeaseSpecialEdition from '../components/LeaseSpecialEdition';
import RcfPnv from '../components/RcfPnv';
import TermSelector from '../components/TermSelector';
import VehicleInfo from '../components/VehicleInfo';
import { getDefaultLeaseTfsShare } from '../utils/getDefaultLeaseTfsShare';
import styles from './styles.module.scss';

type Props = {
  leaseCard: LeaseCardModel;
  leaseTerm: LeaseTermModel;
  leaseForm: LeaseFormModel;
};

const LeaseGstForm = ({ leaseCard, leaseTerm, leaseForm }: Props) => {
  const { userInfoStore, programDetailsStore } = useStores();
  const { region } = useUrlParams();
  const [fundingSplitOpen, setFundingSplitOpen] = useState(false);

  const { handleAddLeaseTerm, handleHideLeaseTerm, handleSaveCompatibility, handleInputChange, handleUptoChangeOrSave, handleEnhCostData, handleEnhTfsCostData } = useSaveLeaseData(
    leaseCard,
    leaseTerm,
    leaseForm,
  );
  const { id, term } = leaseCard;
  const { inputs, updateTfsShare, seriesName } = leaseForm;
  const tfsLabel = useGetTfsLabel();
  const mileages = getAnnualMileagesList(userInfoStore.userInfo.brand, region, term > 30, seriesName.toLowerCase().includes(SeriesCodeToNameMap.supra.toLowerCase()));

  const defaultTfsShare = getDefaultLeaseTfsShare(
    !!programDetailsStore.offering.useOldCostShareForLexus,
    region,
    term,
    leaseCard.isEnhCostShareAccepted,
    leaseCard.enhancedCostShare,
  );

  const modelOptions = leaseCard.includedVehicles.map(item => {
    return { label: `(${item.modelCode}) ${item.vehicleDescription}`, value: item.modelCode };
  });

  const [standardRegionalRcf, setStandardRegionalRcf] = useState<string | number>(inputs.standardRegionalRcf);

  const handleModelOnChange = (item: DropdownItem) => {
    leaseForm.updateInput('vehicleDescription', item.label as string);
    leaseForm.updateInput('modelCode', Number(item.value));
    handleInputChange(true);
  };

  const isDisabled = programDetailsStore.offering.status === Status.MEETS_GUIDELINES || leaseCard.isDisabled;

  return (
    <LeaseCardBase
      leaseCard={leaseCard}
      leaseTerm={leaseTerm}
      leaseForm={leaseForm}
      onInputChange={handleInputChange}
      onIsUpToApplied={handleUptoChangeOrSave}
      areTfsEnhRatesUsed={leaseForm.areTfsEnhRatesUsed}
      leftContent={
        <>
          <VehicleInfo leaseCard={leaseCard} leaseForm={leaseForm} onSaveCompatibility={handleSaveCompatibility} isDisabled={isDisabled} />
          <LeaseDates leaseCard={leaseCard} leaseForm={leaseForm} onChange={() => handleInputChange(true)} isDisabled={isDisabled} />
          <LeaseSpecialEdition leaseCard={leaseCard} leaseForm={leaseForm} onChange={handleInputChange} />
          <BlockInputLabel label="Model" className={styles.modelSelection}>
            <Dropdown
              id={`gst-model-${id}`}
              value={inputs.vehicleDescription}
              options={modelOptions}
              onChange={item => handleModelOnChange(item)}
              disabled={isDisabled}
              error={Boolean(leaseForm.vehicleDescriptionError)}
            />
          </BlockInputLabel>
          <TermSelector leaseCard={leaseCard} leaseForm={leaseForm} allowHide onAddTerm={handleAddLeaseTerm} onHideTerm={handleHideLeaseTerm} />
        </>
      }
      rightHeaderContent={
        <RcfPnv
          leaseCard={leaseCard}
          leaseForm={leaseForm}
          onInputChange={handleInputChange}
          onUpToSave={handleUptoChangeOrSave}
          onRcfChange={() => updateTfsShare(defaultTfsShare)}
          standardRegionalRcf={standardRegionalRcf}
          setStandardRegionalRcf={setStandardRegionalRcf}
        />
      }
      rightBodyContent={
        <>
          <LeaseFormLayoutColumn>
            {/* Target Payment */}
            <LeaseInput
              id={`target-payment-${id}`}
              label="Target Payment"
              value={inputs.targetPayment}
              dollarSign
              wholeNumber
              error={Boolean(leaseForm.tpError)}
              onValueChange={vals => {
                leaseForm.updateInput('targetPayment', vals.value);
                handleUptoChangeOrSave();
              }}
              disabled={isDisabled}
            />

            {/* Due at Signing*/}
            <LeaseInput
              id={`das-${id}`}
              label="Due at Signing"
              value={inputs.dueAtSigning}
              dollarSign
              wholeNumber
              error={Boolean(leaseForm.dasError)}
              onValueChange={vals => {
                leaseForm.updateInput('dueAtSigning', vals.value);
                handleUptoChangeOrSave();
              }}
              disabled={isDisabled}
              text={!leaseForm.inputs.acquisitionFee && 'Does not include Acquisition Fee'}
            />

            {/* Residual Value Amount*/}
            <LeaseInput
              id={`rvAmt-${id}`}
              label="Residual Value Amount"
              value={inputs.residualAmount}
              dollarSign
              wholeNumber
              error={Boolean(leaseForm.gstRvAmountError)}
              onValueChange={vals => {
                leaseForm.updateInput('residualAmount', Number(vals.value));
                handleInputChange();
              }}
              disabled={isDisabled}
            />
          </LeaseFormLayoutColumn>

          <LeaseFormLayoutColumn rightSection>
            {/* Net Cap Cost*/}
            <LeaseInput
              id={`netCap-${id}`}
              label="Net Capitalized Cost"
              value={inputs.netCapCost}
              dollarSign
              wholeNumber
              error={Boolean(leaseForm.gstNetCapError)}
              onValueChange={vals => {
                leaseForm.updateInput('netCapCost', Number(vals.value));
                handleInputChange();
              }}
              disabled={isDisabled}
            />

            {/* Total MSRP */}
            <LeaseInput
              id={`totalMsrp-${id}`}
              label="Total MSRP"
              value={inputs.totalMsrp}
              dollarSign
              wholeNumber
              error={Boolean(leaseForm.gstTotalMsrpError)}
              onValueChange={vals => {
                leaseForm.updateInput('totalMsrp', Number(vals.value));
                handleInputChange();
              }}
              disabled={isDisabled}
            />

            {/* TFS Share */}
            <LeaseInput
              id={`tfs-share-${id}`}
              label={
                <>
                  {leaseForm.isEnhanced && (
                    <Button id={`funding-split-${id}-btn`} variant="text" onClick={() => setFundingSplitOpen(true)} className={styles.iconBtn} disabled={isDisabled}>
                      <OATIcon icon="split" colorTheme="blue" pointer />
                    </Button>
                  )}
                  {leaseForm.isEnhanced && leaseForm.fundingSourceSplits.length > 0 ? 'Blended Share' : `${tfsLabel} Share`}
                </>
              }
              text={`Default: ${defaultTfsShare}%`}
              percentageSign
              value={inputs.tfsShare}
              wholeNumber
              error={Boolean(leaseForm.tfsShareError)}
              disabled={!leaseForm.isEnhanced || leaseForm.fundingSourceSplits.length > 0 || isDisabled || leaseForm.isExample}
              onValueChange={vals => {
                leaseForm.updateInput('tfsShare', vals.value);
                leaseForm.calculateNonAdGstEstCosts(region, !!programDetailsStore.offering.useOldCostShareForLexus, true);
                leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
                handleUptoChangeOrSave();
              }}
            />
            {fundingSplitOpen && (
              <LeaseFundingSplitModal
                leaseCard={leaseCard}
                leaseForm={leaseForm}
                onClose={() => setFundingSplitOpen(false)}
                onSubmit={val => {
                  leaseForm.updateInput('tfsShare', val);
                  leaseForm.calculateNonAdGstEstCosts(region, !!programDetailsStore.offering.useOldCostShareForLexus, true);
                  leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
                  handleUptoChangeOrSave();
                }}
              />
            )}

            {FEATURE_OR_5646 && Number(inputs.tfsShare) === 100 && (
              <LeaseInput
                label={<>{tfsLabel} PNV</>}
                dollarSign
                wholeNumber
                value={inputs.tfsEstCost}
                onValueChange={vals => {
                  leaseForm.updateInput('tfsEstCost', Number(vals.value));
                  leaseForm.calculateNonAdGstEstCosts(region, !!programDetailsStore.offering.useOldCostShareForLexus);
                  leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
                  handleInputChange();
                }}
                error={Boolean(leaseForm.tfsEstCostError)}
              />
            )}
          </LeaseFormLayoutColumn>

          <LeaseFormLayoutColumn>
            {/* Term */}
            <LeaseInputWrapper>
              <LeaseInputLabel>Term</LeaseInputLabel>
              <NumberInput id={`term-${id}`} value={leaseCard.term} disabled />
              <LeaseInputDescription>{leaseForm.inputs.isStandalone ? `Default: 36` : `National: ${inputs.nationalTerm}`}</LeaseInputDescription>
            </LeaseInputWrapper>

            {/* Subvention Cash */}
            <LeaseInput
              label={
                <>
                  {tfsLabel} Subvention Cash {inputs.isSubCashAdvertised && <OATIcon icon="active_offer" colorTheme="orange" />}
                </>
              }
              field="subventionCash"
              value={inputs.subventionCash}
              error={Boolean(leaseForm.subCashError)}
              wholeNumber
              dollarSign
              text={leaseForm.inputs.isStandalone ? undefined : `National: $${formatDollars(leaseForm.inputs.nationalSubventionCash)}`}
              onValueChange={val => {
                leaseForm.updateSubCash(val.value);
                leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
                handleUptoChangeOrSave();
              }}
              disabled={isDisabled || leaseForm.isExample || leaseForm.areTfsEnhRatesUsed}
            />

            {/* Down Payment */}
            <LeaseInput
              id={`downPayment-${id}`}
              label="Down Payment"
              value={inputs.downPayment}
              dollarSign
              wholeNumber
              error={Boolean(leaseForm.dpError)}
              onValueChange={vals => {
                leaseForm.updateInput('downPayment', Number(vals.value));
                handleInputChange();
              }}
              disabled={isDisabled}
            />
          </LeaseFormLayoutColumn>

          <LeaseFormLayoutColumn>
            {/* Annual Mileage */}
            <LeaseInputWrapper>
              <LeaseInputLabel>Annual Mileage</LeaseInputLabel>
              <Dropdown
                id="mileage"
                value={inputs.mileage}
                options={mileages.mileages}
                disabled={isDisabled}
                onChange={val => {
                  leaseForm.updateInput('mileage', val.value);
                  handleInputChange();
                }}
              />
              <LeaseInputDescription>
                {leaseForm.inputs.isStandalone ? `Default: ${formatDollars(leaseForm.defaultMileage)}` : `National: ${formatDollars(leaseForm.inputs.nationalMileage)}`}
              </LeaseInputDescription>
            </LeaseInputWrapper>

            {/* Bonus Cash */}
            <LeaseInput
              id={`bonusCash-${id}`}
              label="Bonus Cash"
              value={inputs.bonusCash}
              dollarSign
              wholeNumber
              onValueChange={vals => {
                leaseForm.updateInput('bonusCash', Number(vals.value));
                handleInputChange();
              }}
              disabled={isDisabled || leaseForm.isExample}
            />

            {/* Gross Cap Cost */}
            <LeaseInput
              id={`grossCapCost-${id}`}
              label="Gross Cap Cost"
              value={inputs.grossCapCost}
              dollarSign
              wholeNumber
              onValueChange={vals => {
                leaseForm.updateInput('grossCapCost', Number(vals.value));
                handleInputChange();
              }}
              disabled={isDisabled}
            />
          </LeaseFormLayoutColumn>
          {FEATURE_OR_2477 && leaseCard.enhancedCostFields.showModal && (
            <EnhCostShareModal
              onButtonPress={isConfirmed => {
                leaseCard.updateEnhancedCostShareField('isModalConfirmed', isConfirmed);

                if (isConfirmed) {
                  handleEnhCostData();
                  leaseForm.calculateNonAdGstEstCosts(region, !!programDetailsStore.offering.useOldCostShareForLexus);
                  leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
                }

                handleUptoChangeOrSave(true);
              }}
            />
          )}
          {FEATURE_OR_2477 && leaseCard.enhancedCostFields.removingReason && (
            <EnhCostShareModal
              onButtonPress={isConfirmed => {
                leaseCard.updateEnhancedCostShareField('isModalConfirmed', isConfirmed);

                handleEnhCostData();
                leaseForm.calculateNonAdGstEstCosts(region, !!programDetailsStore.offering.useOldCostShareForLexus);
                leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);

                handleUptoChangeOrSave(true);
              }}
              submitBtnText="OK"
              text={`${leaseCard.enhancedCostFields.removingReason} National Enhanced Cost Share will be removed. Do you want to continue?`}
              title="Remove Enhanced Cost Share"
            />
          )}
          {FEATURE_OR_4154 && leaseCard.enhancedTfsCostFields.showModal && (
            <EnhCostShareModal
              title={`Enhanced ${tfsLabel} Cost Share Available`}
              text={`Offer is eligible for National Enhanced ${tfsLabel} Cost Share. Do you want to apply?`}
              onButtonPress={isConfirmed => {
                leaseCard.updateEnhancedTfsCostShareField('isModalConfirmed', isConfirmed);
                handleEnhTfsCostData();

                if (isConfirmed) {
                  leaseForm.calculateNonAdGstEstCosts(region, !!programDetailsStore.offering.useOldCostShareForLexus);
                  leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
                }

                handleUptoChangeOrSave(false);
              }}
            />
          )}
          {FEATURE_OR_4154 && leaseCard.enhancedTfsCostFields.removingReason && (
            <EnhCostShareModal
              onButtonPress={isConfirmed => {
                leaseCard.updateEnhancedTfsCostShareField('isModalConfirmed', isConfirmed);

                handleEnhTfsCostData();
                leaseForm.calculateNonAdGstEstCosts(region, !!programDetailsStore.offering.useOldCostShareForLexus);
                leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);

                handleUptoChangeOrSave(true);
              }}
              text={`Dates selected are ineligible for National Enhanced ${tfsLabel} Cost Share. National Enhanced ${tfsLabel} Cost Share will be removed. Do you want to continue?`}
              submitBtnText="OK"
              title={`Enhanced ${tfsLabel} Cost Share Removed`}
            />
          )}
        </>
      }
    />
  );
};

export default observer(LeaseGstForm);
