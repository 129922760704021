import { observer } from 'mobx-react-lite';
import { dateFormatISOString, OATWrapper, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import IpBreadCrumbs from '../../../components/IpComponents/IpBreadCrumbs';
import MainFooter from '../../../components/MainFooter';
import MainHeader from '../../../components/MainHeader';
import { useCreateCashOfferMutation } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import CashCard from '../../ProgramDetails/components/CashCard';
import CreateOfferContent from '../CreateOfferContent';
import CreateOfferCta from '../CreateOfferCta';

const CreateCashComponent = () => {
  const {
    userInfoStore: { isNational },
    createOfferStore: { offering, seriesProfile, rgnlAlt, cashCard },
  } = useStores();

  const { region, period, profile, regalt } = useUrlParams();

  const { error } = useToast();
  const history = useHistory();

  const [createCashOffer] = useCreateCashOfferMutation();

  const transformCashCardData = () => {
    const {
      fields: {
        note,
        name,
        tdaNote,
        excludedStates,
        includedStates,
        combinedPerUnitCost,
        startDate,
        endDate,
        compatibileOffers,
        includedVehicles,
        excludedVehicles,
        isSpecialEdition,
        contestNumber,
        estimatedCost,
      },
    } = cashCard;

    return {
      name,
      note,
      tdaNote,
      excludedStates,
      includedStates,
      combinedPerUnitCost: Number(combinedPerUnitCost),
      compatibilityList: [...compatibileOffers],
      endDate: dateFormatISOString(endDate),
      isStandalone: true,
      rgnlAltId: rgnlAlt.id,
      startDate: dateFormatISOString(startDate),
      vehicles: [...includedVehicles, ...excludedVehicles],
      isSpecialEdition,
      contestNumber,
      estimatedCost,
    };
  };

  const handleCreateOffer = async () => {
    try {
      const res = await trackPromise(createCashOffer({ variables: { input: { ...transformCashCardData() } } }));

      if (res.data?.createCashOffer.success) {
        const anchor = 'cash-' + res.data?.createCashOffer.offer?.id;
        history.push(`/details/region/${region}/period/${period}/profile/${profile}/regalt/${regalt}?scrollTo=${anchor}`);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <>
      <MainHeader
        breadCrumbs={IpBreadCrumbs(isNational(), offering, offering.regionCode, seriesProfile, rgnlAlt, 'Create Cash Offer  ', '', false, profile)}
        headerTitle="Create Cash Offer"
      />
      <CreateOfferContent>
        <OATWrapper>
          <CashCard cashCard={cashCard} forCreate />
          <CreateOfferCta onClick={handleCreateOffer} disabled={cashCard.hasError || cashCard.hasDateError} />
        </OATWrapper>
      </CreateOfferContent>
      <MainFooter />
    </>
  );
};

export default observer(CreateCashComponent);
