import { SeriesProfile } from '../gql/generated';

const defaultSeriesProfile: SeriesProfile = {
  created: '',
  createdBy: '',
  groups: [],
  id: '',
  isComplete: false,
  isCustomSeries: false,
  isEarningsOnly: false,
  name: '',
  note: '',
  offeringId: '',
  rev: '',
  series: [],
  updated: '',
  updatedBy: '',
  vehicles: [],
  regionCode: '',
};

export default defaultSeriesProfile;
