import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ToggleButton from '../../../../components/ToggleButton';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  regaltId?: string;
  profileId?: string;
}

const getTierListByUserType = (isLexus: boolean, isSet: boolean): string[] => {
  if (isLexus) {
    return ['1+', '1', '2', '3', '4', '5', '6', '7'];
  } else if (isSet) {
    return ['1+', '1', '2', '3', '4'];
  } else {
    return ['1+', '1', '2', '3'];
  }
};

const CreateAprModal = ({ onClose, regaltId, profileId }: Props) => {
  const [selectedTiers, setSelectedTiers] = useState<Record<string, boolean>>({ '1+': false, '1': false, '2': false, '3': false });
  const history = useHistory();
  const { period, profile, region, regalt } = useUrlParams();
  const {
    userInfoStore: { isLexus, isSETUser },
  } = useStores();

  const handleSelectTier = (tier: string) => {
    return () => {
      setSelectedTiers({ ...selectedTiers, [tier]: !selectedTiers[tier] });
    };
  };

  const tiersList = useMemo(() => getTierListByUserType(isLexus(), isSETUser()), [isLexus, isSETUser]);

  const handleSubmit = () => {
    return () => {
      const tiers = encodeURIComponent(tiersList.filter(tier => selectedTiers[tier]).join(','));
      history.push(`/create/apr/region/${region}/period/${period}/profile/${profileId || profile}/regalt/${regaltId || regalt}?tiers=${tiers}`);
    };
  };
  const disableSubmit = Object.values(selectedTiers).every(item => !item);

  return (
    <Modal isOpen onClose={() => onClose()}>
      <ModalHeader onClose={() => onClose()}>Select APR Tier</ModalHeader>
      <ModalBody>
        <div className={styles.body}>
          {tiersList.map(tier => {
            return (
              <div key={tier}>
                <ToggleButton className={styles.toggleBtn} toggled={selectedTiers[tier]} dark onClick={handleSelectTier(tier)} id={`${profileId}-toggle-btn`}>
                  Tier {tier}
                </ToggleButton>
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="primary" onClick={handleSubmit()} disabled={disableSubmit} id={`${profileId}-submit-btn`}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateAprModal;
