import { observer, useLocalObservable } from 'mobx-react-lite';
import { BlockInputLabel, BlockUi, Button, ModalBody, ModalFooter, useToast } from 'oat-common-ui';
import { useState } from 'react';
import AnchorScrollTo from '../../../../components/AnchorScrollTo';
import NumberInput from '../../../../components/NumberInput';
import PenRateModalTable from '../../../../components/PenRateTable/modal/PenRateModalTable';
import usePenRateSaveUtils from '../../../../components/PenRateTable/usePenRatesSaveUtils';
import { SCROLL_OFFSET_PROGRAM_DETAILS } from '../../../../constants/global';
import { RgnlAlt } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import PenRatesStore from './PenRatesStore';
import styles from './styles.module.scss';

interface PenRateContentProps {
  rgnlAlt: RgnlAlt;
  currentOfferId: string;
  scrollTo?: string;
  onClose: () => void;
}

const PenRateModalContent = ({ rgnlAlt, currentOfferId, scrollTo, onClose }: PenRateContentProps) => {
  const penRatesStore = useLocalObservable(() => new PenRatesStore(rgnlAlt, currentOfferId));
  const {
    programDetailsStore: { ryoEarnings, updateOffersForecastedSales, updateRevsFromPenRates },
  } = useStores();
  const { savePenRate } = usePenRateSaveUtils(penRatesStore.rgnlAlt, updateRevsFromPenRates);
  const [saving, setSaving] = useState(false);
  const { error } = useToast();

  const handleSavePenRate = async () => {
    const { apr, cash, misc, lease } = penRatesStore.rgnlAlt;

    try {
      setSaving(true);
      await savePenRate();
      updateOffersForecastedSales(ryoEarnings, { apr, cash, misc, lease });
      setSaving(false);
      if (scrollTo) {
        AnchorScrollTo(scrollTo, SCROLL_OFFSET_PROGRAM_DETAILS);
      }
      onClose();
    } catch (e) {
      setSaving(false);
      error((e as Error).message);
    }
  };

  return (
    <>
      <BlockUi blocking={saving} title="Saving" />
      <ModalBody>
        <div className={styles.body}>
          <BlockInputLabel label="Forecasted Sales" className={styles.fsv} labelClass={styles.label}>
            <NumberInput id="penrate-fsv" value={penRatesStore.rgnlAlt.fsv} disabled />
          </BlockInputLabel>
          <PenRateModalTable rgnlAlt={penRatesStore.rgnlAlt} currentOfferId={penRatesStore.currentOfferId} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button id="penrate-submit" variant="primary" onClick={handleSavePenRate} disabled={penRatesStore.rgnlAlt.errors.length > 0}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
};

export default observer(PenRateModalContent);
