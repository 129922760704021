import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Button, Input, OATIcon, useToast } from 'oat-common-ui';
import { ChangeEvent } from 'react';
import { trackPromise } from 'react-promise-tracker';
import DollarDisplay from '../../../../../components/DollarDisplay';
import { Offering, ReconcileResponse, SaveNvsUnitsInput, SeriesNvsInput, useCompleteNvsMutation, useSaveNvsUnitsMutation } from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';
import { assignNumberValue, defaultToUndefined } from '../../../../../utils/assignValue';
import { RecconcileFields } from '../../../models/ReconcileDataModel';
import ReconcileSeriesModel from '../../../models/ReconcileSeriesModel';
import styles from './styles.module.scss';

const Summary = () => {
  const {
    userInfoStore: { isNational, isSETUser },
    reconcileStore: { offering, reconcileData, setOffering, initData },
  } = useStores();
  const { series, fields, totalPenData, lastNvsCompletedBy, lastNvsCompletedDate, calculateEarningsOnlySP, updateField, validateInputsForReconcile, setIsCompleteNvsClicked, calculateTotalsForSummaryTable } =
    reconcileData;
  const { error } = useToast();
  const [saveNvsUnits] = useSaveNvsUnitsMutation();
  const [completeNvs] = useCompleteNvsMutation();
  const invalidSummary = series.filter(srs => srs.invalidSummarySeries).length > 0;

  const totalUnitsOnChange = (field: keyof RecconcileFields, value: number) => {
    updateField(field, value);
    calculateEarningsOnlySP(isNational());
  };

  const nvsUnitsOnChange = (srs: ReconcileSeriesModel, e: ChangeEvent<HTMLInputElement>) => {
    srs.updateField('nvsUnits', defaultToUndefined(e.target.value));
    if (srs.isMultiSeries) {
      calculateTotalsForSummaryTable();
    } else {
      calculateEarningsOnlySP(isNational());
    }
    validateInputsForReconcile();
  };

  const handleSave = async () => {
    try {
      const seriesNvsInputs: SeriesNvsInput[] = [];

      series.forEach(srs => {
        if (!srs.isAdjustment) {
          seriesNvsInputs.push({
            rgnlAltId: srs.rgnlAltId,
            nvsUnits: assignNumberValue(srs.fields.nvsUnits),
          });
        }
      });

      const saveNvsUnitsInput: SaveNvsUnitsInput = {
        regionalOfferingId: offering.id,
        regionalOfferingRev: offering.rev,
        isNational: isNational(),
        totalNvsUnits: assignNumberValue(fields.totalNvsUnits),
        seriesNvsInputs,
      };

      if (isNational()) {
        saveNvsUnitsInput.totalNationalActualUnits = assignNumberValue(fields.totalNationalActualUnits);
      } else {
        saveNvsUnitsInput.totalRegionalActualUnits = assignNumberValue(fields.totalRegionalActualUnits);
      }

      const resp = await trackPromise(saveNvsUnits({ variables: { input: saveNvsUnitsInput } }));
      initData(resp.data?.saveNvsUnits.reconcileData as ReconcileResponse, isNational());
      setOffering(resp.data?.saveNvsUnits.offering as Offering);
      reconcileData.updateData(resp.data?.saveNvsUnits.offering as Offering);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleCompleteNvs = async () => {
    try {
      setIsCompleteNvsClicked();
      if (validateInputsForReconcile()) {
        const resp = await trackPromise(completeNvs({ variables: { input: { regionalOfferingId: offering.id, regionalOfferingRev: offering.rev } } }));
        setOffering(resp.data?.completeNvs.offering as Offering);
        reconcileData.updateData(resp.data?.completeNvs.offering as Offering);
      } else {
        error('Please review all highlighted fields for your series profile.');
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <section className={styles.summaryTableContainer}>
      <table id="recSummary" className={styles.penTable}>
        <thead>
          <tr className={styles.penRow}>
            <th />
            {(isNational() || isSETUser()) && (
              <>
                <td className={clsx(styles.seriesOffer, styles.headerLabel)}>Total NVS Units</td>
                <td>
                  <Input className={styles.unitInputs} defaultValue={fields.totalNvsUnits} onChange={e => totalUnitsOnChange('totalNvsUnits', assignNumberValue(e.target.value))} />
                </td>
                <td className={clsx(styles.seriesOffer, styles.headerLabel)}>Total Actual Units</td>
                <td>
                  <Input
                    className={styles.unitInputs}
                    defaultValue={fields.totalNationalActualUnits}
                    onChange={e => totalUnitsOnChange('totalNationalActualUnits', assignNumberValue(e.target.value))}
                  />
                </td>
              </>
            )}
            {!(isNational() || isSETUser()) && (
              <>
                <td className={clsx(styles.seriesOffer, styles.headerLabel)} colSpan={2}>
                  Total Actual Units
                </td>
                <td>
                  <Input
                    className={styles.unitInputs}
                    defaultValue={fields.totalRegionalActualUnits}
                    onChange={e => totalUnitsOnChange('totalRegionalActualUnits', assignNumberValue(e.target.value))}
                  />
                </td>
              </>
            )}
          </tr>
          <tr className={styles.labelRow}>
            <td />
            <td colSpan={4} className={styles.labelCol}>
              Regional Estimates
            </td>
            <td className={styles.penTableSpacer} />
            <td colSpan={4} className={styles.labelCol}>
              NVS
            </td>
            <td className={styles.penTableSpacer} />
            <td colSpan={5} className={styles.labelCol}>
              Regional Actuals
            </td>
            {!(isNational() || isSETUser()) && (
              <>
                <td className={styles.penTableSpacer}></td>
                <td colSpan={5} className={styles.labelCol}>
                  National Actuals
                </td>
              </>
            )}
          </tr>
          <tr className={styles.penRow}>
            <td />
            <td>Projected Units</td>
            <td>Earnings</td>
            <td>Spend</td>
            <td>Balance</td>
            <td className={styles.penTableSpacer}></td>
            <td>NVS Units</td>
            <td>Earnings</td>
            <td>Spend</td>
            <td>Balance</td>
            <td className={styles.penTableSpacer}></td>
            <td>Actual Units</td>
            <td>Earnings</td>
            <td>Spend</td>
            <td>Balance</td>
            <td>Variance</td>
            {!(isNational() || isSETUser()) && (
              <>
                <td className={styles.penTableSpacer}></td>
                <td>Actual Units</td>
                <td>Earnings</td>
                <td>Spend</td>
                <td>Balance</td>
                <td>Variance</td>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {series.map(srs => (
            <tr key={srs.uid} className={styles.penRow}>
              <td className={styles.seriesOffer}>{srs.seriesProfileName}</td>
              <td>{srs.forecastedSalesVolume}</td>
              <td>
                <div className={styles.dollarContainer}>
                  <DollarDisplay id={`rec-proj-earnings-${srs.uid}`} value={srs.raEarnings} className={styles.dollarAmount} />
                </div>
              </td>
              <td>
                <div className={styles.dollarContainer}>
                  <DollarDisplay id={`rec-proj-cost-${srs.uid}`} value={srs.raCost} className={styles.dollarAmount} />
                </div>
              </td>
              <td>
                <div className={styles.dollarContainer}>
                  <DollarDisplay id={`rec-proj-balance-${srs.uid}`} value={srs.raBalance} className={styles.dollarAmount} />
                </div>
              </td>
              <td className={styles.penTableSpacer}></td>
              {(isNational() || isSETUser()) && !(srs.isEarningsOnly || srs.isAdjustment) ? (
                <td className={styles.penInputs}>
                  <Input value={srs.fields.nvsUnits} onChange={e => nvsUnitsOnChange(srs, e)} error={srs.invalidSummarySeries && srs.fields.hasNvsUnitsError} />
                </td>
              ) : (
                <td className={clsx(styles.penData, Number(srs.fields.nvsUnits) < 0 && styles.textRed)}>{srs.fields.nvsUnits}</td>
              )}
              <td>
                <div className={styles.dollarContainer}>
                  <DollarDisplay id={`rec-nvs-earnings-${srs.uid}`} value={srs.totalNvsEarnings} className={styles.dollarAmount} />
                </div>
              </td>
              <td>
                <div className={styles.dollarContainer}>
                  <DollarDisplay id={`rec-nvs-proj-cost-${srs.uid}`} value={srs.totalNvsOfferCost} className={styles.dollarAmount} />
                </div>
              </td>
              <td>
                <div className={styles.dollarContainer}>
                  <DollarDisplay id={`rec-nvs-bal-${srs.uid}`} value={srs.totalNvsBalance} className={styles.dollarAmount} />
                </div>
              </td>
              <td className={styles.penTableSpacer}></td>
              <td>
                <span className={clsx(Number(isNational() ? srs.fields.nationalActualSalesVolume : srs.fields.regionalActualSalesVolume) < 0 && styles.textRed)}>
                  {isNational() ? srs.fields.nationalActualSalesVolume : srs.fields.regionalActualSalesVolume}
                </span>
              </td>
              <td>
                <div className={styles.dollarContainer}>
                  <DollarDisplay
                    id={`rec-act-earnings-${srs.uid}`}
                    value={isNational() ? srs.totalNationalActualEarnings : srs.totalRegionalActualEarnings}
                    className={styles.dollarAmount}
                  />
                </div>
              </td>
              <td>
                <div className={styles.dollarContainer}>
                  <DollarDisplay
                    id={`rec-act-cost-${srs.uid}`}
                    value={isNational() ? srs.totalNationalActualOfferCosts : srs.totalRegionalActualOfferCosts}
                    className={styles.dollarAmount}
                  />
                </div>
              </td>
              <td>
                <div className={styles.dollarContainer}>
                  <DollarDisplay
                    id={`rec-act-bal-${srs.uid}`}
                    value={isNational() ? srs.totalNationalActualBalance : srs.totalRegionalActualBalance}
                    className={styles.dollarAmount}
                  />
                </div>
              </td>
              <td>
                <div className={styles.dollarContainer}>
                  <DollarDisplay
                    id={`rec-act-bal-var-${srs.uid}`}
                    value={isNational() ? srs.nationalBalanceVariance : srs.regionalBalanceVariance}
                    className={styles.dollarAmount}
                  />
                </div>
              </td>
              {!(isNational() || isSETUser()) && (
                <>
                  <td className={styles.penTableSpacer}></td>
                  <td>{srs.fields.nationalActualSalesVolume}</td>
                  <td>
                    <div className={styles.dollarContainer}>
                      <DollarDisplay id={`rec-nat-act-earnings-${srs.uid}`} value={srs.totalNationalActualEarnings} className={styles.dollarAmount} />
                    </div>
                  </td>
                  <td>
                    <div className={styles.dollarContainer}>
                      <DollarDisplay id={`rec-nat-act-cost-${srs.uid}`} value={srs.totalNationalActualOfferCosts} className={styles.dollarAmount} />
                    </div>
                  </td>
                  <td>
                    <div className={styles.dollarContainer}>
                      <DollarDisplay id={`rec-nat-act-bal-${srs.uid}`} value={srs.totalNationalActualBalance} className={styles.dollarAmount} />
                    </div>
                  </td>
                  <td>
                    <div className={styles.dollarContainer}>
                      <DollarDisplay id={`rec-nat-act-var-${srs.uid}`} value={srs.nationalBalanceVariance} className={styles.dollarAmount} />
                    </div>
                  </td>
                </>
              )}
            </tr>
          ))}
          <tr className={clsx(styles.penRow, styles.totalRow)}>
            <td className={styles.seriesOffer}>Total</td>
            <td>{totalPenData.totalForecastedSalesVolume}</td>
            <td>
              <div className={styles.dollarContainer}>
                <DollarDisplay id={`rec-prj-total-ear`} value={totalPenData.totalProjectedEarnings} className={styles.dollarAmount} />
              </div>
            </td>
            <td>
              <div className={styles.dollarContainer}>
                <DollarDisplay id={`rec-prj-total-cost`} value={totalPenData.totalProjectedOfferCosts} className={styles.dollarAmount} />
              </div>
            </td>
            <td>
              <div className={styles.dollarContainer}>
                <DollarDisplay id={`rec-prj-total-bal`} value={totalPenData.totalProjectedBalance} className={styles.dollarAmount} />
              </div>
            </td>
            <td className={styles.penTableSpacer}></td>
            <td>{totalPenData.totalNvsUnits}</td>
            <td>
              <div className={styles.dollarContainer}>
                <DollarDisplay id={`rec-nvs-total-ear`} value={totalPenData.totalNvsEarnings} className={styles.dollarAmount} />
              </div>
            </td>
            <td>
              <div className={styles.dollarContainer}>
                <DollarDisplay id={`rec-nvs-total-cost`} value={totalPenData.totalNvsOfferCost} className={styles.dollarAmount} />
              </div>
            </td>
            <td>
              <div className={styles.dollarContainer}>
                <DollarDisplay id={`rec-nvs-total-bal`} value={totalPenData.totalNvsEarnings} className={styles.dollarAmount} />
              </div>
            </td>
            <td className={styles.penTableSpacer}></td>
            <td>{isNational() ? totalPenData.totalNationalActualSalesVolume : totalPenData.totalRegionalActualSalesVolume}</td>
            <td>
              <div className={styles.dollarContainer}>
                <DollarDisplay
                  id={`rec-act-total-ear`}
                  value={isNational() ? totalPenData.totalNationalActualEarnings : totalPenData.totalRegionalActualEarnings}
                  className={styles.dollarAmount}
                />
              </div>
            </td>
            <td>
              <div className={styles.dollarContainer}>
                <DollarDisplay
                  id={`rec-act-total-cost`}
                  value={isNational() ? totalPenData.totalNationalActualOfferCosts : totalPenData.totalRegionalActualOfferCosts}
                  className={styles.dollarAmount}
                />
              </div>
            </td>
            <td>
              <div className={styles.dollarContainer}>
                <DollarDisplay
                  id={`rec-act-total-bal`}
                  value={isNational() ? totalPenData.totalNationalActualBalance : totalPenData.totalRegionalActualBalance}
                  className={styles.dollarAmount}
                />
              </div>
            </td>
            <td>
              <div className={styles.dollarContainer}>
                <DollarDisplay
                  id={`rec-act-total-bal-var`}
                  value={isNational() ? totalPenData.totalNationalActualBalanceVariance : totalPenData.totalRegionalActualBalanceVariance}
                  className={styles.dollarAmount}
                />
              </div>
            </td>

            {!(isNational() || isSETUser()) && (
              <>
                <td className={styles.penTableSpacer}></td>
                <td>{totalPenData.totalNationalActualSalesVolume}</td>
                <td>
                  <div className={styles.dollarContainer}>
                    <DollarDisplay id={`rec-nat-act-total-ear`} value={totalPenData.totalNationalActualEarnings} className={styles.dollarAmount} />
                  </div>
                </td>
                <td>
                  <div className={styles.dollarContainer}>
                    <DollarDisplay id={`rec-nat-act-total-cost`} value={totalPenData.totalNationalActualOfferCosts} className={styles.dollarAmount} />
                  </div>
                </td>
                <td>
                  <div className={styles.dollarContainer}>
                    <DollarDisplay id={`rec-nat-total-bal`} value={totalPenData.totalNationalActualBalance} className={styles.dollarAmount} />
                  </div>
                </td>
                <td>
                  <div className={styles.dollarContainer}>
                    <DollarDisplay id={`rec-nat-total-var`} value={totalPenData.totalNationalActualBalanceVariance} className={styles.dollarAmount} />
                  </div>
                </td>
              </>
            )}
          </tr>
        </tbody>
      </table>
      {invalidSummary && (
        <div className={styles.recError}>
          <OATIcon icon="warning" colorTheme="red" />
          <span>Please review all highlighted fields for your series profile.</span>
        </div>
      )}

      <div className={styles.recCtas}>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
        {(isNational() || isSETUser()) && (
          <Button variant="primary" onClick={handleCompleteNvs}>
            Complete NVS
          </Button>
        )}
      </div>
      {isNational() && lastNvsCompletedBy && <p className={styles.lastModified}>{`Last Updated By ${lastNvsCompletedBy} on ${lastNvsCompletedDate}`}</p>}
    </section>
  );
};

export default observer(Summary);
