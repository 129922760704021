import { observer } from 'mobx-react-lite';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader, OATIcon } from 'oat-common-ui';
import { useState } from 'react';
import AccordionComponent from '../../../../components/Accordion/AccordionComponent';
import AccordionItemModel from '../../../../components/Accordion/models/AccordionItemModel';
import AccordionModel from '../../../../components/Accordion/models/AccordionModel';
import styles from './styles.module.scss';

interface Props {
  accordion: AccordionModel;
  className?: string;
  ctaLabel?: string;
  disabled?: boolean;
  id?: string;
  isExclusionsActive: boolean;
  labelExcluded: string;
  labelIncluded: string;
  onClose: () => void;
  onSubmit: (exlusions: boolean, items: AccordionItemModel[]) => void;
  submitText?: string;
}

const ExclusionsModal = ({ id, submitText = 'Submit', className, ctaLabel, accordion, labelExcluded, labelIncluded, onSubmit, onClose, isExclusionsActive }: Props) => {
  const [exclusions, setExclusions] = useState(isExclusionsActive);

  const title = exclusions ? labelExcluded : labelIncluded;

  const showErrorMessage = accordion.isAllSelected(exclusions);

  const handleExclusions = () => {
    setExclusions(!exclusions);
    accordion.toggleReverse();
  };

  const handleSelectAll = () => {
    accordion.toggleAll(true);
  };

  const handleRemoveAll = () => {
    accordion.toggleAll(false);
  };

  return (
    <Modal className={styles.width} isOpen onClose={onClose}>
      {/* Add styling for header */}
      <ModalHeader onClose={onClose}>{`Select ${title}`}</ModalHeader>
      <ModalBody>
        <div className={styles.labelWrapper}>
          <OATIcon className={styles.icon} icon="switch" colorTheme="blue" size={14} onClick={handleExclusions} />
          <span className={styles.exclusion}>{exclusions ? 'Exclusions' : 'Inclusions'}</span>
        </div>
        <div className={styles.accordionWrapper}>
          <AccordionComponent accordion={accordion} />
        </div>
      </ModalBody>
      <ModalFooter className={styles.footerWrapper}>
        {showErrorMessage && (
          <span className={styles.errorText}>
            {exclusions
              ? 'You cannot exclude all vehicles for the Series. Please deselect some exclusions.'
              : 'You cannot exclude all vehicles for the Series. Please select some inclusions.'}
          </span>
        )}
        <ButtonGroup>
          <Button id={`select-all-cta-${id}`} variant="text" className={styles.button} onClick={handleSelectAll}>{`Select All ${ctaLabel ? ctaLabel : title}`}</Button>
          <Button id={`remove-all-cta-${id}`} variant="text" className={styles.button} onClick={handleRemoveAll}>{`Remove All ${ctaLabel ? ctaLabel : title}`}</Button>
          <Button id={`default-modal-submit-${id}`} variant="primary" disabled={showErrorMessage} onClick={() => onSubmit(exclusions, accordion.items)}>
            {submitText}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default observer(ExclusionsModal);
