import { faCheckCircle, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { BlockInputLabel, Dropdown, DropdownItem, OATIcon, SeriesCodeToNameMap, Tooltip, formatDollars } from 'oat-common-ui';
import { useState } from 'react';
import InfoTooltip from '../../../../../components/InfoTooltip';
import NumberInput from '../../../../../components/NumberInput';
import { formatValue } from '../../../../../components/NumberInput/utils';
import SetSubvenedLeaseRcfs from '../../../../../components/SetSubvenedLeaseRcfs';
import SlideToggle from '../../../../../components/SlideToggle';
import { FEATURE_OR_2477, FEATURE_OR_4154, Status } from '../../../../../constants/global';
import useUrlParams from '../../../../../hooks/useUrlParams';
import useStores from '../../../../../stores/useStores';
import { assignNumberValue } from '../../../../../utils/assignValue';
import getAnnualMileagesList from '../../../../../utils/getAnnualMileagesList';
import useGetTfsLabel from '../../../../../utils/useGetTfsLabel';
import useUserInfo from '../../../../../utils/useUserInfo';
import LeaseCardModel from '../../../models/LeaseCardModel';
import LeaseFormModel from '../../../models/LeaseFormModel';
import LeaseTermModel from '../../../models/LeaseTermModel';
import EnhCostShareModal from '../../EnhCostShareModal';
import LeaseCardBase from '../LeaseCardBase';
import CostInfo from '../components/CostInfo';
import EstCost from '../components/EstCost';
import LeaseDates from '../components/LeaseDates';
import { LeaseFormLayoutColumn } from '../components/LeaseFormLayout';
import LeaseFundingSplitModal from '../components/LeaseFundingSplitModal';
import LeaseInput, { LeaseInputDescription, LeaseInputLabel, LeaseInputWrapper } from '../components/LeaseInput';
import LeaseSpecialEdition from '../components/LeaseSpecialEdition';
import MonthInfo from '../components/MonthInfo';
import TFSSubven from '../components/TFSSubven';
import TermSelector from '../components/TermSelector';
import AdjustmentFactorTooltipMessage from '../components/Tooltips/AdjustmentFactorTooltipMessage';
import DueAtSigningTooltipMessage from '../components/Tooltips/DueAtSigningTooltipMessage';
import PnvsTooltipMessage from '../components/Tooltips/PnvsTooltipMessage';
import TfsRcfTooltipMessage from '../components/Tooltips/TfsRcfTooltipMessage';
import ViewRcfTooltipMessage from '../components/Tooltips/ViewRcfTooltipMessage';
import VehicleInfo from '../components/VehicleInfo';
import ViewRcfModal from '../components/ViewRcfModal';
import { getDefaultLeaseTfsShare } from '../utils/getDefaultLeaseTfsShare';
import LeaseAdditionalCashAmount from './LeaseAdditionalCashAmount';
import LeaseAdditionalCashDD from './LeaseAdditionalCashDD';
import styles from './styles.module.scss';
import useSaveLeaseData from './useSaveLeaseData';

interface Props {
  leaseCard: LeaseCardModel;
  leaseTerm: LeaseTermModel;
  leaseForm: LeaseFormModel;
}

const LeaseAdForm = ({ leaseCard, leaseTerm, leaseForm }: Props) => {
  const {
    programDetailsStore: { offering },
  } = useStores();

  const { id, term } = leaseCard;
  const {
    lock,
    inputs,
    setLock,
    updateMileage,
    updateInputWithCalc,
    updateSubCash,
    updateOfferCosts,
    updateTfsShare,
    updateRvEnhancement,
    removeCustomerCashAdditionalCash,
    recalculate,
    setTfsEnhRatesUsed,
    seriesName,
  } = leaseForm;
  const { syncMasterAndExamples } = leaseTerm;

  const [editRcf, setEditRcf] = useState(false);
  const [standardRegionalRcf, setStandardRegionalRcf] = useState(inputs.standardRegionalRcf);
  const [fundingSplitOpen, setFundingSplitOpen] = useState(false);
  const [openViewRcfModal, setOpenViewRcfModal] = useState(false);
  const tfsLabel = useGetTfsLabel();
  const { userInfoStore, rcfsResidualsStore, programDetailsStore } = useStores();
  const { region } = useUrlParams();

  const { isLexusUser, isSETUser } = useUserInfo();
  const defaultTfsShare = getDefaultLeaseTfsShare(!!offering.useOldCostShareForLexus, region, term, leaseCard.isEnhCostShareAccepted, leaseCard.enhancedCostShare);

  const {
    handleAddLeaseTerm,
    handleHideLeaseTerm,
    handleSaveCompatibility,
    handleInputChange,
    handleEnhCostData,
    handleEnhTfsCostData,
    handleAdditionalCashChange,
    handleUptoChangeOrSave,
  } = useSaveLeaseData(leaseCard, leaseTerm, leaseForm);

  const tiersList = inputs.rates.map(rate => `Tier ${rate.tier}`).join(', ');
  const mileages = getAnnualMileagesList(userInfoStore.userInfo.brand, region, term > 30, seriesName.toLowerCase().includes(SeriesCodeToNameMap.supra.toLowerCase()));

  const handleLockChange = (field: string) => {
    setLock(field);
  };

  const handleMileage = ({ value }: DropdownItem) => {
    const { residuals } = rcfsResidualsStore;
    updateMileage(value, residuals);
    updateOfferCosts(programDetailsStore.ryoEarnings);
    handleInputChange();
  };

  const handleEditRcf = () => {
    setEditRcf(true);
  };

  const handleSubmitStdRcf = () => {
    updateInputWithCalc('standardRegionalRcf', standardRegionalRcf);
    updateOfferCosts(programDetailsStore.ryoEarnings);
    setEditRcf(false);
    handleInputChange(true);
  };

  const isDisabled = programDetailsStore.offering.status === Status.MEETS_GUIDELINES || leaseCard.isDisabled;
  const masterLease = leaseTerm.getMaster();

  const tfsEnhRatesUsedChanged = () => {
    setTfsEnhRatesUsed(!leaseForm.areTfsEnhRatesUsed, tfsLabel);
    handleLockChange('targetPayment');
    handleUptoChangeOrSave();
  };

  return (
    <LeaseCardBase
      leaseCard={leaseCard}
      leaseTerm={leaseTerm}
      leaseForm={leaseForm}
      onInputChange={handleInputChange}
      onIsUpToApplied={handleUptoChangeOrSave}
      areTfsEnhRatesUsed={leaseForm.areTfsEnhRatesUsed}
      leftContent={
        <>
          <VehicleInfo
            leaseCard={leaseCard}
            isAdvertised
            leaseForm={leaseForm}
            onSaveCompatibility={handleSaveCompatibility}
            onChange={(skipDelay, isVehicleChange) => handleInputChange(skipDelay, undefined, isVehicleChange)}
            isDisabled={isDisabled}
          />
          <div>
            {<MonthInfo targetPayment={inputs.targetPayment} term={term} dueAtSigning={inputs.dueAtSigning} />}
            <TFSSubven tfsCashValue={inputs.subventionCash} />
          </div>
          <LeaseDates leaseCard={leaseCard} leaseForm={leaseForm} onChange={() => handleInputChange(true)} isDisabled={isDisabled} />
          {isLexusUser() && <LeaseSpecialEdition leaseCard={leaseCard} leaseForm={leaseForm} onChange={handleInputChange} />}
          <div className={styles.applicableTiersContainer}>
            <FontAwesomeIcon icon={faCheckCircle} />
            <span>Applicable Tiers</span>
            <div className={styles.tiersList}>{tiersList}</div>
          </div>
          <div className={styles.viewRcfContainer}>
            {isSETUser() ? (
              <div onClick={() => setOpenViewRcfModal(true)}>
                <FontAwesomeIcon icon={faList} />
                <span className={styles.viewRcflabel}>View/Edit RCF</span>
              </div>
            ) : (
              <Tooltip
                id={`view-rcf-${leaseForm.uid}`}
                message={
                  <ViewRcfTooltipMessage
                    term={inputs.term}
                    rates={!leaseForm.areTfsEnhRatesUsed ? inputs.rates : undefined}
                    tfsEnhancedRates={leaseForm.areTfsEnhRatesUsed && inputs.tfsEnhancedRates != null ? inputs.tfsEnhancedRates : undefined}
                    service={tfsLabel}
                  />
                }
                clickable
                place="bottom"
              >
                <FontAwesomeIcon icon={faList} />
                <span className={styles.viewRcflabel}>View RCF</span>
              </Tooltip>
            )}
          </div>

          {/* Cost inputs (ex: rvamount, gcc, ncc, etc...) */}
          <CostInfo leaseForm={leaseForm} isSET={isSETUser()} valueOnChange={handleInputChange} isDisabled={isDisabled} />
          <TermSelector leaseCard={leaseCard} leaseForm={leaseForm} allowHide onAddTerm={handleAddLeaseTerm} onHideTerm={handleHideLeaseTerm} />
        </>
      }
      rightHeaderContent={
        <>
          {/* RCF */}
          <LeaseInput
            label="Regional Subvened RCF"
            lock={lock}
            field="rcf"
            value={leaseForm.areTfsEnhRatesUsed ? inputs.enhancedStdRcf : inputs.rcf}
            rcf
            allowNegative
            error={Boolean(leaseForm.rcfError)}
            onLockChange={handleLockChange}
            onValueChange={value => {
              updateInputWithCalc('rcf', value.value);
              removeCustomerCashAdditionalCash(programDetailsStore.offerCards.cash);
              updateTfsShare(defaultTfsShare);
              syncMasterAndExamples();
              updateOfferCosts(programDetailsStore.ryoEarnings);
              handleUptoChangeOrSave();
            }}
            className={styles.headerRcf}
            disabled={isDisabled || leaseForm.isExample || leaseForm.areTfsEnhRatesUsed}
          />

          <SetSubvenedLeaseRcfs leaseForm={leaseForm} />
          {/* National RCF / Standard RCF */}
          <div className={clsx(styles.tfsRcf, leaseForm.areTfsEnhRatesUsed && styles.tfsRcfLabelDisabled)}>
            {/* National/Standard RCF */}
            {!editRcf && <b>{formatValue(leaseForm.inputs.isStandalone ? inputs.standardRegionalRcf : inputs.nationalRcf, true)}</b>}
            {editRcf && (
              <div className={styles.editRcfInputContainer}>
                <NumberInput
                  id={`edit-std-rcf-input-${id}`}
                  value={standardRegionalRcf}
                  rcf
                  onChange={e => {
                    setStandardRegionalRcf(e.currentTarget.value);
                  }}
                />
                <OATIcon id={`std-rcf-submit-${id}`} icon="check-mark" colorTheme="blue" onClick={handleSubmitStdRcf} />
              </div>
            )}

            <BlockInputLabel>
              {/* Tooltip/Label */}
              <InfoTooltip id={`nat-rcf-${id}`} message={<TfsRcfTooltipMessage leaseDetails={leaseForm} />} />
              <span className={styles.tfsRcfLabel}>{leaseForm.inputs.isStandalone ? 'Regional Standard RCF' : `${leaseForm.getStandardNationalLabel()} ${tfsLabel} RCF`}</span>

              {/* Edit RCF icon */}
              {!leaseForm.areTfsEnhRatesUsed && !isDisabled && !leaseForm.isExample && leaseForm.inputs.isStandalone && !editRcf && (
                <span className={styles.editRcfIcon}>
                  <OATIcon id={`edit-std-rcf-${id}`} colorTheme="blue" icon="edit" onClick={handleEditRcf} />
                </span>
              )}
            </BlockInputLabel>
          </div>

          {/* Enhanced Standard TFS RCF */}
          {leaseForm.showEnhTfsRcf && (
            <div className={styles.tfsRcf}>
              {!editRcf && <b>{formatValue(leaseForm.inputs.isStandalone ? inputs.standardRegionalRcf : inputs.nationalRcf, true)}</b>}
              <BlockInputLabel className={styles.enhTfsRcfLabel}>
                <InfoTooltip id={`enh-std-rcf-${id}`} message={'something'} />
                <span className={styles.tfsRcfLabel}>{`Enhanced Standard ${tfsLabel} RCF`}</span>
                <SlideToggle
                  containerClass={styles.enhTfsRcfToggle}
                  selected={leaseForm.inputs.isTfsEnhRcf}
                  toggleSelected={() => {
                    leaseForm.updateInputWithCalc('isTfsEnhRcf', !leaseForm.inputs.isTfsEnhRcf);
                    updateOfferCosts(programDetailsStore.ryoEarnings);
                    handleInputChange();
                  }}
                />
              </BlockInputLabel>
            </div>
          )}

          {/* Enhanced Standard RCF */}
          {leaseForm.inputs.isStandalone && leaseForm.isEnhRcfEligible && (
            <div className={styles.tfsRcf}>
              {!editRcf && <b>{inputs.enhancedStdRcf}</b>}
              <BlockInputLabel className={styles.enhTfsRcfLabel}>
                <InfoTooltip id={`enh-std-rcf-${id}`} message="" />
                <span className={styles.tfsRcfLabel}>{`Enhanced Standard ${tfsLabel} RCF`}</span>
                <SlideToggle containerClass={styles.enhTfsRcfToggle} disabled={isDisabled} selected={leaseForm.areTfsEnhRatesUsed} toggleSelected={tfsEnhRatesUsedChanged} />
              </BlockInputLabel>
            </div>
          )}

          {/* Est Buydown Cost */}
          <div className={styles.headerRight}>
            <EstCost
              value={masterLease.inputs.estimatedCost}
              label="Est. Buydown Cost"
              error={masterLease.estCostError?.min}
              tooltip={<InfoTooltip id={`est-cost-${id}`} message={<PnvsTooltipMessage leaseDetails={masterLease} term={term} />} />}
            />
          </div>
        </>
      }
      rightBodyContent={
        <>
          <LeaseFormLayoutColumn>
            {/* Target Payment  */}
            <LeaseInput
              label="Target Payment"
              lock={lock}
              field="targetPayment"
              value={inputs.targetPayment}
              error={Boolean(leaseForm.tpError)}
              dollarSign
              text={`${leaseForm.inputs.isStandalone ? 'Default: ' : 'National: '} $${formatDollars(inputs.targetPaymentDefault)}`}
              onLockChange={handleLockChange}
              onValueChange={val => {
                updateInputWithCalc('targetPayment', val.value);
                updateOfferCosts(programDetailsStore.ryoEarnings);
                handleUptoChangeOrSave();
              }}
              wholeNumber
              allowNegative
              disabled={isDisabled}
            />

            {/* Dealer Gross */}
            <LeaseInput
              label="Dealer Gross"
              lock={lock}
              field="dealerGross"
              value={inputs.dealerGross}
              error={Boolean(leaseForm.dgError)}
              dollarSign
              text={`Regional Default: $${formatDollars(inputs.dealerGrossDefault)}`}
              onLockChange={handleLockChange}
              onValueChange={val => {
                updateInputWithCalc('dealerGross', val.value);
                updateOfferCosts(programDetailsStore.ryoEarnings);
                handleUptoChangeOrSave();
              }}
              wholeNumber
              allowNegative
              disabled={isDisabled}
            />

            {/* Term */}
            <LeaseInputWrapper>
              <LeaseInputLabel>Term</LeaseInputLabel>
              <NumberInput id={`term-${id}`} value={leaseCard.term} className={styles.input} disabled />
              <LeaseInputDescription>{leaseForm.inputs.isStandalone ? `Default: 36` : `National: ${inputs.nationalTerm}`}</LeaseInputDescription>
            </LeaseInputWrapper>
          </LeaseFormLayoutColumn>

          <LeaseFormLayoutColumn rightSection>
            {/* Due At Signing */}
            <LeaseInput
              label={
                <>
                  <InfoTooltip id={`das-tt-${id}`} message={<DueAtSigningTooltipMessage leaseDetails={leaseForm} />} />
                  Due At Signing
                </>
              }
              lock={lock}
              field="dueAtSigning"
              wholeNumber
              allowNegative
              value={inputs.dueAtSigning}
              error={Boolean(leaseForm.dasError || leaseForm.dpError)}
              dollarSign
              text={
                <>
                  <div>Default: ${formatDollars(inputs.dueAtSigningDefault)}</div>
                  {!leaseForm.inputs.acquisitionFee && !leaseForm.inputs.isAcquisitionFeeCapitalized && 'Does not include Acquisition Fee'}
                </>
              }
              onLockChange={handleLockChange}
              onValueChange={val => {
                if (assignNumberValue(val.value) !== assignNumberValue(inputs.dueAtSigning)) {
                  updateInputWithCalc('dueAtSigning', val.value);
                  updateOfferCosts(programDetailsStore.ryoEarnings);
                  handleUptoChangeOrSave();
                }
              }}
              disabled={isDisabled}
            />

            {/* Subvention Cash */}
            <LeaseInput
              label={
                <>
                  {tfsLabel} Subvention Cash {leaseForm.inputs.isSubCashAdvertised && <OATIcon icon="active_offer" colorTheme="orange" />}
                </>
              }
              lock={lock}
              field="subventionCash"
              value={inputs.subventionCash}
              error={Boolean(leaseForm.subCashError)}
              dollarSign
              text={leaseForm.inputs.isStandalone ? undefined : `National: $${formatDollars(leaseForm.inputs.nationalSubventionCash)}`}
              onLockChange={handleLockChange}
              onValueChange={val => {
                updateSubCash(val.value);
                syncMasterAndExamples();
                updateOfferCosts(programDetailsStore.ryoEarnings);
                handleUptoChangeOrSave();
              }}
              wholeNumber
              allowNegative
              disabled={isDisabled || leaseForm.isExample || leaseForm.areTfsEnhRatesUsed}
            />
            {/* Annual Mileage */}
            <LeaseInputWrapper>
              <LeaseInputLabel>Annual Mileage</LeaseInputLabel>
              <Dropdown id="mileage" value={inputs.mileage} options={mileages.mileages} onChange={handleMileage} disabled={isDisabled} />
              <LeaseInputDescription>
                {leaseForm.inputs.isStandalone ? `Default: ${formatDollars(leaseForm.defaultMileage)}` : `National: ${formatDollars(leaseForm.inputs.nationalMileage)}`}
              </LeaseInputDescription>
            </LeaseInputWrapper>
          </LeaseFormLayoutColumn>
          <LeaseFormLayoutColumn>
            {/* Adjustment Factor*/}
            <LeaseInput
              id={`adj-factor-${id}`}
              label={
                <>
                  <InfoTooltip id={`adj-factor-tt-${id}`} message={<AdjustmentFactorTooltipMessage />} />
                  Adjustment Factor
                </>
              }
              value={inputs.adjustmentFactor}
              className={styles.adjFactor}
              onValueChange={vals => {
                updateInputWithCalc('adjustmentFactor', vals.value);
                syncMasterAndExamples();
                updateOfferCosts(programDetailsStore.ryoEarnings);
                handleInputChange();
              }}
              percentageSign
              text={`Default: 10%`}
              disabled={isDisabled || leaseForm.isExample || leaseForm.areTfsEnhRatesUsed}
            />

            {/* Additional Cash Offer */}
            <LeaseAdditionalCashDD
              leaseTerm={leaseTerm}
              leaseForm={leaseForm}
              leaseCard={leaseCard}
              cashOffers={programDetailsStore.offerCards.cash}
              miscOffers={programDetailsStore.offerCards.misc}
              onChange={() => handleInputChange(true)}
            />

            {/* RV Enhancement (SET Only) */}
            {isSETUser() && (
              <LeaseInput
                id={`rv-enh-${id}`}
                label="RV Enhancement"
                value={inputs.enhancedResidualRate}
                percentageSign
                onValueChange={vals => {
                  updateRvEnhancement(vals.value);
                  updateOfferCosts(programDetailsStore.ryoEarnings);
                  handleInputChange();
                }}
                wholeNumber
                disabled={isDisabled}
              />
            )}
          </LeaseFormLayoutColumn>

          <LeaseFormLayoutColumn>
            {/* TFS Share */}
            <LeaseInput
              id={`tfs-share-${id}`}
              label={
                <>
                  {!isDisabled && !leaseForm.isExample && leaseForm.isEnhanced && (
                    <OATIcon id={`funding-split-${id}`} icon="split" colorTheme="blue" pointer onClick={() => setFundingSplitOpen(true)} />
                  )}
                  {leaseForm.isEnhanced && leaseForm.fundingSourceSplits.length > 0 ? 'Blended Share' : `${tfsLabel} Share`}
                </>
              }
              text={`${leaseCard.enhancedCostShare?.tfsCostShare ? `Enhanced: ${leaseCard.enhancedCostShare?.tfsCostShare}%` : `Default: ${defaultTfsShare}%`}`}
              percentageSign
              value={inputs.tfsShare}
              className={styles.tfsShare}
              disabled={!leaseForm.isEnhanced || leaseForm.fundingSourceSplits.length > 0 || isDisabled || leaseForm.isExample}
              onValueChange={vals => {
                updateInputWithCalc('tfsShare', vals.value);
                syncMasterAndExamples();
                updateOfferCosts(programDetailsStore.ryoEarnings);
                handleUptoChangeOrSave();
              }}
            />
            {fundingSplitOpen && (
              <LeaseFundingSplitModal
                leaseCard={leaseCard}
                leaseForm={leaseForm}
                onClose={() => setFundingSplitOpen(false)}
                onSubmit={val => {
                  updateInputWithCalc('tfsShare', val);
                  syncMasterAndExamples();
                  updateOfferCosts(programDetailsStore.ryoEarnings);
                }}
              />
            )}

            {/* Additional Cash Amount */}
            <LeaseAdditionalCashAmount
              leaseTerm={leaseTerm}
              leaseForm={leaseForm}
              leaseCard={leaseCard}
              onChange={handleAdditionalCashChange}
              cashOffers={programDetailsStore.offerCards.cash}
              miscOffers={programDetailsStore.offerCards.misc}
            />
          </LeaseFormLayoutColumn>
          {openViewRcfModal && <ViewRcfModal leaseCard={leaseCard} leaseForm={leaseForm} onClose={() => setOpenViewRcfModal(false)} disabled={isDisabled} />}
          {FEATURE_OR_2477 && leaseCard.enhancedCostFields.showModal && (
            <EnhCostShareModal
              onButtonPress={isConfirmed => {
                leaseCard.updateEnhancedCostShareField('isModalConfirmed', isConfirmed);

                // re-calculate if user choose to use enhanced cost share offer with new (enhanced) values
                if (isConfirmed) {
                  handleEnhCostData();
                  recalculate(undefined, 'tfsShare');
                  syncMasterAndExamples();
                  updateOfferCosts(programDetailsStore.ryoEarnings);
                }

                handleInputChange(true);
              }}
            />
          )}
          {FEATURE_OR_2477 && leaseCard.enhancedCostFields.removingReason && (
            <EnhCostShareModal
              onButtonPress={isConfirmed => {
                leaseCard.updateEnhancedCostShareField('isModalConfirmed', isConfirmed);

                handleEnhCostData();
                recalculate(undefined, 'tfsShare');
                syncMasterAndExamples();
                updateOfferCosts(programDetailsStore.ryoEarnings);

                handleInputChange(true);
              }}
              text={`${leaseCard.enhancedCostFields.removingReason} National Enhanced Cost Share will be removed. Do you want to continue?`}
              submitBtnText="OK"
              title="Remove Enhanced Cost Share"
            />
          )}
          {FEATURE_OR_4154 && leaseCard.enhancedTfsCostFields.showModal && (
            <EnhCostShareModal
              title={`Enhanced ${tfsLabel} Cost Share Available`}
              text={`Offer is eligible for National Enhanced ${tfsLabel} Cost Share. Do you want to apply?`}
              onButtonPress={isConfirmed => {
                leaseCard.updateEnhancedTfsCostShareField('isModalConfirmed', isConfirmed);
                handleEnhTfsCostData();

                if (isConfirmed) {
                  setStandardRegionalRcf(inputs.standardRegionalRcf);
                  recalculate(undefined, 'rcf');
                  syncMasterAndExamples();
                  updateOfferCosts(programDetailsStore.ryoEarnings);
                }

                handleInputChange(true, undefined, false, false);
              }}
            />
          )}
          {FEATURE_OR_4154 && leaseCard.enhancedTfsCostFields.removingReason && (
            <EnhCostShareModal
              onButtonPress={isConfirmed => {
                leaseCard.updateEnhancedTfsCostShareField('isModalConfirmed', isConfirmed);

                handleEnhTfsCostData();
                recalculate(undefined, 'rcf');
                syncMasterAndExamples();
                updateOfferCosts(programDetailsStore.ryoEarnings);

                handleInputChange(true);
              }}
              text={`Dates selected are ineligible for National Enhanced ${tfsLabel} Cost Share. National Enhanced ${tfsLabel} Cost Share will be removed. Do you want to continue?`}
              submitBtnText="OK"
              title={`Enhanced ${tfsLabel} Cost Share Removed`}
            />
          )}
        </>
      }
    />
  );
};

export default observer(LeaseAdForm);
