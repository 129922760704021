import { useMemo } from 'react';
import { Status } from '../constants/global';
import { Offering } from '../gql/generated';

const useOfferingStatus = (offering: Offering) => {
  const offeringStatus: string = useMemo(() => {
    const { status, isAtc } = offering;

    if (isAtc) {
      return status === Status.NEW ? Status.ATC_DRAFT : `ATC - ${status}`;
    }

    return status;
  }, [offering]);

  return {
    offeringStatus,
  };
};

export default useOfferingStatus;
