import clsx from 'clsx';
import { ReactNode } from 'react';
import useGetTfsLabel from '../../../../../../../utils/useGetTfsLabel';
import PenRateModel from '../../../../../models/PenRateModel';
import ExpHeader from '../ExpHeader';
import styles from '../ExpHeader/styles.module.scss';

interface Props {
  aprModel: PenRateModel;
  showDates: (model: PenRateModel) => ReactNode;
}

const AprHeader = ({ aprModel, showDates }: Props) => {
  const tfsLabel = useGetTfsLabel();
  const { name, tier, optionTypeName, subCash, isSubCashEnhanced } = aprModel;

  return (
    <ExpHeader
      name={
        <>
          {name} {tier}
        </>
      }
      isSubCashEnhanced={isSubCashEnhanced || subCash > 0}
      showDates={showDates(aprModel)}
      optionTypeName={`${tfsLabel} ${optionTypeName} Subvention Cash`}
      headerStyles={clsx(styles.aprName, styles.aprHeader)}
      dividerStyle={styles.aprDivider}
    />
  );
};

export default AprHeader;
