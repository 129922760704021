import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { OATIcon, useToast } from 'oat-common-ui';
import { useMemo } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { AprDetails, useSaveFundingSplitsMutation } from '../../../../../../gql/generated';
import useStores from '../../../../../../stores/useStores';
import AprCardModel from '../../../../models/AprCardModel';
import AprTierModel from '../../../../models/AprTierModel';
import FundingSplitsModal from '../../../FundingSplitsModal';
import FundingSplitsTable from '../../../FundingSplitsModal/FundingSplitsTable';
import AprFundingModel from './AprFundingModel';

import styles from './styles.module.scss';

type Props = {
  aprCard: AprCardModel;
  selectedTier: AprTierModel;
  onClose: () => void;
  onSubmit: () => void;
};

const AprFundingSplitModal = ({ aprCard, selectedTier, onClose, onSubmit }: Props) => {
  const {
    programDetailsStore,
    rcfsResidualsStore: { stdAprRates },
  } = useStores();

  const { error } = useToast();
  const [saveFundingSplits] = useSaveFundingSplitsMutation();

  let aprFunding = useMemo(() => new AprFundingModel(selectedTier, stdAprRates), [selectedTier, stdAprRates]);

  const handleSubmit = async () => {
    const { tfsShares, payload } = aprFunding.getFundingSplitsPayloadData(aprCard.id, aprCard.rev);

    if (aprCard.isForCreate) {
      selectedTier.updateTermsFundingSourceSplits(payload.fundingSourceSplits, tfsShares);
    } else {
      try {
        const res = await trackPromise(
          saveFundingSplits({
            variables: {
              input: payload,
            },
          }),
        );

        if (res.data?.saveFundingSourceSplits.offer) {
          aprCard.updateRev(res.data?.saveFundingSourceSplits.offer.rev);
          selectedTier.updateTermFundingSourceSplits(res.data.saveFundingSourceSplits.offer.aprDetails as AprDetails[]);
        }

        selectedTier.updateTermsFundingSourceSplits(payload.fundingSourceSplits, tfsShares);
        selectedTier.updateEstCosts();
        if (!aprCard.isForCreate) {
          selectedTier.updateOfferCosts(programDetailsStore.ryoEarnings);
        }
        onSubmit();
      } catch (e) {
        error((e as Error).message);
      }
    }
  };

  const fundingSplitsIsDisabled = aprCard.isEnhCostShareUpdated && !Boolean(aprCard.enhCostShareOfferId);

  return (
    <FundingSplitsModal onClose={onClose} onSubmit={handleSubmit} hasError={aprFunding.hasError}>
      <div className={styles.tabsWrapper}>
        {aprFunding.enhancedTerms.map(enhancedTerm => {
          return (
            <div
              className={clsx(styles.termTab, aprFunding.activeTerm === enhancedTerm.fields.term && styles.active)}
              key={enhancedTerm.fields.term}
              onClick={() => aprFunding.setActiveTerm(enhancedTerm)}
            >
              {enhancedTerm.fields.term} mo.
              {aprFunding.isTermBlended(enhancedTerm.fields.term) && <OATIcon className={styles.expandIcon} icon="expand" size={14} />}
            </div>
          );
        })}
      </div>
      {aprFunding.activeFundingTerm && <FundingSplitsTable fundingTerm={aprFunding.activeFundingTerm} isDisabled={fundingSplitsIsDisabled} />}
    </FundingSplitsModal>
  );
};

export default observer(AprFundingSplitModal);
