import { AdminConstants, AdminModels, isOfferEligibleForEnhCostShare, isOfferEligibleForEnhTfsCostShare } from 'oat-admin-common';
import { dateFormatISOString } from 'oat-common-ui';
import { EnhancedCostShare, Maybe, TfsContract, VehicleInput } from '../gql/generated';
import useStores from '../stores/useStores';

export type OfferEligibilityFields = {
  enhCostShareOfferId?: Maybe<string>;
  enhTfsCostShareId?: Maybe<string>;
  isEligibleForEnhCostShare?: Maybe<boolean>;
  isEligibleForEnhTfsCostShare?: Maybe<boolean>;
  isEnhCostShareAccepted?: Maybe<boolean>;
  isEnhTfsCostShareAccepted?: Maybe<boolean>;
};

export interface AprTierTermOfferInput {
  tier: string;
  highTerm: number;
}

export interface EnhCostShareOfferInput {
  startDate?: Date | string;
  endDate?: Date | string;
  vehicles: VehicleInput[];
  optionTypeName: AdminConstants.OPTION_TYPE_NAMES;
  states: string[];
  regionCode?: string;
  // for apr
  tierTerms?: AprTierTermOfferInput[];

  // for lease
  tiers?: string[];
  terms?: string[] | number[];

  // for Misc
  tfsContractRequired?: boolean;
  tfsContracts?: Array<TfsContract>;
}

export interface EnhTfsCostShareOfferInput {
  endDate: string;
  optionTypeName: AdminConstants.OPTION_TYPE_NAMES;
  seriesProfileId: string;
  startDate: string;
}

export interface HandleResponseBase {
  showModal: boolean;
  isRemoving: boolean;
  isReplacing?: boolean;
  eligibleOfferId?: string;
  reason?: string;
}

export interface HandleEnhCostShareResponse extends HandleResponseBase {
  enhancedCostShare?: EnhancedCostShare;
}

export interface HandleEnhTfsCostShareResponse extends HandleResponseBase {
  enhancedTfsCostShare?: AdminModels.EnhancedTfsCostShare.EnhancedTfsCostShare;
}

const useOfferEligibility = () => {
  const {
    programDetailsStore: { enhancedCostShares },
    regionalCostShareStore: { geTfstCostSharesByType },
  } = useStores();

  const handleEnhCostShare = (offerInput: EnhCostShareOfferInput, offer?: OfferEligibilityFields): HandleEnhCostShareResponse => {
    const eligibilityInput: AdminModels.EnhancedCostShare.OfferInput = {
      endDate: dateFormatISOString(offerInput.endDate),
      startDate: dateFormatISOString(offerInput.startDate),
      vehicles: offerInput.vehicles,
      optionTypeName: offerInput.optionTypeName,
      states: offerInput.states,
      regionCode: offerInput.regionCode || '',
      tiers: offerInput.tiers || [],
      terms: offerInput.terms?.map(Number) || [],
      tierTerms: offerInput.tierTerms || [],
      tfsContractRequired: offerInput.tfsContractRequired,
      tfsContracts: offerInput.tfsContracts,
    };

    const existingEnhCostShareEligibilityRes = isOfferEligibleForEnhCostShare(
      eligibilityInput,
      enhancedCostShares.filter(costShare => costShare.offerId === offer?.enhCostShareOfferId) as AdminModels.EnhancedCostShare.EnhancedCostShare[],
    );

    if (existingEnhCostShareEligibilityRes.isEligible) {
      return {
        showModal: false,
        isRemoving: false,
        eligibleOfferId: enhancedCostShares.find(e => e.offerId === offer?.enhCostShareOfferId)?.offerId,
      };
    } else {
      const offerEligibilityRes = isOfferEligibleForEnhCostShare(eligibilityInput, enhancedCostShares as AdminModels.EnhancedCostShare.EnhancedCostShare[]);

      const eligibleOfferId = offerEligibilityRes.isEligible ? offerEligibilityRes.enhancedCostShareOfferId : undefined;
      const { showModal, isReplacing, isRemoving } = handleShowEnhCostShareModal(offerEligibilityRes.isEligible, eligibleOfferId, offer);

      return {
        showModal,
        eligibleOfferId: showModal ? eligibleOfferId : undefined,
        isRemoving: Boolean(isRemoving),
        reason: isReplacing ? '' : existingEnhCostShareEligibilityRes.reason,
        enhancedCostShare: enhancedCostShares.find(e => e.offerId === eligibleOfferId),
        isReplacing,
      };
    }
  };

  const handleShowEnhCostShareModal = (
    isEligible: boolean,
    eligibleOfferId?: string,
    offer?: OfferEligibilityFields,
  ): { showModal: boolean; isRemoving?: boolean; isReplacing?: boolean } => {
    if (!isEligible) {
      if (offer?.enhCostShareOfferId) {
        return { isRemoving: true, showModal: true };
      }

      return { isRemoving: false, showModal: false };
    } else {
      if (offer?.isEligibleForEnhCostShare) {
        if (eligibleOfferId !== offer.enhCostShareOfferId) {
          return { showModal: true, isReplacing: true };
        }

        return { showModal: false };
      }

      return { showModal: true };
    }
  };

  const handleShowEnhTfsCostShareModal = (
    isEligible: boolean,
    eligibleOfferId?: string,
    offer?: OfferEligibilityFields,
  ): { showModal: boolean; isRemoving?: boolean; isReplacing?: boolean } => {
    if (!isEligible) {
      if (offer?.enhTfsCostShareId) {
        return { isRemoving: true, showModal: true };
      }

      return { isRemoving: false, showModal: false };
    } else {
      if (offer?.isEligibleForEnhTfsCostShare) {
        if (eligibleOfferId !== offer.enhTfsCostShareId) {
          return { showModal: true, isReplacing: true };
        }

        return { showModal: false };
      }

      return { showModal: true };
    }
  };

  const handleEnhTfsCostShare = (input: EnhTfsCostShareOfferInput, offer?: OfferEligibilityFields): HandleEnhTfsCostShareResponse => {
    const tfsCostShares = geTfstCostSharesByType(input.seriesProfileId, input.optionTypeName) || [];
    const eligibilityRes = isOfferEligibleForEnhTfsCostShare(input, tfsCostShares);

    const eligibleOfferId = eligibilityRes.isEligible ? eligibilityRes.eligibleEntity.id : undefined;
    const { showModal, isReplacing, isRemoving } = handleShowEnhTfsCostShareModal(eligibilityRes.isEligible, eligibleOfferId, offer);

    return {
      showModal,
      eligibleOfferId: showModal ? eligibleOfferId : undefined,
      isRemoving: Boolean(isRemoving),
      enhancedTfsCostShare: tfsCostShares.find(e => e.id === eligibleOfferId),
      isReplacing,
      reason: !eligibilityRes.isEligible ? eligibilityRes.reason : '',
    };
  };

  return {
    handleEnhCostShare,
    handleEnhTfsCostShare,
  };
};

export default useOfferEligibility;
