import { observer } from 'mobx-react-lite';
import { Button, OATIcon, Table, TableContainer, useInputDelay, useToast } from 'oat-common-ui';
import { PenetrationDetails, SavePenetrationResult } from '../../gql/generated';
import RgnlAltModel from '../../pages/Summary/models/RgnlAltModel';
import useStores from '../../stores/useStores';
import getPriorMonthNameFromDate from '../../utils/getPriorMonthNameFromDate';
import NumberInput from '../NumberInput';
import PenRateErrors from './PenRateErrors';
import PenRateTableRow from './PenRateTableRow';
import styles from './styles.module.scss';
import usePenRatesSaveUtils from './usePenRatesSaveUtils';

type Props = {
  rgnlAlt: RgnlAltModel;
  isDisabled?: boolean;
  onSave: (penetrationDetails: PenetrationDetails[], res?: SavePenetrationResult) => void;
};

const PenRateTable = ({ rgnlAlt, onSave, isDisabled }: Props) => {
  const { savePenRate } = usePenRatesSaveUtils(rgnlAlt, onSave);
  const { setDelay } = useInputDelay();
  const { error } = useToast();
  const {
    summaryStore: { offering },
  } = useStores();

  const handleSavePenRate = () => {
    setDelay(async () => {
      try {
        await savePenRate();
      } catch (e) {
        error((e as Error).message);
      }
    });
  };

  const HeaderRow = () => {
    return (
      <thead>
        <tr>
          <th className={styles.priorPenRate}>{getPriorMonthNameFromDate(offering.startDate, 3)}</th>
          <th className={styles.priorPenRate}>{getPriorMonthNameFromDate(offering.startDate, 2)}</th>
          <th className={styles.priorPenRate}>{getPriorMonthNameFromDate(offering.startDate)}</th>
          <th className={styles.column}>
            Penetration Rate{' '}
            {!rgnlAlt.editPenRate && (
              <Button
                variant="text"
                id={`edit-pr-${rgnlAlt.id}-btn`}
                onClick={() => {
                  rgnlAlt.toggleEditPenRate();
                  handleSavePenRate();
                }}
                disabled={isDisabled}
                className={styles.iconBtn}
              >
                <OATIcon colorTheme="blue" pointer icon="edit" />
              </Button>
            )}
          </th>
          <th>Offer</th>
          <th className={styles.column}>
            Forecasted Sales{' '}
            {rgnlAlt.editPenRate && (
              <Button
                variant="text"
                id={`edit-fs-${rgnlAlt.id}-btn`}
                onClick={() => {
                  rgnlAlt.toggleEditPenRate();
                  handleSavePenRate();
                }}
                disabled={isDisabled}
                className={styles.iconBtn}
              >
                <OATIcon colorTheme="blue" pointer icon="edit" />
              </Button>
            )}
          </th>
          <th className={styles.estSalesCol}>Estimated Cost</th>
        </tr>
      </thead>
    );
  };

  return (
    <div className={styles.penRateTable}>
      <h4 className={styles.header}>Pen Rate Table</h4>
      <TableContainer className={styles.tableContainer}>
        <Table>
          <HeaderRow />
          <tbody>
            {rgnlAlt.cash.map(item => {
              return (
                <PenRateTableRow
                  key={`pt-cash-${item.id}`}
                  rgnlAlt={rgnlAlt}
                  item={item}
                  editPenRate={rgnlAlt.editPenRate}
                  offerDetails={item.name}
                  offerStyles={styles.cashRow}
                  onSave={handleSavePenRate}
                />
              );
            })}
            {rgnlAlt.apr.map(item => {
              return (
                <PenRateTableRow
                  key={`pt-apr-${item.id}-${item.tier}`}
                  rgnlAlt={rgnlAlt}
                  item={item}
                  editPenRate={rgnlAlt.editPenRate}
                  offerDetails={`${item.name} Tier ${item.tier}`}
                  offerStyles={styles.aprRow}
                  onSave={handleSavePenRate}
                />
              );
            })}
            {rgnlAlt.lease.map(item => {
              return (
                <PenRateTableRow
                  key={`pt-lease-${item.uid}-${item.term}`}
                  rgnlAlt={rgnlAlt}
                  item={item}
                  editPenRate={rgnlAlt.editPenRate}
                  offerDetails={`${item.name} ${item.term}mo`}
                  offerStyles={styles.leaseRow}
                  onSave={handleSavePenRate}
                />
              );
            })}
            {rgnlAlt.misc.map(item => {
              return (
                <PenRateTableRow
                  key={`pt-misc-${item.id}`}
                  rgnlAlt={rgnlAlt}
                  item={item}
                  editPenRate={rgnlAlt.editPenRate}
                  offerDetails={`${item.name}`}
                  offerStyles={styles.cashRow}
                  onSave={handleSavePenRate}
                />
              );
            })}

            {/* Totals */}
            <tr className={styles.footerRow}>
              <td colSpan={3} />
              <td>
                <NumberInput disabled value={rgnlAlt.totalPenRates} percentageSign />
              </td>
              <td />
              <td>
                <NumberInput disabled value={rgnlAlt.totalSales} />
              </td>
              <td />
            </tr>
          </tbody>
        </Table>
      </TableContainer>
      <PenRateErrors rgnlAlt={rgnlAlt} isSummary />
    </div>
  );
};

export default observer(PenRateTable);
