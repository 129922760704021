import { ReactNode, useEffect, useState } from 'react';
import apolloClient from '../../apolloClient';
import IpBreadCrumbs from '../../components/IpComponents/IpBreadCrumbs';
import { Status } from '../../constants/global';
import { GetSeriesProfileHierarchyDocument } from '../../gql/generated';
import useStores from '../../stores/useStores';
import LoadingPage from '../LoadingPage';

interface Props {
  seriesProfileIds: string[];
  children: ReactNode;
}

const SeriesProfilesData = ({ children, seriesProfileIds }: Props) => {
  const { summaryStore, userInfoStore } = useStores();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const filterProfilesByStatusDropdown = () => {
      let dropdownOption;

      switch (summaryStore.offering.status) {
        case Status.ATC_SUBMITTED:
        case Status.SUBMITTED:
          dropdownOption = summaryStore.dropdownOptions[0];
          break;
        case Status.MEETS_GUIDELINES:
          dropdownOption = summaryStore.dropdownOptions[1];
          break;
        case Status.ATC_NEEDS_REVISIONS:
        case Status.NEEDS_REVISIONS:
          dropdownOption = summaryStore.dropdownOptions[2];
          break;
        case Status.ATC_REVISED:
        case Status.REVISED:
          dropdownOption = summaryStore.dropdownOptions[3];
          break;
        default:
          dropdownOption = summaryStore.dropdownValue;
      }

      summaryStore.selectDropdownOption(dropdownOption);
    };

    (async () => {
      try {
        // Query seriesProfile Ids
        const promises = [];
        for (const seriesProfileId of seriesProfileIds) {
          const request = apolloClient.query({ query: GetSeriesProfileHierarchyDocument, variables: { seriesProfileId } });
          promises.push(request);
        }

        const response = await Promise.all(promises);
        if (response.some(item => !!item.error)) {
          setError(true);
        } else {
          // Process data
          summaryStore.setProfiles(response.map(item => item.data.seriesProfileHierarchyById));

          if (userInfoStore.isNational() && ![Status.DRAFT, Status.NO_RESPONSE].includes(summaryStore.offering.status as Status)) {
            filterProfilesByStatusDropdown();
          }
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(true);
      }
    })();
  }, [seriesProfileIds, summaryStore, userInfoStore]);

  if (loading || error) {
    return <LoadingPage breadCrumbs={IpBreadCrumbs(false)} error={!!error} />;
  }

  return <>{children}</>;
};

export default SeriesProfilesData;
