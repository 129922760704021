import { observer } from 'mobx-react-lite';
import { OfferTypes } from 'oat-common-ui';
import { ReactNode } from 'react';
import PenRateModel from '../../../../../models/PenRateModel';
import styles from '../styles.module.scss';
import MiscDetailsRow from './MiscDetailsRow';
import MiscHeader from './MiscHeader';
import MiscValue from './MiscValue';

interface Props {
  miscModels: PenRateModel[] | undefined;
  inputIsDisabled: boolean;
  showDates: (model: PenRateModel) => ReactNode;
  onToggle?: (model: PenRateModel) => void;
  onSave?: () => void;
}

const ExpMiscRow = ({ miscModels, inputIsDisabled, showDates, onSave, onToggle }: Props) => {
  return (
    <>
      {miscModels?.map((miscItem, i) => {
        return (
          <section key={`${OfferTypes.MISCELLANEOUS}-${miscItem.id}-${i}`} className={styles.containerList}>
            <MiscHeader miscModel={miscItem} showDates={showDates} onToggle={onToggle} />
            <MiscValue miscModel={miscItem} />
            <MiscDetailsRow miscModel={miscItem} inputIsDisabled={inputIsDisabled} onSave={onSave} />
          </section>
        );
      })}
    </>
  );
};

export default observer(ExpMiscRow);
