import { dateFormatISOString } from 'oat-common-ui';
import { FEATURE_OR_4154 } from '../../../constants/global';
import { AprDetailInput } from '../../../gql/generated';
import AprCardModel from '../../ProgramDetails/models/AprCardModel';
import AprTierModel from '../../ProgramDetails/models/AprTierModel';

type UserInfo = {
  isGst: boolean;
  isLexus: boolean;
  isSet: boolean;
};

const getAprDetailsFromTier = (aprCard: AprCardModel, tier: AprTierModel, userInfo: UserInfo): AprDetailInput[] => {
  const aprDetails: AprDetailInput[] = [];
  const firstTerm = tier.terms[0];

  tier.terms.forEach(term => {
    if (term.fields.isIncluded) {
      aprDetails.push({
        avgAmntFinanced: Number(userInfo.isSet ? term.fields.termAverageAmount : aprCard.fields.combinedAverageAmount),
        bonusCash: aprCard.fields.bonusCash,
        buyRate: term.fields.buyRate,
        distribution: Number(term.fields.termPen),
        endDate: dateFormatISOString(FEATURE_OR_4154 ? firstTerm.fields.endDate : term.fields.endDate),
        estimatedCost: tier.fields.estCost,
        tfsCost: tier.fields.tfsEstCost,
        enhTfsCost: tier.fields.enhTfsEstCost,
        subCashEstCost: Number(term.fields.subCashEstCost ?? 0),
        subCashTfsEstCost: Number(term.fields.subCashTfsEstCost ?? 0),
        subCashEnhTfsEstCost: Number(term.fields.subCashEnhTfsEstCost ?? 0),
        offerCost: tier.fields.offerCost,
        offerEarnings: tier.fields.offerEarnings,
        offerTfsCost: tier.fields.offerTfsCost,
        offerEnhTfsCost: tier.fields.offerEnhTfsCost,
        subCashOfferCost: tier.fields.subCashOfferCost,
        subCashOfferTfsCost: tier.fields.subCashOfferTfsCost,
        subCashOfferEnhTfsCost: tier.fields.subCashOfferEnhTfsCost,
        isAdvertised: term.isAdvertised,
        note: tier.fields.note,
        startDate: dateFormatISOString(FEATURE_OR_4154 ? firstTerm.fields.startDate : term.fields.startDate),
        subventionCash: Number(term.fields.subventionCash),
        subventionRate: term.fields.rate,
        tdaNote: tier.fields.tdaNote,
        term: Number(term.fields.term),
        tfsShare: Number(term.fields.tfsShare),
        tier: tier.tier,
        subCashTfsShare: Number(term.fields.subCashTfsShare),
        subCashTfsCostShareCap: Number(term.fields.subCashTfsCostShareCap),
      });
    }
  });

  return aprDetails;
};

export const processAprTierMap = (aprCard: AprCardModel, userInfo: UserInfo) => {
  const aprDetails: AprDetailInput[] = [];

  for (const tier of aprCard.tierMap.values()) {
    aprDetails.push(...getAprDetailsFromTier(aprCard, tier, userInfo));
  }

  return aprDetails;
};

export const transformAprCardData = (aprCard: AprCardModel, userInfo: UserInfo, forCreate = false) => {
  const {
    excludedStates,
    fields: { compatibileOffers, name, vehicles, accessoryCodes, specialEditionPackage, contestNumber },
    includedStates,
    isSpecialEdition,
    isStandalone,
    isForCreate,
  } = aprCard;

  return {
    ...(!isForCreate && { accessoryCodes, specialEditionPackage }),
    aprDetails: processAprTierMap(aprCard, userInfo),
    compatibilityList: compatibileOffers,
    excludedStates,
    includedStates,
    isSpecialEdition,
    isStandalone,
    name,
    specialEditionPackage: forCreate ? undefined : specialEditionPackage,
    vehicles,
    contestNumber,
  };
};
