import { Button, OATWrapper } from 'oat-common-ui';
import { useState } from 'react';
import useStores from '../../../../stores/useStores';
import AddSeriesProfileModal from './components/AddSeriesProfileModal';
import styles from './styles.module.scss';
import { Status } from '../../../../constants/global';

const AddSeriesProfileSection = () => {
  const {
    summaryStore: { offering },
  } = useStores();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <OATWrapper className={styles.seriesProfileSection}>
        <div className={styles.seriesProfileCtaWrapper}>
          <Button
            id="add-series-profile"
            variant="primary"
            onClick={() => {
              setIsModalOpen(true);
            }}
            disabled={offering.status === Status.MEETS_GUIDELINES}
          >
            Add Series Profile
          </Button>
        </div>
      </OATWrapper>
      {isModalOpen && <AddSeriesProfileModal offering={offering} onClose={() => setIsModalOpen(false)} />}
    </>
  );
};

export default AddSeriesProfileSection;
