import { observer } from 'mobx-react-lite';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';
import { ReactNode } from 'react';

interface Props {
  hasError?: boolean;
  children?: ReactNode;
  onClose: () => void;
  onSubmit: () => void;
}

const FundingSplitsModal = ({ hasError = false, children, onClose, onSubmit }: Props) => {
  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <Modal onClose={onClose} isOpen>
      <ModalHeader title="Funding Source Split" onClose={onClose} />
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button id="funding-split-modal" variant="primary" disabled={hasError} onClick={handleSubmit}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default observer(FundingSplitsModal);
