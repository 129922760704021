import clsx from 'clsx';
import { Button, OATIcon } from 'oat-common-ui';
import { HTMLAttributes, ReactNode } from 'react';
import NumberInput, { NumberInputProps } from '../../../../../../components/NumberInput';
import styles from './styles.module.scss';

export const LeaseInputWrapper = ({ className, children }: HTMLAttributes<HTMLDivElement>) => {
  return <div className={clsx(styles.leaseInputWrapper, className)}>{children}</div>;
};

export const LeaseInputLabel = ({ className, children }: HTMLAttributes<HTMLLabelElement>) => {
  return <label className={clsx(styles.leaseLabel, className)}>{children}</label>;
};

export const LeaseInputDescription = ({ className, children }: HTMLAttributes<HTMLElement>) => {
  return (
    <div>
      <span className={clsx(styles.leaseDescription, className)}>{children}</span>
    </div>
  );
};

interface Props extends NumberInputProps {
  label: ReactNode;
  lock?: string;
  field?: string;
  text?: ReactNode;
  wrapperClass?: string;
  onLockChange?: (field: string) => void;
}

const LeaseInput = ({
  id,
  label,
  lock,
  field,
  value,
  disabled,
  text,
  wrapperClass,
  percentageSign,
  dollarSign,
  className,
  wholeNumber,
  maxLength,
  allowNegative = false,
  error = false,
  onLockChange,
  onValueChange,
  ...rest
}: Props) => {
  const showLock = Boolean(onLockChange);

  const handleLock = (field: string | undefined) => {
    return () => {
      if (onLockChange && field) {
        onLockChange(field);
      }
    };
  };

  return (
    <LeaseInputWrapper className={clsx(wrapperClass, field === 'rcf' && styles.rcfWrapper)}>
      {/* Label */}
      {label && (
        <LeaseInputLabel className={clsx(field === 'rcf' && styles.rcfLabel)}>
          <span>{label}</span>
        </LeaseInputLabel>
      )}

      {/* Input */}
      <div className={clsx(showLock && styles.leaseInput, className)}>
        {showLock && (
          <Button id={`${field}Lock-${id}`} onClick={handleLock(field)} className={clsx(styles.target, lock === field && styles.lockedTarget)} disabled={disabled}>
            <OATIcon icon="target" colorTheme={lock === field ? 'blue' : 'white'} />
          </Button>
        )}
        <NumberInput
          id={id}
          className={clsx(styles.inputBase, lock === field && styles.lockedInputBase)}
          percentageSign={percentageSign}
          dollarSign={dollarSign}
          error={error}
          value={value}
          maxLength={maxLength}
          onValueChange={(val, src) => {
            if (onValueChange && src.source === 'event') {
              onValueChange(val, src);
            }
          }}
          wholeNumber={wholeNumber}
          allowNegative={allowNegative}
          disabled={disabled}
          {...rest}
        />
      </div>

      {/* Description/Defaults */}
      {text && <LeaseInputDescription>{text}</LeaseInputDescription>}
    </LeaseInputWrapper>
  );
};
export default LeaseInput;
