import { SaveFundingSourceSplitsInput } from '../../../../../../gql/generated';
import { assignStringValue } from '../../../../../../utils/assignValue';
import LeaseFormModel from '../../../../models/LeaseFormModel';
import { FundingSplitItem } from '../../../FundingSplitsModal/FundingSplitTerm';
import { calculateBlendedShare } from '../../../FundingSplitsModal/utils';

export const initialize = (leaseForm: LeaseFormModel) => {
  const splits: FundingSplitItem[] = [];
  const nationalStdRcf = leaseForm.inputs.isStandalone ? leaseForm.inputs.standardRegionalRcf : leaseForm.inputs.nationalRcf;

  // add standard rcf row
  const stdSplit = new FundingSplitItem();
  stdSplit.from = assignStringValue(leaseForm.inputs.standardRcf);
  stdSplit.to = assignStringValue(nationalStdRcf);
  stdSplit.tfsShare = '100';
  stdSplit.disableRateInput = true;
  stdSplit.disableTfsShare = true;
  splits.push(stdSplit);

  if (leaseForm.fundingSourceSplits.length > 0) {
    leaseForm.fundingSourceSplits.forEach((item, index) => {
      const newSplit = new FundingSplitItem();
      newSplit.from = assignStringValue(item.from);
      newSplit.to = assignStringValue(item.to);
      newSplit.tfsShare = assignStringValue(item.tfsShare);
      newSplit.disableRateInput = index === leaseForm.fundingSourceSplits.length - 1;

      splits.push(newSplit);
    });
  } else {
    const newSplit = new FundingSplitItem();
    newSplit.from = assignStringValue(nationalStdRcf);
    newSplit.to = assignStringValue(leaseForm.inputs.rcf);
    newSplit.tfsShare = assignStringValue(leaseForm.inputs.tfsShare);
    newSplit.disableRateInput = true;

    splits.push(newSplit);
  }

  return splits;
};

/**
 * Use tfsShare to update lease offer if no splits applied
 * Use tfsShare as blended share if splits applied
 * @param leaseForm
 * @param fundingSplits
 * @returns
 */
export const transformLeaseFundingSplit = (id: string, rev: string, leaseForm: LeaseFormModel, fundingSplits: FundingSplitItem[]) => {
  let tfsShare = Number(fundingSplits[1].tfsShare);
  const payload: SaveFundingSourceSplitsInput = {
    id,
    rev,
    fundingSourceSplits: [
      {
        tier: '1+',
        term: leaseForm.inputs.term,
        splits: [],
      },
    ],
  };

  // no splits added, just check tfsShare
  if (fundingSplits.length > 2) {
    fundingSplits.forEach((item, i) => {
      if (i >= 1) {
        payload.fundingSourceSplits[0].splits.push({
          from: Number(item.from),
          to: Number(item.to),
          tfsShare: Number(item.tfsShare),
        });
      }
    });

    // calculate blended share
    tfsShare = calculateBlendedShare(fundingSplits);
  }

  return {
    tfsShare,
    payload,
  };
};
