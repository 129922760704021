import { observer } from 'mobx-react-lite';
import { formatDollars } from 'oat-common-ui';
import TooltipList, { TooltipListItem } from '../../../../../../components/TooltipList/TooltipList';
import LeaseFormModel from '../../../../models/LeaseFormModel';

interface Props {
  leaseDetails: LeaseFormModel;
}

const TotalDealerGrossProceedsTooltipMessage = ({ leaseDetails }: Props) => {
  return (
    <>
      <TooltipList>
        <TooltipListItem>
          <span>Dealer Gross/Proceeds</span>
          <span>${formatDollars(leaseDetails.inputs.dealerGross)}</span>
        </TooltipListItem>
        <TooltipListItem symbol="+">
          <span>DAP</span>
          <span>${formatDollars(leaseDetails.inputs.dap)}</span>
        </TooltipListItem>
        <TooltipListItem symbol="+">
          <span>Holdback</span>
          <span>${formatDollars(leaseDetails.inputs.holdback)}</span>
        </TooltipListItem>
        <TooltipListItem symbol="+">
          <span>Finance Reserve</span>
          <span>${formatDollars(leaseDetails.inputs.financeReserve)}</span>
        </TooltipListItem>
      </TooltipList>

      <TooltipList divider>
        <TooltipListItem>
          <span>Total Dealer Gross/Proceeds</span>
          <span>${formatDollars(leaseDetails.inputs.totalDealerGross)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
};

export default observer(TotalDealerGrossProceedsTooltipMessage);
