import { ReactNode } from 'react';
import { ResidualValue, SetResidualValue, SetStandardRate, SetStandardRcf, StdAprRate, useGetRcfsRatesResidualsQuery, useGetSetRcfsRatesResidualsQuery } from '../../gql/generated';
import StdLeaseRcfs from '../../models/StdLeaseRcfs';
import LoadingPage from '../../pages/LoadingPage';
import useUserInfo from '../../utils/useUserInfo';
import IpBreadCrumbs from '../IpComponents/IpBreadCrumbs';

interface Props {
  brand: string;
  offeringYear: string;
  offeringMonth: string;
  offeringId: string;
  regionCode: number;
  children: ReactNode;
  onLoad: (stdLeaseRcfs: StdLeaseRcfs[], stdAprRates: StdAprRate[], residualValues: ResidualValue[]) => void;
  onSETLoad: (stdLeaseRcfs: SetStandardRcf[], stdAprRates: SetStandardRate[], residualValues: SetResidualValue[]) => void;
}

const GetRcfsRatesResidualsData = ({ brand, offeringYear, offeringMonth, offeringId, regionCode, children, onLoad, onSETLoad }: Props) => {
  const { isSETUser } = useUserInfo();
  const { loading, error, data } = useGetRcfsRatesResidualsQuery({
    variables: {
      brand: brand,
      offeringYear,
      offeringMonth,
      regionCode,
    },
    skip: isSETUser(),
  });

  const {
    loading: SETLoading,
    error: SETError,
    data: SETData,
  } = useGetSetRcfsRatesResidualsQuery({
    variables: {
      offeringId,
    },
    skip: !isSETUser(),
  });

  if (loading || error || SETLoading || SETError) {
    return <LoadingPage breadCrumbs={IpBreadCrumbs(false)} error={Boolean(error) || Boolean(SETError)} />;
  }

  if (data) {
    onLoad(data.stdLeaseRcfs as StdLeaseRcfs[], data.stdAprRates as StdAprRate[], data.residualValues as ResidualValue[]);
  }

  if (SETData) {
    // We cast it as the original types to imply that they have the same structure
    onSETLoad(SETData.setStandardRcfs as SetStandardRcf[], SETData.setStandardRates as SetStandardRate[], SETData.setResidualValues as SetResidualValue[]);
  }

  return <>{children}</>;
};

export default GetRcfsRatesResidualsData;
