import { observer } from 'mobx-react-lite';
import { OATIcon, useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import { scrollToTop } from '../../../components/AnchorScrollTo';
import IpBreadCrumbs from '../../../components/IpComponents/IpBreadCrumbs';
import MainFooter from '../../../components/MainFooter';
import MainHeader from '../../../components/MainHeader';
import SubHeader from '../../../components/SubHeader';
import { SCROLL_OFFSET_PROGRAM_DETAILS, Status } from '../../../constants/global';
import { useSaveEstCarryOverMutation, useUpdateOfferingStatusMutation } from '../../../gql/generated';
import useAnchorScrollTo from '../../../hooks/useAnchorScrollTo';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import useUserInfo from '../../../utils/useUserInfo';
import SummaryCosts from '../components/SummaryCosts';
import { getRegionalSummaryCtaStatuses, processStatusToSubmitRegional } from '../utils';
import NotesHeader from './ActionBar/NotesHeader';
import AddSeriesProfileSection from './AddSeriesProfileSection';
import SeriesProfileSection from './SeriesProfileSection';
import styles from './styles.module.scss';
import { useShowScrollToTop } from './useShowScrollToTop';

const RegionalSummary = () => {
  const {
    summaryStore: { offering, profiles, estCarryOver, updateOfferingRev, updateSummaryOfferingStatus, showAllOffers },
    userInfoStore: { isNational },
  } = useStores();
  const history = useHistory();
  const { scrollTo, region, period, parentOfferingId } = useUrlParams();
  const { error, success } = useToast();
  const { showScrollToTop } = useShowScrollToTop();
  const { isSETUser } = useUserInfo();
  const { setDelay } = useInputDelay();

  useAnchorScrollTo(scrollTo, SCROLL_OFFSET_PROGRAM_DETAILS);

  const [saveEstCarryOver] = useSaveEstCarryOverMutation();
  const [updateOfferingStatus] = useUpdateOfferingStatusMutation();

  const { showSubmitAllOffersToNationalBtn, showReconcileBtn, disableSubmitAllOffersToNational, showDownloadIncentivePeriodDocsBtn } = getRegionalSummaryCtaStatuses(
    offering,
    profiles,
  );

  const isDisabled = Boolean(isNational() && offering.isAtc);

  const updateCarryOver = async () => {
    try {
      const res = await trackPromise(saveEstCarryOver({ variables: { id: offering.id, rev: offering.rev, estCarryOver } }));
      updateOfferingRev(offering.id, res.data?.saveEstCarryOver.offering?.rev || '');
    } catch (e) {
      error((e as Error).message);
    }
  };

  const onSubmitAllOffersToNational = async () => {
    const statusToSubmit = processStatusToSubmitRegional(offering, isSETUser());

    try {
      const res = await trackPromise(updateOfferingStatus({ variables: { input: { offeringId: offering.id, offeringRev: offering.rev, status: statusToSubmit } } }));
      updateSummaryOfferingStatus(statusToSubmit);
      updateOfferingRev(offering.id, res.data?.updateOfferingStatus.offering?.rev || '');

      success('Offers have successfully been submitted for review.');

      setDelay(() => {
        history.push('/dashboard');
      });
    } catch (e) {
      error((e as Error).message);
      return;
    }
  };

  const onReconcileHandler = () => {
    history.push(`/reconcile/region/${region}/period/${period}`);
  };

  const needsRevisionsProfiles = profiles.filter(profile => profile.getSelectedRgnAlt()?.status === Status.NEEDS_REVISIONS);

  return (
    <section className={styles.regionalSummary}>
      <MainHeader
        headerTitle={offering.name}
        breadCrumbs={IpBreadCrumbs(isNational(), offering, region, undefined, undefined, undefined, parentOfferingId)}
        showRightContent
        rightContent={<SummaryCosts />}
      />
      <SubHeader profiles={profiles} updateCarryOver={updateCarryOver} offering={offering} />
      <NotesHeader offering={offering} />
      {showScrollToTop && (
        <span className={styles.scrollToBtn}>
          <OATIcon icon="angle-up" size={20} onClick={() => scrollToTop(true)} />
        </span>
      )}

      {/* div prevents the sp section background from flickering */}
      <div>
        {(!showAllOffers ? needsRevisionsProfiles : profiles).map(profile => {
          return <SeriesProfileSection key={profile.id} profile={profile} />;
        })}
        {!isDisabled && <AddSeriesProfileSection />}

        <MainFooter
          darkTheme
          isShowDownloadIncentivePeriodDocs={showDownloadIncentivePeriodDocsBtn}
          isShowSubmitAllOffersToNational={showSubmitAllOffersToNationalBtn}
          onSubmitAllOffersToNational={() => onSubmitAllOffersToNational()}
          isDisableSubmitAllOffersToNational={isDisabled || disableSubmitAllOffersToNational}
          isShowReconcile={showReconcileBtn}
          onReconcile={() => onReconcileHandler()}
        />
      </div>
    </section>
  );
};

export default observer(RegionalSummary);
