import { observer } from 'mobx-react-lite';
import { useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import IpBreadCrumbs from '../../../components/IpComponents/IpBreadCrumbs';
import MainFooter from '../../../components/MainFooter';
import MainHeader from '../../../components/MainHeader';
import { useCreateMiscOfferMutation } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import MiscCard from '../../ProgramDetails/components/MiscCard';
import CreateOfferContent from '../CreateOfferContent';
import CreateOfferCta from '../CreateOfferCta';
import { getMiscOfferInputByOptionTypeName } from './getMiscOfferInputByOptionTypeName';
import { useState } from 'react';
import { FEATURE_OR_2477 } from '../../../constants/global';
import EnhCostShareModal from '../../ProgramDetails/components/EnhCostShareModal';
import useOfferEligibility from '../../../hooks/useOfferEligibility';
import { AdminConstants } from 'oat-admin-common';

const CreateMiscComponent = () => {
  const {
    userInfoStore: { isNational },
    createOfferStore: { offering, seriesProfile, rgnlAlt, miscCard },
  } = useStores();

  const { region, period, profile, regalt } = useUrlParams();
  const history = useHistory();
  const { error } = useToast();

  const [showEnhTfsCostShareModal, setShowEnhTfsCostShareModal] = useState(false);
  const { handleEnhCostShare } = useOfferEligibility();

  const [createMiscOffer] = useCreateMiscOfferMutation();

  const { miscProps, hasRequiredFieldError } = getMiscOfferInputByOptionTypeName(miscCard, true);

  const handleCreateOffer = async (isEnhCostShareAccepted?: boolean) => {
    try {
      const { showModal, enhancedCostShare } = handleEnhCostShare({
        endDate: miscCard.fields.endDate ?? '',
        startDate: miscCard.fields.startDate ?? '',
        optionTypeName: miscCard.fields.type as AdminConstants.OPTION_TYPE_NAMES,
        regionCode: region,
        states: [],
        vehicles: miscCard.fields.includedVehicles,
        tfsContractRequired: miscCard.fields.tfsContractRequired,
        tfsContracts: miscCard.fields.tfsContracts,
      });

      if (showModal && isEnhCostShareAccepted === undefined) {
        setShowEnhTfsCostShareModal(true);
        return;
      }

      const foundEhancedCostShare = isEnhCostShareAccepted ? enhancedCostShare : undefined;

      const res = await trackPromise(
        createMiscOffer({
          variables: {
            input: {
              ...miscProps,
              rgnlAltId: rgnlAlt.id,
              enhCostShareOfferId: foundEhancedCostShare?.offerId,
              isEligibleForEnhCostShare: !!foundEhancedCostShare,
              isEnhCostShareAccepted,
              tfsShare: foundEhancedCostShare?.tfsCostShare ?? miscProps.tfsShare,
              tfsCostShareCap: foundEhancedCostShare?.tfsCostShareCap ?? miscProps.tfsCostShareCap,
            },
          },
        }),
      );

      if (res.data?.createMiscOffer.success) {
        const anchor = `misc-${res.data?.createMiscOffer.offer?.id}`;
        history.push(`/details/region/${region}/period/${period}/profile/${profile}/regalt/${regalt}?scrollTo=${anchor}`);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <>
      <MainHeader
        breadCrumbs={IpBreadCrumbs(isNational(), offering, offering.regionCode, seriesProfile, rgnlAlt, 'Create Miscellaneous Offer  ', '', false, profile)}
        headerTitle="Create Miscellaneous Offer"
      />
      <CreateOfferContent>
        <MiscCard card={miscCard} forCreate />
        <CreateOfferCta
          onClick={() => handleCreateOffer()}
          disabled={miscCard.hasError || miscCard.hasDateError || hasRequiredFieldError || miscCard.hasNoteError || miscCard.hasConquestCompetitorDetailsError || miscCard.hasTradeInAssitanceModelRestrictionsError}
        />
      </CreateOfferContent>
      <MainFooter />
      {FEATURE_OR_2477 && showEnhTfsCostShareModal && (
        <EnhCostShareModal
          onButtonPress={isConfirm => {
            handleCreateOffer(isConfirm);
          }}
        />
      )}
    </>
  );
};

export default observer(CreateMiscComponent);
