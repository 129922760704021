import { OfferTypes } from 'oat-common-ui';
import { ReactNode } from 'react';
import PenRateModel from '../../../../../models/PenRateModel';
import NotExpTableValues from '../NotExpTableValues';
import CashTableValues from './CashTableValues';

interface Props {
  cashModels: PenRateModel[] | undefined;
  showDates: (model: PenRateModel) => ReactNode | undefined;
}

const CashRow = ({ cashModels, showDates }: Props) => {
  return (
    <>
      {cashModels?.map((cashItem, i) => {
        return (
          <tr key={`${OfferTypes.CUSTOMER_CASH}-${cashItem.id}-${i}`}>
            <NotExpTableValues model={cashItem} />
            <CashTableValues cashModel={cashItem} showDates={showDates} />
          </tr>
        );
      })}
    </>
  );
};

export default CashRow;
