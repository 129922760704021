import { ReconcileResponse, SeriesProfile } from '../../../gql/generated';
import ProfileModel from '../../Summary/models/ProfileModel';

export const xformSeriesToProfiles = (reconcileData: ReconcileResponse) => {
  const profiles = reconcileData.reconcileSeriesResponses.map(series => {
    const profile = {
      created: '',
      createdBy: '',
      groups: [],
      id: series?.seriesProfileId,
      isComplete: false,
      isCustomSeries: false,
      isEarningsOnly: false,
      name: series?.seriesProfileName,
      note: '',
      offeringId: '',
      regionCode: '',
      rev: '',
      series: [],
      updated: '',
      updatedBy: '',
      vehicles: [],
    };
    return new ProfileModel(profile as SeriesProfile);
  });
  return profiles;
};
