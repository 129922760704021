import { ReactNode } from 'react';
import { Vehicle } from '../../../../../../../gql/generated';
import PenRateModel from '../../../../../models/PenRateModel';
import CostContainer from '../CostContainer';
import VehiclesList from '../VehiclesList';
import styles from './styles.module.scss';

interface Props {
  offerHeader: ReactNode;
  offerContent: ReactNode;
  model: PenRateModel;
  excludedVehicles: Vehicle[];
  includedVehicles: Vehicle[];
}

const OfferLayout = ({ offerHeader, offerContent, model, excludedVehicles, includedVehicles }: Props) => {
  return (
    <section className={styles.containerList}>
      {offerHeader}
      <div className={styles.marginLeft}>
        <div className={styles.containerList}>
          <table className={styles.table}>
            <tbody>{offerContent}</tbody>
          </table>
          <VehiclesList model={model} excludedVehicles={excludedVehicles} includedVehicles={includedVehicles} />
        </div>
        <CostContainer model={model} showEstimatedCost={false} />
      </div>
    </section>
  );
};

export default OfferLayout;
