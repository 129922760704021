import { makeAutoObservable } from 'mobx';
import validator from '../utils/validator';

class SettingsStore {
  dealerGross = '0';

  constructor() {
    makeAutoObservable(this);
  }

  setDealerGross = (value: string) => {
    this.dealerGross = value;
  };

  get dealerGrossError() {
    return validator(this.dealerGross, { required: true, min: 0 }) !== undefined;
  }
}

export default SettingsStore;
