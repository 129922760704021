import { observer } from 'mobx-react-lite';
import { formatDollars, formatRcf } from 'oat-common-ui';
import TooltipList, { TooltipListItem } from '../../../../../../components/TooltipList/TooltipList';
import useStores from '../../../../../../stores/useStores';
import LeaseFormModel from '../../../../models/LeaseFormModel';

interface Props {
  leaseDetails: LeaseFormModel;
  term: number;
}

const PnvsTooltipMessage = ({ leaseDetails, term }: Props) => {
  const {
    userInfoStore: { getTFSRateLabel },
  } = useStores();

  const tfsLabel = getTFSRateLabel();

  return (
    <>
      <TooltipList>
        <TooltipListItem>
          <span>(Gross Cap Cost</span>
          <span>${formatDollars(leaseDetails.inputs.grossCapCost)}</span>
        </TooltipListItem>
        <TooltipListItem symbol="+">
          <span>Residual Value Amount</span>
          <span>${formatDollars(leaseDetails.inputs.residualAmount)}</span>
        </TooltipListItem>
        <TooltipListItem symbol="-">
          <span>Down Payment)</span>
          <span>${formatDollars(leaseDetails.inputs.downPayment)}</span>
        </TooltipListItem>

        {leaseDetails.inputs.isStandalone ? (
          <TooltipListItem symbol="*">
            <span>(Standard {tfsLabel} RCF</span>
            <span>{formatRcf(leaseDetails.inputs.standardRegionalRcf)}</span>
          </TooltipListItem>
        ) : (
          <TooltipListItem symbol="*">
            <span>(National {tfsLabel} RCF</span>
            <span>{formatRcf(leaseDetails.inputs.nationalRcf)}</span>
          </TooltipListItem>
        )}

        <TooltipListItem symbol="-">
          <span>Regional Subvened RCF)</span>
          <span>{formatRcf(leaseDetails.inputs.rcf)}</span>
        </TooltipListItem>
        <TooltipListItem symbol="*">
          <span>Term</span>
          <span>{term}</span>
        </TooltipListItem>
        <TooltipListItem symbol="*">
          <span>(100% - TFS Cost Share)</span>
          <span>{100 - 1 * Number(leaseDetails.inputs.tfsShare)}%</span>
        </TooltipListItem>
        <TooltipListItem symbol="*">
          <span>(100% + Adjustment Factor)</span>
          <span>{100 + 1 * Number(leaseDetails.inputs.adjustmentFactor)}%</span>
          {}
        </TooltipListItem>
      </TooltipList>

      <TooltipList divider>
        <TooltipListItem>
          <span>Est. Buydown Cost</span>
          <span>${formatDollars(leaseDetails.inputs.estimatedCost)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
};

export default observer(PnvsTooltipMessage);
