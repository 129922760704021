import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Button, Checkbox, InlineInputLabel, Input } from 'oat-common-ui';
import { useMemo, useState } from 'react';
import AccordionItemModel from '../../../../components/Accordion/models/AccordionItemModel';
import AccordionModel from '../../../../components/Accordion/models/AccordionModel';
import createAccordionFromAccessories from '../../../../components/Accordion/utils/createAccordionFromAccessories';
import { Status } from '../../../../constants/global';
import { VehicleInput } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import ExclusionsModal from '../OfferExclusions/ExclusionsModal';
import VehicleCompabitibleInfo from '../VehicleCompatibleInfo';
import styles from './styles.module.scss';

type Props = {
  accessoryCodes?: string;
  className?: string;
  compatibileAccessories?: string[];
  forCreate: boolean;
  id: string;
  inputLabel?: string;
  isChecked: boolean;
  isLexus: boolean;
  label: string;
  onChange: (value: string) => void;
  onSelect: () => void;
  onSubmit: (compatibility: string[]) => void;
  value?: string;
  vehicles: VehicleInput[];
  disabled?: boolean;
};

const SpecialEdition = ({
  accessoryCodes,
  className,
  compatibileAccessories,
  forCreate,
  id,
  inputLabel,
  isChecked,
  isLexus,
  label,
  onChange,
  onSelect,
  onSubmit,
  value,
  vehicles,
  disabled,
}: Props) => {
  const { programDetailsStore } = useStores();
  const [openModal, setOpenModal] = useState(false);

  const accordion = useMemo(() => {
    if (compatibileAccessories?.length && !!vehicles.length) {
      return new AccordionModel(createAccordionFromAccessories(compatibileAccessories, vehicles[0], accessoryCodes));
    }

    return undefined;
  }, [compatibileAccessories, accessoryCodes, vehicles]);

  const handleOnSubmit = (isExclusion: boolean, items: AccordionItemModel[]) => {
    const checkedAccessories = items[0].items[0].items.filter(item => (isExclusion ? !item.checked : item.checked)).map(accessory => accessory.value);
    onSubmit(checkedAccessories);
    setOpenModal(!openModal);
  };

  const countLabel = `${accessoryCodes?.length ? accessoryCodes.split(',').length : '0'} Compatible Configurations`;

  const isDisabled = programDetailsStore.offering.status === Status.MEETS_GUIDELINES || disabled;

  return (
    <div className={cx(styles.specialEdition, className)}>
      <InlineInputLabel label={label} className={styles.label}>
        <Checkbox id={`special-edition-checkbox-${id}`} onChange={onSelect} isChecked={isChecked} className={styles.checkbox} isDisabled={isDisabled} />
      </InlineInputLabel>

      {isChecked && !forCreate && (
        <>
          {isLexus ? (
            <InlineInputLabel label={inputLabel} inputWrapperClass={styles.packageInput} className={styles.label}>
              <Input id={`special-edition-input-${id}`} value={value} onChange={e => onChange(e.currentTarget.value)} disabled={isDisabled} />
            </InlineInputLabel>
          ) : (
            <VehicleCompabitibleInfo
              title="Compatibile Accessories"
              content={
                <Button className={styles.countLabelBtn} variant="text" onClick={() => accordion && accordion?.items.length > 0 && setOpenModal(!openModal)} disabled={isDisabled}>
                  {countLabel}
                </Button>
              }
            />
          )}
          {openModal && accordion && (
            <ExclusionsModal
              accordion={accordion}
              id={`${id}-specialEdition`}
              isExclusionsActive={false}
              labelExcluded="Accessory Configurations"
              labelIncluded="Accessory Configurations"
              onClose={() => setOpenModal(!openModal)}
              onSubmit={handleOnSubmit}
              ctaLabel="Configurations"
            />
          )}
        </>
      )}
    </div>
  );
};

export default observer(SpecialEdition);
