import clsx from 'clsx';
import styles from './styles.module.scss';

interface Props {
  status: string;
  statusClass?: string;
  count: number;
  subText?: string;
}

const StatusItem = ({ status, subText, count, statusClass }: Props) => {
  return (
    <div className={styles.row}>
      <div className={clsx(styles.count, statusClass)}>{count}</div>
      <div>
        <div className={styles.status}>{status}</div>
        <div className={styles.subText}>{subText}</div>
      </div>
    </div>
  );
};

export default StatusItem;
