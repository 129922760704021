import { AdminConstants, getDefaultTfsShare } from 'oat-admin-common';
import { dateStringToDate } from 'oat-common-ui';
import { AprTerms } from '../../../constants/global';
import { Offering, RgnlAlt, SeriesProfile } from '../../../gql/generated';
import { getAllStatesForRegion } from '../../ProgramDetails/components/LeaseCard/components/VehicleInfo/utils';
import AprCardModel from '../../ProgramDetails/models/AprCardModel';
import AprTermModel from '../../ProgramDetails/models/AprTermModel';
import AprTierModel from '../../ProgramDetails/models/AprTierModel';
import { getCompatibilityListByOfferType } from '../utils/getCompatibilityListByOfferType';

type CreateAprData = { tiers: string[]; startDate: string; endDate: string; seriesProfile: SeriesProfile; region: string; offering: Offering; rgnlAlt: RgnlAlt };

const createAprCard = (data: CreateAprData) => {
  const { tiers, startDate, endDate, seriesProfile, region, offering, rgnlAlt } = data;

  const card = new AprCardModel({ seriesProfile });

  card.tiersList = tiers;
  card.selectedTier = tiers[0];
  card.isForCreate = true;
  card.isStandalone = true;
  card.includedStates = rgnlAlt.states ?? getAllStatesForRegion(region);

  card.setCompatibileOffers(getCompatibilityListByOfferType(AdminConstants.OPTION_TYPE_NAMES.APR));

  tiers.forEach(tier => {
    const terms = AprTerms.map(term => {
      const item = new AprTermModel();
      // default rate is set within CreateAprComponent setDefaultRatesToTerms() to avoid multiple API calls.

      const tfsShare = getDefaultTfsShare(AdminConstants.OPTION_TYPE_NAMES.APR, region, term, !!offering.useOldCostShareForLexus).toString();

      item.fields.startDate = dateStringToDate(startDate);
      item.fields.endDate = dateStringToDate(endDate);
      item.fields.nationalStartDate = dateStringToDate(startDate);
      item.fields.nationalEndDate = dateStringToDate(endDate);
      item.fields.term = term;
      item.fields.termPen = '0';
      item.fields.tfsShare = tfsShare;
      item.fields.nationalTfsShare = tfsShare;
      item.isAdvertised = term === 72;

      return item;
    });

    const tierModel = new AprTierModel(tier, '', '');
    tierModel.terms = terms;
    card.tierMap.set(tier, tierModel);
  });

  return card;
};

export default createAprCard;
