const AnchorScrollTo = (eID: string, userOffset?: number) => {
  /* private variables */
  const scrollOffset = 114; // This is the height of the header

  if (eID) {
    setTimeout(() => {
      const element = document.getElementById(`${eID}`);
      const offset = userOffset ? userOffset : scrollOffset;
      const elementOffset = element?.offsetTop;

      // This is a check where element.offsetTop returns null
      if (elementOffset) {
        let destination = eID === 'bottom' ? document.documentElement.clientHeight - window.innerHeight : elementOffset;
        destination -= offset;
        window.scrollTo({
          top: destination,
          behavior: 'smooth',
        });
      }
    }, 500);
  }
};

export const scrollToTop = (smoothScroll = false) => {
  if (smoothScroll) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  } else {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
};

export default AnchorScrollTo;
