import { AdminConstants } from 'oat-admin-common';
import { BreadCrumbType } from 'oat-common-ui';
import { Link } from 'react-router-dom';
import { Offering, RgnlAlt, SeriesProfile } from '../../../gql/generated';

/**
 * Link output
 * /dashboard/<periodLink>/<regionPeriodLink>/<endLink>
 *
 * @param isNational
 * @param offering
 * @param region
 * @param seriesProfile
 * @param rgnlAlt
 * @param createText
 * @param reconcile
 * @returns
 */

const { REGIONS } = AdminConstants;

const IpBreadCrumbs = (
  isNational: boolean,
  offering?: Offering,
  region?: string,
  seriesProfile?: SeriesProfile,
  rgnlAlt?: RgnlAlt,
  createText?: string,
  parentOfferingId?: string,
  reconcile = false,
  scrollToProfile?: string,
) => {
  const breadCrumbs: BreadCrumbType[] = [
    {
      name: '',
      component: <Link to={`/dashboard`}>Dashboard</Link>,
    },
  ];

  periodLink(isNational, breadCrumbs, region, offering, seriesProfile, parentOfferingId, reconcile, scrollToProfile);
  regionPeriodLink(isNational, breadCrumbs, region, offering, seriesProfile); // region period link (national only)
  endLink(breadCrumbs, region, offering, seriesProfile, rgnlAlt, createText, reconcile);

  return breadCrumbs;
};

const periodLink = (
  isNational: boolean,
  breadCrumbs: BreadCrumbType[],
  region?: string,
  offering?: Offering,
  seriesProfile?: SeriesProfile,
  parentOfferingId?: string,
  reconcile = false,
  scrollToProfile?: string,
) => {
  if (offering && offering.id) {
    // national link
    if (isNational && region) {
      breadCrumbs.push({ name: '', component: <Link to={`/regionStatus/period/${parentOfferingId}`}>{offering.name}</Link> });
      // regional link
    } else if (!isNational && (seriesProfile || reconcile)) {
      const scrollTo = scrollToProfile ? `?scrollTo=${scrollToProfile}` : '';
      breadCrumbs.push({ name: '', component: <Link to={`/summary/region/${region}/period/${offering.id}${scrollTo}`}>{offering.name}</Link> });
    } else {
      breadCrumbs.push({ name: offering.name });
    }
  }
};

const regionPeriodLink = (isNational: boolean, breadCrumbs: BreadCrumbType[], region?: string, offering?: Offering, seriesProfile?: SeriesProfile) => {
  const regionName = REGIONS.find(item => item.code === region)?.name || '';

  if (isNational && region) {
    if (seriesProfile && offering && offering.id) {
      breadCrumbs.push({ name: '', component: <Link to={`/summary/region/${region}/period/${offering.id}`}>{regionName}</Link> });
    } else {
      breadCrumbs.push({ name: regionName });
    }
  }
};

const endLink = (breadCrumbs: BreadCrumbType[], region?: string, offering?: Offering, seriesProfile?: SeriesProfile, rgnlAlt?: RgnlAlt, createText?: string, reconcile = false) => {
  // create text
  if (createText && rgnlAlt && offering && seriesProfile) {
    breadCrumbs.push({ name: '', component: <Link to={`/details/region/${region}/period/${offering.id}/profile/${seriesProfile.id}/regalt/${rgnlAlt.id}`}>{rgnlAlt.name}</Link> });
    breadCrumbs.push({ name: createText });
  } // program details
  else if (seriesProfile && seriesProfile.id) {
    breadCrumbs.push({ name: seriesProfile.name });
  } // reconcile
  else if (createText && reconcile) {
    breadCrumbs.push({ name: createText });
  }
};

export default IpBreadCrumbs;
