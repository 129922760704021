import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { BlockUi, Button } from 'oat-common-ui';
import useStores from '../../../../stores/useStores';
import OfferBody, { OfferBodyLeft } from '../../../ProgramDetails/components/OfferBody';
import CreateLeaseOfferDetails from '../CreateLeaseOfferDetails';
import commonStyles from '../common.module.scss';
import EnterVin from './components/EnterVin';
import FinalSelection from './components/FinalSelection';
import SelectTrim from './components/SelectTrim';
import styles from './styles.module.scss';

const CreateAdLease = () => {
  const {
    createLeaseStore: { blockUIText, advertisedData, setTab },
  } = useStores();

  const handleTab = (tab: number) => {
    return () => {
      advertisedData.setTab(tab);
    };
  };

  const handleToNonAdvertised = () => {
    return () => {
      advertisedData.setError();
      setTab(2);
    };
  };

  return (
    <>
      <BlockUi blocking={Boolean(blockUIText)} title={blockUIText} />
      <OfferBody>
        <OfferBodyLeft className={styles.leftSection}>
          <CreateLeaseOfferDetails />
        </OfferBodyLeft>
        <section className={styles.rightSection}>
          <div className={styles.tabs}>
            <div>
              <Button className={clsx(styles.tabBtn, advertisedData.tab === 1 && styles.active)} onClick={handleTab(1)}>
                Select Trim
              </Button>
              <Button className={clsx(styles.tabBtn, advertisedData.tab === 2 && styles.active)} onClick={handleTab(2)}>
                Enter VIN
              </Button>
            </div>

            <Button className={commonStyles.offerHeaderLink} onClick={handleToNonAdvertised()} variant="text">
              Make Non-Advertised
            </Button>
          </div>

          <div className={styles.tabContent}>
            {advertisedData.showSelectTrimFlow() && <SelectTrim />}
            {advertisedData.showEnterVinFlow() && <EnterVin />}
            {advertisedData.showFinalSelectionFlow() && <FinalSelection />}
          </div>
        </section>
      </OfferBody>
    </>
  );
};

export default observer(CreateAdLease);