import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import DollarDisplay from '../../../../components/DollarDisplay';
import DollarDisplayCosts from '../../../../components/DollarDisplayCosts';
import useStores from '../../../../stores/useStores';
import useGetTfsLabel from '../../../../utils/useGetTfsLabel';
import { BalanceTooltip, CostTooltip, EarningsTooltip, TfsCostTooltip } from './tooltips';

const SummaryCosts = () => {
  const {
    summaryStore: { allProfiles },
    offeringCostsStore: { raCosts, periodEarnings, periodCost, periodBalance, periodTfsCost, periodEnhTfsCost },
  } = useStores();
  const [showTfsEnhanced, setShowTfsEnhanced] = useState(false);
  const tfsLabel = useGetTfsLabel();
  const tfsCostLabel = showTfsEnhanced ? `Enhanced ${tfsLabel} Cost` : `Total ${tfsLabel} Cost`;

  const filteredProfiles = allProfiles.filter(profile => !profile.isEarningsOnly);
  const periodTfsCostDisplay = showTfsEnhanced ? periodEnhTfsCost : periodTfsCost;

  return (
    <>
      <DollarDisplayCosts
        id="summary-costs"
        leftContent={
          <DollarDisplay
            id={`tfs-cost`}
            value={periodTfsCostDisplay}
            lightText
            tooltip={<TfsCostTooltip profiles={allProfiles} raCosts={raCosts} periodCost={periodTfsCostDisplay} enhanced={showTfsEnhanced} />}
            showTooltip
            showToggle
            rightBorder
            toggleSelected={showTfsEnhanced}
            onToggle={() => setShowTfsEnhanced(!showTfsEnhanced)}
            label={tfsCostLabel}
          />
        }
        earnings={periodEarnings}
        earningsTooltip={<EarningsTooltip profiles={allProfiles} raCosts={raCosts} periodCost={periodEarnings} />}
        earningsLabel="Est. Period Earnings"
        cost={periodCost}
        costLabel="Est. Period Cost"
        costTooltip={<CostTooltip profiles={filteredProfiles} raCosts={raCosts} periodCost={periodCost} />}
        balance={periodBalance}
        balanceLabel="Est. Period Balance"
        balanceTooltip={<BalanceTooltip profiles={allProfiles} raCosts={raCosts} periodCost={periodBalance} />}
        showBalanceTooltip
        lightText
      />
    </>
  );
};

export default observer(SummaryCosts);
