import { AdminConstants } from 'oat-admin-common';
import { DropdownItem, uuidv4 } from 'oat-common-ui';
import { SetContestNumbers } from '../gql/generated';
import SETContestNumber, { SETContestNumbersByType } from '../models/SETContestNumber';
import ProfileModel from '../pages/Summary/models/ProfileModel';

const buildNumbersByType = (setNumbers: SETContestNumbersByType, contestNumArray: SETContestNumbersByType[]) => {
  const numbers: SETContestNumber[] = [];
  setNumbers.numbers?.forEach(number => {
    if (number) {
      const contestNumber = new SETContestNumber(number);
      numbers.push(contestNumber);
    }
  });

  const type = {
    optionType: setNumbers.optionType,
    numbers,
  };
  contestNumArray.push(type);
};

export const buildContestNumbers = (data: SETContestNumbersByType[], offeringId: string, isProgramDetails: boolean, profiles?: ProfileModel[]) => {
  const contestNumbersByType: SETContestNumbersByType[] = [];
  const uniqueOffers: string[] = [];

  if (!isProgramDetails) {
    profiles?.forEach(profile => {
      if (profile.rgnlAlts.filter(rgnlAlt => rgnlAlt.cash.length > 0) && !uniqueOffers.includes(AdminConstants.OPTION_TYPE_NAMES.CUSTOMER_CASH)) {
        uniqueOffers.push(AdminConstants.OPTION_TYPE_NAMES.CUSTOMER_CASH);
      }
      if (profile.rgnlAlts.filter(rgnlAlt => rgnlAlt.apr.length > 0) && !uniqueOffers.includes(AdminConstants.OPTION_TYPE_NAMES.APR)) {
        uniqueOffers.push(AdminConstants.OPTION_TYPE_NAMES.APR);
      }
      if (profile.rgnlAlts.filter(rgnlAlt => rgnlAlt.lease.length > 0) && !uniqueOffers.includes(AdminConstants.OPTION_TYPE_NAMES.LEASE)) {
        uniqueOffers.push(AdminConstants.OPTION_TYPE_NAMES.LEASE);
      }
      profile.rgnlAlts.forEach(rgnlAlt => {
        rgnlAlt.misc.forEach(offer => {
          if (!uniqueOffers.includes(offer.optionTypeName)) {
            uniqueOffers.push(offer.optionTypeName);
          }
        });
      });
    });

    const allUniqueOffers = [...new Set(uniqueOffers)];
    allUniqueOffers.forEach(offer => {
      const offerAvailable = data.length > 0 ? data.filter(item => item.optionType === offer) : [];
      if (offerAvailable.length > 0) {
        buildNumbersByType(offerAvailable[0], contestNumbersByType);
      } else {
        const type = {
          optionType: offer,
          numbers: [
            {
              id: '',
              rev: '',
              isDefault: true,
              optionTypeName: offer,
              offeringId,
              number: '',
              uid: uuidv4(),
              hasError: false,
            },
          ],
        };
        contestNumbersByType.push(type);
      }
    });
  }

  if (isProgramDetails && data.length) {
    data.forEach(contestNumbers => {
      buildNumbersByType(contestNumbers, contestNumbersByType);
    });
  }

  return contestNumbersByType;
};

export const buildContestNumberDropdownList = (contestNumbers: SETContestNumber[]) => {
  const newItems: DropdownItem[] = [];
  contestNumbers.forEach(value => {
    newItems.push({ label: value.number, value: value.id });
  });
  newItems.push({ value: ' New Contest Number', label: ' New Contest Number', showAddBtn: true });

  return newItems;
};

export const invalidInput = (contestNumbersByType: SETContestNumbersByType[], value: string) => {
  let invalid = false;
  contestNumbersByType.forEach(type => {
    type.numbers.forEach(item => {
      if (item.number === value) {
        invalid = true;
      }
    });
  });
  return invalid;
};

export const xformSETContestNumbersResponse = (data: SetContestNumbers[], originalNumbersByType: SETContestNumbersByType[]) => {
  originalNumbersByType.forEach(type => {
    const filtered = data.filter(contestNumbers => contestNumbers.optionTypeName === type.optionType).map(number => new SETContestNumber(number));
    type.numbers = filtered;
  });

  return originalNumbersByType;
};
