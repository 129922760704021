import { FundingSplitItem } from './FundingSplitTerm';

export const calculateBlendedShare = (fundingSplits: FundingSplitItem[], hasStandardRate = true) => {
  let sum = 0;
  let diffSum = 0;

  const splits = hasStandardRate ? fundingSplits.slice(1) : fundingSplits;

  splits.forEach(item => {
    const diff = Number(item.from) - Number(item.to);
    sum += diff * (Number(item.tfsShare) / 100.0);
    diffSum += diff;
  });

  return Number(((sum / diffSum) * 100.0).toFixed(2));
};
