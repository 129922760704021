import { dateFormatISOString } from 'oat-common-ui';
import getDateParts from './getDateParts';
import getMonthNameFromDate from './getMonthNameFromDate';

type DateType = Date | string | undefined;

export const handleEnhancedDates = (startDate: DateType, endDate: DateType, defaultStartDate: DateType, defaultEndDate: DateType, isShort = false, forCreate = false) => {
  const formatDefaultStartDate = dateFormatISOString(defaultStartDate);
  const formatDefaultEndDate = dateFormatISOString(defaultEndDate);
  const formatStartDate = dateFormatISOString(startDate);
  const formatEndDate = dateFormatISOString(endDate);

  if ((formatDefaultStartDate === formatStartDate && formatDefaultEndDate === formatEndDate) || forCreate) {
    return undefined;
  }

  if (isShort) {
    const { day: startDay } = getDateParts(formatStartDate);
    const { day: endDay } = getDateParts(formatEndDate);
    return `${getMonthNameFromDate(formatStartDate, true)} ${startDay} - ${getMonthNameFromDate(formatEndDate, true)} ${endDay}`;
  }

  return `${formatStartDate} - ${formatEndDate}`;
};
