import { makeAutoObservable } from 'mobx';
import { AdminConstants, AdminModels, getCompatibilityForOfferType, getDefaultTfsShare } from 'oat-admin-common';
import { uuidv4 } from 'oat-common-ui';
import { FEATURE_OR_2477, tierOrder } from '../../../constants/global';
import { CombinedOffers, EnhancedCostShare, LeaseDetails, LeaseOfferDetails, Offer, Offering, Vehicle, VehicleInput } from '../../../gql/generated';
import { assignBooleanValue, assignStringValue, defaultBooleanToUndefined } from '../../../utils/assignValue';
import { checkRcfSubVentionCashCostShare } from '../../../utils/checkRcfSubVentionCashCostShare';
import { getHigherTermUpToLeases } from '../../../utils/getHigherTermUpToLeases';
import { getIncludedExcludedVehicles } from '../../../utils/getIncludedExcludedVehicles';
import { getLeaseCurrentTerm } from '../../../utils/getLeaseCurrentTerm';
import { getLeaseLowerTerms } from '../../../utils/getLeaseLowerTerms';
import transformLeaseDetails, { getBaseLeaseDetails, transformLeaseCard } from '../components/LeaseCard/LeaseAdForm/transformLeaseDetails';
import { getDefaultLeaseTfsShare } from '../components/LeaseCard/utils/getDefaultLeaseTfsShare';
import { setLeaseExampleInputs } from './LeaseFormInputs';
import LeaseFormModel from './LeaseFormModel';
import LeaseTermModel from './LeaseTermModel';
import ResidualValue from './ResidualValue';
import { findNationalLeaseByTerm } from './leaseUtils';

const { RegionCodes, DEFAULT_MILEAGE_TOYOTA, DEFAULT_MILEAGE_LEXUS } = AdminConstants;

interface EnhancedCostShareFields {
  isModalConfirmed?: boolean;
  isNotificationConfirmed: boolean;
  isReplacing: boolean;
  isSaveVehicles?: boolean;
  offerId?: string;
  removingReason?: string;
  showModal: boolean;
}
class LeaseCardModel {
  uid = uuidv4();
  leaseTerms: LeaseTermModel[] = [];
  terms: Array<number> = [];
  term = 36;
  tier = '1+';
  minTerm = 24;
  maxTerm = 72;
  isInclusions = false;
  id = '';
  rev = '';
  parentId = '';
  name = '';
  contestNumber = '';
  contestNumberOfferId = '';
  isDuplicate = false;
  isMultiSeries = false;
  isGST = false;
  isSET = false;
  isLexus = false;
  isSpecialEdition = false;
  specialEditionPackage = '';
  accessoryCodes = '';
  compatabilityList: string[] = [];
  compatibility: AdminModels.Compatibility | undefined = {
    defaultTypes: [],
    incompatibleTypes: [],
    optionalTypes: [],
  };
  includedVehicles: VehicleInput[] = [];
  excludedVehicles: VehicleInput[] = [];
  excludedStates: string[] = [];
  includedStates: string[] = [];
  nationalLeases: LeaseDetails[] = []; // See addOddTermLease
  nationalVehicles: Vehicle[] = [];
  optionTierType: string = '';
  defaulMileage = DEFAULT_MILEAGE_TOYOTA;
  regionCode = '';
  isNationalAndIsAtc = false;

  // Existing data from WS
  isEligibleForEnhCostShare?: boolean = undefined;
  isEnhCostShareAccepted?: boolean = undefined;
  isEnhCostShareUpdated?: boolean = undefined;
  isEnhCostShareRemoved?: boolean = undefined;
  enhCostShareOfferId?: string = undefined;
  enhancedCostShare?: EnhancedCostShare = undefined;

  isEligibleForEnhTfsCostShare?: boolean = undefined;
  isEnhTfsCostShareAccepted?: boolean = undefined;
  isEnhTfsCostShareUpdated?: boolean = undefined;
  enhTfsCostShareId?: string = undefined;
  enhancedTfsCostShare?: AdminModels.EnhancedTfsCostShare.EnhancedTfsCostShare = undefined;

  // UI fields for save user modal action
  enhancedCostFields: EnhancedCostShareFields = {
    isModalConfirmed: undefined,
    isNotificationConfirmed: false,
    isReplacing: false,
    isSaveVehicles: false,
    offerId: undefined,
    removingReason: undefined,
    showModal: false,
  };

  // UI fields for save user modal action
  enhancedTfsCostFields: EnhancedCostShareFields = {
    isModalConfirmed: undefined,
    isNotificationConfirmed: false,
    isReplacing: false,
    offerId: undefined,
    removingReason: undefined,
    showModal: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  switchTerm = (term: number, tier: string) => {
    this.term = term;
    this.tier = tier;
  };

  /**
   *
   * @param offer
   * @param regionCode
   * @param isLexus
   * @param initialTerm
   * @param contestNumbers currently not being passed in
   * @param residuals currently being passed in LeaseContextMenu for reset to national
   */
  setLeases = (offer: CombinedOffers, regionCode: string, isLexus: boolean, initialTerm = 36, residuals?: ResidualValue[]) => {
    if (offer && offer.regional.leaseDetails) {
      let leaseTerms: LeaseTermModel[] = [];
      const terms = new Set<number>();
      this.isDuplicate = Boolean(offer.regional.isDuplicate);
      this.isGST = regionCode === RegionCodes.GST;
      this.isSET = regionCode === RegionCodes.SET;
      this.regionCode = regionCode;
      this.isLexus = isLexus;
      this.compatabilityList = offer.regional.compatibilityList;
      this.compatibility = getCompatibilityForOfferType(AdminConstants.OPTION_TYPE_NAMES.LEASE);
      this.includedStates = offer.regional.includedStates || [];
      this.excludedStates = offer.regional.excludedStates || [];
      this.setVehicles(offer.regional.vehicles);
      this.parentId = assignStringValue(offer?.regional.parentId);
      this.optionTierType = offer.regional.optionTierType ?? '';
      this.defaulMileage = isLexus ? DEFAULT_MILEAGE_LEXUS : DEFAULT_MILEAGE_TOYOTA;
      this.nationalVehicles = offer.national?.vehicles || [];

      // Each Lease Detail is based on Term. This loop will create a LeaseForm obj per term.
      offer.regional.leaseDetails.forEach(regionalDetails => {
        if (!regionalDetails) {
          return;
        }
        terms.add(regionalDetails.highTerm);

        this.nationalLeases = offer.national?.leaseDetails ?? this.nationalLeases;
        const nationalDetails = findNationalLeaseByTerm(this.nationalLeases, regionalDetails.highTerm);

        const leaseTerm = new LeaseTermModel();
        leaseTerm.setForms(this, offer.regional, regionalDetails, nationalDetails, residuals);
        leaseTerms.push(leaseTerm);

        leaseTerms = leaseTerms.sort((a, b) => {
          return tierOrder[a.tier] - tierOrder[b.tier];
        });
      });

      this.id = offer.regional.id;
      this.rev = offer.regional.rev;
      this.name = offer.regional.name;
      this.accessoryCodes = assignStringValue(offer.regional.accessoryCodes);
      this.isSpecialEdition = offer.regional.isSpecialEdition;
      this.specialEditionPackage = assignStringValue(offer.regional.specialEditionPackage);
      this.terms = Array.from(terms);
      this.term = this.terms.includes(initialTerm) ? initialTerm : Math.max(...this.terms);
      this.minTerm = Math.min(...this.terms);
      this.maxTerm = Math.max(...this.terms);
      this.contestNumber = offer.regional.contestNumber || '';
      this.contestNumberOfferId = offer.regional.contestNumberOfferId || '';
      this.leaseTerms = leaseTerms;
      this.isEligibleForEnhCostShare = defaultBooleanToUndefined(offer.regional.isEligibleForEnhCostShare);
      this.enhCostShareOfferId = assignStringValue(
        offer.regional.enhCostShareOfferId,
        typeof this.isEligibleForEnhCostShare === 'boolean' && !this.isEligibleForEnhCostShare ? undefined : '',
      );
      this.isEnhCostShareAccepted = defaultBooleanToUndefined(offer.regional.isEnhCostShareAccepted);
      this.isEnhCostShareUpdated = defaultBooleanToUndefined(offer.regional.isEnhCostShareUpdated);
      this.isEnhCostShareRemoved = defaultBooleanToUndefined(offer.regional.isEnhCostShareRemoved);

      this.enhTfsCostShareId = assignStringValue(
        offer.regional.enhTfsCostShareId,
        typeof this.isEligibleForEnhTfsCostShare === 'boolean' && !this.isEligibleForEnhTfsCostShare ? undefined : '',
      );
      this.isEligibleForEnhTfsCostShare = defaultBooleanToUndefined(offer.regional.isEligibleForEnhTfsCostShare);
      this.isEnhTfsCostShareAccepted = defaultBooleanToUndefined(offer.regional.isEnhTfsCostShareAccepted);
      this.isEnhTfsCostShareUpdated = defaultBooleanToUndefined(offer.regional.isEnhTfsCostShareUpdated);
    }
  };

  updateEnhancedCostShareField = <T extends keyof EnhancedCostShareFields, V extends EnhancedCostShareFields[T]>(field: T, value: V) => {
    this.enhancedCostFields[field] = value;
  };

  updateEnhancedTfsCostShareField = <T extends keyof EnhancedCostShareFields, V extends EnhancedCostShareFields[T]>(field: T, value: V) => {
    this.enhancedTfsCostFields[field] = value;
  };

  resetEnhancedCostShareFields = () => {
    this.enhancedCostFields = {
      isModalConfirmed: undefined,
      isNotificationConfirmed: false,
      isReplacing: false,
      isSaveVehicles: false,
      offerId: undefined,
      removingReason: undefined,
      showModal: false,
    };

    this.enhancedTfsCostFields = {
      isModalConfirmed: undefined,
      isNotificationConfirmed: false,
      isReplacing: false,
      isSaveVehicles: false,
      offerId: undefined,
      removingReason: undefined,
      showModal: false,
    };
  };

  setEnhanceCostShareValues = (offer: Offer | undefined) => {
    this.enhCostShareOfferId = assignStringValue(offer?.enhCostShareOfferId, undefined);
    this.isEnhCostShareAccepted = defaultBooleanToUndefined(offer?.isEnhCostShareAccepted);
    this.isEligibleForEnhCostShare = defaultBooleanToUndefined(offer?.isEligibleForEnhCostShare);
    this.isEnhCostShareUpdated = defaultBooleanToUndefined(offer?.isEnhCostShareUpdated);
    this.isEnhCostShareRemoved = defaultBooleanToUndefined(offer?.isEnhCostShareRemoved);

    this.enhTfsCostShareId = assignStringValue(offer?.enhTfsCostShareId);
    this.isEnhTfsCostShareAccepted = defaultBooleanToUndefined(offer?.isEnhTfsCostShareAccepted);
    this.isEligibleForEnhTfsCostShare = defaultBooleanToUndefined(offer?.isEligibleForEnhTfsCostShare);
    this.isEnhTfsCostShareUpdated = defaultBooleanToUndefined(offer?.isEnhTfsCostShareUpdated);
  };

  setIsEnhCostShareUpdated = (isUpdated: boolean) => {
    this.isEnhCostShareUpdated = isUpdated;
  };

  setIsEnhTfsCostShareUpdated = (isUpdated: boolean) => {
    this.isEnhTfsCostShareUpdated = isUpdated;
  };

  setEnhCostShareOfferId = (id: string) => {
    this.enhCostShareOfferId = id;
  };

  setIsEnhCostShareAccepted = (isAccepted: boolean | undefined) => {
    this.isEnhCostShareAccepted = isAccepted;
  };

  get isDisabled() {
    return Boolean(this.isEnhCostShareUpdated) || this.isNationalAndIsAtc || Boolean(this.isEnhCostShareRemoved);
  }

  setIsNationalAndIsAtc = (isNationalAndIsAtc: boolean) => {
    this.isNationalAndIsAtc = isNationalAndIsAtc;
  };

  setEnhancedCostShare = (enhCostShare?: EnhancedCostShare) => {
    this.enhancedCostShare = enhCostShare;
  };

  setEnhancedTfsCostShare = (enhTfsCostShare?: AdminModels.EnhancedTfsCostShare.EnhancedTfsCostShare) => {
    this.enhancedTfsCostShare = enhTfsCostShare;
  };

  getLeaseOfferDetails = (): LeaseOfferDetails[] => {
    let retVal: LeaseOfferDetails[] = [];

    this.leaseTerms.forEach(leaseTerm =>
      leaseTerm.leaseForms.forEach(form => {
        retVal.push(getBaseLeaseDetails(form));
      }),
    );

    return retVal;
  };

  getActiveLeaseForm = (): LeaseFormModel | undefined => {
    let retVal = undefined;

    this.leaseTerms.forEach(leaseTerm =>
      leaseTerm.leaseForms.forEach(leaseForm => {
        if (this.term === leaseForm.inputs.term && this.tier === leaseForm.inputs.tier) {
          retVal = leaseForm;
        }
      }),
    );

    return retVal;
  };

  removeEnhCostShare = (useOldCostShareForLexus?: boolean) => {
    const currentForm = this.getActiveLeaseForm();

    this.setEnhCostShareOfferId('');
    this.setEnhancedCostShare(undefined);
    this.setIsEnhCostShareAccepted(undefined);

    this.leaseTerms.forEach(leaseTerm => {
      leaseTerm.leaseForms.forEach(leaseForm => {
        leaseForm.inputs.tfsShare = getDefaultTfsShare(AdminConstants.OPTION_TYPE_NAMES.LEASE, this.regionCode, leaseTerm.term, !!useOldCostShareForLexus).toString();
        leaseForm.inputs.subCashTfsShare = 0;
        leaseForm.inputs.subCashTfsCostShareCap = 0;
        this.isEligibleForEnhCostShare = false;
        this.enhCostShareOfferId = '';

        if (currentForm) {
          leaseForm.inputs.startDate = currentForm?.inputs.startDate;
          leaseForm.inputs.endDate = currentForm?.inputs.endDate;
        }

        this.updateEnhancedCostShareField('isSaveVehicles', true);
      });
    });
  };

  applyEnhTfsCostShare = () => {
    this.leaseTerms.forEach(leaseTerm => {
      leaseTerm.leaseForms.forEach(leaseForm => {
        leaseForm.setLock('targetPayment');

        const newStandardRegionalRcf = Number(leaseForm.inputs.standardRcf) - Number(this.enhancedTfsCostShare?.value);
        leaseForm.updateInput('standardRegionalRcf', newStandardRegionalRcf);

        if (Number(leaseForm.inputs.rcf) > newStandardRegionalRcf) {
          leaseForm.updateInput('rcf', newStandardRegionalRcf);
        }

        if (this.enhancedTfsCostShare?.endDate && leaseForm.inputs.endDate > this.enhancedTfsCostShare?.endDate) {
          leaseForm.inputs.endDate = this.enhancedTfsCostShare?.endDate;
        }

        if (this.enhancedTfsCostShare?.startDate && leaseForm.inputs.startDate < this.enhancedTfsCostShare?.startDate) {
          leaseForm.inputs.startDate = this.enhancedTfsCostShare?.startDate;
        }
      });
    });

    this.isEnhTfsCostShareAccepted = true;
    this.isEligibleForEnhTfsCostShare = true;
    this.enhTfsCostShareId = this.enhancedTfsCostShare?.id ?? this.enhTfsCostShareId;
  };

  removeEnhTfsCostShare = () => {
    const currentForm = this.getActiveLeaseForm();

    this.enhTfsCostShareId = '';
    this.enhancedCostShare = undefined;
    this.isEnhTfsCostShareAccepted = undefined;

    this.leaseTerms.forEach(leaseTerm => {
      leaseTerm.leaseForms.forEach(leaseForm => {
        leaseForm.setLock('targetPayment');

        leaseForm.updateInput('rcf', leaseForm.inputs.standardRcf);
        leaseForm.updateInput('standardRegionalRcf', leaseForm.inputs.standardRcf);

        this.isEligibleForEnhTfsCostShare = false;
        this.enhTfsCostShareId = '';

        if (currentForm) {
          leaseForm.inputs.startDate = currentForm?.inputs.startDate;
          leaseForm.inputs.endDate = currentForm?.inputs.endDate;
        }
      });
    });
  };

  cancelEnhTfsostShareAction = () => {
    this.leaseTerms.forEach(leaseTerm => {
      leaseTerm.leaseForms.forEach(leaseForm => {
        // Cancel remove

        if (this.enhancedTfsCostFields.removingReason) {
          leaseForm.inputs.startDate = leaseForm.initialData.startDate;
          leaseForm.inputs.endDate = leaseForm.initialData.endDate;
        }

        // Cancel apply
        else if (this.enhancedTfsCostFields.offerId || this.enhancedTfsCostFields.isReplacing) {
          this.isEnhTfsCostShareAccepted = false;
          this.isEligibleForEnhTfsCostShare = this.enhancedTfsCostFields.isReplacing;
          this.enhTfsCostShareId = this.enhancedTfsCostFields.isReplacing ? this.enhancedTfsCostFields.offerId : '';
        }
      });
    });
  };

  applyEnhCostShare = (useOldCostShareForLexus?: boolean) => {
    this.leaseTerms.forEach(leaseTerm => {
      leaseTerm.leaseForms.forEach(leaseForm => {
        leaseForm.updateInput(
          'tfsShare',
          this.enhancedCostShare?.tfsCostShare ?? getDefaultTfsShare(AdminConstants.OPTION_TYPE_NAMES.LEASE, this.regionCode, leaseTerm.term, !!useOldCostShareForLexus).toString(),
        );

        leaseForm.updateInput('subCashTfsShare', Number(this.enhancedCostShare?.subventionCashTfsCostShare));
        leaseForm.updateInput('subCashTfsCostShareCap', Number(this.enhancedCostShare?.subventionCashTfsCostShareCap));

        if (this.enhancedCostShare?.endDate && leaseForm.inputs.endDate > this.enhancedCostShare?.endDate) {
          leaseForm.inputs.endDate = this.enhancedCostShare?.endDate;
        }

        if (this.enhancedCostShare?.startDate && leaseForm.inputs.startDate < this.enhancedCostShare?.startDate) {
          leaseForm.inputs.startDate = this.enhancedCostShare?.startDate;
        }
      });
    });

    this.isEnhCostShareAccepted = true;
    this.isEligibleForEnhCostShare = true;
    this.enhCostShareOfferId = this.enhancedCostShare?.offerId ?? this.enhCostShareOfferId;
    this.updateEnhancedCostShareField('isSaveVehicles', true);
  };

  cancelEnhCostShareAction = () => {
    this.leaseTerms.forEach(leaseTerm => {
      leaseTerm.leaseForms.forEach(leaseForm => {
        // Cancel remove
        if (this.enhancedCostFields.removingReason) {
          leaseForm.inputs.startDate = leaseForm.initialData.startDate;
          leaseForm.inputs.endDate = leaseForm.initialData.endDate;

          this.setVehicles(leaseForm.initialData.vehicles);
        }
        // Cancel apply
        else if (this.enhancedCostFields.offerId || this.enhancedCostFields.isReplacing) {
          this.isEnhCostShareAccepted = false;
          this.isEligibleForEnhCostShare = this.enhancedCostFields.isReplacing;
          this.enhCostShareOfferId = this.enhancedCostFields.isReplacing ? this.enhancedCostFields.offerId : '';

          if (leaseForm.initialData.vehicles.map(v => v.isInclusion && v.modelCode).toString() !== this.includedVehicles.map(v => v.modelCode).toString()) {
            this.updateEnhancedCostShareField('isSaveVehicles', true);
          }
        }
      });
    });
  };

  /**
   * Used to update leases from response (ex: addtional cash logic)
   */
  updateLeasesFromResponse = (offer: CombinedOffers) => {
    this.rev = offer.regional.rev;
    this.leaseTerms.forEach(leaseTerm => {
      // process each form (note: form could be master or example based on isExample flag)
      leaseTerm.leaseForms.forEach(leaseForm => {
        offer.regional.leaseDetails?.forEach(ld => {
          if (leaseForm.inputs.term === ld.highTerm) {
            leaseForm.setLock('targetPayment');

            // sets master/example lease updates
            const nationalDetails = findNationalLeaseByTerm(this.nationalLeases, ld.highTerm);
            leaseForm.setDetails(offer.regional, ld, this.isLexus, nationalDetails);

            // set example updates
            if (leaseForm.isExample && ld.leaseExamples) {
              ld.leaseExamples.forEach(ex => {
                if (ex.name === leaseForm.inputs.name) {
                  setLeaseExampleInputs(leaseForm, ex);
                  leaseForm.calculateDefaultPayments();
                }
              });
            }
          }
        });
      });
    });
  };

  toggleIsInclusions = () => {
    this.isInclusions = !this.isInclusions;
  };

  updateCompatibilityList = (compatabilityList: string[]) => {
    this.compatabilityList = compatabilityList;
  };

  toggleCompatibileOffer = (offerName: string) => {
    const compOfferIndex = this.compatabilityList.findIndex(offer => offer === offerName);

    if (compOfferIndex === -1) {
      this.compatabilityList.push(offerName);
    } else {
      this.compatabilityList.splice(compOfferIndex, 1);
    }
  };

  setCompatibility = (compatability: AdminModels.Compatibility) => {
    this.compatibility = compatability;
  };

  setVehicles = (vehicles: VehicleInput[]) => {
    const { excludedVehicles, includedVehicles } = getIncludedExcludedVehicles(vehicles);
    this.excludedVehicles = excludedVehicles;
    this.includedVehicles = includedVehicles;
  };

  setNationalLeases = (nationalLeases: LeaseDetails[]) => {
    this.nationalLeases = nationalLeases;
  };

  setNationalVehicles = (nationalVehicles: Vehicle[]) => {
    this.nationalVehicles = nationalVehicles;
  };

  updateExcIncVehicles = (excList: VehicleInput[], incList: VehicleInput[]) => {
    this.excludedVehicles = excList;
    this.includedVehicles = incList;
  };

  updateTierRates = (offer: Offer | undefined) => {
    this.leaseTerms.forEach(term => {
      term.leaseForms.forEach(form => {
        const foundDetail = offer?.leaseDetails?.find(detail => detail.highTerm === form.inputs.term);
        if (foundDetail) {
          form.inputs.rates = foundDetail.rates;
          form.isValidEnR = assignBooleanValue(foundDetail.isValidEnR, true);
          form.inputs.advanceRates = foundDetail.advanceRates;
        }
      });
    });
  };

  updateRev = (rev: string | undefined) => {
    this.rev = rev || '';
  };

  updateName = (name: string) => {
    this.name = name;
  };

  updateAccessoryCodes = (val: string) => {
    this.accessoryCodes = val;
  };

  updateIsSpecialEdition = (val: boolean) => {
    this.isSpecialEdition = val;
  };

  updateSpecialEditionPackage = (val: string) => {
    this.specialEditionPackage = val;
  };

  // Returns payload LeaseDetails array
  // Triggered by changes from RCF, SubventionCash,tfsShare
  setUpToValues = (leaseForm: LeaseFormModel, ryoEarnings: number, stdRcf?: number | undefined, offering?: Offering): LeaseOfferDetails[] | undefined => {
    if (leaseForm.hasError()) {
      return;
    }

    if (leaseForm.isExample) {
      return undefined;
    }

    let leaseTerms: LeaseTermModel[] = [];

    if (leaseForm.inputs.isUpToApplied) {
      // includes lower term leases
      const lowerLeaseTerms = getLeaseLowerTerms(this.leaseTerms, leaseForm.inputs.term, false);

      lowerLeaseTerms.forEach(leaseTerm => {
        leaseTerm.leaseForms.forEach(item => {
          if (leaseForm.isEnhRcfEligible && leaseForm.areTfsEnhRatesUsed && !item.isEnhRcfEligible) {
            return;
          }
          if (!item.isExample) {
            item.setLock('targetPayment');
          }
          item.inputs.rcf = leaseForm.inputs.rcf;
          if (item.isEnhRcfEligible) {
            item.areTfsEnhRatesUsed = leaseForm.areTfsEnhRatesUsed;
            item.inputs.note = item.areTfsEnhRatesUsed ? `${this.isLexus ? `LFS` : `TFS`} Enhanced Standard RCF` : '';
          }

          const defaultItemTfsShare = getDefaultLeaseTfsShare(
            !!offering?.useOldCostShareForLexus,
            this.regionCode,
            item.inputs.term,
            this.isEnhCostShareAccepted,
            this.enhancedCostShare,
          );

          const defaultFormTfsShare = getDefaultLeaseTfsShare(
            !!offering?.useOldCostShareForLexus,
            this.regionCode,
            leaseForm.inputs.term,
            this.isEnhCostShareAccepted,
            this.enhancedCostShare,
          );

          const checkIfTfsShareChanged = Boolean(defaultFormTfsShare !== Number(leaseForm.inputs.tfsShare));
          const checkIfEnhanced = checkRcfSubVentionCashCostShare(
            leaseForm.isEnhanced,
            leaseForm.inputs.subventionCash.toString(),
            leaseForm.inputs.nationalSubventionCash.toString(),
            checkIfTfsShareChanged,
            this.enhancedCostShare,
          );

          if (checkIfEnhanced) {
            if (leaseForm.isEnhanced) {
              item.inputs.tfsShare = leaseForm.inputs.tfsShare;
            } else {
              if (this.isEnhCostShareAccepted && this.enhancedCostShare?.tfsCostShare && FEATURE_OR_2477) {
                item.inputs.tfsShare = this.enhancedCostShare.tfsCostShare;
                leaseForm.inputs.tfsShare = this.enhancedCostShare.tfsCostShare;
              } else {
                item.inputs.tfsShare = defaultItemTfsShare;
                leaseForm.inputs.tfsShare = defaultFormTfsShare;
              }
            }
            if (leaseForm.inputs.nationalSubventionCash.toString() !== leaseForm.inputs.subventionCash.toString()) {
              item.inputs.subCashTfsShare = leaseForm.inputs.subCashTfsShare;
              item.inputs.subCashTfsCostShareCap = leaseForm.inputs.subCashTfsCostShareCap;
            }
          } else {
            item.inputs.tfsShare = defaultItemTfsShare;
          }
          item.updateSubCash(assignStringValue(leaseForm.inputs.subventionCash, '0')); // also recalculates
          item.updateOfferCosts(ryoEarnings);
        });
      });
      leaseTerms = leaseTerms.concat(lowerLeaseTerms);
    }

    // add current term
    const currentTerm = getLeaseCurrentTerm(this.leaseTerms, leaseForm.inputs.term);
    leaseTerms = leaseTerms.concat(currentTerm);

    // check if the isUpTo is checked in the higher terms
    const { isUpToApplied, highLeaseTerms } = getHigherTermUpToLeases(this.leaseTerms, leaseForm.inputs.term);
    if (isUpToApplied) {
      highLeaseTerms.forEach(leaseTerm => {
        leaseTerm.leaseForms.forEach(item => {
          item.inputs.isUpToApplied = false;
        });
      });
      leaseTerms = leaseTerms.concat(highLeaseTerms);
    }

    return leaseTerms.map(leaseTerm => {
      return transformLeaseDetails(leaseTerm, leaseTerm.getMaster());
    });
  };

  addOddTermLease = (regionalOffer: Offer, detail: LeaseDetails) => {
    const leaseTerms = this.leaseTerms.slice();
    const terms = this.terms.slice();

    const leaseTerm = new LeaseTermModel();
    const nationalDetails = findNationalLeaseByTerm(this.nationalLeases, detail.highTerm);
    leaseTerm.setForms(this, regionalOffer, detail, nationalDetails);
    leaseTerms.push(leaseTerm);

    terms.push(detail.highTerm);
    terms.sort((a, b) => a - b);
    this.terms = terms;
    this.leaseTerms = leaseTerms.sort((a, b) => a.term - b.term);
  };

  hideOddTermLease = (oddTerm: number | number[]) => {
    const leaseTerms = this.leaseTerms.slice();
    const terms = this.terms.slice();

    const oddTerms = Array.isArray(oddTerm) ? oddTerm : [oddTerm];

    const filterOddTermLeases = leaseTerms.filter(leaseTerm => !oddTerms.includes(leaseTerm.term));
    const filterOddTerms = terms.filter(term => !oddTerms.includes(term));
    this.term = filterOddTerms[filterOddTerms.length - 1];
    this.terms = filterOddTerms;
    this.leaseTerms = filterOddTermLeases;
  };

  getOddTerms = () => {
    const nationalTerms = this.nationalLeases.map(item => item.highTerm);
    return this.terms.filter(term => !nationalTerms.includes(term));
  };

  // When the model is selected and user excludes that model code, blank the vehicleDescription
  checkVehicleDescription = () => {
    this.leaseTerms.forEach(leaseTerm => {
      leaseTerm.leaseForms.forEach(form => {
        this.excludedVehicles.forEach(veh => {
          if (veh.vehicleDescription === form.inputs.vehicleDescription) {
            form.inputs.vehicleDescription = '';
          }
        });
      });
    });
  };

  updateContestNumberDetails = (val: string, id: string) => {
    this.contestNumber = val;
    this.contestNumberOfferId = id;
  };

  resetToNational = (leaseOffer: Offer, residuals: ResidualValue[], region: string, offering: Offering) => {
    this.setVehicles(leaseOffer.vehicles);
    this.compatabilityList = leaseOffer.compatibilityList;
    this.hideOddTermLease(this.getOddTerms());
    this.updateLeasesFromResponse({ regional: leaseOffer, national: { ...leaseOffer, leaseDetails: this.nationalLeases } });

    // extra values to reset like settings/EFC values
    this.leaseTerms.forEach(leaseTerm => {
      leaseTerm.leaseForms.forEach(leaseForm => {
        if (!leaseForm.isGST && leaseForm.inputs.isAdvertised) {
          if (leaseForm.isVinLoaded) {
            leaseForm.inputs.tdaFee = assignStringValue(leaseForm.inputs.defaultTdaFee, '0');
            leaseForm.inputs.totalMsrp = leaseForm.inputs.defaultTotalMsrp;
            leaseForm.inputs.dealerCostPerInvoice = leaseForm.inputs.defaultDealerCostPerInvoice;
          }

          leaseForm.inputs.dealerGross = leaseForm.inputs.dealerGrossDefault;
          leaseForm.inputs.enhancedResidualRate = 0;

          // keep user selected mileage
          leaseForm.updateMileage(leaseForm.inputs.mileage, residuals);
          leaseForm.recalculate(false, '', region, offering);
        }
      });
    });

    return transformLeaseCard(this);
  };
}

export default LeaseCardModel;
