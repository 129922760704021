import { observer } from 'mobx-react-lite';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';
import Accordion from '../../../../components/Accordion/Accordion';
import AccordionItem from '../../../../components/Accordion/AccordionItem';
import AccordionItemModel from '../../../../components/Accordion/models/AccordionItemModel';
import styles from './styles.module.scss';

interface Props {
  id?: string;
  disabled?: boolean;
  submitText?: string;
  className?: string;
  toggleItem: (uid: string, checked: boolean) => void;
  toggleAll: (checked: boolean) => void;
  items: AccordionItemModel[];
  compatibileOffers: string[];
  onSubmit: (compatibility: string[]) => void;
  onClose: () => void;
}

const CompatibilityModal = ({ id, submitText = 'Submit', className, onSubmit, onClose, items, compatibileOffers, toggleAll, toggleItem }: Props) => {
  const handleOnSubmit = () => {
    onSubmit(items.filter(i => i.checked).map(i => i.value));
    onClose();
  };

  const handleSelectAll = () => {
    toggleAll(true);
  };

  const handleRemoveAll = () => {
    toggleAll(false);
  };

  const handleCancel = () => {
    onClose();

    items.forEach(item => {
      if (compatibileOffers.find(label => label === item.label)) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
  };

  return (
    <Modal isOpen onClose={onClose}>
      <ModalHeader onClose={onClose}>Select Compatibile Offers</ModalHeader>
      <ModalBody>
        <div className={styles.accordionWrapper}>
          <Accordion>
            {items.map(item => {
              return <AccordionItem key={item.uid} item={item} toggleItem={toggleItem} />;
            })}
          </Accordion>
        </div>
      </ModalBody>
      <ModalFooter className={className}>
        <ButtonGroup>
          <Button id={`select-all-cta-${id}`} variant="text" className={styles.button} onClick={handleSelectAll}>
            Select All
          </Button>
          <Button id={`remove-all-cta-${id}`} variant="text" className={styles.button} onClick={handleRemoveAll}>
            Remove All
          </Button>

          <Button id={`cancel-modal-submit-${id}`} variant="primary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button id={`default-modal-submit-${id}`} variant="primary" onClick={handleOnSubmit}>
            {submitText}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default observer(CompatibilityModal);
