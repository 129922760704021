import { ReactNode } from 'react';
import { ContestNumbersResponse, useGetContestNumbersQuery } from '../../gql/generated';

interface Props {
  offeringId: string;
  children: ReactNode;
  onLoad: (setContestNumbers: ContestNumbersResponse) => void;
}

const GetSETContentNumbers = ({ offeringId, children, onLoad }: Props) => {
  const { loading, error, data } = useGetContestNumbersQuery({ variables: { offeringId } });

  if (loading || error) {
    return <></>;
  }

  if (data) {
    onLoad(data.contestNumbers);
  }

  return <>{children}</>;
};

export default GetSETContentNumbers;
