import { OATIcon } from 'oat-common-ui';
import RgnlAltModel from '../../pages/Summary/models/RgnlAltModel';
import styles from './styles.module.scss';

interface Props {
  rgnlAlt: RgnlAltModel;
  isSummary?: boolean;
}

const PenRateErrors = ({ rgnlAlt, isSummary = false }: Props) => {
  return (
    <>
      {(rgnlAlt.errors.length > 0 || rgnlAlt.vinErrors.length > 0 || (isSummary && rgnlAlt.contestNumberError.length > 0)) && (
        <div className={styles.errors}>
          <ul>
            {rgnlAlt.errors.map((err, i) => {
              const key = i;
              return (
                <li key={key}>
                  <OATIcon icon="warning" colorTheme="red" /> {err}
                </li>
              );
            })}
            {rgnlAlt.vinErrors.map((err, index) => {
              return (
                <li key={index}>
                  <OATIcon icon="warning" colorTheme="red" /> {err}
                </li>
              );
            })}
            {rgnlAlt.contestNumberError.length > 0 && (
              <li>
                <OATIcon icon="warning" colorTheme="red" /> {rgnlAlt.contestNumberError}
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default PenRateErrors;
