import { AdminConstants, getDefaultTfsShare } from 'oat-admin-common';
import { dateStringToDate } from 'oat-common-ui';
import { useState } from 'react';
import { INVALID_DATES, INVALID_MODELCODES } from '../constants/global';
import { CreateAprOfferInput, Offer, Offering, SaveAprOfferInput } from '../gql/generated';
import { UserInfo } from '../pages/ProgramDetails/components/AprCard';
import AprCardModel from '../pages/ProgramDetails/models/AprCardModel';
import { buildEnhCostShareCheckPayload, buildEnhCostShareInput, buildEnhCostSharePayload, handleRecalcForEnhTierTerms } from '../pages/createOffer/CreateApr/enhCostShareUtils';
import { transformAprCardData } from '../pages/createOffer/CreateApr/utils';
import useStores from '../stores/useStores';
import { EnhCostShareOfferInput, HandleEnhCostShareResponse, OfferEligibilityFields } from './useOfferEligibility';
import useUrlParams from './useUrlParams';

const { OPTION_TYPE_NAMES } = AdminConstants;

const useAprEnhCostShareUtils = (
  aprCard: AprCardModel,
  handleEnhCostShare: (offerInput: EnhCostShareOfferInput, offer?: OfferEligibilityFields | undefined) => HandleEnhCostShareResponse,
  offering: Offering,
) => {
  const {
    userInfoStore: { isGst, isSETUser, isLexus },
    programDetailsStore: { ryoEarnings },
  } = useStores();
  const { region } = useUrlParams();
  const userInfo: UserInfo = { isGst: isGst(), isSet: isSETUser(), isLexus: isLexus() };

  const [showEnhCostShareModal, setShowEnhCostShareModal] = useState(false);
  const [showRemoveCostShareModal, setShowRemoveCostShareModal] = useState(false);
  const [removeEnhReason, setEnhRemoveReason] = useState('');
  const [replaceExisting, setReplaceExisting] = useState(false);

  const handleCheckForEnhCostShares = () => {
    const { checkPayload, existingOfferPayload } = buildEnhCostShareCheckPayload(aprCard, region, userInfo);
    const { showModal, eligibleOfferId, enhancedCostShare, reason, isRemoving, isReplacing } = handleEnhCostShare(checkPayload, existingOfferPayload);

    if (isReplacing) {
      setReplaceExisting(isReplacing);
    }

    if (showModal && eligibleOfferId) {
      aprCard.setEnhCostShareOfferId(eligibleOfferId);
      aprCard.setIsEligibleForEnhCostShare(!!eligibleOfferId);
      aprCard.setEnhancedCostShare(enhancedCostShare);

      if (aprCard.isEnhCostShareAccepted === undefined || isReplacing) {
        setShowEnhCostShareModal(true);
      }
    }

    if (isRemoving && reason) {
      setEnhRemoveReason(reason);
      setShowRemoveCostShareModal(true);
    }

    return { showModal, isRemoving, isReplacing };
  };

  const handleUpdateTierTermsWithEnhCostShare = (isEnhCostShareAccepted: boolean | undefined, skipRecalc = false, updateTfs = false) => {
    const { enhancedCostShare, tiersList, tierMap } = aprCard;

    for (const tier of tiersList) {
      if (!isEnhCostShareAccepted || skipRecalc) {
        break;
      }

      const tierTerm = tierMap.get(tier);

      if (tierTerm) {
        for (const term of tierTerm.terms) {
          if (enhancedCostShare && !updateTfs && !removeEnhReason) {
            const foundTfsCostShare = enhancedCostShare.tierTerms?.find(
              foundTierTerm => foundTierTerm?.tier === tier && foundTierTerm?.highTerm === term.fields.term && !!foundTierTerm.tfsCostShare,
            )?.tfsCostShare;

            term.updateField(
              'tfsShare',
              isEnhCostShareAccepted
                ? foundTfsCostShare?.toString() ?? getDefaultTfsShare(OPTION_TYPE_NAMES.APR, region, term.fields.term, !!offering.useOldCostShareForLexus).toString()
                : term.fields.tfsShare,
            );
            term.updateField('subCashTfsShare', isEnhCostShareAccepted ? Number(enhancedCostShare.subventionCashTfsCostShare) : term.fields.subCashTfsShare);
            term.updateField('subCashTfsCostShareCap', isEnhCostShareAccepted ? Number(enhancedCostShare.subventionCashTfsCostShareCap) : term.fields.subCashTfsCostShareCap);
          }

          if (updateTfs) {
            term.updateField('tfsShare', term.fields.tfsShare);
          }

          if (removeEnhReason && isEnhCostShareAccepted) {
            term.updateField('tfsShare', getDefaultTfsShare(OPTION_TYPE_NAMES.APR, region, term.fields.term, !!offering.useOldCostShareForLexus).toString());
            term.updateField('subCashTfsShare', 0);
            term.updateField('subCashTfsCostShareCap', 0);
          }
        }

        handleRecalcForEnhTierTerms(tierTerm, ryoEarnings);
      }
    }
  };

  const handleResetDatesOnUpdate = () => {
    const firstTerm = aprCard.selectedTierModel?.terms[0];

    if (firstTerm) {
      firstTerm.updateField('startDate', aprCard.initialDates.startDate);
      firstTerm.updateField('endDate', aprCard.initialDates.endDate);
    }
  };

  const handleResetVehiclesOnUpdate = () => {
    aprCard.updateField('vehicles', aprCard.initialVehicles);
  };

  const handleResetOnCancel = (isEnhCostShareAccepted: boolean | undefined) => {
    // for clicking cancel on modal for removing enhanced cost share
    if (removeEnhReason) {
      const removeReason = removeEnhReason.trim().toLowerCase();

      if (removeReason.includes(INVALID_MODELCODES.trim().toLowerCase()) && !isEnhCostShareAccepted) {
        handleResetVehiclesOnUpdate();
      }

      if (removeReason.includes(INVALID_DATES.trim().toLowerCase()) && !isEnhCostShareAccepted) {
        handleResetDatesOnUpdate();
      }
    }
  };

  const handleResetRemoveParams = () => {
    setEnhRemoveReason('');
    setShowRemoveCostShareModal(false);
  };

  const handleResetExistingParams = () => {
    setReplaceExisting(false);
  };

  const handleResetAfterSave = () => {
    setShowEnhCostShareModal(false);

    if (removeEnhReason) {
      handleResetRemoveParams();
    }

    if (replaceExisting) {
      handleResetExistingParams();
    }
  };

  const setNewAprEnhFields = (aprOffer: Offer) => {
    aprCard.updateEnhCostShareFields(aprOffer);

    // setting new initial dates & model codes after save, to revert data if user hits cancel on new modal
    if (aprOffer.aprDetails?.length) {
      aprCard.setInitialDates(dateStringToDate(aprOffer.aprDetails[0].startDate), dateStringToDate(aprOffer.aprDetails[0].endDate));
      aprCard.updateInitialVehicles();
    }
  };

  const handleUpdateAttributesOnCreate = (isEnhCostShareAccepted: boolean) => {
    // for cost share and handling recalculations
    if (aprCard.enhCostShareOfferId) {
      handleUpdateTierTermsWithEnhCostShare(isEnhCostShareAccepted);
    }
  };

  const handleUpdateAttributesOnUpdate = (isEnhCostShareAccepted: boolean | undefined, skipRecalc: boolean, updateTfs = false) => {
    // for cost share and handling recalculations
    handleUpdateTierTermsWithEnhCostShare(isEnhCostShareAccepted, skipRecalc, updateTfs);

    // for reverting dates or model codes if modal is present and user clicks cancel
    handleResetOnCancel(isEnhCostShareAccepted);
  };

  const buildEnhCostSharePayloadOnCreate = (rgnlAltId: string, isEnhCostShareAccepted: boolean, onSecondModal = false): CreateAprOfferInput => {
    const payload = onSecondModal
      ? buildEnhCostShareInput({
          isEligibleForEnhCostShare: aprCard.isEligibleForEnhCostShare,
          isEnhCostShareAccepted: aprCard.isEnhCostShareAccepted,
          enhCostShareOfferId: !!aprCard.isEligibleForEnhCostShare && !!aprCard.isEnhCostShareAccepted ? aprCard.enhCostShareOfferId : '',
          isEnhCostShareUpdated: aprCard.isEnhCostShareUpdated,
        })
      : buildEnhCostShareInput({
          isEligibleForEnhCostShare: isEnhCostShareAccepted,
          isEnhCostShareAccepted: showEnhCostShareModal ? isEnhCostShareAccepted : undefined,
          enhCostShareOfferId: isEnhCostShareAccepted && aprCard.enhCostShareOfferId ? aprCard.enhCostShareOfferId : '',
          isEnhCostShareUpdated: aprCard.isEnhCostShareUpdated,
        });

    const enhCostSharePayload: CreateAprOfferInput = {
      rgnlAltId,
      ...transformAprCardData(aprCard, userInfo, true),
      ...payload,
    };

    return enhCostSharePayload;
  };

  const buildEnhCostSharePayloadOnUpdate = (isEnhCostShareAccepted: boolean | undefined): SaveAprOfferInput => {
    const enhCostSharePayload: SaveAprOfferInput = {
      id: aprCard.id,
      rev: aprCard.rev,
      ...buildEnhCostSharePayload(aprCard, userInfo, {
        removeEnhReason,
        replaceExisting,
        isEnhCostShareAccepted,
      }),
    };

    return enhCostSharePayload;
  };

  const handleCancelOnAddEnhCostShare = (isReplacing = false) => {
    if (isReplacing) {
      aprCard.setIsEnhCostShareAccepted(false);
      return;
    }

    aprCard.setIsEnhCostShareAccepted(false);
    aprCard.setEnhCostShareOfferId(undefined);
    aprCard.setIsEligibleForEnhCostShare(false);
    aprCard.setEnhancedCostShare(undefined);
  };

  return {
    handleCheckForEnhCostShares,
    handleUpdateTierTermsWithEnhCostShare,
    handleResetRemoveParams,
    showEnhCostShareModal,
    showRemoveCostShareModal,
    setShowRemoveCostShareModal,
    removeEnhReason,
    setShowEnhCostShareModal,
    setNewAprEnhFields,
    replaceExisting,
    handleResetExistingParams,
    handleResetOnCancel,
    handleResetAfterSave,
    handleUpdateAttributesOnCreate,
    handleUpdateAttributesOnUpdate,
    handleCancelOnAddEnhCostShare,
    handleResetDatesOnUpdate,
    buildEnhCostSharePayloadOnCreate,
    buildEnhCostSharePayloadOnUpdate,
  };
};

export default useAprEnhCostShareUtils;
