import { Button, Modal, ModalBody, ModalFooter, ModalHeader, uploadWithPresignedUrl, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import apolloClient from '../../../apolloClient';
import FileUpload from '../../../components/FileUpload';
import {
  GetPresignedUploadUrlDocument,
  GetPresignedUploadUrlQueryVariables,
  Offering,
  PresignedUrlResponse,
  useGetLastWofcoLogQuery,
  useUploadWofcoContentMutation,
} from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import getDateParts from '../../../utils/getDateParts';

interface Props {
  offering: Offering;
  onClose: () => void;
}

export enum UPLOAD_TYPE {
  WOFCO = 'WOFCO',
}

const getVars = (type: string, fileName: string, offeringId: string) => {
  const vars: GetPresignedUploadUrlQueryVariables = {
    type,
    fileName,
    offeringId,
  };

  return vars;
};

const getPresignedUploadUrl = async (type: UPLOAD_TYPE, fileName: string, offeringId: string): Promise<PresignedUrlResponse> => {
  const response = await apolloClient.query({ query: GetPresignedUploadUrlDocument, variables: getVars(type, fileName, offeringId) });
  return response.data.presignedUploadUrl;
};

const WofcoModal = ({ offering, onClose }: Props) => {
  const {
    userInfoStore: {
      userInfo: { brand },
    },
  } = useStores();

  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState<File | undefined>();
  const [loaded, setLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { month, year } = getDateParts(offering.startDate);
  const { data, loading, error } = useGetLastWofcoLogQuery({
    variables: {
      brand,
      offeringId: offering.id,
      offeringMonth: month,
      offeringYear: year,
    },
  });
  const [uploadWofcoContent] = useUploadWofcoContentMutation();
  const { error: errorToast } = useToast();

  if (!loaded && !loading && data) {
    setFileName(data.lastWofcoLog?.fileName ?? '');
    setLoaded(true);
  }

  const handleSubmit = async () => {
    if (file) {
      try {
        setSubmitting(true);
        const presignedUrlResponse = await getPresignedUploadUrl(UPLOAD_TYPE.WOFCO, file.name, offering.id);
        await uploadWithPresignedUrl(presignedUrlResponse.presignedUrl, file);

        const { data } = await trackPromise(
          uploadWofcoContent({
            variables: {
              input: {
                s3Key: presignedUrlResponse.s3Key,
                fileName: file.name,
                brand,
                offeringId: offering.id,
              },
            },
          }),
        );
        setFileName(data?.uploadWofcoContent.lastUploadedFile?.fileName ?? '');
        onClose();
      } catch (e) {
        errorToast(`Error occurred uploading SET sheet for Offering ID - ${offering.id}`);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Modal isOpen onClose={onClose}>
      <ModalHeader title="Incentive Period Settings" onClose={onClose} />
      <ModalBody>
        {loading && 'Loading...'}
        {loaded && !error && <FileUpload id="wofco-input" label="Regional Standard Rates and Residuals" file={fileName} onChange={setFile} />}
      </ModalBody>
      <ModalFooter>
        <Button id={`${offering.id}-modal-cancel-btn`} variant="text" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button id={`${offering.id}-modal-save-btn`} variant="primary" onClick={handleSubmit} disabled={submitting || !file}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WofcoModal;
