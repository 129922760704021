import { observer } from 'mobx-react-lite';
import { formatDollars } from 'oat-common-ui';
import TooltipList, { TooltipListItem } from '../../../../../../components/TooltipList/TooltipList';
import LeaseFormModel from '../../../../models/LeaseFormModel';

interface Props {
  leaseDetails: LeaseFormModel;
}

const GrossCapCostTooltipMessage = ({ leaseDetails }: Props) => {
  return (
    <>
      <TooltipList>
        <TooltipListItem>
          <span>Dealer Gross</span>
          <span>${formatDollars(leaseDetails.inputs.dealerGross)}</span>
        </TooltipListItem>
        <TooltipListItem symbol="+">
          <span>Dealer Cost Per Invoice</span>
          <span>${formatDollars(leaseDetails.inputs.dealerCostPerInvoice)}</span>
        </TooltipListItem>
      </TooltipList>

      <TooltipList divider>
        <TooltipListItem>
          <span>Gross Cap Cost</span>
          <span>${formatDollars(leaseDetails.inputs.grossCapCost)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
};

export default observer(GrossCapCostTooltipMessage);
