import { dateStringToDate } from 'oat-common-ui';

const getPriorMonthNameFromDate = (dateString: string, range = 1): string => {
  const current = dateStringToDate(dateString);
  current.setMonth(current.getMonth() - range);

  return current.toLocaleString('default', { month: 'long' });
};

export default getPriorMonthNameFromDate;
