import { OATIcon } from 'oat-common-ui';
import Popover from '../../../../../../../../components/Popover';
import LeaseCardModel from '../../../../../../models/LeaseCardModel';
import LeaseFormModel from '../../../../../../models/LeaseFormModel';
import VehicleInfoTooltipMessage from '../../../Tooltips/VehicleInfoTooltipMessage';
import styles from './styles.module.scss';

interface Props {
  leaseCard: LeaseCardModel;
  leaseForm: LeaseFormModel;
  isDisabled: boolean;
}

const VehicleInfoRight = ({ leaseCard, leaseForm, isDisabled }: Props) => {
  return (
    <div className={styles.infoContainer}>
      {leaseForm.inputs.isAdvertised && (
        <Popover
          id={`vehicle-info-${leaseForm.uid}`}
          content={<VehicleInfoTooltipMessage leaseCard={leaseCard} leaseForm={leaseForm} isDisabled={isDisabled} />}
          buttonContent={<OATIcon icon="info" colorTheme="blue" pointer />}
        />
      )}
      <h3 className={styles.seriesTitle}>{leaseForm.seriesName}</h3>
    </div>
  );
};

export default VehicleInfoRight;
