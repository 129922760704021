import { SeriesSorting as SeriesSortingList, useFetchSeriesSortingQuery } from '../../gql/generated';
import useStores from '../../stores/useStores';
import LoadingPage from '../LoadingPage';
import SeriesSortingComponent from './SeriesSortingComponent';

const SeriesSorting = () => {
  const { userInfoStore, seriesSortingStore } = useStores();

  const regionCode = userInfoStore.getRegionCode();

  const { data, loading, error } = useFetchSeriesSortingQuery({
    variables: { regionCode },
  });

  if (loading || error) {
    return <LoadingPage breadCrumbs={[{ name: userInfoStore.properties.abbrName }]} error={!!error} />;
  }

  if (data) {
    seriesSortingStore.setSeriesSorting(data.fetchSeriesSorting as SeriesSortingList[]);
  }

  return <SeriesSortingComponent />;
};

export default SeriesSorting;
