import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { BlockInputLabel } from 'oat-common-ui';
import NumberInput from '../../../../../../../components/NumberInput';
import PenRateModel from '../../../../../models/PenRateModel';
import CostContainer from '../CostContainer';
import styles from '../styles.module.scss';

interface Props {
  cashModel: PenRateModel;
  inputIsDisabled: boolean;
  onSave?: () => void;
}

const CashDetailsRow = ({ cashModel, inputIsDisabled, onSave }: Props) => {
  const { penetration, updatePenRate } = cashModel;

  const handleOnChange = (val: string) => {
    updatePenRate(val);

    if (onSave) {
      onSave();
    }
  };

  return (
    <div className={clsx(styles.offerRow, styles.marginLeft)}>
      <div className={styles.containerList}>
        <div className={styles.table}>
          <BlockInputLabel labelComponent={<span className={clsx(styles.topText, styles.marginBottom)}>Penetration Rate</span>}>
            <NumberInput value={penetration} percentageSign onChange={e => handleOnChange(e.target.value)} className={styles.penRateInput} disabled={inputIsDisabled} units />
          </BlockInputLabel>
        </div>
        <CostContainer model={cashModel} />
      </div>
    </div>
  );
};

export default observer(CashDetailsRow);
