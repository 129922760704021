import clsx from 'clsx';
import { ReactNode, useRef, useState } from 'react';
import { Popover as BPopover, Overlay } from 'react-bootstrap';
import styles from './styles.module.scss';

export type PlacementType = 'top' | 'left' | 'right' | 'bottom';

interface Props {
  id?: string;
  content?: ReactNode;
  buttonContent?: ReactNode;
  contentClass?: string;
  placement?: PlacementType;
}

const Popover = ({ id, content, buttonContent, contentClass, placement = 'bottom' }: Props) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  return (
    <div ref={ref} className={styles.container}>
      <Overlay show={show} target={ref.current} onHide={() => setShow(false)} placement={placement} rootClose>
        <BPopover id={id} className={styles.popover}>
          <BPopover.Body className={clsx(styles.body, contentClass)}>{content}</BPopover.Body>
        </BPopover>
      </Overlay>
      <button id={id} type="button" className={styles.btn} onClick={() => setShow(!show)}>
        {buttonContent}
      </button>
    </div>
  );
};

export default Popover;
