export const calcRegionalSubNational = (value: string, nationalValue?: number) => {
  const transformedValue = Number(value.replace(',', ''));

  return nationalValue === undefined ? Number(value) : transformedValue - nationalValue;
};

export const calcRegionalAddNational = (value: string, nationalValue?: number) => {
  const transformedValue = Number(value.replace(',', ''));

  return nationalValue === undefined ? Number(value) : transformedValue + nationalValue;
};

export const calculatePnvAmountValues = (field: string, value: string, nationalValue?: number) => {
  let combinedPerUnitCost = '';
  let pnv = '';

  if (field === 'amount') {
    pnv = calcRegionalSubNational(value, nationalValue).toString();
    combinedPerUnitCost = value;
  } else {
    pnv = value;
    combinedPerUnitCost = calcRegionalAddNational(value, nationalValue).toString();
  }

  return { combinedPerUnitCost, pnv };
};
