import { ranges } from '../../../../../../constants/global';
import { AdvanceRates, Maybe } from '../../../../../../gql/generated';
import Rate from '../../models/Rate';

export const buildAdvanceRatesView = (advanceRates: Maybe<AdvanceRates>[] | null | undefined, mainRates: Rate[]) => {
  const rates: Record<string, { rates: AdvanceRates[] }> = {};
  const uniqueTiers = mainRates.map(rate => {
    return rate.tier;
  });

  ranges.forEach(range => {
    const tiersList = uniqueTiers.map(tier => {
      return {
        maxAdvance: range.split('-')[1],
        minAdvance: range.split('-')[0],
        standardRcf: 999,
        subventedRcf: 999,
        tier: tier,
      };
    });
    rates[range] = { rates: tiersList };
  });

  // Build 1st row for 0-110.99 range from main rates
  mainRates.forEach(rate => {
    rates[ranges[0]].rates.forEach(rt => {
      if (rate.tier === rt.tier) {
        rt.standardRcf = rate?.standardRcf ?? rt.standardRcf;
        rt.subventedRcf = rate?.subventedRcf ?? rt.subventedRcf;
      }
    });
  });

  // Build remaining ranges rows from advance Rates
  advanceRates &&
    advanceRates.forEach(rate => {
      rates[`${rate?.minAdvance}-${rate?.maxAdvance}`].rates.forEach(rt => {
        if (rate?.tier === rt.tier) {
          rt.standardRcf = rate?.standardRcf ?? rt.standardRcf;
          rt.subventedRcf = rate?.subventedRcf ?? rt.subventedRcf;
        }
      });
    });

  return rates;
};

export const transformAdvanceRates = (advanceRates: Record<string, { rates: AdvanceRates[] }>) => {
  const rates: AdvanceRates[] = [];

  Object.values(advanceRates).forEach(item => {
    const filterDefaultRates = item.rates.filter(rt => rt.standardRcf !== 999 && rt.subventedRcf !== 999);
    filterDefaultRates.forEach(rate => {
      rates.push(rate);
    });
  });

  return rates;
};
