import { AdminConstants } from 'oat-admin-common';
import { useState } from 'react';
import GetRcfsAndResidualsData from '../../../components/GetRcfsAndResidualsData';
import {
  Offering,
  ResidualValue,
  SeriesMapping,
  SeriesProfile,
  SetResidualValue,
  SetStandardRcf,
  SetSubvenedRcf,
  useGetCreateLeaseDataQuery,
  useGetSeriesMappingQuery,
} from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import StdLeaseRcfs from '../../../models/StdLeaseRcfs';
import useStores from '../../../stores/useStores';
import { assignNumberValue } from '../../../utils/assignValue';
import getDateParts from '../../../utils/getDateParts';
import LoadingPage from '../../LoadingPage';
import GetEnhCostShare from '../../ProgramDetails/GetEnhCostShare';
import CreateLeaseComponent from './CreateLeaseComponent';
import EFCSeriesData from './EFCSeriesData';
import useUserInfo from '../../../utils/useUserInfo';

const { OPTION_TYPE_NAMES } = AdminConstants;

const CreateLease = () => {
  const { region, period, profile, offerId, offerTerm } = useUrlParams();
  const { isLexusUser } = useUserInfo();

  const {
    userInfoStore,
    createLeaseStore,
    rcfsResidualsStore,
    seriesMappingStore: { seriesMapping, seriesMappingLoaded, setSeriesMapping },
    programDetailsStore: { setSeriesProfile },
  } = useStores();

  const { loading, error, data } = useGetCreateLeaseDataQuery({ variables: { offeringId: period, seriesProfileId: profile, regionCode: region } });

  const {
    data: seriesMappingData,
    loading: seriesMappingLoading,
    error: seriesMappingError,
  } = useGetSeriesMappingQuery({ variables: { brand: userInfoStore.userInfo.brand }, skip: seriesMappingLoaded });

  // Adding observable to CreateLease to watch seriesMappingLoaded will result in errors
  // Need to control the load state manually
  const [isSeriesMappingLoaded, setIsSeriesMappingLoaded] = useState(seriesMappingLoaded);

  const breadCrumbs = [{ name: userInfoStore.properties.abbrName }];
  const isLoading = loading || seriesMappingLoading;
  const isError = error || seriesMappingError;

  if (isLoading || isError) {
    return <LoadingPage breadCrumbs={breadCrumbs} error={!!isError} />;
  }

  if (seriesMappingData) {
    setSeriesMapping(seriesMappingData.seriesMapping as SeriesMapping[]);
    setIsSeriesMappingLoaded(true);
  }

  if (data && isSeriesMappingLoaded) {
    createLeaseStore.initData(
      data.offering as Offering,
      data.seriesProfile as SeriesProfile,
      userInfoStore.isLexus(),
      seriesMapping,
      data.dealerGross?.dealerGross,
      offerId,
      offerTerm,
    );

    setSeriesProfile(data.seriesProfile as SeriesProfile, region, isLexusUser(), seriesMapping);
  }

  // callback for after getting rcfs and residuals
  const handleRcfsResidualsLoad = (stdLeaseRcfs: StdLeaseRcfs[], residualValues: ResidualValue[]) => {
    rcfsResidualsStore.setStandardRcfs(stdLeaseRcfs);
    rcfsResidualsStore.setResiduals(residualValues);
    createLeaseStore.setNationalRcfs(stdLeaseRcfs, assignNumberValue(createLeaseStore.seriesProfile.ncsRcf));
  };

  const handleSETRcfsResidualsLoad = (stdLeaseRcfs: SetStandardRcf[], residualValues: SetResidualValue[]) => {
    rcfsResidualsStore.setStandardRcfs(stdLeaseRcfs);
    rcfsResidualsStore.setResiduals(residualValues);
    createLeaseStore.setNationalRcfs(stdLeaseRcfs, assignNumberValue(createLeaseStore.seriesProfile.ncsRcf), true);
  };

  const handleSETSubvenedRcfs = (setSubRcfs: SetSubvenedRcf) => {
    rcfsResidualsStore.setSubvenedLeaseRcfs(setSubRcfs);
  };

  const { year, month } = getDateParts(createLeaseStore.offering.startDate);

  return (
    <GetRcfsAndResidualsData
      brand={userInfoStore.userInfo.brand}
      offeringYear={year}
      offeringMonth={month}
      offeringId={period}
      onLoad={handleRcfsResidualsLoad}
      onSETLoad={handleSETRcfsResidualsLoad}
      onSETLoadSubRcfs={handleSETSubvenedRcfs}
      seriesName={createLeaseStore.nonAdvertisedData.fields.series}
      seriesYear={createLeaseStore.nonAdvertisedData.fields.modelYear}
      modelCodes={createLeaseStore.vehicles.map(vehicle => Number(vehicle.modelCode))}
    >
      <EFCSeriesData>
        <GetEnhCostShare optionTypeName={OPTION_TYPE_NAMES.LEASE}>
          <CreateLeaseComponent />
        </GetEnhCostShare>
      </EFCSeriesData>
    </GetRcfsAndResidualsData>
  );
};

export default CreateLease;
