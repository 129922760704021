import { MaintenanceLayout, OATToast, ResetLayout, TooltipStyles } from 'oat-common-ui';
import { HashRouter } from 'react-router-dom';
import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css';
import '../node_modules/oat-common-ui/dist/index.css';
import Routes from './Routes';

function App() {
  return (
    <MaintenanceLayout url={'/maintenance.json'}>
      <ResetLayout />
      <TooltipStyles />
      <HashRouter>
        <OATToast>
          <Routes />
        </OATToast>
      </HashRouter>
    </MaintenanceLayout>
  );
}

export default App;
