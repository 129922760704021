import { observer } from 'mobx-react-lite';
import { formatDollars, OATIcon, Table, TableContainer } from 'oat-common-ui';
import RgnlAltModel from '../../../pages/Summary/models/RgnlAltModel';
import NumberInput from '../../NumberInput';
import PenRateErrors from '../PenRateErrors';
import styles from '../styles.module.scss';
import PenRateModalTableRow from './PenRateModalTableRow';

type Props = {
  rgnlAlt: RgnlAltModel;
  currentOfferId: string;
};

const PenRateModalTable = ({ rgnlAlt, currentOfferId }: Props) => {
  const HeaderRow = () => {
    return (
      <thead>
        <tr>
          <th className={styles.currentOffer} />
          <th>OFFER</th>
          <th className={styles.column}>
            PENETRATION RATE {!rgnlAlt.editPenRate && <OATIcon id={`edit-pr-${rgnlAlt.id}`} colorTheme="blue" pointer icon="edit" onClick={() => rgnlAlt.toggleEditPenRate()} />}
          </th>
          <th className={styles.column}>
            FORECASTED SALES {rgnlAlt.editPenRate && <OATIcon id={`edit-fs-${rgnlAlt.id}`} colorTheme="blue" pointer icon="edit" onClick={() => rgnlAlt.toggleEditPenRate()} />}
          </th>
        </tr>
      </thead>
    );
  };

  return (
    <div className={styles.penRateTable}>
      <TableContainer className={styles.tableContainer}>
        <Table>
          <HeaderRow />
          <tbody className={styles.whiteBg}>
            {rgnlAlt.cash.map(item => {
              return (
                <PenRateModalTableRow
                  key={`pt-cash-${item.id}`}
                  rgnlAlt={rgnlAlt}
                  item={item}
                  currentOfferId={currentOfferId}
                  editPenRate={rgnlAlt.editPenRate}
                  offerDetails={item.name}
                  offerStyles={styles.cashRow}
                />
              );
            })}

            {rgnlAlt.apr.map(item => {
              return (
                <PenRateModalTableRow
                  key={`pt-apr-${item.id}-${item.tier}`}
                  rgnlAlt={rgnlAlt}
                  item={item}
                  currentOfferId={currentOfferId}
                  editPenRate={rgnlAlt.editPenRate}
                  offerDetails={`${item.name} Tier ${item.tier}`}
                  offerStyles={styles.aprRow}
                />
              );
            })}

            {rgnlAlt.lease.map(item => {
              return (
                <PenRateModalTableRow
                  key={`pt-lease-${item.uid}-${item.term}`}
                  rgnlAlt={rgnlAlt}
                  item={item}
                  currentOfferId={currentOfferId}
                  editPenRate={rgnlAlt.editPenRate}
                  offerDetails={`${item.name} ${item.term}mo`}
                  offerStyles={styles.leaseRow}
                />
              );
            })}

            {rgnlAlt.misc.map(item => {
              return (
                <PenRateModalTableRow
                  key={`pt-misc-${item.id}`}
                  rgnlAlt={rgnlAlt}
                  item={item}
                  currentOfferId={currentOfferId}
                  editPenRate={rgnlAlt.editPenRate}
                  offerDetails={`${item.name}`}
                  offerStyles={styles.cashRow}
                />
              );
            })}
            <tr className={styles.footerRow}>
              <td colSpan={2} />
              <td>
                <NumberInput disabled value={rgnlAlt.totalPenRates} percentageSign />
              </td>
              <td>{formatDollars(rgnlAlt.totalSales.toString())}</td>
            </tr>
          </tbody>
        </Table>
      </TableContainer>
      <PenRateErrors rgnlAlt={rgnlAlt} />
    </div>
  );
};

export default observer(PenRateModalTable);
