import clsx from 'clsx';
import { ToggleButton } from 'oat-common-ui';
import styles from './styles.module.scss';

interface Props {
  selected: boolean;
  toggleSelected: () => void;
  disabled?: boolean;
  id?: string;
  containerClass?: string;
}

const SlideToggle = ({ containerClass, selected, ...rest }: Props) => {
  return (
    <ToggleButton
      containerClass={clsx(styles.slideToggle, selected && styles.selected, containerClass)}
      buttonClass={clsx(styles.toggleBtn, selected && styles.toggleBtnSelected)}
      selected={selected}
      {...rest}
    />
  );
};

export default SlideToggle;
