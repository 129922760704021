import { makeAutoObservable } from 'mobx';
import { AdminConstants } from 'oat-admin-common';
import { assignNumberValue } from 'oat-common-ui';
import { Status } from '../../../constants/global';
import { CombinedOffers, Offering, RgnlAlt, SeriesProfile, SeriesYear, Vehicle } from '../../../gql/generated';
import { assignStringValue } from '../../../utils/assignValue';
import PenRateModel from './PenRateModel';
import RgnlAltModel from './RgnlAltModel';

const { OPTION_TYPE_NAMES, OfferTypes, RegionCodes } = AdminConstants;

class ProfileModel {
  id = '';
  rev = '';
  offeringId = '';
  nationalId = '';
  isNational = false;
  regionCode = '';
  isCustomSeries = false;
  isEarningsOnly = false;
  isConfirmedSeries = false;
  isConfirmedView = false;
  name = '';
  note = '';
  modelNote = null;
  isComplete = false;
  ncsRate = null;
  ncsRcf = null;
  series: SeriesYear[] = [];
  rgnlAlts: RgnlAltModel[] = [];
  rgnAltOffersMap: Map<string, CombinedOffers[]> = new Map();
  groups: string[] = [];
  isExpanded = false;
  addNote = false;
  inputIsDisabled = true;
  isValid = true;
  vehicles: Vehicle[] = [];

  constructor(data: SeriesProfile, offering?: Offering) {
    makeAutoObservable(this);

    this.rev = data.rev;
    this.id = data.id;
    this.series = data.series;
    this.name = data.name;
    this.note = assignStringValue(data.note);
    this.isCustomSeries = data.isCustomSeries;
    this.groups = data.groups;
    this.vehicles = data.vehicles;
    this.regionCode = data.regionCode;
    this.isEarningsOnly = data.isEarningsOnly;

    if (data.rgnlAlts) {
      this.validateOffers(data.rgnlAlts.find(rgnAlt => rgnAlt.isSelected)?.offers);

      data.rgnlAlts.forEach(rgnAlt => {
        this.rgnAltOffersMap.set(rgnAlt.id, rgnAlt.offers as CombinedOffers[]);
      });

      this.rgnlAlts = this.processRgnlAlts(
        data.rgnlAlts
          .sort((a, b) => assignNumberValue(a.raNumber) - assignNumberValue(b.raNumber))
          .map(item => {
            return new RgnlAltModel(item, offering);
          }),
      );

      this.isConfirmedSeries = this.hasConfirmedRgnAlt();
      this.isConfirmedView = this.hasConfirmedRgnAlt();
    }

    if (this.isEarningsOnly && this.rgnlAlts.length) {
      this.rgnlAlts[0].isSelected = true;
    }
  }

  validateOffers = (combinedOffers?: CombinedOffers[] | null) => {
    if (!combinedOffers) {
      return false;
    }

    let isValid = true;

    combinedOffers.forEach(combinedOffer => {
      const regionalOffer = combinedOffer.regional;
      if (regionalOffer.optionTypeName === OPTION_TYPE_NAMES.FINAL_PAY && !Boolean(regionalOffer.cashDetails?.note)) {
        isValid = false;
      } else if (regionalOffer.offerType === OfferTypes.LEASE) {
        regionalOffer.leaseDetails?.forEach(leaseDetail => {
          if ((leaseDetail.isAdvertised && this.regionCode !== RegionCodes.GST && !Boolean(leaseDetail.vin)) || leaseDetail.isBadVin || leaseDetail.isVinStopSale) {
            isValid = false;
          }
        });
      }
      // TODO: implement E&R validation when BE is done
    });

    this.isValid = isValid;
  };

  selectRgnlAlt = (id: string) => {
    this.rgnlAlts.forEach(item => {
      item.isSelected = item.id === id ? !item.isSelected : false;
    });

    this.isConfirmedSeries = false;
  };

  resetRgnAltToNational = (regionalAlt: RgnlAlt) => {
    this.rgnlAlts = this.rgnlAlts.map(rgnAlt => {
      if (rgnAlt.id === regionalAlt.id) {
        return new RgnlAltModel(regionalAlt);
      }

      return rgnAlt;
    });
  };

  setIsConfirmedView = (isConfirmed: boolean) => {
    this.isConfirmedView = isConfirmed;
  };

  setIsConfirmedSeries = (isConfirmed: boolean) => {
    this.isConfirmedSeries = isConfirmed;
  };

  updateRev = (rev: string | undefined) => {
    this.rev = rev || '';
  };

  getSelectedRgnAlt = (): RgnlAltModel | undefined => this.rgnlAlts.find(rgnlAlt => rgnlAlt.isSelected);

  setIsExpanded = (isExpanded: boolean) => {
    this.isExpanded = isExpanded;
  };

  setAddNote = (addNote: boolean) => {
    this.addNote = addNote;
  };

  updateNote = (note: string) => {
    this.note = note;
  };

  setInputIsDisabled = (isDisabled: boolean) => {
    this.inputIsDisabled = isDisabled;
  };

  hasConfirmedRgnAlt() {
    return this.rgnlAlts.some(rgnAlt => rgnAlt.isSeriesConfirmed && rgnAlt.isSelected);
  }

  processRgnlAlts = (rgnlAlts: RgnlAltModel[]) => {
    /*
     ** For Submitted Status
     Regional Enhancement, Enhance & Refined offers, Regional Standalone offers,
     and/or Regional Standalone Series Profile that have not carried over from the previous month (offers that have a changed PNVS)
     then those Series Profiles will be given the submitted status for National Review

    ** For Meets Guidelines Status
    - IF a Series Profiles contains all of its offerings that have NOT Changed PNVS from the previous month or PNVS = $0,
      then those Series Profiles will automatically be given a Meets Guidelines Status and not be included in Submitted Review
    - If All other offers are at the National Offerings, then the Series Profile will be given the Meets Guidelines status
      and not be available to review in the Submitted Program
    - If RYO was taken as Earnings and no Regional Offers were added, then the Series Profile will be given Meets Guidelines
      and not be available to review in the Submitted Program
    - If a Standalone Series Profile or A standalone Regional offer is part of a SP, but does not change PNVS from the previous
      month OR is at $0, then it will automatically be put in Meets Guidelines.
    */

    for (const rgnlAlt of rgnlAlts) {
      const { apr, lease, misc, cash } = rgnlAlt;
      const offers = [...apr, ...lease, ...cash, ...misc];

      this.processOffers(offers);
    }

    return rgnlAlts;
  };

  processOffers = (offers: PenRateModel[]) => {
    // this is incomplete
    const atLeaseOneOfferIs = offers.some(offer => {
      return offer.isStandAlone || offer.isEnhanced;
    });

    if (atLeaseOneOfferIs) {
      this.getSelectedRgnAlt()?.updateStatus(Status.SUBMITTED);
      return;
    }

    // need to add conditions for Meets Guidelines Status
  };

  get includedVehicles() {
    return this.vehicles.filter(vehicle => vehicle.isInclusion);
  }
}

export default ProfileModel;
