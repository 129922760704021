import { CreateLeaseModels } from 'oat-common-ui';
import CreateAdLeaseModel from '../../../../models/CreateAdLeaseModel';
import MostRecentConfig from '../../../../models/MostRecentConfig';
import { formatInventory } from '../../../utils/utils';

export const handleSelectTrimFirstSteps = (advertisedData: CreateAdLeaseModel, trim: CreateLeaseModels.TrimItem) => {
  advertisedData.selectTrim(trim);
  advertisedData.setCarJelly(trim.carJelly);
  advertisedData.clearMostRecentConfig();
};

export const handleSetMostRecentConfig = (advertisedData: CreateAdLeaseModel, mostRecentConfig: MostRecentConfig) => {
  const { success, salesPeriod, configuration, modelCode } = mostRecentConfig;

  if (success && configuration && modelCode) {
    const foundConfig = advertisedData.findConfig(modelCode);

    advertisedData.setMostRecentConfig({
      salesPeriod,
      configuration,
      modelCode,
      count: Number(foundConfig?.count) || 0,
      inventory: formatInventory(foundConfig?.inventory || 0),
      optionsTotal: foundConfig?.optionsTotal || 0,
    });
  }
};
