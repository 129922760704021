import ExpTableHeadVal from '../ExpTableHeadVal';

const LeaseTableHeaderValues = () => {
  return (
    <tr>
      <ExpTableHeadVal val=" " />
      <ExpTableHeadVal val="Tiers" />
      <ExpTableHeadVal val="RCF" />
      <ExpTableHeadVal val="Subv." />
      <ExpTableHeadVal
        val={
          <>
            Penetration
            <br />
            Rate
          </>
        }
      />
      <ExpTableHeadVal val="PNV" />
    </tr>
  );
};

export default LeaseTableHeaderValues;
