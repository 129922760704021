import clsx from 'clsx';
import { AdminConstants } from 'oat-admin-common';
import { InlineInputLabel, NumberInput, OATIcon } from 'oat-common-ui';
import TooltipList, { TooltipListItem } from '../../../../../components/TooltipList/TooltipList';
import { EnhTfsCostShare } from '../../../../../gql/generated';
import useGetTfsLabel from '../../../../../utils/useGetTfsLabel';
import styles from './styles.module.scss';

interface Props {
  costShareDates: EnhTfsCostShare[];
}

const { OPTION_TYPE_NAMES } = AdminConstants;

const EnhCostShareDatesTooltip = ({ costShareDates }: Props) => {
  const tfsLabel = useGetTfsLabel();
  const optionTypeName = costShareDates[0].optionTypeName;

  return (
    <div className={styles.tooltipMessage}>
      <div className={styles.title}>{`Enhanced ${tfsLabel} Cost Share Availabilities`}</div>
      <div data-testid="ecs-date-items">
        {costShareDates.map((ecs, i) => {
          return (
            <div className={clsx(styles.costShareRow, styles.marginBottom)} key={`${ecs.optionTypeName}-${i}`}>
              <TooltipList>
                <TooltipListItem>
                  <InlineInputLabel labelComponent={<span className={styles.grayColor}>Adjustment: </span>}>
                    <NumberInput
                      id={`${ecs.optionTypeName}-${i}`}
                      readOnly
                      value={ecs.value}
                      className={clsx(styles.tooltipInput, styles.text)}
                      rcf={optionTypeName === OPTION_TYPE_NAMES.LEASE}
                      rate={optionTypeName === OPTION_TYPE_NAMES.APR}
                    />
                  </InlineInputLabel>
                </TooltipListItem>
              </TooltipList>

              <div className={clsx(styles.dividerContainer, optionTypeName === OPTION_TYPE_NAMES.LEASE ? styles.leaseMargins : styles.aprMargins)}>
                <div className={styles.divider} />
              </div>

              <TooltipList>
                <TooltipListItem className={styles.dates}>
                  <div className={styles.startDate}>
                    <OATIcon icon="calendar" className={styles.grayColor} />
                    <span className={clsx(styles.marginLeft, styles.text)} id={`${ecs.optionTypeName}-${i}-startDate`}>
                      {ecs.startDate}
                    </span>
                  </div>
                  <div className={styles.datesDividerContainer}>
                    <div className={styles.datesDivider} />
                  </div>
                  <div className={clsx(styles.endDate, styles.text)} id={`${ecs.optionTypeName}-${i}-endDate`}>
                    {ecs.endDate}
                  </div>
                </TooltipListItem>
              </TooltipList>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EnhCostShareDatesTooltip;
