import { observer, useLocalObservable } from 'mobx-react-lite';
import { useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { useSaveFundingSplitsMutation } from '../../../../../../gql/generated';
import LeaseCardModel from '../../../../models/LeaseCardModel';
import LeaseFormModel from '../../../../models/LeaseFormModel';
import FundingSplitsModal from '../../../FundingSplitsModal';
import FundingSplitTerm from '../../../FundingSplitsModal/FundingSplitTerm';
import FundingSplitsTable from '../../../FundingSplitsModal/FundingSplitsTable';
import { initialize, transformLeaseFundingSplit } from './utils';

interface Props {
  leaseCard: LeaseCardModel;
  leaseForm: LeaseFormModel;
  onClose: () => void;
  onSubmit: (tfsShare: string) => void;
}

const LeaseFundingSplitModal = ({ leaseCard, leaseForm, onClose, onSubmit }: Props) => {
  const fundingTerm = useLocalObservable(() => new FundingSplitTerm(leaseForm.inputs.term, initialize(leaseForm)));
  const [saveFundingSplits] = useSaveFundingSplitsMutation();
  const { error } = useToast();

  const handleSubmit = async () => {
    try {
      const { tfsShare, payload } = transformLeaseFundingSplit(leaseCard.id, leaseCard.rev, leaseForm, fundingTerm.splits);
      const res = await trackPromise(
        saveFundingSplits({
          variables: {
            input: payload,
          },
        }),
      );
      leaseCard.updateRev(res.data?.saveFundingSourceSplits.offer.rev);
      leaseForm.updateFundingSourceSplits(payload.fundingSourceSplits[0].splits);

      onSubmit(tfsShare.toString());
    } catch (e) {
      error((e as Error).message);
    }
  };

  const fundingSplitsIsDisabled = leaseCard.isEnhCostShareUpdated && !Boolean(leaseCard.enhCostShareOfferId);

  return (
    <FundingSplitsModal onClose={onClose} onSubmit={handleSubmit} hasError={fundingTerm.hasError}>
      <FundingSplitsTable fundingTerm={fundingTerm} lease isDisabled={fundingSplitsIsDisabled} />
    </FundingSplitsModal>
  );
};

export default observer(LeaseFundingSplitModal);
