import { SeriesProfile, useGetContestNumbersQuery } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import { SETContestNumbersByType } from '../../../models/SETContestNumber';
import useStores from '../../../stores/useStores';
import useUserInfo from '../../../utils/useUserInfo';
import LoadingPage from '../../LoadingPage';
import GetEnhCostShare from '../../ProgramDetails/GetEnhCostShare';
import useCreateOffer from '../useCreateOffer';
import { createMiscCardForCreate } from '../utils/createMiscCard';
import CreateMiscComponent from './CreateMiscComponent';

const CreateMisc = () => {
  const {
    userInfoStore,
    createOfferStore: { setMisc, miscCard },
    programDetailsStore: { setSETContestNumbersByType },
  } = useStores();
  const { period, region } = useUrlParams();
  const { isSETUser } = useUserInfo();
  const { data: setData, loading: setLoading, error: setError } = useGetContestNumbersQuery({ variables: { offeringId: period }, skip: !isSETUser() });
  const { loading, error, data } = useCreateOffer();

  const breadCrumbs = [{ name: userInfoStore.properties.abbrName }];

  const isLoading = setLoading || loading;
  const hasError = setError || error;

  if (isLoading || hasError) {
    return <LoadingPage breadCrumbs={breadCrumbs} error={!!hasError} />;
  }

  if (setData) {
    setSETContestNumbersByType(setData.contestNumbers.setContestNumbers as SETContestNumbersByType[]);
  }

  if (data) {
    const card = createMiscCardForCreate(data.offering.startDate, data.offering.endDate, region, data.seriesProfile as SeriesProfile);
    setMisc(card);
  }

  return (
    <GetEnhCostShare optionTypeName={miscCard.fields.type}>
      <CreateMiscComponent />
    </GetEnhCostShare>
  );
};

export default CreateMisc;
