import clsx from 'clsx';
import { formatDollars, InlineInputLabel, OATIcon } from 'oat-common-ui';
import styles from './styles.module.scss';

interface Props {
  cash: string;
  isEnhanced: boolean;
  name: string;
}

const OfferValue = ({ cash, isEnhanced, name }: Props) => {
  return (
    <div className={styles.containerList}>
      <InlineInputLabel labelComponent={<span className={clsx(styles.expTableTd, styles.expTheadTd)}>{name}</span>}>
        <span className={clsx(styles.expTableTdValue, isEnhanced && styles.addBold)}>${formatDollars(cash)}</span>
        {isEnhanced && (
          <span className={styles.iconLeft}>
            <OATIcon icon="enhancement" colorTheme="orange" />
          </span>
        )}
      </InlineInputLabel>
    </div>
  );
};

export default OfferValue;
