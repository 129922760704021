import { dateStringToDate } from 'oat-common-ui';

const dateWithinDateRangeValidator = (date: Date | string | undefined, dateFrom: Date | string | undefined, dateTo: Date | string | undefined) => {
  if (!date || !dateFrom || !dateTo) {
    return false;
  }

  const inputDate = dateStringToDate(date);
  const minDate = dateStringToDate(dateFrom);
  const maxDate = dateStringToDate(dateTo);

  return minDate.getTime() <= inputDate.getTime() && inputDate.getTime() <= maxDate.getTime();
};

export default dateWithinDateRangeValidator;
