import { Status } from '../../../constants/global';
import { RegionalStatus } from '../../../stores/RegionalStatusStore';

export const processStatus = (item: RegionalStatus, isNational: boolean) => {

  if (isNational && (item.status === Status.NEW || item.status === Status.NO_RESPONSE) && item.isAtc) {
    return Status.ATC_DRAFT;
  }

  if (isNational && item.status === Status.NEW) {
    return Status.NO_RESPONSE;
  }

  return item.isAtc ? `ATC - ${item.status}` : item.status;
};
