import { observer } from 'mobx-react-lite';
import { SettingsLayout } from 'oat-common-ui';
import MainHeader from '../../components/MainHeader';
import { SettingsBreadCrumbs, SettingsNav } from '../../components/SettingsComponents';
import useStores from '../../stores/useStores';
import SeriesSortingList from './components/SeriesSortingList';

const SeriesSortingComponent = () => {
  const { seriesSortingStore, userInfoStore } = useStores();

  return (
    <>
      <MainHeader showMiscLinks={false} breadCrumbs={SettingsBreadCrumbs('Dashboard', '')} />
      <SettingsLayout settings={SettingsNav(userInfoStore.isNational())}>
        <SeriesSortingList seriesSortingList={seriesSortingStore.seriesSorting} />
      </SettingsLayout>
    </>
  );
};

export default observer(SeriesSortingComponent);
