import axios, { AxiosResponse } from 'axios';

interface Image {
  full_image: string;
}

interface Applicable {
  trim: string;
  override: {
    title: string;
    images: Image;
    price: {
      msrp: string;
    };
    description: string;
  };
}

interface Category {
  code: string;
  title: string;
  description: string;
  type: string;
  price: {
    msrp: string;
  };
  applicable: Applicable[];
  valid: boolean;
  title_disclaimer: string;
  images: Image;
  categories: Category[];
  description_disclaimer: string;
}

export interface SeriesList {
  series: string;
  options: VehicleContentOption[];
}

export interface YearsList {
  year: string;
  series_list: SeriesList[];
}

export interface VehicleContentResponse {
  years_list: YearsList[];
}

export interface VehicleContentOption {
  code: string;
  title: string;
  description: string;
  type: string;
  price: {
    msrp: string;
  };
  applicable: Applicable[];
  valid: boolean;
  title_disclaimer: string;
  images: Image;
  categories: Category[];
  description_disclaimer: string;
}

export interface SeriesList {
  series: string;
  options: VehicleContentOption[];
}

export interface YearsList {
  year: string;
  series_list: SeriesList[];
}

export interface VehicleContentResponse {
  years_list: YearsList[];
}

const getAccessoriesFromVehicleContentResponse = (vehicleContentResponse: VehicleContentResponse, seriesYear: string): string[] => {
  const allOptions: VehicleContentOption[] | undefined = vehicleContentResponse.years_list.find(year => year.year === seriesYear)?.series_list[0].options;
  const lioOptions = allOptions?.filter(option => option.type === 'LIO');

  return lioOptions?.map(liOption => liOption.code.replace('G_', '')) || [];
};

export const getGstCompatibileAccessories = async (series: string, seriesYear: string) => {
  const params = {
    year: seriesYear,
    series,
  };

  try {
    const vehiclecontent: AxiosResponse<VehicleContentResponse> = await axios.get(`${process.env.REACT_APP_EFC_MS_URL}/vehiclecontent/v2/TOY/600/EN/options`, {
      params,
      headers: {
        'x-api-key': process.env.REACT_APP_EFC_MS_API_KEY as string,
      },
    });

    return getAccessoriesFromVehicleContentResponse(vehiclecontent.data, seriesYear);
  } catch (_error) {
    return [];
  }
};
