import { AdminConstants } from 'oat-admin-common';

export const getCompatabilityCount = (compList: string[] | undefined) => {
  const length = compList?.length;
  return `${!length ? 0 : length} Compatible Offer${(length && length > 1) || !length ? 's' : ''}`;
};

export const returnStatesList = (regionCode: string, includedStates: string[]) => {
  for (const region of AdminConstants.REGIONS) {
    const statesList = region.statesList.split(',');

    if (region.code === regionCode) {
      const statesListStr = statesList.slice().sort().join(',');
      const includedStatesStr = includedStates.slice().sort().join(',');

      if (statesListStr === includedStatesStr) {
        return 'All';
      }

      return statesList
        .filter(state => includedStates.includes(state))
        .sort()
        .join(',');
    }
  }

  return undefined;
};

export const getAllStatesForRegion = (regionCode: string) => {
  let allStates: string[] = [];

  const region = AdminConstants.REGIONS.find(region => region.code === regionCode);

  if (region) {
    allStates = region.statesList.split(',').sort();
  }

  return allStates;
};

export const formatInvoiceDate = (invoiceDate: string) => {
  const dateArr = invoiceDate.split('-');
  const removedDate = dateArr.splice(0, 1);
  dateArr.push(removedDate[0]);

  return dateArr.join('/');
};
