import { observer } from 'mobx-react-lite';
import { CreateLeaseModels, SelectTrimFlow } from 'oat-common-ui';
import apolloClient from '../../../../../apolloClient';
import { FEATURE_OR_4641 } from '../../../../../constants/global';
import { GetMostRecentConfigDocument } from '../../../../../gql/generated';
import useUrlParams from '../../../../../hooks/useUrlParams';
import EFCStore from '../../../../../stores/EFCStore';
import useStores from '../../../../../stores/useStores';
import useUserInfo from '../../../../../utils/useUserInfo';
import CreateAdLeaseModel from '../../models/CreateAdLeaseModel';
import getConfigListFromDD365 from '../utils/getConfigListFromDD365';
import getConfigListFromEFC from '../utils/getConfigListFromEFC';
import setStopSaleData from '../utils/setStopSaleData';
import { mapToPortsMap, processVehiclesAccordion, returnNoVehiclesError } from '../utils/utils';
import { handleSelectTrimFirstSteps, handleSetMostRecentConfig } from './table/tables/tableUtils';

const SelectTrim = () => {
  const {
    createLeaseStore: { setBlockUIText, advertisedData, setSelectedTrim, nationalOfferId, isEnhanced },
    userInfoStore: {
      userInfo: { brand },
    },
    efcStore,
    dd365Store: { getDD35Inventory },
  } = useStores();
  const { selectTrimFields } = advertisedData;
  const { region, period } = useUrlParams();

  const detail = advertisedData.tab === 1 ? advertisedData.selectTrimFields : advertisedData.enterVinFields;
  const { selectVin } = advertisedData;
  const { getInventoryApi } = efcStore;
  const { isSETUser, isLexusUser } = useUserInfo();

  const setNoVehiclesError = () => {
    advertisedData.setError(returnNoVehiclesError('No docs found', advertisedData.selectTrimFields.trim?.trimTitle || ''));
  };

  // Reverts back to first step of advertised
  const handleToTrimSelection = () => {
    if (advertisedData.tab === 1) {
      advertisedData.backToTrimSelectionStep();
    } else {
      advertisedData.setEnterVinStep(2);
    }
  };

  const handleSelectTrim = async (trim: CreateLeaseModels.TrimItem) => {
    setSelectedTrim(trim);
    handleSelectTrimFirstSteps(advertisedData, trim);
    try {
      setBlockUIText('Fetching Configurations');

      if (isSETUser()) {
        const [inventoryData, mostRecentConfigData] = await Promise.all([
          getDD35Inventory(region, advertisedData.seriesYear, trim.code),
          apolloClient.query({
            query: GetMostRecentConfigDocument,
            variables: {
              offeringId: period,
              series: advertisedData.series,
              seriesYear: advertisedData.seriesYear,
            },
          }),
        ]);

        if (inventoryData && inventoryData.items) {
          if (!inventoryData.items.length) {
            setNoVehiclesError();
            return;
          }

          advertisedData.setConfigList(getConfigListFromDD365(inventoryData));
        }
        handleSetMostRecentConfig(advertisedData, mostRecentConfigData.data.mostRecentConfig);
      } else {
        const [inventoryData, mostRecentConfigData] = await Promise.all([
          getInventoryApi(region, trim.code, advertisedData.seriesYear, advertisedData.series, brand),
          apolloClient.query({
            query: GetMostRecentConfigDocument,
            variables: {
              offeringId: period,
              series: advertisedData.series,
              seriesYear: advertisedData.seriesYear,
            },
          }),
        ]);

        if (!inventoryData.body.response.docs.length) {
          setNoVehiclesError();
          return;
        }

        detail.vehiclesAccordion = processVehiclesAccordion(detail.vehiclesAccordion, trim.code, detail.isInclusions, Boolean(nationalOfferId), isEnhanced);

        advertisedData.setConfigList(getConfigListFromEFC(inventoryData));
        handleSetMostRecentConfig(advertisedData, mostRecentConfigData.data.mostRecentConfig);
      }
    } catch (e) {
      advertisedData.setError(returnNoVehiclesError('No docs found', advertisedData.selectTrimFields.trim?.trimTitle || ''));
    } finally {
      setBlockUIText('');
    }
  };

  const handleToConfigSelection = () => {
    if (advertisedData.tab === 1) {
      advertisedData.backToConfigSelectionStep();
    } else {
      advertisedData.setEnterVinStep(2);
    }
  };

  // Fetchies vin list data from efc, or grabs from dd365 inventory response
  // Renders VIN in table before fetching stop sale data
  const getVinListData = async (
    region: string,
    trimCode: string,
    seriesYear: string,
    config: string,
    brand: string,
    isSET: boolean,
    efcStore: EFCStore,
    configItem?: CreateLeaseModels.ConfigItem,
  ) => {
    const { getVinListApi } = efcStore;

    // get vin list data
    const vinList: CreateLeaseModels.VinItem[] = [];

    if (isSET && configItem) {
      configItem.vinItems.forEach(item => vinList.push(item));
    } else {
      const data = await getVinListApi(region, trimCode, seriesYear, config, brand);

      if (data.inventoryVehicles.length > 0) {
        for (const vin of data.inventoryVehicles) {
          // create vin item and add to vin list
          const vinItem = new CreateLeaseModels.VinItem();
          vinItem.setFromEFC(vin);
          vinList.push(vinItem);
        }
      }
    }

    return {
      vinList,
    };
  };
  // Loops through vin list to grab stop sale values. Its throttled to handle each VIN at a time to prevent errors.
  const getStopSalesForVinList = async (adLease: CreateAdLeaseModel, vinList: CreateLeaseModels.VinItem[], brand: string, { getStopSaleSSC, getStopSaleVIS }: EFCStore) => {
    const vinItems: CreateLeaseModels.VinItem[] = [];
    for (const vinItem of vinList) {
      try {
        const promises: [Promise<CreateLeaseModels.EFCStopSaleSSC>, Promise<CreateLeaseModels.EFCStopSaleVIS>] = [
          getStopSaleSSC(vinItem.vin, brand),
          getStopSaleVIS(vinItem.vin, brand),
        ];
        const res = await Promise.all(promises);
        setStopSaleData(vinItem, res[0], res[1]);

        vinItems.push(vinItem);
      } catch (e) {
        vinItems.push(vinItem);
      }
    }
    adLease.setVinList(vinItems);
  };

  const handleSelectConfig = async (config: CreateLeaseModels.ConfigItem) => {
    advertisedData.selectConfig(config);

    try {
      setBlockUIText('Fetching VIN data');

      // get vin list data
      const { vinList } = await getVinListData(region, detail.trim?.code || '', advertisedData.seriesYear, config.config, brand, isSETUser(), efcStore, config);
      advertisedData.setVinList(vinList);

      if (vinList.length > 0) {
        getStopSalesForVinList(advertisedData, vinList, brand, efcStore);
      }
    } catch (e) {
      advertisedData.setError(returnNoVehiclesError('No detail found', detail.trim?.trimTitle || ''));
    } finally {
      setBlockUIText('');
    }
  };

  const handleSelectVin = (vin: CreateLeaseModels.VinItem) => {
    selectVin(vin);
  };

  return (
    <SelectTrimFlow
      trimItem={selectTrimFields.trim}
      trimQuery={selectTrimFields.trimQuery}
      handleTrimQueryChange={advertisedData.setTrimQuery}
      configItem={selectTrimFields.config}
      configQuery={selectTrimFields.configQuery}
      handleConfigQueryChange={advertisedData.setConfigQuery}
      vinItem={selectTrimFields.vin}
      dealerCodeQuery={selectTrimFields.dealerCodeQuery}
      handleDealerCodeQueryChange={advertisedData.setDealerCodeQuery}
      handleTrimClick={handleToTrimSelection}
      handleSelectTrim={handleSelectTrim}
      handleConfigClick={handleToConfigSelection}
      handleSelectConfig={handleSelectConfig}
      handleSelectVin={handleSelectVin}
      showTrimTable={advertisedData.showTrimTable()}
      showConfigTable={advertisedData.showConfigTable()}
      showVinTable={advertisedData.showVinTable()}
      showNoVehicles={advertisedData.error?.message}
      trimList={advertisedData.trimList}
      configList={selectTrimFields.configList}
      vinList={selectTrimFields.vinList}
      gStatus={advertisedData.gStatusFiltered}
      setGStatus={advertisedData.setGstatusFiltered}
      isSETUser={isSETUser()}
      isLexusUser={isLexusUser()}
      useSrpLabelLogic={false}
      isNationalApp={false}
      portsMap={mapToPortsMap}
      handleSelectDifferentTrim={advertisedData.backToTrimSelectionStep}
      sortCol={advertisedData.sortField}
      sortAsc={advertisedData.sortAscending}
      handleTrimTableSort={advertisedData.sortTrimList}
      handleConfigTableSort={advertisedData.sortConfigList}
      handleVinTableSort={advertisedData.sortVinList}
      showVinCount={FEATURE_OR_4641 && !isSETUser()}
      showRecentConfig={Boolean(advertisedData.mostRecentConfig)}
      mostRecentConfig={advertisedData.mostRecentConfig}
    />
  );
};

export default observer(SelectTrim);