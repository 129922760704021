import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Offering } from '../../../gql/generated';
import FsvModal from '../../../pages/Summary/RegionalSummary/FsvCosts/FsvModal';
import ProfileModel from '../../../pages/Summary/models/ProfileModel';
import useStores from '../../../stores/useStores';
import DollarDisplay from '../../DollarDisplay';
import styles from '../styles.module.scss';

interface RightPanelProps {
  updateCarryOver: () => void;
  offering: Offering;
  profiles: ProfileModel[];
  isEditable: boolean;
}

const RightPanel = ({ updateCarryOver, offering, profiles, isEditable }: RightPanelProps) => {
  const {
    summaryStore: { estCarryOver, totalForecastedSales, setEstCarryOver },
    offeringCostsStore: { periodBalance },
  } = useStores();
  const [openFsvModal, setOpenFsvModal] = useState(false);
  const estCarryOverBalance = estCarryOver + periodBalance;

  const handleCustomEditOnClick = () => {
    setOpenFsvModal(true);
  };

  return (
    <>
      <div className={styles.rightPanel}>
        <DollarDisplay value={totalForecastedSales} label="Est. Sales" onCustomEditClick={handleCustomEditOnClick} isEditable={isEditable} units />
        <DollarDisplay value={estCarryOver} label="Est. Carry Over" onChange={setEstCarryOver} onUpdate={updateCarryOver} isEditable={isEditable} />
        <DollarDisplay value={estCarryOverBalance} label="Est. Balance" useColors />
      </div>
      {openFsvModal && <FsvModal profiles={profiles} offering={offering} totalForecastedSales={totalForecastedSales} onClose={() => setOpenFsvModal(false)} />}
    </>
  );
};

export default observer(RightPanel);
