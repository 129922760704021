import { observer } from 'mobx-react-lite';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter } from 'oat-common-ui';
import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  onNo: () => void;
  onYes: () => void;
  modalText: string;
}

const ReviewModelsModal = ({ onClose, onNo, onYes, modalText }: Props) => {
  return (
    <Modal isOpen size="md" onClose={() => onClose()}>
      <ModalBody>
        <div className={styles.reviewModelsModalText}>{modalText}</div>
        <div className={styles.reviewModelsModalText}>Please review your Model Inclusions/Exclusions before confirming the Series Profile.</div>
        <span className={styles.reviewModelsModalText}>Do you wish to continue?</span>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button variant="text" id="review-modal-no-btn" onClick={() => onNo()}>
            No
          </Button>
          <Button variant="primary" id="review-modal-yes-btn" onClick={() => onYes()}>
            Yes
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default observer(ReviewModelsModal);
