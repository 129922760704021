import { DefaultModal, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import { Status } from '../../../../../../constants/global';
import { useUpdateOfferAdvertisedMutation } from '../../../../../../gql/generated';
import useUrlParams from '../../../../../../hooks/useUrlParams';
import useStores from '../../../../../../stores/useStores';
import useUserInfo from '../../../../../../utils/useUserInfo';
import LeaseCardModel from '../../../../models/LeaseCardModel';
import LeaseFormModel from '../../../../models/LeaseFormModel';

interface Props {
  nonAdLabel: string;
  adLabel: string;
  leaseCard: LeaseCardModel;
  leaseForm: LeaseFormModel;
  onClose: () => void;
}

const LeaseAdvertisedModal = ({ nonAdLabel, adLabel, leaseCard, leaseForm, onClose }: Props) => {
  const { region, period, profile, regalt } = useUrlParams();
  const {
    programDetailsStore: { offering, ryoEarnings },
    efcStore,
    dd365Store,
    userInfoStore,
  } = useStores();
  const history = useHistory();
  const { isSETUser } = useUserInfo();
  const [updateOfferAdvertised] = useUpdateOfferAdvertisedMutation();
  const { error } = useToast();

  const handleLeaseAdvertised = async () => {
    // Default value is false (will go to Create Lease (change vin) page)
    let updateOnly = false;

    // To advertised
    if (!leaseForm.inputs.isAdvertised) {
      // If non-GST and has vin, validate existing vin
      if (!leaseForm.isGST && leaseForm.inputs.vin) {
        try {
          let soldOrTempVin = false;
          if (isSETUser()) {
            const res = await trackPromise(dd365Store.getDD365VinDetails(leaseForm.inputs.vin, userInfoStore.userInfo.brand));
            soldOrTempVin = res?.isTempVin ?? false;
          } else {
            const vinResp = await trackPromise(efcStore.getEfcVinDetails(leaseForm.inputs.vin, region));
            soldOrTempVin = vinResp?.vehicleCategory === 'H' ?? false;
          }

          // OR-1586
          // If VIN is not H, do update
          // otherwise, if VIN exists while offering is in NO_RESPONSE, NEW, then VIN was carried over -> go to create page
          // if offering is SUBMITTED status or other, the VIN was actively selected for current offering. -> do update
          if (!soldOrTempVin || (offering.status !== Status.NO_RESPONSE && offering.status !== Status.NEW)) {
            updateOnly = true;
            leaseForm.setLock('targetPayment');
            leaseForm.updateInputWithCalc('acquisitionFee', leaseForm.inputs.acquisitionFee); // random attr update to trigger calculation
            leaseForm.updateOfferCosts(ryoEarnings);
          }
        } catch (e) {
          error('VIN not found');
        } finally {
          onClose();
        }
      }

      // To non-advertised, do update only
    } else {
      updateOnly = true;
    }

    // Run update
    if (updateOnly) {
      try {
        const res = await trackPromise(
          updateOfferAdvertised({ variables: { input: { id: leaseCard.id, rev: leaseCard.rev, term: leaseForm.inputs.term, isAdvertised: !leaseForm.inputs.isAdvertised } } }),
        );
        leaseForm.updateInput('isAdvertised', !leaseForm.inputs.isAdvertised);
        leaseCard.updateRev(res.data?.updateOfferAdvertised?.offer.rev);
      } catch (e) {
        error((e as Error).message);
      } finally {
        onClose();
      }
    } else {
      history.push(`/changeVin/region/${region}/period/${period}/profile/${profile}/regalt/${regalt}/offerId/${leaseCard.id}/offerTerm/${leaseForm.inputs.term}`);
    }
  };

  return (
    <DefaultModal
      open
      title={leaseForm.inputs.isAdvertised ? nonAdLabel : adLabel}
      message={
        leaseForm.inputs.isAdvertised
          ? 'Are you sure you want to change this offer Lease Non-Advertised? Non-Advertised Offers will not be displayed to your end consumer.'
          : 'Are you sure you want to make this Lease Advertised?'
      }
      onClose={onClose}
      onSubmit={handleLeaseAdvertised}
      submitText="Confirm"
    />
  );
};

export default LeaseAdvertisedModal;
