import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import LeaseTermModel from '../../../../models/LeaseTermModel';
import styles from './styles.module.scss';

interface Props {
  leaseTerm: LeaseTermModel;
}

const ExampleTabs = ({ leaseTerm }: Props) => {
  return (
    <div className={styles.exampleTabs}>
      {leaseTerm.leaseForms.map(form => {
        return (
          <div
            key={form.uid}
            id={`lease-tab-${leaseTerm.uid}-${form.uid}`}
            className={clsx(styles.tab, form.uid === leaseTerm.currentFormUid && styles.active)}
            onClick={() => leaseTerm.setCurrentFormUid(form.uid)}
          >
            {form.isExample ? form.inputs.name : 'Master'}
          </div>
        );
      })}
    </div>
  );
};

export default observer(ExampleTabs);
