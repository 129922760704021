import { CreateLeaseModels } from 'oat-common-ui';
import { Vehicle, VehicleInput } from '../../../../gql/generated';

export const formatTrimList = (vehicles: Vehicle[], efcData: CreateLeaseModels.TrimItem[], leaseStoreVehicles?: VehicleInput[], isChangeVin?: boolean) => {
  const formattedTrimList: CreateLeaseModels.TrimItem[] = [];

  vehicles.forEach(vehicle => {
    const code = vehicle.modelCode;
    const vDesc = vehicle.vehicleDescription;

    efcData.forEach(data => {
      if (code === data.code) {
        const trimTitle = vDesc.includes(code) ? vDesc : `${code} ${vDesc}`;
        const seriesTitle = `${vDesc
          .split(' ')
          .filter(v => !v.includes(code))
          .join(' ')} (${code})`;

        formattedTrimList.push({
          ...data,
          code,
          grade: data.grade,
          trimTitle,
          residual: data.residual || '',
          seriesTitle,
          count: data.count,
        });
      }
    });
  });

  return leaseStoreVehicles && isChangeVin
    ? formattedTrimList.filter(item => !leaseStoreVehicles.find(vehicle => vehicle.modelCode === item.code && !vehicle.isInclusion))
    : formattedTrimList;
};