import { makeAutoObservable } from 'mobx';
import { AdminConstants } from 'oat-admin-common';
import { Status } from '../constants/global';
import { Offering } from '../gql/generated';

export interface RegionalStatus extends Offering {
  sortOrderByStatus?: number;
  regionName?: string;
}

const { REGIONS } = AdminConstants;
class RegionalStatusStore {
  regionalStatuses: RegionalStatus[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSortOrderByStatus = (status: string) => {
    let sortOrder;

    switch (status) {
      case Status.REVISED:
        sortOrder = 0;
        break;
      case Status.ATC_REVISED:
        sortOrder = 1;
        break;
      case Status.SUBMITTED:
        sortOrder = 2;
        break;
      case Status.ATC_SUBMITTED:
        sortOrder = 3;
        break;
      case Status.NEEDS_REVISIONS:
        sortOrder = 4;
        break;
      case Status.ATC_NEEDS_REVISIONS:
        sortOrder = 5;
        break;
      case Status.NO_RESPONSE:
        sortOrder = 6;
        break;
      case Status.MEETS_GUIDELINES:
        sortOrder = 7;
        break;
      default:
        break;
    }

    return sortOrder;
  };

  setRegionalStatuses = (regionalStatuses: Offering[], isNational: boolean) => {
    this.regionalStatuses = regionalStatuses.map(regionalStatus => {
      const status = isNational && regionalStatus.status === Status.NEW ? Status.NO_RESPONSE : regionalStatus.status;
      const sortOrderByStatus = this.setSortOrderByStatus(status);
      const regionName = REGIONS.find(region => region.code === regionalStatus.regionCode)?.name;

      return {
        ...regionalStatus,
        status,
        sortOrderByStatus,
        regionName,
      };
    });
  };
}

export default RegionalStatusStore;
