import { BlockInputLabel, Button, ButtonGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Offer, useAddLeaseExampleMutation } from '../../../../../../gql/generated';
import validator from '../../../../../../utils/validator';
import LeaseCardModel from '../../../../models/LeaseCardModel';
import LeaseFormModel from '../../../../models/LeaseFormModel';
import LeaseTermModel from '../../../../models/LeaseTermModel';

interface Props {
  leaseCard: LeaseCardModel;
  leaseTerm: LeaseTermModel;
  leaseForm: LeaseFormModel;
  onClose: () => void;
}

const LeaseExamplesModal = ({ leaseCard, leaseTerm, leaseForm, onClose }: Props) => {
  const [name, setName] = useState('');
  const nameError = validator(name, { required: true });
  const [addLeaseExample] = useAddLeaseExampleMutation();
  const { error } = useToast();

  const handleSave = async () => {
    try {
      const res = await trackPromise(
        addLeaseExample({
          variables: {
            input: {
              offerId: leaseCard.id,
              rev: leaseCard.rev,
              term: leaseForm.inputs.term,
              name,
            },
          },
        }),
      );

      leaseTerm.addExample(leaseCard, res.data?.addLeaseExample.offer as Offer, name);
      leaseCard.updateRev(res.data?.addLeaseExample.offer.rev);
    } catch (e) {
      error((e as Error).message);
    }
    onClose();
  };

  return (
    <Modal isOpen onClose={onClose}>
      <ModalHeader title="Create Lease Example" onClose={onClose} />
      <ModalBody>
        <BlockInputLabel label="Name:">
          <Input id="modal-name-input" value={name} error={nameError?.required} onChange={e => setName(e.currentTarget.value)} />
        </BlockInputLabel>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button id="modal-cancel" variant="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="modal-save" variant="primary" disabled={nameError?.required} onClick={handleSave}>
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default LeaseExamplesModal;
