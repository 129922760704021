import { makeAutoObservable } from 'mobx';
import defaultOffering from '../defaultObjects/defaultOffering';
import { Offering, ReconcileResponse, ReconcileSeriesResponse } from '../gql/generated';
import ReconcileDataModel from '../pages/ReconcilePage/models/ReconcileDataModel';
import ReconcileSeriesModel from '../pages/ReconcilePage/models/ReconcileSeriesModel';
import { mapMiscAdjustment } from '../pages/ReconcilePage/utils/mapMiscAdjustment';
import ProfileModel from '../pages/Summary/models/ProfileModel';
import { assignNumberValue, defaultToUndefined } from '../utils/assignValue';
class ReconcileStore {
  offering: Offering = defaultOffering;
  profiles: ProfileModel[] = [];
  reconcileData = new ReconcileDataModel();
  profileQuery = '';
  allSeries: ReconcileSeriesModel[] = [];
  enhancedSeries: ReconcileSeriesModel[] = [];
  isNational = false;

  constructor() {
    makeAutoObservable(this);
  }

  setOffering = (offering: Offering) => {
    this.offering = offering;
  };

  updateOfferingRev = (offeringId: string, rev: string) => {
    if (offeringId === this.offering.id) {
      this.offering = { ...this.offering, rev };
    }
  };

  checkEnhanced = (init = false) => {
    this.reconcileData.series = this.reconcileData.isEnhancementsOnly ? this.enhancedSeries : this.allSeries;
    this.reconcileData.series.forEach(series => {
      series.toggleEnhanced(this.reconcileData.isEnhancementsOnly);
    });
    if (!init) {
      this.reconcileData.calculateEarningsOnlySP(this.isNational);
    }
    this.reconcileData.calculateTotalsForSeriesTable();
    this.reconcileData.calculateTotalsForSummaryTable();
  };

  toggleEnhanced = () => {
    this.reconcileData.isEnhancementsOnly = !this.reconcileData.isEnhancementsOnly;
    this.checkEnhanced();
  };

  setProfiles = (profiles: ProfileModel[]) => {
    this.profiles = profiles;
  };

  initData = (data: ReconcileResponse, isNational: boolean) => {
    const miscAdjustment = new ReconcileSeriesModel(mapMiscAdjustment() as ReconcileSeriesResponse, isNational, true);
    miscAdjustment.totalNationalActualEarnings = assignNumberValue(data.nationalMiscAdjAmount);
    miscAdjustment.totalRegionalActualEarnings = assignNumberValue(data.regionalMiscAdjAmount);
    miscAdjustment.totalNationalActualBalance = assignNumberValue(data.nationalMiscAdjAmount);
    miscAdjustment.totalRegionalActualBalance = assignNumberValue(data.regionalMiscAdjAmount);
    miscAdjustment.nationalBalanceVariance = assignNumberValue(data.nationalMiscAdjAmount);
    miscAdjustment.regionalBalanceVariance = assignNumberValue(data.regionalMiscAdjAmount);
    this.isNational = isNational;
    this.reconcileData = new ReconcileDataModel();
    this.reconcileData.lastNvsCompletedDate = data.lastNvsCompletedDate;
    this.reconcileData.lastNvsCompletedBy = data.lastNvsCompletedBy;
    this.reconcileData.lastReconcileCompletedDate = data.lastReconcileCompletedDate;
    this.reconcileData.lastReconcileCompletedBy = data.lastReconcileCompletedBy;
    this.reconcileData.lastReconcileUpdatedBy = data.lastReconcileUpdatedBy;
    this.reconcileData.lastReconcileUpdatedDate = data.lastReconcileUpdatedDate;
    this.reconcileData.isEnhancementsOnly = data.isEnhancementsOnly;
    this.reconcileData.fields.totalNvsUnits = defaultToUndefined(data.totalNvsUnits);
    this.reconcileData.fields.totalNationalActualUnits = defaultToUndefined(data.totalNationalActualUnits);
    this.reconcileData.fields.totalRegionalActualUnits = defaultToUndefined(data.totalRegionalActualUnits);
    this.reconcileData.fields.isNvsComplete = data.isNvsComplete;
    this.reconcileData.fields.isReconcileComplete = data.isReconcileComplete;
    this.reconcileData.fields.nationalMiscAdjAmount = defaultToUndefined(data.nationalMiscAdjAmount);
    this.reconcileData.fields.nationalMiscAdjNote = data.nationalMiscAdjNote;
    this.reconcileData.fields.regionalMiscAdjAmount = defaultToUndefined(data.regionalMiscAdjAmount);
    this.reconcileData.fields.regionalMiscAdjNote = data.regionalMiscAdjNote;
    this.reconcileData.series = data.reconcileSeriesResponses.map(series => {
      return new ReconcileSeriesModel(series as ReconcileSeriesResponse, isNational);
    });
    this.reconcileData.series.push(miscAdjustment);
    this.allSeries = this.reconcileData.series;
    this.enhancedSeries = this.reconcileData.series.filter(series => series && (series.isEnhanced || series.isAdjustment));
    this.checkEnhanced(true);
  };
}

export default ReconcileStore;
