import { AdminConstants } from 'oat-admin-common';
import { dateFormatISOString } from 'oat-common-ui';
import { AprTierTermOfferInput } from '../../../hooks/useOfferEligibility';
import AprCardModel from '../../ProgramDetails/models/AprCardModel';
import AprTierModel from '../../ProgramDetails/models/AprTierModel';
import { processAprTierMap, transformAprCardData } from './utils';

type UserInfo = {
  isGst: boolean;
  isLexus: boolean;
  isSet: boolean;
};

const { OPTION_TYPE_NAMES } = AdminConstants;

export const handleRecalcForEnhTierTerms = (selectedTierModel: AprTierModel | undefined, ryoEarnings: number) => {
  selectedTierModel?.updateEstCosts();
  selectedTierModel?.updateOfferCosts(ryoEarnings);
};

export const buildEnhCostShareInput = (input: {
  isEligibleForEnhCostShare: boolean | undefined;
  isEnhCostShareAccepted: boolean | undefined;
  enhCostShareOfferId: string | undefined;
  isEnhCostShareUpdated?: boolean | undefined;
}) => {
  const { isEligibleForEnhCostShare, isEnhCostShareAccepted, enhCostShareOfferId, isEnhCostShareUpdated } = input;

  return {
    isEligibleForEnhCostShare,
    isEnhCostShareAccepted,
    enhCostShareOfferId,
    isEnhCostShareUpdated,
  };
};

export const buildEnhCostSharePayload = (
  aprCard: AprCardModel,
  userInfo: UserInfo,
  enhCostShareParams: { removeEnhReason: string; replaceExisting: boolean; isEnhCostShareAccepted: boolean | undefined },
) => {
  const { removeEnhReason, replaceExisting, isEnhCostShareAccepted } = enhCostShareParams;
  const aprPayload = transformAprCardData(aprCard, userInfo);

  if (
    (removeEnhReason && isEnhCostShareAccepted) ||
    (!isEnhCostShareAccepted && !aprCard.enhCostShareOfferId && !removeEnhReason)
    // if isEnhCostShareAccepted is already set to false or user clicks cancel & isnt trying to remove or replace existing enhanced cost share
  ) {
    return {
      ...aprPayload,
      ...buildEnhCostShareInput({
        isEligibleForEnhCostShare: false,
        isEnhCostShareAccepted:
          (aprCard.isEnhCostShareAccepted === undefined && !aprCard.isEligibleForEnhCostShare) || (removeEnhReason && isEnhCostShareAccepted) ? undefined : false,
        enhCostShareOfferId: '',
        isEnhCostShareUpdated: aprCard.isEnhCostShareUpdated,
      }),
    };
  }

  // Handling apply on EnhCostShareNotification OR-5083
  if (aprCard.isEnhCostShareUpdated && isEnhCostShareAccepted) {
    return {
      ...aprPayload,
      ...buildEnhCostShareInput({
        isEligibleForEnhCostShare: true,
        isEnhCostShareAccepted: true,
        enhCostShareOfferId: aprCard.enhCostShareOfferId,
        isEnhCostShareUpdated: false,
      }),
    };
  }

  // For replacing an existing enhanced cost share but user clicks cancel on modal, and then from that point forward
  if ((replaceExisting || aprCard.isEligibleForEnhCostShare) && aprCard.isEnhCostShareAccepted === false) {
    return {
      ...aprPayload,
      ...buildEnhCostShareInput({
        isEligibleForEnhCostShare: true,
        isEnhCostShareAccepted: false,
        enhCostShareOfferId: aprCard.enhCostShareOfferId,
        isEnhCostShareUpdated: aprCard.isEnhCostShareUpdated,
      }),
    };
  }

  return {
    ...aprPayload,
    ...buildEnhCostShareInput({
      isEligibleForEnhCostShare: !!aprCard.enhancedCostShare,
      isEnhCostShareAccepted: true,
      enhCostShareOfferId: aprCard.enhCostShareOfferId,
      isEnhCostShareUpdated: aprCard.isEnhCostShareUpdated,
    }),
  };
};

export const buildIsEnhCostShareAcceptedParam = (
  isEnhCostShareAccepted: boolean,
  removeEnhReason: string,
  replaceExisting: boolean,
  aprCard: AprCardModel,
): boolean | undefined => {
  if (removeEnhReason || replaceExisting || aprCard.isEnhCostShareAccepted === undefined || aprCard.isEnhCostShareUpdated) {
    return isEnhCostShareAccepted;
  }

  if (!aprCard.isEnhCostShareAccepted) {
    return false;
  }

  return aprCard.isEnhCostShareAccepted;
};

export const buildIsEnhTfsCostShareAcceptedParam = (
  isEnhTfsCostShareAccepted: boolean,
  removeEnhTfsReason: string,
  replaceExistingTfs: boolean,
  aprCard: AprCardModel,
): boolean | undefined => {
  if (removeEnhTfsReason || replaceExistingTfs || aprCard.isEnhTfsCostShareAccepted === undefined) {
    return isEnhTfsCostShareAccepted;
  }

  if (!aprCard.isEnhTfsCostShareAccepted) {
    return false;
  }

  return aprCard.isEnhTfsCostShareAccepted;
};

export const buildEnhCostShareCheckPayload = (aprCard: AprCardModel, region: string, userInfo: UserInfo) => {
  const checkPayload = {
    optionTypeName: OPTION_TYPE_NAMES.APR,
    startDate: dateFormatISOString(aprCard.selectedTierModel?.terms[0].fields.startDate),
    endDate: dateFormatISOString(aprCard.selectedTierModel?.terms[0].fields.endDate),
    regionCode: region,
    states: aprCard.includedStates,
    vehicles: aprCard.fields.vehicles.filter(v => v.isInclusion),
    tierTerms: processAprTierMap(aprCard, userInfo).map(item => ({ tier: item.tier, highTerm: item.term })) as AprTierTermOfferInput[],
  };

  const existingOfferPayload = {
    isEligibleForEnhCostShare: aprCard.isEligibleForEnhCostShare,
    isEnhCostShareAccepted: aprCard.isEnhCostShareAccepted,
    enhCostShareOfferId: aprCard.enhCostShareOfferId,
  };

  return { checkPayload, existingOfferPayload };
};
