import { observer } from 'mobx-react-lite';
import { Button, Checkbox, OATIcon } from 'oat-common-ui';
import SubventionCashModel from '../SubventionCashModel';

import styles from './styles.module.scss';

type Props = {
  subventionCashModel: SubventionCashModel;
};

const SubventionCashTable = ({ subventionCashModel }: Props) => {
  const { getSubventionCashTableCell, tiers, terms, toggleSubventionCashTableCell, copyAcross, copyDown, removeAll } = subventionCashModel;

  return (
    <div className={styles.tableWrapper}>
      <table className={styles.subventionCashTable}>
        <thead>
          <tr>
            <th className={styles.tableCell}></th>
            {tiers.map((tier, index) => (
              <th key={tier} className={styles.tableCell}>
                <div className={styles.actionWrapper}>
                  <span>Tier {tier}</span>
                  {index !== tiers.length - 1 && (
                    <Button variant="text" onClick={() => copyAcross({ tier })}>
                      Copy Across
                    </Button>
                  )}

                  <Button variant="text" onClick={() => copyDown({ tier })}>
                    Copy Down
                  </Button>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {terms.map((term, index) => {
            return (
              <tr key={`${term}_${index}`}>
                <td className={styles.tableCell}>
                  <div className={styles.actionWrapper}>
                    <span className={styles.term}>{term} mo</span>
                    <Button variant="text" onClick={() => copyAcross({ term })}>
                      Copy Across
                    </Button>
                    {index !== terms.length - 1 && (
                      <Button variant="text" onClick={() => copyDown({ term })}>
                        Copy Down
                      </Button>
                    )}
                  </div>
                </td>
                {tiers.map((tier, index) => {
                  return (
                    <td key={`${tier}_${term}_${index}`} className={styles.tableCell}>
                      <div className={styles.actionWrapper}>
                        <Checkbox
                          checkmarkClass={styles.checkbox}
                          className={styles.checkboxWrapper}
                          isChecked={getSubventionCashTableCell(tier, term)?.checked}
                          onChange={() => toggleSubventionCashTableCell(tier, term)}
                        />
                      </div>
                    </td>
                  );
                })}
                <td className={styles.removeCtaCell} key={`${term}_remove`}>
                  <OATIcon className={styles.icon} icon="minus" size={20} onClick={() => removeAll({ term })} />
                </td>
              </tr>
            );
          })}
          <tr>
            <td className={styles.emptyCell}></td>
            {tiers.map((tier, index) => (
              <td className={styles.removeCtaCell} key={`${tier}_remove_${index}`}>
                <OATIcon icon="minus" size={20} onClick={() => removeAll({ tier })} />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default observer(SubventionCashTable);
