import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlockInputLabel, Dropdown, DropdownItem, Input, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { AddContestNumberInput, useAddSetContestNumberMutation } from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import { buildContestNumberDropdownList, invalidInput } from '../../../../utils/buildContestNumbers';
import { Status } from '../../../../constants/global';
import styles from './styles.module.scss';

interface Props {
  optionTypeName: string;
  contestNumber: string;
  id: string;
  handleUpdate: (contestNumberId: string, contestNumber: string, isUpdate?: boolean) => void;
  isDisabled?: boolean;
  forCreate?: boolean;
}

const ContestNumber = ({ optionTypeName, contestNumber, id, handleUpdate, isDisabled, forCreate = false }: Props) => {
  const {
    programDetailsStore: { contestNumbersByType, offering },
  } = useStores();
  const { error } = useToast();
  const { period } = useUrlParams();
  const [addSetContestNumber] = useAddSetContestNumberMutation();
  const isDisabledOrMeetsGuidelines = offering.status === Status.MEETS_GUIDELINES || isDisabled;

  const filteredNumbersList = buildContestNumberDropdownList(contestNumbersByType.filter(type => type.optionType === optionTypeName)[0]?.numbers || []);

  const [showContestNumberInput, setShowContestNumberInput] = useState(!forCreate && filteredNumbersList.length <= 1);
  const [contestNumberInput, setContestNumberInput] = useState(contestNumber || '');

  const handleContestNumber = (item: DropdownItem) => {
    if (item.label === ' New Contest Number') {
      setShowContestNumberInput(true);
    } else {
      handleUpdate(item.value, item.label as string, true);
    }
  };

  const handleAddContestNumber = async () => {
    try {
      if (!invalidInput(contestNumbersByType, contestNumberInput)) {
        const payload: AddContestNumberInput = {
          offeringId: period,
          optionTypeName,
          contestNumber: contestNumberInput,
        };
        const resp = await trackPromise(addSetContestNumber({ variables: { input: payload } }));
        const contestNumberId = resp.data?.addSetContestNumber.setContestNumbers?.filter(num => num?.number === contestNumberInput)[0]?.id || '';
        handleUpdate(contestNumberId, contestNumberInput, true);
        setShowContestNumberInput(false);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <BlockInputLabel label="Contest Number" className={styles.contestNumberContainer}>
      {showContestNumberInput ? (
        <>
          <Input
            value={contestNumberInput}
            onChange={e => setContestNumberInput(e.currentTarget.value)}
            error={!contestNumberInput || invalidInput(contestNumbersByType, contestNumberInput)}
            disabled={isDisabledOrMeetsGuidelines}
          />
          {!isDisabledOrMeetsGuidelines && <FontAwesomeIcon icon={faCheckCircle} onClick={handleAddContestNumber} className={styles.circleCheck} />}
        </>
      ) : (
        <Dropdown id={`${id}contestNumber`} value={contestNumber} options={filteredNumbersList} disabled={isDisabledOrMeetsGuidelines} onChange={item => handleContestNumber(item)} />
      )}
    </BlockInputLabel>
  );
};

export default ContestNumber;
