import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import DollarDisplay from '../../../../components/DollarDisplay';
import StartEndDate from '../../../../components/StartEndDate';
import { Status } from '../../../../constants/global';
import { useSaveVehiclesMutation, VehicleInput } from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import getDisabledVehicles from '../../../../utils/getDisabledVehicles';
import useUserInfo from '../../../../utils/useUserInfo';
import CashCardModel from '../../models/CashCardModel';
import BlockText from '../BlockText';
import ContestNumber from '../ContestNumber';
import { returnStatesList } from '../LeaseCard/components/VehicleInfo/utils';
import OfferCompatibleOffers from '../OfferCompatibleOffers';
import OfferExclusions from '../OfferExclusions';
import SpecialEdition from '../SpecialEdition';
import styles from './styles.module.scss';

const { OPTION_TYPE_NAMES } = AdminConstants;

type Props = {
  cashCard: CashCardModel;
  handleInputChange: () => void;
  handleSaveCompatibility: () => void;
  forCreate?: boolean;
  compatibileAccessories?: string[];
};

const CashCardLeftContent = ({ cashCard, handleInputChange, handleSaveCompatibility, forCreate, compatibileAccessories }: Props) => {
  const { id, rev, uid, isStandAlone, isDuplicate, fields, nationalOffer, updateField, updateRev, updateVehicles, toggleCompatibileOffer, toggleIsSpecialEdition, hasDateError } =
    cashCard;
  const {
    userInfoStore: { isLexus },
    programDetailsStore: { offering, seriesProfile },
  } = useStores();
  const { isSETUser, isGSTUser } = useUserInfo();
  const { region } = useUrlParams();
  const disabledVehicles = getDisabledVehicles(nationalOffer?.vehicles || seriesProfile.vehicles);
  const isDisabled = offering.status === Status.MEETS_GUIDELINES || cashCard.isDisabled;
  const isDateDisabled = (!isStandAlone && !isDuplicate) || isDisabled;
  const { error } = useToast();
  const [saveVehicles] = useSaveVehiclesMutation();

  const handleOnUpdate = (contestNumberId: string, contestNumber: string, isUpdate = false) => {
    updateField('contestNumber', contestNumber);
    updateField('contestNumberOfferId', contestNumberId);
    if (isUpdate && !forCreate) {
      handleInputChange();
    }
  };

  const handleChangeDate = (fieldName: 'startDate' | 'endDate', val?: Date) => {
    if (val) {
      updateField(fieldName, val);
      handleInputChange();
    }
  };

  const handleExclusions = async (excludedList: VehicleInput[], includedList: VehicleInput[]) => {
    updateVehicles(excludedList, includedList);

    if (!forCreate) {
      try {
        const res = await trackPromise(saveVehicles({ variables: { input: { id, rev, vehicles: [...excludedList, ...includedList] } } }));
        updateRev(res.data?.saveVehicles.offer?.rev);
      } catch (e) {
        error((e as Error).message);
      }
    }
  };

  const handleCompatibility = (compatibility: string[]) => {
    updateField('compatibileOffers', compatibility);
    handleSaveCompatibility();
  };

  return (
    <>
      <div className={styles.offerInfoDetails}>
        <div className={styles.cashBasicInfo}>
          <div className={styles.cashBack}>
            <div>
              <DollarDisplay className={styles.cashBackAmount} value={fields.combinedPerUnitCost} wholeNumber />
            </div>
            <p className={styles.cashBackLabel}>CASH BACK</p>
          </div>
        </div>
        <div className={styles.detailsContainer}>
          {isSETUser() && (
            <ContestNumber
              optionTypeName={OPTION_TYPE_NAMES.CUSTOMER_CASH}
              contestNumber={cashCard.fields.contestNumber}
              id={cashCard.uid}
              handleUpdate={handleOnUpdate}
              forCreate={forCreate}
            />
          )}
          <OfferExclusions
            disabledVehicles={disabledVehicles}
            excludedModels={fields.excludedVehicles}
            id={cashCard.uid}
            includedModels={fields.includedVehicles}
            isEditEnabled={isStandAlone || isDuplicate}
            onSubmit={handleExclusions}
          />
          <BlockText topText="Compatible States" bottomText={returnStatesList(region, fields.includedStates)} />
          <OfferCompatibleOffers
            compatibileOffers={fields.compatibileOffers}
            compatibility={fields.compatibility}
            toggleCompatibileOffer={toggleCompatibileOffer}
            optionType={OPTION_TYPE_NAMES.CUSTOMER_CASH}
            onSubmit={handleCompatibility}
            id={id}
            isEditable={isStandAlone || isDuplicate}
          />
        </div>
      </div>
      <div className={styles.datesContainer}>
        <StartEndDate
          datePickerClass={styles.datePicker}
          dateWrapperClass={styles.dateWrapper}
          maxDate={fields.defaultEndDate}
          minDate={fields.defaultStartDate}
          setStartDate={value => handleChangeDate('startDate', value)}
          setEndDate={value => handleChangeDate('endDate', value)}
          startDate={fields.startDate}
          endDate={fields.endDate}
          isDark
          labelClass={styles.labelWrapper}
          startDateError={hasDateError || (!fields.isValidEnR && isDuplicate)}
          endDateError={hasDateError || (!fields.isValidEnR && isDuplicate)}
          disabled={isDateDisabled}
        />
      </div>
      {(isLexus() || isGSTUser()) && (
        <SpecialEdition
          id={uid}
          accessoryCodes={fields.accessoryCodes}
          className={styles.specialEditionWrapper}
          compatibileAccessories={compatibileAccessories}
          inputLabel="Package"
          isChecked={fields.isSpecialEdition}
          isLexus={isLexus()}
          label="Special edition"
          onChange={value => {
            updateField('specialEditionPackage', value);
            handleInputChange();
          }}
          onSubmit={value => {
            updateField('accessoryCodes', value.join());
            handleInputChange();
          }}
          onSelect={() => {
            toggleIsSpecialEdition();
            handleInputChange();
          }}
          value={fields.specialEditionPackage}
          vehicles={fields.vehicles}
          forCreate={forCreate || cashCard.isMultiSeries}
        />
      )}
    </>
  );
};

export default observer(CashCardLeftContent);
