import { makeAutoObservable } from 'mobx';
import { AdminConstants, AdminModels } from 'oat-admin-common';
import { dateStringToDate, uuidv4 } from 'oat-common-ui';
import AccordionModel from '../../../components/Accordion/models/AccordionModel';
import createAccordionFromVehicles from '../../../components/Accordion/utils/createAccordionFromVehicles';
import { AprTerms, DEFAULT_TIERS } from '../../../constants/global';
import {
  AprDetails,
  CombinedOffers,
  EnhTfsCostShare,
  EnhancedCostShare,
  Offer,
  Offering,
  SeriesMapping,
  SeriesProfile,
  SetStandardRate,
  StdAprRate,
  VehicleInput,
} from '../../../gql/generated';
import { stores } from '../../../stores/useStores';
import { assignDollarCents, assignNumberValue, assignStringValue, defaultBooleanToUndefined } from '../../../utils/assignValue';
import getDisabledVehicles from '../../../utils/getDisabledVehicles';
import { getFoundingSourceLabel } from '../../../utils/getFoundingSourceLabel';

import AprTermModel from './AprTermModel';
import AprTierModel from './AprTierModel';
import { sortedRegionalDetailsByTier } from './aprUtils';

export type TierValidationObject = { errorMessage?: string; hasError: boolean };

export type Rate = {
  tier: string;
  term: number;
  rate: number;
};

export class AprCardFields {
  combinedAverageAmount = '0';

  name: string = AdminConstants.OfferTypes.APR;
  specialEditionPackage?: string;

  enhancedStartDate: Date | undefined;
  enhancedEndDate: Date | undefined;

  vehicles: VehicleInput[] = [];
  vehiclesAccordion = new AccordionModel();
  isInclusions = false;

  compatibileOffers: string[] = [];

  compatibility: AdminModels.Compatibility = {
    defaultTypes: [],
    incompatibleTypes: [],
    optionalTypes: [],
  };

  accessoryCodes?: string;
  contestNumber = '';
  contestNumberOfferId = '';
  bonusCash: number = 0;
  modelNote = '';

  // for APR edit subvention modal bottom labels (enhanced cost share)
  subventionCashTfsCostShare?: number | undefined = undefined;
  subventionCashTfsCostShareCap?: number | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }
}

interface AprConstructorProps {
  offer?: CombinedOffers;
  seriesMapping?: SeriesMapping[];
  region?: string;
  seriesProfile?: SeriesProfile;
  selectedTier?: string;
  offering?: Offering;
}

class AprCardModel {
  uid = uuidv4();
  fields = new AprCardFields();
  id = '';
  rev = '';
  parentId = '';

  isForCreate = false;
  isSpecialEdition = false;
  isStandalone = false;
  isMultiSeries = false;
  isDuplicate = false;
  isValidEnR = true;

  selectedTier = DEFAULT_TIERS[0];
  invalidTiers = new Map<string, TierValidationObject>();

  tierMap = new Map<string, AprTierModel>();
  tiersList = DEFAULT_TIERS;
  termsList = AprTerms.join().split(',');
  isDefaultSet = false;

  excludedStates: string[] = [];
  includedStates: string[] = [];

  nationalOffer: Offer | undefined;

  isEligibleForEnhCostShare?: boolean = undefined;
  enhCostShareOfferId?: string = undefined;
  isEnhCostShareAccepted?: boolean = undefined;
  isEnhCostShareUpdated?: boolean = undefined;
  isEnhCostShareRemoved?: boolean = undefined;
  wasEnhCostshareAccepted?: boolean = undefined;
  enhancedCostShare?: EnhancedCostShare = undefined;

  isEligibleForEnhTfsCostShare?: boolean = undefined;
  enhTfsCostShareId?: string = undefined;
  isEnhTfsCostShareAccepted?: boolean = undefined;
  isEnhTfsCostShareUpdated?: boolean = undefined;
  wasEnhTfsCostShareAccepted?: boolean = undefined;
  enhTfsCostShare?: EnhTfsCostShare = undefined;

  isNationalAndIsAtc = false;

  initialDates: { startDate: Date; endDate: Date } = {
    startDate: new Date(),
    endDate: new Date(),
  };
  initialVehicles: VehicleInput[] = [];

  constructor(data?: AprConstructorProps) {
    makeAutoObservable(this);
    this.initData({
      offer: data?.offer,
      seriesMapping: data?.seriesMapping,
      seriesProfile: data?.seriesProfile,
      selectedTier: data?.selectedTier,
      region: data?.region,
      offering: data?.offering,
    });
  }

  initData = (data: AprConstructorProps) => {
    const { offer, seriesMapping, region, seriesProfile, selectedTier, offering } = data;

    const disabledVehicles = getDisabledVehicles(seriesProfile?.vehicles || []);

    if (offer?.national) {
      this.nationalOffer = offer?.national;
    }

    if (offer?.regional.aprDetails) {
      const { tierMap, tiersList, termsList } = this.initTierMapList(offer?.regional.aprDetails, offer?.national?.aprDetails, region, offering);

      this.includedStates = offer.regional.includedStates || [];
      this.excludedStates = offer.regional.excludedStates || [];
      this.tiersList = tiersList;
      this.termsList = termsList;
      this.selectedTier = selectedTier || tiersList[0];
      this.tierMap = tierMap;
      this.id = offer.regional.id;
      this.rev = offer.regional.rev;
      this.fields.modelNote = offer.regional.aprDetails[0].modelNote;
      this.setIsValidEnr(offer.regional.aprDetails);
      if (offer.regional.isDuplicate) {
        this.setEnhancedDates(offer.regional.aprDetails);
      }
    }

    const firstAprDetail: AprDetails | undefined = offer?.regional?.aprDetails ? offer?.regional?.aprDetails[0] : undefined;

    this.fields.combinedAverageAmount = assignStringValue(firstAprDetail?.avgAmntFinanced);
    this.isStandalone = !Boolean(offer?.regional.nationalId);
    this.fields.accessoryCodes = assignStringValue(offer?.regional?.accessoryCodes);
    this.isSpecialEdition = Boolean(offer?.regional?.isSpecialEdition);
    this.isDuplicate = Boolean(offer?.regional?.isDuplicate);
    this.fields.specialEditionPackage = assignStringValue(offer?.regional?.specialEditionPackage);
    this.parentId = assignStringValue(offer?.regional.parentId);

    this.fields.name = offer?.regional.name || offer?.national?.name || 'APR';
    this.id = assignStringValue(offer?.regional.id);
    this.rev = assignStringValue(offer?.regional.rev);
    this.isEligibleForEnhCostShare = defaultBooleanToUndefined(offer?.regional.isEligibleForEnhCostShare);
    this.enhCostShareOfferId = assignStringValue(
      offer?.regional.enhCostShareOfferId,
      typeof this.isEligibleForEnhCostShare === 'boolean' && !this.isEligibleForEnhCostShare ? undefined : '',
    );
    this.isEnhCostShareAccepted = defaultBooleanToUndefined(offer?.regional.isEnhCostShareAccepted);
    this.isEnhCostShareUpdated = defaultBooleanToUndefined(offer?.regional.isEnhCostShareUpdated);
    this.isEnhCostShareRemoved = defaultBooleanToUndefined(offer?.regional.isEnhCostShareRemoved);

    this.isEnhTfsCostShareUpdated = defaultBooleanToUndefined(offer?.regional.isEnhTfsCostShareUpdated);
    this.isEligibleForEnhTfsCostShare = defaultBooleanToUndefined(offer?.regional.isEligibleForEnhTfsCostShare);
    this.enhTfsCostShareId = assignStringValue(
      offer?.regional.enhTfsCostShareId,
      typeof this.isEligibleForEnhTfsCostShare === 'boolean' && !this.isEligibleForEnhTfsCostShare ? undefined : '',
    );
    this.isEnhTfsCostShareAccepted = defaultBooleanToUndefined(offer?.regional.isEnhTfsCostShareAccepted);

    this.fields.vehicles = offer?.regional.vehicles || seriesProfile?.vehicles || [];
    this.initialVehicles = this.fields.vehicles;
    this.fields.vehiclesAccordion.items = createAccordionFromVehicles(this.fields.vehicles, seriesMapping as SeriesMapping[], this.fields.isInclusions, disabledVehicles);
    this.fields.compatibileOffers = offer?.national?.compatibilityList || offer?.regional?.compatibilityList || [];
    this.fields.contestNumber = offer?.regional?.contestNumber || '';
    this.fields.contestNumberOfferId = offer?.regional?.contestNumberOfferId || '';
    this.fields.bonusCash = assignNumberValue(firstAprDetail?.bonusCash);
  };

  initTierMapList = (regionalDetails: AprDetails[], nationalAprDetails?: AprDetails[] | null, region?: string, offering?: Offering) => {
    const tierMap = new Map<string, AprTierModel>();
    const tiersList: string[] = [];
    const termsList: string[] = [];

    // group details by tier
    sortedRegionalDetailsByTier(regionalDetails).forEach(aprDetail => {
      if (!aprDetail) {
        return;
      }

      if (!tiersList.includes(aprDetail.tier)) {
        tiersList.push(aprDetail.tier);
      }

      // create term model to put into tier
      const nationalDetails = nationalAprDetails?.find(item => item?.highTerm === aprDetail.highTerm && item?.tier === aprDetail.tier);
      const termModel = new AprTermModel({ regionalDetails: aprDetail, nationalDetails }, region, offering);

      if (termModel.fields.startDate && termModel.fields.endDate) {
        this.setInitialDates(termModel.fields.startDate, termModel.fields.endDate);
      }

      // get/create tier model
      if (!tierMap.has(aprDetail.tier)) {
        const aprTier = new AprTierModel(aprDetail.tier, aprDetail.note, aprDetail.tdaNote);

        // set costs
        aprTier.fields.estCost = assignDollarCents(aprDetail.estimatedCost);
        aprTier.fields.tfsEstCost = assignDollarCents(aprDetail.tfsCost);
        aprTier.fields.enhTfsEstCost = assignDollarCents(aprDetail.enhTfsCost);
        aprTier.fields.subCashEstCost = assignDollarCents(aprDetail.subCashEstCost);
        aprTier.fields.offerEarnings = assignNumberValue(aprDetail.offerEarnings);
        aprTier.fields.offerCost = assignDollarCents(aprDetail.offerCost);
        aprTier.fields.offerTfsCost = assignDollarCents(aprDetail.offerTfsCost);
        aprTier.fields.offerEnhTfsCost = assignDollarCents(aprDetail.offerEnhTfsCost);
        aprTier.fields.subCashOfferCost = assignDollarCents(aprDetail.subCashOfferCost);
        aprTier.fields.subCashOfferTfsCost = assignDollarCents(aprDetail.subCashOfferTfsCost);
        aprTier.fields.subCashOfferEnhTfsCost = assignDollarCents(aprDetail.subCashOfferEnhTfsCost);
        aprTier.fields.subCashTfsEstCost = assignDollarCents(aprDetail.subCashTfsEstCost);

        aprTier.fields.totalOfferCost = assignDollarCents(aprTier.fields.offerCost + aprTier.fields.subCashOfferCost);
        aprTier.fields.offerBalance = assignDollarCents(aprTier.fields.offerEarnings - aprTier.fields.totalOfferCost);

        tierMap.set(aprDetail.tier, aprTier);
      }

      const aprTier = tierMap.get(aprDetail.tier) as AprTierModel;
      aprTier.terms.push(termModel);

      // get list of terms available for EnR modal
      if (!termsList.includes(termModel.fields.term.toString())) {
        termsList.push(termModel.fields.term.toString());
      }
    });

    tiersList.sort((tierA, tierB) => (DEFAULT_TIERS.findIndex(tier => tierA === tier) > DEFAULT_TIERS.findIndex(tier => tierB === tier) ? 1 : -1));

    return { tierMap, tiersList, termsList };
  };

  switchTier = (tier: string) => {
    this.selectedTier = tier;
  };

  toggleIsInclusions = () => {
    this.fields.isInclusions = !this.fields.isInclusions;
  };

  toggleIsSpecialEdition = () => {
    this.isSpecialEdition = !this.isSpecialEdition;
    if (!this.isSpecialEdition) {
      this.fields.accessoryCodes = '';
      this.fields.specialEditionPackage = '';
    }
  };

  toggleCompatibileOffer = (offerName: string) => {
    const compOfferIndex = this.fields.compatibileOffers.findIndex(offer => offer === offerName);

    if (compOfferIndex === -1) {
      this.fields.compatibileOffers.push(offerName);
    } else {
      this.fields.compatibileOffers.splice(compOfferIndex, 1);
    }
  };

  updateField = <T extends keyof AprCardFields, V extends AprCardFields[T]>(field: T, value: V) => {
    this.fields[field] = value;
  };

  updateCombinedAverageAmount = (avgAmount: string) => {
    this.fields.combinedAverageAmount = avgAmount;
    for (const tier of this.tierMap.values()) {
      for (const term of tier.terms) {
        term.fields.termAverageAmount = avgAmount;
      }
    }
  };

  setDefaultRatesToTerms = (defaultRates: (StdAprRate | SetStandardRate)[], ncsRate?: number | null, isSETUser = false) => {
    const setRates = defaultRates as SetStandardRate[];
    if (!this.isDefaultSet) {
      for (const tier of this.tierMap.values()) {
        for (const term of tier.terms) {
          let rate;
          if (isSETUser) {
            rate = setRates.find(rate => term.fields.term >= rate.minTerm && term.fields.term <= rate.maxTerm && rate.tier === tier.tier)?.rate || 0;
          } else {
            rate = defaultRates.find(rate => rate.term === term.fields.term && rate.tier === tier.tier)?.rate || 0;
          }

          if (ncsRate && rate && !isSETUser) {
            rate = rate - ncsRate;
          }

          term.fields.buyRate = assignDollarCents(rate);

          if (this.isForCreate) {
            // on creating page we are using default rates
            // for all other offers we are using rates from API response (AprTermModel -> constructor)
            term.fields.rate = assignDollarCents(rate);
          }
        }
      }

      this.isDefaultSet = true;
    }
  };

  setCompatibileOffers = (compatibileOffers?: AdminModels.Compatibility) => {
    this.fields.compatibileOffers = compatibileOffers?.defaultTypes || [];
  };

  setCompatibility = (compatability: AdminModels.Compatibility) => {
    this.fields.compatibility = compatability;
  };

  setInitialDates = (startDate: Date, endDate: Date) => {
    this.initialDates.startDate = startDate;
    this.initialDates.endDate = endDate;
  };

  setIsDefaultSet = (isDefaultSet: boolean) => {
    this.isDefaultSet = isDefaultSet;
  };

  updateInitialVehicles = () => {
    this.initialVehicles = this.fields.vehicles;
  };

  get isAvgAmountValid() {
    if (!stores.userInfoStore.isSETUser()) {
      return Number(this.fields.combinedAverageAmount) > 0;
    } else {
      let hasTermWithInvalidInput = false;

      for (const tier of this.tierMap.values()) {
        for (const term of tier.terms) {
          if (term.fields.term !== 24) {
            if (Number(term.fields.termAverageAmount) <= 0) {
              hasTermWithInvalidInput = true;
            }
          }
        }
      }

      return !hasTermWithInvalidInput;
    }
  }

  setTermsAvgAmount = (value: string, termToChange: number) => {
    for (const tier of this.tierMap.values()) {
      tier.terms.forEach(term => {
        if (term.fields.term === termToChange) {
          term.fields.termAverageAmount = value;
        }
      });
    }
  };

  updateLoweTiersPenRate = (penRate: string, changedTerm: number) => {
    for (const tier of this.tierMap.values()) {
      tier.terms.forEach(term => {
        if (term.fields.term === changedTerm) {
          term.fields.termPen = penRate;
        }
      });
    }
  };

  updateLoweTiersBuyRate = (rate: number, changedTerm: number) => {
    let increment = rate === 0 ? 0.9 : 1;

    for (const tier of this.tierMap.values()) {
      const foundTerm = tier.terms.find(term => term.fields.term === changedTerm);

      if (!foundTerm) {
        return;
      }

      if (tier.tier === '1+' || tier.tier === '1') {
        foundTerm.fields.rate = rate;
      } else {
        foundTerm.fields.rate = rate + increment;
        increment = increment + 1;
      }
    }
  };

  updateRev = (rev: string | undefined) => {
    this.rev = rev || '';
  };

  setIsValidEnr = (aprDetails: AprDetails[]) => {
    const invalidEnRs = aprDetails.filter(aprD => aprD.isValidEnR === false);
    this.isValidEnR = invalidEnRs.length > 0 ? false : true;
  };

  setEnhancedDates = (aprDetails: AprDetails[]) => {
    const enhancedStartDate = aprDetails[0].startDate;
    const enhancedEndDate = aprDetails[0].endDate;

    this.fields.enhancedStartDate = dateStringToDate(enhancedStartDate);
    this.fields.enhancedEndDate = dateStringToDate(enhancedEndDate);

    // If APR offer is EnR (isDuplicate) we are showing date only if no date has been changed
    if (aprDetails.some(offer => offer.startDate !== enhancedStartDate || offer.endDate !== enhancedEndDate)) {
      this.fields.enhancedStartDate = undefined;
      this.fields.enhancedEndDate = undefined;
    }
  };

  updateEnRValidation = (aprDetails?: AprDetails[] | null) => {
    if (!aprDetails) {
      return;
    }

    // EnR offer can have only one tier and multiple tiers
    for (const tier of this.tierMap.values()) {
      tier.terms.forEach(term => {
        aprDetails.forEach(aprDetail => {
          if (term.fields.term === aprDetail.highTerm) {
            term.fields.isValidEnR = Boolean(aprDetail.isValidEnR);
          }
        });
      });
    }

    this.setIsValidEnr(aprDetails);
    this.setEnhancedDates(aprDetails);
  };

  setEnhancedCostShare = (enhancedCostShare: EnhancedCostShare | undefined) => {
    this.enhancedCostShare = enhancedCostShare;
  };

  setEnhCostShareOfferId = (id: string | undefined) => {
    this.enhCostShareOfferId = id;
  };

  setIsEligibleForEnhCostShare = (isEligible: boolean) => {
    this.isEligibleForEnhCostShare = isEligible;
  };

  setIsEnhCostShareAccepted = (isAccepted: boolean | undefined) => {
    this.isEnhCostShareAccepted = isAccepted;
  };

  updateEnhCostShareFields = (aprOffer: Offer | undefined) => {
    this.enhCostShareOfferId = assignStringValue(aprOffer?.enhCostShareOfferId, undefined);
    this.isEligibleForEnhCostShare = defaultBooleanToUndefined(aprOffer?.isEligibleForEnhCostShare);
    this.isEnhCostShareAccepted = defaultBooleanToUndefined(aprOffer?.isEnhCostShareAccepted);
    this.isEnhCostShareUpdated = defaultBooleanToUndefined(aprOffer?.isEnhCostShareUpdated);
    this.isEnhCostShareRemoved = defaultBooleanToUndefined(aprOffer?.isEnhCostShareRemoved);
  };

  setEnhTfsCostShareId = (id: string | undefined) => {
    this.enhTfsCostShareId = id;
  };

  setIsEligibleForEnhTfsCostShare = (isEligible: boolean) => {
    this.isEligibleForEnhTfsCostShare = isEligible;
  };

  setIsEnhTfsCostShareUpdated = (isUpdated: boolean | undefined) => {
    this.isEnhTfsCostShareUpdated = isUpdated;
  };

  setIsEnhTfsCostShareAccepted = (isAccepted: boolean | undefined) => {
    this.isEnhTfsCostShareAccepted = isAccepted;
  };

  setEnhTfsCostShare = (enhTfsCostShare: EnhTfsCostShare | undefined) => {
    this.enhTfsCostShare = enhTfsCostShare;
  };

  updateEnhTfsCostShareFields = (aprOffer: Offer | undefined) => {
    this.enhTfsCostShareId = assignStringValue(aprOffer?.enhTfsCostShareId, undefined);
    this.isEligibleForEnhTfsCostShare = defaultBooleanToUndefined(aprOffer?.isEligibleForEnhTfsCostShare);
    this.isEnhTfsCostShareAccepted = defaultBooleanToUndefined(aprOffer?.isEnhTfsCostShareAccepted);
  };

  setWasEnhCostShareAccepted = (wasAccepted: boolean | undefined) => {
    this.wasEnhCostshareAccepted = wasAccepted;
  };

  setWasEnhTfsCostShareAccepted = (wasAccepted: boolean | undefined) => {
    this.wasEnhTfsCostShareAccepted = wasAccepted;
  };

  get hasEnhanced() {
    let isEnhanced = this.isDuplicate;

    for (const tier of this.tierMap.values()) {
      // eslint-disable-next-line no-loop-func
      tier.terms.forEach(term => {
        if (term.isEnhanced) {
          isEnhanced = true;
        }
      });
    }

    return isEnhanced;
  }

  get hasError() {
    let hasError = false;
    const tiersWithErrors = new Map<string, TierValidationObject>();

    for (const tier of this.tierMap.values()) {
      if (tier.hasTermiWithDateError) {
        hasError = true;
        tiersWithErrors.set(tier.tier, { hasError: true });
      }

      if (tier.hasPenRateSumError) {
        hasError = true;
        tiersWithErrors.set(tier.tier, {
          hasError: true,
          errorMessage: `Tier ${tier.tier} - Term Pen % for the selected tier must sum to 100%`,
        });
      }

      if (tier.hasTermiWithRateError) {
        hasError = true;
        tiersWithErrors.set(tier.tier, {
          hasError: true,
          errorMessage: `Tier ${tier.tier} - Subvention Rate must be lower than National or Regional Buy Rate`,
        });
      }

      if (tier.hasTermWithSubCashError) {
        hasError = true;
        tiersWithErrors.set(tier.tier, {
          hasError: true,
          errorMessage: `Tier ${tier.tier} - Subvention Cash must be higher than National Subvention Cash`,
        });
      }

      if (tier.hasTermWithInvalidPenRate) {
        hasError = true;
        tiersWithErrors.set(tier.tier, {
          hasError: true,
          errorMessage: `Tier ${tier.tier} - Term Pen % must be a numeric value greater than or equal to 0 and less than 100`,
        });
      }

      if (tier.hasTermWithInvalidRate) {
        hasError = true;
        tiersWithErrors.set(tier.tier, {
          hasError: true,
          errorMessage: `Tier ${tier.tier} - Subvention Rate must be a numeric value greater than or equal to 0 and less than 100`,
        });
      }

      if (tier.hasTermWithInvalidTfsShare) {
        hasError = true;
        tiersWithErrors.set(tier.tier, {
          hasError: true,
          errorMessage: `Tier ${tier.tier} - ${getFoundingSourceLabel(
            stores.userInfoStore.isLexus(),
            stores.userInfoStore.isSETUser(),
          )} Cost Share % must be a numeric value greater than or equal to 0 and less than 100`,
        });
      }
    }

    this.invalidTiers = tiersWithErrors;

    return hasError;
  }

  get hasEnRError() {
    return !this.isValidEnR && this.isDuplicate;
  }

  get selectedTierModel() {
    return this.tierMap.get(this.selectedTier);
  }

  isAdvertised = () => {
    return Boolean(this.tierMap.get(this.selectedTier)?.highestAdvertisedTerm.isAdvertised);
  };

  get showResetToNational() {
    let hasChangedRateOrCash = false;

    for (const tier of this.tierMap.values()) {
      if (tier.hasEnhancedTerm || tier.hasChangedSubventionCash) {
        hasChangedRateOrCash = true;
      }
    }

    return hasChangedRateOrCash;
  }

  get isSpecialEditionValid() {
    if (!this.isForCreate && this.isSpecialEdition) {
      return stores.userInfoStore.isGst() && Boolean(this.fields.accessoryCodes);
    } else {
      return true;
    }
  }

  get isDisabled() {
    return Boolean(this.isEnhCostShareUpdated) || this.isNationalAndIsAtc || Boolean(this.isEnhCostShareRemoved);
  }

  setIsNationalAndIsAtc = (isNationalAndIsAtc: boolean) => {
    this.isNationalAndIsAtc = isNationalAndIsAtc;
  };
}

export default AprCardModel;
