import clsx from 'clsx';
import { AdminConstants } from 'oat-admin-common';
import { Button, ButtonGroup, OATIcon } from 'oat-common-ui';
import { useMemo } from 'react';
import styles from './styles.module.scss';
interface Props {
  className?: string;
  enhCostShareOfferId?: string;
  id: string;
  isEnhCostShareUpdated?: boolean;
  isEnhCostShareRemoved?: boolean;
  isSummary?: boolean;
  offerType?: string;
  onApply?: () => void;
  onHide?: () => void;
  onCancel?: () => void;
}

const EnhCostShareNotification = ({
  className,
  enhCostShareOfferId,
  id,
  isEnhCostShareUpdated,
  isEnhCostShareRemoved,
  isSummary = false,
  offerType,
  onApply,
  onHide,
  onCancel,
}: Props) => {
  const isUpdated = isEnhCostShareUpdated && enhCostShareOfferId;
  const isRemoved = isEnhCostShareRemoved;

  const showNotification = isRemoved || isUpdated || isSummary;

  const enhCostShareMsg = useMemo(() => {
    const msgDetail =
      offerType === AdminConstants.OPTION_TYPE_NAMES.APR ? ' on Rate/Subvention Cash' : offerType === AdminConstants.OPTION_TYPE_NAMES.LEASE ? ' on RCF/Subvention Cash' : '';

    if (isSummary) {
      return `National Enhanced Cost Share has been updated. Please review offer(s).`;
    }

    if (isRemoved) {
      return `National Enhanced Cost Share${msgDetail} has been removed. Please review offer(s).`;
    }

    if (isUpdated) {
      return `Offer is eligible for National Enhanced Cost Share${msgDetail}. Do you want to apply?`;
    }
  }, [isSummary, offerType, isRemoved, isUpdated]);

  return showNotification ? (
    <div className={clsx(styles.enhCostShareNotification, className)}>
      <div className={styles.message}>
        <OATIcon icon="warning" colorTheme="red" />
        <span>{enhCostShareMsg}</span>
      </div>
      {isRemoved && (
        <Button variant="primary" id={`${id}-enh-cost-notification-ok`} onClick={() => onHide && onHide()}>
          OK
        </Button>
      )}
      {isUpdated && (
        <ButtonGroup>
          <Button variant="primary" id={`${id}-enh-cost-notification-cancel`} onClick={() => onCancel && onCancel()}>
            Cancel
          </Button>
          <Button variant="primary" id={`${id}-enh-cost-notification-apply`} onClick={() => onApply && onApply()}>
            Apply
          </Button>
        </ButtonGroup>
      )}
    </div>
  ) : (
    <></>
  );
};
export default EnhCostShareNotification;
