import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';
import styles from './styles.module.scss';

type Props = {
  onButtonPress: (isApplied: boolean) => void;
  submitBtnText?: string;
  text?: string;
  title?: string;
  id?: string;
};

const EnhCostShareModal = ({ onButtonPress, title, text, submitBtnText, id }: Props) => {
  return (
    <Modal size="md" isOpen onClose={() => onButtonPress(false)}>
      <ModalHeader title={title || 'Apply Enhanced Cost Share'} onClose={() => onButtonPress(false)} />
      <ModalBody className={styles.modalBody}>
        <p>{text || 'Offer is eligible for National Enhanced Cost Share. Do you want to apply?'}</p>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button id={`modal-cancel-btn-${id}`} variant="primary" onClick={() => onButtonPress(false)}>
            Cancel
          </Button>
          <Button id={`modal-apply-btn-${id}`} variant="primary" onClick={() => onButtonPress(true)}>
            {submitBtnText || 'Apply'}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default EnhCostShareModal;
