import clsx from 'clsx';
import { Button, ButtonGroup } from 'oat-common-ui';
import { Link } from 'react-router-dom';
import { Status } from '../../../constants/global';
import { Offering } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import styles from '../styles.module.scss';
import { processOfferingUrl } from '../utils';

interface Props {
  offering: Offering;
}

const ATCToggleButtons = ({ offering }: Props) => {
  const { region, parentOfferingId } = useUrlParams();
  const originalOfferingUrl = processOfferingUrl(region, offering?.originalOfferingId || '', parentOfferingId);
  const atcOfferingUrl = processOfferingUrl(region, offering?.atcOfferingId || '', parentOfferingId);

  return (
    <div className={styles.atcBtns}>
      <ButtonGroup>
        <Link to={originalOfferingUrl} className={styles.noStyle}>
          <Button disabled={!!atcOfferingUrl} variant="primary" className={clsx(styles.atcBtn, offering.status !== Status.MEETS_GUIDELINES && styles.notSelected)}>
            Meets Guidelines
          </Button>
        </Link>
        <Link to={atcOfferingUrl} className={styles.noStyle}>
          <Button disabled={!!originalOfferingUrl} variant="primary" className={clsx(styles.atcBtn, offering.status === Status.MEETS_GUIDELINES && styles.notSelected)}>
            Proposed ATC/ATE
          </Button>
        </Link>
      </ButtonGroup>
    </div>
  );
};

export default ATCToggleButtons;
