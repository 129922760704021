import { useEffect, useState } from 'react';
import useStores from '../stores/useStores';
import { getGstCompatibileAccessories } from '../utils/getGstCompatibileAccessories';

const useCompatibileAccessories = (series: string, seriesYear: string) => {
  const {
    userInfoStore: { isGst },
    compatibleAccessoriesStore,
  } = useStores();

  const [compatibileAccessories, setCompatibileAccessories] = useState<string[]>([]);

  useEffect(() => {
    const fetchAccessories = async () => {
      const accessories = await getGstCompatibileAccessories(series, seriesYear);
      compatibleAccessoriesStore.setData(accessories);
      setCompatibileAccessories(accessories);
    };

    isGst() && fetchAccessories();
  }, [compatibleAccessoriesStore, series, seriesYear, isGst]);

  return {
    compatibileAccessories,
  };
};

export default useCompatibileAccessories;
