import { observer } from 'mobx-react-lite';
import { BlockInputLabel, ButtonGroup, CustomDatePicker, dateFormatISOString, dateStringToDate } from 'oat-common-ui';
import useStores from '../../../../../../../stores/useStores';
import AprCardModel from '../../../../../models/AprCardModel';
import styles from './styles.module.scss';

interface Props {
  aprCard: AprCardModel;
  onChange: (skipDelay?: boolean, skipRecalc?: boolean) => void;
  isDisabled: boolean;
}

const OfferDates = ({ aprCard, onChange, isDisabled }: Props) => {
  const { programDetailsStore } = useStores();

  const firstTerm = aprCard.selectedTierModel?.terms[0];

  const handleUpdateDatesForAllTiers = (dateType: string, dateValue: Date | undefined) => {
    for (const tier of aprCard.tiersList) {
      const aprTier = aprCard.tierMap.get(tier);
      const aprFirstTerm = aprTier?.terms[0];

      aprFirstTerm?.updateField(dateType === 'startDate' ? 'startDate' : 'endDate', dateValue);
    }
  };

  const handleMinMaxDate = (isMin: boolean) => {
    if (isMin) {
      if (aprCard.isStandalone) {
        return dateStringToDate(programDetailsStore.offering.startDate);
      }

      return aprCard.isDuplicate ? firstTerm?.fields.defaultStartDate : firstTerm?.fields.nationalStartDate;
    }

    if (aprCard.isStandalone) {
      return dateStringToDate(programDetailsStore.offering.endDate);
    }

    return aprCard.isDuplicate ? firstTerm?.fields.defaultEndDate : firstTerm?.fields.nationalEndDate;
  };

  return (
    <ButtonGroup className={styles.margins}>
      <BlockInputLabel
        label="Start Date: "
        labelClass={styles.topInputLabel}
        bottomContent={
          <p className={styles.inputLabel}>{`Period starts: ${dateFormatISOString(
            aprCard.isStandalone ? programDetailsStore.offering.startDate : firstTerm?.fields.nationalStartDate,
          )}`}</p>
        }
      >
        <CustomDatePicker
          id={`apr-start-date-${aprCard.uid}`}
          disabled={(!aprCard.isStandalone && !aprCard.isDuplicate) || isDisabled}
          error={firstTerm?.hasDateError || (!aprCard.isValidEnR && aprCard.isDuplicate)}
          maxDate={handleMinMaxDate(false)}
          minDate={handleMinMaxDate(true)}
          onChange={(value?: Date) => {
            handleUpdateDatesForAllTiers('startDate', value);
            onChange(false, (aprCard.isEnhTfsCostShareAccepted && aprCard.enhTfsCostShareId) || (aprCard.isEnhCostShareAccepted && aprCard.enhCostShareOfferId) ? true : false);
          }}
          selected={firstTerm?.fields.startDate}
        />
      </BlockInputLabel>
      <BlockInputLabel
        label="End Date: "
        labelClass={styles.topInputLabel}
        bottomContent={
          <p className={styles.inputLabel}>{`Period ends: ${dateFormatISOString(
            aprCard.isStandalone ? programDetailsStore.offering.endDate : firstTerm?.fields.nationalEndDate,
          )}`}</p>
        }
      >
        <CustomDatePicker
          id={`apr-end-date-${aprCard.uid}`}
          disabled={(!aprCard.isStandalone && !aprCard.isDuplicate) || isDisabled}
          error={firstTerm?.hasDateError || (!aprCard.isValidEnR && aprCard.isDuplicate)}
          maxDate={handleMinMaxDate(false)}
          minDate={handleMinMaxDate(true)}
          onChange={(value?: Date) => {
            handleUpdateDatesForAllTiers('endDate', value);
            onChange(false, (aprCard.isEnhTfsCostShareAccepted && aprCard.enhTfsCostShareId) || (aprCard.isEnhCostShareAccepted && aprCard.enhCostShareOfferId) ? true : false);
          }}
          selected={firstTerm?.fields.endDate}
        />
      </BlockInputLabel>
    </ButtonGroup>
  );
};

export default observer(OfferDates);
