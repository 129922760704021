import { observer } from 'mobx-react-lite';
import { Status } from '../../../../../constants/global';
import useStores from '../../../../../stores/useStores';
import CashCardModel from '../../../models/CashCardModel';
import LeaseCardModel from '../../../models/LeaseCardModel';
import LeaseFormModel from '../../../models/LeaseFormModel';
import LeaseTermModel from '../../../models/LeaseTermModel';
import MiscCardModel from '../../../models/MiscCardModel';
import LeaseInput from '../components/LeaseInput';
import styles from '../styles.module.scss';

interface Props {
  leaseTerm: LeaseTermModel;
  leaseForm: LeaseFormModel;
  leaseCard: LeaseCardModel;
  cashOffers: CashCardModel[];
  miscOffers: MiscCardModel[];
  onChange: () => void;
}

const LeaseAdditionalCashAmount = ({ leaseCard, leaseTerm, leaseForm, cashOffers, miscOffers, onChange }: Props) => {
  const { programDetailsStore } = useStores();

  const handleAdditionalCash = (value: string) => {
    const found = [...cashOffers, ...miscOffers].find(item => item.id === leaseForm.inputs.additionalCashOfferId);
    leaseTerm.syncMasterAndExamples();
    leaseForm.updateAdditionalCashAmount(value, programDetailsStore.ryoEarnings, found); // recalculate is called in here
    leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
    onChange();
  };

  return (
    <LeaseInput
      id={`additional-cash-amount-${leaseForm.uid}`}
      label="Additional Cash Amount"
      dollarSign
      value={leaseForm.inputs.additionalCashAmount}
      className={styles.adtnlCash}
      disabled={leaseForm.isExample || !leaseForm.inputs.additionalCashOfferId || programDetailsStore.offering.status === Status.MEETS_GUIDELINES || leaseCard.isDisabled}
      allowNegative={false}
      wholeNumber
      error={!!leaseForm.additionalCashError}
      onValueChange={vals => {
        handleAdditionalCash(vals.value);
      }}
    />
  );
};

export default observer(LeaseAdditionalCashAmount);
