import { makeAutoObservable } from 'mobx';
import { uuidv4 } from 'oat-common-ui';
import { Offering } from '../../../gql/generated';
import { assignNumberValue } from '../../../utils/assignValue';

export interface SeriesProfileForecastedSales {
  seriesProfileId: string;
  forecastedSales: number;
  group: string;
  series: string;
  isEarningsOnly: boolean;
  isMultiSeries: boolean;
  selectedRaId: string;
}

class FSVModel {
  uid = uuidv4();
  offeringId = '';
  offeringRev = '';
  totalForecastedSales = 0;
  seriesProfileForecastedSales: SeriesProfileForecastedSales[] = [];
  singleSeriesFsvTotal = 0;

  constructor(offering: Offering, totalForecastedSales: number, seriesProfileForecastedSales: SeriesProfileForecastedSales[]) {
    makeAutoObservable(this);

    this.seriesProfileForecastedSales = seriesProfileForecastedSales;
    this.totalForecastedSales = totalForecastedSales;
    this.offeringId = offering.id;
    this.offeringRev = offering.rev;
  }

  calculateEarningsOnlyFsv = () => {
    this.singleSeriesFsvTotal = 0;

    this.seriesProfileForecastedSales.forEach(profile => {
      if (!profile.isEarningsOnly && !profile.isMultiSeries) {
        this.singleSeriesFsvTotal += assignNumberValue(profile.forecastedSales, 0);
      }
    });

    this.seriesProfileForecastedSales.forEach(sp => {
      if (sp.isEarningsOnly) {
        sp.forecastedSales = this.totalForecastedSales - this.singleSeriesFsvTotal;
      }
    });
  };

  updateProfileSales = (profile: SeriesProfileForecastedSales, value: number) => {
    profile.forecastedSales = value;
    this.calculateEarningsOnlyFsv();
  };

  updateTotalFsv = (value: string) => {
    this.totalForecastedSales = Number(value);
  };
}

export default FSVModel;
