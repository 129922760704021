import { observer } from 'mobx-react-lite';
import { formatDollars } from 'oat-common-ui';
import TooltipList, { TooltipListItem } from '../../../../../../components/TooltipList/TooltipList';
import LeaseFormModel from '../../../../models/LeaseFormModel';

interface Props {
  leaseDetails: LeaseFormModel;
}

const DueAtSigningTooltipMessage = ({ leaseDetails }: Props) => {
  const { isZeroDueAtSigning, isAcquisitionFeeCapitalized, isSubCashAppliedToDueAtSigning, isAddtlCashAppliedToDueAtSigning } = leaseDetails.inputs;

  return (
    <>
      <TooltipList>
        <TooltipListItem>
          <span>Down Payment</span>
          <span>${formatDollars(leaseDetails.inputs.downPayment)}</span>
        </TooltipListItem>
        <TooltipListItem symbol="+">
          <span>Target Payment</span>
          <span>${formatDollars(leaseDetails.inputs.targetPayment)}</span>
        </TooltipListItem>
        {/* Zero Due At Signing */}
        {isZeroDueAtSigning && (
          <>
            <TooltipListItem symbol="-">
              <span>Dealer Gross</span>
              <span>${formatDollars(leaseDetails.inputs.dealerGrossForZeroDueAtSigning)}</span>
            </TooltipListItem>
            <TooltipListItem symbol="-">
              <span>Subvention Cash</span>
              <span>${formatDollars(leaseDetails.inputs.subventionCash)}</span>
            </TooltipListItem>
          </>
        )}

        {/* Normal Due At Signing */}
        {!isZeroDueAtSigning && isSubCashAppliedToDueAtSigning && (
          <TooltipListItem symbol="-">
            <span>Subvention Cash</span>
            <span>${formatDollars(leaseDetails.inputs.subventionCash)}</span>
          </TooltipListItem>
        )}

        {!isZeroDueAtSigning && !isAcquisitionFeeCapitalized && (
          <TooltipListItem symbol="+">
            <span>Acquisition Fee</span>
            <span>${formatDollars(leaseDetails.inputs.acquisitionFee)}</span>
          </TooltipListItem>
        )}

        {isAddtlCashAppliedToDueAtSigning && Boolean(leaseDetails.inputs.additionalCashOfferId) && (
          <TooltipListItem symbol="-">
            <span>Additional Cash</span>
            <span>${formatDollars(leaseDetails.inputs.additionalCashAmount)}</span>
          </TooltipListItem>
        )}
      </TooltipList>

      <TooltipList divider>
        <TooltipListItem>
          <span>Due At Signing</span>
          <span>${formatDollars(leaseDetails.inputs.dueAtSigning)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
};

export default observer(DueAtSigningTooltipMessage);
