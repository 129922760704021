import clsx from 'clsx';
import { BlockInputLabel, formatDollars, OATIcon } from 'oat-common-ui';
import PenRateModel from '../../../../../models/PenRateModel';
import styles from './styles.module.scss';

interface Props {
  model: PenRateModel;
  labelText?: string;
  addPlusIcon?: boolean;
  showEstimatedCost?: boolean;
}

const CostContainer = ({ model, labelText = 'Est. Sales', addPlusIcon, showEstimatedCost = true }: Props) => {
  const { estimatedCost, forecastedSales } = model;

  return (
    <>
      <div className={clsx(styles.containerStack, styles.marginLeft)}>
        <BlockInputLabel labelComponent={<span className={clsx(styles.topText, styles.marginBottom)}>{labelText}</span>}>
          <span className={styles.bottomText}>{forecastedSales}</span>
        </BlockInputLabel>
      </div>
      {showEstimatedCost && (
        <div className={styles.containerStack}>
          <BlockInputLabel labelComponent={<span className={clsx(styles.topText, styles.marginBottom)}>Est. Cost</span>}>
            <div className={styles.estCostContainer}>
              {addPlusIcon && <OATIcon icon="plus" colorTheme="green" className={styles.marginRight} />}
              <span className={styles.bottomText}>${formatDollars(estimatedCost)}</span>
            </div>
          </BlockInputLabel>
        </div>
      )}
    </>
  );
};

export default CostContainer;
