import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { formatDollars } from 'oat-common-ui';
import TooltipList, { TooltipListItem } from '../../../../components/TooltipList/TooltipList';
import ReconcileSeriesModel from '../../models/ReconcileSeriesModel';
import styles from './styles.module.scss';

interface Props {
  series: ReconcileSeriesModel;
  unitsLabel: string;
  unitsValue: number;
  earnings: number;
  remaining: number;
}

const ProgramEarningsTooltipMessage = ({ series, unitsLabel, unitsValue, earnings, remaining }: Props) => {
  return (
    <>
      <TooltipList className={styles.progEarningsList}>
        <TooltipListItem>
          <span>{unitsLabel}</span>
          <span>{unitsValue}</span>
        </TooltipListItem>
        {series.ryoEarningsAmount > 0 && (
          <TooltipListItem symbol={series.ryoEarningsAmount > 0}>
            <span>{AdminConstants.OPTION_TYPE_NAMES.RYO_EARNINGS}</span>
            <span>${formatDollars(series.ryoEarningsAmount)}</span>
          </TooltipListItem>
        )}
        {series.allRyos.map((ryoItem, ind) => (
          <TooltipListItem key={ryoItem.uid} symbol={ind > 0 && ind !== series.allRyos.length - 1}>
            <span>
              {ind === 0 && <span>( </span>}
              {ryoItem.optionTypeName === AdminConstants.OPTION_TYPE_NAMES.NATIONAL_RYO ? 'Regional RYO' : ryoItem.optionTypeName}
              {ind === series.allRyos.length - 1 && <span> )</span>}
            </span>
            <span>${formatDollars(ryoItem.combinedPerUnitCost)}</span>
          </TooltipListItem>
        ))}
        {series.lumpsumOptions.map(lumpSum => (
          <TooltipListItem>
            <span>+ Lump Sum RYO</span>
            <span>${formatDollars(lumpSum.combinedPerUnitCost)}</span>
          </TooltipListItem>
        ))}
        <TooltipListItem className={styles.separator} />
        <TooltipListItem>
          <span>Est. Program Earnings</span>
          <span>${formatDollars(earnings)}</span>
        </TooltipListItem>
        <TooltipListItem className={styles.separator} />
        <TooltipListItem>
          <div>Earnings By Option</div>
        </TooltipListItem>
        <TooltipListItem className={styles.separator} />

        {series.offers.map((offer, ind) => (
          <TooltipListItem key={offer.uid} symbol={ind > 0}>
            <span className={styles.popoverTitle}>{offer.popoverTitle}</span>
            <span>${formatDollars(offer.offerEarnings)}</span>
          </TooltipListItem>
        ))}

        <TooltipListItem>
          <span>Remaining</span>
          <span>${formatDollars(remaining)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
};

export default observer(ProgramEarningsTooltipMessage);
