import clsx from 'clsx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
  BlockInputLabel,
  Button,
  ButtonGroup,
  Dropdown,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  OfferTypes,
  dateFormatISOString,
  dateStringToDate,
  useToast,
} from 'oat-common-ui';
import React, { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import AccordionModel from '../../../../components/Accordion/models/AccordionModel';
import createAccordionFromVehicles from '../../../../components/Accordion/utils/createAccordionFromVehicles';
import mapVehicleDataFromAccordion from '../../../../components/Accordion/utils/mapVehicleDataFromAccordion';
import StartEndDate from '../../../../components/StartEndDate';
import { LeaseDetails, VehicleInput, useEnhanceAndRefineMutation } from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import dateWithinDateRangeValidator from '../../../../utils/dateWithinDateRangeValidator';
import getDisabledVehicles from '../../../../utils/getDisabledVehicles';
import getInclExclLabel from '../../../../utils/getInclExclLabel';
import useUserInfo from '../../../../utils/useUserInfo';
import LeaseCardModel from '../../models/LeaseCardModel';
import ExclusionInclusion from '../ExclusionInclusion';
import Rate from '../LeaseCard/models/Rate';
import EnRErrorMsg from './EnRErrorMsg';
import styles from './styles.module.scss';
import AprCardModel from '../../models/AprCardModel';
import { setAprDefaultStartEndDates } from '../../../../utils/setDefaultStartEndDates';

interface Props {
  id: string;
  rev: string;
  startDate: string;
  endDate: string;
  minDate: Date | undefined;
  maxDate: Date | undefined;
  vehicles: VehicleInput[];
  nationalVehicles: VehicleInput[];
  tiers: Rate[];
  terms: string[];
  toggleIsInclusions: () => void;
  isInclusions: boolean;
  currentRcf?: string;
  openPenRateModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOfferId: React.Dispatch<React.SetStateAction<string>>;
  setTerm?: React.Dispatch<React.SetStateAction<number>>;
  onClose: () => void;
  parentNationalLeases?: LeaseDetails[];
  aprCard?: AprCardModel;
}

const AprLeaseEnRModal = ({
  id,
  rev,
  startDate,
  endDate,
  minDate,
  maxDate,
  vehicles,
  nationalVehicles,
  tiers,
  terms,
  toggleIsInclusions,
  isInclusions,
  currentRcf,
  openPenRateModal,
  setOfferId,
  setTerm,
  onClose,
  parentNationalLeases = [],
  aprCard,
}: Props) => {
  const {
    programDetailsStore: { insertLeaseCard, offerCards, insertAprCard, seriesProfile, offering },
    seriesMappingStore: { seriesMapping },
  } = useStores();

  const { region } = useUrlParams();
  const { isLexusUser } = useUserInfo();
  const disabledVehicles = getDisabledVehicles(nationalVehicles);
  const tiersOptions = aprCard
    ? aprCard.tiersList.map(tier => ({ label: `${tier}`, value: `${tier}` }))
    : tiers.map((tier, i) => ({
        label: `${tier.tier} ${i === 0 ? currentRcf : tier.subventedRcf}`,
        value: tier.tier,
      }));
  const [startDateInput, setStartDateInput] = useState<Date | undefined>(dateStringToDate(startDate));
  const [endDateInput, setEndDateInput] = useState<Date | undefined>(dateStringToDate(endDate));
  const [selectedTerms, setSelectedTerms] = useState<string[]>(terms);
  const [selectedTiers, setSelectedTiers] = useState<string[]>(aprCard ? aprCard.tiersList : tiers.map(item => item.tier));
  const [enhanceAndRefine] = useEnhanceAndRefineMutation();
  const { error } = useToast();

  const accordionModel = useLocalObservable(() => new AccordionModel(createAccordionFromVehicles(vehicles, seriesMapping, !isInclusions, disabledVehicles)));

  const btnsLabel = getInclExclLabel(isInclusions);

  const handleTerms = (term: string, remove = false) => {
    if (remove) {
      setSelectedTerms(selectedTerms.filter(termItem => termItem !== term));
    } else {
      const set = new Set(selectedTerms);
      set.add(term);
      setSelectedTerms(Array.from(set).sort());
    }
  };

  const handleTiers = (tier: string, remove = false) => {
    if (remove) {
      setSelectedTiers(selectedTiers.filter(tierItem => tierItem !== tier));
    } else {
      const set = new Set(selectedTiers);
      set.add(tier);
      if (aprCard) {
        setSelectedTiers(aprCard.tiersList.filter(item => set.has(item)));
      } else {
        setSelectedTiers((tiers?.map(item => item.tier) ?? []).filter(item => set.has(item)));
      }
    }
  };

  const handleSubmit = async () => {
    const stringStartDate = dateFormatISOString(startDateInput);
    const stringEndDate = dateFormatISOString(endDateInput);
    const [excludedList, includedList] = mapVehicleDataFromAccordion(accordionModel.items, vehicles, !isInclusions);

    const payload = {
      id,
      rev,
      startDate: stringStartDate,
      endDate: stringEndDate,
      vehicles: [...excludedList, ...includedList],
      terms: selectedTerms,
      tiers: selectedTiers,
    };

    try {
      const res = await trackPromise(enhanceAndRefine({ variables: { input: payload } }));

      if (res.data?.enhanceAndRefine.success) {
        const offer = res.data.enhanceAndRefine.offer;
        setOfferId(offer.id);

        switch (offer.offerType) {
          case OfferTypes.LEASE: {
            // process lease offer
            const leaseCard = new LeaseCardModel();
            leaseCard.setNationalLeases(parentNationalLeases);
            leaseCard.setLeases({ regional: offer }, region, isLexusUser());
            leaseCard.setNationalVehicles(nationalVehicles);
            const termsLength = leaseCard.leaseTerms.length;
            if (termsLength > 0 && setTerm) {
              setTerm(leaseCard.leaseTerms[termsLength - 1].term);
            }
            insertLeaseCard(leaseCard, id);

            break;
          }
          case OfferTypes.APR: {
            // process apr offer
            const card = new AprCardModel();
            card.initData({ offer: { regional: offer, national: aprCard?.nationalOffer }, seriesMapping, region, seriesProfile, offering });
            setAprDefaultStartEndDates(offerCards.apr, card);
            insertAprCard(card, id);
            break;
          }
        }
        openPenRateModal(true);
      }
      onClose();
    } catch (e) {
      error((e as Error).message);
    }
  };

  const hasTermError = selectedTerms.length === 0;
  const hasTierError = selectedTiers.length === 0;
  const hasDateError = !dateWithinDateRangeValidator(startDate, minDate, maxDate) || !dateWithinDateRangeValidator(endDate, minDate, maxDate);
  const hasExclusionError = accordionModel.isAllSelected(!isInclusions);
  const hasError = hasTermError || hasTierError || hasDateError || hasExclusionError;

  return (
    <Modal onClose={onClose} isOpen>
      <ModalHeader title="Enhance & Refine" onClose={onClose} />
      <ModalBody className={styles.enhanceModalBody}>
        <StartEndDate
          dateWrapperClass={styles.enhanceDates}
          dateClass={styles.enhanceDate}
          startDate={startDateInput}
          endDate={endDateInput}
          minDate={minDate}
          maxDate={maxDate}
          setStartDate={setStartDateInput}
          setEndDate={setEndDateInput}
          isFullWidth
        />
        <ExclusionInclusion toggleIsInclusions={toggleIsInclusions} isInclusions={isInclusions} vehiclesAccordion={accordionModel} />
        <ButtonGroup>
          <Button id={`select-all-cta-${id}`} variant="text" className={styles.button} onClick={() => accordionModel.toggleAll(true)}>{`Select All ${btnsLabel}`}</Button>
          <Button id={`remove-all-cta-${id}`} variant="text" className={styles.button} onClick={() => accordionModel.toggleAll(false)}>{`Remove All ${btnsLabel}`}</Button>
        </ButtonGroup>
        <div className={styles.pillboxContainer}>
          <BlockInputLabel label="Terms:" className={clsx(styles.pillbox)} labelClass={styles.inputLabel}>
            <Dropdown
              darkTheme
              minWidth={220}
              id="mdl-terms-input"
              value={''}
              pillBoxValue={selectedTerms}
              isPillBox
              onCancelClick={item => {
                handleTerms(item, true);
              }}
              onChange={item => handleTerms(item.value)}
              usePillBoxLabelForSelectedItems
              options={terms.map(term => ({
                label: `Term ${term}`,
                value: term,
              }))}
            />
          </BlockInputLabel>

          <BlockInputLabel label="Tiers:" className={clsx(styles.pillbox)} labelClass={styles.inputLabel}>
            <Dropdown
              darkTheme
              minWidth={220}
              id="mdl-tiers-input"
              value={''}
              pillBoxValue={selectedTiers}
              isPillBox
              onCancelClick={item => {
                handleTiers(item, true);
              }}
              onChange={item => handleTiers(item.value)}
              options={tiersOptions}
            />
          </BlockInputLabel>
        </div>
        <EnRErrorMsg hasDateError={hasDateError} hasExclusionError={hasExclusionError} isInclusions={isInclusions} hasTermError={hasTermError} hasTierError={hasTierError} />
      </ModalBody>
      <ModalFooter className={styles.enhanceModalFooter}>
        <Button id="enhance-refine-modal-submit" variant="primary" onClick={handleSubmit} disabled={hasError}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default observer(AprLeaseEnRModal);
