import ExpTableHeadVal from '../ExpTableHeadVal';

interface Props {
  aprTerms: number[];
}

const AprTableHeaderValues = ({ aprTerms }: Props) => {
  return (
    <tr>
      <ExpTableHeadVal val=" " />
      {aprTerms.map(term => {
        return <ExpTableHeadVal val={term} key={term} />;
      })}
      <ExpTableHeadVal val="PNV" />
      <ExpTableHeadVal
        val={
          <>
            Penetration
            <br />
            Rate
          </>
        }
      />
      <ExpTableHeadVal
        val={
          <>
            Forecasted
            <br />
            Sales
          </>
        }
      />
    </tr>
  );
};

export default AprTableHeaderValues;
