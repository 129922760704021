import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  className?: string;
  children?: ReactNode;
}

export const SubHeaderContainer = ({ className, children }: Props) => {
  return (
    <>
      <section className={clsx(styles.subHeaderContainer, className)}>
        <div className={styles.content}>{children}</div>
      </section>
      <div className={styles.spacer} />
    </>
  );
};
