import { makeAutoObservable } from 'mobx';
import { AdminConstants } from 'oat-admin-common';
import { DEFAULT_TIERS, FEATURE_OR_2477 } from '../constants/global';
import defaultOffering from '../defaultObjects/defaultOffering';
import defaultRgnlAlt from '../defaultObjects/defaultRgnlAlt';
import defaultSeriesProfile from '../defaultObjects/defaultSeriesProfile';
import { EnhancedCostShare, Offer, Offering, PenetrationDetails, RgnlAlt, SavePenetrationResult, SeriesMapping, SeriesProfile } from '../gql/generated';
import { SETContestNumbersByType } from '../models/SETContestNumber';
import AprCardModel from '../pages/ProgramDetails/models/AprCardModel';
import CashCardModel from '../pages/ProgramDetails/models/CashCardModel';
import LeaseCardModel from '../pages/ProgramDetails/models/LeaseCardModel';
import MiscCardModel from '../pages/ProgramDetails/models/MiscCardModel';
import PenRateModel from '../pages/Summary/models/PenRateModel';
import { createCashCard } from '../pages/createOffer/utils/createCashCard';
import { createMiscCard } from '../pages/createOffer/utils/createMiscCard';
import { assignDollarCents, assignNumberValue } from '../utils/assignValue';
import { buildContestNumbers } from '../utils/buildContestNumbers';
import getSortedCombinedOffers from '../utils/getSortedCombinedOffers';
import { setAprDefaultStartEndDates, setCashDefaultStartEndDates, setLeaseDefaultStartEndDates, setMiscDefaultStartEndDates } from '../utils/setDefaultStartEndDates';

class ProgramDetailsStore {
  offering: Offering = defaultOffering;
  seriesProfile: SeriesProfile = defaultSeriesProfile;
  rgnlAlt: RgnlAlt = defaultRgnlAlt;
  contestNumbersByType: SETContestNumbersByType[] = [];

  offerCards = {
    lease: new Array<LeaseCardModel>(),
    apr: new Array<AprCardModel>(),
    cash: new Array<CashCardModel>(),
    misc: new Array<MiscCardModel>(),
  };

  totalCost = 0;
  ryoEarnings = 0;
  nationalRyo = 0;
  additionalRyo = 0;
  lumpSum = 0;
  isMultiSeries = false;

  enhancedCostShares: EnhancedCostShare[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setOffering = (offering: Offering) => {
    this.offering = offering;
  };

  setSeriesProfile = (
    seriesProfile: SeriesProfile,
    regionCode: string,
    isLexus: boolean,
    seriesMapping: SeriesMapping[],
    scrollTo = '',
    scrollToTerm = '',
    scrollToTier = '',
    isNational = false,
  ) => {
    this.seriesProfile = seriesProfile;
    this.isMultiSeries = seriesProfile.series.length > 1;

    const selectedRa = seriesProfile.rgnlAlts?.find(ra => ra && ra.isSelected);
    if (selectedRa) {
      this.rgnlAlt = selectedRa;
      const cash: CashCardModel[] = [];
      const apr: AprCardModel[] = [];
      const lease: LeaseCardModel[] = [];
      const misc: MiscCardModel[] = [];
      const ryoEarnings = selectedRa.ryoEarnings ?? 0;
      let nationalRyo = 0;
      let additionalRyo = 0;
      let lumpSum = 0;

      const isDisabled = Boolean(isNational && this.offering.isAtc);

      const sortedOffers = getSortedCombinedOffers(this.rgnlAlt.offers || []);
      sortedOffers.forEach(offer => {
        if (!offer) {
          return;
        }
        switch (offer.regional.optionTypeName) {
          case AdminConstants.OPTION_TYPE_NAMES.CUSTOMER_CASH: {
            const cashObj = createCashCard(new CashCardModel(), seriesMapping, offer, seriesProfile, this.offering.startDate, this.offering.endDate);
            cashObj.isMultiSeries = this.isMultiSeries;

            cashObj.setIsNationalAndIsAtc(isDisabled);
            if (cashObj.isDuplicate) {
              setCashDefaultStartEndDates(cash, cashObj);
            }

            cash.push(cashObj);
            break;
          }
          case AdminConstants.OPTION_TYPE_NAMES.APR: {
            const selectedTier = scrollTo.includes(offer.regional.id) ? scrollToTier : undefined;
            const aprObj = new AprCardModel({ offer, seriesMapping, seriesProfile, selectedTier, region: regionCode, offering: this.offering });

            if (aprObj.isDuplicate) {
              setAprDefaultStartEndDates(apr, aprObj);
            }

            aprObj.isMultiSeries = this.isMultiSeries;
            aprObj.setIsNationalAndIsAtc(isDisabled);
            apr.push(aprObj);
            break;
          }
          case AdminConstants.OPTION_TYPE_NAMES.LEASE: {
            const leaseObj = new LeaseCardModel();
            const term = scrollTo.includes(offer.regional.id) ? Number(scrollToTerm) : undefined;
            leaseObj.setLeases(offer, regionCode, isLexus, term);
            leaseObj.isMultiSeries = this.isMultiSeries;

            leaseObj.enhCostShareOfferId = offer.regional.enhCostShareOfferId || '';
            leaseObj.setIsNationalAndIsAtc(isDisabled);

            if (leaseObj.isDuplicate) {
              setLeaseDefaultStartEndDates(lease, leaseObj);
            }

            lease.push(leaseObj);
            break;
          }
          case AdminConstants.OPTION_TYPE_NAMES.ADDITIONAL_RYO: {
            additionalRyo += assignNumberValue(offer.regional.cashDetails?.combinedPerUnitCost);
            break;
          }
          case AdminConstants.OPTION_TYPE_NAMES.NATIONAL_RYO: {
            nationalRyo += assignNumberValue(offer.regional.cashDetails?.combinedPerUnitCost);
            break;
          }
          case AdminConstants.OPTION_TYPE_NAMES.LUMPSUM_RYO: {
            lumpSum += offer.regional.cashDetails?.combinedPerUnitCost ?? 0;
            break;
          }
          default: {
            const miscObj = createMiscCard(new MiscCardModel(), seriesMapping, offer, seriesProfile, this.offering.startDate, this.offering.endDate);
            miscObj.isMultiSeries = this.isMultiSeries;
            miscObj.updateInitialData();
            miscObj.setIsNationalAndIsAtc(isDisabled);

            if (miscObj.isDuplicate) {
              setMiscDefaultStartEndDates(misc, miscObj);
            }

            misc.push(miscObj);
            break;
          }
        }
      });

      this.offerCards.cash = cash;
      this.offerCards.apr = apr;
      this.offerCards.lease = lease;
      this.offerCards.misc = misc;
      this.ryoEarnings = ryoEarnings;
      this.nationalRyo = nationalRyo;
      this.additionalRyo = additionalRyo;
      this.lumpSum = lumpSum;
    }
  };

  setEnhancedCostShares = (enhCostShares: EnhancedCostShare[]) => {
    this.enhancedCostShares = enhCostShares;
    this.offerCards.apr.forEach(apr => apr.enhCostShareOfferId && apr.setEnhancedCostShare(this.getEnhancedCostShareById(apr.enhCostShareOfferId)));
    this.offerCards.lease.forEach(lease => lease.enhCostShareOfferId && lease.setEnhancedCostShare(this.getEnhancedCostShareById(lease.enhCostShareOfferId)));
    this.offerCards.misc.forEach(misc => misc.enhCostShareOfferId && misc.setEnhancedCostShare(this.getEnhancedCostShareById(misc.enhCostShareOfferId)));
  };

  getEnhancedCostShareById = (id?: string) => {
    return this.enhancedCostShares.find(costShare => costShare.offerId === id);
  };

  updateOffersForecastedSales = (ryoEarnings: number, { apr, cash, misc, lease }: { [offerType: string]: PenRateModel[] }) => {
    this.offerCards.apr.forEach(offerCard => {
      apr?.forEach(aprPenRate => {
        if (aprPenRate.id === offerCard.id) {
          const offerCardTier = offerCard.tierMap.get(aprPenRate.tier);
          if (offerCardTier) {
            offerCardTier.updateForecastedSales(Number(aprPenRate.forecastedSales));
            offerCardTier.updateOfferCosts(ryoEarnings);
          }
        }
      });
    });

    this.offerCards.cash.forEach(offerCard => {
      cash?.forEach(cashPenRate => {
        if (cashPenRate.id === offerCard.id) {
          offerCard.updateField('forecastedSales', Number(cashPenRate.forecastedSales));
          offerCard.updateOfferCosts(ryoEarnings);
        }
      });
    });

    this.offerCards.misc.forEach(offerCard => {
      misc?.forEach(miscPenRate => {
        if (miscPenRate.id === offerCard.id) {
          offerCard.updateField('forecastedSales', Number(miscPenRate.forecastedSales));
          offerCard.updateOfferCosts(ryoEarnings);
        }
      });
    });

    this.offerCards.lease.forEach(offerCard => {
      lease?.forEach(leaseRate => {
        if (leaseRate.id === offerCard.id) {
          const leaseForm = offerCard.leaseTerms.find(leaseTerm => leaseTerm.term === Number(leaseRate.term))?.getMaster();

          if (leaseForm) {
            leaseForm.updateInput('penetration', Number(leaseRate.penetration));
            leaseForm.updateInput('forecastedSales', Number(leaseRate.forecastedSales));
            leaseForm.updateOfferCosts(ryoEarnings);
          }
        }
      });
    });
  };

  setTotalCost = (cost: number) => {
    this.totalCost = cost;
  };

  removeEnRChildOffer = (id: string, offerType: 'apr' | 'cash' | 'misc' | 'lease', tierTerm?: string | number) => {
    switch (offerType) {
      case 'apr':
        this.offerCards.apr = this.offerCards.apr.filter(offer => !(offer.isDuplicate && offer.parentId === id && offer.selectedTier === tierTerm));
        break;

      case 'cash':
        this.offerCards.cash = this.offerCards.cash.filter(offer => !(offer.isDuplicate && offer.parentId === id));
        break;

      case 'misc':
        this.offerCards.misc = this.offerCards.misc.filter(offer => !(offer.isDuplicate && offer.parentId === id));
        break;

      case 'lease':
        this.offerCards.lease.forEach(leaseCard => {
          if (leaseCard.isDuplicate && leaseCard.parentId === id) {
            leaseCard.terms = leaseCard.terms.filter(term => term !== tierTerm);
          }
        });
        break;

      default:
        break;
    }
  };

  removeAprOffer = (id: string, rev: string) => {
    let selectedTier = '';

    this.offerCards.apr = this.offerCards.apr.filter(aprCard => {
      if (aprCard.id !== id) {
        return true;
      }

      aprCard.rev = rev;
      aprCard.tierMap.delete(aprCard.selectedTier);
      selectedTier = aprCard.selectedTier;
      aprCard.tiersList = aprCard.tiersList
        .filter(tier => tier !== aprCard.selectedTier)
        .sort((tierA, tierB) => (DEFAULT_TIERS.findIndex(tier => tierA === tier) > DEFAULT_TIERS.findIndex(tier => tierB === tier) ? 1 : -1));

      const lastTier = aprCard.tiersList[aprCard.tiersList.length - 1];
      const tierModel = aprCard.tierMap.get(lastTier);

      if (tierModel) {
        aprCard.switchTier(lastTier);
      }

      return aprCard.tierMap.size > 0;
    });

    this.removeEnRChildOffer(id, 'apr', selectedTier);
  };

  removeCashOffer = (id: string, rev: string) => {
    this.offerCards.cash = this.offerCards.cash.filter(item => item.id !== id && item.rev !== rev);
    this.removeEnRChildOffer(id, 'cash');
  };

  removeLeaseOffer = (id: string, rev: string, term: number) => {
    const leases = this.offerCards.lease.filter(leaseCard => {
      if (leaseCard.id !== id) {
        return true;
      }

      // update rev and filter out deleted lease
      leaseCard.rev = rev;
      leaseCard.leaseTerms = leaseCard.leaseTerms.filter(item => item.term !== term);

      // select next available term
      leaseCard.leaseTerms.reverse();
      const lastLease = leaseCard.leaseTerms[0];
      if (lastLease) {
        leaseCard.term = lastLease.term;
        leaseCard.terms = leaseCard.terms.filter(t => t !== term);
      }

      // if there are no leases left, the whole lease card will delete
      return leaseCard.leaseTerms.length > 0;
    });

    this.offerCards.lease = leases;
    this.removeEnRChildOffer(id, 'lease', term);
  };

  removeMiscOffer = (id: string, rev: string) => {
    this.offerCards.misc = this.offerCards.misc.filter(item => item.id !== id && item.rev !== rev);
    this.removeEnRChildOffer(id, 'misc');
  };

  setSETContestNumbersByType = (data: SETContestNumbersByType[]) => {
    this.contestNumbersByType = buildContestNumbers(data, this.offering.id, true);
  };

  insertCashCard = (newCard: CashCardModel, parentId?: string) => {
    if (parentId) {
      const parentIndex = this.offerCards.cash.findIndex(offer => offer.id === parentId);
      this.offerCards.cash.splice(parentIndex + 1, 0, newCard);
    } else {
      this.offerCards.cash.push(newCard);
    }
  };

  insertMiscCard = (newCard: MiscCardModel, parentId?: string) => {
    if (FEATURE_OR_2477) {
      newCard.setEnhancedCostShare(this.getEnhancedCostShareById(newCard.enhCostShareOfferId));
    }

    if (parentId) {
      const parentIndex = this.offerCards.misc.findIndex(offer => offer.id === parentId);
      this.offerCards.misc.splice(parentIndex + 1, 0, newCard);
    } else {
      this.offerCards.misc.push(newCard);
    }
  };

  insertAprCard = (newCard: AprCardModel, parentId?: string) => {
    if (parentId) {
      const parentIndex = this.offerCards.apr.findIndex(offer => offer.id === parentId);
      this.offerCards.apr.splice(parentIndex + 1, 0, newCard);
    } else {
      this.offerCards.apr.push(newCard);
    }
  };

  insertLeaseCard = (newCard: LeaseCardModel, parentId?: string) => {
    if (FEATURE_OR_2477) {
      newCard.setEnhancedCostShare(this.getEnhancedCostShareById(newCard.enhCostShareOfferId));
    }

    if (parentId) {
      const parentIndex = this.offerCards.lease.findIndex(offer => offer.id === parentId);
      this.offerCards.lease.splice(parentIndex + 1, 0, newCard);
    } else {
      this.offerCards.lease.push(newCard);
    }
  };

  updateRevsFromPenRates = (penetrationDetails: PenetrationDetails[], penRates?: SavePenetrationResult) => {
    if (penRates) {
      const { apr, lease, cash, misc } = this.offerCards;

      // update offerCosts
      [...cash, ...misc].forEach(offer => {
        const penDets = penetrationDetails.find(item => item.offerId === offer.id);
        if (penDets) {
          offer.fields.offerEarnings = penDets.offerEarnings;
          offer.fields.offerCost = penDets.offerCost;
          offer.fields.offerBalance = assignDollarCents(penDets.offerEarnings - penDets.offerCost);
        }
      });

      lease.forEach(offer => {
        offer.leaseTerms.forEach(leaseTerm => {
          const penDets = penetrationDetails.find(item => item.offerId === offer.id && item.term === leaseTerm.term);
          const masterLease = leaseTerm.getMaster();
          if (penDets && masterLease) {
            masterLease.offerEarnings = penDets.offerEarnings;
            masterLease.offerCost = penDets.offerCost;
            masterLease.offerBalance = assignDollarCents(penDets.offerEarnings - penDets.offerCost);
          }
        });
      });

      apr.forEach(offer => {
        Array.from(offer.tierMap.values()).forEach(aprTier => {
          const penDets = penetrationDetails.find(item => item.offerId === offer.id && item.tier === aprTier.tier);
          if (penDets) {
            aprTier.fields.offerEarnings = penDets.offerEarnings;
            aprTier.fields.offerCost = penDets.offerCost;
            aprTier.fields.offerBalance = assignDollarCents(penDets.offerEarnings - penDets.offerCost);
          }
        });
      });

      // updates revs
      const offerCards = [...apr, ...lease, ...cash, ...misc];
      penRates.rgnlAlt.offers?.forEach(offer => {
        const foundCard = offerCards.find(card => card.id === offer?.regional.id);
        if (foundCard) {
          foundCard.rev = offer?.regional.rev ?? '';
        }
      });
    }
  };

  /**
   * maps values from response if applicable
   * the whole lease card, terms, and examples will be overwritten with values from backend
   */
  updateAdditionalCashForLease = (offers: Offer[]) => {
    this.offerCards.lease.forEach(leaseCard => {
      // match offer ids
      const foundOffer = offers.find(offer => offer.id === leaseCard.id);
      if (foundOffer) {
        // For national offer, we only need the leaseDetails to process.
        // Spreading foundOffer is to make the CombinedOffers type happy.
        const nationalOffer = foundOffer.isStandalone ? undefined : { ...foundOffer, leaseDetails: leaseCard.nationalLeases };
        leaseCard.updateLeasesFromResponse({ regional: foundOffer, national: nationalOffer });
      }
    });
  };
}

export default ProgramDetailsStore;
