import IpBreadCrumbs from '../../components/IpComponents/IpBreadCrumbs';
import { Offering, useGetRegionalOfferingsByNationalIdQuery } from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';
import LoadingPage from '../LoadingPage';
import RegionStatusComponent from './RegionStatusComponent';

const RegionStatus = () => {
  const { period } = useUrlParams();
  const { regionalStatusStore, userInfoStore } = useStores();

  const { data, loading, error } = useGetRegionalOfferingsByNationalIdQuery({
    variables: {
      nationalId: period,
    },
  });

  if (loading || error) {
    return <LoadingPage breadCrumbs={IpBreadCrumbs(false)} error={!!error} />;
  }

  if (data) {
    regionalStatusStore.setRegionalStatuses(data.regionalOfferingsByNationalId as Offering[], userInfoStore.isNational());
  }

  return <RegionStatusComponent />;
};

export default RegionStatus;
