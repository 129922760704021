const sortTiers = (inputTiers: string[], referenceTiers: string[]) => {
  const tiers: string[] = [];
  referenceTiers.forEach(item => {
    if (inputTiers.find(item2 => item === item2)) {
      tiers.push(item);
    }
  });

  return tiers;
};

export default sortTiers;
