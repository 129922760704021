import { useLazyQuery } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { useToast } from 'oat-common-ui';
import { useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import IpBreadCrumbs from '../../components/IpComponents/IpBreadCrumbs';
import MainFooter from '../../components/MainFooter';
import MainHeader from '../../components/MainHeader';
import SubHeader from '../../components/SubHeader';
import { ReportTypes } from '../../constants/global';
import { GetPresignedDownloadUrlDocument, Offering, useCompleteReconcileMutation, useSaveShowEnhancedMutation } from '../../gql/generated';
import useReportGeneration from '../../hooks/useReportGeneration';
import useReportMutations from '../../hooks/useReportMutations';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';
import CompletedBy from './components/CompletedBy';
import Print from './components/Print';
import Details from './components/ReconcileTemplates/Details';
import Summary from './components/ReconcileTemplates/Summary';

const ReconcileComponent = () => {
  const {
    userInfoStore: { isNational },
    reconcileStore: { toggleEnhanced, offering, profiles, setOffering, reconcileData },
    reportsStore: { generatedReports },
  } = useStores();
  const { parentOfferingId } = useUrlParams();
  const [saveShowEnhanced] = useSaveShowEnhancedMutation();
  const [completeReconcile] = useCompleteReconcileMutation();
  const { error } = useToast();
  const allReports = useReportMutations(offering, isNational(), true);
  const { generateReports } = useReportGeneration();

  const [runDownloadQuery] = useLazyQuery(GetPresignedDownloadUrlDocument, {
    onCompleted: data => {
      const a = document.createElement('a');
      a.href = data.presignedDownloadUrl.presignedUrl;
      a.click();
    },
  });

  const onDownload = () => {
    const report = generatedReports.filter(item => (isNational() ? item.reportName === ReportTypes.FY_RECONCILE : item.reportName === ReportTypes.RECONCILE_SUMMARY))[0];
    runDownloadQuery({
      variables: {
        s3Key: report.s3Key,
        fileName: report.fileName,
      },
    });
  };

  const handleToggleEnhanced = async () => {
    try {
      toggleEnhanced();
      const resp = await trackPromise(
        saveShowEnhanced({ variables: { input: { regionalOfferingId: offering.id, regionalOfferingRev: offering.rev, isEnhancementsOnly: reconcileData.isEnhancementsOnly } } }),
      );
      setOffering(resp.data?.saveShowEnhanced.offering as Offering);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleGenerateReconcile = async () => {
    try {
      const res = allReports[isNational() ? ReportTypes.FY_RECONCILE : ReportTypes.RECONCILE_SUMMARY];
      await res();
      generateReports(offering, false, true, parentOfferingId);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleCompleteReconcile = async () => {
    try {
      reconcileData.setIsCompleteReconcileClicked();
      if (reconcileData.validateInputsForReconcile()) {
        const resp = await trackPromise(completeReconcile({ variables: { input: { regionalOfferingId: offering.id, regionalOfferingRev: offering.rev } } }));
        setOffering(resp.data?.completeReconcile.offering as Offering);
        reconcileData.updateData(resp.data?.completeReconcile.offering as Offering);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  useEffect(() => {
    generateReports(offering, false, true, parentOfferingId);
  }, [generateReports, offering, parentOfferingId]);

  return (
    <>
      <MainHeader
        headerTitle={`${offering.name}: Reconcile`}
        breadCrumbs={IpBreadCrumbs(isNational(), offering, offering.regionCode, undefined, undefined, 'Reconcile', undefined, true)}
        showRightContent
        rightContent={<Print onGenerate={handleGenerateReconcile} onDownload={onDownload} />}
      />
      <SubHeader
        profiles={profiles}
        updateCarryOver={() => {}}
        showRightPanel={false}
        isReconcile
        toggleEnhanced={handleToggleEnhanced}
        isEnhancementsOnly={reconcileData.isEnhancementsOnly}
        offering={offering}
      />
      <Summary />
      <Details />
      {isNational() && <MainFooter darkTheme showCompleteReconcile onCompleteReconcile={handleCompleteReconcile} leftContent={<CompletedBy reconcileData={reconcileData} />} />}
    </>
  );
};

export default observer(ReconcileComponent);
