import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  width?: string;
  children?: ReactNode;
}

const ListTooltip = ({ width, children }: Props) => {
  return (
    <ul className={styles.listTooltip} style={{ width }}>
      {children}
    </ul>
  );
};

export default ListTooltip;
