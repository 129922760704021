import { Offering } from '../gql/generated';

const defaultOffering: Offering = {
  brand: '',
  created: '',
  createdBy: '',
  endDate: '',
  id: '',
  name: '',
  regionCode: '',
  rev: '',
  startDate: '',
  status: '',
  updated: '',
  updatedBy: '',
  note: '',
  nationalNote: '',
  isUpdatedToMG: false,
};

export default defaultOffering;
