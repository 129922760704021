import { BreadCrumbType } from 'oat-common-ui';
import { Offering, ReconcileResponse, useGetOfferingByIdQuery, useGetReconcileDetailsQuery } from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';
import LoadingPage from '../LoadingPage';
import ReconcileComponent from './ReconcileComponent';
import { xformSeriesToProfiles } from './utils/xformSeriesToProfiles';

const ReconcilePage = () => {
  const {
    userInfoStore: { getRegionNameFull, isNational },
    reconcileStore: { setOffering, initData, setProfiles },
  } = useStores();
  const breadCrumbs: BreadCrumbType[] = [{ name: getRegionNameFull() }];
  const { period } = useUrlParams();
  const { data: offeringData } = useGetOfferingByIdQuery({ variables: { id: period } });
  const { data, loading, error } = useGetReconcileDetailsQuery({ variables: { regionalOfferingId: period, isNational: isNational() } });

  if (loading || error) {
    return <LoadingPage breadCrumbs={breadCrumbs} error={!!error} />;
  }

  if (data) {
    setOffering(offeringData?.offering as Offering);
    initData(data.getReconcileDetails as ReconcileResponse, isNational());
    setProfiles(xformSeriesToProfiles(data.getReconcileDetails as ReconcileResponse));
  }

  return <ReconcileComponent />;
};

export default ReconcilePage;
