import { observer } from 'mobx-react-lite';
import { DefaultModal, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { useResetAllCostSharesMutation } from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';

import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
}

const ResetAllCostSharesModal = ({ onClose }: Props) => {
  const {
    summaryStore: { offering },
  } = useStores();
  const [resetAllCostShares] = useResetAllCostSharesMutation();
  const { error } = useToast();

  const handleSubmit = async () => {
    try {
      const res = await trackPromise(resetAllCostShares({ variables: { input: { offeringId: offering.id, rev: offering.rev } } }));

      if (res.data?.resetAllCostShares.success) {
        window.location.reload();
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      onClose();
    }
  };

  return (
    <DefaultModal
      message="Are you sure you want to reset the TFS Cost Share for all offers?"
      modalBodyClassName={styles.modal}
      onClose={onClose}
      onSubmit={handleSubmit}
      open
      submitText="Reset"
      title="Update Cost Shares"
    />
  );
};

export default observer(ResetAllCostSharesModal);
