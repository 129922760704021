import { BlockInputLabel, Button, ButtonGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';
import { ReportTypes } from '../../../../constants/global';
import useStores from '../../../../stores/useStores';
import styles from './styles.module.scss';

interface Props {
  handleGenerate: () => void;
  onCancel: () => void;
}

const PrintModal = ({ handleGenerate, onCancel }: Props) => {
  const {
    userInfoStore: { isNational },
  } = useStores();
  return (
    <Modal isOpen onClose={onCancel}>
      <ModalHeader className={styles.modalHeader} onClose={onCancel}>
        Export Report
      </ModalHeader>
      <ModalBody className={styles.modalBody}>
        <BlockInputLabel label="Report Name">
          <Input className={styles.reportInput} defaultValue={isNational() ? ReportTypes.FY_RECONCILE : ReportTypes.RECONCILE_SUMMARY} disabled />
        </BlockInputLabel>
        <p className={styles.rowContainer}>It may take up to 5 minutes to generate the report.</p>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button variant="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleGenerate}>
            Ok
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default PrintModal;
