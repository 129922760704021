import clsx from 'clsx';
import { ReactNode } from 'react';
import useGetTfsLabel from '../../../../../../../utils/useGetTfsLabel';
import PenRateModel from '../../../../../models/PenRateModel';
import ExpHeader from '../ExpHeader';
import styles from '../ExpHeader/styles.module.scss';

interface Props {
  leaseModel: PenRateModel;
  showDates: (model: PenRateModel) => ReactNode;
}

const LeaseHeader = ({ leaseModel, showDates }: Props) => {
  const tfsLabel = useGetTfsLabel();
  const { name, term, subCash, optionTypeName } = leaseModel;

  return (
    <ExpHeader
      name={
        <>
          {name} {term} mo
        </>
      }
      isSubCashEnhanced={subCash > 0}
      showDates={showDates(leaseModel)}
      optionTypeName={`${tfsLabel} ${optionTypeName} Subvention Cash `}
      headerStyles={clsx(styles.leaseName, styles.leaseHeader)}
      dividerStyle={styles.leaseDivider}
    />
  );
};

export default LeaseHeader;
