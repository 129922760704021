import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { OATIcon, Tooltip } from 'oat-common-ui';
import DollarDisplay from '../../../../../../components/DollarDisplay';
import useStores from '../../../../../../stores/useStores';
import { assignNumberValue } from '../../../../../../utils/assignValue';
import ReconcileSeriesModel from '../../../../models/ReconcileSeriesModel';
import ProgramEarningsTooltipMessage from '../../../Tooltips/ProgramEarningsTooltipMessage';
import styles from './styles.module.scss';

interface Props {
  series: ReconcileSeriesModel;
}

const SpHeaderSummary = ({ series }: Props) => {
  const {
    userInfoStore: { isNational },
  } = useStores();

  return (
    <table className={styles.estCalc} cellPadding="0" cellSpacing="0">
      <caption />
      <thead>
        <tr>
          <th />
          <td className={clsx(styles.estLabel, styles.summaryCol1)}>Est. Program Earnings</td>
          <td className={clsx(styles.spacer, styles.summaryCol2)}></td>
          <td className={clsx(styles.estLabel, styles.summaryCol2, styles.borderTop)}>Est. Program Cost</td>
          <td className={clsx(styles.spacer, styles.summaryCol2)}></td>
          <td className={clsx(styles.estLabel, styles.summaryCol3)}>Est. Program Balance</td>
        </tr>
      </thead>
      <tbody>
        <tr className={styles.stackFormat}>
          <td className={styles.estAct}>Regional Estimates</td>
          <td className={clsx(styles.stackFormatValue, styles.summaryCol1)}>
            <Tooltip
              id={`${series.uid}-sp-prog-earning`}
              message={
                <ProgramEarningsTooltipMessage
                  series={series}
                  unitsLabel="Total Projected Units"
                  unitsValue={series.forecastedSalesVolume}
                  earnings={series.raEarnings}
                  remaining={series.remainingProjectedEarnings}
                />
              }
              place="bottom"
              clickable
            >
              <OATIcon icon="info" colorTheme="blue" className={styles.info} />
              <DollarDisplay useColors id={`sp-proj-earnings-${series.uid}`} value={series.raEarnings} className={styles.dollarAmount} />
            </Tooltip>
          </td>
          <td className={styles.summaryCol2}>
            <span className={clsx(styles.divider, styles.htmlDash)}></span>
          </td>
          <td className={clsx(styles.stackFormatValue, styles.summaryCol2)}>
            <DollarDisplay useColors id={`sp-proj-costs-${series.uid}`} value={series.raCost} className={styles.dollarAmount} />
          </td>
          <td className={styles.summaryCol2}>
            <span className={styles.divider}>=</span>
          </td>
          <td className={clsx(styles.stackFormatValue, styles.summaryCol3)}>
            <DollarDisplay useColors id={`sp-proj-bal-${series.uid}`} value={series.raBalance} className={styles.dollarAmount} />
          </td>
        </tr>
        <tr className={styles.stackFormat}>
          <td className={styles.estAct}>Regional Actuals</td>
          <td className={clsx(styles.stackFormatValue, styles.summaryCol1)}>
            <Tooltip
              id={`${series.uid}-sp-act-earning`}
              message={
                <ProgramEarningsTooltipMessage
                  series={series}
                  unitsLabel="Total Actual Units"
                  unitsValue={assignNumberValue(isNational() ? series.fields.nationalActualSalesVolume : series.fields.regionalActualSalesVolume)}
                  earnings={isNational() ? series.totalNationalActualEarnings : series.totalRegionalActualEarnings}
                  remaining={isNational() ? series.remainingNationalActualEarnings : series.remainingRegionalActualEarnings}
                />
              }
              place="bottom"
              clickable
            >
              <OATIcon icon="info" colorTheme="blue" className={styles.info} />
              <DollarDisplay
                useColors
                id={`sp-proj-earnings-${series.uid}`}
                value={isNational() ? series.totalNationalActualEarnings : series.totalRegionalActualEarnings}
                className={styles.dollarAmount}
              />
            </Tooltip>
          </td>
          <td className={styles.summaryCol2}>
            <span className={clsx(styles.divider, styles.htmlDash)}></span>
          </td>
          <td className={clsx(styles.stackFormatValue, styles.summaryCol2)}>
            <DollarDisplay
              useColors
              id={`sp-act-costs-${series.uid}`}
              value={isNational() ? series.totalNationalActualOfferCosts : series.totalRegionalActualOfferCosts}
              className={styles.dollarAmount}
            />
          </td>
          <td className={styles.summaryCol2}>
            <span className={styles.divider}>=</span>
          </td>
          <td className={clsx(styles.stackFormatValue, styles.summaryCol3)}>
            <DollarDisplay
              useColors
              id={`sp-act-bal-${series.uid}`}
              value={isNational() ? series.totalNationalActualBalance : series.totalRegionalActualBalance}
              className={styles.dollarAmount}
            />
          </td>
        </tr>
        <tr className={clsx(styles.stackFormat, styles.variance)}>
          <td className={styles.estAct}>Variance</td>
          <td className={clsx(styles.stackFormatValue, styles.estActuals, styles.summaryCol1)}>
            <DollarDisplay
              useColors
              id={`sp-ear-var-${series.uid}`}
              value={isNational() ? series.nationalEarningsVariance : series.regionalEarningsVariance}
              className={styles.dollarAmount}
            />
          </td>
          <td className={clsx(styles.estActuals, styles.summaryCol2)}>
            <span className={clsx(styles.divider, styles.htmlDash)}></span>
          </td>
          <td className={clsx(styles.stackFormatValue, styles.estActuals, styles.summaryCol2)}>
            <DollarDisplay
              useColors
              id={`sp-prog-var-${series.uid}`}
              value={isNational() ? series.nationalProgramCostVarianceTotal : series.regionalProgramCostVarianceTotal}
              className={styles.dollarAmount}
            />
          </td>
          <td className={clsx(styles.estActuals, styles.summaryCol2)}>
            <span className={styles.divider}>=</span>
          </td>
          <td className={clsx(styles.stackFormatValue, styles.estActuals, styles.summaryCol3)}>
            <DollarDisplay
              useColors
              id={`sp-bal-var-${series.uid}`}
              value={isNational() ? series.nationalBalanceVariance : series.regionalBalanceVariance}
              className={styles.dollarAmount}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default observer(SpHeaderSummary);
