import { CreateLeaseModels, EFCInventoryResponse, assignNumberValue } from 'oat-common-ui';

const getConfigListFromEFC = (data: EFCInventoryResponse) => {
  const configList: CreateLeaseModels.ConfigItem[] = [];
  data.body.response.docs.forEach(doc => {
    const config: string[] = [];
    const configFull: string[] = [];

    doc.accessories.forEach(acc => {
      config.push(acc.code);
      configFull.push(`${acc.code}#${acc.title}#${acc.msrp.replace('$', '')}`);
    });

    config.sort();

    configList.push({
      config: config.join(', '),
      configFull: configFull.join('|'),
      optionsTotal: Math.round(Number(doc.optionlistmsrp)),
      count: Number(doc.vincount || '0'),
      inventory: assignNumberValue(doc.inventorypercent),
      modelCode: doc.model.code,
      vinItems: [],
    });
  });

  return configList;
};

export default getConfigListFromEFC;
