import { observer } from 'mobx-react-lite';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader, NumberInput, OATIcon, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { AdvanceRates, OfferAdvanceRatesInput, useSaveAdvanceRatesMutation } from '../../../../../../gql/generated';
import { getLeaseLowerTerms } from '../../../../../../utils/getLeaseLowerTerms';
import LeaseCardModel from '../../../../models/LeaseCardModel';
import LeaseFormModel from '../../../../models/LeaseFormModel';
import styles from './styles.module.scss';
import { buildAdvanceRatesView, transformAdvanceRates } from './utils';

interface Props {
  leaseCard: LeaseCardModel;
  leaseForm: LeaseFormModel;
  disabled: boolean;
  onClose: () => void;
}

const ViewRcfModal = ({ leaseCard, leaseForm, disabled, onClose }: Props) => {
  const { inputs } = leaseForm;
  const [saveAdvanceRates] = useSaveAdvanceRatesMutation();
  const { error } = useToast();
  const disableRanges = ['131-999'];
  const [rates, setRates] = useState(buildAdvanceRatesView(inputs.advanceRates, inputs.rates));

  const handleSave = async () => {
    try {
      const allRates = transformAdvanceRates(rates);
      let offerAdvanceRates: OfferAdvanceRatesInput[] = [];

      if (inputs.isUpToApplied) {
        offerAdvanceRates = getLeaseLowerTerms(leaseCard.leaseTerms, inputs.term).map(term => {
          return {
            tier: term.tier,
            term: term.term,
            advanceRates: allRates,
          };
        });
      } else {
        offerAdvanceRates.push({
          tier: inputs.tier,
          term: inputs.term,
          advanceRates: allRates,
        });
      }

      const res = await trackPromise(
        saveAdvanceRates({
          variables: {
            input: {
              id: leaseCard.id,
              rev: leaseCard.rev,
              offerAdvanceRates,
            },
          },
        }),
      );

      const leaseDetails = res.data?.saveAdvanceRates.offer.leaseDetails?.find(detail => detail.highTerm === inputs.term);
      if (leaseDetails) {
        leaseCard.updateRev(res.data?.saveAdvanceRates.offer.rev);
        leaseCard.updateTierRates(res.data?.saveAdvanceRates.offer);
      }
    } catch (e) {
      error((e as Error).message);
    }
    onClose();
  };

  const isDisabled = (rate: AdvanceRates, disableField = false) => {
    return (
      disableRanges.includes(`${rate.minAdvance}-${rate.maxAdvance}`) ||
      (rate.tier === '4' && rate.minAdvance === '121' && rate.maxAdvance === '130.99') ||
      disableField ||
      rate.subventedRcf === 999
    );
  };

  const copyColumn = (tier: string) => {
    const allSelectedTiers = Object.values(rates).map(values => {
      return values.rates.filter(rate => rate.tier === tier)[0];
    });
    allSelectedTiers.forEach(tier => {
      if (!isDisabled(tier)) {
        tier.subventedRcf = allSelectedTiers[0].subventedRcf;
      }
    });
    setRates({ ...rates });
  };

  const copyRow = (tier: string) => {
    const selectedTiers = Object.values(rates)
      .map(values => values.rates.find(rate => rate.tier === tier))
      .slice(0, 4);

    Object.values(rates).forEach(value => {
      value.rates.forEach(rate => {
        const selectedTier = selectedTiers.find(
          selected => selected && rate.minAdvance === selected.minAdvance && rate.maxAdvance === selected.maxAdvance && !isDisabled(selected),
        );

        if ((selectedTier && Number(rate.tier) > Number(tier)) || selectedTier?.tier === '1+') {
          rate.subventedRcf = selectedTier.subventedRcf;
        }
      });
    });
    setRates({ ...rates });
  };

  const getHeaders = () => {
    return inputs.rates.map(rate => {
      return (
        <td key={rate.tier}>
          <span>{`Tier ${rate.tier} RCF`}</span>
          <div className={styles.anglesContainer}>
            <OATIcon icon="circle-down" colorTheme="blue" pointer onClick={() => copyColumn(rate.tier)} />
            <OATIcon icon="circle-right" colorTheme="blue" pointer onClick={() => copyRow(rate.tier)} />
          </div>
        </td>
      );
    });
  };

  const updateRcf = (rateObj: AdvanceRates, value: number) => {
    rates[`${rateObj.minAdvance}-${rateObj.maxAdvance}`].rates.forEach(rate => {
      if (rate.tier === rateObj.tier) {
        rate.subventedRcf = value;
        setRates({ ...rates });
      }
    });
  };

  return (
    <Modal isOpen onClose={onClose} size="lg">
      <ModalHeader title="View/Edit RCF" onClose={onClose} />
      <ModalBody>
        <table>
          <thead>
            <tr className={styles.rcfHeaderRow}>
              <td />
              {getHeaders()}
            </tr>
          </thead>
          <tbody>
            {Object.entries(rates).map(([key, value]) => (
              <tr key={key} className={styles.rcfBodyRow}>
                <td>{key}</td>
                {value.rates.map(item => (
                  <td key={`clm-${key}-${item.tier}`}>
                    <NumberInput
                      id={`rcf-${item?.tier}-${key}`}
                      className={styles.inputBase}
                      rcf
                      value={isDisabled(item) ? undefined : item.subventedRcf}
                      onValueChange={val => updateRcf(item, Number(val.value))}
                      disabled={isDisabled(item, item.tier === '1+' && item.minAdvance === '0')}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button id="modal-cancel" variant="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="modal-save" variant="primary" disabled={disabled} onClick={handleSave}>
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default observer(ViewRcfModal);
