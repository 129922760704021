import { observer } from 'mobx-react-lite';
import { Button, OATIcon } from 'oat-common-ui';
import NumberInput from '../../../../components/NumberInput';
import useGetTfsLabel from '../../../../utils/useGetTfsLabel';
import FundingSplitTerm from './FundingSplitTerm';
import styles from './styles.module.scss';

interface Props {
  fundingTerm: FundingSplitTerm;
  lease?: boolean;
  isDisabled?: boolean;
}

const FundingSplitsTable = ({ fundingTerm, lease = false, isDisabled }: Props) => {
  const tfsLabel = useGetTfsLabel();
  return (
    <table className={styles.fsTable}>
      <thead>
        <tr>
          <td>From</td>
          <td />
          <td>To</td>
          <td>{tfsLabel} Cost Share</td>
        </tr>
      </thead>
      <tbody>
        {fundingTerm.splits.map(item => (
          <tr key={item.uid}>
            <td>{item.from}</td>
            <td>-</td>
            <td>
              {item.disableRateInput ? (
                item.to
              ) : (
                <NumberInput
                  value={item.to}
                  rcf={lease}
                  textAlign="center"
                  allowNegative={false}
                  onValueChange={vals => fundingTerm.editRate(item, vals.value)}
                  error={item.rateError}
                  disabled={isDisabled}
                />
              )}
            </td>
            <td>
              {item.disableTfsShare ? (
                `${item.tfsShare}%`
              ) : (
                <NumberInput
                  value={item.tfsShare}
                  allowNegative={false}
                  percentageSign
                  onValueChange={vals => fundingTerm.editTfsShare(item, vals.value)}
                  error={item.tfsShareError}
                  disabled={isDisabled}
                />
              )}
            </td>
          </tr>
        ))}
        <tr>
          <td>
            <Button id="add-funding-split-btn" variant="text" onClick={() => fundingTerm.addSplit()} icon={<OATIcon icon="add" />} disabled={isDisabled}>
              Add Split
            </Button>
          </td>
          <td></td>
          <td></td>
          <td>
            <Button id="remove-funding-splits-btn" variant="text" onClick={() => fundingTerm.removeSplits()} disabled={isDisabled}>
              Remove All Splits
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default observer(FundingSplitsTable);
