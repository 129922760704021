import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { Button, ButtonGroup } from 'oat-common-ui';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useUrlParams from '../../../../../../hooks/useUrlParams';
import useStores from '../../../../../../stores/useStores';
import LeaseCardModel from '../../../../models/LeaseCardModel';
import LeaseFormModel from '../../../../models/LeaseFormModel';
import OfferHeader from '../../../OfferHeader';
import RemoveOfferModal from '../../../RemoveOfferModal';
import styles from './styles.module.scss';

interface Props {
  leaseCard: LeaseCardModel;
  leaseForm: LeaseFormModel;
}

const LeaseVinErrorCard = ({ leaseCard, leaseForm }: Props) => {
  const {
    programDetailsStore: { seriesProfile, removeLeaseOffer },
  } = useStores();
  const { region, period, regalt } = useUrlParams();
  const history = useHistory();

  const [showRemoveOfferModal, setShowRemoveOfferModal] = useState(false);

  const leaseId = `lease-${leaseCard.id}`;
  const { OfferTypes } = AdminConstants;

  const gotoCreateOffer = () => {
    const changeVinLinkParams = leaseForm.isExample ? `?example=${encodeURIComponent(leaseForm.inputs.name)}` : '';
    history.push(
      `/changeVin/region/${region}/period/${period}/profile/${seriesProfile.id}/regalt/${regalt}/offerId/${leaseCard.id}/offerTerm/${leaseForm.inputs.term}${changeVinLinkParams}`,
    );
  };

  return (
    <section id={leaseId}>
      <OfferHeader offerType={OfferTypes.LEASE} title={`${leaseCard.name}`} titleSufix={`${leaseCard.term}mo`} offerSource={''} />
      <div className={styles.errorVinCardContent}>
        <p>Your previous vehicle is no longer available. Create a lease with the button below for both advertised and non-advertised offers.</p>
        <ButtonGroup>
          <Button id={`create-lease-${regalt}`} variant="primary" onClick={() => gotoCreateOffer()}>
            Create Lease
          </Button>
          {leaseForm.inputs.isStandalone && (
            <Button
              id={`remove-lease-${leaseCard.id}`}
              variant="primary"
              onClick={() => {
                setShowRemoveOfferModal(true);
              }}
            >
              Delete Lease
            </Button>
          )}
        </ButtonGroup>
      </div>
      {showRemoveOfferModal && (
        <RemoveOfferModal
          id={leaseCard.id}
          rev={leaseCard.rev}
          term={leaseForm.inputs.term}
          onClose={() => {
            setShowRemoveOfferModal(false);
          }}
          onSubmit={(_id, rev) => {
            removeLeaseOffer(leaseCard.id, rev ?? '', leaseForm.inputs.term);
          }}
        />
      )}
    </section>
  );
};

export default observer(LeaseVinErrorCard);
