import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface TooltipListItemProps {
  symbol?: ReactNode;
  className?: string;
  children?: ReactNode;
}

export const TooltipListItem = ({ symbol, className, children }: TooltipListItemProps) => {
  return (
    <li className={clsx(styles.tooltipListItem, className)}>
      {symbol && <span className={styles.symbol}>{symbol}</span>}
      {children}
    </li>
  );
};

interface TooltipListProps {
  divider?: boolean;
  className?: string;
  children?: ReactNode;
}

const TooltipList = ({ divider, className, children }: TooltipListProps) => {
  return <ul className={clsx(styles.tooltipList, className, divider && styles.divider)}>{children}</ul>;
};

export default TooltipList;
