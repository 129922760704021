import { observer } from 'mobx-react-lite';
import { formatDollars } from 'oat-common-ui';
import TooltipList, { TooltipListItem } from '../../../../../../components/TooltipList/TooltipList';
import LeaseFormModel from '../../../../models/LeaseFormModel';

interface Props {
  leaseDetails: LeaseFormModel;
}

const NetCapCostTooltipMessage = ({ leaseDetails }: Props) => {
  const { isAcquisitionFeeCapitalized, isSubCashAppliedToDueAtSigning } = leaseDetails.inputs;

  return (
    <>
      <TooltipList>
        <TooltipListItem>
          <span>Gross Cap Cost</span>
          <span>${formatDollars(leaseDetails.inputs.grossCapCost)}</span>
        </TooltipListItem>
        <TooltipListItem symbol="-">
          <span>Down Payment</span>
          <span>${formatDollars(leaseDetails.inputs.downPayment)}</span>
        </TooltipListItem>
        {isAcquisitionFeeCapitalized && (
          <TooltipListItem symbol="+">
            <span>Acq. Fee</span>
            <span>${formatDollars(leaseDetails.inputs.acquisitionFee)}</span>
          </TooltipListItem>
        )}
        {!isSubCashAppliedToDueAtSigning && (
          <TooltipListItem symbol="-">
            <span>Subvention Cash</span>
            <span>${formatDollars(leaseDetails.inputs.subventionCash)}</span>
          </TooltipListItem>
        )}
        {Boolean(leaseDetails.inputs.additionalCashOfferId && !leaseDetails.inputs.isAddtlCashAppliedToDueAtSigning) && (
          <TooltipListItem symbol="-">
            <span>Additional Cash</span>
            <span>${formatDollars(leaseDetails.inputs.additionalCashAmount)}</span>
          </TooltipListItem>
        )}
      </TooltipList>

      <TooltipList divider>
        <TooltipListItem>
          <span>Net Cap Cost</span>
          <span>${formatDollars(leaseDetails.inputs.netCapCost)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
};

export default observer(NetCapCostTooltipMessage);
