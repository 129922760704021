import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, ModalBody, ModalHeader } from 'oat-common-ui';
import { ModalTitle } from 'react-bootstrap';
import { RgnlAlt, useGetSeriesProfileHierarchyQuery } from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import PenRateModalContent from './PenRateModalContent';

interface Props {
  currentOfferId: string;
  scrollTo?: string;
  onClose: () => void;
}

const PenRatesModal = ({ currentOfferId, scrollTo, onClose }: Props) => {
  const { profile, regalt } = useUrlParams();
  const { data, loading, error } = useGetSeriesProfileHierarchyQuery({
    variables: {
      seriesProfileId: profile,
    },
  });

  const foundRa = data?.seriesProfileHierarchyById?.rgnlAlts?.find(ra => ra.id === regalt);

  if (error) {
    onClose();
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Modal isOpen onClose={onClose}>
      <ModalHeader onClose={onClose}>
        <ModalTitle>Adjust Pen. Rate/Forecasted Sales Volume</ModalTitle>
      </ModalHeader>
      {loading && <ModalBody>{loading && 'Loading...'}</ModalBody>}
      {data && <PenRateModalContent rgnlAlt={foundRa as RgnlAlt} currentOfferId={currentOfferId} scrollTo={scrollTo} onClose={onClose} />}
    </Modal>
  );
};

export default observer(PenRatesModal);
