import clsx from 'clsx';
import { OATIcon } from 'oat-common-ui';
import { ReactNode } from 'react';
import Popover, { PlacementType } from '../Popover';
import styles from './styles.module.scss';

type Props = {
  iconSize?: number;
  id: string;
  message?: ReactNode;
  iconClassName?: string;
  iconRightPadding?: boolean;
  buttonContentText?: string;
  addBodyStyles?: string;
  placement?: PlacementType;
};

const InfoTooltip = ({ id, message, iconSize = 16, iconClassName, iconRightPadding = false, buttonContentText, addBodyStyles, placement }: Props) => {
  return (
    <Popover
      id={id}
      content={message}
      contentClass={clsx(styles.body, addBodyStyles)}
      placement={placement}
      buttonContent={
        <div className={styles.buttonContent}>
          <OATIcon className={clsx(iconClassName, iconRightPadding && styles.iconRightPadding)} pointer icon="info" size={iconSize} colorTheme="blue" />
          <span>{buttonContentText}</span>
        </div>
      }
    />
  );
};

export default InfoTooltip;
