import { observer } from 'mobx-react-lite';
import { Tooltip } from 'oat-common-ui';
import { EnhTfsCostShare } from '../../../../gql/generated';
import useGetTfsLabel from '../../../../utils/useGetTfsLabel';
import BlockText from '../BlockText';
import EnhCostShareDatesTooltip from './EnhCostShareDatesTooltip';
import styles from './styles.module.scss';

interface Props {
  id: string;
  costShareDates: EnhTfsCostShare[];
  isNonAdvertised?: boolean;
}

const EnhCostShareAvailabilities = ({ id, costShareDates, isNonAdvertised }: Props) => {
  const tfsLabel = useGetTfsLabel();

  return (
    <BlockText
      className={isNonAdvertised ? styles.nonAdStyle : ''}
      topText={`Enhanced ${tfsLabel} Cost Share`}
      bottomText={
        <Tooltip grayTheme id={`${id}-enh-cost-shares-tooltip`} message={<EnhCostShareDatesTooltip costShareDates={costShareDates} />} place="bottom">
          <span className={styles.underlined}>{`${costShareDates.length} Available`}</span>
        </Tooltip>
      }
    />
  );
};

export default observer(EnhCostShareAvailabilities);
