import { brands } from 'oat-common-ui';
import { Brand } from '../constants/global';

export type PortStates = {
  [code: string]: {
    [port: string]: string[];
  };
};

class RegionsStore {
  isLoaded = false;
  statesOptions: string[] = [];
  westernLexusRegions = brands[Brand.LEXUS].ports.laPortLWAstates.concat(brands[Brand.LEXUS].ports.ptPortLWAstates).slice().sort();
  southernLexusRegions = brands[Brand.LEXUS].ports.laPortLSAstates.concat(brands[Brand.LEXUS].ports.jxPortLSAstates).slice().sort();
  easternLexusRegions = brands[Brand.LEXUS].ports.easternPortStates.slice().sort();
  centralLexusRegions = brands[Brand.LEXUS].ports.centralPortStates.slice().sort();
  portsWithStates: PortStates = {
    LSA: {
      CM: [],
      GT: [],
      JX: ['AL', 'FL', 'GA', 'NC', 'SC', 'TN'],
      LA: ['AR', 'LA', 'MS', 'OK', 'TX'],
      NY: [],
      PT: [],
      SJ: [],
    },
    LWA: {
      CM: [],
      GT: [],
      JX: [],
      LA: ['AZ', 'CA', 'NM', 'NV'],
      NY: [],
      PT: ['AK', 'CO', 'ID', 'OR', 'UT', 'WA', 'MT', 'WY'],
      SJ: [],
    },
  };
}

export default RegionsStore;
