import { ReconcileSeriesResponse } from '../../../gql/generated';

export const mapMiscAdjustment = () => {
  const series = {} as ReconcileSeriesResponse;
  series.forecastedSalesVolume = 0;
  series.nationalActualDealerStock = 0;
  series.nationalActualSalesVolume = 0;
  series.nvsUnits = 0;
  series.raBalance = 0;
  series.raCost = 0;
  series.raEarnings = 0;
  series.reconcileOfferResponses = [];
  series.regionalActualDealerStock = 0;
  series.regionalActualSalesVolume = 0;
  series.rgnlAltId = '';
  series.ryoEarningsAmount = 0;
  series.seriesProfileId = 'misc-adjustment';
  series.seriesProfileName = 'Misc Adjustments';

  return series;
};
