import { observer } from 'mobx-react-lite';
import { OfferTypes } from 'oat-common-ui';
import { ReactNode } from 'react';
import { Vehicle } from '../../../../../../../gql/generated';
import PenRateModel from '../../../../../models/PenRateModel';
import CostContainer from '../CostContainer';
import styles from '../styles.module.scss';
import VehiclesList from '../VehiclesList';
import ExpLeaseTableValues from './ExpLeaseTableValues';
import LeaseHeader from './LeaseHeader';
import LeaseTableHeaderValues from './LeaseTableHeaderValues';

interface Props {
  leaseModels: PenRateModel[] | undefined;
  inputIsDisabled: boolean;
  showDates: (model: PenRateModel) => ReactNode;
  excludedVehicles: Vehicle[];
  includedVehicles: Vehicle[];
  onSave?: () => void;
}

const ExpLeaseRow = ({ leaseModels, inputIsDisabled, showDates, excludedVehicles, includedVehicles, onSave }: Props) => {
  return (
    <>
      {leaseModels?.map((leaseItem, i) => {
        return (
          <section key={`${OfferTypes.LEASE}-${leaseItem.id}-${i}`} className={styles.containerList}>
            <LeaseHeader leaseModel={leaseItem} showDates={showDates} />

            <div className={styles.containerList}>
              <table className={styles.table}>
                <thead>
                  <LeaseTableHeaderValues />
                  <ExpLeaseTableValues leaseModel={leaseItem} inputIsDisabled={inputIsDisabled} onSave={onSave} />
                </thead>
              </table>
              <VehiclesList model={leaseItem} excludedVehicles={excludedVehicles} includedVehicles={includedVehicles} />
              <div className={styles.marginTop}>
                <CostContainer model={leaseItem} showEstimatedCost={false} />
              </div>
            </div>
          </section>
        );
      })}
    </>
  );
};

export default observer(ExpLeaseRow);
