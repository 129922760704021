export const getFiscalYear = () => {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  // January starts with #0, #2 is March
  // From January till March return current year
  // April till January return current year + 1
  if (currentMonth <= 2) {
    return currentYear;
  } else {
    return currentYear + 1;
  }
};
