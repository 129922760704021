import { AdminConstants, AdminModels } from 'oat-admin-common';
import { dateFormatISOString } from 'oat-common-ui';
import { CreateLeaseOfferInput, SaveVehicle, VehicleInput } from '../../../../../gql/generated';
import getDefaultAcFee from '../../../../../utils/getDefaultAcFee';
import CreateNonAdLeaseModel from '../../models/CreateNonAdLeaseModel';

const transformCreateGSTLeasePayload = (
  nonAdvertisedData: CreateNonAdLeaseModel,
  regalt: string,
  excludedList: VehicleInput[],
  includedList: VehicleInput[],
  compatibileOffers: string[],
  enhancedCostShare?: AdminModels.EnhancedCostShare.EnhancedLeaseCostShare,
  enhancedTfsCostShare?: AdminModels.EnhancedTfsCostShare.EnhancedTfsCostShare,
  isEnhCostShareAccepted?: boolean | null,
  isEnhTfsCostShareAccepted?: boolean | null,
): CreateLeaseOfferInput => {
  const vehiclesList: SaveVehicle[] = [...excludedList, ...includedList];
  const terms = nonAdvertisedData.fields.selectedTermsList.map(Number);
  return {
    compatibilityList: [...compatibileOffers],
    targetPayment: Number(nonAdvertisedData.fields.targetPayment),
    vehicles: vehiclesList,
    dueAtSigning: Number(nonAdvertisedData.fields.dueAtSigning),
    rgnlAltId: regalt,
    netCapCost: Number(nonAdvertisedData.fields.netCapCost),
    endDate: dateFormatISOString(nonAdvertisedData.fields.endDate),
    regionalSubventionRCF: enhancedTfsCostShare?.value ? Number(nonAdvertisedData.fields.rcf) - Number(enhancedTfsCostShare?.value) : Number(nonAdvertisedData.fields.rcf),
    isAdvertised: true,
    modelCode: Number(nonAdvertisedData.fields.modelCode),
    modelYear: Number(nonAdvertisedData.fields.modelYear),
    series: nonAdvertisedData.fields.series,
    vehicleDescription: nonAdvertisedData.fields.vehicleDescription,
    mileage: Number(nonAdvertisedData.fields.selectedMileage.value),
    totalMsrp: Number(nonAdvertisedData.fields.totalMsrp),
    note: nonAdvertisedData.fields.note,
    subventionCash: Number(nonAdvertisedData.fields.subventionCash),
    name: 'Lease',
    bonusCash: Number(nonAdvertisedData.fields.bonusCash),
    optionTierType: nonAdvertisedData.fields.isBlended ? 'Blended' : 'ByTier',
    residualAmount: Number(nonAdvertisedData.fields.residualValueAmount),
    pnv: Number(nonAdvertisedData.fields.pnv),
    grossCapCost: Number(nonAdvertisedData.fields.grossCapCost),
    startDate: dateFormatISOString(nonAdvertisedData.fields.startDate),
    tdaNote: nonAdvertisedData.fields.tdaNote,
    terms,
    advertisedTerms: terms,
    tiers: nonAdvertisedData.fields.selectedTiers,
    downPayment: Number(nonAdvertisedData.fields.downPayment),
    isSpecialEdition: nonAdvertisedData.fields.isSpecialEdition,
    acquisitionFee: getDefaultAcFee(AdminConstants.BRAND_TOYOTA, AdminConstants.RegionCodes.GST),
    isEligibleForEnhCostShare: !!enhancedCostShare,
    enhCostShareOfferId: enhancedCostShare?.offerId,
    isEnhCostShareAccepted: isEnhCostShareAccepted,
    isEligibleForEnhTfsCostShare: !!enhancedTfsCostShare,
    enhTfsCostShareId: enhancedTfsCostShare?.id,
    isEnhTfsCostShareAccepted: isEnhTfsCostShareAccepted,
  };
};

export default transformCreateGSTLeasePayload;
