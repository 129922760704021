import { Route, RouteProps } from 'react-router-dom';
import { useGetUserInfoQuery } from '../../gql/generated';
import Error from '../../pages/ErrorPage';
import useStores from '../../stores/useStores';
import MainHeader from '../MainHeader';

export const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const { userInfoStore } = useStores();
  const { data, loading, error } = useGetUserInfoQuery();

  if (loading) {
    return <MainHeader showMiscLinks={false} showProgressBar breadCrumbs={[{ name: 'Initializing' }]} />;
  }

  if (error) {
    return <Error message="Error occurred when logging in. Please logout completely and try again" />;
  }

  if (data && data.userInfo) {
    userInfoStore.setUserInfo(data.userInfo, true);
  }

  return <Route {...rest}>{children}</Route>;
};

export default PrivateRoute;
