import { formatAccounting } from 'oat-common-ui';
import { FC } from 'react';
import { Offering } from '../../../gql/generated';
import DollarDisplay from '../../DollarDisplay';
import InfoTooltip from '../../InfoTooltip';
import FYBalanceTooltip from './FYBalanceTooltip';
import styles from './styles.module.scss';

interface FYBalanceProps {
  fyBalance: number;
  balances: Offering[];
}

const FYBalance: FC<FYBalanceProps> = ({ fyBalance, balances }) => {
  return (
    <div className={styles.rightSide}>
      <div className={styles.fyBalance}>
        <DollarDisplay value={fyBalance.toString()} useColors={true} />
      </div>
      <div className={styles.balanceInfo}>
        <InfoTooltip
          message={
            <>
              <FYBalanceTooltip balances={balances} />
              <div className={styles.summary}>
                <div>
                  <span className={styles.program}>Fiscal Year Balance</span>
                  <span className={styles.balance}>{formatAccounting(fyBalance)}</span>
                </div>
              </div>
            </>
          }
          id="fy-balance"
          iconSize={12}
          iconClassName={styles.tooltipIcon}
        />
        <span>FY Balance</span>
      </div>
    </div>
  );
};

export default FYBalance;
