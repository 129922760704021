import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { formatDollars, InputContainer, OATIcon, useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import Note from '../../../../components/Note';
import NumberInput from '../../../../components/NumberInput';
import { ENR_ERROR_MESSAGE, Status } from '../../../../constants/global';
import { OfferingCosts, useSaveCashOfferMutation, useSaveCompatibilityMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import { assignStringValue } from '../../../../utils/assignValue';
import { getOfferHeaderSource } from '../../../../utils/getOfferHeaderSource';
import { handleEnhancedDates } from '../../../../utils/handleEnhancedDates';
import CashCardModel from '../../models/CashCardModel';
import OfferBody, { OfferBodyLeft, OfferBodyRight } from '../OfferBody';
import { OfferCostTooltip, OfferEarningsTooltip } from '../offerCostTooltips';
import OfferHeader from '../OfferHeader';
import OfferHeaderCosts from '../OfferHeader/OfferHeaderCosts';
import ProgramDetailsWrapper from '../ProgramDetailsWrapper';
import CashCardLeftContent from './CashCardLeftContent';
import CashContextMenu from './CashContextMenu';
import styles from './styles.module.scss';
import { getCashPayload } from './utils';

const { OfferTypes } = AdminConstants;

type Props = {
  cashCard: CashCardModel;
  forCreate?: boolean;
  compatibileAccessories?: string[];
};

const CashCard = ({ cashCard, forCreate = false, compatibileAccessories }: Props) => {
  const { id, rev, isStandAlone, isAdvertised, isDuplicate, fields, hasError, nationalOffer, updateField, updateEstCosts, updateOfferCosts } = cashCard;
  const {
    userInfoStore: { isLexus },
    programDetailsStore: { seriesProfile, offering, ryoEarnings, updateAdditionalCashForLease },
    seriesMappingStore: { seriesMapping },
    offeringCostsStore,
  } = useStores();

  const { setDelay } = useInputDelay();
  const { error } = useToast();
  const [saveCashOffer] = useSaveCashOfferMutation();
  const [saveCompatibility] = useSaveCompatibilityMutation();

  const cashId = 'cash-' + id;
  const isDisabled = offering.status === Status.MEETS_GUIDELINES || cashCard.isDisabled;

  const handleInputChange = () => {
    if (cashCard.id) {
      setDelay(async () => {
        try {
          const payload = getCashPayload(cashCard);
          const res = await trackPromise(saveCashOffer({ variables: { input: payload } }));
          if (res.data) {
            cashCard.setCardData(res.data.saveCashOffer.offer, seriesMapping, seriesProfile, offering);
          }

          if (res.data?.saveCashOffer.offers) {
            updateAdditionalCashForLease(res.data.saveCashOffer.offers);
          }

          offeringCostsStore.setData(res.data?.saveCashOffer.offeringCosts as OfferingCosts);
        } catch (e) {
          error((e as Error).message);
        }
      });
    }
  };

  const handleSaveCompatibility = async () => {
    if (hasError || (!id && !rev)) {
      return;
    }

    try {
      const res = await trackPromise(saveCompatibility({ variables: { input: { id, rev, compatibilityList: cashCard.fields.compatibileOffers } } }));
      cashCard.updateRev(res.data?.saveCompatibility.offer.rev);
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <ProgramDetailsWrapper fixed>
      <section id={cashId}>
        <OfferHeader
          isTitleEditable={isStandAlone && !isDisabled}
          offerSource={getOfferHeaderSource(isStandAlone, forCreate, isAdvertised, fields.isEnhanced)}
          offerType={OfferTypes.CUSTOMER_CASH}
          nationalNote={nationalOffer?.cashDetails?.note}
          setTitle={name => {
            updateField('name', name);
            handleInputChange();
          }}
          title={fields.name}
          titleOnly={forCreate}
          enhancedDates={handleEnhancedDates(fields.startDate, fields.endDate, fields.defaultStartDate, fields.defaultEndDate, false, forCreate)}
        >
          {!forCreate && (
            <OfferHeaderCosts
              id={cashCard.id}
              earnings={fields.offerEarnings}
              earningsTooltip={<OfferEarningsTooltip forecastedSales={fields.forecastedSales} ryoEarnings={ryoEarnings} earnings={fields.offerEarnings} />}
              cost={fields.offerCost}
              costTooltip={<OfferCostTooltip forecastedSales={fields.forecastedSales} estCost={Number(fields.estimatedCost)} cost={fields.offerCost} />}
              balance={fields.offerBalance}
            />
          )}
          <CashContextMenu cashCard={cashCard} />
        </OfferHeader>
        <OfferBody>
          <OfferBodyLeft>
            <CashCardLeftContent
              cashCard={cashCard}
              handleInputChange={handleInputChange}
              forCreate={forCreate}
              compatibileAccessories={compatibileAccessories}
              handleSaveCompatibility={handleSaveCompatibility}
            />
          </OfferBodyLeft>
          <OfferBodyRight>
            {/* Cash Amount */}
            <InputContainer label="Cash Amount" className={styles.inputContainer}>
              <div className={cx(styles.input, hasError && styles.inputError)}>
                <span className={cx(styles.dollarSign)}>$</span>
                <NumberInput
                  id={`${cashId}-cash-amount-input`}
                  dollarSign
                  wholeNumber
                  error={hasError}
                  className={styles.numberInputDark}
                  value={fields.combinedPerUnitCost}
                  onValueChange={value => {
                    updateField('combinedPerUnitCost', value.value);
                    updateEstCosts();
                    updateOfferCosts(ryoEarnings);
                    handleInputChange();
                  }}
                  disabled={isDisabled}
                  units
                />
              </div>
              <p className={styles.defaultValueLabel}>
                {forCreate || isStandAlone ? `Default: $0` : `National: $${formatDollars(assignStringValue(fields.defaultPerUnitCost?.toString()))}`}
              </p>
            </InputContainer>

            <Note value={fields.note} onChange={note => updateField('note', note)} label="Note" disabled={isDisabled} />
            <Note value={fields.tdaNote} onChange={tdaNote => updateField('tdaNote', tdaNote)} label={isLexus() ? 'LDA Note' : 'TDA Note'} disabled={isDisabled} />

            {hasError && (
              <div className={styles.error}>
                <OATIcon className={styles.errorIcon} icon="warning" size={14} />
                <span className={styles.message}>
                  {isStandAlone ? 'Cash Amount must be a numeric value greater than or equal to 0' : 'Cash Amount must be greater than the National Offer'}
                </span>
              </div>
            )}
            {!fields.isValidEnR && isDuplicate && (
              <div className={styles.error}>
                <OATIcon className={styles.warningIcon} icon="warning" colorTheme="red" />
                <span className={styles.message}>{ENR_ERROR_MESSAGE}</span>
              </div>
            )}
          </OfferBodyRight>
        </OfferBody>
      </section>
    </ProgramDetailsWrapper>
  );
};

export default observer(CashCard);
