import { AdminConstants } from 'oat-admin-common';
import RgnlAltModel from '../pages/Summary/models/RgnlAltModel';

const { OPTION_TYPE_NAMES } = AdminConstants;

const nationalRYOHAsRYOOffers = ({ ryo }: RgnlAltModel) => {
  const ryoTypes: string[] = [OPTION_TYPE_NAMES.NATIONAL_RYO, OPTION_TYPE_NAMES.ADDITIONAL_RYO, OPTION_TYPE_NAMES.LUMPSUM_RYO];
  return ryo.some(offer => ryoTypes.includes(offer.optionTypeName));
};

export default nationalRYOHAsRYOOffers;
