import { observer } from 'mobx-react-lite';
import { InputContainer, SettingsLayout, useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import MainHeader from '../../components/MainHeader';
import NumberInput from '../../components/NumberInput';
import { SettingsBreadCrumbs, SettingsNav } from '../../components/SettingsComponents';
import { useSaveDealerGrossMutation } from '../../gql/generated';
import useStores from '../../stores/useStores';
import styles from './styles.module.scss';

const DealerGrossComponent = () => {
  const {
    userInfoStore: { isLexus, getRegionCode, isNational },
    settingsStore,
  } = useStores();

  const [saveDealerGross] = useSaveDealerGrossMutation();
  const { setDelay } = useInputDelay();
  const { error } = useToast();

  const handleChange = (value: string) => {
    settingsStore.setDealerGross(value);

    try {
      setDelay(async () => {
        if (!settingsStore.dealerGrossError) {
          await trackPromise(saveDealerGross({ variables: { regionCode: getRegionCode(), dealerGross: settingsStore.dealerGross } }));
        }
      });
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <>
      <MainHeader showMiscLinks={false} breadCrumbs={SettingsBreadCrumbs('Dashboard', '')} />
      <SettingsLayout settings={SettingsNav(isNational())}>
        <div className={styles.dealerGrossSettings}>
          <div className={styles.leftPanel}>{`Set default dealer gross for the ${isLexus() ? 'area' : 'region'}`}</div>
          <div className={styles.rightPanel}>
            <InputContainer label="Dealer Gross" className={styles.inputWrapper}>
              <NumberInput
                id="dealer-gross"
                dark
                wholeNumber
                maxLength={7}
                value={settingsStore.dealerGross}
                className={styles.input}
                onValueChange={vals => handleChange(vals.value)}
                error={settingsStore.dealerGrossError}
                units
              />
            </InputContainer>
          </div>
        </div>
      </SettingsLayout>
    </>
  );
};

export default observer(DealerGrossComponent);
