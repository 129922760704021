import { ModelCode, ModelCodes, SeriesMapping, Vehicle } from '../../../gql/generated';
import AccordionItemModel from '../models/AccordionItemModel';

const handleIsChecked = (model: ModelCode, yearItem: AccordionItemModel, includedVehicles: Vehicle[]) => {
  const foundVehicle = includedVehicles.find(includedVehicle => includedVehicle.modelCode === model.modelCode && includedVehicle.seriesYear === yearItem.value);

  return foundVehicle?.isInclusion ?? false;
};

const createAccordionFromModelCodes = (vehicles: ModelCodes[], seriesMappingData?: SeriesMapping[], includedVehicles?: Vehicle[]) => {
  const years: AccordionItemModel[] = [];

  const getVehicleNameFromSeriesMapping = (vehicle: ModelCodes) => {
    return seriesMappingData?.find(seriesData => seriesData.oatSeries === vehicle.series && seriesData.seriesYear.toString() === vehicle.year)?.displayName;
  };

  vehicles?.forEach(vehicle => {
    let yearItem = years.find(year => year.value === vehicle.year);
    if (!yearItem) {
      yearItem = new AccordionItemModel(vehicle.year, vehicle.year);
      years.push(yearItem);
    }

    let seriesItem = yearItem.items.find(series => series.value === vehicle.series);
    if (!seriesItem) {
      const seriesName = getVehicleNameFromSeriesMapping(vehicle);
      seriesItem = new AccordionItemModel(seriesName || vehicle.seriesNameFromFile, vehicle.series);
      seriesItem.level = 2;
      yearItem.items.push(seriesItem);
    }

    vehicle.models.forEach(model => {
      let modelItem = seriesItem?.items.find(t => t.value === model.modelCode);

      if (!modelItem) {
        modelItem = new AccordionItemModel(`(${model.modelCode}) ${model.vehicleDescription}`, model.modelCode);
        modelItem.level = 3;
        modelItem.checked = handleIsChecked(model, yearItem as AccordionItemModel, includedVehicles ?? []);
        modelItem.description = model.vehicleDescription;
        modelItem.disabled = false;
        seriesItem?.items.push(modelItem);
      }
    });

    yearItem.items.sort((itemA, itemB) => (itemA.label > itemB.label ? 1 : -1));
    yearItem.checked = yearItem.items.some(item => item.checked);

    seriesItem.items.sort((itemA, itemB) => (itemA.label > itemB.label ? 1 : -1));
    seriesItem.checked = seriesItem.items.some(item => item.checked);
  });

  return years.sort((itemA, itemB) => (itemA.label > itemB.label ? -1 : 1));
};

export default createAccordionFromModelCodes;
