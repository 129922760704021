import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { brands, Checkbox } from 'oat-common-ui';
import { ChangeEvent } from 'react';
import useChecklist from '../../../../../../../hooks/useChecklist';
import CheckListItem from '../../../../../../../models/CheckListItem';
import useStores from '../../../../../../../stores/useStores';
import LeaseFormModel from '../../../../../models/LeaseFormModel';
import styles from './styles.module.scss';

interface Props {
  leaseDetails: LeaseFormModel;
}

const PortsTooltipMessage = ({ leaseDetails }: Props) => {
  const {
    userInfoStore: { isLexus },
  } = useStores();
  const brandConstants = isLexus() ? brands.Lexus : brands.Toyota;
  const list = brandConstants.ports.portsAvailable.map(port => ({
    name: port,
    selected: leaseDetails.inputs.port.includes(port),
  }));
  const { checklist, selectItem } = useChecklist(list);

  const handleOnCheck = (e: ChangeEvent<HTMLInputElement>, port: CheckListItem) => {
    selectItem(port, e.target.checked);
    leaseDetails.inputs.port = checklist
      .filter(item => item.selected)
      .map(item => item.name)
      .join(', ');
  };

  return (
    <div className={styles.portsPopoverContent}>
      <div>
        <ul className={styles.portsContainer}>
          {checklist.map(port => (
            <li key={`adv-${port.name}-${leaseDetails.uid}`}>
              <div className={styles.checkboxWrapper}>
                <Checkbox id={`port-${port.name}-${leaseDetails.uid}`} isChecked={port.selected} onChange={e => handleOnCheck(e, port)} />
                <span className={styles.portLabel}>{AdminConstants.PortsMap[port.name]}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default observer(PortsTooltipMessage);
