import { OfferTypes } from 'oat-common-ui';
import { ReactNode } from 'react';
import PenRateModel from '../../../../../models/PenRateModel';
import NotExpTableValues from '../NotExpTableValues';
import LeaseTableValues from './LeaseTableValues';

interface Props {
  leaseModels: PenRateModel[] | undefined;
  showDates: (model: PenRateModel) => ReactNode | undefined;
}

const LeaseRow = ({ leaseModels, showDates }: Props) => {
  return (
    <>
      {leaseModels?.map((leaseItem, i) => {
        return (
          <tr key={`${OfferTypes.LEASE}-${leaseItem.id}-${i}`}>
            <NotExpTableValues model={leaseItem} />
            <LeaseTableValues leaseModel={leaseItem} showDates={showDates} />
          </tr>
        );
      })}
    </>
  );
};

export default LeaseRow;
