import { AdminConstants } from 'oat-admin-common';
import { SeriesProfile, useGetContestNumbersQuery } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import { SETContestNumbersByType } from '../../../models/SETContestNumber';
import useStores from '../../../stores/useStores';
import useUserInfo from '../../../utils/useUserInfo';
import LoadingPage from '../../LoadingPage';
import GetEnhCostShare from '../../ProgramDetails/GetEnhCostShare';
import EFCSeriesData from '../../ProgramDetails/components/EFCSeriesData';
import useCreateOffer from '../useCreateOffer';
import CreateAprComponent from './CreateAprComponent';
import GetStandardAprRates from './GetStandardAprRates';
import createAprCard from './createAprCard';

const { OPTION_TYPE_NAMES } = AdminConstants;

const CreateApr = () => {
  const { period } = useUrlParams();
  const { isSETUser, isLexusUser } = useUserInfo();
  const { data: setData, loading: setLoading, error: setError } = useGetContestNumbersQuery({ variables: { offeringId: period }, skip: !isSETUser() });
  const { loading, error, data } = useCreateOffer();
  const {
    userInfoStore,
    createOfferStore: { offering, setApr, rgnlAlt },
    programDetailsStore: { setSETContestNumbersByType, setSeriesProfile },
    seriesMappingStore: { seriesMapping },
  } = useStores();

  const { tiers, region } = useUrlParams();

  const breadCrumbs = [{ name: userInfoStore.properties.abbrName }];

  const isLoading = setLoading || loading;
  const hasError = setError || error;

  if (isLoading || hasError) {
    return <LoadingPage breadCrumbs={breadCrumbs} error={!!hasError} />;
  }

  if (setData) {
    setSETContestNumbersByType(setData.contestNumbers.setContestNumbers as SETContestNumbersByType[]);
  }

  if (data) {
    setSeriesProfile(data.seriesProfile as SeriesProfile, region, isLexusUser(), seriesMapping);

    const aprCard = createAprCard({
      endDate: offering.endDate,
      region,
      seriesProfile: data.seriesProfile as SeriesProfile,
      startDate: offering.startDate,
      tiers: tiers.split(','),
      offering,
      rgnlAlt
    });

    setApr(aprCard);
  }

  return (
    <GetStandardAprRates>
      <EFCSeriesData>
        <GetEnhCostShare optionTypeName={OPTION_TYPE_NAMES.APR}>
          <CreateAprComponent />
        </GetEnhCostShare>
      </EFCSeriesData>
    </GetStandardAprRates>
  );
};

export default CreateApr;
