import { formatDollars, formatDollarsCents, formatRcf, OATIcon, Tooltip } from 'oat-common-ui';
import { NavLink } from 'react-router-dom';
import PenRateExclusions from '../../../../components/PenRateTable/PenRateExclusions';
import { LEASE_BAD_VIN_ERROR, LEASE_VIN_AUTOSELECTED_ERROR, LEASE_VIN_STOP_SALE_ERROR } from '../../../../constants/global';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import { handleEnhancedDates } from '../../../../utils/handleEnhancedDates';
import useGetTfsLabel from '../../../../utils/useGetTfsLabel';
import PenRateModel from '../../models/PenRateModel';
import ProfileModel from '../../models/ProfileModel';
import RgnlAltModel from '../../models/RgnlAltModel';
import EnhancedLabel from './EnhancedLabel';
import styles from './styles.module.scss';
import { assignDollarCents } from '../../../../utils/assignValue';

interface Props {
  profile: ProfileModel;
  rgnlAlt: RgnlAltModel;
}

/**
 * Column data:
 * <td>national details</td>
 * <td>regional details</td>
 * <td>est cost</td>
 */
const OffersTableRow = ({ profile, rgnlAlt }: Props) => {
  const { region, period } = useUrlParams();
  const tfsLabel = useGetTfsLabel();
  const {
    summaryStore: { offering },
  } = useStores();

  const createProgramDetailsLink = (
    name: string,
    scrollTo: string,
    scrollToTerm?: string,
    scrollToTier?: string,
    subCashType?: string,
    subCash?: number,
    errorMessage?: string,
  ) => {
    const scrollToParams = [`scrollTo=${scrollTo}`];
    if (scrollToTerm) {
      scrollToParams.push(`scrollToTerm=${scrollToTerm}`);
    }
    if (scrollToTier) {
      scrollToParams.push(`scrollToTier=${scrollToTier}`);
    }

    return (
      <div className={styles.nameContainer}>
        {rgnlAlt.isSelected ? (
          <NavLink to={`/details/region/${region}/period/${period}/profile/${profile.id}/regalt/${rgnlAlt.id}?${scrollToParams.join('&')}`}>{name}</NavLink>
        ) : (
          <label>{name}</label>
        )}
        {Boolean(subCash) && <span>{` + ${tfsLabel} ${subCashType} Subvention Cash`}</span>}
        {errorMessage && (
          <span className={styles.vinTooltip}>
            <Tooltip id={`${name}-bad-vin`} place="bottom" message={errorMessage}>
              <OATIcon icon="warning" size={12} colorTheme="red" />
            </Tooltip>
          </span>
        )}
      </div>
    );
  };

  const getLeaseVinErrorMessage = (model: PenRateModel) => {
    let errorMsg = '';
    const { isBadVin, isVinAutoSelected, isVinStopSale } = model;

    if (isBadVin) {
      errorMsg = LEASE_BAD_VIN_ERROR;
    } else if (isVinAutoSelected) {
      errorMsg = LEASE_VIN_AUTOSELECTED_ERROR;
    } else if (isVinStopSale) {
      errorMsg = LEASE_VIN_STOP_SALE_ERROR;
    }

    return errorMsg;
  };

  return (
    <tbody>
      {/* Customer Cash */}
      {rgnlAlt.cash.map(cashModel => {
        const { id, isStandAlone, isEnhanced, cash, nationalCash, estimatedCost, startDate, endDate } = cashModel;
        const isEnhancedDates = handleEnhancedDates(startDate, endDate, offering.startDate, offering.endDate, true);

        return (
          <tr key={`ofr-tbl-cash-${id}`} className={styles.cashRow}>
            <td className={styles.cash}>
              {createProgramDetailsLink(cashModel.name, `cash-${id}`)}
              {isEnhancedDates && <span className={styles.dates}>{isEnhancedDates}</span>}
              <PenRateExclusions penRateModel={cashModel} />
            </td>
            <td>{isStandAlone ? '-' : `$${formatDollars(nationalCash)}`}</td>
            <td>
              <EnhancedLabel isEnhanced={isEnhanced || isStandAlone}>${formatDollars(cash)}</EnhancedLabel>
            </td>
            <td>${formatDollarsCents(estimatedCost)}</td>
          </tr>
        );
      })}

      {/* APR */}
      {rgnlAlt.apr.map(aprModel => {
        const { id, isStandAlone, tier, aprDetails, nationalAprDetails, estimatedCost, subCashEstCost, subCash } = aprModel;

        return (
          <tr key={`ofr-tbl-apr-${id}-${tier}`} className={styles.aprRow}>
            <td className={styles.apr}>
              {createProgramDetailsLink(`${aprModel.name} Tier ${tier}`, `apr-${id}`, '', encodeURIComponent(tier), 'APR', subCash)}
              <PenRateExclusions penRateModel={aprModel} />
            </td>
            <td>
              <ul>
                {isStandAlone
                  ? // if standalone, just repeat the regional details with '-'
                    aprDetails.map(({ term }) => {
                      return <li key={`apr-std-${id}-${tier}-${term}`}>-</li>;
                    })
                  : // list national data
                    nationalAprDetails.map(({ rate, term, subCash }) => {
                      return (
                        <li key={`apr-nat-${id}-${tier}-${term}`}>
                          {assignDollarCents(rate)}% for {term}mos {Boolean(subCash) && `+ $${formatDollars(subCash)}`}
                        </li>
                      );
                    })}
              </ul>
            </td>
            <td>
              <ul>
                {aprDetails.map(({ rate, term, subCash, isEnhanced, isSubCashEnhanced }) => {
                  return (
                    <li key={`apr-reg-${id}-${term}`}>
                      <EnhancedLabel isEnhanced={isEnhanced || isSubCashEnhanced || isStandAlone}>
                        {assignDollarCents(rate)}% for {term}mos
                        {((isStandAlone && subCash) || !isStandAlone) && <>&nbsp;{`+ $${formatDollars(subCash)}`}</>}
                      </EnhancedLabel>
                    </li>
                  );
                })}
              </ul>
            </td>
            <td>
              ${formatDollarsCents(estimatedCost)}
              {subCash > 0 && ` + $${formatDollarsCents(Math.round(subCashEstCost))}`}
            </td>
          </tr>
        );
      })}

      {/* Lease */}
      {rgnlAlt.lease.map(leaseModel => {
        const { id, isStandAlone, isEnhanced, isSubCashEnhanced, term, tiers, rcf, nationalRcf, nationalSubCash, subCash, estimatedCost, subCashEstCost } = leaseModel;
        const vinErrorMessage = getLeaseVinErrorMessage(leaseModel);

        return (
          <tr key={`ofr-tbl-lease-${id}-${term}`} className={styles.leaseRow}>
            <td className={styles.lease}>
              {createProgramDetailsLink(`${leaseModel.name} ${term} mo`, `lease-${id}`, term, '', 'Lease', subCash, vinErrorMessage)}
              <PenRateExclusions penRateModel={leaseModel} />
            </td>
            <td>
              <ul>
                <li>{isStandAlone ? '-' : formatRcf(nationalRcf)}</li>
                <li>{isStandAlone ? '-' : `Tier ${tiers.join(', ')}`}</li>
                <li>{!nationalSubCash ? '-' : `$${formatDollars(nationalSubCash)}`}</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <EnhancedLabel isEnhanced={isEnhanced || isStandAlone}>{formatRcf(rcf)}</EnhancedLabel>
                </li>
                <li>
                  <EnhancedLabel isEnhanced={isEnhanced || isStandAlone}>Tier {tiers.join(', ')}</EnhancedLabel>
                </li>
                <li>
                  <EnhancedLabel isEnhanced={isSubCashEnhanced || isStandAlone}>{!subCash ? '-' : `$${formatDollars(subCash)}`}</EnhancedLabel>
                </li>
              </ul>
            </td>
            <td>
              ${formatDollarsCents(estimatedCost)}
              {subCash > 0 && ` + $${formatDollarsCents(subCashEstCost)}`}
            </td>
          </tr>
        );
      })}

      {/* Misc */}
      {rgnlAlt.misc.map(miscModel => {
        const { id, isStandAlone, isEnhanced, cash, nationalCash, estimatedCost, startDate, endDate } = miscModel;
        const isEnhancedDates = handleEnhancedDates(startDate, endDate, offering.startDate, offering.endDate, true);

        return (
          <tr key={id} className={styles.cashRow}>
            <td className={styles.cash}>
              {createProgramDetailsLink(miscModel.name, `misc-${id}`)}
              {isEnhancedDates && <span className={styles.dates}>{isEnhancedDates}</span>}
              <PenRateExclusions penRateModel={miscModel} />
            </td>
            <td>{isStandAlone ? '--' : `$${formatDollars(nationalCash)}`}</td>
            <td>
              <EnhancedLabel isEnhanced={isEnhanced || isStandAlone}>${formatDollars(cash)}</EnhancedLabel>
            </td>
            <td>${formatDollarsCents(estimatedCost)}</td>
          </tr>
        );
      })}

      {/* RYO */}
      {rgnlAlt.ryo.map(({ id, isStandAlone, name, cash, nationalCash, estimatedCost }) => {
        return (
          <tr key={id} className={styles.ryoRow}>
            <td className={styles.ryo}>{name}</td>
            <td>{isStandAlone ? '--' : `$${formatDollars(nationalCash)}`}</td>
            <td>${formatDollars(cash)}</td>
            <td>${formatDollars(estimatedCost)}</td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default OffersTableRow;
