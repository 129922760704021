import { formatDollars, OATIcon, Tooltip } from 'oat-common-ui';
import styles from './styles.module.scss';

const processTooltipMessage = (nationalVal: string) => {
  return !nationalVal ? (
    'No National Value'
  ) : (
    <div className={styles.alignLeft}>
      <span>National:</span>
      <br />
      <span>{nationalVal}</span>
    </div>
  );
};

export const handleTableValueRender = (isEnhanced: boolean, id: string, regionalVal: string, nationalVal: string, isCash = false) => {
  let valToRender = regionalVal;

  if (isCash) {
    valToRender = `$${formatDollars(regionalVal)}`;
  }

  if (isEnhanced) {
    return (
      <Tooltip className={styles.toolTip} id={id} message={processTooltipMessage(nationalVal)}>
        <span className={styles.addBold}>{valToRender}</span>
        <span className={styles.thunderIcon}>
          <OATIcon icon="enhancement" colorTheme="orange" />
        </span>
      </Tooltip>
    );
  }

  return valToRender;
};
