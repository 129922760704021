import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children?: ReactNode;
  className?: string;
}

export const OfferBody = ({ children }: Props) => {
  return <div className={styles.offerBody}>{children}</div>;
};

interface LeftProps extends Props {
  small?: boolean;
}

export const OfferBodyLeft = ({ small, children, className }: LeftProps) => {
  return <div className={clsx(styles.offerBodyLeft, small && styles.small, className)}>{children}</div>;
};

export const OfferBodyRight = ({ children, className }: Props) => {
  return <div className={clsx(styles.offerBodyRight, className)}>{children}</div>;
};

export default OfferBody;
