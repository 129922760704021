import { observer } from 'mobx-react-lite';
import { OATIcon } from 'oat-common-ui';
import { useState } from 'react';
import PrintModal from '../PrintModal';
import styles from './styles.module.scss';

interface Props {
  onGenerate: () => void;
  onDownload: () => void;
}

const Print = ({ onGenerate, onDownload }: Props) => {
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const handlePrint = () => {
    onGenerate();
    setOpenPrintModal(false);
  };

  return (
    <div>
      <OATIcon icon="report" onClick={() => setOpenPrintModal(true)} className={styles.printIcon} size={20} />
      <OATIcon icon="printer" onClick={onDownload} className={styles.printIcon} size={20} />
      {openPrintModal && <PrintModal handleGenerate={handlePrint} onCancel={() => setOpenPrintModal(false)} />}
    </div>
  );
};

export default observer(Print);
