import { BreadCrumbType } from 'oat-common-ui';
import MainHeader from '../../components/MainHeader';
import ErrorPage from '../ErrorPage';

type Props = {
  breadCrumbs?: BreadCrumbType[];
  error?: boolean;
};

const LoadingPage = ({ breadCrumbs, error }: Props) => {
  if (error) {
    return <ErrorPage breadCrumbs={breadCrumbs} />;
  }

  return <MainHeader breadCrumbs={breadCrumbs} showProgressBar />;
};

export default LoadingPage;
