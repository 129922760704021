import { observer } from 'mobx-react-lite';
import { BlockInputLabel, Button, OATIcon, Textarea, useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { useSaveRgnlAltNationalNoteMutation } from '../../../../../../../gql/generated';
import ProfileModel from '../../../../../models/ProfileModel';
import styles from './styles.module.scss';

interface Props {
  profile: ProfileModel;
  isDisabled?: boolean;
}

const AddNationalNote = ({ profile, isDisabled }: Props) => {
  const [saveRgnlAltNationalNote] = useSaveRgnlAltNationalNoteMutation();
  const { setDelay } = useInputDelay();
  const { error } = useToast();
  const selectedRgnlAlt = profile.getSelectedRgnAlt();

  const deleteNoteHandler = () => {
    profile.setAddNote(false);
    profile.updateNote('');
  };

  const handleSaveRgnlAltNationalNote = (nationalNote: string) => {
    profile.updateNote(nationalNote);

    if (nationalNote) {
      setDelay(async () => {
        try {
          const res = await trackPromise(
            saveRgnlAltNationalNote({
              variables: {
                input: {
                  id: selectedRgnlAlt?.id || '',
                  rev: selectedRgnlAlt?.rev || '',
                  nationalNote: profile.note,
                },
              },
            }),
          );
          selectedRgnlAlt?.updateRev(res.data?.saveRgnlAltNationalNote.rgnlAlt.rev || '');
        } catch (e) {
          error((e as Error).message);
        }
      });
    }
  };

  return (
    <div className={styles.addNoteContainer}>
      <BlockInputLabel labelClass={styles.labelClass} labelComponent={<span className={styles.inputLabel}>National Note</span>}>
        <Textarea
          id={`${selectedRgnlAlt?.id}-national-note`}
          className={styles.addNoteInput}
          value={profile.note}
          error={!profile.note}
          onChange={e => handleSaveRgnlAltNationalNote(e.target.value)}
          disabled={isDisabled}
        />
      </BlockInputLabel>
      {!isDisabled && (
        <Button variant="text" className={styles.addNoteDeleteBtn} id="delete-note-btn" onClick={() => deleteNoteHandler()}>
          <OATIcon icon="trash" colorTheme="blue" />
        </Button>
      )}
    </div>
  );
};

export default observer(AddNationalNote);
