import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { BlockInputLabel, Dropdown, DropdownItem, formatDollars, InlineInputLabel, Input } from 'oat-common-ui';
import NumberInput from '../../../../../components/NumberInput';
import RadioInput from '../../../../../components/RadioInput';
import { FEATURE_OR_2477, SalesCodes, Status, SystemToPay } from '../../../../../constants/global';
import useStores from '../../../../../stores/useStores';
import { assignNumberValue, assignStringValue } from '../../../../../utils/assignValue';
import { getDropdownItemFromEnum } from '../../../../../utils/getDropdownItemFromEnum';
import { isCashOfferAtDefault } from '../../../../../utils/isCashOfferAtDefault';
import MiscCardModel from '../../../models/MiscCardModel';
import styles from '../styles.module.scss';
import useMiscOfferCard from '../useMiscOfferCard';
import AmountField from './AmountField';
import PaymentSection from './PaymentSection';

type BasicFieldsProps = {
  card: MiscCardModel;
  onChange: () => void;
  forCreate?: boolean;
};

const BasicMiscCardFields = ({ card, onChange, forCreate = false }: BasicFieldsProps) => {
  const {
    fields: { type, name, combinedPerUnitCost, defaultPerUnitCost, pnv, systemToPay, tfsCostShare, defaultTfsCostShare, tfsCostShareCap, defaultTfsCostShareCap, salesCode, maxNoOfDays, tfsContractRequired },
    hasError,
    hasPNVError,
    isStandAlone,
    updateTfsModal,
    updateField,
    updateFieldsOnChangeType,
    updateSalesCode,
    updateOfferCosts,
    updateAmountOrPnv,
  } = card;
  const { hasPNV, isGroupOne } = useMiscOfferCard(card);
  const {
    userInfoStore: { isSETUser, userInfo, isLexus },
    programDetailsStore,
  } = useStores();
  const { CASH_TYPES } = AdminConstants;
  const isOther = type === CASH_TYPES.OTHER;
  const isAtDefault = isCashOfferAtDefault(combinedPerUnitCost, assignStringValue(defaultPerUnitCost));

  const handlePNVChange = (value: string) => {
    updateAmountOrPnv('pnv', value);
    updateOfferCosts(programDetailsStore.ryoEarnings);
    onChange();
  };

  const handleCashAmount = (value: string) => {
    updateField('combinedPerUnitCost', value, true);
    updateOfferCosts(programDetailsStore.ryoEarnings);
    onChange();
  };

  const isDisabled = programDetailsStore.offering.status === Status.MEETS_GUIDELINES || card.isDisabled;

  const renderCostShareField = (className = '') => {
    return (
      <BlockInputLabel label={`${isSETUser(userInfo) ? 'SETF' : `${isLexus() ? 'LFS' : 'TFS'}`} Cost Share`} className={className} labelClass={styles.inputLabel}>
        <NumberInput
          percentageSign
          wholeNumber
          dark={!forCreate}
          error={!tfsCostShare}
          className={styles.costShare}
          value={tfsCostShare}
          onValueChange={e => {
            updateField('tfsCostShare', assignStringValue(e.value, '0'), true);
            updateOfferCosts(programDetailsStore.ryoEarnings);
            onChange();
          }}
          disabled={isDisabled || isAtDefault}
          units
        />
        <p className={styles.defaultValueLabel}>{card.enhancedCostShare?.tfsCostShare ? `Enhanced: ${card.enhancedCostShare?.tfsCostShare}%` : `Default: 0%`}</p>
      </BlockInputLabel>
    );
  }

  const renderCapField = (className = '') => {
    return (
      <BlockInputLabel label={`${isLexus() ? 'LFS' : 'TFS'} Cap`} className={className} labelClass={styles.inputLabel}>
        <NumberInput
          dollarSign
          wholeNumber
          dark={!forCreate}
          error={!tfsCostShareCap}
          className={styles.costShare}
          value={tfsCostShareCap}
          onValueChange={e => {
            updateField('tfsCostShareCap', assignStringValue(e.value, '0'), true);
            updateOfferCosts(programDetailsStore.ryoEarnings);
            onChange();
          }}
          disabled={isDisabled || isAtDefault}
          units
        />
        <p className={styles.defaultValueLabel}>{card.enhancedCostShare?.tfsCostShareCap ? `Enhanced: $${card.enhancedCostShare?.tfsCostShareCap}` : `Default: $0`}</p>
      </BlockInputLabel>
    );
  }

  return (
    <>
      <BlockInputLabel label="Type" className={cx(styles.inlineInput, isOther && styles.displayInlineBlock)} labelClass={styles.inputLabel}>
        {!forCreate ? (
          <p className={styles.nonEditableType}>{type}</p>
        ) : (
          <Dropdown
            minWidth={250}
            id="change-misc-type"
            options={getDropdownItemFromEnum(CASH_TYPES)}
            value={type}
            onChange={(item: DropdownItem) => {
              onChange();
              updateFieldsOnChangeType(item.value);
            }}
            disabled={isDisabled}
          />
        )}
      </BlockInputLabel>
      <div className={cx(styles.inlineInput, isOther && styles.displayInlineBlock)}>
        <AmountField
          card={card}
          forCreate={forCreate}
          onChange={value => {
            if (isCashOfferAtDefault(value, assignStringValue(defaultPerUnitCost))) {
              updateField('tfsCostShare', assignStringValue(defaultTfsCostShare, '0'), true);
              updateField('tfsCostShareCap', assignStringValue(defaultTfsCostShareCap, '0'), true);
            }
            onChange();
          }}
        />
        {((type !== CASH_TYPES.DEALER_CASH && isGroupOne) || type === CASH_TYPES.FINAL_PAY || type === CASH_TYPES.OTHER) && (
          <InlineInputLabel label={`${isSETUser(userInfo) ? 'SETF' : 'TFS'} Contract Required?`} className={styles.inputLabel} contentClass={styles.radioBtnWrapper}>
            <RadioInput
              id={`tfs-contracts-${card.id}`}
              type="checkbox"
              labelClass={styles.radioBtn}
              checked={tfsContractRequired}
              disabled={isDisabled || !isStandAlone}
              onClick={() => {
                updateField('tfsContractRequired', !tfsContractRequired);
                !tfsContractRequired && updateTfsModal(true);
                tfsContractRequired && onChange();
              }}
              onChange={() => {}}
            />
            <span className={styles.radioBtnText}>Yes</span>
          </InlineInputLabel>
        )}
      </div>
      <BlockInputLabel label="Name" className={styles.marginTop30} labelClass={styles.inputLabel}>
        <Input
          id="misc-offer-name"
          value={name}
          darkTheme={!forCreate}
          onChange={e => {
            updateField('name', e.currentTarget.value);
            onChange();
          }}
          disabled={isDisabled}
        />
      </BlockInputLabel>
      <PaymentSection card={card} isShow={isGroupOne} forCreate={forCreate} onChange={onChange} />
      <div className={styles.marginTop30}>
        <BlockInputLabel label="System to Pay" className={styles.inlineInput} labelClass={styles.inputLabel}>
          <Dropdown
            darkTheme={!forCreate}
            minWidth={220}
            id="system-top-pay"
            value={systemToPay}
            options={getDropdownItemFromEnum(SystemToPay)}
            onChange={item => {
              updateField('systemToPay', item.value);
              onChange();
            }}
            disabled={isDisabled}
          />
        </BlockInputLabel>
        {hasPNV && type !== CASH_TYPES.DEFERRED_PAYMENT && (
          <BlockInputLabel label="PNV" className={styles.inlineInput} labelClass={styles.inputLabel}>
            <NumberInput
              dark={!forCreate}
              negativeNumber
              readOnly={!isOther}
              error={hasPNVError}
              className={styles.numberInput}
              value={pnv}
              onValueChange={e => handlePNVChange(e.value)}
              disabled={isDisabled}
              units
            />
          </BlockInputLabel>
        )}
        {hasPNV && type === CASH_TYPES.DEFERRED_PAYMENT && (
          <BlockInputLabel label="PNV" className={styles.inlineInput} labelClass={styles.inputLabel}>
            <NumberInput
              dark={!forCreate}
              wholeNumber
              readOnly={!isOther}
              error={hasError}
              className={styles.numberInput}
              value={combinedPerUnitCost}
              onValueChange={e => handleCashAmount(e.value)}
              disabled={isDisabled}
              units
            />
            {!forCreate && <p className={styles.defaultValueLabel}>{isStandAlone ? `Default: $0` : `National: $${formatDollars(assignNumberValue(defaultPerUnitCost))}`}</p>}
          </BlockInputLabel>
        )}
        {type === CASH_TYPES.DEFERRED_PAYMENT && (
          <BlockInputLabel label="Maximum No. of Days" className={cx(styles.inlineInput, styles.marginRight0)} labelClass={styles.inputLabel}>
            <NumberInput
              allowNegative={false}
              dark={!forCreate}
              error={!maxNoOfDays}
              className={styles.numberInput}
              required
              value={maxNoOfDays}
              onValueChange={e => {
                updateField('maxNoOfDays', e.value);
                updateOfferCosts(programDetailsStore.ryoEarnings);
                onChange();
              }}
              wholeNumber
              disabled={isDisabled}
              units
            />
          </BlockInputLabel>
        )}
        {isGroupOne && renderCostShareField(styles.inlineInput)}
        {FEATURE_OR_2477 && isGroupOne && !isSETUser() && renderCapField(styles.inlineInput)}

        <div className={cx(type === CASH_TYPES.DEFERRED_PAYMENT && styles.marginTop30, styles.salesCodeRegion)}>
          {(type === CASH_TYPES.DEFERRED_PAYMENT || isGroupOne) && (
            <BlockInputLabel label="Sales Code" className={styles.inlineInput} labelClass={styles.inputLabel}>
              <Dropdown
                darkTheme={!forCreate}
                minWidth={220}
                id="sales-code"
                value={salesCode[0]}
                pillBoxValue={salesCode}
                isPillBox
                onCancelClick={item => {
                  updateSalesCode(item, true);
                  onChange();
                }}
                onChange={item => {
                  updateSalesCode(item.value);
                  onChange();
                }}
                options={getDropdownItemFromEnum(SalesCodes)}
                disabled={isDisabled}
              />
            </BlockInputLabel>
          )}
          {type === CASH_TYPES.DEFERRED_PAYMENT && renderCostShareField(styles.inlineInput)}
          {type === CASH_TYPES.DEFERRED_PAYMENT && !isSETUser() && renderCapField(styles.inlineInput)}
        </div>
      </div>
    </>
  );
};

export default observer(BasicMiscCardFields);
