const leaseErrorUtils = {
  LEASE_MATH_ERROR: 'Error! Mathematically not possible. Please try modifying the data.',
  ODD_TERMS_AVAILABILITY_ERROR: 'Only previously selected odd terms are available. Please add new terms to National Lease card prior to enhancing and refining.',
  DAS_DP_ERROR: `Due At Signing is resulting in a negative Down Payment. Down Payment cannot be negative.`,
  rcfMinError: (term: number) => `Term ${term} - Regional Subvened RCF cannot be negative.`,
  rcfMaxError: (term: number) => `Term ${term} - Regional Subvened RCF cannot be greater than National/Standard RCF.`,
  tpMinError: (term: number) => `Term ${term} - Target Payment cannot be negative.`,
  dgMinError: (term: number) => `Term ${term} - Dealer Gross cannot be negative.`,
  subCashMinError: (term: number) => `Term ${term} - Subvention Cash cannot be less than National Subvention Cash.`,
  noteError: (tfsLabel: string) => `Please add a regional note when selecting Enhanced Standard ${tfsLabel} RCF`,
};

export default leaseErrorUtils;
