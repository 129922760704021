import { AdminConstants } from 'oat-admin-common';
import { Button, ButtonGroup } from 'oat-common-ui';
import { useHistory } from 'react-router-dom';
import useUrlParams from '../../../../../hooks/useUrlParams';
import useStores from '../../../../../stores/useStores';

type Props = {
  isMultiSeries: boolean;
  onCancel: () => void;
  openAprModal: () => void;
  profileId: string;
  rgnlAltId: string;
};

const { OfferTypes, CASH_TYPES } = AdminConstants;

const AddOfferCtas = ({ rgnlAltId, openAprModal, onCancel, profileId, isMultiSeries }: Props) => {
  const { region, period } = useUrlParams();
  const history = useHistory();
  const {
    userInfoStore: { isSETUser },
  } = useStores();

  const gotoCreateOffer = (type: string, miscType?: string) => {
    const queryParams = miscType ? `?miscType=${miscType}` : '';

    return () => {
      history.push(`/create/${type}/region/${region}/period/${period}/profile/${profileId}/regalt/${rgnlAltId}${queryParams}`);
    };
  };

  return (
    <div>
      <ButtonGroup>
        {!isMultiSeries && (
          <>
            <Button id={`create-cash-${rgnlAltId}`} variant="primary" onClick={gotoCreateOffer('cash')}>
              {OfferTypes.CUSTOMER_CASH}
            </Button>
            <Button id={`create-apr-${rgnlAltId}`} variant="primary" onClick={openAprModal}>
              {OfferTypes.APR}
            </Button>
            <Button id={`create-lease-${rgnlAltId}`} variant="primary" onClick={gotoCreateOffer('lease')}>
              {OfferTypes.LEASE}
            </Button>
          </>
        )}
        {isSETUser() && (
          <Button id={`create-dealer-cash-${rgnlAltId}`} variant="primary" onClick={gotoCreateOffer('misc', CASH_TYPES.DEALER_CASH)}>
            {CASH_TYPES.DEALER_CASH}
          </Button>
        )}
        <Button id={`create-misc-${rgnlAltId}`} variant="primary" onClick={gotoCreateOffer('misc')}>
          {OfferTypes.MISCELLANEOUS}
        </Button>
        <Button id={`cancel-add-${rgnlAltId}`} variant="text" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default AddOfferCtas;
