import { OATIcon } from 'oat-common-ui';
import { Status } from '../../../constants/global';
import styles from './styles.module.scss';

interface Props {
  status: string;
}

const RegionStatus = ({ status }: Props) => {
  let statusToRender;

  switch (status) {
    case Status.NEW:
    case Status.NO_RESPONSE:
      statusToRender = <span className={styles.statusGray}>{status}</span>;
      break;
    case Status.SUBMITTED:
    case Status.ATC_SUBMITTED:
    case Status.NEEDS_REVISIONS:
    case Status.ATC_NEEDS_REVISIONS:
      statusToRender = <span className={styles.statusBlack}>{status}</span>;
      break;
    case Status.REVISED:
    case Status.ATC_REVISED:
      statusToRender = (
        <>
          <OATIcon icon="final-incentive" colorTheme="orange" /> <span className={styles.statusBlack}>{status}</span>
        </>
      );
      break;
    case Status.MEETS_GUIDELINES:
      statusToRender = (
        <>
          <OATIcon icon="check-mark" colorTheme="green" /> <span className={styles.statusBlack}>{status}</span>
        </>
      );
      break;
    default:
      statusToRender = <span className={styles.statusBlack}>{status}</span>;
      break;
  }

  return <td>{statusToRender}</td>;
};

export default RegionStatus;
