import useGetTfsLabel from '../../../../utils/useGetTfsLabel';
import EnhCostShareModal from '../EnhCostShareModal';

interface Props {
  onButtonPressOne: (isApplied: boolean) => void;
  onButtonPressTwo: (isApplied: boolean) => void;
  showEnhCostShareModal: boolean;
  showEnhTfsCostShareModal: boolean;
  setShowEnhCostShareModal: (val: boolean) => void;
  setShowEnhTfsCostShareModal: (val: boolean) => void;
  submitBtnText?: string;
  textOne?: string;
  titleOne?: string;
  idOne?: string;
  textTwo?: string;
  titleTwo?: string;
  idTwo?: string;
}

// ** For specific use case from Ticket OR-5826 **
const DualEnhCostShareModal = ({
  onButtonPressOne,
  onButtonPressTwo,
  idOne,
  idTwo,
  textOne,
  titleOne,
  textTwo,
  titleTwo,
  submitBtnText,
  showEnhCostShareModal,
  showEnhTfsCostShareModal,
  setShowEnhCostShareModal,
  setShowEnhTfsCostShareModal,
}: Props) => {
  const tfsLabel = useGetTfsLabel();

  // for enhanced cost share modal
  const handleOnButtonPressOne = (isApplied: boolean) => {
    setShowEnhCostShareModal(false);
    onButtonPressOne(isApplied);
  };

  // for enhanced tfs cost share modal
  const handleOnButtonPressTwo = (isApplied: boolean) => {
    setShowEnhTfsCostShareModal(false);
    onButtonPressTwo(isApplied);
  };

  // first modal to be displayed
  const enhCostShareModal = (
    <EnhCostShareModal id={idOne} title={titleOne} text={textOne} onButtonPress={isApplied => handleOnButtonPressOne(isApplied)} submitBtnText={submitBtnText} />
  );

  // second modal to be displayed
  const enhTfsCostShareModal = (
    <EnhCostShareModal
      id={idTwo}
      onButtonPress={isApplied => handleOnButtonPressTwo(isApplied)}
      title={titleTwo || `Enhanced ${tfsLabel} Cost Share Available`}
      text={textTwo || `Offer is eligible for National Enhanced ${tfsLabel} Cost Share. Do you want to apply?`}
      submitBtnText={submitBtnText}
    />
  );

  return (
    <>
      {showEnhCostShareModal && showEnhTfsCostShareModal && enhCostShareModal}
      {!showEnhCostShareModal && showEnhTfsCostShareModal && enhTfsCostShareModal}
    </>
  );
};

export default DualEnhCostShareModal;
