import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Button, Checkbox, Modal, ModalBody, ModalFooter, ModalHeader, OATIcon, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { FEATURE_OR_2477 } from '../../../../../constants/global';
import { TfsContract, useSaveTfsContractsMutation } from '../../../../../gql/generated';
import useMiscEnhCostShareUtils from '../../../../../hooks/useMiscEnhCostShareUtils';
import useStores from '../../../../../stores/useStores';
import { getTfsContractsWithTierTerms } from '../../../../createOffer/utils/getTfsContractsWithTierTerms';
import MiscCardModel from '../../../models/MiscCardModel';
import styles from '../styles.module.scss';
import { useGetMiscTerms } from '../useMiscOfferCard';
import { convertTermToTermRange } from '../utils/convertTermToTermRange';

export type TermDataModel = {
  term: number;
  tiers: string[];
};

type TfsContractProps = {
  card: MiscCardModel;
  onClose: () => void;
  saveOffer?: () => void;
  forCreate?: boolean;
};

const TfsContractModal = ({ card, onClose, forCreate = false, saveOffer }: TfsContractProps) => {
  const {
    id,
    isStandAlone,
    rev,
    fields: { tfsContracts, type },
    updateField,
    updateRev,
  } = card;

  const {
    programDetailsStore: { seriesProfile },
    seriesMappingStore: { seriesMapping },
  } = useStores();
  const termData = useGetMiscTerms();
  const [localTfs, setLocalTfs] = useState<TfsContract[]>(JSON.parse(JSON.stringify(tfsContracts)));
  const [activeTab, setActiveTab] = useState(localTfs[0]);
  const [isSelected, setIsSelected] = useState([false, false, false, false]);
  const { error } = useToast();

  const [saveTfsContracts] = useSaveTfsContractsMutation();
  const { handleOfferEligibility } = useMiscEnhCostShareUtils(card);

  const handleChecked = (tier: string, term: number) => {
    if (!activeTab.tierTerms) {
      return false;
    }

    return activeTab.tierTerms.includes(`${tier}#${term}`);
  };

  const selectAllLocalTiersTerms = (termData: TermDataModel[]) => {
    const tierTerms = new Set<string>();

    termData.forEach(term => {
      term.tiers.forEach(tier => {
        tierTerms.add(`${tier}#${term.term}`);
      });
    });

    const newVal = localTfs.slice();
    newVal.forEach(val => (val.tierTerms = Array.from(tierTerms).join(',')));
    setLocalTfs(newVal);
  };

  const unSelectLocalTiersTermsInActiveTab = (index: number) => {
    if (!localTfs) {
      return;
    }

    localTfs[index].tierTerms = '';
  };

  const updateSingleLocalTierTerm = (tier: string, term: number, isChecked: boolean) => {
    const newVal = localTfs.slice();
    const indexOfActive = localTfs.findIndex(tfs => tfs.contractType === activeTab.contractType && tfs.isSubvented === activeTab.isSubvented);

    const tierTermsArray = newVal[indexOfActive].tierTerms ? newVal[indexOfActive].tierTerms.split(',') : [];

    if (isChecked) {
      newVal[indexOfActive].tierTerms = tierTermsArray.filter(val => val !== `${tier}#${term}`).join(',');
    } else {
      tierTermsArray.push(`${tier}#${term}`);
      newVal[indexOfActive].tierTerms = tierTermsArray.join(',');
    }

    setLocalTfs(newVal);
  };

  const handleDownArrowClick = (termDataModel: TermDataModel) => {
    const newVal = localTfs.slice();
    const indexOfActive = localTfs.findIndex(tfs => tfs.contractType === activeTab.contractType && tfs.isSubvented === activeTab.isSubvented);
    const tierTermsInColumn = termDataModel.tiers.map(tier => `${tier}#${termDataModel.term}`);

    tierTermsInColumn.forEach(tierTerm => {
      if (!newVal[indexOfActive].tierTerms.includes(tierTerm)) {
        newVal[indexOfActive].tierTerms = newVal[indexOfActive].tierTerms ? `${newVal[indexOfActive].tierTerms},${tierTerm}` : tierTerm;
      }
    })

    setLocalTfs(newVal);
  }

  const handleRightArrowClick = (tier: string) => {
    const newVal = localTfs.slice();
    const indexOfActive = localTfs.findIndex(tfs => tfs.contractType === activeTab.contractType && tfs.isSubvented === activeTab.isSubvented);
    const terms = termData.map(term => term.term);

    terms.forEach(term => {
      const tierTerm = `${tier}#${term}`;
      if (!newVal[indexOfActive].tierTerms.includes(tierTerm)) {
        newVal[indexOfActive].tierTerms = newVal[indexOfActive].tierTerms ? `${newVal[indexOfActive].tierTerms},${tierTerm}` : tierTerm;
      }
    })

    setLocalTfs(newVal);
  }

  const handleOnSave = async () => {
    if (!forCreate) {
      try {
        const res = await trackPromise(saveTfsContracts({ variables: { input: { id, rev, tfsContracts: getTfsContractsWithTierTerms(localTfs) } } }));

        if (res.data?.saveTfsContracts.success) {
          updateRev(res.data?.saveTfsContracts.offer.rev);
          card.setCardData(res.data?.saveTfsContracts.offer, seriesMapping, seriesProfile);
        }

        saveOffer && saveOffer();
      } catch (e) {
        error((e as Error).message);
      }
    } else {
      updateField('tfsContracts', localTfs);
    }
  };

  const handleCheckbox = (index: number, hasTierTerms: boolean) => {
    if (isStandAlone) {
      const newVal = isSelected.slice();
      // check/uncheck tab checkbox with or without tier/terms logic
      newVal.splice(index, 1, hasTierTerms || isSelected[index] ? false : true);
      setIsSelected(newVal);
      unSelectLocalTiersTermsInActiveTab(index);
    }
  };

  const handleClick = () => {
    if (FEATURE_OR_2477) {
      updateField('tfsContracts', localTfs);
      onClose();

      // on create misc offer page, to prevent enh cost share modal to render if offer is eligible after saving tfs contracts on this modal
      // enh cost share modal will only render if eligible and after user clicks Create Offer button
      if (forCreate) {
        return;
      }

      const showRemoveModal = handleOfferEligibility();

      if (showRemoveModal) {
        // if enhanced cost share is eligible for removal based on selected tfs contracts,
        // save of the TFS contracts is handled within handleSaveOffer in MiscCard if user selects OK
        // if user selects cancel, no save is called
        return;
      }
    }

    handleOnSave();
    onClose();
  };

  return (
    <Modal isOpen onClose={onClose}>
      <ModalHeader onClose={onClose}>{`Edit Tiers & Terms ${type}`}</ModalHeader>
      <ModalBody className={styles.displayFlex}>
        <div className={styles.tfsModalContainer}>
          <ul className={styles.tfsList}>
            {localTfs.map((tfs, index) => {
              const hasTierTerms = tfs.tierTerms.length > 0;

              return (
                <li key={index} className={cx(styles.contractTypeListElement, tfs === activeTab && styles.activeTab)}>
                  <Checkbox
                    className={styles.contractTypeCheckbox}
                    isChecked={hasTierTerms || isSelected[index]}
                    onChange={() => {
                      handleCheckbox(index, hasTierTerms);
                    }}
                  />
                  <Button id="tfs-contract-type" onClick={() => setActiveTab(tfs)} className={styles.contractTypeBtn}>{`${tfs.contractType} ${
                    tfs.isSubvented ? 'Subvented' : 'Non-Subvented'
                  }`}</Button>
                </li>
              );
            })}
          </ul>
          <Button id="select-all-tiers-terms" className={styles.selectAll} disabled={!isStandAlone} variant="primary" onClick={() => selectAllLocalTiersTerms(termData)}>
            Select all tiers & term ranges
          </Button>
        </div>
        <div className={styles.modalBodyTable}>
          <div className={styles.modalSubheader}>
            <p>Tiers</p>
            <p className={styles.termText}>Term Range</p>
          </div>
          <div>
            <div className={styles.tfsModalContainer}>
              <div className={cx(styles.displayFlex, styles.terms)}>
                {termData.map((term, index) => (
                  <div key={index}>
                    <p className={cx(styles.termRange, index === 0 && styles.firstTermRange)}>
                      {convertTermToTermRange(term.term, termData)}
                      <Button variant="text" id={`${term.term}-arrow-btn-${term.term}`} className={styles.arrowBtn} onClick={() => handleDownArrowClick(term)}>
                        <OATIcon icon="arrow-down2" size={10} className={styles.arrowTermIcon} />
                      </Button>
                    </p>
                    <ul className={styles.tfsList}>
                      {term.tiers.map(tier => {
                        const isChecked = handleChecked(tier, term.term);

                        return (
                          <li key={tier} className={styles.tierList}>
                            {index === 0 && (
                              <span className={styles.tier}>
                                {tier}
                                <Button variant="text" id={`${term.term}-arrow-btn-${index}`} className={styles.arrowBtn} onClick={() => handleRightArrowClick(tier)}>
                                  <OATIcon icon="arrow-down2" size={10} className={styles.arrowIcon} />
                                </Button>
                              </span>
                            )}
                            <Checkbox
                              className={cx(styles.checkbox, index === 0 && styles.firstCheckbox)}
                              checkmarkClass={styles.checkboxRedesign}
                              isChecked={isChecked}
                              isDisabled={!isStandAlone}
                              onChange={() => updateSingleLocalTierTerm(tier, term.term, isChecked)}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button id="tfs-contract-save-btn" variant="primary" onClick={() => handleClick()}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default observer(TfsContractModal);
