import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import SettingsMenu from '../../../../components/SettingsMenu';
import { Status } from '../../../../constants/global';
import useStores from '../../../../stores/useStores';
import MiscCardModel from '../../models/MiscCardModel';
import AdvertiseOfferModal from '../AdvertiseOfferModal';
import EnhanceAndRefineModal from '../EnhanceAndRefineModal';
import PenRatesModal from '../PenRatesModal';
import RemoveOfferModal from '../RemoveOfferModal';
import ResetToNationalModal from '../ResetToNationalModal';
import ResetToPrevRegionalModal from '../ResetToPrevRegionalModal';
import { ItemAdjustPenRates, ItemAdvertised, ItemEnhanceAndRefine, ItemRemoveOffer, ItemResetPrevRegionalOffer, ItemResetToNational, ItemTfsContracts } from '../contextMenuItems';
import TfsContractModal from './components/TfsContractModal';

interface Props {
  miscCard: MiscCardModel;
  forCreate?: boolean;
  onChange?: () => void;
}

const MiscContextMenu = ({ miscCard, forCreate, onChange }: Props) => {
  const [showAdvertisedModal, setShowAdvertisedModal] = useState(false);
  const [showRemoveOfferModal, setShowRemoveOfferModal] = useState(false);
  const [showPenRatesModal, setShowPenRatesModal] = useState(false);
  const [showResetToNationalModal, setShowResetToNationalModal] = useState(false);
  const [showResetToPrevRegionalOffer, setShowResetToPrevRegionalOffer] = useState(false);
  const [showEnhanceAndRefineModal, setShowEnhanceAndRefineModal] = useState(false);
  const [offerId, setOfferId] = useState(miscCard.id);
  const {
    programDetailsStore: { removeMiscOffer, seriesProfile, offering },
    seriesMappingStore: { seriesMapping },
    summaryStore,
  } = useStores();

  return (
    <>
      {/* Context Menu */}
      <SettingsMenu isDisabled={summaryStore.offering.status === Status.MEETS_GUIDELINES || miscCard.isDisabled}>
        {!miscCard.isStandAlone && <ItemResetToNational onClick={() => setShowResetToNationalModal(true)} />}
        <ItemResetPrevRegionalOffer onClick={() => setShowResetToPrevRegionalOffer(true)} />
        <ItemTfsContracts onClick={() => miscCard.updateTfsModal(true)} />
        {!miscCard.isDuplicate && <ItemEnhanceAndRefine onClick={() => setShowEnhanceAndRefineModal(true)} />}
        <ItemAdvertised advertised={miscCard.isAdvertised} onClick={() => setShowAdvertisedModal(true)} />
        <ItemAdjustPenRates
          onClick={() => {
            setShowPenRatesModal(true);
            setOfferId(miscCard.id);
          }}
        />
        {(miscCard.isStandAlone || miscCard.isDuplicate) && <ItemRemoveOffer onClick={() => setShowRemoveOfferModal(true)} />}
      </SettingsMenu>

      {/* Modals */}
      {showPenRatesModal && <PenRatesModal currentOfferId={offerId} onClose={() => setShowPenRatesModal(false)} />}
      {showAdvertisedModal && (
        <AdvertiseOfferModal
          id={miscCard.id}
          rev={miscCard.rev}
          isAdvertised={miscCard.isAdvertised}
          onClose={() => {
            setShowAdvertisedModal(false);
          }}
          onSubmit={offer => {
            miscCard.updateRev(offer.rev);
            miscCard.updateAdvertised(!miscCard.isAdvertised);
          }}
        />
      )}
      {showRemoveOfferModal && (
        <RemoveOfferModal
          id={miscCard.id}
          rev={miscCard.rev}
          onClose={() => {
            setShowRemoveOfferModal(false);
          }}
          onSubmit={() => {
            removeMiscOffer(miscCard.id, miscCard.rev);
          }}
        />
      )}
      {miscCard.showTfsModal && <TfsContractModal forCreate={forCreate} card={miscCard} onClose={() => miscCard.updateTfsModal(false)} saveOffer={onChange} />}
      {showResetToNationalModal && (
        <ResetToNationalModal
          id={miscCard.id}
          onClose={() => setShowResetToNationalModal(false)}
          onSubmit={offer => {
            miscCard.updateRev(offer.rev);
            miscCard.setCardData(offer, seriesMapping, seriesProfile, offering);
          }}
          rev={miscCard.rev}
        />
      )}
      {showResetToPrevRegionalOffer && (
        <ResetToPrevRegionalModal
          id={miscCard.id}
          onClose={() => setShowResetToPrevRegionalOffer(false)}
          onSubmit={offer => {
            miscCard.updateRev(offer.rev);
            miscCard.setCardData(offer, seriesMapping, seriesProfile, offering);
          }}
          rev={miscCard.rev}
        />
      )}
      {showEnhanceAndRefineModal && (
        <EnhanceAndRefineModal
          id={miscCard.id}
          onClose={() => setShowEnhanceAndRefineModal(false)}
          rev={miscCard.rev}
          isInclusions={miscCard.fields.isInclusions}
          toggleIsInclusions={miscCard.toggleIsInclusions}
          startDate={miscCard.fields.startDate}
          endDate={miscCard.fields.endDate}
          minDate={miscCard.fields.startDate}
          maxDate={miscCard.fields.endDate}
          openPenRateModal={setShowPenRatesModal}
          vehicles={miscCard.fields.vehicles}
          setOfferId={setOfferId}
          miscCard={miscCard}
        />
      )}
    </>
  );
};

export default observer(MiscContextMenu);
