import { AdminConstants } from 'oat-admin-common';
import { RgnlAlt } from '../../../../gql/generated';
import { assignNumberValue } from '../../../../utils/assignValue';
import getSortedCombinedOffers from '../../../../utils/getSortedCombinedOffers';
import PenRateModel from '../PenRateModel';
import RgnlAltModel from '../RgnlAltModel';
import processAprTiers from './processAprTiers';
import sortRgnlAltOffers from './sortRgnlAltOffers';

const { OPTION_TYPE_NAMES } = AdminConstants;

const processRgnlAltOffers = (rgnlAlt: RgnlAlt, model: RgnlAltModel) => {
  model.id = rgnlAlt.id || '';
  model.rev = rgnlAlt.rev;
  model.name = rgnlAlt.name;

  model.isSelected = rgnlAlt.isSelected;

  if (model.isSelected) {
    model.status = rgnlAlt.status.includes('-') ? rgnlAlt.status.split('-')[1].trim() : rgnlAlt.status;
  }

  model.isStandalone = rgnlAlt.isStandalone;
  model.isNationalRYO = rgnlAlt.isNationalRyoRgnlAlt;
  model.editPenRate = rgnlAlt.isPenetrationEditable;
  model.fsv = assignNumberValue(rgnlAlt.forecastedSalesVolume, 0);
  model.regionCode = rgnlAlt.regionCode;
  model.seriesProfileId = rgnlAlt.seriesProfileId;

  const sortedOffers = getSortedCombinedOffers(rgnlAlt.offers || []);
  sortedOffers.forEach(item => {
    switch (item.regional.optionTypeName) {
      case OPTION_TYPE_NAMES.CUSTOMER_CASH: {
        const cashObj = new PenRateModel();
        cashObj.setCash(item);
        model.cash.push(cashObj);
        break;
      }

      case OPTION_TYPE_NAMES.APR: {
        const aprPenRates = processAprTiers(item);
        aprPenRates.forEach(apr => model.apr.push(apr));
        break;
      }

      case OPTION_TYPE_NAMES.LEASE: {
        item.regional.leaseDetails?.forEach(lease => {
          const leaseObj = new PenRateModel();
          leaseObj.setLease(item, lease);
          model.lease.push(leaseObj);
        });
        break;
      }

      case OPTION_TYPE_NAMES.NATIONAL_RYO: {
        const ryoObj = new PenRateModel();
        ryoObj.setRyo(item);
        model.ryo.push(ryoObj);
        const costs = assignNumberValue(item.regional.cashDetails?.combinedPerUnitCost);
        model.totalRyoEarnings += costs;
        model.nationalRyo += costs;
        break;
      }
      case OPTION_TYPE_NAMES.ADDITIONAL_RYO: {
        const ryoObj = new PenRateModel();
        ryoObj.setRyo(item);
        model.ryo.push(ryoObj);
        const costs = assignNumberValue(item.regional.cashDetails?.combinedPerUnitCost);
        model.totalRyoEarnings += costs;
        model.additionalRyo += costs;
        break;
      }
      case OPTION_TYPE_NAMES.LUMPSUM_RYO: {
        const ryoObj = new PenRateModel();
        ryoObj.setRyo(item);
        model.ryo.push(ryoObj);
        model.lumpSum += item.regional.cashDetails?.combinedPerUnitCost ?? 0;
        break;
      }

      default: {
        const miscObj = new PenRateModel();
        miscObj.setMisc(item);
        model.misc.push(miscObj);
        break;
      }
    }
  });

  model.runValidation();
  model.checkVinError();

  sortRgnlAltOffers(model);
};

export default processRgnlAltOffers;
