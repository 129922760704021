import { makeAutoObservable } from 'mobx';
import { AdminConstants } from 'oat-admin-common';
import { dateStringToDate } from 'oat-common-ui';
import { EnhTfsCostShare, SeriesProfile } from '../gql/generated';

const { OPTION_TYPE_NAMES } = AdminConstants;

type TCostShareDates = { aprCostShareDates: EnhTfsCostShare[]; leaseCostShareDates: EnhTfsCostShare[] };

class RegionalCostShareStore {
  constructor() {
    makeAutoObservable(this);
  }

  costShareDatesMap = new Map<string, TCostShareDates>();

  sortCostSharesByDate = (costShareDates: EnhTfsCostShare[]) => {
    return costShareDates.slice().sort((a, b) => dateStringToDate(a.endDate).getTime() - dateStringToDate(b.endDate).getTime());
  };

  setCostShareDates = (seriesProfiles: SeriesProfile[]) => {
    for (const seriesProfile of seriesProfiles) {
      const enhTfsCostShares = seriesProfile.enhTfsCostShares;

      if (enhTfsCostShares?.length) {
        const aprCostShareDates: EnhTfsCostShare[] = [];
        const leaseCostShareDates: EnhTfsCostShare[] = [];

        for (const enhTfsCostShare of enhTfsCostShares) {
          if (enhTfsCostShare?.optionTypeName === OPTION_TYPE_NAMES.APR) {
            aprCostShareDates.push(enhTfsCostShare);
          }

          if (enhTfsCostShare?.optionTypeName === OPTION_TYPE_NAMES.LEASE) {
            leaseCostShareDates.push(enhTfsCostShare);
          }
        }

        this.costShareDatesMap.set(seriesProfile.id, {
          aprCostShareDates: this.sortCostSharesByDate(aprCostShareDates),
          leaseCostShareDates: this.sortCostSharesByDate(leaseCostShareDates),
        });
      }
    }
  };

  geTfstCostSharesByType = (seriesProfileId: string, optionTypeName: string): EnhTfsCostShare[] | undefined => {
    if (optionTypeName === AdminConstants.OPTION_TYPE_NAMES.APR) {
      return this.costShareDatesMap.get(seriesProfileId)?.aprCostShareDates;
    } else {
      return this.costShareDatesMap.get(seriesProfileId)?.leaseCostShareDates;
    }
  };

  getEnhancedTfsCostShareById = (seriesProfileid: string, optionTypeName: string, id?: string) => {
    return this.geTfstCostSharesByType(seriesProfileid, optionTypeName)?.find(costShare => costShare.id === id);
  };
}

export default RegionalCostShareStore;
