import { Button, ButtonGroup, OATFooter } from 'oat-common-ui';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import authClient from '../../authClient';
import styles from './styles.module.scss';

interface Props {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  darkTheme?: boolean;
  secondaryTheme?: boolean;
  isShowPublish?: boolean;
  isDisablePublish?: boolean;
  onPublish?: () => void;
  onPreviewPublish?: () => void;
  isShowReconcile?: boolean;
  onReconcile?: () => void;
  isShowUpdateOfferingStatus?: boolean;
  onUpdateOfferingStatus?: () => void;
  isShowSubmitAllOffersToNational?: boolean;
  isDisableSubmitAllOffersToNational?: boolean;
  onSubmitAllOffersToNational?: () => void;
  isShowDownloadIncentivePeriodDocs?: boolean;
  helpUrl?: string;
  showCompleteReconcile?: boolean;
  onCompleteReconcile?: () => void;
}

const MainFooter = ({
  leftContent,
  onPublish,
  onPreviewPublish,
  isShowReconcile,
  onReconcile,
  isShowUpdateOfferingStatus,
  onUpdateOfferingStatus,
  isShowSubmitAllOffersToNational,
  isDisableSubmitAllOffersToNational,
  onSubmitAllOffersToNational,
  isShowDownloadIncentivePeriodDocs,
  showCompleteReconcile,
  onCompleteReconcile,
  ...rest
}: Props) => {
  const handlePublish = () => {
    if (onPublish) {
      onPublish();
    }
  };

  const handlePreviewPublish = () => {
    if (onPreviewPublish) {
      onPreviewPublish();
    }
  };

  const handleOnReconcile = () => {
    if (onReconcile) {
      onReconcile();
    }
  };

  const handleOnUpdateOfferingStatus = () => {
    if (onUpdateOfferingStatus) {
      onUpdateOfferingStatus();
    }
  };

  const handleSubmitAllOffersToNational = () => {
    if (onSubmitAllOffersToNational) {
      onSubmitAllOffersToNational();
    }
  };

  const handleOnCompleteReconcile = () => {
    if (onCompleteReconcile) {
      onCompleteReconcile();
    }
  };
  return (
    <OATFooter
      {...rest}
      isShowPreview
      onClickLogout={() => authClient.logout()}
      onPublish={handlePublish}
      onPreviewPublish={handlePreviewPublish}
      leftContent={
        leftContent || (
          <ButtonGroup>
            {isShowDownloadIncentivePeriodDocs && (
              <Link to="/reports/incentivePeriods" className={styles.noStyle}>
                <Button variant="text" id="download-incentive-period-docs-btn">
                  Download Incentive Period Documents
                </Button>
              </Link>
            )}
          </ButtonGroup>
        )
      }
      rightContent={
        <div className={styles.rightContent}>
          <ButtonGroup>
            {isShowSubmitAllOffersToNational && (
              <Button variant="primary" id="submit-all-offers-to-national-btn" onClick={() => handleSubmitAllOffersToNational()} disabled={isDisableSubmitAllOffersToNational}>
                Submit All Offers to National
              </Button>
            )}
            {isShowUpdateOfferingStatus && (
              <Button variant="primary" id="update-offering-status-btn" onClick={() => handleOnUpdateOfferingStatus()}>
                Update Offering Status
              </Button>
            )}
            {isShowReconcile && (
              <Button variant="primary" id="reconcile-btn" onClick={() => handleOnReconcile()}>
                Reconcile
              </Button>
            )}
            {showCompleteReconcile && (
              <Button variant="primary" id="complete-reconcile-btn" onClick={() => handleOnCompleteReconcile()}>
                Complete Reconcile
              </Button>
            )}
          </ButtonGroup>
        </div>
      }
    />
  );
};

export default MainFooter;
