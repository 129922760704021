import { Efc3dImagesResponse } from 'oat-common-ui';

export const getEfc3dImageExtColors = (seriesImages: Map<string, Efc3dImagesResponse>, year: string, series: string, modelCode?: string) => {
  const mapKey = `${year}_${series}`;
  const seriesItem = seriesImages.get(mapKey);

  if (seriesItem) {
    const modelObject = modelCode ? seriesItem.DataArea.model[modelCode] : Object.values(seriesItem.DataArea.model)[0];
    return modelObject?.exteriorcolor;
  }

  return undefined;
};

type ImageObject = {
  [res: string]: {
    [id: string]: string[];
  };
};

const parse3dImageObject = (imageObj: ImageObject) => {
  let keys = Object.keys(imageObj ?? {});
  if (keys.length > 0) {
    // filter out large width imgs
    // example dimension: 600_800_PNG
    keys = keys
      .filter(key => +key.split('_')[0] < 800)
      .sort()
      .reverse();
    const resObj = imageObj[keys[0]];

    const resObjKeys = Object.keys(resObj);
    if (resObjKeys.length > 0) {
      const urlArr = resObj[resObjKeys[0]];
      if (urlArr.length > 0) {
        return urlArr[0];
      }
    }
  }

  return '';
};

export const getEfcCarJelly = (seriesImages: Map<string, Efc3dImagesResponse>, year: string, series: string, modelCode?: string) => {
  const extColors = getEfc3dImageExtColors(seriesImages, year, series, modelCode);

  const extColorsArray = extColors ? Object.values(extColors) : [];
  if (extColorsArray.length > 0) {
    return extColorsArray[0].carjellyimage ? extColorsArray[0].carjellyimage : parse3dImageObject(extColorsArray[0]['3dimage']);
  }

  return undefined;
};

export const getEfcImageFullPath = (imagePath: string) => {
  return `${process.env.REACT_APP_CAR_JELLY_URL}${imagePath}`;
};
