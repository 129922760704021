import { makeAutoObservable } from 'mobx';
import { OfferingCosts, OfferingRaCosts } from '../gql/generated';
import { assignNumberValue } from '../utils/assignValue';

class OfferingCostsStore {
  // key is the rgnlAlt id
  raCosts: Record<string, OfferingRaCosts> = {};
  periodEarnings = 0;
  periodCost = 0;
  periodBalance = 0;
  periodTfsCost = 0;
  periodEnhTfsCost = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setData = (offeringCosts?: OfferingCosts) => {
    if (offeringCosts) {
      const offerCosts: Record<string, OfferingRaCosts> = {};
      this.periodEarnings = assignNumberValue(offeringCosts.periodEarnings);
      this.periodCost = assignNumberValue(offeringCosts.periodCost);
      this.periodBalance = assignNumberValue(offeringCosts.periodBalance);
      this.periodTfsCost = assignNumberValue(offeringCosts.periodTfsCost);
      this.periodEnhTfsCost = assignNumberValue(offeringCosts.periodEnhancedTfsCost);

      if (offeringCosts.costs) {
        offeringCosts.costs.forEach(item => {
          offerCosts[item.raId] = item;
        });
        this.raCosts = offerCosts;
      }
    }
  };
}

export default OfferingCostsStore;
