export interface NumberConfig {
  allowNegative?: boolean;
  decimalScale?: number;
  thousandSeparator?: boolean;
  max?: number;
}

export const wholeNumberConfig: NumberConfig = {
  allowNegative: false,
  decimalScale: 0,
  thousandSeparator: true,
};

export const negativeNumberConfig: NumberConfig = {
  allowNegative: true,
  decimalScale: 0,
  thousandSeparator: true,
};

export const unitsConfig: NumberConfig = {
  allowNegative: false,
  decimalScale: 0,
};

export const rcfConfig: NumberConfig = {
  decimalScale: 5,
};

export const dollarConfig: NumberConfig = {
  decimalScale: 2,
  thousandSeparator: true,
};

export const getConfig = (wholeNumber = false, negativeNumber = false, units = false, rcf = false, dollar = false) => {
  if (wholeNumber) {
    return wholeNumberConfig;
  } else if (negativeNumber) {
    return negativeNumberConfig;
  } else if (units) {
    return unitsConfig;
  } else if (rcf) {
    return rcfConfig;
  } else if (dollar) {
    return dollarConfig;
  }

  return {};
};
