import { PenetrationDetails } from '../../gql/generated';
import PenRateModel from '../../pages/Summary/models/PenRateModel';
import RgnlAltModel from '../../pages/Summary/models/RgnlAltModel';

const getCommonPayloadAttr = (item: PenRateModel) => {
  return {
    offerEarnings: item.offerEarnings,
    offerCost: item.offerCost,
    offerTfsCost: item.offerTfsCost,
    offerEnhTfsCost: item.offerEnhTfsCost,
    subCashOfferCost: item.subCashOfferCost,
    subCashOfferTfsCost: item.subCashOfferTfsCost,
    subCashOfferEnhTfsCost: item.subCashOfferEnhTfsCost,
    isFourthOption: false,
    offerId: item.id,
    offerRev: item.rev,
    optionTypeName: item.optionTypeName,
    penetration: Number(item.penetration),
    forecastedSales: Number(item.forecastedSales),
    term: 0,
    tier: '',
    optionTierType: item.optionTierType,
  };
};

const transformPenratesPayload = (rgnlAlt: RgnlAltModel) => {
  const payload: PenetrationDetails[] = [];

  rgnlAlt.cash.forEach(item => {
    payload.push({ ...getCommonPayloadAttr(item) });
  });

  rgnlAlt.apr.forEach(item => {
    payload.push({
      ...getCommonPayloadAttr(item),
      tier: item.tier,
    });
  });

  rgnlAlt.lease.forEach(item => {
    payload.push({
      ...getCommonPayloadAttr(item),
      tier: item.tier,
      term: Number(item.term),
    });
  });

  rgnlAlt.misc.forEach(item => {
    payload.push({
      ...getCommonPayloadAttr(item),
      isFourthOption: item.isFourthOption,
    });
  });

  return payload;
};

export default transformPenratesPayload;
