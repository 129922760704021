import { makeAutoObservable } from 'mobx';
import { sortFieldsHelper } from 'oat-common-ui';
import { GetOfferingsByRegionActiveQuery, Offering } from '../gql/generated';
import { processActiveOfferings } from '../pages/Dashboard/util';

class DashboardStore {
  isLoading = true;
  active: Offering[] = [];
  archived: Offering[] = [];

  // for FY Balance in header for Regional User
  processedOfferings: Offering[] = [];
  fyBalance: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  initData = (data: GetOfferingsByRegionActiveQuery | undefined, isNational: boolean) => {
    if (data) {
      const sortField = 'startDate';
      const sortAscending = false;
      this.active = processActiveOfferings(data.active as Offering[], isNational).sort(sortFieldsHelper(sortField, sortAscending));
      this.archived = (data.archived as Offering[]).slice().sort(sortFieldsHelper(sortField, sortAscending));
    }
  };

  updateByOffering = (offering: Offering) => {
    this.active = this.active.map(item => (item.id === offering.id ? offering : item));
    this.archived = this.archived.map(item => (item.id === offering.id ? offering : item));
  };

  sortActive = (field: string, sortAscending: boolean) => {
    this.active = this.active.slice().sort(sortFieldsHelper(field, sortAscending));
  };

  sortArchived = (field: string, sortAscending: boolean) => {
    this.archived = this.archived.slice().sort(sortFieldsHelper(field, sortAscending));
  };

  getOfferingById = (offeringId: string) => {
    const allOfferings = this.active.concat(this.archived);

    return allOfferings.find(offering => offering.id === offeringId);
  };
}

export default DashboardStore;
