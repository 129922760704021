import { BlockInputLabel, Dropdown, DropdownItem } from 'oat-common-ui';
import { getDropdownItemFromEnum } from '../../../../../../utils/getDropdownItemFromEnum';
import LeaseFormModel from '../../../../models/LeaseFormModel';
import styles from './styles.module.scss';

interface Props {
  leaseForm: LeaseFormModel;
  term: number;
  oddTermsList: number[];
  onChange?: (val: number) => void;
  isDisabled?: boolean;
}

const LeaseAddTerm = ({ leaseForm, term, oddTermsList, onChange, isDisabled }: Props) => {
  if (oddTermsList.length > 0) {
    return (
      <>
        <BlockInputLabel label="Add Term" className={styles.addTermContainer}>
          <Dropdown
            id={`add-term-${leaseForm.uid}`}
            options={getDropdownItemFromEnum(oddTermsList)}
            value={term.toString()}
            onChange={(item: DropdownItem) => {
              if (onChange) {
                onChange(Number(item.value));
              }
            }}
            disabled={isDisabled}
            darkTheme
          />
        </BlockInputLabel>
      </>
    );
  }

  return null;
};

export default LeaseAddTerm;
