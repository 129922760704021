import clsx from 'clsx';
import { Button, OATIcon } from 'oat-common-ui';
import { scrollToTop } from '../../../../components/AnchorScrollTo';
import styles from './styles.module.scss';

interface Props {
  showScrollToTop: boolean;
}

const ArrowUpButton = ({ showScrollToTop }: Props) => {
  const buttonClass = clsx(styles.arrowUpBtn, showScrollToTop ? styles.show : styles.hide);

  return (
    <Button variant="text" className={buttonClass} id="arrow-up-btn" onClick={() => scrollToTop(true)}>
      <OATIcon icon="circle-up" className={styles.icon} size={30} colorTheme="blue" />
    </Button>
  );
};

export default ArrowUpButton;
