import { FEATURE_OR_4756 } from '../../constants/global';
import { useGetSetSubvenedRcfsQuery } from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';

const useGetSETSubvenedRcfs = () => {
  const { period } = useUrlParams();

  const {
    rcfsResidualsStore: { subvenedLeaseRcfs, setSubvenedLeaseRcfs },
    userInfoStore: { isSETUser },
    programDetailsStore: { seriesProfile },
  } = useStores();

  const seriesProfileModelCodes = seriesProfile.vehicles.map(vehicle => Number(vehicle.modelCode));

  const { data } = useGetSetSubvenedRcfsQuery({
    variables: { offeringId: period, seriesYear: Number(seriesProfile.series[0].seriesYear), series: seriesProfile.series[0].series },
    skip:
      !isSETUser() || !FEATURE_OR_4756 || (isSETUser() && FEATURE_OR_4756 && seriesProfileModelCodes.filter(mdCode => subvenedLeaseRcfs.modelCodes?.includes(mdCode)).length > 0),
  });

  if (data && data.setSubvenedRcfs) {
    setSubvenedLeaseRcfs(data.setSubvenedRcfs);
  }
};

export default useGetSETSubvenedRcfs;
