import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import NumberInput from '../../../../../../../components/NumberInput';
import PenRateModel from '../../../../../models/PenRateModel';
import styles from '../styles.module.scss';
import { handleTableValueRender } from '../utils';

interface Props {
  aprModel: PenRateModel;
  inputIsDisabled: boolean;
  onSave?: () => void;
}

const ExpAprTableValues = ({ aprModel, inputIsDisabled, onSave }: Props) => {
  const { aprDetails, nationalAprDetails, penetration, forecastedSales, estimatedCost, tier, updatePenRate } = aprModel;

  const handleOnChange = (val: string) => {
    updatePenRate(val);

    if (onSave) {
      onSave();
    }
  };

  return (
    <tr className={clsx(styles.offerRow, styles.detailsRow)}>
      <td className={clsx(styles.expTableTd, styles.expTableTdValue)}>Tier {tier}</td>
      {aprDetails.map((aprDetail, i) => {
        const nationalRate = nationalAprDetails.find(natApr => natApr.term === aprDetail.term)?.rate;

        return (
          <td key={`${aprDetail.rate}-${i}`} className={styles.expTableTd}>
            {handleTableValueRender(aprDetail.isEnhanced, `${aprModel.id}-rate-enhanced`, aprDetail.rate.toString(), nationalRate?.toString() || '')}
          </td>
        );
      })}
      <td className={styles.expTableTd}>${estimatedCost}</td>
      <td className={styles.expTableTd}>
        <NumberInput value={penetration} percentageSign onChange={e => handleOnChange(e.target.value)} className={styles.penRateInput} disabled={inputIsDisabled} />
      </td>
      <td className={styles.expTableTd}>{forecastedSales}</td>
    </tr>
  );
};

export default observer(ExpAprTableValues);
