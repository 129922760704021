import { observer } from 'mobx-react-lite';
import { isFlatCostOfferType } from 'oat-admin-common';
import { formatDollarsCents } from 'oat-common-ui';
import { ReactNode } from 'react';
import PenRateModel from '../../../pages/Summary/models/PenRateModel';
import RgnlAltModel from '../../../pages/Summary/models/RgnlAltModel';
import OfferDetailsCell from '../cells/OfferDetailsCell';
import PenRateCell from '../cells/PenRateCell';
import SalesCell from '../cells/SalesCell';
import styles from '../styles.module.scss';

export interface PenRateTableRowProps {
  rgnlAlt: RgnlAltModel;
  item: PenRateModel;
  editPenRate: boolean;
  offerDetails: ReactNode;
  offerStyles?: string;
  currentOfferId?: string;
  onSave?: () => void;
}

const PenRateTableRow = ({ rgnlAlt, item, editPenRate, offerDetails, offerStyles, onSave }: PenRateTableRowProps) => {
  return (
    <tr>
      {/* Prior pen rates - only show data if it's carry over */}
      <td className={styles.historicalRate}>{Boolean(item.priorPenRate3) && `Pen - ${item.priorPenRate3}%`}</td>
      <td className={styles.historicalRate}>{Boolean(item.priorPenRate2) && `Pen - ${item.priorPenRate2}%`}</td>
      <td className={styles.historicalRate}>{Boolean(item.priorPenRate1) && `Pen - ${item.priorPenRate1}%`}</td>

      {/* Pen Rate input */}
      <PenRateCell rgnlAlt={rgnlAlt} penRate={item} editPenRate={editPenRate} onSave={onSave} isDisabled={isFlatCostOfferType(item.optionTypeName)} />

      {/* Offer */}
      <OfferDetailsCell
        isDisabled={isFlatCostOfferType(item.optionTypeName)}
        offerDetails={offerDetails}
        offerStyles={offerStyles}
        onSave={onSave}
        penRate={item}
        rgnlAlt={rgnlAlt}
      />

      {/* Forecasted Sales */}
      <SalesCell rgnlAlt={rgnlAlt} penRate={item} editPenRate={editPenRate} onSave={onSave} isDisabled={isFlatCostOfferType(item.optionTypeName)} />

      {/* Calculated Estimated Cost */}
      <td>
        ${formatDollarsCents(item.offerCost)}
        {item.subCash > 0 && ` + $${formatDollarsCents(item.subCashOfferCost)}`}
      </td>
    </tr>
  );
};

export default observer(PenRateTableRow);
