import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { BlockInputLabel, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, OATIcon, useToast } from 'oat-common-ui';
import { ChangeEvent } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { ContestNumberInput, SetContestNumbers, useSaveSetContestNumbersMutation } from '../../../gql/generated';
import SETContestNumber, { SETContestNumbersByType } from '../../../models/SETContestNumber';
import useStores from '../../../stores/useStores';
import { invalidInput, xformSETContestNumbersResponse } from '../../../utils/buildContestNumbers';
import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  offeringId: string;
}

const ContestNumbersModal = ({ onClose, offeringId }: Props) => {
  const {
    summaryStore: { addContestNumber, contestNumbersByType, deleteContestNumber, setSETContestNumbersByType },
  } = useStores();
  const [saveSetContestNumbers] = useSaveSetContestNumbersMutation();
  const { error } = useToast();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, contestNumber: SETContestNumber) => {
    contestNumber.hasError = false;
    if (invalidInput(contestNumbersByType, e.currentTarget.value)) {
      contestNumber.hasError = true;
    } else {
      contestNumber.number = e.currentTarget.value;
    }
  };

  const handleSubmit = async () => {
    try {
      const allContestNumbers: ContestNumberInput[] = [];
      contestNumbersByType.forEach((type: SETContestNumbersByType) => {
        type.numbers.forEach(obj => {
          allContestNumbers.push({
            id: !obj.id ? undefined : obj.id,
            rev: !obj.rev ? undefined : obj.rev,
            isDefault: obj.isDefault,
            optionTypeName: obj.optionTypeName,
            number: obj.number,
          });
        });
      });
      const resp = await trackPromise(saveSetContestNumbers({ variables: { input: { offeringId, contestNumbers: allContestNumbers } } }));
      const xransformed = xformSETContestNumbersResponse(resp.data?.saveSetContestNumbers.setContestNumbers as SetContestNumbers[], contestNumbersByType);
      setSETContestNumbersByType(xransformed);
      onClose();
    } catch (e) {
      error((e as Error).message);
    }
  };

  const disableSubmit = contestNumbersByType.filter((item: SETContestNumbersByType) => item.numbers.filter(number => number.number === '' || number.hasError).length).length > 0;

  return (
    <Modal isOpen onClose={() => onClose()} size="md">
      <ModalHeader className={styles.header} onClose={() => onClose()}>
        Contest Numbers
      </ModalHeader>
      <ModalBody className={styles.body}>
        <div className={styles.offerWrapper}>
          {contestNumbersByType.map((type, ind) => (
            <div className={styles.offerWrapper} key={type.optionType}>
              {type.numbers?.map((contestNumber: SETContestNumber, index: number) => (
                <span key={contestNumber.uid}>
                  {contestNumber && (
                    <BlockInputLabel
                      label={contestNumber.isDefault ? `${contestNumber.optionTypeName} Default` : `${contestNumber.optionTypeName} ${index}`}
                      labelClass={styles.inputLabel}
                      className={styles.inputContainer}
                    >
                      <Input
                        error={!contestNumber.number || contestNumber.hasError}
                        className={clsx(ind % 2 === 0 && contestNumber.number && !contestNumber.hasError && styles.dark)}
                        defaultValue={contestNumber.number}
                        onChange={e => handleInputChange(e, contestNumber)}
                      />
                      {index !== 0 && <OATIcon icon="trash" colorTheme="blue" className={styles.delete} onClick={() => deleteContestNumber(contestNumber)} />}
                      {type.numbers?.length - 1 === index && (
                        <OATIcon icon="plus" colorTheme="blue" className={styles.plusIcon} onClick={() => addContestNumber(type, offeringId)} />
                      )}
                    </BlockInputLabel>
                  )}
                </span>
              ))}
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="text" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={disableSubmit}>
          Save Contest Numbers
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default observer(ContestNumbersModal);
