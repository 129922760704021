import { ReactNode } from 'react';
import { TooltipListItem } from '../../../../components/TooltipList/TooltipList';

interface Props {
  headerText: string;
  body: ReactNode;
  showHeaderText?: boolean;
  className?: string;
  bodyClassName?: string;
}

const EnhTooltipListItem = ({ headerText, body, showHeaderText = true, className, bodyClassName }: Props) => {
  return (
    <TooltipListItem className={className}>
      <div>{showHeaderText && headerText}</div>
      <div className={bodyClassName}>{body}</div>
    </TooltipListItem>
  );
};

export default EnhTooltipListItem;
