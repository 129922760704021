import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { CreateLeaseModels, EnterVinFlow } from 'oat-common-ui';
import useUrlParams from '../../../../../hooks/useUrlParams';
import useStores from '../../../../../stores/useStores';
import useUserInfo from '../../../../../utils/useUserInfo';
import getConfigListFromVinItem from '../utils/getConfigListFromVinItem';
import setStopSaleData from '../utils/setStopSaleData';
import { mapToPortsMap } from '../utils/utils';

const { RegionCodes } = AdminConstants;

const EnterVin = () => {
  const {
    createLeaseStore: { advertisedData },
    userInfoStore: {
      userInfo: { brand },
    },
    efcStore,
    dd365Store: { getDD365VinDetailsItem },
  } = useStores();
  const { region } = useUrlParams();
  const { getEfcVinDetailsItem, getStopSaleSSC, getStopSaleVIS } = efcStore;
  const { isLexusUser } = useUserInfo();

  const handleVinSearch = async () => {
    try {
      // get vin details data
      const vinQuery = advertisedData.enterVinFields.vinQuery;
      const vinItem = region === RegionCodes.SET ? await getDD365VinDetailsItem(vinQuery, brand) : await getEfcVinDetailsItem(vinQuery, brand);

      // get SSC data and inventory (if model code is different)
      const details: [Promise<CreateLeaseModels.EFCStopSaleSSC | undefined>, Promise<CreateLeaseModels.EFCStopSaleVIS | undefined>] = [
        getStopSaleSSC(vinItem.vin, brand),
        getStopSaleVIS(vinItem.vin, brand),
      ];
      const responses = await Promise.all(details);

      // process SSC data in vinList
      const stopSaleSSC = responses[0];
      const stopSaleVIS = responses[1];
      setStopSaleData(vinItem, stopSaleSSC, stopSaleVIS);
      advertisedData.setVinList([vinItem]);

      // set config list from vin detail call
      advertisedData.setConfigList(getConfigListFromVinItem(vinItem));

      advertisedData.setEnterVinStep(2);
    } catch (e) {
      advertisedData.setError({
        name: 'No vin found',
        message: 'No VIN found. Please try another VIN.',
      });
    }
  };

  const handleVinSearchReset = () => {
    advertisedData.setVinQuery('');
    advertisedData.setVinList([]);
  };

  return (
    <EnterVinFlow
      vinList={advertisedData.enterVinFields.vinList}
      vinQuery={advertisedData.enterVinFields.vinQuery}
      hasVinQueryError={advertisedData.hasVinQueryError}
      showVinList={advertisedData.showVinList()}
      handleSelectVin={advertisedData.selectVin}
      portsMap={mapToPortsMap}
      handleVinSearchReset={handleVinSearchReset}
      handleVinSearch={handleVinSearch}
      onVinQueryChange={advertisedData.setVinQuery}
      isSETUser={false}
      isLexusUser={isLexusUser()}
      useSrpLabelLogic={false}
      isNationalApp
      showNoVehicles={advertisedData.error?.message}
      sortCol={advertisedData.sortField}
      sortAsc={advertisedData.sortAscending}
      handleSortColumns={advertisedData.sortVinList}
    />
  );
};

export default observer(EnterVin);