import { observer } from 'mobx-react-lite';
import { Button, OATIcon } from 'oat-common-ui';
import ProfileTitle from '../../../../components/ProfileTitle';
import { Status } from '../../../../constants/global';
import ProfileModel from '../../models/ProfileModel';
import styles from './styles.module.scss';

interface Props {
  profile: ProfileModel;
}

const ProfileHeader = ({ profile }: Props) => {
  const selectedRa = profile.getSelectedRgnAlt();

  return (
    <header className={styles.profileHeader}>
      <div className={styles.flexLeft}>
        <h3 className={styles.leftText}>{profile.name}</h3>
        <ProfileTitle selectedRa={selectedRa} />
      </div>
      <div className={styles.flexRight}>
        <div>
          {!profile.addNote && profile.isExpanded && (
            <Button variant="text" id={`${profile.id}-add-note-btn`} onClick={() => profile.setAddNote(true)} className={styles.iconBtn}>
              <OATIcon icon="notes" colorTheme="blue" />
              <span className={styles.addNoteText}>Add a Note</span>
            </Button>
          )}
          {!profile.isExpanded && (
            <div className={styles.profileStatus}>
              {selectedRa?.status === Status.MEETS_GUIDELINES && <OATIcon icon="check-mark" colorTheme="green" />}
              {selectedRa?.status === Status.REVISED && <OATIcon icon="final-incentive" colorTheme="orange" />}
              <span className={styles.statusText}>{selectedRa?.status}</span>
            </div>
          )}
        </div>
        {profile.inputIsDisabled && <div className={styles.divider} />}
        {!profile.isExpanded && (
          <Button onClick={() => profile.setIsExpanded(true)} className={styles.profileBtn}>
            <OATIcon icon="expand" colorTheme="blue" />
          </Button>
        )}
        {profile.isExpanded && profile.inputIsDisabled && (
          <Button onClick={() => profile.setInputIsDisabled(false)} className={styles.profileBtn}>
            <OATIcon icon="edit" colorTheme="blue" />
          </Button>
        )}
      </div>
    </header>
  );
};

export default observer(ProfileHeader);
