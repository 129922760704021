import { observer } from 'mobx-react-lite';
import { isFlatCostOfferType } from 'oat-admin-common';
import { OATIcon } from 'oat-common-ui';
import PenRateModel from '../../../pages/Summary/models/PenRateModel';
import OfferDetailsCell from '../cells/OfferDetailsCell';
import PenRateCell from '../cells/PenRateCell';
import SalesCell from '../cells/SalesCell';
import { PenRateTableRowProps } from '../PenRateTableRow';

// This is for the '>' icon
const offerSelected = (item: PenRateModel, currentOfferId = '') => {
  if (currentOfferId.includes(item.id)) {
    if (item.term) {
      const term = currentOfferId.split('#');
      return term[1] && term[1] === item.term;
    }

    if (item.tier) {
      const tier = currentOfferId.split('#');
      return tier[1] && tier[1] === item.tier;
    }

    return true;
  }

  return false;
};

const PenRateModalTableRow = ({ rgnlAlt, currentOfferId, item, editPenRate, offerDetails, offerStyles }: PenRateTableRowProps) => {
  return (
    <tr>
      <td>{offerSelected(item, currentOfferId) && <OATIcon icon="angle-right" />}</td>
      {/* Offer */}
      <OfferDetailsCell rgnlAlt={rgnlAlt} penRate={item} offerDetails={offerDetails} offerStyles={offerStyles} isModalView isDisabled={isFlatCostOfferType(item.optionTypeName)} />

      {/* Pen Rate input */}
      <PenRateCell rgnlAlt={rgnlAlt} penRate={item} editPenRate={editPenRate} isDisabled={isFlatCostOfferType(item.optionTypeName)} />

      {/* Forecasted Sales */}
      <SalesCell rgnlAlt={rgnlAlt} penRate={item} editPenRate={editPenRate} isDisabled={isFlatCostOfferType(item.optionTypeName)} />
    </tr>
  );
};

export default observer(PenRateModalTableRow);
