import { dateFormatISOString, MonthShortNamesConstant } from 'oat-common-ui';
import getDateParts from './getDateParts';

export const returnFormattedDateValues = (startDate: string, endDate: string) => {
  const { day: startDateDay, month: startDateMonth, year: startDateYear } = getDateParts(dateFormatISOString(startDate));
  const { day: endDateDay, month: endDateMonth, year: endDateYear } = getDateParts(dateFormatISOString(endDate));

  return {
    startDateMonth: MonthShortNamesConstant[Number(startDateMonth)],
    startDateDay,
    startDateYear,
    endDateMonth: MonthShortNamesConstant[Number(endDateMonth)],
    endDateDay,
    endDateYear,
  };
};
