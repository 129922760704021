import { makeAutoObservable } from 'mobx';
import { CreateLeaseModels, sortFieldsHelper } from 'oat-common-ui';
import AccordionModel from '../../../../components/Accordion/models/AccordionModel';
import validator from '../../../../utils/validator';
import MostRecentConfig from './MostRecentConfig';
import TermItem from './TermItem';

export class Fields {
  trimQuery = '';
  configQuery = '';
  vinQuery = '';
  dealerCodeQuery = '';
  trim: CreateLeaseModels.TrimItem | undefined = undefined;
  config: CreateLeaseModels.ConfigItem | undefined = undefined;
  configList: CreateLeaseModels.ConfigItem[] = [];
  vin: CreateLeaseModels.VinItem | undefined = undefined;
  vinList: CreateLeaseModels.VinItem[] = [];
  blended = true;
  exclusions = [];
  carJelly = '';
  selectedTermsList: string[] = [];
  selectedTier = '1+';
  selectedTiers = ['1+'];
  terms: TermItem[] = [];
  vehiclesAccordion = new AccordionModel();
  editVehicles = false;
  isInclusions = false;
  name = 'Lease';
  pnv = 0;
  tfsCostShare = 20;
  note = '';
  tdaNote = '';
  isSpecialEdition = false;

  constructor() {
    makeAutoObservable(this);
  }
}

class CreateAdLeaseModel {
  tab = 1;
  step = 1;
  enterVinStep = 1;
  trimList: CreateLeaseModels.TrimItem[] = [];
  gStatusOnly = false;
  tiers = [];
  sortField = '';
  sortAscending = false;
  series = '';
  seriesYear = '';
  error: Error | undefined = undefined;
  gStatusFiltered = false;
  selectTrimFields = {} as Fields;
  enterVinFields = {} as Fields;
  mostRecentConfig: MostRecentConfig | undefined = undefined;
  hideTiersTerms = false;

  constructor() {
    makeAutoObservable(this);
  }

  setTab = (tab: number) => {
    this.tab = tab;
  };

  setTrimQuery = (query: string) => {
    this.selectTrimFields.trimQuery = query;
  };

  setConfigQuery = (query: string) => {
    this.selectTrimFields.configQuery = query;
  };

  showTrimTable = () => this.tab === 1 && this.step === 1;
  showConfigTable = () => this.tab === 1 && this.step === 2;
  showVinTable = () => this.tab === 1 && this.step === 3;
  showVinList = () => this.enterVinStep < 3;
  showSelectTrimFlow = () => this.tab === 1 || this.enterVinStep === 3;
  showEnterVinFlow = () => this.tab === 2 && this.enterVinStep < 3;
  showFinalSelectionFlow = () => (this.tab === 1 && this.step === 4) || (this.tab === 2 && this.enterVinStep === 3);

  get hasVinQueryError() {
    return validator(this.enterVinFields.vinQuery, { required: true }) !== undefined;
  }

  get hasInclusionsError() {
    const { isInclusions, vehiclesAccordion } = this.getDetails();
    return (isInclusions && vehiclesAccordion.isAllSelected(false)) || (!isInclusions && vehiclesAccordion.isAllSelected(true));
  }

  setVinQuery = (query: string) => {
    this.enterVinFields.vinQuery = query;
  };

  setDealerCodeQuery = (query: string) => {
    this.selectTrimFields.dealerCodeQuery = query;
  };

  setTrimList = (trimList: CreateLeaseModels.TrimItem[], series: string, seriesYear: string) => {
    this.error = undefined;
    this.series = series;
    this.seriesYear = seriesYear;
    this.trimList = trimList;
  };

  setSortField = (sortField: string) => {
    if (sortField !== this.sortField) {
      this.sortAscending = false;
    }

    this.sortAscending = !this.sortAscending;
    this.sortField = sortField;
  };

  sortTrimList = (field: string) => {
    this.setSortField(field);
    this.trimList = this.trimList.slice().sort(sortFieldsHelper(this.sortField, this.sortAscending));
  };

  sortConfigList = (field: string) => {
    this.setSortField(field);
    this.selectTrimFields.configList = this.selectTrimFields.configList.slice().sort(sortFieldsHelper(this.sortField, this.sortAscending));
  };

  sortVinList = (field: string) => {
    this.setSortField(field);
    this.selectTrimFields.vinList = this.selectTrimFields.vinList.slice().sort(sortFieldsHelper(this.sortField, this.sortAscending));
  };

  setConfigList = (configList: CreateLeaseModels.ConfigItem[]) => {
    this.error = undefined;
    this.gStatusFiltered = false;
    if (this.tab === 1) {
      this.selectTrimFields.configList = configList;
    } else {
      this.enterVinFields.configList = configList;
      this.enterVinFields.trim = this.trimList.filter(trim => trim.code === this.enterVinFields.vinList[0].modelCode)[0];
      this.enterVinFields.config = this.enterVinFields.configList[0];
    }
  };

  setVinList = (vinList: CreateLeaseModels.VinItem[]) => {
    if (this.tab === 1) {
      this.selectTrimFields.vinList = vinList;
    } else {
      this.enterVinFields.vinList = vinList;
    }

    this.error = undefined;
    this.gStatusFiltered = false;
  };

  setMostRecentConfig = (mostRecentConfig: MostRecentConfig) => {
    this.mostRecentConfig = mostRecentConfig;
  };

  clearMostRecentConfig = () => {
    this.mostRecentConfig = undefined;
  };

  findConfig = (modelCode: string) => {
    return this.selectTrimFields.configList.find(config => config.modelCode === modelCode);
  };

  selectTrim = (trim: CreateLeaseModels.TrimItem) => {
    this.selectTrimFields.trim = trim;
    this.step += 1;
  };

  selectConfig = (config: CreateLeaseModels.ConfigItem) => {
    this.selectTrimFields.config = config;
    this.step += 1;
  };

  selectVin = (vin: CreateLeaseModels.VinItem) => {
    if (this.tab === 1) {
      this.selectTrimFields.vin = vin;
      this.step += 1;
    } else {
      this.enterVinFields.vin = vin;
      this.enterVinStep += 1;
    }
  };

  setCarJelly = (carJelly: string) => {
    this.selectTrimFields.carJelly = carJelly;
  };

  setGstatusFiltered = () => {
    this.gStatusFiltered = !this.gStatusFiltered;
  };

  setGstatusFilterDefault = () => {
    this.gStatusFiltered = false;
  };

  setStep = (step: number) => {
    this.step = step;
    if (step === 1) {
      this.selectTrimFields.trim = undefined;
    }

    if (step <= 2) {
      this.selectTrimFields.config = undefined;
    }

    if (step <= 3) {
      this.selectTrimFields.vin = undefined;
    }
  };

  setEnterVinStep = (step: number) => {
    this.enterVinStep = step;
  };

  selectTier = (value: string) => {
    if (this.tab === 1) {
      this.selectTrimFields.selectedTier = value;
    } else {
      this.enterVinFields.selectedTier = value;
    }
  };

  setSelectedTerms = (termsList: string[]) => {
    if (this.tab === 1) {
      this.selectTrimFields.selectedTermsList = termsList;
      this.selectTrimFields.terms = termsList.map(term => ({ term: term, isAdvertised: true }));
    } else {
      this.enterVinFields.selectedTermsList = termsList;
      this.enterVinFields.terms = termsList.map(term => ({ term: term, isAdvertised: true }));
    }
  };

  setTiersBlended = (tiersList: string[]) => {
    const indexOfNewlySelected = tiersList.indexOf(this.tab === 1 ? this.selectTrimFields.selectedTier : this.enterVinFields.selectedTier) + 1;

    if (this.tab === 1) {
      this.selectTrimFields.selectedTiers = tiersList.slice(0, indexOfNewlySelected);
    } else {
      this.enterVinFields.selectedTiers = tiersList.slice(0, indexOfNewlySelected);
    }
  };

  setTiersByTier = (tiersList: string[]) => {
    if (this.tab === 1) {
      this.selectTrimFields.selectedTiers = tiersList;
    } else {
      this.enterVinFields.selectedTiers = tiersList;
    }
  };

  setSelectedTiers = (tiersList: string[], isBlended = true) => {
    if (isBlended) {
      this.setTiersBlended(tiersList);
    } else {
      this.setTiersByTier(tiersList);
    }
  };

  setOption = (value: boolean) => {
    if (this.tab === 1) {
      this.selectTrimFields.blended = value;
    } else {
      this.enterVinFields.blended = value;
    }
  };

  setEditVehicles = (value: boolean) => {
    if (this.tab === 1) {
      this.selectTrimFields.editVehicles = value;
    } else {
      this.enterVinFields.editVehicles = value;
    }
  };

  setHideTiersTerms = (isHidden: boolean) => {
    this.hideTiersTerms = isHidden;
  };

  toggleIsInclusions = () => {
    if (this.tab === 1) {
      this.selectTrimFields.isInclusions = !this.selectTrimFields.isInclusions;
    } else {
      this.enterVinFields.isInclusions = !this.enterVinFields.isInclusions;
    }
  };

  backToTrimSelectionStep = () => {
    this.setTrimQuery('');
    this.setDealerCodeQuery('');
    this.setStep(1);
    this.setError();
    this.setGstatusFilterDefault();
    this.setConfigList([]);
    this.setVinList([]);
  };

  backToConfigSelectionStep = () => {
    this.setConfigQuery('');
    this.setDealerCodeQuery('');
    this.setStep(2);
    this.setError();
    this.setGstatusFilterDefault();
    this.setVinList([]);
  };

  clearDealerCode = () => {
    this.setDealerCodeQuery('');
    this.setGstatusFilterDefault();
  };

  setError = (e?: Error) => {
    if (this.error && e === undefined) {
      this.error = undefined;
      return;
    }

    this.error = e;
  };

  getDetails = () => {
    return this.tab === 1 ? this.selectTrimFields : this.enterVinFields;
  };

  resetAll = () => {
    if (this.tab === 1) {
      this.initSelectTrim();
    } else {
      this.initEnterVin();
    }
  };

  vinSelection = () => {
    if (this.tab === 1) {
      this.setStep(3);
    } else {
      this.setEnterVinStep(2);
    }
  };

  updateIsSpecialEdition = (val: boolean) => {
    this.getFieldObj().isSpecialEdition = val;
  };

  getFieldObj = () => {
    return this.tab === 1 ? this.selectTrimFields : this.enterVinFields;
  };

  initSelectTrim = () => {
    this.step = 1;
    this.error = undefined;
    this.selectTrimFields.trimQuery = '';
    this.selectTrimFields.configQuery = '';
    this.selectTrimFields.vinQuery = '';
    this.selectTrimFields.dealerCodeQuery = '';
    this.selectTrimFields.trim = undefined;
    this.selectTrimFields.config = undefined;
    this.selectTrimFields.configList = [];
    this.selectTrimFields.vin = undefined;
    this.selectTrimFields.vinList = [];
    this.selectTrimFields.selectedTier = '1+';
    this.selectTrimFields.selectedTiers = ['1+'];
    this.selectTrimFields.selectedTermsList = [];
    this.selectTrimFields.blended = true;
    this.selectTrimFields.exclusions = [];
    this.selectTrimFields.carJelly = '';
    this.selectTrimFields.terms = [];
    this.selectTrimFields.vehiclesAccordion = new AccordionModel();
    this.selectTrimFields.editVehicles = false;
    this.selectTrimFields.isInclusions = false;
    this.selectTrimFields.name = 'Lease';
    this.selectTrimFields.pnv = 0;
    this.selectTrimFields.tfsCostShare = 20;
    this.selectTrimFields.note = '';
    this.selectTrimFields.tdaNote = '';
  };

  initEnterVin = () => {
    this.enterVinStep = 1;
    this.error = undefined;
    this.enterVinFields.trimQuery = '';
    this.enterVinFields.configQuery = '';
    this.enterVinFields.vinQuery = '';
    this.enterVinFields.dealerCodeQuery = '';
    this.enterVinFields.trim = undefined;
    this.enterVinFields.config = undefined;
    this.enterVinFields.configList = [];
    this.enterVinFields.vin = undefined;
    this.enterVinFields.vinList = [];
    this.enterVinFields.selectedTier = '1+';
    this.enterVinFields.selectedTiers = ['1+'];
    this.enterVinFields.selectedTermsList = [];
    this.enterVinFields.blended = true;
    this.enterVinFields.exclusions = [];
    this.enterVinFields.carJelly = '';
    this.enterVinFields.terms = [];
    this.enterVinFields.vehiclesAccordion = new AccordionModel();
    this.enterVinFields.editVehicles = false;
    this.enterVinFields.isInclusions = false;
    this.enterVinFields.name = 'Lease';
    this.enterVinFields.pnv = 0;
    this.enterVinFields.tfsCostShare = 20;
    this.enterVinFields.note = '';
    this.enterVinFields.tdaNote = '';
  };
}

export default CreateAdLeaseModel;