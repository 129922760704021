import { ReactNode } from 'react';
import {
  ResidualValue,
  SetResidualValue,
  SetStandardRcf,
  SetSubvenedRcf,
  useGetNationalRcfsAndResidualsQuery,
  useGetSetNationalRcfsAndResidualsQuery,
  useGetSetSubvenedRcfsQuery,
} from '../../gql/generated';
import StdLeaseRcfs from '../../models/StdLeaseRcfs';
import LoadingPage from '../../pages/LoadingPage';
import useUserInfo from '../../utils/useUserInfo';
import IpBreadCrumbs from '../IpComponents/IpBreadCrumbs';
import { FEATURE_OR_4756 } from '../../constants/global';

interface Props {
  brand: string;
  offeringYear: string;
  offeringMonth: string;
  offeringId: string;
  children: ReactNode;
  onLoad: (stdLeaseRcfs: StdLeaseRcfs[], residualValues: ResidualValue[]) => void;
  onSETLoad: (stdLeaseRcfs: SetStandardRcf[], residualValues: SetResidualValue[]) => void;
  onSETLoadSubRcfs: (subRcfs: SetSubvenedRcf) => void;
  seriesName: string;
  seriesYear: string;
  modelCodes: number[];
}

const GetRcfsAndResidualsData = ({ brand, offeringYear, offeringMonth, offeringId, children, onLoad, onSETLoad, onSETLoadSubRcfs, seriesName, seriesYear, modelCodes }: Props) => {
  const { isSETUser } = useUserInfo();
  const { loading, error, data } = useGetNationalRcfsAndResidualsQuery({
    variables: {
      brand: brand,
      offeringYear,
      offeringMonth,
    },
    skip: isSETUser(),
  });

  const {
    loading: SETLoading,
    error: SETError,
    data: SETData,
  } = useGetSetNationalRcfsAndResidualsQuery({
    variables: {
      offeringId,
    },
    skip: !isSETUser(),
  });

  const {
    data: setRcfsData,
    error: setRcfsError,
    loading: setRcfsLoading,
  } = useGetSetSubvenedRcfsQuery({
    variables: {
      offeringId,
      seriesYear: Number(seriesYear),
      series: seriesName,
      modelCodes,
    },
    skip: (!isSETUser() && FEATURE_OR_4756) || !FEATURE_OR_4756,
  });

  if (loading || error || SETLoading || SETError || setRcfsError || setRcfsLoading) {
    return <LoadingPage breadCrumbs={IpBreadCrumbs(false)} error={Boolean(error) || Boolean(SETError)} />;
  }

  if (data) {
    onLoad(data.stdLeaseRcfs as StdLeaseRcfs[], data.residualValues as ResidualValue[]);
  }

  if (SETData) {
    onSETLoad(SETData.setStandardRcfs as SetStandardRcf[], SETData.setResidualValues as SetResidualValue[]);
  }

  if (setRcfsData) {
    onSETLoadSubRcfs(setRcfsData.setSubvenedRcfs as SetSubvenedRcf);
  }

  return <>{children}</>;
};

export default GetRcfsAndResidualsData;
