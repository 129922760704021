import { AdminConstants } from 'oat-admin-common';

const { RegionCodes, BRAND_LEXUS, TOYOTA_SET_AC_FEE, TOYOTA_AC_FEE, LEXUS_AC_FEE } = AdminConstants;

const getDefaultAcFee = (brand: string, region: string) => {
  if (brand === BRAND_LEXUS) {
    return LEXUS_AC_FEE;
  }

  return region === RegionCodes.SET ? TOYOTA_SET_AC_FEE : TOYOTA_AC_FEE;
};

export default getDefaultAcFee;
