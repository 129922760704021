import { AdminConstants } from 'oat-admin-common';
import { BreadCrumbType } from 'oat-common-ui';
import { Brand } from '../../constants/global';
import { Offering, useGetOfferingsByRegionActiveQuery } from '../../gql/generated';
import useStores from '../../stores/useStores';
import LoadingPage from '../LoadingPage';
import ReportsComponent from './ReportsComponent';
import { createDropdownItemFromRegions } from './utils/createDropdownItemFromRegions';

interface Props {
  isNationalPlanning?: boolean;
}
const ReportsPage = ({ isNationalPlanning = false }: Props) => {
  const { reportsStore, userInfoStore } = useStores();
  const regionCode = userInfoStore.getRegionCode();
  const breadCrumbs: BreadCrumbType[] = [{ name: userInfoStore.getRegionNameFull() }];
  const { REGIONS } = AdminConstants;
  const availableRegions = REGIONS.filter(region => (userInfoStore.isLexus() ? region.brand === Brand.LEXUS : region.brand === Brand.TOYOTA));
  const convertedRegions = createDropdownItemFromRegions(availableRegions);

  const { data, loading, error } = useGetOfferingsByRegionActiveQuery({
    variables: { regionCode, includeCosts: !userInfoStore.isNational() },
  });

  if (loading || error) {
    return <LoadingPage breadCrumbs={breadCrumbs} error={!!error} />;
  }

  if (data) {
    reportsStore.setIsNational(userInfoStore.isNational());
    reportsStore.setAvailableIpList([...(data.active as Offering[]), ...(data.archived as Offering[])]);
    reportsStore.setSelectedIp(reportsStore.availableIpList[0]);
    reportsStore.setAvailableRegions(convertedRegions);
    reportsStore.setSelectedRegion(convertedRegions[0]);
  }

  return <ReportsComponent isNationalPlanning={isNationalPlanning} />;
};

export default ReportsPage;
