import { DEFAULT_TIERS } from '../../../../constants/global';
import PenRateModel from '../PenRateModel';
import RgnlAltModel from '../RgnlAltModel';

const sortRgnlAltOffers = (rgnlAlt: RgnlAltModel) => {
  // sort APR
  const aprs: PenRateModel[] = [];
  DEFAULT_TIERS.forEach(tier => {
    rgnlAlt.apr.forEach(apr => {
      if (apr.tier === tier) {
        aprs.push(apr);
      }
    });
  });

  rgnlAlt.apr = aprs;

  // sort lease
  rgnlAlt.lease = rgnlAlt.lease.slice().sort((a, b) => Number(a.term) - Number(b.term));
};

export default sortRgnlAltOffers;
