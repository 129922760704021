import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import SettingsMenu from '../../../../components/SettingsMenu';
import { Status } from '../../../../constants/global';
import useStores from '../../../../stores/useStores';
import CashCardModel from '../../models/CashCardModel';
import AdvertiseOfferModal from '../AdvertiseOfferModal';
import EnhanceAndRefineModal from '../EnhanceAndRefineModal';
import PenRatesModal from '../PenRatesModal';
import RemoveOfferModal from '../RemoveOfferModal';
import ResetToNationalModal from '../ResetToNationalModal';
import ResetToPrevRegionalOffer from '../ResetToPrevRegionalModal';
import { ItemAdjustPenRates, ItemAdvertised, ItemEnhanceAndRefine, ItemRemoveOffer, ItemResetPrevRegionalOffer, ItemResetToNational } from '../contextMenuItems';

interface Props {
  cashCard: CashCardModel;
}

const CashContextMenu = ({ cashCard }: Props) => {
  const [showAdvertisedModal, setShowAdvertisedModal] = useState(false);
  const [showRemoveOfferModal, setShowRemoveOfferModal] = useState(false);
  const [showPenRatesModal, setShowPenRatesModal] = useState(false);
  const [showResetToNationalModal, setShowResetToNationalModal] = useState(false);
  const [showResetToPrevRegionalOffer, setShowResetToPrevRegionalOffer] = useState(false);
  const [showEnhanceAndRefineModal, setShowEnhanceAndRefineModal] = useState(false);
  const [offerId, setOfferId] = useState(cashCard.id);
  const {
    programDetailsStore: { removeCashOffer, offering, seriesProfile },
    seriesMappingStore: { seriesMapping },
  } = useStores();

  return (
    <>
      {/* Context Menu */}
      <SettingsMenu isDisabled={offering.status === Status.MEETS_GUIDELINES || cashCard.isDisabled}>
        {!cashCard.isStandAlone && <ItemResetToNational onClick={() => setShowResetToNationalModal(true)} />}
        <ItemResetPrevRegionalOffer onClick={() => setShowResetToPrevRegionalOffer(true)} />
        {!cashCard.isDuplicate && <ItemEnhanceAndRefine onClick={() => setShowEnhanceAndRefineModal(true)} />}
        <ItemAdvertised advertised={cashCard.isAdvertised} onClick={() => setShowAdvertisedModal(true)} />
        <ItemAdjustPenRates
          onClick={() => {
            setShowPenRatesModal(true);
            setOfferId(cashCard.id);
          }}
        />
        {(cashCard.isStandAlone || cashCard.isDuplicate) && <ItemRemoveOffer onClick={() => setShowRemoveOfferModal(true)} />}
      </SettingsMenu>

      {/* Modals */}
      {showPenRatesModal && <PenRatesModal currentOfferId={offerId} onClose={() => setShowPenRatesModal(false)} />}
      {showAdvertisedModal && (
        <AdvertiseOfferModal
          id={cashCard.id}
          rev={cashCard.rev}
          isAdvertised={cashCard.isAdvertised}
          onClose={() => {
            setShowAdvertisedModal(false);
          }}
          onSubmit={offer => {
            cashCard.updateAdvertised();
            cashCard.updateRev(offer.rev);
          }}
        />
      )}
      {showRemoveOfferModal && (
        <RemoveOfferModal
          id={cashCard.id}
          rev={cashCard.rev}
          onClose={() => {
            setShowRemoveOfferModal(false);
          }}
          onSubmit={() => {
            removeCashOffer(cashCard.id, cashCard.rev);
          }}
        />
      )}
      {showResetToNationalModal && (
        <ResetToNationalModal
          id={cashCard.id}
          onClose={() => setShowResetToNationalModal(false)}
          onSubmit={offer => {
            cashCard.updateRev(offer.rev);
            cashCard.setCardData(offer, seriesMapping, seriesProfile, offering);
          }}
          rev={cashCard.rev}
        />
      )}
      {showResetToPrevRegionalOffer && (
        <ResetToPrevRegionalOffer
          id={cashCard.id}
          onClose={() => setShowResetToPrevRegionalOffer(false)}
          onSubmit={offer => {
            cashCard.updateRev(offer.rev);
            cashCard.setCardData(offer, seriesMapping, seriesProfile, offering);
          }}
          rev={cashCard.rev}
        />
      )}
      {showEnhanceAndRefineModal && (
        <EnhanceAndRefineModal
          id={cashCard.id}
          onClose={() => setShowEnhanceAndRefineModal(false)}
          rev={cashCard.rev}
          isInclusions={cashCard.fields.isInclusions}
          toggleIsInclusions={cashCard.toggleIsInclusions}
          startDate={cashCard.fields.startDate}
          endDate={cashCard.fields.endDate}
          minDate={cashCard.fields.startDate}
          maxDate={cashCard.fields.endDate}
          openPenRateModal={setShowPenRatesModal}
          vehicles={cashCard.fields.vehicles}
          setOfferId={setOfferId}
          cashCard={cashCard}
        />
      )}
    </>
  );
};

export default observer(CashContextMenu);
