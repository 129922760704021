import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import { EnhancedCostShare, LeaseOfferDetails, Offer, useSaveLeaseOfferMutation, useSaveVehiclesMutation, Vehicle } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import { assignStringValue } from '../../../utils/assignValue';
import { AdminModels } from 'oat-admin-common';
import { FEATURE_OR_4154 } from '../../../constants/global';

const useCreateLeaseSave = () => {
  const { region, period, profile, regalt } = useUrlParams();
  const [saveLeaseOffer] = useSaveLeaseOfferMutation();
  const [saveVehicles] = useSaveVehiclesMutation();
  const history = useHistory();

  const saveLease = async (leaseOffer: Offer, isSpecialEdition: boolean, leaseDetails: LeaseOfferDetails[], excludedList: Vehicle[], includedList: Vehicle[]) => {
    const { id, rev, name, accessoryCodes, specialEditionPackage, contestNumber } = leaseOffer;

    const res = await trackPromise(
      saveLeaseOffer({
        variables: {
          input: {
            id,
            rev,
            name,
            accessoryCodes,
            specialEditionPackage,
            isSpecialEdition,
            leaseDetails,
            contestNumber,
            optionTierType: leaseOffer.optionTierType ?? 'Blended',
            isEligibleForEnhCostShare: leaseOffer.isEligibleForEnhCostShare,
            enhCostShareOfferId: leaseOffer.enhCostShareOfferId,
            isEnhCostShareAccepted: leaseOffer.isEnhCostShareAccepted,
            isEligibleForEnhTfsCostShare: leaseOffer.isEligibleForEnhTfsCostShare,
            enhTfsCostShareId: leaseOffer.enhTfsCostShareId,
            isEnhTfsCostShareAccepted: leaseOffer.isEnhTfsCostShareAccepted,
          },
        },
      }),
    );

    await trackPromise(
      saveVehicles({
        variables: {
          input: {
            id,
            rev: res.data?.saveLeaseOffer.offer?.rev ?? '',
            vehicles: [...excludedList, ...includedList],
          },
        },
      }),
    );

    const anchor = `lease-${id}`;
    history.push(`/details/region/${region}/period/${period}/profile/${profile}/regalt/${regalt}?scrollTo=${anchor}`);
  };

  const processLeaseOfferForPayload = (data: {
    leaseOffer: Offer;
    enhancedCostShare?: EnhancedCostShare;
    enhacedTfsCostShare?: AdminModels.EnhancedTfsCostShare.EnhancedTfsCostShare;
    isEnhTfsCostShareAccepted?: boolean;
    isEnhCostShareAccepted?: boolean;
  }) => {
    const { leaseOffer, enhancedCostShare, enhacedTfsCostShare, isEnhTfsCostShareAccepted, isEnhCostShareAccepted } = data;

    return {
      ...leaseOffer,
      isEligibleForEnhCostShare: leaseOffer.isEligibleForEnhCostShare ?? !!enhancedCostShare,
      enhCostShareOfferId: leaseOffer.enhCostShareOfferId ?? enhancedCostShare?.offerId,
      isEnhCostShareAccepted: isEnhCostShareAccepted !== undefined ? isEnhCostShareAccepted : leaseOffer.isEnhCostShareAccepted,

      ...(leaseOffer.isStandalone &&
        FEATURE_OR_4154 && {
          isEligibleForEnhTfsCostShare: leaseOffer.isEligibleForEnhTfsCostShare ?? !!enhacedTfsCostShare,
          enhTfsCostShareId: leaseOffer.enhTfsCostShareId ?? enhacedTfsCostShare?.id,
          isEnhTfsCostShareAccepted: isEnhTfsCostShareAccepted !== undefined ? isEnhTfsCostShareAccepted : leaseOffer.isEnhTfsCostShareAccepted,
        }),

      accessoryCodes: assignStringValue(leaseOffer.accessoryCodes), // accessory codes and specialEditionPackage cannot be null!
      specialEditionPackage: assignStringValue(leaseOffer.specialEditionPackage),
      contestNumber: assignStringValue(leaseOffer.contestNumber),
    };
  };

  return {
    saveLease,
    processLeaseOfferForPayload,
  };
};

export default useCreateLeaseSave;
