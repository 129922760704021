import PenRateModel from '../../../../../models/PenRateModel';
import OfferValue from '../OfferValue';

interface Props {
  miscModel: PenRateModel;
}

const MiscValue = ({ miscModel }: Props) => {
  const { isEnhanced, cash, name } = miscModel;

  return <OfferValue cash={cash} name={name} isEnhanced={isEnhanced} />;
};

export default MiscValue;
