import { DefaultModal, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { Offer, useUpdateOfferAdvertisedMutation } from '../../../../gql/generated';
import styles from './styles.module.scss';

interface Props {
  id: string;
  rev: string;
  tier?: string;
  term?: number;
  isAdvertised: boolean;
  onClose: () => void;
  onSubmit: (offer: Offer) => void;
}

const AdvertiseOfferModal = ({ id, rev, tier, term, isAdvertised, onClose, onSubmit }: Props) => {
  const [updateOfferAdvertised] = useUpdateOfferAdvertisedMutation();
  const { error } = useToast();

  const handleSubmit = async () => {
    try {
      const res = await trackPromise(updateOfferAdvertised({ variables: { input: { id, rev, tier, term, isAdvertised: !isAdvertised } } }));
      onSubmit(res.data?.updateOfferAdvertised?.offer as Offer);
    } catch (e) {
      error((e as Error).message);
    } finally {
      onClose();
    }
  };
  return (
    <DefaultModal
      open
      title={`Make ${isAdvertised ? 'Non-Advertised' : 'Advertised'}`}
      modalBodyClassName={styles.modal}
      message={
        isAdvertised
          ? 'Are you sure you want to change this offer Non-Advertised? Non-Advertised Offers will not be displayed to your end consumer.'
          : 'Are you sure you want to change this offer Advertised?'
      }
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default AdvertiseOfferModal;
