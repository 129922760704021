import React from 'react';
import { VehicleInput } from '../../gql/generated';
import SetSubvenedRcfsModel from '../../models/SetSubvenedRcfsModel';
import TooltipList, { TooltipListItem } from '../TooltipList/TooltipList';
import styles from './styles.module.scss';

interface SubvenedRcfModalProps {
  subvenedRcfs: SetSubvenedRcfsModel;
  vehicles: VehicleInput[];
}

const SubvenedRcfModal = ({ subvenedRcfs, vehicles }: SubvenedRcfModalProps) => {
  const renderVehicles = (subRcfModelCodes: string[]) => {
    return subRcfModelCodes.map(model => {
      const vehicle = vehicles.find(v => v.modelCode === model);
      return (
        <TooltipListItem key={model}>
          <span>({vehicle?.modelCode})</span>
          <span className={styles.vehicleListDesc}>{vehicle?.vehicleDescription}</span>
        </TooltipListItem>
      );
    });
  };

  return (
    <>
      {subvenedRcfs.subvenedRcfDetails?.map((subRcf, i) => (
        <React.Fragment key={subRcf.uuid}>
          <TooltipList divider={i > 0}>
            <TooltipListItem>
              <span>Subvened RCF Avail.</span>
              <span>{subRcf?.rcf}</span>
            </TooltipListItem>
            <span>Models</span>
            {renderVehicles(subRcf?.modelCodes as string[])}
          </TooltipList>
          <TooltipList className={styles.termList}>
            <TooltipListItem>
              <span className={styles.termTitle}>Term</span>
              <span>{subRcf?.term}</span>
            </TooltipListItem>
          </TooltipList>
        </React.Fragment>
      ))}
    </>
  );
};

export default SubvenedRcfModal;
