import { observer } from 'mobx-react-lite';
import { Input, OATIcon } from 'oat-common-ui';
import React from 'react';
import AccordionComponent from '../../../../components/Accordion/AccordionComponent';
import AccordionModel from '../../../../components/Accordion/models/AccordionModel';
import getInclExclLabel from '../../../../utils/getInclExclLabel';
import styles from './styles.module.scss';

interface Props {
  setEditVehicles?: (val: boolean) => void;
  toggleIsInclusions: () => void;
  vehiclesAccordion: AccordionModel;
  isInclusions: boolean;
  editVehicles?: boolean;
}

const ExclusionInclusion = ({ setEditVehicles, toggleIsInclusions, vehiclesAccordion, isInclusions, editVehicles = true }: Props) => {
  return (
    <div className={styles.incExcWrapper}>
      <div className={styles.labelWrapper}>
        <OATIcon
          className={styles.switchIcon}
          icon="switch"
          colorTheme="blue"
          size={14}
          onClick={() => {
            toggleIsInclusions();
            vehiclesAccordion.toggleReverse();
          }}
        />
        <span>{getInclExclLabel(isInclusions)}</span>
      </div>
      {!editVehicles && (
        <div className={styles.incExcInputWrapper}>
          <Input readOnly value={vehiclesAccordion.getDisplayValues()} className={styles.incExcInput} />
          <OATIcon className={styles.pencilIcon} onClick={() => setEditVehicles && setEditVehicles(true)} icon="edit" colorTheme="blue" />
        </div>
      )}
      {editVehicles && <AccordionComponent accordion={vehiclesAccordion} />}
    </div>
  );
};

export default observer(ExclusionInclusion);
