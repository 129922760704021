import { makeAutoObservable } from 'mobx';
import defaultOffering from '../defaultObjects/defaultOffering';
import { Offering } from '../gql/generated';

class OfferingsStore {
  offering: Offering = defaultOffering;

  constructor() {
    makeAutoObservable(this);
  }

  setOffering = (offering: Offering) => {
    this.offering = offering;
  };

  updateOffering = (props: Partial<Offering>) => {
    this.offering = {
      ...this.offering,
      ...props,
    };
  };
}

export default OfferingsStore;
