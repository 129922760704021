import { observer } from 'mobx-react-lite';
import { Button, ButtonGroup, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { Status } from '../../../../../constants/global';
import { RgnlAlt, useConfirmAllSeriesMutation } from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';
import styles from '../styles.module.scss';

interface Props {
  showCostShareModal: React.Dispatch<React.SetStateAction<boolean>>;
  showResetAllCostSharesBtn?: boolean;
  isDisableConfirmAllSeries?: boolean;
}

const ActionButtons = ({ showCostShareModal, showResetAllCostSharesBtn = true, isDisableConfirmAllSeries }: Props) => {
  const { summaryStore } = useStores();
  const [confirmAllSeries] = useConfirmAllSeriesMutation();
  const { error } = useToast();

  const showAllOffersBtn = summaryStore.offering.status === Status.NEEDS_REVISIONS || summaryStore.offering.status === Status.REVISED;

  const showAllOffersHandler = () => summaryStore.setShowAllOffers(true);

  const confirmAllSeriesHandler = async () => {
    const idRevList: Array<{ rgnlAltId: string; rev: string }> = [];

    summaryStore.profiles.forEach(profile => {
      profile.rgnlAlts.forEach(rgnAlt => {
        if (profile.isValid && rgnAlt.isSelected && !rgnAlt.isSeriesConfirmed) {
          idRevList.push({
            rgnlAltId: rgnAlt.id,
            rev: rgnAlt.rev,
          });
        }
      });
    });

    try {
      const res = await trackPromise(
        confirmAllSeries({
          variables: {
            input: {
              idRevList,
            },
          },
        }),
      );

      if (res.data?.confirmAllSeries.success) {
        summaryStore.confirmAllSeries(res.data?.confirmAllSeries.rgnlAlts as RgnlAlt[]);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <div className={styles.buttonsContainer}>
      <ButtonGroup>
        {showAllOffersBtn && (
          <Button variant="primary" onClick={showAllOffersHandler} id="show-all-offers-btn">
            Show All Offers
          </Button>
        )}
        {showResetAllCostSharesBtn && (
          <Button variant="primary" onClick={() => showCostShareModal(true)} id="reset-all-cost-shares-btn">
            Reset All Cost Shares
          </Button>
        )}
        <Button variant="primary" onClick={confirmAllSeriesHandler} id="confirm-all-series-btn" disabled={isDisableConfirmAllSeries}>
          Confirm All Series
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default observer(ActionButtons);
