import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import CreateApr from './pages/createOffer/CreateApr';
import CreateCash from './pages/createOffer/CreateCash';
import CreateLease from './pages/createOffer/CreateLease';
import CreateMisc from './pages/createOffer/CreateMisc';
import Dashboard from './pages/Dashboard';
import DealerGross from './pages/DealerGross';
import ErrorPage from './pages/ErrorPage';
import InitialRoute from './pages/InitialRoute';
import PersonalSettings from './pages/PersonalSettings';
import ProgramDetails from './pages/ProgramDetails';
import ReconcilePage from './pages/ReconcilePage';
import RegionStatus from './pages/RegionStatus';
import ReportsPage from './pages/ReportsPage';
import SeriesSorting from './pages/SeriesSorting';
import Summary from './pages/Summary';

const Routes = () => {
  return (
    <Switch>
      {/* Added duplicate Dashboard to load all data for redirect to work */}
      <PrivateRoute exact path="/">
        <InitialRoute />
      </PrivateRoute>
      <PrivateRoute exact path="/dashboard">
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute exact path="/regionStatus/period/:period">
        <RegionStatus />
      </PrivateRoute>
      <PrivateRoute exact path="/summary/region/:region/period/:period">
        <Summary />
      </PrivateRoute>
      <PrivateRoute exact path="/details/region/:region/period/:period/profile/:profile/regalt/:regalt">
        <ProgramDetails />
      </PrivateRoute>
      <PrivateRoute exact path="/create/cash/region/:region/period/:period/profile/:profile/regalt/:regalt">
        <CreateCash />
      </PrivateRoute>
      <PrivateRoute exact path="/create/apr/region/:region/period/:period/profile/:profile/regalt/:regalt">
        <CreateApr />
      </PrivateRoute>
      <PrivateRoute exact path="/create/lease/region/:region/period/:period/profile/:profile/regalt/:regalt">
        <CreateLease />
      </PrivateRoute>
      <PrivateRoute exact path="/create/misc/region/:region/period/:period/profile/:profile/regalt/:regalt">
        <CreateMisc />
      </PrivateRoute>
      <PrivateRoute exact path="/changeVin/region/:region/period/:period/profile/:profile/regalt/:regalt/offerId/:offerId/offerTerm/:offerTerm">
        <CreateLease />
      </PrivateRoute>
      <PrivateRoute exact path="/reconcile/region/:region/period/:period">
        <ReconcilePage />
      </PrivateRoute>
      <PrivateRoute exact path="/reports/incentivePeriods">
        <ReportsPage />
      </PrivateRoute>
      <PrivateRoute exact path="/reports/nationalPlanning">
        <ReportsPage isNationalPlanning />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/personal">
        <PersonalSettings />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/dealerGross">
        <DealerGross />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/seriesSorting">
        <SeriesSorting />
      </PrivateRoute>
      <Route exact path="/error">
        <ErrorPage />
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Routes;
