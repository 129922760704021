import { makeAutoObservable } from 'mobx';
import { RgnlAlt } from '../../../../gql/generated';
import RgnlAltModel from '../../../Summary/models/RgnlAltModel';

class PenRatesStore {
  rgnlAlt = new RgnlAltModel();
  currentOfferId = '';

  constructor(rgnlAlt?: RgnlAlt | null, currentOfferId = '') {
    makeAutoObservable(this);

    if (rgnlAlt) {
      const ra = new RgnlAltModel(rgnlAlt);
      this.rgnlAlt = ra;
      this.currentOfferId = currentOfferId;
    }
  }
}

export default PenRatesStore;
