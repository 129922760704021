import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { LeaseOfferDetails, useSaveSubventionCashMutation } from '../../../../../../../gql/generated';
import useStores from '../../../../../../../stores/useStores';
import { assignStringValue } from '../../../../../../../utils/assignValue';
import validator from '../../../../../../../utils/validator';
import LeaseCardModel from '../../../../../models/LeaseCardModel';
import LeaseFormModel from '../../../../../models/LeaseFormModel';
import LeaseInput from '../../LeaseInput';
import styles from './styles.module.scss';

interface Props {
  leaseCard: LeaseCardModel;
  leaseForm: LeaseFormModel;
  onClose: () => void;
  onSave: (skipDelay?: boolean, payload?: LeaseOfferDetails[]) => void;
}

const LeaseEditSubCashModal = ({ leaseCard, leaseForm, onClose, onSave }: Props) => {
  const [tfsShare, setTfsShare] = useState(assignStringValue(leaseForm.inputs.subCashTfsShare.toString()));
  const {
    programDetailsStore: { ryoEarnings, offering },
    rcfsResidualsStore,
  } = useStores();
  const [cap, setCap] = useState(assignStringValue(leaseForm.inputs.subCashTfsCostShareCap.toString()));
  const [saveSubCash] = useSaveSubventionCashMutation();
  const { error } = useToast();

  const tfsShareError = validator(tfsShare, { required: true });
  const capError = validator(cap, { required: true });
  const isInvalid = tfsShareError?.required || capError?.required;

  const handleOnSave = async () => {
    if (isInvalid) {
      return;
    }

    try {
      const subventionCashInputs = leaseForm.inputs.isUpToApplied
        ? leaseCard.leaseTerms
            .filter(item => item.term <= leaseForm.inputs.term)
            .map(item => item.getMaster())
            .map(item => ({
              term: item.inputs.term,
              tier: leaseCard.optionTierType === 'ByTier' ? leaseCard.tier : null,
              subCashTfsCostShareCap: Number(cap),
              subCashTfsShare: Number(tfsShare),
              subventionCash: Number(leaseForm.inputs.subventionCash),
            }))
        : [
            {
              term: leaseForm.inputs.term,
              tier: leaseCard.optionTierType === 'ByTier' ? leaseCard.tier : null,
              subCashTfsCostShareCap: Number(cap),
              subCashTfsShare: Number(tfsShare),
              subventionCash: Number(leaseForm.inputs.subventionCash),
            },
          ];

      const res = await trackPromise(
        saveSubCash({
          variables: {
            input: {
              offerId: leaseCard.id,
              rev: leaseCard.rev,
              subventionCashInputs,
            },
          },
        }),
      );

      leaseCard.updateRev(res.data?.saveSubventionCash.offer.rev);
      leaseForm.updateSubCashCap(Number(cap), Number(tfsShare));
      leaseForm.updateOfferCosts(ryoEarnings);
      if (leaseForm.inputs.isUpToApplied) {
        const payload = leaseCard.setUpToValues(leaseForm, ryoEarnings, rcfsResidualsStore.stdLeaseRcfs.find(rcfItem => rcfItem.tier === '1+')?.rcf, offering);
        onSave(false, payload);
      } else {
        onSave();
      }
      onClose();
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <Modal isOpen onClose={onClose}>
      <ModalHeader title="Edit Lease Subvention Cash" onClose={onClose} />
      <ModalBody>
        <div className={styles.container}>
          <div className={styles.column}>
            <LeaseInput
              id="edit-subcash-tfsshare"
              value={tfsShare}
              maxLength={3}
              onValueChange={val => setTfsShare(val.value)}
              percentageSign
              label="Sub Cash TFS Cost Share"
              error={tfsShareError?.required}
              text={`${leaseCard.enhancedCostShare?.subventionCashTfsCostShare ? `Enhanced: ${leaseCard.enhancedCostShare?.subventionCashTfsCostShare}%` : `Default: 0%`}`}
            />
          </div>
          <div className={styles.column}>
            <LeaseInput
              id="edit-subcash-cap"
              value={cap}
              wholeNumber
              onValueChange={val => setCap(val.value)}
              dollarSign
              label="Cap"
              error={capError?.required}
              text={`${leaseCard.enhancedCostShare?.subventionCashTfsCostShareCap ? `Enhanced: $${leaseCard.enhancedCostShare?.subventionCashTfsCostShareCap}` : `Default: $0`}`}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button id="modal-cancel-btn" variant="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="modal-save-btn" variant="primary" onClick={handleOnSave} disabled={isInvalid}>
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default LeaseEditSubCashModal;
