import { observer } from 'mobx-react-lite';
import { Dropdown } from 'oat-common-ui';
import { NationalPlanningReports } from '../../../../constants/global';
import useStores from '../../../../stores/useStores';
import ReportsList from '../ReportsList';

const NationalPlanning = () => {
  const {
    reportsStore: { availableIpList, selectedIp, setSelectedIp },
  } = useStores();

  const createIpListForDropdown = () => {
    const list: { value: string; label: string }[] = [];
    availableIpList.forEach(ip => list.push({ value: ip.name, label: ip.name }));

    return list;
  };

  const handleIpChange = (value: string) => {
    const findSelectedIp = availableIpList.filter(ip => ip.name === value)[0];
    setSelectedIp(findSelectedIp);
  };

  return (
    <>
      <Dropdown
        id="reports-ip-dropdown"
        options={createIpListForDropdown()}
        value={selectedIp.name}
        onChange={value => {
          handleIpChange(value.value);
        }}
        darkTheme
      />
      <ReportsList reports={NationalPlanningReports} />
    </>
  );
};

export default observer(NationalPlanning);
