import { formatDollars, OATIcon } from 'oat-common-ui';
import useGetTfsLabel from '../../../../../../utils/useGetTfsLabel';
import styles from './styles.module.scss';

interface Props {
  tfsCashValue: string | number;
}

const TFSSubven = ({ tfsCashValue }: Props) => {
  const tfsLabel = useGetTfsLabel();

  return (
    <div className={styles.tfsContainer}>
      <OATIcon icon="plus" className={styles.plusIcon} />
      <div>
        <sup className={styles.dollarSign}>$</sup>
        <span className={styles.tfsCashValue}>{formatDollars(tfsCashValue)}</span>
      </div>
      <span className={styles.tfsCashText}>${tfsLabel} Subvention Cash</span>
    </div>
  );
};

export default TFSSubven;
