import { observer } from 'mobx-react-lite';
import { OATIcon } from 'oat-common-ui';
import styles from './styles.module.scss';

interface Props {
  item: string;
  innerRef: (element: HTMLElement | null) => void;
}

const SeriesSortingItem = ({ item, innerRef, ...rest }: Props) => {
  return (
    <div ref={innerRef} className={styles.seriesSortingItem} {...rest}>
      <span className={styles.seriesName}>{item}</span>
      <OATIcon icon="drag" />
    </div>
  );
};

export default observer(SeriesSortingItem);
