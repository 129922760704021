import clsx from 'clsx';
import { BlockInputLabel, Button, Dropdown, IValueLabel, Modal, ModalBody, ModalFooter, ModalHeader, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Offer, useUpdateTiersAndTermsOnEnROfferMutation } from '../../../../gql/generated';
import styles from './styles.module.scss';

interface Props {
  id: string;
  rev: string;
  tiers: string[];
  terms: string[];
  termOptions: IValueLabel[];
  tierOptions: IValueLabel[];
  onSubmit: (updatedOffer: Offer) => void;
  onClose: () => void;
}

const EditTiersAndTermsModal = ({ id, rev, tiers, terms, termOptions, tierOptions, onClose, onSubmit }: Props) => {
  const [selectedTerms, setSelectedTerms] = useState<string[]>(terms);
  const [selectedTiers, setSelectedTiers] = useState<string[]>(tiers);
  const nationalTiers = tierOptions.map(item => item.value);
  const [updateTiersAndTermsOnEnROffer] = useUpdateTiersAndTermsOnEnROfferMutation();
  const { error } = useToast();
  const handleTerms = (term: string, remove = false) => {
    if (remove) {
      setSelectedTerms(selectedTerms.filter(termItem => termItem !== term));
    } else {
      const set = new Set(selectedTerms);
      set.add(term);
      setSelectedTerms(Array.from(set).sort());
    }
  };

  const handleTiers = (tier: string, remove = false) => {
    if (remove) {
      setSelectedTiers(selectedTiers.filter(tierItem => tierItem !== tier));
    } else {
      const set = new Set(selectedTiers);
      set.add(tier);
      setSelectedTiers(nationalTiers.filter(item => set.has(item)));
    }
  };

  const handleSubmit = async () => {
    const payload = {
      id,
      rev,
      terms: selectedTerms,
      tiers: selectedTiers,
    };
    try {
      const res = await trackPromise(updateTiersAndTermsOnEnROffer({ variables: { input: payload } }));

      if (res.data?.updateTiersAndTermsOnEnROffer.success) {
        const offer = res.data.updateTiersAndTermsOnEnROffer.offer;
        onSubmit(offer);
        onClose();
      }
    } catch (e) {
      error((e as Error).message);
    }
  };
  return (
    <Modal size="md" isOpen onClose={onClose}>
      <ModalHeader title="Edit Tiers & Terms" onClose={onClose} />
      <ModalBody className={styles.modalBody}>
        <div className={styles.pillboxContainer}>
          <BlockInputLabel label="Terms:" className={clsx(styles.pillbox)} labelClass={styles.inputLabel}>
            <Dropdown
              darkTheme
              minWidth={220}
              id="mdl-terms-input"
              value={''}
              pillBoxValue={selectedTerms}
              isPillBox
              onCancelClick={item => {
                handleTerms(item, true);
              }}
              onChange={item => handleTerms(item.value)}
              usePillBoxLabelForSelectedItems
              options={termOptions}
            />
          </BlockInputLabel>

          <BlockInputLabel label="Tiers:" className={clsx(styles.pillbox)} labelClass={styles.inputLabel}>
            <Dropdown
              darkTheme
              minWidth={220}
              id="mdl-tiers-input"
              value={''}
              pillBoxValue={selectedTiers}
              isPillBox
              onCancelClick={item => {
                handleTiers(item, true);
              }}
              onChange={item => handleTiers(item.value)}
              options={tierOptions}
            />
          </BlockInputLabel>
        </div>
      </ModalBody>
      <ModalFooter className={styles.enhanceModalFooter}>
        <Button id="enhance-refine-modal-submit" variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditTiersAndTermsModal;
