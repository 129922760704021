import { AdminConstants } from 'oat-admin-common';
import { CreateMiscOfferInput, SaveMiscOfferInput } from '../gql/generated';
import MiscCardModel from '../pages/ProgramDetails/models/MiscCardModel';
import useStores from '../stores/useStores';
import useOfferEligibility from './useOfferEligibility';
import useUrlParams from './useUrlParams';

const useMiscEnhCostShareUtils = (miscCard: MiscCardModel) => {
  const { region } = useUrlParams();
  const { handleEnhCostShare } = useOfferEligibility();
  const {
    programDetailsStore: { getEnhancedCostShareById, ryoEarnings },
  } = useStores();

  const handleOfferEligibility = () => {
    const { showModal, isRemoving, reason, eligibleOfferId, isReplacing } = handleEnhCostShare(
      {
        startDate: miscCard.fields.startDate,
        endDate: miscCard.fields.endDate,
        vehicles: miscCard.fields.includedVehicles,
        optionTypeName: miscCard.fields.type as AdminConstants.OPTION_TYPE_NAMES,
        regionCode: region,
        states: miscCard.fields.includedStates,
        tfsContractRequired: miscCard.fields.tfsContractRequired,
        tfsContracts: miscCard.fields.tfsContracts,
      },
      miscCard,
    );

    if (showModal && miscCard.enhCostShareFields.isModalConfirmed === undefined && (miscCard.isEnhCostShareAccepted === undefined || isReplacing)) {
      miscCard.updateEnhCostShareField('eligibleOfferId', eligibleOfferId);
      miscCard.updateEnhCostShareField('showEnhCostShareModal', true);
      miscCard.updateEnhCostShareField('isReplacing', Boolean(isReplacing));
      return true;
    }

    if (showModal && isRemoving && miscCard.enhCostShareFields.isModalConfirmed === undefined) {
      miscCard.updateEnhCostShareField('removingReason', reason);
      return true;
    }

    return false;
  };

  // Set proper enhancedCostShare offer to the miscCard depend on what user clicked
  const handleEnhCostData = () => {
    // Apply enh cost share is confirmed
    if (miscCard.enhCostShareFields.eligibleOfferId && (miscCard.enhCostShareFields.isModalConfirmed || miscCard.enhCostShareFields.isNotificationConfirmed)) {
      miscCard.setEnhancedCostShare(getEnhancedCostShareById(miscCard.enhCostShareFields.eligibleOfferId));
      miscCard.applyEnhCostShare();
    }

    // Remove enh cost share is confirmed
    if (miscCard.enhCostShareFields.isModalConfirmed && miscCard.enhCostShareFields.removingReason) {
      miscCard.removeEnhCostShare();
    }

    // Apply/remove cost share is canceled
    if (miscCard.enhCostShareFields.isModalConfirmed === false && (miscCard.enhCostShareFields.eligibleOfferId || miscCard.enhCostShareFields.removingReason)) {
      miscCard.cancelEnhCostShareAction();
    }

    miscCard.updateOfferCosts(ryoEarnings);
  };

  const buildPayload = (miscProps: CreateMiscOfferInput | SaveMiscOfferInput, isRemove = false, isCancel = false) => {
    let enhCostSharePayload = {
      isEnhCostShareAccepted: miscCard.isEligibleForEnhCostShare ? true : miscCard.isEnhCostShareAccepted,
      isEnhCostShareUpdated: miscCard.isEnhCostShareUpdated,
      isEnhCostShareRemoved: miscCard.isEnhCostShareRemoved,
    };

    if (isRemove) {
      enhCostSharePayload = {
        isEnhCostShareAccepted: miscCard.enhCostShareOfferId ? true : false,
        isEnhCostShareUpdated: miscCard.isEnhCostShareUpdated,
        isEnhCostShareRemoved: false,
      };
    }

    if (isCancel) {
      enhCostSharePayload = {
        isEnhCostShareAccepted: false,
        isEnhCostShareUpdated: false,
        isEnhCostShareRemoved: false,
      };
    }

    return {
      ...miscProps,
      id: miscCard.id,
      rev: miscCard.rev,
      enhCostShareOfferId: miscCard.enhCostShareOfferId,
      isEligibleForEnhCostShare: miscCard.isEligibleForEnhCostShare,
      ...enhCostSharePayload,
    };
  };

  return {
    handleOfferEligibility,
    handleEnhCostData,
    buildPayload,
  };
};

export default useMiscEnhCostShareUtils;
