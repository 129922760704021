import { SeriesCodeToNameMap } from 'oat-common-ui';
import { VehicleInput } from '../../../gql/generated';
import AccordionItemModel from '../models/AccordionItemModel';

const createAccordionFromAccessories = (compatibileAccessories: string[], vehicle: VehicleInput, accessoryCodes?: string) => {
  const years: AccordionItemModel[] = [];

  const yItem = new AccordionItemModel(vehicle.seriesYear, vehicle.seriesYear);
  years.push(yItem);

  const serItem = new AccordionItemModel(SeriesCodeToNameMap[vehicle.series], vehicle.series);
  serItem.level = 2;
  yItem.items.push(serItem);

  compatibileAccessories.forEach(accessory => {
    const item = new AccordionItemModel(accessory, accessory);
    item.checked = Boolean(accessoryCodes?.includes(accessory));
    item.level = 3;
    serItem?.items.push(item);
  });

  serItem.items.sort((itemA, itemB) => (itemA.value > itemB.value ? 1 : -1));
  serItem.checked = serItem.items.some(item => item.checked);
  yItem.checked = yItem.items.some(item => item.checked);

  return years;
};

export default createAccordionFromAccessories;
