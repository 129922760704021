import { CreateLeaseModels, DD365InventoryItem, DD365InventoryResponse, DD365VinDetailsResponse, assignNumberValue } from 'oat-common-ui';

const getVinItemFromDD365 = (item: DD365InventoryItem, config: string) => {
  const vinItem = new CreateLeaseModels.VinItem();
  vinItem.setFromDD365Inventory(item);
  return vinItem;
};

export const getAccessoriesFromDD365 = (item: DD365InventoryItem) => {
  const config: string[] = [];
  const configFull: string[] = [];
  const configList: string[] = [];

  if (item.options) {
    item.options.forEach(opt => {
      const code = opt.optionCd.slice(0, 2);
      config.push(code);
      configFull.push(`${code}#${opt.marketingName}#${opt.dealerInvoicePrice}`);
      configList.push(`${opt.marketingName} (${code})`);
    });
  }

  config.sort();

  return {
    config: config.join(', '),
    configFull: configFull.join('|'),
    configList,
  };
};

export const getAccessoriesFromDD365VinDetail = (item: DD365VinDetailsResponse) => {
  const codes: string[] = [];
  const accessoriesList: string[] = [];

  if (item.options) {
    item.options.forEach(opt => {
      const code = opt.code.slice(0, 2);
      codes.push(code);

      accessoriesList.push(`${opt.marketingName} (${code})`);
    });
  }

  codes.sort();

  return {
    config: codes.join(', '),
    accessoriesList,
  };
};

// Config items from dd365 have vin items already so vinItems will be created as well
const getConfigListFromDD365 = (data: DD365InventoryResponse): CreateLeaseModels.ConfigItem[] => {
  // We use a map because a config could be repeated with in the items array.
  // Each config would increase it's count
  const configMap: Map<string, CreateLeaseModels.ConfigItem> = new Map<string, CreateLeaseModels.ConfigItem>();
  const totalCount = data.items.length;

  data.items.forEach(item => {
    const { config, configFull } = getAccessoriesFromDD365(item);
    const configItem = configMap.get(config);

    if (configItem) {
      configItem.count = configItem.count + 1;
      configItem.inventory = assignNumberValue((configItem.count / totalCount).toFixed(2));
      configItem.vinItems = [...configItem.vinItems, getVinItemFromDD365(item, configItem.config)];
      configMap.set(config, configItem);
    } else {
      const optionsTotal = item.options && item.options.reduce((a, b) => a + b.msrp, 0);
      configMap.set(config, {
        config,
        configFull,
        optionsTotal,
        count: 1,
        inventory: assignNumberValue((1 / totalCount).toFixed(2)),
        modelCode: item.model.modelCd,
        vinItems: [getVinItemFromDD365(item, config)],
      });
    }
  });

  return Array.from(configMap.values());
};

export default getConfigListFromDD365;
