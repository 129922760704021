import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Input, OATIcon } from 'oat-common-ui';
import { Highlighter, Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import { SeriesProfile } from '../../../gql/generated';
import ProfileModel from '../../../pages/Summary/models/ProfileModel';
import { getScrollTopFromClick } from '../../../pages/Summary/utils';
import styles from '../styles.module.scss';

interface SubHeaderInputContainerProps {
  profiles: ProfileModel[];
}

const SubHeaderInputContainer = ({ profiles }: SubHeaderInputContainerProps) => {
  const handleScrollOnClick = (id: string) => {
    window.scrollTo({
      top: getScrollTopFromClick(id),
      behavior: 'smooth',
    });
  };

  const getInputWidthValue = (value: number) => {
    return (value + 1) * 8;
  };

  let inputWidth = getInputWidthValue(profiles.length ? profiles[0].name.length : 0);

  profiles.forEach(profile => {
    if (getInputWidthValue(profile.name.length) > inputWidth) {
      inputWidth = getInputWidthValue(profile.name.length);
    }
  });

  return (
    <div className={styles.inputContainer}>
      <Typeahead
        id="basic-example"
        options={profiles}
        onChange={profile => (profile.length ? handleScrollOnClick((profile[0] as SeriesProfile).id.toString()) : undefined)}
        selectHint={(shouldSelect, e) => e.key === 'Enter' || shouldSelect}
        placeholder="Search"
        labelKey="name"
        emptyLabel=""
        renderInput={({ inputRef, referenceElementRef, style, ...inputProps }, text) => (
          <Input
            id="input"
            {...inputProps}
            ref={input => {
              inputRef(input);
              referenceElementRef(input);
            }}
            style={{ width: `${inputWidth > 0 ? inputWidth : '300'}px` }}
            className={clsx(
              styles.searchBar,
              profiles.some(i => i.name.toLowerCase() !== text.text.toLowerCase() && i.name.toLowerCase().includes(text.text.toLowerCase())) && text.text && styles.active,
            )}
          />
        )}
        renderMenu={(results, menuProps, text) => (
          <ul>
            <Menu {...menuProps} className={styles.menuItem}>
              {results.map((result, index) => (
                <li key={`${(result as SeriesProfile).id}`}>
                  <MenuItem option={result} position={index}>
                    <Highlighter search={text.text}>{(result as SeriesProfile).name}</Highlighter>
                  </MenuItem>
                </li>
              ))}
            </Menu>
          </ul>
        )}
      />
      <OATIcon icon="search" size={16} colorTheme="blue" className={styles.searchIcon} />
    </div>
  );
};

export default observer(SubHeaderInputContainer);
