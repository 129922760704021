import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { OATIcon } from 'oat-common-ui';
import { Fragment } from 'react';
import DollarDisplay from '../../../../../../components/DollarDisplay';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import NumberInput from '../../../../../../components/NumberInput';
import { Status } from '../../../../../../constants/global';
import useStores from '../../../../../../stores/useStores';
import AprCardModel from '../../../../models/AprCardModel';
import AprTierModel from '../../../../models/AprTierModel';
import PnvsTooltipMessage from './components/PnvsTooltipMessage';
import styles from './styles.module.scss';

type Props = {
  card: AprCardModel;
  onChange: (skipDelay: boolean, skipRecalc: boolean) => void;
  selectedTierModel: AprTierModel;
};

const AprTierHeader = ({ selectedTierModel, card, onChange }: Props) => {
  const {
    userInfoStore: { isSETUser, isGst },
    programDetailsStore,
  } = useStores();

  const { terms } = selectedTierModel;

  const {
    fields: { combinedAverageAmount, bonusCash },
    isAvgAmountValid,
    setTermsAvgAmount,
    updateCombinedAverageAmount,
    updateField: updateAprCardField,
  } = card;

  const isDisabled = programDetailsStore.offering.status === Status.MEETS_GUIDELINES || card.isDisabled;

  const updateAllTiersCosts = () => {
    for (const tier of card.tierMap.values()) {
      tier.updateEstCosts();
      tier.updateOfferCosts(programDetailsStore.ryoEarnings);
    }
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.header}>
        <div className={styles.avgAmountWrapper}>
          <label className={styles.label}>Average Amount Financed</label>
          <div className={styles.avgAmountInputs}>
            {isSETUser() ? (
              terms.map(termObj => {
                if (termObj.fields.term === 24) {
                  return <Fragment key={`${termObj.uid}-avgAmount`}></Fragment>;
                } else {
                  return (
                    <div key={`${termObj.uid}-avgAmount`}>
                      <NumberInput
                        className={styles.avgAmountInput}
                        dollarSign
                        onValueChange={vals => {
                          setTermsAvgAmount(vals.value, termObj.fields.term);
                          updateAllTiersCosts();
                          onChange(false, true);
                        }}
                        selectOnFocus
                        value={termObj.fields.termAverageAmount}
                        wholeNumber
                        disabled={isDisabled}
                        error={!termObj.fields.termAverageAmount || Number(termObj.fields.termAverageAmount) === 0}
                        units
                      />
                      <p className={styles.termInputLabel}>Term {termObj.fields.term}</p>
                    </div>
                  );
                }
              })
            ) : (
              <NumberInput
                id={`${card.id}-avg-amt-input`}
                className={styles.avgAmountInput}
                dollarSign
                onValueChange={(vals, source) => {
                  const { event } = source;

                  if (event) {
                    updateCombinedAverageAmount(vals.value);
                    updateAllTiersCosts();
                    onChange(false, true);
                  }
                }}
                disabled={isDisabled}
                selectOnFocus
                value={combinedAverageAmount}
                wholeNumber
                error={!combinedAverageAmount || Number(combinedAverageAmount) === 0}
                units
              />
            )}
          </div>
        </div>
        {isGst() && (
          <div className={styles.avgAmountWrapper}>
            <label>Bonus Cash</label>
            <div className={styles.avgAmountInputs}>
              <NumberInput
                className={styles.avgAmountInput}
                dollarSign
                dark
                onValueChange={vals => {
                  updateAprCardField('bonusCash', Number(vals.value));
                  onChange(false, true);
                }}
                disabled={isDisabled}
                selectOnFocus
                value={bonusCash}
                wholeNumber
                units
              />
            </div>
          </div>
        )}

        <div className={styles.pnvsWrapper}>
          <DollarDisplay useColors={false} value={selectedTierModel.fields.estCost} className={styles.pnvsSum} />
          <div>
            <InfoTooltip iconSize={12} id="weighted-costs-pnvs" message={<PnvsTooltipMessage aprTerms={terms} selectedTierModel={selectedTierModel} />} />
            <span className={clsx(styles.label)}>Weighted Cost PNVS</span>
          </div>
        </div>
      </div>
      {!isAvgAmountValid && (
        <p className={styles.error}>
          <OATIcon className={styles.errorIcon} icon="warning" size={14} />
          <span>Average Amount Financed must be a numeric value greater than 0</span>
        </p>
      )}
    </div>
  );
};

export default observer(AprTierHeader);
