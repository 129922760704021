import { observer } from 'mobx-react-lite';
import { OATIcon } from 'oat-common-ui';
import { FC, useMemo } from 'react';
import useStores from '../../../../../../../../../stores/useStores';
import { getFoundingSourceLabel } from '../../../../../../../../../utils/getFoundingSourceLabel';

import { FEATURE_OR_4154 } from '../../../../../../../../../constants/global';
import styles from '../styles.module.scss';

type Props = {
  forCreate?: boolean;
  hasBlended: boolean;
  isEnhanced: boolean;
  openFundingSplit: () => void;
};

const AprTableHeader: FC<Props> = ({ forCreate, hasBlended, isEnhanced, openFundingSplit }) => {
  const {
    userInfoStore: { isSETUser, isLexus },
  } = useStores();

  const aprTableHeaderValues = useMemo(() => {
    const aprHeaders = ['Start Date', 'End Date', 'Term', 'Rate', 'Target Payment', `costShare`, 'Term Pen %', `${getFoundingSourceLabel(isLexus(), isSETUser())} Subvention Cash`];
    const featureFlagAprHeaders = ['Term', 'Rate', 'Target Payment', `costShare`, 'Term Pen %', `${getFoundingSourceLabel(isLexus(), isSETUser())} Subvention Cash`];

    if (isSETUser() && forCreate) {
      aprHeaders.push('Include Term?');
    }

    return FEATURE_OR_4154 ? featureFlagAprHeaders : aprHeaders;
  }, [forCreate, isLexus, isSETUser]);

  return (
    <thead className={styles.headerWrapper}>
      <tr>
        {aprTableHeaderValues.map((header, i) => {
          return (
            <td key={`${header}-${i}`} className={styles.headerValue}>
              {header === 'costShare' ? (
                <>
                  <span className={styles.headerText}>
                    {isEnhanced && <OATIcon id={`header`} icon="split" colorTheme="blue" pointer onClick={openFundingSplit} />}
                    {hasBlended ? 'Blended Share' : `${getFoundingSourceLabel(isLexus(), isSETUser())} Cost Share`}
                  </span>
                </>
              ) : (
                <span className={styles.headerText}>{header}</span>
              )}
            </td>
          );
        })}
      </tr>
    </thead>
  );
};

export default observer(AprTableHeader);
