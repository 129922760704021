import AccordionItemModel from '../models/AccordionItemModel';

const recursiveApplyHideFalse = (items: AccordionItemModel[]) => {
  items.forEach(item => {
    item.hide = false;

    if (item.items.length > 0) {
      recursiveApplyHideFalse(item.items);
    }
  });
};

const shouldHide = (query: string, value: string) => {
  const queryTrimmed = query.trim().toLowerCase();

  return Boolean(queryTrimmed && !value.toLowerCase().includes(queryTrimmed));
};

export const recursiveQuery = (accordionItems: AccordionItemModel[], query: string) => {
  accordionItems.forEach(item => {
    if (item.items.length > 0) {
      item.hide = shouldHide(query, item.label);

      if (!item.hide) {
        recursiveApplyHideFalse(item.items);
      } else {
        recursiveQuery(item.items, query);
        item.hide = item.items.every(i => i.hide);
      }
    } else {
      item.hide = shouldHide(query, item.label);
    }
  });
};
