import { DEFAULT_TIERS } from '../constants/global';
import { LeaseDetails, LeaseRates } from '../gql/generated';

const getLowestTierFromLeaseDetails = (leaseDetails: LeaseDetails) => {
  let retDetails: LeaseRates | undefined;
  for (const tier of DEFAULT_TIERS) {
    const found = leaseDetails.rates.find(rate => rate.tier === tier);
    if (!found) {
      retDetails = found;
    }
  }

  return retDetails ?? leaseDetails.rates[0];
};

export default getLowestTierFromLeaseDetails;
