import { observer } from 'mobx-react-lite';
import React from 'react';
import useUserInfo from '../../../../utils/useUserInfo';
import LeaseCard from '../../models/LeaseCardModel';
import ProgramDetailsWrapper from '../ProgramDetailsWrapper';
import LeaseAdForm from './LeaseAdForm';
import LeaseGstForm from './LeaseGstForm';
import LeaseNonAdForm from './LeaseNonAdForm';
import LeaseVinErrorCard from './components/LeaseVinErrorCard';

interface LeaseCardProps {
  leaseCard: LeaseCard;
}

const LeaseCardGroup = ({ leaseCard }: LeaseCardProps) => {
  const { leaseTerms, term, tier } = leaseCard;
  const { isGSTUser } = useUserInfo();

  return (
    <ProgramDetailsWrapper fixed>
      <section>
        {leaseTerms.map(leaseTerm => {
          if (Number(leaseTerm.term) === Number(term) && leaseTerm.tier === tier) {
            return leaseTerm.leaseForms.map(leaseForm => {
              if (leaseForm.inputs.isBadVin && leaseForm.inputs.isAdvertised) {
                return <LeaseVinErrorCard leaseCard={leaseCard} leaseForm={leaseForm} />;
              } else if (leaseForm.uid === leaseTerm.currentFormUid) {
                return (
                  <React.Fragment key={leaseForm.uid}>
                    {leaseForm.inputs.isAdvertised && isGSTUser() && <LeaseGstForm leaseCard={leaseCard} leaseTerm={leaseTerm} leaseForm={leaseForm} />}
                    {leaseForm.inputs.isAdvertised && !isGSTUser() && <LeaseAdForm leaseCard={leaseCard} leaseTerm={leaseTerm} leaseForm={leaseForm} />}
                    {!leaseForm.inputs.isAdvertised && <LeaseNonAdForm leaseCard={leaseCard} leaseTerm={leaseTerm} leaseForm={leaseForm} />}
                  </React.Fragment>
                );
              } else {
                return null;
              }
            });
          } else {
            return null;
          }
        })}
      </section>
    </ProgramDetailsWrapper>
  );
};

export default observer(LeaseCardGroup);
