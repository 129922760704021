import cx from 'clsx';
import { Button, IconNames, OATIcon, useComponentVisible } from 'oat-common-ui';
import { FC, ReactNode } from 'react';
import { ColorThemeType } from '../../pages/ProgramDetails/models/OfferHeader';
import styles from './styles.module.scss';

type SettingsMenuProps = {
  icon?: IconNames;
  iconColorTheme?: ColorThemeType;
  iconSize?: number;
  children?: ReactNode;
  className?: string;
  isDisabled?: boolean;
  hideBtn?: boolean;
};

/**
 * Need to utilize the outside click
 * @param param0
 * @returns
 */
const SettingsMenu: FC<SettingsMenuProps> = ({ children, iconSize = 16, icon = 'gear', iconColorTheme = 'blue', className, isDisabled, hideBtn = false }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <div className={cx(styles.settingsMenu, className)}>
      {!hideBtn && (
        <Button variant="text" onClick={() => setIsComponentVisible(!isComponentVisible)} className={styles.gearBtn} disabled={isDisabled}>
          <OATIcon className={styles.gearIcon} icon={icon} colorTheme={iconColorTheme} size={iconSize} />
        </Button>
      )}
      {isComponentVisible && (
        <ul className={styles.list} ref={ref} onClick={() => setIsComponentVisible(false)}>
          {children}
        </ul>
      )}
    </div>
  );
};

export default SettingsMenu;
