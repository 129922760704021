import { makeAutoObservable } from 'mobx';
import { uuidv4 } from 'oat-common-ui';
import { AprDetails, FundingSourceSplitsInput } from '../../../gql/generated';
import { assignDollarCents, assignNumberValue } from '../../../utils/assignValue';
import { calculateOfferBalance, calculateOfferCost, calculateOfferEarning } from '../../../utils/offerCalculations';
import AprTermModel from './AprTermModel';

export class AprTierModelFields {
  note = '';
  tdaNote = '';

  estCost = 0;
  tfsEstCost = 0;
  enhTfsEstCost = 0;
  subCashEstCost = 0;
  subCashTfsEstCost = 0;
  subCashEnhTfsEstCost = 0;
  offerEarnings = 0;
  offerCost = 0;
  offerBalance = 0;
  offerTfsCost = 0;
  offerEnhTfsCost = 0;
  subCashOfferCost = 0;
  subCashOfferTfsCost = 0;
  subCashOfferEnhTfsCost = 0;

  totalOfferCost = 0;

  constructor() {
    makeAutoObservable(this);
  }
}

class AprTierModel {
  uid = uuidv4();
  fields = new AprTierModelFields();
  highestAdvertisedTerm: AprTermModel = new AprTermModel();
  terms: AprTermModel[] = [];
  tier: string;

  constructor(tier: string, note: string, tdaNote: string) {
    makeAutoObservable(this);
    this.fields.note = note;
    this.fields.tdaNote = tdaNote;
    this.tier = tier;
    this.setHighestAdvertisedTerm();
  }

  setHighestAdvertisedTerm = () => {
    const advertisedTerms = this.terms.filter(term => term.isAdvertised);
    if (advertisedTerms.length) {
      this.highestAdvertisedTerm = advertisedTerms[advertisedTerms.length - 1];
    }
  };

  toggleAdvertisedTerm = (term: number) => {
    const clickedTerm = this.terms.find(item => item.fields.term === term);
    const advertisedTerms = this.terms.filter(item => item.isAdvertised);

    if (clickedTerm) {
      if (clickedTerm.isAdvertised && advertisedTerms.length < 2) {
        return;
      }

      clickedTerm.isAdvertised = !clickedTerm.isAdvertised;
    }

    this.setHighestAdvertisedTerm();
  };

  updateTermFundingSourceSplits = (aprDetails: AprDetails[]) => {
    this.terms.forEach(term => {
      const foundDetail = aprDetails.find(aprDetail => aprDetail.highTerm === term.fields.term);
      if (foundDetail) {
        term.fields.fundingSourceSplits = foundDetail.fundingSourceSplits ?? [];
      }
    });
  };

  updateTerms = (aprDetails: AprDetails[]) => {
    this.terms.forEach(term => {
      aprDetails?.forEach(aprDetail => {
        if (term.fields.term === aprDetail.highTerm && aprDetail.tier === this.tier) {
          term.isAdvertised = aprDetail.isAdvertised;
        }
      });
    });
    this.setHighestAdvertisedTerm();
  };

  updateField = <T extends keyof AprTierModelFields, V extends AprTierModelFields[T]>(field: T, value: V) => {
    this.fields[field] = value;
  };

  updateTermsFundingSourceSplits = (
    fundingSourceSplits: FundingSourceSplitsInput[],
    tfsShares: Array<{
      term: number;
      tfsShare: number;
      tier: string;
    }>,
  ) => {
    fundingSourceSplits.forEach(split => {
      this.terms.forEach(term => {
        if (split.term === term.fields.term) {
          term.updateFundingSourceSplits(split.splits);
        }
      });
    });

    tfsShares.forEach(tfsShare => {
      this.terms.forEach(term => {
        if (tfsShare.term === term.fields.term) {
          term.updateField('tfsShare', tfsShare.tfsShare.toString());
        }
      });
    });
  };

  updateEstCosts = () => {
    let estCostSum = 0;
    let tfsEstCostSum = 0;
    let enhTfsEstCostSum = 0;
    let subCashOfferCostSum = 0;
    let subCashOfferTfsCostSum = 0;
    let subCashOfferEnhTfsCostSum = 0;

    this.terms.forEach(term => {
      const { estCost, tfsCost, enhTfsCost } = term.getEstimatedCosts();
      estCostSum += estCost;
      tfsEstCostSum += tfsCost;
      enhTfsEstCostSum += enhTfsCost;

      // fn saves these costs to the term fields
      const { subCashEstCost, subCashTfsEstCost, subCashEnhTfsEstCost } = term.getSubCashEstCost();

      const distribution = (this.forecastedSales * assignNumberValue(term.fields.termPen)) / 100.0;
      subCashOfferCostSum += subCashEstCost * distribution;
      subCashOfferTfsCostSum += subCashTfsEstCost * distribution;
      subCashOfferEnhTfsCostSum += subCashEnhTfsEstCost * distribution;
    });

    this.fields.estCost = assignDollarCents(estCostSum);
    this.fields.tfsEstCost = assignDollarCents(tfsEstCostSum);
    this.fields.enhTfsEstCost = assignDollarCents(enhTfsEstCostSum);

    this.fields.subCashOfferCost = assignDollarCents(subCashOfferCostSum);
    this.fields.subCashOfferTfsCost = assignDollarCents(subCashOfferTfsCostSum);
    this.fields.subCashOfferEnhTfsCost = assignDollarCents(subCashOfferEnhTfsCostSum);
  };

  updateOfferCosts(ryoEarnings: number) {
    this.fields.offerEarnings = calculateOfferEarning(this.forecastedSales, ryoEarnings);
    this.fields.offerCost = calculateOfferCost(this.forecastedSales, this.fields.estCost, 0);
    this.fields.offerTfsCost = calculateOfferCost(this.forecastedSales, this.fields.tfsEstCost, 0);
    this.fields.offerEnhTfsCost = calculateOfferCost(this.forecastedSales, this.fields.enhTfsEstCost, 0);

    this.fields.totalOfferCost = this.fields.offerCost + this.fields.subCashOfferCost;
    this.fields.offerBalance = calculateOfferBalance(this.fields.offerEarnings, this.fields.totalOfferCost);
  }

  getEnhancedTerms() {
    return this.terms.filter(term => !term.isEnhanced);
  }

  updateForecastedSales = (forecastedSales: number) => {
    this.terms.forEach(term => (term.forecastedSales = forecastedSales));
  };

  get forecastedSales() {
    return this.terms[0].forecastedSales;
  }

  // Error Handling
  get hasTermiWithDateError() {
    return this.terms.some(term => term.hasDateError);
  }

  get hasTermiWithRateError() {
    return this.terms.some(term => term.hasRateError);
  }

  get hasTermWithInvalidRate() {
    return this.terms.some(term => !term.isRateInputValid);
  }

  get hasTermWithInvalidPenRate() {
    return this.terms.some(term => !term.isPenRateInputValid);
  }

  get hasTermWithSubCashError() {
    return this.terms.some(term => term.hasSubCashError);
  }

  get hasTermWithBlendedCost() {
    return this.terms.some(term => term.fields.fundingSourceSplits.length > 1);
  }

  get hasTermWithInvalidTfsShare() {
    return this.terms.some(term => !term.isTfsShareInputValid);
  }

  get hasEnhancedTerm() {
    return this.terms.some(term => term.isEnhanced);
  }

  get hasChangedSubventionCash() {
    return this.terms.some(term => Number(term.fields.subventionCash) !== Number(term.fields.nationalSubventionCash));
  }

  get hasPenRateSumError() {
    const penRateSum = this.terms.reduce((current, term) => {
      if (term.fields.isIncluded) {
        return current + Number(term.fields.termPen);
      } else {
        return current + 0;
      }
    }, 0);

    const hasEnhancedTerm = this.terms.some(term => term.isEnhanced);

    if (hasEnhancedTerm) {
      return penRateSum !== 100;
    } else {
      return !(penRateSum === 100 || penRateSum === 0);
    }
  }
}

export default AprTierModel;
