import { ResidualValue, SetResidualValue, SetStandardRate, SetStandardRcf, SetSubvenedRcf, StdAprRate } from '../gql/generated';
import StdAprRates from '../models/StdAprRates';
import StdLeaseRcfs from '../models/StdLeaseRcfs';
import searchRcfByTierTerm from '../utils/searchRcfByTierTerm';
import SetSubvenedRcfsModel from '../models/SetSubvenedRcfsModel';
import { assignNumberValue, uuidv4 } from 'oat-common-ui';
import { makeAutoObservable } from 'mobx';

class RcfsResidualsStore {
  residuals: ResidualValue[] = [];
  stdAprRates: StdAprRates[] = [];
  stdLeaseRcfs: StdLeaseRcfs[] = [];
  subvenedLeaseRcfs: SetSubvenedRcfsModel = {};

  constructor() {
    makeAutoObservable(this);
  }

  setStandardRcfs = (rcfs: (StdLeaseRcfs | SetStandardRcf)[]) => {
    const stdLeaseRcfs = rcfs as StdLeaseRcfs[];
    // SET: Set term as maxTerm
    this.stdLeaseRcfs = stdLeaseRcfs.map(item => {
      return {
        ...item,
        term: item.term || item.maxTerm,
      };
    });
  };

  setStandardRates = (rates: (StdAprRate | SetStandardRate)[]) => {
    this.stdAprRates = rates;
  };

  setResiduals = (residuals: (ResidualValue | SetResidualValue)[]) => {
    this.residuals = residuals as ResidualValue[]; // Casting because Residual and SetResidualValue share the same structure
  };

  getStdRcfForCreateLease = (ncsRcf: number, isSET = false) => {
    // For SET: defaulting to 36 term because create lease follows same logic.
    // Secondly, we should have functional requirement with UX to handle it by term dynamically for all leases.
    const rcf = searchRcfByTierTerm(this.stdLeaseRcfs, '1+', isSET ? 36 : undefined);
    const ncs = isSET ? 0 : ncsRcf;

    return Number((rcf - ncs).toFixed(5));
  };

  setSubvenedLeaseRcfs = (subvenedRcfs: SetSubvenedRcf) => {
    this.subvenedLeaseRcfs.modelCodes = subvenedRcfs.modelCodes as number[];
    this.subvenedLeaseRcfs.seriesYear = assignNumberValue(subvenedRcfs.seriesYear);
    this.subvenedLeaseRcfs.subvenedRcfDetails = subvenedRcfs.subvenedRcfDetails
      ?.map(rcfDetail => ({
        uuid: uuidv4(),
        rcf: rcfDetail?.rcf?.toFixed(5),
        modelCodes: rcfDetail?.modelCodes as string[],
        term: assignNumberValue(rcfDetail?.term),
      }))
      .sort((a, b) => {
        return a.term - b.term || Number(a.rcf) - Number(b.rcf);
      });
  };
}

export default RcfsResidualsStore;
