import { CreateLeaseModels, DD365API, DD365InventoryResponse, DD365VinDetailsResponse } from 'oat-common-ui';
import authClient from '../authClient';

class DD365Store {
  vinApi: Record<string, DD365VinDetailsResponse> = {}; // vin cache
  inventoryApi: Record<string, DD365InventoryResponse> = {}; // inventory cache

  getDD35Inventory = async (region: string, year: string, model: string) => {
    const key = `${region}/${year}/${model}`;
    if (this.inventoryApi[key]) {
      return this.inventoryApi[key];
    }

    const { headers } = await this.getHeaders();
    const domain = `${process.env.REACT_APP_REST_PROXY_URL}`;
    const res = await DD365API.getDD365Inventory(domain, headers.Authorization, region, year, model, 'A,F,G');
    this.inventoryApi[key] = res.data;

    return res.data;
  };

  /**
   * API Call to get vin details
   * @param vin
   * @param brand
   * @returns
   */
  getDD365VinDetails = async (vin: string, brand: string) => {
    if (this.vinApi[vin]) {
      return this.vinApi[vin];
    }

    const { headers } = await this.getHeaders();
    const domain = `${process.env.REACT_APP_REST_PROXY_URL}`;

    try {
      const res = await DD365API.getDD365VinDetails(headers.Authorization, domain, vin);
      this.vinApi[vin] = res.data;
      return res.data;
    } catch (e) {
      return undefined;
    }
  };

  /**
   * Uses API call and converts response to VinItem
   * @param vin
   * @param brand
   * @returns
   */
  getDD365VinDetailsItem = async (vin: string, brand: string) => {
    const res = await this.getDD365VinDetails(vin, brand);
    const vinItem = new CreateLeaseModels.VinItem();

    if (res) {
      vinItem.setFromDD365(res);
    }

    return vinItem;
  };

  getHeaders = async () => {
    let token = await authClient.getToken();

    if (!token) {
      token = process.env.REACT_APP_TOKEN || '';
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    return { headers };
  };
}

export default DD365Store;
