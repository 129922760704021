import { observer, useLocalObservable } from 'mobx-react-lite';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader, useToast } from 'oat-common-ui';
import { ModalTitle } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import { FEATURE_OR_2947_PHASE_2 } from '../../../../constants/global';
import { useSaveSubventionCashMutation } from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import AprCardModel from '../../models/AprCardModel';
import SubventionCashInputs from './SubventionCashInputs';
import SubventionCashModel, { SubventionCashModelFields } from './SubventionCashModel';
import SubventionCashTable from './SubventionCashTable';

interface Props {
  aprCard: AprCardModel;
  onClose: () => void;
  saveApr: () => void;
}

interface SubventionCashInput {
  subCashTfsCostShareCap: number;
  subCashTfsShare: number;
  subventionCash: number;
  term: number;
  tier: string;
}

interface SaveSubventionCashInput {
  offerId: string;
  rev: string;
  subventionCashInputs: SubventionCashInput[];
}

const transformSubventionCashInputs = (fields: SubventionCashModelFields): SubventionCashInput[] => {
  const { subventionCash, subventionCashInputs, subventionCashTableCells } = fields;

  const checkedTerms = subventionCashTableCells.filter(cell => cell.checked);

  return checkedTerms.map(checkedTerm => {
    const inputForTerm = subventionCashInputs.find(cashInput => cashInput.term === checkedTerm.term);

    return {
      subCashTfsShare: inputForTerm?.subCashTfsShare || 0,
      subCashTfsCostShareCap: inputForTerm?.subCashTfsCostShareCap || 0,
      subventionCash,
      term: checkedTerm.term,
      tier: checkedTerm.tier,
    };
  });
};

const SubventionCashModal = ({ aprCard, saveApr, onClose }: Props) => {
  const subventionCashModel = useLocalObservable(() => new SubventionCashModel(aprCard));

  const {
    programDetailsStore,
    seriesMappingStore: { seriesMapping },
  } = useStores();
  const { region } = useUrlParams();

  const [saveSubvention] = useSaveSubventionCashMutation();
  const { error } = useToast();
  const hasCostShareOrCapError = subventionCashModel.fields.subventionCashInputs.find(field => isNaN(field.subCashTfsCostShareCap) || isNaN(field.subCashTfsShare));
  const isSaveDisabled = subventionCashModel.subCashInputHasError(0, 'subventionCash') || Boolean(hasCostShareOrCapError);

  const handleSubmit = async () => {
    const { offerId, rev, fields } = subventionCashModel;

    const payload: SaveSubventionCashInput = {
      offerId,
      rev,
      subventionCashInputs: transformSubventionCashInputs(fields),
    };

    try {
      const res = await trackPromise(saveSubvention({ variables: { input: payload } }));
      const updatedOffer = res.data?.saveSubventionCash.offer;

      if (updatedOffer) {
        aprCard.updateRev(updatedOffer.rev);
        aprCard.initData({ offer: { regional: updatedOffer, national: aprCard.nationalOffer }, seriesMapping, region, offering: programDetailsStore.offering });

        for (const tier of aprCard.tierMap.values()) {
          tier.updateEstCosts();
          tier.updateOfferCosts(programDetailsStore.ryoEarnings);
        }

        // using handleInputChange from AprCard
        await saveApr();
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      onClose();
    }
  };

  return (
    <Modal isOpen onClose={onClose} size="lg">
      <ModalHeader onClose={onClose}>
        <ModalTitle>Edit {FEATURE_OR_2947_PHASE_2 ? 'Finance Subvention Cash' : 'APR Subvention Cash'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <SubventionCashInputs subventionCashModel={subventionCashModel} aprCard={aprCard} />
        <SubventionCashTable subventionCashModel={subventionCashModel} />
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button variant="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isSaveDisabled}>
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default observer(SubventionCashModal);
