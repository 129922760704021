import { makeAutoObservable } from 'mobx';
import defaultOffering from '../defaultObjects/defaultOffering';
import defaultRgnlAlt from '../defaultObjects/defaultRgnlAlt';
import defaultSeriesProfile from '../defaultObjects/defaultSeriesProfile';
import { Offering, RgnlAlt, SeriesProfile } from '../gql/generated';
import AprCardModel from '../pages/ProgramDetails/models/AprCardModel';
import CashCardModel from '../pages/ProgramDetails/models/CashCardModel';
import MiscCardModel from '../pages/ProgramDetails/models/MiscCardModel';

class CreateOfferStore {
  offering: Offering = defaultOffering;
  seriesProfile: SeriesProfile = defaultSeriesProfile;
  rgnlAlt: RgnlAlt = defaultRgnlAlt;
  series = '';
  seriesYear = '';

  aprCard = new AprCardModel();
  cashCard = new CashCardModel();
  miscCard = new MiscCardModel();

  constructor() {
    makeAutoObservable(this);
  }

  setSeriesProfileData = (seriesProfile: SeriesProfile) => {
    this.seriesProfile = seriesProfile;
    this.seriesYear = seriesProfile.series[0].seriesYear;
    this.series = seriesProfile.series[0].series;

    const rgnlAlt = seriesProfile.rgnlAlts?.find(item => item.isSelected);

    if (rgnlAlt) {
      this.rgnlAlt = rgnlAlt;
    }
  };

  setOffering = (offering: Offering) => {
    this.offering = offering;
  };

  setApr = (card: AprCardModel) => {
    this.aprCard = card;
  };

  setCash = (card: CashCardModel) => {
    this.cashCard = card;
  };

  setMisc = (card: MiscCardModel) => {
    this.miscCard = card;
  };
}

export default CreateOfferStore;
