import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { BlockInputLabel, Dropdown, Input } from 'oat-common-ui';
import { ReturningBrands, Status } from '../../../../../constants/global';
import useStores from '../../../../../stores/useStores';
import { getDropdownItemFromEnum } from '../../../../../utils/getDropdownItemFromEnum';
import MiscCardModel from '../../../models/MiscCardModel';
import styles from '../styles.module.scss';

type ReturningSeriesProps = {
  card: MiscCardModel;
  onChange: () => void;
  forCreate?: boolean;
};

const ReturningSeriesSection = ({ card, onChange, forCreate = false }: ReturningSeriesProps) => {
  const { programDetailsStore } = useStores();
  const {
    fields: { returningBrands, returningSeries },
    updateField,
  } = card;

  const isDisabled = programDetailsStore.offering.status === Status.MEETS_GUIDELINES || card.isDisabled;

  return (
    <div className={styles.marginTop30}>
      <BlockInputLabel label="Returning Brands" className={cx(styles.inlineInput, styles.inputContainer)} labelClass={styles.inputLabel}>
        <Dropdown
          darkTheme={!forCreate}
          minWidth={200}
          id="returning-brands"
          value={returningBrands}
          options={getDropdownItemFromEnum(ReturningBrands)}
          onChange={item => {
            updateField('returningBrands', item.value);
            onChange();
          }}
          disabled={isDisabled}
        />
      </BlockInputLabel>
      <BlockInputLabel label="Returning Series" className={styles.inlineInput} labelClass={styles.inputLabel}>
        <Input
          darkTheme={!forCreate}
          required
          error={!returningSeries}
          value={returningSeries}
          onChange={e => {
            updateField('returningSeries', e.currentTarget.value);
            onChange();
          }}
          disabled={isDisabled}
        />
      </BlockInputLabel>
    </div>
  );
};

export default observer(ReturningSeriesSection);
