import { SeriesProfile, useGetContestNumbersQuery } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import { SETContestNumbersByType } from '../../../models/SETContestNumber';
import useStores from '../../../stores/useStores';
import useUserInfo from '../../../utils/useUserInfo';
import LoadingPage from '../../LoadingPage';
import useCreateOffer from '../useCreateOffer';
import { createCashCardForCreate } from '../utils/createCashCard';
import CreateCashComponent from './CreateCashComponent';

const CreateCash = () => {
  const { period } = useUrlParams();
  const { isSETUser } = useUserInfo();
  const { data: setData, loading: setLoading, error: setError } = useGetContestNumbersQuery({ variables: { offeringId: period }, skip: !isSETUser() });
  const { loading, error, data } = useCreateOffer();

  const {
    userInfoStore,
    createOfferStore: { setCash, rgnlAlt },
    programDetailsStore: { setSETContestNumbersByType },
  } = useStores();
  const { region } = useUrlParams();

  const breadCrumbs = [{ name: userInfoStore.properties.abbrName }];

  const isLoading = setLoading || loading;
  const hasError = setError || error;

  if (isLoading || hasError) {
    return <LoadingPage breadCrumbs={breadCrumbs} error={!!hasError} />;
  }

  if (setData) {
    setSETContestNumbersByType(setData.contestNumbers.setContestNumbers as SETContestNumbersByType[]);
  }

  if (data) {
    const card = createCashCardForCreate(data.offering.startDate, data.offering.endDate, region,rgnlAlt, data.seriesProfile as SeriesProfile);
    setCash(card);
  }

  return <CreateCashComponent />;
};

export default CreateCash;
