import LeaseTermModel from '../pages/ProgramDetails/models/LeaseTermModel';

export const getHigherTermUpToLeases = (leaseTerms: LeaseTermModel[], term: number) => {
  let isUpToApplied = false;

  const highLeaseTerms = leaseTerms.filter(item => Number(item.term) > term);

  highLeaseTerms.forEach(leaseTerm => {
    leaseTerm.leaseForms.forEach(item => {
      if (item.inputs.isUpToApplied) {
        isUpToApplied = true;
      }
    });
  });

  return {
    highLeaseTerms,
    isUpToApplied,
  };
};
