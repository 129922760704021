import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { BlockInputLabel, Button, InlineInputLabel, Input, OATIcon } from 'oat-common-ui';
import RadioInput from '../../../../../components/RadioInput';
import { Status } from '../../../../../constants/global';
import useStores from '../../../../../stores/useStores';
import MiscCardModel from '../../../models/MiscCardModel';
import styles from '../styles.module.scss';
import { useEffect } from 'react';

type InputTableProps = {
  card: MiscCardModel;
  onChange: () => void;
  forCreate?: boolean;
};

const InputTable = ({ card, onChange, forCreate = false }: InputTableProps) => {
  const { programDetailsStore } = useStores();
  const {
    fields: { type, modelRestrictions, competitorDetails, transferable },
    isStandAlone,
    updateArray,
    updateField,
  } = card;
  const { CASH_TYPES } = AdminConstants;
  const isTradeIn = type === CASH_TYPES.TRADE_IN_ASSISTANCE;
  const array = isTradeIn ? modelRestrictions : competitorDetails;
  const arrayLength = array?.length ?? 0;
  const isRemoveEnabled = arrayLength > 1;
  const isListEmpty = arrayLength === 0;
  const getArrayType = isTradeIn ? 'modelRestrictions' : 'competitorDetails';
  const isDisabled = programDetailsStore.offering.status === Status.MEETS_GUIDELINES || card.isDisabled;

  useEffect(() => {
    // Ensure that the array always has at least one element when the type is CASH_TYPES.CONQUEST
    if (isListEmpty) {
      updateArray(getArrayType, 'add');
    }
  }, [getArrayType, isListEmpty, updateArray])

  return (
    <>
      <BlockInputLabel
        className={clsx(styles.modelTableHeader, isTradeIn ? styles.marginTop70 : styles.marginTop30)}
        label={isTradeIn ? 'Model Restrictions' : 'Competitor Details'}
      />
      <div className={styles.modelTable}>
        {array?.map((model, index) => (
          <div key={index} className={styles.modelTableRow}>
            <BlockInputLabel label="Make" className={styles.inlineInput} labelClass={styles.inputLabel}>
              <Input
                darkTheme={!forCreate}
                required
                readOnly={!isStandAlone}
                error={!model.make}
                value={model.make}
                onChange={e => {
                  updateArray(getArrayType, 'edit', index, { make: e.currentTarget.value, model: model.model, years: model.years });
                  onChange();
                }}
                disabled={isDisabled}
              />
            </BlockInputLabel>
            <BlockInputLabel label="Model" className={styles.inlineInput} labelClass={styles.inputLabel}>
              <Input
                darkTheme={!forCreate}
                required
                readOnly={!isStandAlone}
                error={!model.model}
                value={model.model}
                onChange={e => {
                  updateArray(getArrayType, 'edit', index, { make: model.make, model: e.currentTarget.value, years: model.years });
                  onChange();
                }}
                disabled={isDisabled}
              />
            </BlockInputLabel>
            <BlockInputLabel label="Model Year" className={styles.inlineInput} labelClass={styles.inputLabel}>
              <Input
                darkTheme={!forCreate}
                required
                readOnly={!isStandAlone}
                error={!model.years}
                value={model.years}
                onChange={e => {
                  updateArray(getArrayType, 'edit', index, { make: model.make, model: model.model, years: e.currentTarget.value });
                  onChange();
                }}
                disabled={isDisabled}
              />
            </BlockInputLabel>
            {(isStandAlone && isRemoveEnabled) && (
              <Button
                variant="text"
                onClick={() => {
                  updateArray(getArrayType, 'remove', index);
                  onChange();
                }}
                className={clsx(styles.iconBtn, styles.removeIcon)}
                disabled={isDisabled}
              >
                -
              </Button>
            )}
          </div>
        ))}
      </div>
      {isStandAlone && (
        <Button
          className={styles.addVehicleBtn}
          onClick={() => {
            updateArray(getArrayType, 'add');
            onChange();
          }}
          disabled={isDisabled}
        >
          <OATIcon icon="add" className={styles.addVehicleIcon} />
          Add Vehicle
        </Button>
      )}

      {type === CASH_TYPES.CONQUEST && (
        <InlineInputLabel label="Transferable" contentClass={styles.radioBtnWrapper} className={styles.inputLabel} inputWrapperClass={styles.marginTop30}>
          <RadioInput
            id={`misc-transferable-conquest-${card.id}`}
            checked={transferable}
            disabled={isDisabled}
            labelClass={styles.radioBtn}
            onClick={() => {
              isStandAlone && updateField('transferable', !transferable);
              onChange();
            }}
            onChange={() => {}}
          />
          <span className={styles.radioBtnText}>Yes</span>
        </InlineInputLabel>
      )}
    </>
  );
};

export default observer(InputTable);
