import { CreateLeaseModels, STOP_SALE_CAMPAIGNS_SSC, STOP_SALE_CODES_VIS, STOP_SALE_COMPLETION } from 'oat-common-ui';
import { EFCStopSaleVIS } from '../../../../../models/EFCStopSaleVIS';
import { EFCStopSaleSSC } from '../../../../../models/EFcStopSaleSSC';

const setStopSaleData = (vinItem: CreateLeaseModels.VinItem, stopSaleSSC?: EFCStopSaleSSC, stopSaleVIS?: EFCStopSaleVIS) => {
  let isStopSaleSSC = false;
  let isStopSaleVIS = false;
  if (stopSaleSSC) {
    const campaignList =
      stopSaleSSC.GetSpecialServiceCampaignResponse.GetSpecialServiceCampaignBOD.GetSpecialServiceCampaignDataArea.SpecialServiceCampaignPayload.SpecialServiceCampaigns;

    if (campaignList && campaignList.length > 0) {
      isStopSaleSSC = campaignList.some(campaign => STOP_SALE_CAMPAIGNS_SSC.includes(campaign.DetailType) && campaign.CompletionDescription === STOP_SALE_COMPLETION);
    }
  }
  if (stopSaleVIS) {
    isStopSaleVIS = STOP_SALE_CODES_VIS.includes(stopSaleVIS.Show.VehicleInventoryBOD.DataArea.VehicleInventory.DistStatus.VehStopSaleList.VehStopSaleSevereCode);
  }

  // set stopSale value here
  vinItem.setStopSale(isStopSaleSSC || isStopSaleVIS ? 'Y' : 'N');
};

export default setStopSaleData;
