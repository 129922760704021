import { formatValue } from '../../../../../../../components/NumberInput/utils';
import styles from './styles.module.scss';

interface Props {
  rate?: number;
  term?: number;
}

const PaymentDetails = ({ rate, term }: Props) => {
  return (
    <div className={styles.paymentDetails}>
      <div className={styles.detail}>
        <span className={styles.value}>{formatValue(rate, false, false, false, true) || 0} %</span>
        <span className={styles.label}>APR</span>
      </div>
      <span className={styles.divider} />
      <div className={styles.detail}>
        <span className={styles.value}>{term?.toString()}</span>
        <span className={styles.label}>MONTHS</span>
      </div>
    </div>
  );
};

export default PaymentDetails;
