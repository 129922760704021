import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { OATWrapper } from 'oat-common-ui';
import IpBreadCrumbs from '../../../components/IpComponents/IpBreadCrumbs';
import MainHeader from '../../../components/MainHeader';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import FetchTfsCostShareAvailabilities from '../../ProgramDetails/components/FetchTfsCostShareAvailabilities';
import CreateAdGstLease from './CreateAdGstLease';
import CreateAdLease from './CreateAdLease';
import CreateNonAdLease from './CreateNonAdLease';

const moduleTitle = 'Create Lease Offer';
const { RegionCodes } = AdminConstants;

const CreateLeaseComponent = () => {
  const {
    userInfoStore: { isNational },
    createLeaseStore: { offering, seriesProfile, rgnlAlt, tab },
  } = useStores();
  const isGST = offering.regionCode === RegionCodes.GST;
  const { profile } = useUrlParams();
  const isAdvertised = tab === 1;

  return (
    <FetchTfsCostShareAvailabilities>
      <MainHeader
        breadCrumbs={IpBreadCrumbs(isNational(), offering, offering.regionCode, seriesProfile, rgnlAlt, `${moduleTitle}  `, '', false, profile)}
        headerTitle={moduleTitle}
      />
      <OATWrapper>{isAdvertised ? isGST ? <CreateAdGstLease /> : <CreateAdLease /> : <CreateNonAdLease />}</OATWrapper>
    </FetchTfsCostShareAvailabilities>
  );
};

export default observer(CreateLeaseComponent);
