import { observer } from 'mobx-react-lite';
import { BlockInputLabel, Button } from 'oat-common-ui';
import { useState } from 'react';
import DollarDisplayCosts from '../../../../components/DollarDisplayCosts';
import NumberInput from '../../../../components/NumberInput';
import { CostTooltipLabel } from '../../../../components/raCostTooltips';
import { Status } from '../../../../constants/global';
import useStores from '../../../../stores/useStores';
import { assignDollarCents } from '../../../../utils/assignValue';
import { formatDollar } from '../../../createOffer/CreateLease/CreateAdLease/utils/utils';
import RgnlAltModel from '../../models/RgnlAltModel';
import FsvModal from './FsvModal';
import styles from './styles.module.scss';
import { renderBalanceTooltip, renderCostsTooltip, renderEarningsTooltip } from './tooltips';

interface Props {
  isConfirmedView?: boolean;
  rgnlAlt: RgnlAltModel;
  showOnlyDollarDisplay?: boolean;
}

const FsvCosts = ({ isConfirmedView, rgnlAlt, showOnlyDollarDisplay = false }: Props) => {
  const {
    offeringCostsStore,
    summaryStore: { profiles, offering, totalForecastedSales },
  } = useStores();
  const [openFsvModal, setOpenFsvModal] = useState(false);

  const earnings = offeringCostsStore.raCosts[rgnlAlt.id]?.raEarnings ?? 0;
  const cost = offeringCostsStore.raCosts[rgnlAlt.id]?.raCost ?? 0;
  const balance = assignDollarCents(earnings - cost);
  const isDisabled = offering.status === Status.MEETS_GUIDELINES;

  const renderDisplayCosts = () => {
    return (
      <DollarDisplayCosts
        id={rgnlAlt.id}
        earnings={earnings}
        earningsTooltip={<>{renderEarningsTooltip(rgnlAlt)}</>}
        earningsLabel={CostTooltipLabel.EST_EARNINGS}
        cost={cost}
        costTooltip={<>{renderCostsTooltip(rgnlAlt)}</>}
        costLabel={CostTooltipLabel.EST_COST}
        balance={balance}
        balanceLabel={CostTooltipLabel.EST_BALANCE}
        balanceTooltip={<>{renderBalanceTooltip(rgnlAlt)}</>}
        showBalanceTooltip
        showOperations
      />
    );
  };

  if (showOnlyDollarDisplay) {
    return renderDisplayCosts();
  }

  return (
    <>
      <div className={styles.fsvCostsWrapper}>
        <div className={styles.column}>
          {isConfirmedView ? (
            <div className={styles.confirmedFsv}>
              <span className={styles.cost}>{rgnlAlt.fsv.toString()}</span>
              <span className={styles.label}>Forecasted Sales Volume</span>
            </div>
          ) : (
            <>
              <BlockInputLabel label="Forecasted Sales">
                <Button id={`ra-fsv-${rgnlAlt.id}`} className={styles.fsvBtn} variant="input" onClick={() => setOpenFsvModal(true)} disabled={isDisabled}>
                  {formatDollar(rgnlAlt.fsv.toString())}
                </Button>
              </BlockInputLabel>

              {Number(rgnlAlt.ryoEarnings) !== 0 && (
                <BlockInputLabel label="RYO Earnings ">
                  <NumberInput id={`ra-ryo-earnings-${rgnlAlt.id}`} value={rgnlAlt.ryoEarnings} disabled={isDisabled} readOnly wholeNumber units />
                </BlockInputLabel>
              )}
            </>
          )}
        </div>
        <div className={styles.column}>{renderDisplayCosts()}</div>
      </div>
      {openFsvModal && (
        <FsvModal
          profiles={profiles}
          offering={offering}
          totalForecastedSales={totalForecastedSales}
          raId={rgnlAlt.id}
          seriesProfileId={rgnlAlt.seriesProfileId}
          onClose={() => setOpenFsvModal(false)}
        />
      )}
    </>
  );
};

export default observer(FsvCosts);
