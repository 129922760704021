import { DefaultModal, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { OfferingCosts, useDeleteOfferMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import styles from './styles.module.scss';

interface Props {
  id: string;
  rev: string;
  tier?: string;
  term?: number;
  onClose: () => void;
  onSubmit: (id?: string, rev?: string) => void;
}

const RemoveOfferModal = ({ id, rev, tier, term, onClose, onSubmit }: Props) => {
  const {
    offeringCostsStore: { setData },
  } = useStores();
  const [deleteOffer] = useDeleteOfferMutation();
  const { error } = useToast();
  const handleSubmit = async () => {
    try {
      const res = await trackPromise(
        deleteOffer({
          variables: {
            id,
            rev,
            tier,
            term,
          },
        }),
      );

      const offer = res.data?.deleteOffer.offer;
      setData(res.data?.deleteOffer.offeringCosts as OfferingCosts);
      onSubmit(offer?.id, offer?.rev);
    } catch (e) {
      error((e as Error).message);
    } finally {
      onClose();
    }
  };

  return (
    <DefaultModal
      message="Removing this offer will erase it, as well as any data you've entered for the offer."
      onClose={onClose}
      onSubmit={handleSubmit}
      open
      modalBodyClassName={styles.modal}
      title="Remove Offer"
      submitText="Confirm"
    />
  );
};

export default RemoveOfferModal;
