import useUrlParams from '../hooks/useUrlParams';
import useStores from '../stores/useStores';

const useGetTfsLabel = () => {
  const {
    userInfoStore: { isLexus },
  } = useStores();
  const { region } = useUrlParams();

  if (region === '500') {
    return 'SETF';
  }

  return isLexus() ? 'LFS' : 'TFS';
};

export default useGetTfsLabel;
