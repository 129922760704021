import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import InfoTooltip from '../../../../components/InfoTooltip';
import TooltipList from '../../../../components/TooltipList/TooltipList';
import useStores from '../../../../stores/useStores';
import EnhTooltipListItem from './EnhTooltipListItem';

import clsx from 'clsx';
import MiscTfsContracts from './MiscTfsContracts';
import styles from './styles.module.scss';
import { renderCostShare, renderExclusions, renderTierTerms, renderTiersAndTerms, renderSubCashCostShare } from './utils';

interface Props {
  offerId: string;
  optionTypeName: string;
  selectedTier?: string;
  className?: string;
}

const { OPTION_TYPE_NAMES } = AdminConstants;

const EnhCostShareTooltip = ({ offerId, optionTypeName, selectedTier, className }: Props) => {
  const {
    programDetailsStore: { enhancedCostShares },
  } = useStores();

  const renderTooltipMessage = () => {
    const filteredEnhancedCostShares = enhancedCostShares.filter(costShare => costShare.optionTypeName === optionTypeName);

    if (filteredEnhancedCostShares.length) {
      return (
        <>
          {filteredEnhancedCostShares.map((enhancedCostShare, i) => {
            return (
              <div key={`${enhancedCostShare.offerId}-${i}`} className={styles.listItem}>
                <TooltipList>
                  <EnhTooltipListItem headerText="Cost Share" body={renderCostShare(enhancedCostShare, selectedTier || '', optionTypeName)} />
                  {(optionTypeName === OPTION_TYPE_NAMES.APR || optionTypeName === OPTION_TYPE_NAMES.LEASE) && (
                    <EnhTooltipListItem
                      headerText="Sub Cash Cost Share"
                      body={renderSubCashCostShare(enhancedCostShare)}
                    />
                  )}
                  <EnhTooltipListItem headerText="Exclusions" body={renderExclusions(enhancedCostShare)} bodyClassName={styles.tooltipBody} />
                  <EnhTooltipListItem headerText="Date Range" body={`${enhancedCostShare.startDate} - ${enhancedCostShare.endDate}`} />
                  {optionTypeName === OPTION_TYPE_NAMES.LEASE && renderTiersAndTerms(enhancedCostShare)}
                  {optionTypeName === OPTION_TYPE_NAMES.APR && renderTierTerms(enhancedCostShare)}
                  {optionTypeName !== OPTION_TYPE_NAMES.APR && optionTypeName !== OPTION_TYPE_NAMES.LEASE && (
                    <EnhTooltipListItem
                      headerText="TFS Contracts"
                      showHeaderText={!!enhancedCostShare.tfsContracts?.length}
                      bodyClassName={styles.tfsContractsBody}
                      body={<MiscTfsContracts enhancedCostShare={enhancedCostShare} />}
                    />
                  )}
                </TooltipList>

                {filteredEnhancedCostShares.length > 1 && i !== filteredEnhancedCostShares.length - 1 && <hr className={styles.divider} />}
              </div>
            );
          })}
        </>
      );
    }
  };

  return (
    <div className={clsx(styles.enhCostShareTooltip, className)}>
      <InfoTooltip
        id={`${optionTypeName}-tooltip-${offerId}`}
        message={renderTooltipMessage()}
        iconRightPadding
        buttonContentText="Enhanced Cost Share is available"
        addBodyStyles={styles.tooltipMessageParent}
        placement="top"
      />
    </div>
  );
};

export default observer(EnhCostShareTooltip);
