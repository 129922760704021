import ReconcileDataModel from '../../models/ReconcileDataModel';

interface Props {
  reconcileData: ReconcileDataModel;
}

const CompletedBy = ({ reconcileData }: Props) => {
  const { lastReconcileCompletedBy, lastReconcileUpdatedBy, lastReconcileCompletedDate, lastReconcileUpdatedDate } = reconcileData;

  const user = lastReconcileUpdatedBy ? lastReconcileUpdatedBy : lastReconcileCompletedBy;
  const date = lastReconcileUpdatedDate ? lastReconcileUpdatedDate : lastReconcileCompletedDate;

  if (user && date) {
    return <span>{`Last Updated By ${user} on ${date}`}</span>;
  }

  return null;
};

export default CompletedBy;
