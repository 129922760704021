import { observer } from 'mobx-react-lite';
import { useInputDelay, useToast } from 'oat-common-ui';
import usePenRatesSaveUtils from '../../../../components/PenRateTable/usePenRatesSaveUtils';
import { Status } from '../../../../constants/global';
import { OfferingCosts, PenetrationDetails, SavePenetrationResult } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import { handleEnhancedDates } from '../../../../utils/handleEnhancedDates';
import { returnFormattedDateValues } from '../../../../utils/returnFormattedDatesValues';
import PenRateModel from '../../models/PenRateModel';
import ProfileModel from '../../models/ProfileModel';
import RgnlAltModel from '../../models/RgnlAltModel';
import AddNationalNote from './components/Expanded/AddNationalNote';
import ExpAprRow from './components/Expanded/APR/ExpAprRow';
import ExpCashRow from './components/Expanded/Cash/ExpCashRow';
import ExpSummaryTableHeader from './components/Expanded/ExpSummaryTableHeader';
import FooterButtons from './components/Expanded/FooterButtons';
import ExpLeaseRow from './components/Expanded/Lease/ExpLeaseRow';
import ExpMiscRow from './components/Expanded/Misc/ExpMiscRow';
import AprRow from './components/NotExpanded/Apr/AprRow';
import CashRow from './components/NotExpanded/Cash/CashRow';
import LeaseRow from './components/NotExpanded/Lease/LeaseRow';
import MiscRow from './components/NotExpanded/Misc/MiscRow';
import NotExpTableHeader from './components/NotExpanded/NotExpTableHeader';
import styles from './styles.module.scss';

interface Props {
  profile: ProfileModel;
}

const SummaryTable = ({ profile }: Props) => {
  const {
    summaryStore: {
      offering: { startDate, endDate },
    },
    offeringCostsStore,
  } = useStores();
  const { setDelay } = useInputDelay();
  const { error } = useToast();

  const { isExpanded, vehicles, inputIsDisabled, addNote, getSelectedRgnAlt } = profile;
  const selectedRa = getSelectedRgnAlt();
  const excludedVehicles = vehicles.filter(vehicle => !vehicle.isInclusion);
  const includedVehicles = vehicles.filter(vehicle => vehicle.isInclusion);

  const handlePostSave = (_penetrationDetails: PenetrationDetails[], res?: SavePenetrationResult) => {
    offeringCostsStore.setData(res?.offeringCosts as OfferingCosts);
  };
  const { savePenRate } = usePenRatesSaveUtils(selectedRa as RgnlAltModel, handlePostSave);

  const handleSavePenRate = () => {
    setDelay(async () => {
      try {
        await savePenRate();
      } catch (e) {
        error((e as Error).message);
      }
    });
  };

  const handleAfterOnChange = () => {
    selectedRa?.postCalculatePenAndSales();
    handleSavePenRate();
  };

  const handleOnToggle = (model: PenRateModel) => {
    selectedRa?.toggleFourthOption(model.id, !model.isFourthOption);
    handleSavePenRate();
  };

  const showDates = (model: PenRateModel) => {
    if (startDate !== model.startDate || endDate !== model.endDate) {
      if (isExpanded) {
        const { startDateMonth, startDateDay, startDateYear, endDateMonth, endDateDay, endDateYear } = returnFormattedDateValues(model.startDate, model.endDate);

        return (
          <span className={styles.headerLeft}>
            {startDateMonth} {startDateDay}, {startDateYear} - {endDateMonth} {endDateDay}, {endDateYear}
          </span>
        );
      } else {
        return <span className={styles.dateValues}>{handleEnhancedDates(model.startDate, model.endDate, startDate, endDate, true)}</span>;
      }
    }

    return null;
  };

  if (!isExpanded) {
    return (
      <div className={styles.notExpandedView}>
        {selectedRa?.status === Status.MEETS_GUIDELINES && profile.note && <AddNationalNote profile={profile} isDisabled />}
        <NotExpTableHeader selectedRa={selectedRa} />
        <table className={styles.notExpTbl}>
          <tbody>
            <CashRow cashModels={selectedRa?.cash} showDates={showDates} />
            <AprRow aprModels={selectedRa?.apr} showDates={showDates} />
            <LeaseRow leaseModels={selectedRa?.lease} showDates={showDates} />
            <MiscRow miscModels={selectedRa?.misc} showDates={showDates} onToggle={handleOnToggle} />
          </tbody>
        </table>
        <FooterButtons profile={profile} selectedRa={selectedRa} />
      </div>
    );
  }

  return (
    <div className={styles.expandedView}>
      {(addNote || selectedRa?.status === Status.REVISED) && <AddNationalNote profile={profile} />}
      <ExpSummaryTableHeader selectedRa={selectedRa} inputIsDisabled={inputIsDisabled} />
      <ExpCashRow cashModels={selectedRa?.cash} inputIsDisabled={inputIsDisabled} showDates={showDates} onSave={handleAfterOnChange} />
      <ExpAprRow
        aprModels={selectedRa?.apr}
        inputIsDisabled={inputIsDisabled}
        showDates={showDates}
        excludedVehicles={excludedVehicles}
        includedVehicles={includedVehicles}
        onSave={handleAfterOnChange}
      />
      <ExpLeaseRow
        leaseModels={selectedRa?.lease}
        inputIsDisabled={inputIsDisabled}
        showDates={showDates}
        excludedVehicles={excludedVehicles}
        includedVehicles={includedVehicles}
        onSave={handleAfterOnChange}
      />
      <ExpMiscRow miscModels={selectedRa?.misc} inputIsDisabled={inputIsDisabled} showDates={showDates} onSave={handleAfterOnChange} onToggle={handleOnToggle} />
      <FooterButtons profile={profile} selectedRa={selectedRa} isExpanded />
    </div>
  );
};

export default observer(SummaryTable);
