import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants, AdminModels } from 'oat-admin-common';
import { BlockInputLabel, Button, Checkbox, Dropdown, InlineInputLabel, Input, SeriesCodeToNameMap, dateFormatISOString, dateStringToDate, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import mapVehicleDataFromAccordion from '../../../../components/Accordion/utils/mapVehicleDataFromAccordion';
import BoxSelector from '../../../../components/BoxSelector';
import NumberInput from '../../../../components/NumberInput';
import StartEndDate from '../../../../components/StartEndDate';
import { FEATURE_OR_2477, FEATURE_OR_4154, FEATURE_OR_4756 } from '../../../../constants/global';
import { CreateLeaseOfferInput, EnhancedCostShare, LeaseOfferDetails, SaveVehicle, useCreateLeaseOfferMutation } from '../../../../gql/generated';
import useOfferEligibility from '../../../../hooks/useOfferEligibility';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import getAnnualMileagesList from '../../../../utils/getAnnualMileagesList';
import useGetTfsLabel from '../../../../utils/useGetTfsLabel';
import useUserInfo from '../../../../utils/useUserInfo';
import EnhCostShareModal from '../../../ProgramDetails/components/EnhCostShareModal';
import EnhCostShareTooltip from '../../../ProgramDetails/components/EnhCostShareTooltip';
import ExclusionInclusion from '../../../ProgramDetails/components/ExclusionInclusion';
import OfferBody, { OfferBodyLeft, OfferBodyRight } from '../../../ProgramDetails/components/OfferBody';
import CreateOfferCta from '../../CreateOfferCta';
import getLeaseOfferFromRgnlAlt from '../CreateAdLease/utils/getLeaseOfferFromRgnlAlt';
import CreateLeaseOfferDetails from '../CreateLeaseOfferDetails';
import commonStyles from '../common.module.scss';
import useCreateLeaseSave from '../useCreateLeaseSave';
import styles from './styles.module.scss';
import { formatValue } from '../../../../components/NumberInput/utils';

const { OPTION_TYPE_NAMES } = AdminConstants;

const CreateNonAdLease = () => {
  const {
    createLeaseStore,
    userInfoStore: { isSETUser, userInfo, isLexus },
    programDetailsStore: { enhancedCostShares },
  } = useStores();

  const { isLexusUser, isGSTUser } = useUserInfo();
  const { region, period, profile, regalt, offerId, offerTerm, example } = useUrlParams();
  const { error } = useToast();
  const history = useHistory();
  const [createLeaseOffer] = useCreateLeaseOfferMutation();
  const {
    offering,
    termsList,
    tiersList,
    compatibileOffers,
    vehicles,
    rgnlAlt,
    isChangeVin,
    nonAdvertisedData: {
      editVehicles,
      setEditVehicles,
      fields: {
        startDate,
        endDate,
        selectedMileage,
        selectedTermsList,
        selectedTiers,
        pnv,
        tfsShare,
        rcf,
        note,
        tdaNote,
        isBlended,
        vehiclesAccordion,
        isInclusions,
        isSpecialEdition,
        modelYear,
        series,
        nationalRcf,
      },

      hasRcfError,
      hasPnvError,
      hasTfcShareError,
      hasDateError,
      hasTermError,
      hasMileageError,
      hasInclusionsError,
      setSelectedTiers,
      setSelectedTerms,
      updateField,
      toggleIsInclusions,
    },
    setTab,
  } = createLeaseStore;
  const { saveLease, processLeaseOfferForPayload } = useCreateLeaseSave();

  const [showEnhCostShareModal, setShowEnhCostShareModal] = useState(false);
  const [showEnhTfsCostShareModal, setShowEnhTfsCostShareModal] = useState(false);
  const [isEnhCostShareAccepted, setIsEnhCostShareAccepted] = useState<boolean | undefined>();
  const [isEnhTfsCostShareAccepted, setIsEnhTfsCostShareAccepted] = useState<boolean | undefined>(undefined);
  const containTerm30OrLower = selectedTermsList.some(term => Number(term) <= 30);
  const mileages = getAnnualMileagesList(userInfo.brand, region, !containTerm30OrLower, series.toLowerCase().includes(SeriesCodeToNameMap.supra.toLowerCase()), true);

  const tfsLabel = useGetTfsLabel();

  const { handleEnhCostShare, handleEnhTfsCostShare } = useOfferEligibility();

  const transformNonAdLeaseCardData = (
    enhancedCostShare?: EnhancedCostShare,
    enhacedTfsCostShare?: AdminModels.EnhancedTfsCostShare.EnhancedTfsCostShare,
    isEnhCostShareAccepted?: boolean | null,
    isEnhTfsCostShareAccepted?: boolean | null,
  ): CreateLeaseOfferInput => {
    const [excludedList, includedList] = mapVehicleDataFromAccordion(vehiclesAccordion.items, vehicles, !isInclusions);
    const vehiclesList: SaveVehicle[] = [...excludedList, ...includedList];

    return {
      compatibilityList: [...compatibileOffers],
      endDate: dateFormatISOString(endDate),
      isAdvertised: false,
      mileage: Number(selectedMileage.value),
      note,
      name: 'Lease',
      optionTierType: isBlended ? 'Blended' : 'ByTier',
      pnv: Number(pnv),
      regionalSubventionRCF: enhacedTfsCostShare?.value ? Number(nationalRcf) - Number(enhacedTfsCostShare.value) : Number(rcf),
      rgnlAltId: regalt,
      startDate: dateFormatISOString(startDate),
      tdaNote,
      terms: selectedTermsList.map(Number),
      tiers: selectedTiers,
      vehicles: vehiclesList,
      isSpecialEdition,
      modelYear: Number(modelYear),
      series: series,

      isEligibleForEnhCostShare: !!enhancedCostShare,
      enhCostShareOfferId: enhancedCostShare?.offerId,
      isEnhCostShareAccepted,

      isEligibleForEnhTfsCostShare: !!enhacedTfsCostShare,
      enhTfsCostShareId: enhacedTfsCostShare?.id,
      isEnhTfsCostShareAccepted,
    };
  };

  const transformChangeVinNonAdLeaseCardData = (
    enhancedCostShare?: EnhancedCostShare,
    enhacedTfsCostShare?: AdminModels.EnhancedTfsCostShare.EnhancedTfsCostShare,
  ): LeaseOfferDetails => {
    return {
      endDate: dateFormatISOString(endDate),
      isAdvertised: false,
      mileage: Number(selectedMileage.value),
      note,
      regionalSubventionRCF: enhacedTfsCostShare?.value ? Number(nationalRcf) - Number(enhacedTfsCostShare.value) : Number(rcf),
      startDate: dateFormatISOString(startDate),
      tdaNote,
      term: Number(offerTerm),
      tfsCostShare: enhancedCostShare?.tfsCostShare ?? Number(tfsShare),
      modelYear: Number(modelYear),
      series,
      subCashTfsShare: enhancedCostShare?.subventionCashTfsCostShare,
      subCashTfsCostShareCap: enhancedCostShare?.subventionCashTfsCostShareCap,
    };
  };

  const handleSaveOffer = async (isEnhCostShareAccepted?: boolean, isEnhTfsCostShareAccepted?: boolean) => {
    const includedList = mapVehicleDataFromAccordion(vehiclesAccordion.items, vehicles, !isInclusions)[1];

    try {
      if (isChangeVin) {
        const { leaseOffer } = getLeaseOfferFromRgnlAlt(rgnlAlt, offerId, offerTerm, decodeURI(example));

        const { showModal, enhancedCostShare } = handleEnhCostShare(
          {
            endDate,
            startDate,
            optionTypeName: AdminConstants.OPTION_TYPE_NAMES.LEASE,
            tiers: selectedTiers,
            terms: selectedTermsList,
            regionCode: region,
            states: [],
            vehicles: vehicles.filter(vehicle => vehicle.isInclusion),
          },
          leaseOffer,
        );

        const { showModal: showTfsModal, enhancedTfsCostShare } = handleEnhTfsCostShare(
          {
            startDate: dateFormatISOString(startDate) ?? offering.startDate,
            endDate: dateFormatISOString(endDate) ?? offering.endDate,
            optionTypeName: AdminConstants.OPTION_TYPE_NAMES.LEASE,
            seriesProfileId: createLeaseStore.seriesProfile.id,
          },
          leaseOffer,
        );

        if (showModal && isEnhCostShareAccepted === undefined) {
          setShowEnhCostShareModal(true);
          return;
        }

        if (showTfsModal && isEnhTfsCostShareAccepted === undefined && leaseOffer.isStandalone && leaseOffer.isEnhTfsCostShareAccepted === null) {
          setShowEnhTfsCostShareModal(true);
          return;
        }

        const foundEnhancedCostShare = isEnhCostShareAccepted ? enhancedCostShare : undefined;
        const foundEnhancedTfsCostShare = isEnhTfsCostShareAccepted && leaseOffer.isStandalone ? enhancedTfsCostShare : undefined;

        const payload = { ...transformChangeVinNonAdLeaseCardData(foundEnhancedCostShare, foundEnhancedTfsCostShare) };
        const [excludedList, includedList] = mapVehicleDataFromAccordion(vehiclesAccordion.items, createLeaseStore.vehicles, !isInclusions);
        const leasePayload = processLeaseOfferForPayload({
          leaseOffer,
          enhancedCostShare: foundEnhancedCostShare,
          enhacedTfsCostShare: foundEnhancedTfsCostShare,
          isEnhCostShareAccepted,
          isEnhTfsCostShareAccepted,
        });

        await saveLease({ ...leasePayload }, leaseOffer.isSpecialEdition, [payload], excludedList, includedList);
      } else {
        const { showModal, enhancedCostShare } = handleEnhCostShare({
          endDate,
          startDate,
          optionTypeName: AdminConstants.OPTION_TYPE_NAMES.LEASE,
          tiers: selectedTiers,
          terms: selectedTermsList,
          regionCode: region,
          states: [],
          vehicles: includedList,
        });

        const { showModal: showTfsModal, enhancedTfsCostShare } = handleEnhTfsCostShare({
          startDate: startDate ? dateFormatISOString(startDate) : offering.startDate,
          endDate: endDate ? dateFormatISOString(endDate) : offering.endDate,
          optionTypeName: AdminConstants.OPTION_TYPE_NAMES.LEASE,
          seriesProfileId: createLeaseStore.seriesProfile.id,
        });

        if (showModal && isEnhCostShareAccepted === undefined) {
          setShowEnhCostShareModal(true);
          return;
        }

        if (showTfsModal && isEnhTfsCostShareAccepted === undefined) {
          setShowEnhTfsCostShareModal(true);
          return;
        }

        const foundEnhancedCostShare = isEnhCostShareAccepted ? enhancedCostShare : undefined;
        const foundEnhancedTfsCostShare = isEnhTfsCostShareAccepted ? enhancedTfsCostShare : undefined;

        const res = await trackPromise(
          createLeaseOffer({
            variables: { input: { ...transformNonAdLeaseCardData(foundEnhancedCostShare, foundEnhancedTfsCostShare, isEnhCostShareAccepted, isEnhTfsCostShareAccepted) } },
          }),
        );
        if (res.data?.createLeaseOffer.success) {
          const anchor = 'lease-' + res.data?.createLeaseOffer.offer?.id;
          history.push(`/details/region/${region}/period/${period}/profile/${profile}/regalt/${regalt}?scrollTo=${anchor}`);
        }
      }
    } catch (e) {
      setShowEnhCostShareModal(false);
      setShowEnhTfsCostShareModal(false);
      error((e as Error).message);
    }
  };

  const handleToNonAdvertised = () => {
    return () => {
      setTab(1);
    };
  };

  const showEnhCostShareTooltip = FEATURE_OR_2477 && enhancedCostShares.find(e => e.optionTypeName === OPTION_TYPE_NAMES.LEASE);

  return (
    <OfferBody>
      <OfferBodyLeft className={styles.leftSection}>
        <CreateLeaseOfferDetails />
      </OfferBodyLeft>
      <OfferBodyRight className={styles.rightSection}>
        <div className={styles.offerHeader}>
          <span className={styles.offerHeaderType}>(Non-Advertised)</span>
          <Button className={commonStyles.offerHeaderLink} onClick={handleToNonAdvertised()}>
            Switch to an Advertised Offer
          </Button>
        </div>
        <div className={styles.nonAdLeaseContent}>
          {/* Terms selection */}
          <BlockInputLabel className={styles.inputLabel} label="Terms">
            <BoxSelector id="termsSelector" options={termsList} selected={selectedTermsList} setSelected={item => setSelectedTerms(item, isSETUser(userInfo))} />
          </BlockInputLabel>

          {/* Tiers selection */}
          <BlockInputLabel label="Tiers" className={cx(styles.tiers, styles.inputLabel)}>
            <BoxSelector
              id="tiersSelector"
              options={tiersList}
              selected={selectedTiers}
              setSelected={item => {
                setSelectedTiers(isBlended ? tiersList : item, isBlended);
              }}
              setLastSelected={item => updateField('selectedTier', item)}
            />
          </BlockInputLabel>

          <div className={styles.dateMileage}>
            <StartEndDate
              labelClass={styles.inputLabel}
              dateWrapperClass={styles.startEndDate}
              minDate={dateStringToDate(offering.startDate)}
              maxDate={dateStringToDate(offering.endDate)}
              startDate={startDate}
              endDate={endDate}
              setStartDate={value => updateField('startDate', value as Date)}
              setEndDate={value => updateField('endDate', value as Date)}
            />

            {/* Miileage */}
            <BlockInputLabel label="Mileage" className={cx(styles.mileage, styles.inputLabel)}>
              <Dropdown id="mileage" value={selectedMileage} options={mileages.mileages} onChange={item => updateField('selectedMileage', item)} error={hasMileageError} />
            </BlockInputLabel>
          </div>

          {((!isSETUser(userInfo) && FEATURE_OR_4756) || !FEATURE_OR_4756) && (
            <div className={styles.costsWrapper}>
              <BlockInputLabel className={styles.inputLabel} label="Regional Subv. RCF">
                <div className={styles.percentInputWrapper}>
                  <NumberInput rcf error={hasRcfError} percentageSign value={rcf} onValueChange={value => updateField('rcf', value.value)} />
                </div>
                <p className={styles.defaultValueLabel}>{`${isSETUser(userInfo) ? 'Standard RCF' : 'National RCF'} ${formatValue(nationalRcf, true)}`}</p>
              </BlockInputLabel>

              {/* PNV */}
              <BlockInputLabel className={styles.inputLabel} label="PNV">
                <NumberInput
                  value={pnv}
                  dollar
                  dollarSign
                  allowNegative={false}
                  error={hasPnvError}
                  className={cx(styles.pnvInput, hasPnvError && styles.inputError)}
                  onValueChange={value => updateField('pnv', value.value)}
                  units
                />
              </BlockInputLabel>
            </div>
          )}

          {/* Blended/By Tier option */}
          <div className={styles.optionType}>
            <BlockInputLabel className={styles.inputLabel} label="Option Type">
              <input type="radio" id="blended" checked={isBlended} onChange={() => updateField('isBlended', true)} />
              <label htmlFor="blended">Blended</label>
              <input type="radio" id="byTier" checked={!isBlended} onChange={() => updateField('isBlended', false)} />
              <label htmlFor="byTier">By Tier</label>
            </BlockInputLabel>
          </div>

          {/* Special Edition */}
          {(isLexusUser() || isGSTUser()) && (
            <div className={styles.specialEdition}>
              <InlineInputLabel label="Special Edition" className={styles.label}>
                <Checkbox
                  id="special-edition-checkbox"
                  onChange={e => updateField('isSpecialEdition', e.target.checked)}
                  isChecked={isSpecialEdition}
                  className={styles.checkbox}
                />
              </InlineInputLabel>
            </div>
          )}

          {/* Vehicles Accordion */}
          <ExclusionInclusion
            isInclusions={isInclusions}
            editVehicles={editVehicles}
            toggleIsInclusions={toggleIsInclusions}
            vehiclesAccordion={vehiclesAccordion}
            setEditVehicles={setEditVehicles}
          />

          {/* Note */}
          <BlockInputLabel label="Note" className={cx(styles.fullWidthInput, styles.inputLabel)}>
            <Input value={note} onChange={e => updateField('note', e.currentTarget.value)} />
          </BlockInputLabel>

          {/* TDA Note */}
          <BlockInputLabel label={isLexus() ? 'LDA Note' : 'TDA Note'} className={cx(styles.fullWidthInput, styles.inputLabel)}>
            <Input value={tdaNote} onChange={e => updateField('tdaNote', e.currentTarget.value)} />
          </BlockInputLabel>

          {/* CTAs */}
          <div className={styles.footer}>
            {/* Enhanced Cost Share Tooltip */}
            {showEnhCostShareTooltip && <EnhCostShareTooltip offerId={offerId} optionTypeName={OPTION_TYPE_NAMES.LEASE} className={styles.leaseTooltip} />}

            <Button
              className={commonStyles.offerHeaderLink}
              onClick={() => {
                vehiclesAccordion.toggleAll(false);
              }}
            >{`Remove ${isInclusions ? 'Inclusions' : 'Exclusions'}`}</Button>
            <CreateOfferCta
              className={styles.createOfferCta}
              label="Create Non-Advertised Offer"
              onClick={() => handleSaveOffer(undefined, undefined)}
              disabled={hasRcfError || hasTfcShareError || hasDateError || hasTermError || hasMileageError || hasInclusionsError}
            />
          </div>
        </div>
      </OfferBodyRight>
      {FEATURE_OR_2477 && showEnhCostShareModal && (
        <EnhCostShareModal
          onButtonPress={isApplied => {
            setIsEnhCostShareAccepted(isApplied);
            handleSaveOffer(isApplied, isEnhTfsCostShareAccepted);
          }}
        />
      )}
      {FEATURE_OR_4154 && showEnhTfsCostShareModal && (
        <EnhCostShareModal
          title={`Enhanced ${tfsLabel} Cost Share Available`}
          text={`Offer is eligible for National Enhanced ${tfsLabel} Cost Share. Do you want to apply?`}
          onButtonPress={isApplied => {
            setIsEnhTfsCostShareAccepted(isApplied);
            handleSaveOffer(isEnhCostShareAccepted, isApplied);
          }}
        />
      )}
    </OfferBody>
  );
};
export default observer(CreateNonAdLease);
