import { AdminConstants } from 'oat-admin-common';
import { FEATURE_OR_2947_PHASE_2 } from '../../../../constants/global';
import { AprDetails, CombinedOffers } from '../../../../gql/generated';
import { assignNumberValue, assignStringValue } from '../../../../utils/assignValue';
import PenRateModel, { AprTerm } from '../PenRateModel';

const processAprTiers = (offer: CombinedOffers) => {
  const tierRecords: Record<string, AprDetails[]> = {};
  const regionalAprDetails = offer.regional.aprDetails || [];
  const nationalAprDetails = offer.national?.aprDetails || [];

  // group details by tier to make processing easier
  regionalAprDetails.forEach(aprDetail => {
    if (!aprDetail) {
      return;
    }

    const record = tierRecords[aprDetail.tier];
    if (!record) {
      tierRecords[aprDetail.tier] = [aprDetail];
    } else {
      tierRecords[aprDetail.tier] = [...record, aprDetail];
    }
  });

  // return array of mapped PenRateModels
  return Object.entries(tierRecords).map(([tier, aprDetails]) => {
    const penRateModel = new PenRateModel();
    penRateModel.setCommonValues(offer);
    penRateModel.tier = tier;

    aprDetails.forEach(aprDetail => {
      penRateModel.startDate = aprDetail.startDate;
      penRateModel.endDate = aprDetail.endDate;
      penRateModel.displayName = `APR Tier ${tier}`;
      penRateModel.subCashName = FEATURE_OR_2947_PHASE_2 ? AdminConstants.OPTION_TYPE_NAMES.TFS_FINANCE_SUBVENTION_CASH : 'TFS APR Subvention Cash';

      // regional term
      const termModel: AprTerm = {
        term: aprDetail.highTerm,
        rate: aprDetail.subventionRate,
        subCash: aprDetail.subventionCash ?? 0,
        isEnhanced: true, // standalone is enhanced by default
        isSubCashEnhanced: true,
        modelNote: aprDetail.modelNote,
        subCashEstCost: assignNumberValue(aprDetail.subCashEstCost),
        subCashTfsCost: assignNumberValue(aprDetail.subCashTfsEstCost),
        subCashEnhTfsCost: assignNumberValue(aprDetail.subCashEnhTfsEstCost),
        distribution: assignNumberValue(aprDetail.distribution),
        buyRate: aprDetail.buyRate,
      };

      // sum sub cash
      penRateModel.subCash += termModel.subCash;

      // set terms list and advertised terms
      penRateModel.terms.push(aprDetail.highTerm);
      if (aprDetail.isAdvertised) {
        penRateModel.isAdvertised = true;
        penRateModel.advTerms.push(aprDetail.highTerm);
      }

      // national term if applicable
      const nationalDetails = nationalAprDetails.find(item => item?.highTerm === aprDetail.highTerm && item?.tier === aprDetail.tier);
      if (nationalDetails) {
        const nationalTermModel: AprTerm = {
          term: nationalDetails?.highTerm,
          rate: nationalDetails?.subventionRate,
          subCash: nationalDetails?.subventionCash ?? 0,
          isEnhanced: false,
          isSubCashEnhanced: false,
          subCashEstCost: 0,
          subCashTfsCost: 0,
          subCashEnhTfsCost: 0,
          distribution: 0,
        };
        penRateModel.nationalAprDetails.push(nationalTermModel);
        penRateModel.nationalNote = nationalDetails.note;

        // process enhanced w/ national term
        termModel.isEnhanced = termModel.rate !== nationalTermModel.rate;
        termModel.isSubCashEnhanced = termModel.subCash !== nationalTermModel.subCash;

        penRateModel.isStandAlone = false;
      } else {
        termModel.isEnhanced = termModel.rate !== termModel.buyRate;
      }

      // push regional term model after all is processed
      penRateModel.aprDetails.push(termModel);

      // If any of the terms is enhanced, the whole tier is enhanced
      penRateModel.isEnhanced = penRateModel.aprDetails.some(aprDetail => aprDetail.isEnhanced);

      // If any of the terms has sub cash enhanced, the whole tier is sub cash enhanced
      penRateModel.isSubCashEnhanced = penRateModel.aprDetails.some(aprDetail => aprDetail.isSubCashEnhanced);

      penRateModel.penetration = assignStringValue(aprDetail.penetration, '0');
      penRateModel.priorPenRate1 = assignNumberValue(aprDetail.priorPenetration);
      penRateModel.priorPenRate2 = assignNumberValue(aprDetail.priorPenetration2);
      penRateModel.priorPenRate3 = assignNumberValue(aprDetail.priorPenetration3);
      penRateModel.forecastedSales = assignStringValue(aprDetail.forecastedSales, '0');
      penRateModel.estimatedCost = assignNumberValue(aprDetail.estimatedCost);
      penRateModel.tfsCost = assignNumberValue(aprDetail.tfsCost);
      penRateModel.enhTfsCost = assignNumberValue(aprDetail.enhTfsCost);

      // Formula for offer's term cost is FSV * distribution * sub cash est cost
      // In offers table in summary, we sum up (distribution * sub cash est cost) to display as sub cash cost for one vehicle.
      // So basically, multiplying this sum by FSV will give the same offer cost as program details page.
      const distributionPerc = assignNumberValue(aprDetail.distribution) / 100.0;
      penRateModel.subCashEstCost += assignNumberValue(aprDetail.subCashEstCost) * distributionPerc;
      penRateModel.subCashTfsCost += assignNumberValue(aprDetail.subCashTfsEstCost) * distributionPerc;
      penRateModel.subCashEnhTfsCost += assignNumberValue(aprDetail.subCashEnhTfsEstCost) * distributionPerc;

      penRateModel.offerEarnings = assignNumberValue(aprDetail.offerEarnings);
      penRateModel.offerCost = assignNumberValue(aprDetail.offerCost);
      penRateModel.subCashOfferCost = assignNumberValue(aprDetail.offerCost);
      penRateModel.estimatedCost = assignNumberValue(aprDetail.estimatedCost);
    });

    return penRateModel;
  });
};

export default processAprTiers;
