import { observer } from 'mobx-react-lite';
import DollarDisplay from '../../../../components/DollarDisplay';
import DollarDisplayCosts from '../../../../components/DollarDisplayCosts';
import useStores from '../../../../stores/useStores';
import useGetTfsLabel from '../../../../utils/useGetTfsLabel';

interface Props {
  showTfsEnhanced: boolean;
  setShowTfsEnhanced: () => void;
}

const ProgramDetailsCosts = ({ showTfsEnhanced, setShowTfsEnhanced }: Props) => {
  const { offeringCostsStore } = useStores();
  const tfsLabel = useGetTfsLabel();
  const tfsCostLabel = showTfsEnhanced ? `Enhanced ${tfsLabel} Cost` : `Total ${tfsLabel} Cost`;

  return (
    <DollarDisplayCosts
      id="program-details-costs"
      leftContent={
        <DollarDisplay
          id={`tfs-cost`}
          value={showTfsEnhanced ? offeringCostsStore.periodEnhTfsCost : offeringCostsStore.periodTfsCost}
          lightText
          showToggle
          rightBorder
          toggleSelected={showTfsEnhanced}
          onToggle={setShowTfsEnhanced}
          label={tfsCostLabel}
        />
      }
      earnings={offeringCostsStore.periodEarnings}
      earningsLabel="Est. Period Earnings"
      cost={offeringCostsStore.periodCost}
      costLabel="Est. Period Cost"
      balance={offeringCostsStore.periodBalance}
      balanceLabel="Est. Period Balance"
      showTooltips={false}
      lightText
    />
  );
};

export default observer(ProgramDetailsCosts);
