import ReactHtmlParser from '@hedgedoc/html-to-react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import TooltipList, { TooltipListItem } from '../../../../../../../components/TooltipList/TooltipList';
import useUrlParams from '../../../../../../../hooks/useUrlParams';
import useStores from '../../../../../../../stores/useStores';
import { getLeaseConfigDisplayList } from '../../../../../../../utils/configListUtils';
import useUserInfo from '../../../../../../../utils/useUserInfo';
import LeaseCardModel from '../../../../../models/LeaseCardModel';
import LeaseFormModel from '../../../../../models/LeaseFormModel';
import styles from './styles.module.scss';

interface Props {
  leaseCard: LeaseCardModel;
  leaseForm: LeaseFormModel;
  isDisabled: boolean;
}

const VehicleInfoTooltipMessage = ({ leaseCard, leaseForm, isDisabled }: Props) => {
  const { region, period: periodParam, profile, regalt } = useUrlParams();
  const {
    createLeaseStore: { advertisedData },
  } = useStores();
  const { isSETUser } = useUserInfo();

  const changeVinLinkParams = leaseForm.isExample ? `?example=${encodeURIComponent(leaseForm.inputs.name)}` : '';
  const changeVinLink = `/changeVin/region/${region}/period/${periodParam}/profile/${profile}/regalt/${regalt}/offerId/${leaseCard.id}/offerTerm/${leaseForm.inputs.term}${changeVinLinkParams}`;

  return (
    <>
      <TooltipList>
        <TooltipListItem>
          <span>{`${leaseForm.seriesName} (${leaseForm.inputs.modelCode || ''})`}</span>
        </TooltipListItem>
        <TooltipListItem className={styles.topMargin}>
          <span>{leaseForm.inputs.vin || ''}</span>
        </TooltipListItem>
        <TooltipListItem>
          {!isDisabled && (
            <Link id="change-vin-link" className={styles.changeVinLink} to={changeVinLink} onClick={() => advertisedData.setHideTiersTerms(true)}>
              Change VIN...
            </Link>
          )}
        </TooltipListItem>
      </TooltipList>

      <TooltipList className={styles.topMargin}>
        {leaseForm.invoiceDate && (
          <TooltipListItem>
            <span>Invoice Date</span>
            <span>{leaseForm.invoiceDate}</span>
          </TooltipListItem>
        )}

        <TooltipListItem>
          <span>Dealer Code</span>
          <span>{leaseForm.dealerCode}</span>
        </TooltipListItem>
        {!isSETUser() && (
          <TooltipListItem>
            <span>Original Dealer Code</span>
            <span>{leaseForm.originalDealerCode}</span>
          </TooltipListItem>
        )}
      </TooltipList>

      <TooltipList className={styles.topMargin}>
        <TooltipListItem>
          <span>Options:</span>
        </TooltipListItem>

        {getLeaseConfigDisplayList(leaseForm.inputs.configuration).map(item => {
          return (
            <TooltipListItem key={item} className={styles.optionsListItem}>
              {ReactHtmlParser(item)}
            </TooltipListItem>
          );
        })}
      </TooltipList>
    </>
  );
};

export default observer(VehicleInfoTooltipMessage);
