import { observer } from 'mobx-react-lite';
import { DragDrop, Draggable, DraggableProvided, DraggableStateSnapshot, DropResult, useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { SeriesSorting, useSaveSeriesSortingMutation } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import { sortSeriesList } from '../utils';
import SeriesSortingItem from './SeriesSortingItem';

interface Props {
  seriesSortingList: SeriesSorting[];
}

const SeriesSortingList = ({ seriesSortingList }: Props) => {
  const [saveSeriesSorting] = useSaveSeriesSortingMutation();
  const { seriesSortingStore, userInfoStore } = useStores();
  const { setDelay } = useInputDelay();
  const { error } = useToast();
  const regionCode = userInfoStore.getRegionCode();

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const currSortListCopy = [...seriesSortingList];
    const updatedSortList = sortSeriesList(seriesSortingList, result.source.index, result.destination.index);
    const sortedSeries = updatedSortList.map((item, i) => ({ ...item, sortOrder: i }));
    seriesSortingStore.setSeriesSorting(sortedSeries);

    setDelay(async () => {
      try {
        await trackPromise(
          saveSeriesSorting({
            variables: {
              input: {
                regionCode,
                sortedSeries,
              },
            },
          }),
        );
      } catch (e) {
        seriesSortingStore.setSeriesSorting(currSortListCopy);
        error(`Modifications were not saved, ${(e as Error).message}`);
      }
    }, 500);
  };

  return (
    <DragDrop droppableId="seriesSorting" handleOnDragEnd={handleOnDragEnd}>
      {seriesSortingList.map((item, i) => {
        return (
          <Draggable key={`${item.displayName}-${i}`} draggableId={`${item.displayName}-${i}`} index={i}>
            {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
              return (
                <SeriesSortingItem
                  innerRef={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  {...draggableProvided.dragHandleProps}
                  key={`${item.displayName}-${i}`}
                  item={item.displayName}
                />
              );
            }}
          </Draggable>
        );
      })}
    </DragDrop>
  );
};

export default observer(SeriesSortingList);
