import { EnhancedCostShare } from '../gql/generated';

export const checkRcfSubVentionCashCostShare = (
  isEnhanced: boolean,
  subventionCash: string,
  nationalSubventionCash: string,
  checkIfTfsShareChanged: boolean,
  enhancedCostShare?: EnhancedCostShare,
) => {
  const checkIfEnhancedNationalSubCash = nationalSubventionCash !== subventionCash;
  const checkIfSubCashAtNationalOrNotExist = !subventionCash || nationalSubventionCash === subventionCash;
  const checkIfEnhancedCostShare = Boolean(enhancedCostShare?.tfsCostShare) || checkIfTfsShareChanged;

  return (isEnhanced && checkIfSubCashAtNationalOrNotExist && checkIfEnhancedCostShare) || (isEnhanced && checkIfEnhancedNationalSubCash && checkIfEnhancedCostShare);
};
