import { AdminConstants } from 'oat-admin-common';
import { formatAccounting } from 'oat-common-ui';
import { EnhancedCostShare } from '../../../../gql/generated';
import EnhTooltipListItem from './EnhTooltipListItem';
import styles from './styles.module.scss';

const { OPTION_TYPE_NAMES } = AdminConstants;

export const renderExclusions = (enhancedCostShare: EnhancedCostShare) => {
  const exclusions = enhancedCostShare.vehicles.filter(vehicle => !vehicle.isInclusion);

  if (exclusions?.length) {
    return exclusions.map(exc => exc.modelCode).join(', ');
  }

  return 'None';
};

export const renderCostShare = (enhancedCostShare: EnhancedCostShare, selectedTier: string, optionTypeName: string) => {
  // for APR offers
  if (selectedTier && enhancedCostShare.tierTerms) {
    const filteredTierTerms = enhancedCostShare.tierTerms.filter(tierTerm => tierTerm?.tier === selectedTier);

    if (filteredTierTerms.length) {
      return filteredTierTerms.map((t, i) => {
        if (t?.tfsCostShare) {
          const key = `${t?.tier}-${t?.highTerm}-${i}`;

          return (
            <div key={key}>
              <span>{`${t?.tier} ${t?.highTerm}:  ${t?.tfsCostShare}%`}</span>
            </div>
          );
        }

        return '';
      });
    }
  }

  // for Lease and Misc offers
  if (!enhancedCostShare.tfsCostShare) {
    return '';
  }
  const showCostShareCap = optionTypeName !== OPTION_TYPE_NAMES.APR && optionTypeName !== OPTION_TYPE_NAMES.LEASE;
  const costShareCap = ` / ${formatAccounting(enhancedCostShare.tfsCostShareCap ?? 0, true, false)}`;
  return `${enhancedCostShare.tfsCostShare ?? 0}%${showCostShareCap ? costShareCap : ''}`;
};

export const renderSubCashCostShare = (enhancedCostShare: EnhancedCostShare): string => {
  if (!(enhancedCostShare.subventionCashTfsCostShare)) {
    return '';
  }
  return `${enhancedCostShare.subventionCashTfsCostShare}% / ${formatAccounting(enhancedCostShare.subventionCashTfsCostShareCap ?? 0, true, false)}`;
}

// for Lease
export const renderTiersAndTerms = (enhancedCostShare: EnhancedCostShare) => {
  const { tiers, terms } = enhancedCostShare;

  const sortedTiers = tiers && tiers?.length > 1 ? tiers?.slice().sort() : tiers ?? [];
  const tierOnePlus = sortedTiers?.find(t => t === '1+');
  const processedTiers = tierOnePlus && sortedTiers.length > 1 ? [tierOnePlus, ...sortedTiers?.filter(t => t !== tierOnePlus)] : sortedTiers;

  return (
    <>
      <EnhTooltipListItem className={styles.minHeight} headerText="Tiers" body={processedTiers.join(', ') || ''} />
      <EnhTooltipListItem className={styles.minHeight} headerText="Terms" body={(terms?.length && terms.map(term => `${term?.lowTerm} - ${term?.highTerm}`).join(', ')) || ''} />
    </>
  );
};

// for APR
export const renderTierTerms = (enhancedCostShare: EnhancedCostShare) => {
  const filteredTierTerms = enhancedCostShare.tierTerms?.filter(tierTerm => !!tierTerm?.tfsCostShare);

  const tierObj: { [tier: string]: number[] } = {};

  if (filteredTierTerms?.length) {
    for (const tierTerm of filteredTierTerms) {
      if (tierTerm?.tier && tierTerm?.highTerm) {
        if (!tierObj[tierTerm.tier]) {
          tierObj[tierTerm.tier] = [tierTerm.highTerm];
        } else {
          tierObj[tierTerm.tier] = [...tierObj[tierTerm.tier], tierTerm.highTerm];
        }
      }
    }
  }

  let tiers = Object.keys(tierObj).sort();
  const tierOnePlus = tiers.find(key => key === '1+');

  if (tierOnePlus && tiers.length > 1) {
    const otherTiers = Object.keys(tierObj).filter(key => key !== '1+');
    tiers = [tierOnePlus, ...otherTiers];
  }

  return (
    <>
      {tiers.map((tier, i) => {
        return <EnhTooltipListItem key={`${tier}-${i}`} headerText={i < 1 ? 'Tiers/Terms' : ''} body={`${tier}: ${tierObj[tier].map(item => item).join(', ')}`} />;
      })}
    </>
  );
};

// for Misc offers
export interface tierTermMap {
  [key: string]: string[];
}

export const processTfsContractByTerm = (tierTerms: string) => {
  const byTerm: tierTermMap = {};
  const tierTermsArr = tierTerms.split(',');

  for (const tierTerm of tierTermsArr) {
    const [tier, term] = tierTerm.split('#');

    if (!byTerm[term]) {
      byTerm[term] = [tier];
    } else {
      if (!byTerm[term].find(t => t === tier)) {
        byTerm[term] = [...byTerm[term], tier];
      }
    }
  }

  return byTerm;
};

export const processTfsContractByTier = (byTermMap: tierTermMap) => {
  const byTier: tierTermMap = {};

  for (const [key, value] of Object.entries(byTermMap)) {
    const tiersArr =
      value.length > 1 && value.includes('1+')
        ? [
            '1+',
            value
              .filter(v => v !== '1+')
              .slice()
              .sort(),
          ]
        : value.slice().sort();
    const tiersStr = tiersArr.join(',');

    if (!byTier[tiersStr]) {
      byTier[tiersStr] = [key];
    } else {
      byTier[tiersStr] = [...byTier[tiersStr], key].slice().sort();
    }
  }

  return byTier;
};

export const processTierMap = (tierMap: tierTermMap, tier: string) => {
  const termsArrByTier = tierMap[tier];
  const tierArr = tier.split(',');
  const firstTier = tierArr.includes('1+') ? '1+' : tierArr[0];

  return { termsArrByTier, tierArr, firstTier };
};
