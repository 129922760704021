import { observer } from 'mobx-react-lite';
import { isFlatCostOfferType } from 'oat-admin-common';
import { formatDollars, formatDollarsCents } from 'oat-common-ui';
import TooltipList, { TooltipListItem } from '../../../../components/TooltipList/TooltipList';

interface EarningsProps {
  difference?: number;
  earnings: number;
  forecastedSales: number;
  isAprOffer?: boolean;
  ryoEarnings: number;
}

export const OfferEarningsTooltip = ({ forecastedSales, ryoEarnings, earnings, difference, isAprOffer }: EarningsProps) => {
  const tierPrefix = isAprOffer ? 'Tier ' : '';

  return (
    <>
      {difference ? (
        <TooltipList>
          <TooltipListItem>
            <span>(RYO Earnings</span>
            <span>{formatDollarsCents(ryoEarnings)}</span>
          </TooltipListItem>

          <TooltipListItem symbol="+">
            <span>Final Pay Difference)</span>
            <span>${formatDollarsCents(difference)}</span>
          </TooltipListItem>

          <TooltipListItem symbol="*">
            <span>Forecasted Sales Volume</span>
            <span>{formatDollars(forecastedSales)}</span>
          </TooltipListItem>
        </TooltipList>
      ) : (
        <TooltipList>
          <TooltipListItem>
            <span>{tierPrefix}Forecasted Sales Volume</span>
            <span>{formatDollars(forecastedSales)}</span>
          </TooltipListItem>
          <TooltipListItem symbol="*">
            <span>RYO Earnings</span>
            <span>${formatDollars(ryoEarnings)}</span>
          </TooltipListItem>
        </TooltipList>
      )}
      <TooltipList divider>
        <TooltipListItem>
          <span>{tierPrefix}Est. Total Offer Earnings</span>
          <span>${formatDollarsCents(earnings)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
};

interface CostProps {
  cost: number;
  estCost: number;
  forecastedSales: number;
  offerType?: string;
  isAprOffer?: boolean;
  totalSubventedCost?: number;
  subCashOfferCost?: number;
}

export const OfferCostTooltip = observer(({ forecastedSales, estCost, cost, offerType = '', isAprOffer, totalSubventedCost = 0, subCashOfferCost = 0 }: CostProps) => {
  const pnvLabel = isAprOffer ? 'Weighted PNVS)' : subCashOfferCost > 0 ? '(PNV' : '(PNV)';
  const tierPrefix = isAprOffer ? '(Tier ' : '';
  const isFlatCostOffer = isFlatCostOfferType(offerType);

  return (
    <>
      {!isFlatCostOffer && (
        <TooltipList>
          <TooltipListItem>
            <span>{tierPrefix}Forecasted Sales Volume</span>
            <span>{formatDollars(forecastedSales)}</span>
          </TooltipListItem>
          <TooltipListItem symbol="*">
            <span>{pnvLabel}</span>
            <span>${formatDollarsCents(estCost)}</span>
          </TooltipListItem>
          {subCashOfferCost > 0 && (
            <TooltipListItem symbol="+">
              <span>Sub Cash Offer Cost)</span>
              <span>${formatDollarsCents(subCashOfferCost)}</span>
            </TooltipListItem>
          )}
          {totalSubventedCost > 0 && (
            <TooltipListItem symbol="+">
              <span>Total Subvented Offer Cost</span>
              <span>${formatDollarsCents(totalSubventedCost)}</span>
            </TooltipListItem>
          )}
        </TooltipList>
      )}
      <TooltipList divider={!isFlatCostOffer}>
        <TooltipListItem>
          <span>Est. Total Offer Cost</span>
          <span>${formatDollarsCents(cost)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
});
