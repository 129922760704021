import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { BreadCrumbType, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { Link, useHistory } from 'react-router-dom';
import MainFooter from '../../../components/MainFooter';
import MainHeader from '../../../components/MainHeader';
import SubHeader from '../../../components/SubHeader';
import { useUpdateOfferingStatusMutation } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import SummaryCosts from '../components/SummaryCosts';
import { useShowScrollToTop } from '../RegionalSummary/useShowScrollToTop';
import { getNationalApprovalCtaStatuses, processStatusToSubmitNational } from '../utils';
import NotesHeader from './ActionBar/NotesHeader';
import ArrowUpButton from './ArrowUpButton';
import Profile from './Profile';

const NationalApproval = () => {
  const { REGIONS } = AdminConstants;
  const [updateOfferingStatus] = useUpdateOfferingStatusMutation();
  const { offeringsStore, summaryStore } = useStores();
  const history = useHistory();
  const { region, period, parentOfferingId } = useUrlParams();
  const { error } = useToast();
  const { showScrollToTop } = useShowScrollToTop();
  const { showUpdateOfferingStatusBtn, showReconcileBtn, showPublishBtn } = getNationalApprovalCtaStatuses(summaryStore.offering, summaryStore.allProfiles);

  const breadCrumbs: BreadCrumbType[] = [
    {
      name: '',
      component: <Link to={`/dashboard`}>Dashboard</Link>,
    },
    {
      name: `${offeringsStore.offering.name}`,
      component: <Link to={`/regionStatus/period/${summaryStore.parentOfferingId}`}>{summaryStore.offering.name}</Link>,
    },
    {
      name: REGIONS.find(item => item.code === region)?.name || '',
    },
  ];

  // used for both onUpdateOfferingStatus & onPublish
  const handleUpdateOfferingStatus = async () => {
    const statusToSubmit = processStatusToSubmitNational(summaryStore.allProfiles, summaryStore.offering);

    try {
      const res = await trackPromise(
        updateOfferingStatus({ variables: { input: { offeringId: summaryStore.offering.id, offeringRev: summaryStore.offering.rev, status: statusToSubmit } } }),
      );
      summaryStore.updateSummaryOfferingStatus(statusToSubmit);
      summaryStore.updateOfferingRev(summaryStore.offering.id, res.data?.updateOfferingStatus.offering?.rev || '');
      history.push(`/regionStatus/period/${parentOfferingId}`);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleOnReconcile = () => {
    history.push(`/reconcile/region/${region}/period/${period}?parentOfferingId=${parentOfferingId}`);
  };

  return (
    <>
      <MainHeader headerTitle={offeringsStore.offering.name} breadCrumbs={breadCrumbs} showRightContent rightContent={<SummaryCosts />} />
      <SubHeader profiles={summaryStore.profiles} updateCarryOver={() => {}} showRightPanel={false} offering={summaryStore.offering} />
      <NotesHeader offering={summaryStore.offering} />
      {summaryStore.profiles.map(profile => {
        return <Profile key={profile.id} profile={profile} />;
      })}
      <ArrowUpButton showScrollToTop={showScrollToTop} />
      <MainFooter
        darkTheme
        isShowUpdateOfferingStatus={showUpdateOfferingStatusBtn}
        isShowReconcile={showReconcileBtn}
        isShowPublish={showPublishBtn}
        onUpdateOfferingStatus={handleUpdateOfferingStatus}
        onReconcile={handleOnReconcile}
        onPublish={handleUpdateOfferingStatus}
      />
    </>
  );
};

export default observer(NationalApproval);
