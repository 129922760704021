import { OATIcon } from 'oat-common-ui';
import styles from './styles.module.scss';

interface Props {
  hasDateError: boolean;
  hasExclusionError: boolean;
  isInclusions: boolean;
  hasTermError?: boolean;
  hasTierError?: boolean;
}

const EnRErrorMsg = ({ hasDateError, hasExclusionError, isInclusions, hasTermError, hasTierError }: Props) => {
  return (
    <>
      {hasDateError && (
        <p className={styles.errorMsg}>
          <OATIcon icon="info" size={14} />
          <span>Start Date to End Date must be within the Incentive Period.</span>
        </p>
      )}
      {hasTermError && (
        <p className={styles.errorMsg}>
          <OATIcon icon="info" size={14} />
          <span>A Term is required.</span>
        </p>
      )}
      {hasTierError && (
        <p className={styles.errorMsg}>
          <OATIcon icon="info" size={14} />
          <span>A Tier is required.</span>
        </p>
      )}
      {hasExclusionError && (
        <span className={styles.errorMsg}>
          <OATIcon icon="info" size={14} />
          <span>
            {isInclusions
              ? 'You cannot exclude all vehicles for the Series. Please select some inclusions.'
              : 'You cannot exclude all vehicles for the Series. Please deselect some exclusions.'}
          </span>
        </span>
      )}
    </>
  );
};

export default EnRErrorMsg;
