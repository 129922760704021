import { assignNumberValue } from 'oat-common-ui';
import StdLeaseRcfs from '../models/StdLeaseRcfs';

const searchRcfByTierTerm = (stdLeaseRcfs: StdLeaseRcfs[], tier: string, term?: number) => {
  const rcf =
    stdLeaseRcfs.find(item => {
      return term
        ? item.tier === tier && assignNumberValue(term) >= assignNumberValue(item.minTerm) && assignNumberValue(term) <= assignNumberValue(item.maxTerm)
        : item.tier === tier;
    })?.rcf ?? 0;

  return rcf;
};

export default searchRcfByTierTerm;
