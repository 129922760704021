import clsx from 'clsx';
import { OATIcon } from 'oat-common-ui';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  isEnhanced: boolean;
  hideIcon?: boolean; // for APR to customize where svg goes
  children?: ReactNode;
}

const EnhancedLabel = ({ isEnhanced, children, hideIcon = false }: Props) => {
  return (
    <label className={clsx(styles.enhancedLabel, isEnhanced && styles.enhanced)}>
      {children} {isEnhanced && !hideIcon && <OATIcon colorTheme="orange" icon="enhancement" />}
    </label>
  );
};

export default EnhancedLabel;
