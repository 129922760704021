import { makeAutoObservable, runInAction } from 'mobx';
import AccordionModel from '../../../../components/Accordion/models/AccordionModel';
import createAccordionFromModelCodes from '../../../../components/Accordion/utils/createAccordionFromModelCodes';
import { recursiveQuery } from '../../../../components/Accordion/utils/recursiveQuery';
import { ModelCodes, SeriesMapping, VehicleInput } from '../../../../gql/generated';

export class AddSeriesProfileModel {
  loaded = false;
  filter = '';
  name = '';
  searchInput = '';
  states: string[] = [];
  vehicles: VehicleInput[] = [];
  modelCodes: ModelCodes[] = [];
  seriesMapping: SeriesMapping[] = [];
  accordion: AccordionModel = new AccordionModel();
  existingProfileNames: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setData = (modelCodes: ModelCodes[], seriesMapping: SeriesMapping[], states: string[], existingProfileNames: string[], includedVehicles?: VehicleInput[]) => {
    this.modelCodes = modelCodes;
    this.seriesMapping = seriesMapping;
    this.states = states;
    this.vehicles = includedVehicles || [];
    this.existingProfileNames = existingProfileNames;

    runInAction(() => {
      this.accordion = new AccordionModel(createAccordionFromModelCodes(modelCodes, seriesMapping, includedVehicles));
      this.loaded = true;
    });
  };

  setName = (value: string) => {
    this.name = value;
  };

  setSearchInput = (value: string) => {
    this.searchInput = value;
    this.filter = value;

    recursiveQuery(this.accordion.items, value);
    this.accordion.toggleExpandAll(Boolean(this.filter));
  };

  setStateCheck = (value: string) => {
    if (this.states.includes(value)) {
      const index = this.states.findIndex(state => state === value);
      this.states.splice(index, 1);
    } else {
      this.states = [...this.states, value];
    }
  };

  get hasErrors() {
    return this.nameError || this.vehiclesError || this.statesMissingError || this.existingProfileError;
  }

  get nameError() {
    return !this.name;
  }

  get vehiclesError() {
    return this.accordion.getChecked(this.accordion.items).length === 0;
  }

  get statesMissingError() {
    return this.states.length === 0;
  }

  get existingProfileError() {
    return this.existingProfileNames.findIndex(name => this.name === name) !== -1;
  }
}

export default AddSeriesProfileModel;
