import { get3dImages, getEfcBrand } from 'oat-admin-common';
import { CreateLeaseModels, EFCInventoryResponse, EFCVinListResponse, EFCVinResponse, Efc3dImagesResponse, EfcApi, SeriesV1Response, UniqueSeriesType } from 'oat-common-ui';
import authClient from '../authClient';
import { EFCStopSaleVIS } from '../models/EFCStopSaleVIS';
import { EFCStopSaleSSC } from '../models/EFcStopSaleSSC';
import { EfcSeriesData } from '../models/EfcSeriesModels';

class EFCStore {
  _apiKey = process.env.REACT_APP_EFC_MS_API_KEY as string;
  _domain = process.env.REACT_APP_EFC_MS_URL as string;
  _3dImagesDomain = process.env.REACT_APP_REST_PROXY_URL as string;
  _3dImagesAppKey = process.env.REACT_APP_NEXT_TMS_APP_KEY as string;

  series: SeriesV1Response | undefined;
  seriesImages = new Map<string, Efc3dImagesResponse>();
  uniqueYearSeries: UniqueSeriesType[] = [];
  inventoryApi: Record<string, EFCInventoryResponse> = {};
  vinApi: Record<string, EFCVinResponse> = {};
  vinListApi: Record<string, EFCVinListResponse> = {};
  efcData: EfcSeriesData = { series: [] };
  efcMapData = new Map<string, EfcSeriesData>();
  stopSaleSSC: Record<string, EFCStopSaleSSC> = {};
  stopSaleVIS: Record<string, EFCStopSaleVIS> = {};

  isEfcSeriesLoaded = (brand: string, region: string) => {
    return !!this.efcMapData.has(`${brand}#${region}`);
  };

  getTokenAndBrand = async (brand: string) => {
    let token = await authClient.getToken();

    if (!token) {
      token = process.env.REACT_APP_TOKEN || '';
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    return { headers, brandParam: getEfcBrand(brand) };
  };

  getEfcSeriesApi = async (brand: string, region: string) => {
    const cachedData = this.efcMapData.get(`${brand}#${region}`);
    if (cachedData) {
      return cachedData;
    }

    const { headers } = await this.getTokenAndBrand(brand);
    const domain = `${process.env.REACT_APP_REST_PROXY_URL}`;
    const res = await EfcApi.getSeries(headers.Authorization, domain, brand, region);

    this.efcMapData.set(`${brand}#${region}`, res.data);
    return res.data;
  };

  getInventoryApi = async (region: string, model: string, year: string, series: string, brand: string) => {
    const key = `${region}-${model}-${year}`;
    const cachedData = this.inventoryApi[key];
    if (cachedData) {
      return cachedData;
    }

    const { headers, brandParam } = await this.getTokenAndBrand(brand);
    const inventoryParam = {
      brand: brandParam,
      facetfields: [],
      fields: [],
      filter: {
        model: [`${model}`],
        series: [`${series}`],
        year: [`${year}`],
        region: [Number(region)],
        vehcategory: ['ALL'],
      },
      group: 'true',
      groupmode: 'simple',
      mode: 'content',
      pagesize: '100',
      pagestart: '',
      sortfield: 'inventorypercent',
      sortorder: 'desc',
      appkey: `${process.env.REACT_APP_INVENTORY_APP_KEY}`,
    };

    const domain = `${process.env.REACT_APP_REST_PROXY_URL}`;
    const res = await EfcApi.getInventory(headers.Authorization, domain, inventoryParam);
    this.inventoryApi[key] = res.data;

    return this.inventoryApi[key];
  };

  getVinListApi = async (region: string, model: string, year: string, config: string, brand: string) => {
    const key = `${region}-${model}-${year}-${config}`;
    const cachedData = this.vinListApi[key];
    if (cachedData) {
      return cachedData;
    }

    const { headers } = await this.getTokenAndBrand(brand);
    const optionParam = config.replace(/\s+/g, '');
    const domain = `${process.env.REACT_APP_REST_PROXY_URL}`;
    const res = await EfcApi.getVinList(headers.Authorization, domain, brand, region, model, year, optionParam);
    this.vinListApi[key] = res.data;

    return this.vinListApi[key];
  };

  getSeriesV1 = async (brand: string) => {
    if (!this.series) {
      try {
        const res = await EfcApi.getSeriesV1(this._domain, this._apiKey, brand);
        this.series = res.data;
        this.uniqueYearSeries = EfcApi.getSeriesV1UniqueSeries(res.data);
      } catch (e) {}
    }
  };

  get3dMapKey = (year: string, series: string) => {
    return `${year}_${series}`;
  };

  has3dImages = (year: string, series: string) => {
    return this.seriesImages.has(this.get3dMapKey(year, series));
  };

  get3dImages = async (brand: string, year: string, series: string) => {
    const mapKey = this.get3dMapKey(year, series);
    if (year !== 'MULTI' && series !== 'MULTI' && !this.seriesImages.has(mapKey)) {
      let token = await authClient.getToken();

      if (!token) {
        token = process.env.REACT_APP_TOKEN || '';
      }

      try {
        const res = await get3dImages(this._3dImagesDomain, token, brand, year, series);
        this.seriesImages.set(mapKey, res.data);
      } catch (e) {}
    }
  };

  getEfcVinDetails = async (vin: string, brand: string) => {
    if (this.vinApi[vin]) {
      return this.vinApi[vin];
    }

    const { headers } = await this.getTokenAndBrand(brand);
    const domain = `${process.env.REACT_APP_REST_PROXY_URL}`;
    try {
      const res = await EfcApi.getVinDetails(headers.Authorization, domain, vin);
      this.vinApi[vin] = res.data;
      return res.data;
    } catch (e) {
      return undefined;
    }
  };

  getEfcVinDetailsItem = async (vin: string, brand: string) => {
    const res = await this.getEfcVinDetails(vin, brand);
    const vinItem = new CreateLeaseModels.VinItem();
    if (res) {
      vinItem.setFromEFC(res);
    }

    return vinItem;
  };

  getStopSaleSSC = async (vin: string, brand: string) => {
    const key = `${vin}`;
    const cachedData = this.stopSaleSSC[key];
    if (cachedData) {
      return cachedData;
    }

    const { headers } = await this.getTokenAndBrand(brand);
    const domain = `${process.env.REACT_APP_REST_PROXY_URL}`;
    const res = await EfcApi.getStopSaleSSC(headers.Authorization, domain, vin);
    this.stopSaleSSC[key] = res.data as EFCStopSaleSSC;
    return this.stopSaleSSC[key];
  };

  getStopSaleVIS = async (vin: string, brand: string) => {
    const key = `${vin}`;
    const cachedData = this.stopSaleVIS[key];
    if (cachedData) {
      return cachedData;
    }

    const { headers } = await this.getTokenAndBrand(brand);
    const domain = `${process.env.REACT_APP_REST_PROXY_URL}`;
    const res = await EfcApi.getStopSaleVIS(headers.Authorization, domain, vin);
    this.stopSaleVIS[key] = res.data as EFCStopSaleVIS;
    return this.stopSaleVIS[key];
  };
}

export default EFCStore;
