import { observer } from 'mobx-react-lite';
import { BlockInputLabel, OATIcon, SlideToggle, Tooltip, assignNumberValue } from 'oat-common-ui';
import { useState } from 'react';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import NumberInput from '../../../../../../components/NumberInput';
import { formatValue } from '../../../../../../components/NumberInput/utils';
import SetSubvenedLeaseRcfs from '../../../../../../components/SetSubvenedLeaseRcfs';
import { FEATURE_OR_5646, Status } from '../../../../../../constants/global';
import useUrlParams from '../../../../../../hooks/useUrlParams';
import useStores from '../../../../../../stores/useStores';
import useGetTfsLabel from '../../../../../../utils/useGetTfsLabel';
import useUserInfo from '../../../../../../utils/useUserInfo';
import LeaseCardModel from '../../../../models/LeaseCardModel';
import LeaseFormModel from '../../../../models/LeaseFormModel';
import LeaseInput from '../LeaseInput';
import TfsRcfTooltipMessage from '../Tooltips/TfsRcfTooltipMessage';
import ViewRcfTooltipMessage from '../Tooltips/ViewRcfTooltipMessage';
import ViewRcfModal from '../ViewRcfModal';
import styles from './styles.module.scss';

interface Props {
  leaseCard: LeaseCardModel;
  leaseForm: LeaseFormModel;
  onUpToSave: (isUpToApplied: boolean) => void;
  onInputChange: (skipDelay?: boolean) => void;
  onRcfChange: () => void;
  standardRegionalRcf: string | number;
  setStandardRegionalRcf: (val: string | number) => void;
}

const RcfPnv = ({ leaseCard, leaseForm, onUpToSave, onInputChange, onRcfChange, standardRegionalRcf, setStandardRegionalRcf }: Props) => {
  const { id } = leaseCard;
  const { inputs } = leaseForm;
  const { programDetailsStore, userInfoStore } = useStores();
  const [openViewRcfModal, setOpenViewRcfModal] = useState(false);
  const { isSETUser } = useUserInfo();
  const { region } = useUrlParams();

  const [editRcf, setEditRcf] = useState(false);
  const tfsLabel = useGetTfsLabel();

  const handleEditRcf = () => {
    setEditRcf(true);
  };

  const tfsEnhRatesUsedChanged = () => {
    leaseForm.setTfsEnhRatesUsed(!leaseForm.areTfsEnhRatesUsed, tfsLabel);
    onUpToSave(leaseForm.inputs.isUpToApplied);
  };

  const handleSubmitStdRcf = () => {
    leaseForm.updateInput('standardRegionalRcf', standardRegionalRcf);
    setEditRcf(false);
    onInputChange(true);
  };

  const handlePnv = (val: number) => {
    leaseForm.updateInput('estimatedCost', val);
    leaseForm.calculateNonAdGstEstCosts(region, !!programDetailsStore.offering.useOldCostShareForLexus);
    leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
    onInputChange();
  };

  const isDisabled = programDetailsStore.offering.status === Status.MEETS_GUIDELINES || leaseCard.isDisabled;

  return (
    <>
      <div>
        <div className={styles.regionalRcfSection}>
          {/* RCF */}
          {isSETUser() ? (
            <div onClick={() => setOpenViewRcfModal(true)}>
              <OATIcon icon="list" colorTheme="blue" pointer />
            </div>
          ) : (
            <Tooltip
              id={`view-rcf-${leaseForm.uid}`}
              message={
                <ViewRcfTooltipMessage
                  term={inputs.term}
                  rates={!leaseForm.areTfsEnhRatesUsed ? inputs.rates : undefined}
                  tfsEnhancedRates={leaseForm.areTfsEnhRatesUsed && inputs.tfsEnhancedRates !== null ? inputs.tfsEnhancedRates : undefined}
                  service={tfsLabel}
                />
              }
              clickable
              place="bottom"
            >
              <OATIcon icon="list" colorTheme="blue" pointer />
            </Tooltip>
          )}

          <div className={styles.label}>Regional Subvened RCF</div>
          <LeaseInput
            className={styles.rcfInput}
            label=""
            field="rcf"
            value={leaseForm.areTfsEnhRatesUsed ? inputs.enhancedStdRcf : inputs.rcf}
            rcf
            allowNegative
            error={Boolean(leaseForm.rcfError)}
            onValueChange={value => {
              leaseForm.updateInput('rcf', value.value);
              onRcfChange();
              onUpToSave(leaseForm.inputs.isUpToApplied);
            }}
            disabled={isDisabled || leaseForm.isExample || leaseForm.areTfsEnhRatesUsed}
          />
          <SetSubvenedLeaseRcfs leaseForm={leaseForm} />
        </div>

        {/* National RCF / Standard RCF */}
        <div className={styles.otherRcfSection}>
          {/* Tooltip/Label */}
          <InfoTooltip id={`nat-rcf-${id}`} message={<TfsRcfTooltipMessage leaseDetails={leaseForm} />} iconSize={14} />
          <span className={styles.label}>{leaseForm.isSET ? 'Regional Standard RCF' : `${leaseForm.getStandardNationalLabel()} ${userInfoStore.getTFSRateLabel()} RCF`}</span>

          {/* Editable Standard RCF */}
          {!editRcf && (
            <>
              <span className={styles.otherRcfValue}>{formatValue(leaseForm.inputs.isStandalone ? inputs.standardRegionalRcf : inputs.nationalRcf, true)}</span>

              {/* Edit RCF icon */}
              {!isDisabled && !leaseForm.isExample && leaseForm.inputs.isStandalone && !leaseForm.areTfsEnhRatesUsed && !editRcf && (
                <span className={styles.editRcfIcon}>
                  <OATIcon id={`edit-std-rcf-${id}`} colorTheme="blue" icon="edit" onClick={handleEditRcf} pointer size={12} />
                </span>
              )}
            </>
          )}
          {editRcf && (
            <div className={styles.inputContainer}>
              <NumberInput
                id={`edit-std-rcf-input-${id}`}
                className={styles.input}
                value={standardRegionalRcf}
                rcf
                onChange={e => {
                  setStandardRegionalRcf(e.currentTarget.value);
                }}
              />
              <OATIcon id={`std-rcf-submit-${id}`} icon="check-mark" colorTheme="blue" onClick={handleSubmitStdRcf} pointer />
            </div>
          )}
        </div>
      </div>

      {/* Enhanced Standard RCF */}
      {leaseForm.isEnhRcfEligible && (
        <div className={styles.tfsRcf}>
          <b>{inputs.enhancedStdRcf}</b>
          <BlockInputLabel className={styles.enhTfsRcfLabel}>
            <InfoTooltip id={`enh-std-rcf-${id}`} message={''} />
            <span className={styles.tfsRcfLabel}>{`Enhanced Standard ${tfsLabel} RCF`}</span>
            <SlideToggle containerClass={styles.enhTfsRcfToggle} selected={leaseForm.areTfsEnhRatesUsed} disabled={isDisabled} toggleSelected={tfsEnhRatesUsedChanged} />
          </BlockInputLabel>
        </div>
      )}

      {/* PNV Input */}
      <div>
        <LeaseInput
          wrapperClass={styles.pnvInput}
          label=""
          dollarSign
          dollar
          value={leaseForm.inputs.estimatedCost}
          error={Boolean(leaseForm.estCostError)}
          onValueChange={value => {
            handlePnv(Number(value.value));
            onRcfChange();
          }}
          disabled={isDisabled || leaseForm.areTfsEnhRatesUsed || (FEATURE_OR_5646 && assignNumberValue(inputs.tfsShare) === 100)}
        />
        <div className={styles.pnvLabel}>PNV</div>
      </div>
      {openViewRcfModal && <ViewRcfModal leaseCard={leaseCard} leaseForm={leaseForm} onClose={() => setOpenViewRcfModal(false)} disabled={isDisabled} />}
    </>
  );
};

export default observer(RcfPnv);
