import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_NAME, BreadCrumbType, DefaultHeaderLayout, OATHeaderLogo, OATHeaderTitle, OATIcon, OATLoadingBar, OATToastContainer } from 'oat-common-ui';
import { ReactNode, useMemo } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Link } from 'react-router-dom';
import { FEATURE_OR_3695 } from '../../constants/global';
import useStores from '../../stores/useStores';

interface Props {
  breadCrumbs?: BreadCrumbType[];
  showProgressBar?: boolean;
  showMiscLinks?: boolean;
  showRightContent?: boolean;
  rightContent?: ReactNode;
  headerTitle?: string;
}

const MainHeader = ({ breadCrumbs = [], showProgressBar, showRightContent = false, rightContent, headerTitle }: Props) => {
  const { promiseInProgress } = usePromiseTracker();
  const {
    userInfoStore: { isLexus, isSETUser },
  } = useStores();

  const settingsUrl = `/settings/personal`;
  // Still need to create report page
  const reportUrl = '/reports/incentivePeriods';
  const isLowerEnv = useMemo(() => {
    return window.location.hostname.indexOf('nonprod') !== -1 || window.location.hostname.indexOf('localhost') !== -1;
  }, []);

  const nav = [
    {
      name: '',
      icon: <OATIcon icon="gear" size={14} />,
      component: <Link to={settingsUrl}>Settings</Link>,
    },
    {
      name: '',
      icon: <OATIcon icon="report" size={14} />,
      component: <Link to={reportUrl}>Reports</Link>,
    },
  ];

  if (isSETUser()) {
    nav.unshift({
      name: '',
      icon: <FontAwesomeIcon icon={faShareSquare} />,
      component: <a href={process.env.REACT_APP_MARKETING_URL}>TDA</a>,
    });
  }

  if (FEATURE_OR_3695) {
    nav.push(
      {
        name: '',
        icon: <OATIcon icon="question" size={14} />,
        component: (
          <a href="https://buyatoyota.atlassian.net/wiki/spaces/OATT/pages/1999306759/Regional+How-to+Articles" target="_blank" rel="noreferrer">
            OAT QRG
          </a>
        ),
      },
      {
        name: '',
        icon: <OATIcon icon="warning" size={14} />,
        component: (
          <a href="https://buyatoyota.atlassian.net/secure/CreateIssue.jspa?issuetype=11005&pid=12454" target="_blank" rel="noreferrer">
            Report Issue
          </a>
        ),
      },
    );
  }

  return (
    <>
      <DefaultHeaderLayout
        isProd={!isLowerEnv}
        breadCrumbs={breadCrumbs}
        showRightContent={showRightContent}
        rightContent={rightContent}
        renderLogo={<OATHeaderLogo isLexus={isLexus()} nav={nav} />}
        renderTitle={<OATHeaderTitle>{headerTitle ? headerTitle : APP_NAME}</OATHeaderTitle>}
      />
      <OATToastContainer />
      {(promiseInProgress || showProgressBar) && <OATLoadingBar />}
    </>
  );
};

export default MainHeader;
