import { OATIcon, Tooltip } from 'oat-common-ui';
import React, { useState } from 'react';
import SubvenedRcfModal from './SubvenedRcfModal';
import { FEATURE_OR_4756 } from '../../constants/global';
import useStores from '../../stores/useStores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import TooltipList, { TooltipListItem } from '../TooltipList/TooltipList';
import LeaseFormModel from '../../pages/ProgramDetails/models/LeaseFormModel';
import clsx from 'clsx';
import { formatValue } from '../NumberInput/utils';
import useGetSETSubvenedRcfs from './useGetSETSubvenedRcfs';

interface SetSubvenedLeaseRcfsProps {
  leaseForm: LeaseFormModel;
}

const SetSubvenedLeaseRcfs = ({ leaseForm }: SetSubvenedLeaseRcfsProps) => {
  const {
    rcfsResidualsStore: { subvenedLeaseRcfs },
    userInfoStore: { userInfo, isSETUser },
  } = useStores();

  useGetSETSubvenedRcfs();

  const [isSubvRcfModalOpen, setIsSubvRcfModalOpen] = useState(false);
  const isStandalone = !Boolean(leaseForm.nationalDetails);
  const showAvailableSubRcfTooltip = subvenedLeaseRcfs?.subvenedRcfDetails && subvenedLeaseRcfs.subvenedRcfDetails.length > 0;

  return FEATURE_OR_4756 && isSETUser(userInfo) && isStandalone && showAvailableSubRcfTooltip ? (
    <div className={clsx(leaseForm.inputs.isAdvertised ? styles.subRcfAd : styles.subRcfNonAd, !leaseForm.isSetSubRcfApplied && styles.isAvailable)}>
      {leaseForm.isSetSubRcfApplied ? (
        <Tooltip
          id="subv-rcf-applied"
          place="bottom"
          className={styles.tooltip}
          message={
            <TooltipList className={styles.termList}>
              <TooltipListItem>
                <span className={styles.termTitle}>Subvened RCF Applied</span>
                <span>{formatValue(leaseForm.inputs.rcf, true)}</span>
              </TooltipListItem>
            </TooltipList>
          }
          children={<OATIcon icon="info" colorTheme="blue" />}
        />
      ) : (
        <Tooltip
          id="subv-rcf-available"
          place="bottom"
          message={<SubvenedRcfModal subvenedRcfs={subvenedLeaseRcfs} vehicles={leaseForm.inputs.vehicles} />}
          className={styles.tooltip}
          isOpen={isSubvRcfModalOpen}
          children={
            <div className={styles.subRcfTooltip} onClick={() => setIsSubvRcfModalOpen(!isSubvRcfModalOpen)}>
              <FontAwesomeIcon icon={faFlag} className={styles.icon} />
            </div>
          }
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default SetSubvenedLeaseRcfs;
