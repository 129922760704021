import { AdminConstants } from 'oat-admin-common';
import { getCompatibilityListByOfferType } from '../../../pages/createOffer/utils/getCompatibilityListByOfferType';
import AccordionItemModel from '../models/AccordionItemModel';

const sortOrderList = AdminConstants.COMPATIBILITY_MODAL_OPTION_TYPES;

const createAccordionFromCompatibility = (compatibility: string[], optionType: AdminConstants.OPTION_TYPE_NAMES) => {
  const items: AccordionItemModel[] = [];
  const { defaultTypes, incompatibleTypes, optionalTypes } = getCompatibilityListByOfferType(optionType);
  [...defaultTypes, ...optionalTypes].forEach(type => {
    const mdl = new AccordionItemModel(type, type);
    mdl.checked = !!compatibility.find(c => c === `${type}`);
    items.push(mdl);
  });

  incompatibleTypes.forEach(type => {
    const mdl = new AccordionItemModel(type, type);
    mdl.disabled = true;
    items.push(mdl);
  });

  return items
    .filter(item => !item.value.includes('RYO')) // regarding comment on OR-2019
    .sort((itemA, itemB) => (sortOrderList.findIndex(item => itemA.value === item) > sortOrderList.findIndex(item => itemB.value === item) ? 1 : -1));
};

export default createAccordionFromCompatibility;
