import { dateStringToDate } from 'oat-common-ui';

const dateValidator = (start: Date | string | undefined, end: Date | string | undefined) => {
  if (!end || !start) {
    return true;
  }

  const endDate = dateStringToDate(end);
  const startDate = dateStringToDate(start);

  return endDate.getTime() < startDate.getTime();
};

export default dateValidator;
