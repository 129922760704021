import { observer } from 'mobx-react-lite';
import { Button, OATIcon } from 'oat-common-ui';
import { useEffect, useState } from 'react';
import NumberInput from '../../../../../../../../../components/NumberInput';

import { formatValue } from '../../../../../../../../../components/NumberInput/utils';
import styles from '../styles.module.scss';

type Props = {
  buyRate: number;
  onChange: (value: number) => void;
  isStandalone: boolean;
  isDisabled?: boolean;
};

const AprBuyRateInput = ({ buyRate, onChange, isStandalone, isDisabled }: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [buyRateValue, setBuyRateValue] = useState<number>(buyRate);

  // force re-render of component when different value is passed
  useEffect(() => {
    setBuyRateValue(buyRate);
  }, [buyRate]);

  const buyRateLabel = isStandalone ? 'Regional Buy Rate: ' : 'National: ';

  return (
    <p className={styles.inputLabel}>
      {!isEditMode ? (
        <span>{`${buyRateLabel}${formatValue(buyRateValue, false, false, false, true)}%`}</span>
      ) : (
        <div className={styles.editBuyRateWrapper}>
          <NumberInput id={`apr-${buyRateLabel}-${buyRateValue}`} percentageSign value={buyRateValue} onValueChange={value => setBuyRateValue(Number(value.value))} />
          <Button
            id="confirm-buyRate"
            className={styles.buyRateBtn}
            variant="text"
            onClick={() => {
              setIsEditMode(false);
              onChange(buyRateValue);
            }}
            disabled={isDisabled}
          >
            <OATIcon className={styles.editAprIcon} colorTheme="blue" icon="check-mark" pointer size={16} />
          </Button>
        </div>
      )}
      {!isEditMode && isStandalone && (
        <Button id="edit-buyRate" className={styles.buyRateBtn} variant="text" onClick={() => setIsEditMode(true)} disabled={isDisabled}>
          <OATIcon className={styles.editAprIcon} colorTheme="blue" icon="edit" pointer size={10} />
        </Button>
      )}
    </p>
  );
};

export default observer(AprBuyRateInput);
