import { DefaultModal, OATLink, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Link } from 'react-router-dom';
import TooltipButton from '../../components/TooltipButton';
import { Status } from '../../constants/global';
import { Offering, useCreateAtcOfferingMutation, useDeleteOfferingMutation } from '../../gql/generated';
import useOfferingStatus from '../../hooks/useOfferingStatus';
import useStores from '../../stores/useStores';
import NvsModal from './NvsModal';
import StatusCell from './StatusCell';
import WofcoModal from './WofcoModal';
import styles from './styles.module.scss';
import { processOffering, renderPeriodBalance } from './util';

interface DashboardRowProps {
  isActive: boolean;
  item: Offering;
  showCreatingAtcMsg: (value: boolean) => void;
}

const DashboardRow = ({ item, isActive, showCreatingAtcMsg }: DashboardRowProps) => {
  const {
    userInfoStore: { isNational, getRegionCode },
  } = useStores();
  const { error } = useToast();

  const { offeringStatus } = useOfferingStatus(item);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showWofcoModal, setShowWofcoModal] = useState(false);
  const [showNvsModal, setShowNvsModal] = useState(false);

  const [createATCOffering] = useCreateAtcOfferingMutation();
  const [deleteOffering] = useDeleteOfferingMutation();

  // Todo: integrate logic where national can see regional's approval screen
  const link = isNational() ? `/regionStatus/period/${item.id}` : `/summary/region/${getRegionCode()}/period/${item.id}`;

  const { isReconcileComplete, balance } = processOffering(item);

  const handleCreateATC = async () => {
    try {
      showCreatingAtcMsg(true);
      await trackPromise(createATCOffering({ variables: { input: { id: item.id, rev: item.rev } } }));

      window.location.reload();
    } catch (e) {
      error((e as Error).message);
    } finally {
      showCreatingAtcMsg(false);
    }
  };

  const handleDeleteOffering = async () => {
    try {
      await trackPromise(deleteOffering({ variables: { input: { id: item.id, rev: item.rev } } }));

      window.location.reload();
    } catch (e) {
      error((e as Error).message);
    } finally {
      setShowDeleteModal(false);
    }
  };

  const renderActionIcon = () => {
    return (
      <>
        {([Status.NO_RESPONSE, Status.DRAFT, Status.ATC_DRAFT].includes(offeringStatus as Status) || item.isAtc) && (
          <TooltipButton iconName="trash" id={`${item.id}-delete`} message="Delete" onClickHandler={() => setShowDeleteModal(true)} />
        )}

        {offeringStatus === Status.MEETS_GUIDELINES && !isNational() && !Boolean(item.atcOfferingId) && (
          <TooltipButton iconName="edit" id={`${item.id}-create`} message="Create ATC" onClickHandler={() => handleCreateATC()} />
        )}

        {offeringStatus === Status.ALL_GUIDELINES_MET && isNational() && (
          <TooltipButton id={`nvs-${item.id}`} message="Upload NVS Units" iconName="gear" onClickHandler={() => setShowNvsModal(true)} />
        )}
      </>
    );
  };

  return (
    <tr className={styles.offeringRow}>
      <td>
        <Link component={OATLink} to={link}>
          {item.name}
        </Link>
      </td>
      <td>{item.startDate}</td>
      <td>{item.endDate}</td>
      <StatusCell status={offeringStatus} />
      {/* need to add attribute for check if its sandbox for first param in renderPeriodBalance */}
      {!isNational() && <td className={balance && balance > 0 ? styles.positiveColor : styles.negativeColor}>{renderPeriodBalance(false, isReconcileComplete, balance || 0)}</td>}
      {((!isNational() && isActive) || isNational()) && (
        <td>
          {renderActionIcon()}
          {showDeleteModal && (
            <DefaultModal
              message="Are you sure you would like to delete this draft?"
              onClose={() => setShowDeleteModal(false)}
              onSubmit={() => handleDeleteOffering()}
              open
              submitText="Confirm"
              title="Delete Draft"
            />
          )}
          {showWofcoModal && <WofcoModal offering={item} onClose={() => setShowWofcoModal(false)} />}
          {showNvsModal && <NvsModal offering={item} onClose={() => setShowNvsModal(false)} />}
        </td>
      )}
    </tr>
  );
};

export default DashboardRow;
