import clsx from 'clsx';
import { Textarea } from 'oat-common-ui';
import { ChangeEvent } from 'react';
import styles from './styles.module.scss';

type Props = {
  darkTheme?: boolean;
  disabled?: boolean;
  error?: boolean;
  id?: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  value?: string;
  className?: string;
};

const TextArea = ({ darkTheme, disabled, id, error, onChange, value = '', required, className }: Props) => {
  return (
    <Textarea
      className={clsx(styles.textarea, className)}
      containerClassName={styles.textareaContainer}
      darkTheme={darkTheme}
      disabled={disabled}
      error={error}
      id={id}
      onChange={onChange}
      required={required}
      value={value}
    />
  );
};

export default TextArea;
