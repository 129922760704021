import { makeAutoObservable } from 'mobx';
import { AdminConstants } from 'oat-admin-common';
import { Offering } from '../../../gql/generated';
import { assignDollarCents, assignNumberValue, assignStringValue } from '../../../utils/assignValue';
import ReconcileOfferModel from './ReconcileOfferModel';
import ReconcileSeriesModel from './ReconcileSeriesModel';
export type InputType = number | undefined;
export class RecconcileFields {
  totalNvsUnits: InputType = undefined;
  totalNationalActualUnits: InputType = undefined;
  totalRegionalActualUnits: InputType = undefined;
  nationalMiscAdjAmount: InputType = undefined;
  nationalMiscAdjNote = '';
  regionalMiscAdjAmount: InputType = undefined;
  regionalMiscAdjNote = '';
  isNvsComplete = false;
  isReconcileComplete = false;
}

class ReconcileDataModel {
  fields = new RecconcileFields();
  isEnhancementsOnly = false;
  lastNvsCompletedDate = '';
  lastNvsCompletedBy = '';
  lastReconcileCompletedDate = '';
  lastReconcileCompletedBy = '';
  lastReconcileUpdatedBy = '';
  lastReconcileUpdatedDate = '';
  series: ReconcileSeriesModel[] = [];
  isCompleteNvsClicked = false;
  isCompleteReconcileClicked = false;
  totalPenData = {
    totalForecastedSalesVolume: 0,
    totalProjectedEarnings: 0,
    totalProjectedOfferCosts: 0,
    totalProjectedBalance: 0,
    totalNvsUnits: 0,
    totalNvsEarnings: 0,
    totalNvsOfferCost: 0,
    totalNvsBalance: 0,
    totalNationalActualSalesVolume: 0,
    totalRegionalActualSalesVolume: 0,
    totalRegionalActualEarnings: 0,
    totalRegionalActualOfferCosts: 0,
    totalRegionalActualBalance: 0,
    totalRegionalActualBalanceVariance: 0,
    totalNationalActualEarnings: 0,
    totalNationalActualOfferCosts: 0,
    totalNationalActualBalance: 0,
    totalNationalActualBalanceVariance: 0,
  };

  constructor() {
    makeAutoObservable(this);
  }

  updateField = <T extends keyof RecconcileFields, V extends RecconcileFields[T]>(field: T, value: V) => {
    this.fields[field] = value;
  };

  calculateTotalsForSeriesTable = () => {
    this.series.forEach(srs => {
      let forecastedSales = 0;
      let estimatedCost = 0;
      let offerCost = 0;
      let totalNationalActualUnits = 0;
      let totalNationalActualCosts = 0;
      let totalNationalActualOfferCosts = 0;
      let totalPenetration = 0;

      srs.allOffers.forEach(offer => {
        forecastedSales += offer.isCustomerChoice ? assignNumberValue(offer.forecastedSales) : 0;
        estimatedCost += assignNumberValue(offer.estimatedCost);
        offerCost += assignNumberValue(offer.offerCost);
        totalPenetration += offer.isCustomerChoice ? assignNumberValue(offer.penetration) : 0;
        totalNationalActualUnits += offer.nationalActualUnits && offer.isCustomerChoice ? assignNumberValue(offer.nationalActualUnits) : 0;
        totalNationalActualCosts += assignNumberValue(offer.nationalActualCost);
        totalNationalActualOfferCosts += assignNumberValue(offer.nationalActualOfferCost);
      });

      srs.totalForecastedSales = forecastedSales;
      srs.totalEstimatedCost = estimatedCost;
      srs.totalOfferCost = offerCost;
      srs.totalNationalActualUnits = totalNationalActualUnits;
      srs.totalNationalActualCosts = totalNationalActualCosts;
      srs.totalNationalActualOfferCosts = totalNationalActualOfferCosts;
      srs.totalProjectedPenRate = totalPenetration;
      srs.calculateRemainingProjectedEarnings();
    });
  };

  calculateTotalsForSummaryTable = () => {
    let totalForecastedSalesVolume = 0;
    let totalProjectedEarnings = 0;
    let totalProjectedOfferCosts = 0;
    let totalProjectedBalance = 0;
    let totalNvsUnits = 0;
    let totalNvsEarnings = 0;
    let totalNvsOfferCost = 0;
    let totalNvsBalance = 0;
    let totalNationalActualSalesVolume = 0;
    let totalRegionalActualSalesVolume = 0;
    let totalRegionalActualEarnings = 0;
    let totalRegionalActualOfferCosts = 0;
    let totalRegionalActualBalance = 0;
    let totalRegionalActualBalanceVariance = 0;
    let totalNationalActualEarnings = 0;
    let totalNationalActualOfferCosts = 0;
    let totalNationalActualBalance = 0;
    let totalNationalActualBalanceVariance = 0;

    this.series.forEach(series => {
      totalForecastedSalesVolume += series.forecastedSalesVolume;
      totalProjectedEarnings += series.raEarnings;
      totalProjectedOfferCosts += series.raCost;
      totalProjectedBalance += series.raBalance;

      totalNvsUnits += assignNumberValue(series.fields.nvsUnits);
      totalNvsEarnings += series.totalNvsEarnings;
      totalNvsOfferCost += series.totalNvsOfferCost;
      totalNvsBalance += series.totalNvsBalance;

      totalNationalActualSalesVolume += assignNumberValue(series.fields.nationalActualSalesVolume);
      totalRegionalActualSalesVolume += assignNumberValue(series.fields.regionalActualSalesVolume);
      totalRegionalActualEarnings += series.totalRegionalActualEarnings;
      totalRegionalActualOfferCosts += series.totalRegionalActualOfferCosts;
      totalRegionalActualBalance += series.totalRegionalActualBalance;
      totalRegionalActualBalanceVariance += series.regionalBalanceVariance;
      totalNationalActualEarnings += series.totalNationalActualEarnings;
      totalNationalActualOfferCosts += series.totalNationalActualOfferCosts;
      totalNationalActualBalance += series.totalNationalActualBalance;
      totalNationalActualBalanceVariance += series.nationalBalanceVariance;
    });

    this.totalPenData.totalForecastedSalesVolume = totalForecastedSalesVolume;
    this.totalPenData.totalProjectedEarnings = totalProjectedEarnings;
    this.totalPenData.totalProjectedOfferCosts = assignDollarCents(totalProjectedOfferCosts);
    this.totalPenData.totalProjectedBalance = totalProjectedBalance;
    this.totalPenData.totalNvsUnits = totalNvsUnits;
    this.totalPenData.totalNvsEarnings = totalNvsEarnings;
    this.totalPenData.totalNvsOfferCost = totalNvsOfferCost;
    this.totalPenData.totalNvsBalance = totalNvsBalance;
    this.totalPenData.totalNationalActualSalesVolume = totalNationalActualSalesVolume;
    this.totalPenData.totalRegionalActualSalesVolume = totalRegionalActualSalesVolume;
    this.totalPenData.totalRegionalActualEarnings = totalRegionalActualEarnings;
    this.totalPenData.totalRegionalActualOfferCosts = totalRegionalActualOfferCosts;
    this.totalPenData.totalRegionalActualBalance = totalRegionalActualBalance;
    this.totalPenData.totalRegionalActualBalanceVariance = assignDollarCents(totalRegionalActualBalanceVariance);
    this.totalPenData.totalNationalActualEarnings = totalNationalActualEarnings;
    this.totalPenData.totalNationalActualOfferCosts = totalNationalActualOfferCosts;
    this.totalPenData.totalNationalActualBalance = totalNationalActualBalance;
    this.totalPenData.totalNationalActualBalanceVariance = assignDollarCents(totalNationalActualBalanceVariance);
  };

  calculateEarningsOnlySP = (isNational: boolean) => {
    let totalNvsUnits = 0;
    let actualSalesVolumeSum = 0;
    const earningsOnlySp = this.series.filter(series => series.isEarningsOnly)[0];

    if (earningsOnlySp) {
      this.series.forEach(series => {
        if (!series.isEarningsOnly && !series.isMultiSeries) {
          totalNvsUnits += assignNumberValue(series.fields.nvsUnits);
          actualSalesVolumeSum += isNational ? assignNumberValue(series.fields.nationalActualSalesVolume) : assignNumberValue(series.fields.regionalActualSalesVolume);
        }
      });
      if (isNational) {
        earningsOnlySp.fields.nvsUnits = assignNumberValue(this.fields.totalNvsUnits) - totalNvsUnits;
        earningsOnlySp.totalNationalActualUnits = assignNumberValue(this.fields.totalNationalActualUnits) - actualSalesVolumeSum;
        earningsOnlySp.fields.nationalActualSalesVolume = earningsOnlySp.totalNationalActualUnits;
      } else {
        earningsOnlySp.totalRegionalActualUnits = assignNumberValue(this.fields.totalRegionalActualUnits) - actualSalesVolumeSum;
        earningsOnlySp.fields.regionalActualSalesVolume = earningsOnlySp.totalRegionalActualUnits;
      }
      this.calculateTotalsForSummaryTable();
    }
  };

  updateActuals = (series: ReconcileSeriesModel, offer: ReconcileOfferModel, param = 'none', value: number, isNational: boolean) => {
    /* calculate actual units for customer cash automatically for triple choice offers */
    let aprUnitsSum = 0;
    let leaseUnitsSum = 0;
    let miscUnitsSum = 0;

    switch (param) {
      case 'regionalUnits': {
        offer.regionalActualUnits = value;
        break;
      }
      case 'nationalUnits': {
        offer.nationalActualUnits = value;
        break;
      }
      case 'regionalPnv': {
        offer.regionalActualCost = value;
        break;
      }
      case 'nationalPnv': {
        offer.nationalActualCost = value;
        break;
      }
    }
    // make sure do not calculate CC actual Units if user is entering units for customer cash or if there are more than 1 customer cash offers.
    if (
      offer.optionTypeName === AdminConstants.OPTION_TYPE_NAMES.APR ||
      offer.optionTypeName === AdminConstants.OPTION_TYPE_NAMES.LEASE ||
      (offer.isMisc && offer.isFourthOption && series.isTripleChoice && series.totalCustomerCash === 1)
    ) {
      // sum all apr and lease actual units first
      series.offers.forEach(ofr => {
        if (ofr.optionTypeName === AdminConstants.OPTION_TYPE_NAMES.APR) {
          aprUnitsSum += isNational ? assignNumberValue(ofr.nationalActualUnits) : assignNumberValue(ofr.regionalActualUnits);
        } else if (ofr.optionTypeName === AdminConstants.OPTION_TYPE_NAMES.LEASE) {
          leaseUnitsSum += isNational ? assignNumberValue(ofr.nationalActualUnits) : assignNumberValue(ofr.regionalActualUnits);
        } else if (ofr.isMisc && ofr.isFourthOption) {
          miscUnitsSum += isNational ? assignNumberValue(ofr.nationalActualUnits) : assignNumberValue(ofr.regionalActualUnits);
        }
      });
      // now calculate actual units for customer cash
      series.offers.forEach(ofr => {
        if (ofr.optionTypeName === AdminConstants.OPTION_TYPE_NAMES.CUSTOMER_CASH) {
          if (isNational) {
            ofr.nationalActualUnits = assignNumberValue(series.fields.nationalActualSalesVolume) - leaseUnitsSum - aprUnitsSum - miscUnitsSum;
            ofr.calcNatCost = true;
          } else {
            ofr.regionalActualUnits = assignNumberValue(series.fields.regionalActualSalesVolume) - leaseUnitsSum - aprUnitsSum - miscUnitsSum;
            ofr.calcRegCost = true;
          }
        }
      });
    }

    series.updateSubCash(isNational, offer);
    if (isNational) {
      offer.calcNatCost = true;
    } else {
      offer.calcRegCost = true;
    }
    series.calculateActualCosts(isNational);
    series.validateActualUnits(isNational);
  };

  calculateActualUnitsAndCost = (
    isFlatCostOfferType: boolean,
    actualPen: number,
    units: number,
    actualPnv: number,
    offer: ReconcileOfferModel,
    dealerStock: number,
    salesVolume: number,
  ) => {
    const actualUnits = Math.round((actualPen / 100) * (offer.optionTypeName === AdminConstants.OPTION_TYPE_NAMES.FINAL_PAY ? dealerStock : salesVolume));
    const actualCost = isFlatCostOfferType ? actualPnv : assignDollarCents((offer.optionTypeName === AdminConstants.OPTION_TYPE_NAMES.FINAL_PAY ? dealerStock : units) * actualPnv);

    return { actualUnits, actualCost };
  };

  updateActualsByPenetration = (series: ReconcileSeriesModel, offer: ReconcileOfferModel, value: InputType, isNational: boolean) => {
    if (isNational) {
      const { actualUnits, actualCost } = this.calculateActualUnitsAndCost(
        offer.isFlatCostOfferType,
        assignNumberValue(value),
        assignNumberValue(offer.nationalActualUnits),
        assignNumberValue(offer.nationalActualCost),
        offer,
        assignNumberValue(series.fields.nationalActualDealerStock),
        assignNumberValue(series.fields.nationalActualSalesVolume),
      );
      offer.nationalActualPenetration = value;
      offer.nationalActualUnits = actualUnits;
      offer.nationalActualOfferCost = actualCost;

      series.totalNationalActualPenetration = series.offers.reduce((sum, offer) => {
        return sum + (offer.isCustomerChoice ? assignNumberValue(offer.nationalActualPenetration) : 0);
      }, 0);
    } else {
      const { actualUnits, actualCost } = this.calculateActualUnitsAndCost(
        offer.isFlatCostOfferType,
        assignNumberValue(value),
        assignNumberValue(offer.regionalActualUnits),
        assignNumberValue(offer.regionalActualCost),
        offer,
        assignNumberValue(series.fields.regionalActualDealerStock),
        assignNumberValue(series.fields.regionalActualSalesVolume),
      );
      offer.regionalActualPenetration = value;
      offer.regionalActualUnits = actualUnits;
      offer.regionalActualOfferCost = actualCost;

      series.totalRegionalActualPenetration = series.offers.reduce((sum, offer) => {
        return sum + (offer.isCustomerChoice ? assignNumberValue(offer.regionalActualPenetration) : 0);
      }, 0);
    }

    series.totalRegionalActualCosts = series.offers.reduce(sum => {
      return sum + assignNumberValue(offer.regionalActualCost);
    }, 0);
    series.totalNationalActualCosts = series.offers.reduce(sum => {
      return sum + assignNumberValue(offer.nationalActualCost);
    }, 0);
    this.updateActuals(series, offer, 'none', 0, isNational);
    series.updateSubCash(isNational, offer);
    series.validateActualUnits(isNational);
  };

  calcActualPnvVariance = (isFlat: boolean, actualCost: number, units: number, projCost: number) => {
    const actualPnv = isFlat || !units ? 0 : assignDollarCents(actualCost / units);
    const costVariance = projCost - actualCost;
    return { actualPnv, costVariance };
  };

  updateActualsByCost = (series: ReconcileSeriesModel, offer: ReconcileOfferModel, value: InputType, isNational: boolean) => {
    if (isNational) {
      offer.calcNatCost = false;
      offer.nationalActualOfferCost = value;
    } else {
      offer.calcRegCost = false;
      offer.regionalActualOfferCost = value;
    }

    if (offer.optionTypeName === AdminConstants.OPTION_TYPE_NAMES.FINAL_PAY) {
      if (!isNational && assignNumberValue(series.fields.regionalActualDealerStock) > 0) {
        const { actualPnv, costVariance } = this.calcActualPnvVariance(
          offer.isFlatCostOfferType,
          assignNumberValue(offer.regionalActualOfferCost),
          assignNumberValue(series.fields.regionalActualDealerStock),
          offer.offerCost,
        );
        offer.regionalActualCost = actualPnv;
        offer.regionalCostVariance = costVariance;
      } else if (isNational && assignNumberValue(series.fields.nationalActualDealerStock) > 0) {
        const { actualPnv, costVariance } = this.calcActualPnvVariance(
          offer.isFlatCostOfferType,
          assignNumberValue(offer.nationalActualOfferCost),
          assignNumberValue(series.fields.nationalActualDealerStock),
          offer.offerCost,
        );
        offer.nationalActualCost = actualPnv;
        offer.nationalCostVariance = costVariance;
      }
    } else {
      if (!isNational && (assignNumberValue(offer.regionalActualUnits) > 0 || offer.isFlatCostOfferType)) {
        const { actualPnv, costVariance } = this.calcActualPnvVariance(
          offer.isFlatCostOfferType,
          assignNumberValue(offer.regionalActualOfferCost),
          assignNumberValue(offer.regionalActualUnits),
          offer.offerCost,
        );
        offer.regionalActualCost = actualPnv;
        offer.regionalCostVariance = costVariance;
      } else if (isNational && (assignNumberValue(offer.nationalActualUnits) > 0 || offer.isFlatCostOfferType)) {
        const { actualPnv, costVariance } = this.calcActualPnvVariance(
          offer.isFlatCostOfferType,
          assignNumberValue(offer.nationalActualOfferCost),
          assignNumberValue(offer.nationalActualUnits),
          offer.offerCost,
        );
        offer.nationalActualCost = actualPnv;
        offer.nationalCostVariance = costVariance;
      }
    }
    series.calculateActualCosts(isNational);
  };

  /* copy national Actual Sales Volume, Actual Dealer Stock, Actual Units, Actual Pen%, Actual PNV, and Actual Cost */
  copyNationalActuals = (series: ReconcileSeriesModel, isNational: boolean) => {
    series.fields.regionalActualSalesVolume = series.fields.nationalActualSalesVolume;
    series.fields.regionalActualDealerStock = series.fields.nationalActualDealerStock;
    series.offers.forEach(offer => {
      offer.regionalActualUnits = offer.nationalActualUnits;
      offer.regionalActualPenetration = offer.nationalActualPenetration;
      offer.regionalActualCost = offer.nationalActualCost;
      offer.regionalActualOfferCost = offer.nationalActualOfferCost;
    });
    series.calculateActualCosts(isNational);
    series.validateActualUnits(isNational);
  };

  /* copy All profile's national Actual Sales Volume, Actual Dealer Stock, Actual Units, Actual Pen%, Actual PNV, and Actual Cost */
  copyAllNationalActuals = (isNational: boolean) => {
    this.totalPenData.totalRegionalActualSalesVolume = this.totalPenData.totalNationalActualSalesVolume;
    this.series.forEach(series => {
      if (!series.isAdjustment) {
        this.copyNationalActuals(series, isNational);
      }
    });
    this.calculateEarningsOnlySP(isNational);
  };

  setIsCompleteNvsClicked = () => {
    this.isCompleteNvsClicked = true;
  };

  setIsCompleteReconcileClicked = () => {
    this.isCompleteReconcileClicked = true;
  };

  validateInputsForReconcile = () => {
    let isValid = true;

    if (this.isCompleteNvsClicked || this.isCompleteReconcileClicked) {
      this.series.forEach(srs => {
        srs.invalidSummarySeries = false;
        srs.invalidSummarySeriesDetail = false;
        srs.invalidSeriesDetail = false;

        if (!srs.isAdjustment) {
          if (srs.fields.hasNvsUnitsError) {
            srs.invalidSummarySeries = true;
            isValid = false;
          }
          if (srs.fields.hasSalesVolumeError) {
            srs.invalidSummarySeriesDetail = true;
            isValid = false;
          }

          if (this.isCompleteReconcileClicked) {
            srs.offers.forEach(offer => {
              if (offer.hasActualUnitsError || offer.hasActualCostError) {
                srs.invalidSeriesDetail = true;
                isValid = false;
              }
            });
          }
        }
      });
    }

    return isValid;
  };

  updateData = (offering: Offering) => {
    this.lastNvsCompletedDate = assignStringValue(offering.lastNvsCompletedDate);
    this.lastNvsCompletedBy = assignStringValue(offering.lastNvsCompletedBy);
    this.lastReconcileCompletedDate = assignStringValue(offering.lastReconcileCompletedDate);
    this.lastReconcileCompletedBy = assignStringValue(offering.lastReconcileCompletedBy);
    this.lastReconcileUpdatedBy = assignStringValue(offering.lastReconcileUpdatedBy);
    this.lastReconcileUpdatedDate = assignStringValue(offering.lastReconcileUpdatedDate);
  };
}

export default ReconcileDataModel;
