import { observer } from 'mobx-react-lite';
import { OATIcon, OATWrapper, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { OfferingCosts, useSaveSeriesProfileMutation, useSelectRgnlAltMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import ProfileModel from '../../models/ProfileModel';
import RgnlAltModel from '../../models/RgnlAltModel';
import FsvCosts from '../FsvCosts';
import RgnlAltSection from '../RgnlAltSection';
import ProfileHeader from './ProfileHeader';

import styles from './styles.module.scss';

type Props = {
  profile: ProfileModel;
};

const SeriesProfileSection = ({ profile }: Props) => {
  const { error } = useToast();

  const {
    summaryStore: { offering },
    offeringCostsStore,
  } = useStores();
  const [selectRa] = useSelectRgnlAltMutation();
  const [saveSeriesProfile] = useSaveSeriesProfileMutation();

  const selectedRa = profile.getSelectedRgnAlt();

  const handleSelectRa = async (rgnlAlt: RgnlAltModel) => {
    try {
      const res = await trackPromise(selectRa({ variables: { id: rgnlAlt.id, rev: rgnlAlt.rev, isSelected: !rgnlAlt.isSelected } }));
      const resRa = res.data?.selectRgnlAltById.rgnlAlt;
      if (resRa) {
        profile.selectRgnlAlt(resRa.id);
        profile.validateOffers(profile.rgnAltOffersMap.get(resRa.id));
        rgnlAlt.updateRev(resRa.rev);
        offeringCostsStore.setData(res.data?.selectRgnlAltById.offeringCosts as OfferingCosts);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleSetTitle = async (title: string) => {
    try {
      const res = await trackPromise(saveSeriesProfile({ variables: { id: profile.id, name: title, rev: profile.rev } }));
      profile.updateRev(res.data?.saveSeriesProfile.seriesProfile.rev);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const RgnAltView = () => {
    return selectedRa ? (
      <RgnlAltSection profile={profile} rgnlAlt={selectedRa} selected onSelect={handleSelectRa} />
    ) : (
      <>
        {profile.rgnlAlts?.map(raItem => (
          <RgnlAltSection key={raItem.id} profile={profile} rgnlAlt={raItem} onSelect={handleSelectRa} />
        ))}
      </>
    );
  };

  return (
    <section id={profile.id} className={styles.spSection}>
      <OATWrapper className={styles.oatWrapper} fixed withPadding>
        <ProfileHeader profile={profile} onSelect={handleSelectRa} setTitle={handleSetTitle} />
        {/* Showing confirmed view */}
        {profile.isConfirmedView && selectedRa ? (
          <section className={styles.confirmedView}>
            <FsvCosts rgnlAlt={selectedRa} isConfirmedView={Boolean(selectRa)} />
            {selectedRa.getHasMidMonthEnhancement(offering.startDate, offering.endDate) && (
              <div className={styles.midMonthEnhancement}>
                <OATIcon className={styles.icon} icon="calendar" size={14} />
                <span className={styles.label}>Mid Month Enhancement</span>
              </div>
            )}
          </section>
        ) : (
          <RgnAltView />
        )}
      </OATWrapper>
    </section>
  );
};

export default observer(SeriesProfileSection);
