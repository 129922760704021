import { trackPromise } from 'react-promise-tracker';
import { CombinedOffers, OfferingCosts, PenetrationDetails, SavePenetrationResult, useSavePenetrationMutation } from '../../gql/generated';
import RgnlAltModel from '../../pages/Summary/models/RgnlAltModel';
import useStores from '../../stores/useStores';
import transformPenratesPayload from './transformPenratesPayload';

const usePenRatesSaveUtils = (rgnlAlt: RgnlAltModel, onSave?: (penetrationDetails: PenetrationDetails[], res?: SavePenetrationResult) => void) => {
  const {
    offeringCostsStore,
    summaryStore: { findProfileById },
  } = useStores();
  const [savePenetration] = useSavePenetrationMutation();

  const savePenRate = async () => {
    if (!rgnlAlt.hasErrors) {
      const penetrationDetails = transformPenratesPayload(rgnlAlt);
      const res = await trackPromise(
        savePenetration({
          variables: { input: { rgnlAltId: rgnlAlt.id, isPenetrationEditable: rgnlAlt.editPenRate, penetrationDetails } },
        }),
      );

      rgnlAlt.updateRev(res.data?.savePenetration.rgnlAlt.rev ?? '');
      findProfileById(rgnlAlt.seriesProfileId)?.updateRev(res.data?.savePenetration.seriesProfile.rev);
      rgnlAlt.updatePenRateRevs(res.data?.savePenetration.rgnlAlt.offers as CombinedOffers[]);
      offeringCostsStore.setData(res.data?.savePenetration.offeringCosts as OfferingCosts);

      if (onSave) {
        onSave(penetrationDetails, res.data?.savePenetration as SavePenetrationResult);
      }
    }
  };

  return {
    savePenRate,
  };
};

export default usePenRatesSaveUtils;
