import { observer } from 'mobx-react-lite';
import { OATWrapper } from 'oat-common-ui';
import ProfileModel from '../../models/ProfileModel';
import ProfileHeader from '../SeriesProfileSection/ProfileHeader';
import SummaryTable from '../SummaryTable';
import styles from './styles.module.scss';

interface Props {
  profile: ProfileModel;
}

const Profile = ({ profile }: Props) => {
  return (
    <section id={profile.id} className={styles.spSection}>
      <OATWrapper className={styles.oatWrapper} fixed>
        <ProfileHeader profile={profile} />
        <SummaryTable profile={profile} />
      </OATWrapper>
    </section>
  );
};

export default observer(Profile);
