import { TableContainer, TableTitle } from 'oat-common-ui';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  tableTitle: string;
  children: ReactNode;
}

const TableWrapper = ({ tableTitle, children }: Props) => {
  return (
    <TableContainer className={styles.regionsTable}>
      <TableTitle>{tableTitle}</TableTitle>
      {children}
    </TableContainer>
  );
};

export default TableWrapper;
