type InputType = string | number | null | boolean | undefined;

export const assignStringValue = (value: InputType, defaultValue = '') => {
  if (value === null || value === undefined || !value) {
    return defaultValue;
  }

  return value.toString();
};

export const assignNumberValue = (value: InputType, defaultValue = 0) => {
  if (value === null || value === undefined) {
    return defaultValue;
  }

  return Number(value);
};

// Used for doing calculations. Use assignNumberValue for SETTING data.
export const assignDollarCents = (value: InputType, defaultValue = 0) => {
  return Number(assignNumberValue(value, defaultValue).toFixed(2));
};

export const assignBooleanValue = (value: InputType, defaultValue: boolean) => {
  if (value === null || value === undefined) {
    return defaultValue;
  }

  return Boolean(value);
};

export const defaultToUndefined = (value: InputType, defaultValue = undefined) => {
  if (value === null) {
    return defaultValue;
  }

  return assignNumberValue(value);
};

export const defaultBooleanToUndefined = (value: InputType, defaultValue = undefined) => {
  if (value === null || value === undefined) {
    return defaultValue;
  }

  return Boolean(value);
};

export const defaultToNull = (value: InputType, defaultValue = null) => {
  if (value === undefined) {
    return defaultValue;
  }

  return assignNumberValue(value);
};
