import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Offering } from '../../gql/generated';
import ProfileModel from '../../pages/Summary/models/ProfileModel';
import useStores from '../../stores/useStores';
import ATCToggleButtons from './ATCToggleButtons';
import ReconcileHeader from './ReconcileHeader';
import RightPanel from './RightPanel';
import { SubHeaderContainer } from './SubHeaderContainer';
import SubHeaderDates from './SubHeaderDates';
import SubHeaderInputContainer from './SubHeaderInputContainer';
import styles from './styles.module.scss';
import { Status } from '../../constants/global';

interface SubHeaderProps {
  profiles: ProfileModel[];
  updateCarryOver: () => void;
  showRightPanel?: boolean;
  isReconcile?: boolean;
  isEnhancementsOnly?: boolean;
  toggleEnhanced?: (enhanced: boolean) => void;
  offering: Offering;
}

const SubHeader: FC<SubHeaderProps> = ({ profiles, updateCarryOver, showRightPanel = true, isReconcile = false, isEnhancementsOnly = true, toggleEnhanced, offering }) => {
  const {
    userInfoStore: { isNational },
  } = useStores();
  const isDisabled = Boolean(isNational() && offering.isAtc) || offering.status === Status.MEETS_GUIDELINES;

  return (
    <SubHeaderContainer className={styles.subHeader}>
      <div className={styles.leftPanel}>
        <SubHeaderDates startDate={offering.startDate} endDate={offering.endDate} />
        <SubHeaderInputContainer profiles={profiles} />
        {isReconcile && toggleEnhanced && <ReconcileHeader isEnhancementsOnly={isEnhancementsOnly} toggleEnhanced={toggleEnhanced} />}
        {isNational() && ((offering.isAtc && offering.originalOfferingId) || (!offering.isAtc && offering.atcOfferingId)) && <ATCToggleButtons offering={offering} />}
      </div>
      {showRightPanel && <RightPanel updateCarryOver={updateCarryOver} offering={offering} profiles={profiles} isEditable={!isDisabled} />}
    </SubHeaderContainer>
  );
};

export default observer(SubHeader);
