export const convertTermToTermRange = (term: number, termData: Array<{ term: number; tiers: string[] }>): string => {
  const findIndex = termData.findIndex(_term => _term.term === term);

  if (findIndex === 0) {
    return `0 - ${term}`;
  }

  const previousTermIncreased = termData[findIndex - 1].term + 1;

  return `${previousTermIncreased} - ${term}`;
};
