import { RgnlAlt } from '../gql/generated';

const defaultRgnlAlt: RgnlAlt = {
  areCostsCalculated: false,
  created: '',
  createdBy: '',
  id: '',
  isComparedToPrior: false,
  isNationalRyoRgnlAlt: false,
  isPenRateValidationRequired: false,
  isPenetrationEditable: false,
  isProgramFinal: false,
  isSelected: false,
  isSelectedPrior: false,
  isSeriesConfirmed: false,
  isStandalone: false,
  isSubmittedToNational: false,
  name: '',
  rev: '',
  seriesProfileId: '',
  states: [],
  status: '',
  updated: '',
  updatedBy: '',
  regionCode: '',
};

export default defaultRgnlAlt;
