import { VehicleInput } from '../gql/generated';

export const getIncludedExcludedVehicles = (vehicles?: VehicleInput[]) => {
  const includedVehicles: VehicleInput[] = [];
  const excludedVehicles: VehicleInput[] = [];

  vehicles?.forEach(vehicle => {
    if (vehicle.isInclusion) {
      includedVehicles.push(vehicle);
    } else {
      excludedVehicles.push(vehicle);
    }
  });

  includedVehicles.sort();
  excludedVehicles.sort();

  return {
    includedVehicles,
    excludedVehicles,
  };
};
