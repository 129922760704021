import { makeAutoObservable } from 'mobx';
import { uuidv4 } from 'oat-common-ui';
import { LeaseDetails, LeaseExample, Offer } from '../../../gql/generated';
import LeaseCardModel from './LeaseCardModel';
import { setLeaseExampleInputs } from './LeaseFormInputs';
import LeaseFormModel from './LeaseFormModel';
import ResidualValue from './ResidualValue';

/**
 * Contains the master term lease and all the examples
 */
class LeaseTermModel {
  uid = uuidv4();
  currentFormUid = '';
  term = 36;
  tier = '1+';
  nationalDetails: LeaseDetails | null | undefined; // used when adding leases during runtime (ex. add example, term)
  residuals: ResidualValue[] | undefined; // same as above
  leaseForms: LeaseFormModel[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setForms = (leaseCard: LeaseCardModel, regionalOffer: Offer, regionalDetails: LeaseDetails, nationalDetails?: LeaseDetails | null, residuals?: ResidualValue[]) => {
    const leaseForms: LeaseFormModel[] = [];
    this.nationalDetails = nationalDetails;
    this.residuals = residuals;

    // process master lease
    const masterForm = this.createLeaseForm(leaseCard, regionalOffer, regionalDetails, nationalDetails, residuals);
    this.currentFormUid = masterForm.uid;
    this.term = masterForm.inputs.term;
    this.tier = masterForm.inputs.tier;
    leaseForms.push(masterForm);

    // process examples
    if (regionalDetails.leaseExamples) {
      regionalDetails.leaseExamples.forEach(ex => {
        const form = this.createLeaseForm(leaseCard, regionalOffer, regionalDetails, nationalDetails, residuals);
        setLeaseExampleInputs(form, ex);
        form.calculateDefaultPayments();
        leaseForms.push(form);
      });
    }

    this.leaseForms = leaseForms;
  };

  // util function used to create master and the base values for examples
  createLeaseForm = (leaseCard: LeaseCardModel, regionalOffer: Offer, regionalDetails: LeaseDetails, nationalDetails?: LeaseDetails | null, residuals?: ResidualValue[]) => {
    const leaseForm = new LeaseFormModel();
    leaseForm.isGST = leaseCard.isGST;
    leaseForm.isSET = leaseCard.isSET;
    leaseForm.isLexus = leaseCard.isLexus;
    leaseForm.setDetails(regionalOffer, regionalDetails, leaseCard.isLexus, nationalDetails, residuals);

    return leaseForm;
  };

  setCurrentFormUid = (uid: string) => {
    this.currentFormUid = uid;
  };

  // a master lease should exist at all times
  getMaster = () => {
    return this.leaseForms.find(item => !item.isExample) as LeaseFormModel;
  };

  addExample = (leaseCard: LeaseCardModel, offer: Offer, name: string) => {
    const details = offer.leaseDetails?.find(item => item.highTerm === this.term) as LeaseDetails;
    const example = details.leaseExamples?.find(item => item.name === name) as LeaseExample;
    const leaseForm = this.createLeaseForm(leaseCard, offer, details, this.nationalDetails, this.residuals);
    setLeaseExampleInputs(leaseForm, example);

    // set efc related info from master
    const masterLease = this.getMaster();
    if (masterLease) {
      leaseForm.seriesName = masterLease.seriesName;
      leaseForm.carJelly = masterLease.carJelly;
      leaseForm.invoiceDate = masterLease.invoiceDate;
      leaseForm.dealerCode = masterLease.dealerCode;
      leaseForm.vinOptions = masterLease.vinOptions;
      leaseForm.inputs.dph = masterLease.inputs.dph;
    }

    this.leaseForms = [...this.leaseForms, leaseForm];
    this.currentFormUid = leaseForm.uid;
  };

  removeExample = () => {
    const currentLeaseForm = this.leaseForms.find(item => item.uid === this.currentFormUid);
    if (currentLeaseForm) {
      this.leaseForms = this.leaseForms.filter(item => item.uid !== currentLeaseForm.uid);

      if (this.leaseForms.length > 0) {
        const nextLeaseForm = this.leaseForms.slice().reverse()[0];
        this.currentFormUid = nextLeaseForm.uid;
      }
    }
  };

  syncMasterAndExamples = () => {
    const master = this.getMaster();
    this.leaseForms.forEach(form => {
      if (form.isExample) {
        form.inputs.rcf = master.inputs.rcf;
        form.inputs.standardRegionalRcf = master.inputs.standardRegionalRcf;
        form.inputs.adjustmentFactor = master.inputs.adjustmentFactor;
        form.inputs.tfsShare = master.inputs.tfsShare;
        form.fundingSourceSplits = master.fundingSourceSplits.slice();
        form.inputs.subventionCash = master.inputs.subventionCash;
        form.inputs.additionalCashOfferId = master.inputs.additionalCashOfferId;
        form.inputs.additionalCashOptionType = master.inputs.additionalCashOptionType;
        form.inputs.additionalCashAmount = master.inputs.additionalCashAmount;
        form.recalculate();
      }
    });
  };
}

export default LeaseTermModel;
