import { useMemo, useState } from 'react';
import CheckListItem from '../models/CheckListItem';

const useChecklist = (list: CheckListItem[]) => {
  const [checklist, setChecklist] = useState(list);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const selectAll = (selected: boolean) => {
    selectChildren(checklist, selected);
    setChecklist([...checklist]);
  };

  const selectItem = (item: CheckListItem, selected: boolean) => {
    item.selected = selected;
    if (item.items) {
      selectChildren(item.items, selected);
    }
    processSelection(checklist);
    setChecklist([...checklist]);
  };

  const selectChildren = (items: CheckListItem[], selected: boolean) => {
    items.forEach(item => {
      item.selected = selected;
      if (item.items) {
        selectChildren(item.items, selected);
      }
    });
  };

  const processSelection = (items: CheckListItem[]) => {
    items.forEach(item => {
      if (item.items) {
        item.selected = item.items.filter(item => !item.selected).length === 0;
        processSelection(item.items);
      }
    });
  };

  const hasSelection = useMemo(() => {
    setIsAllSelected(checklist.filter(item => !item.selected).length === 0);
    let selection = false;

    checklist.forEach(item => {
      if (item.selected) {
        selection = true;
      }
      item.items &&
        item.items.forEach(item => {
          if (item.selected) {
            selection = true;
          }
        });
    });

    return selection;
  }, [checklist]);

  return {
    checklist,
    setChecklist,
    selectItem,
    selectAll,
    isAllSelected,
    hasSelection,
  };
};

export default useChecklist;
