import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { OATIcon } from 'oat-common-ui';
import { ReactNode } from 'react';
import { Status } from '../../../constants/global';
import PenRateModel from '../../../pages/Summary/models/PenRateModel';
import RgnlAltModel from '../../../pages/Summary/models/RgnlAltModel';
import useStores from '../../../stores/useStores';
import { handleEnhancedDates } from '../../../utils/handleEnhancedDates';
import useGetTfsLabel from '../../../utils/useGetTfsLabel';
import SlideToggle from '../../SlideToggle';
import PenRateExclusions from '../PenRateExclusions';
import styles from '../styles.module.scss';

interface Props {
  isDisabled?: boolean;
  isModalView?: boolean;
  offerDetails: ReactNode;
  offerStyles?: string;
  onSave?: () => void;
  penRate: PenRateModel;
  rgnlAlt: RgnlAltModel;
}

const OfferDetailsCell = ({ rgnlAlt, penRate, offerStyles, offerDetails, onSave, isModalView, isDisabled = false }: Props) => {
  const tfsLabel = useGetTfsLabel();
  const {
    summaryStore: { offering },
    programDetailsStore,
  } = useStores();

  const handleOnToggle = (val: boolean) => {
    rgnlAlt.toggleFourthOption(penRate.id, val);
    if (onSave) {
      onSave();
    }
  };

  const isEnhancedDates = handleEnhancedDates(
    penRate.startDate,
    penRate.endDate,
    offering.startDate || programDetailsStore.offering.startDate,
    offering.endDate || programDetailsStore.offering.endDate,
    true,
  );

  return (
    <td className={clsx(styles.offer, offerStyles)}>
      <div className={styles.offerContainer}>
        <div>
          {penRate.isAdvertised && <OATIcon className={styles.icon} icon="active_offer" colorTheme="orange" />}
          <div>
            <label>{offerDetails}</label>
            {isEnhancedDates && <span className={styles.dates}>{isEnhancedDates}</span>}
            {Boolean(penRate.subCash) && (
              <span>
                {` + `}
                {!penRate.isAdvertised && penRate.isSubCashAdvertised && <OATIcon className={styles.subCashAdIcon} icon="active_offer" colorTheme="orange" />}
                {`${tfsLabel} ${penRate.optionTypeName} Subvention Cash`}
              </span>
            )}
          </div>
          <PenRateExclusions penRateModel={penRate} showAdTerms isModalView={isModalView} />
        </div>
        {penRate.showToggle && !isDisabled && (
          <div className={styles.fourthOption}>
            <SlideToggle disabled={offering.status === Status.MEETS_GUIDELINES} selected={penRate.isFourthOption} toggleSelected={() => handleOnToggle(!penRate.isFourthOption)} />
          </div>
        )}
      </div>
    </td>
  );
};

export default observer(OfferDetailsCell);
