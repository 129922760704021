import { SeriesCodeToNameMap } from 'oat-common-ui';
import { SeriesMapping, VehicleInput } from '../../../gql/generated';
import AccordionItemModel from '../models/AccordionItemModel';

const createAccordionFromVehicles = (
  vehicles: VehicleInput[],
  seriesMappingData: SeriesMapping[],
  isExclusions: boolean,
  disabledVehicles?: string[],
  modelCode?: string,
  isNational?: boolean,
  isEnR?: boolean,
) => {
  const years: AccordionItemModel[] = [];

  const getVehicleNameFromSeriesMapping = (series: string) => {
    return seriesMappingData?.find(seriesData => seriesData.oatSeries === series)?.displayName;
  };

  vehicles.forEach(v => {
    let yItem = years.find(y => y.value === v.seriesYear);
    if (!yItem) {
      yItem = new AccordionItemModel(v.seriesYear, v.seriesYear);
      years.push(yItem);
    }

    let serItem = yItem.items.find(s => s.value === v.series);
    if (!serItem) {
      const seriesName = getVehicleNameFromSeriesMapping(v.series);
      serItem = new AccordionItemModel(seriesName ?? SeriesCodeToNameMap[v.series], v.series);
      serItem.level = 2;
      yItem.items.push(serItem);
    }

    let trimItem = serItem.items.find(t => t.value === v.modelCode);
    if (!trimItem) {
      trimItem = new AccordionItemModel(`(${v.modelCode}) ${v.vehicleDescription}`, v.modelCode);
      trimItem.level = 3;
      trimItem.checked = isExclusions ? !v.isInclusion : v.isInclusion; // default mode exclusion
      trimItem.description = v.vehicleDescription;
      trimItem.disabled = modelCode && v.modelCode === modelCode ? true : disabledVehicles?.includes(v.modelCode) ?? false;
      serItem.items.push(trimItem);
    }

    serItem.items.sort((itemA, itemB) => (itemA.label > itemB.label ? 1 : -1));
    serItem.checked = serItem.items.some(item => item.checked);
    yItem.items.sort((itemA, itemB) => (itemA.label > itemB.label ? 1 : -1));
    yItem.checked = yItem.items.some(item => item.checked);
  });

  return years.sort((itemA, itemB) => (itemA.label > itemB.label ? -1 : 1));
};

export default createAccordionFromVehicles;
