import clsx from 'clsx';
import { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { assignNumberValue } from '../../utils/assignValue';
import { getConfig } from './configs';
import styles from './styles.module.scss';
import { formatValue } from './utils';

type DollarSignProps = {
  dollarSign?: boolean;
  error?: boolean;
};

const DollarSign = ({ dollarSign, error }: DollarSignProps) => {
  return dollarSign ? (
    <span id="dollarSign" className={clsx(styles.dollarSign, error && styles.error)}>
      $
    </span>
  ) : null;
};

type PercentSignProps = {
  percentageSign?: boolean;
  error?: boolean;
};

const PercentSign = ({ percentageSign, error }: PercentSignProps) => {
  return percentageSign ? (
    <span id="percentageSign" className={clsx(styles.percentageSign, error && styles.error)}>
      %
    </span>
  ) : null;
};

const getTheme = (dark: boolean, dollarSign: boolean, percentageSign: boolean, textAlign?: string) => {
  return clsx(dark ? styles.dark : styles.default, dollarSign && styles.leftPadding, percentageSign && styles.rightPadding, textAlign === 'center' && styles.centerText);
};

type TextAlignType = 'left' | 'right' | 'center' | undefined;

export interface NumberInputProps extends NumericFormatProps {
  id?: string;
  className?: string;
  dark?: boolean;
  dollarSign?: boolean;
  error?: boolean;
  textAlign?: TextAlignType;
  percentageSign?: boolean;
  selectOnFocus?: boolean;
  max?: number;

  // configs
  wholeNumber?: boolean;
  negativeNumber?: boolean;
  units?: boolean;
  rcf?: boolean;
  dollar?: boolean;
}

const NumberInput = ({
  id,
  className,
  error,
  selectOnFocus,
  value,
  onChange,
  onFocus,
  onBlur,
  readOnly,
  textAlign,
  dark = false,
  dollarSign = false,
  percentageSign = false,
  thousandSeparator = true,
  max,

  // configs
  wholeNumber = false,
  negativeNumber = false,
  units = false,
  rcf = false,
  dollar = false,

  ...rest
}: NumberInputProps) => {
  // config
  const baseTheme = getTheme(dark, dollarSign, percentageSign, textAlign);
  const config = getConfig(wholeNumber, negativeNumber, units, rcf, dollar);

  const [val, setVal] = useState(formatValue(value, rcf, dollar, !units));
  const [isFocused, setIsFocused] = useState(false);

  // useEffect to force rerender of component when different value is passed
  useEffect(() => {
    // condition to prevent unneccesseary re-rendering
    const formattedValue = formatValue(value, rcf, dollar, !units);
    if (!isFocused && (assignNumberValue(val) !== assignNumberValue(value) || val !== formattedValue)) {
      setVal(formattedValue);
    }
  }, [value, val, rcf, dollar, units, isFocused]);

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (selectOnFocus && e.target) {
      e.target.select();
    }

    setIsFocused(true);

    if (onFocus) {
      onFocus(e);
    }
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    setVal(formatValue(e.target.value, rcf, dollar, !units));

    setIsFocused(false);

    if (onBlur) {
      onBlur(e);
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVal(e.currentTarget.value);

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <span className={styles.numberInputWrapper}>
      <DollarSign dollarSign={dollarSign} error={error} />
      <NumericFormat
        id={id}
        className={clsx(styles.numberInput, className, baseTheme, error && styles.error)}
        value={val}
        onFocus={handleFocus}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        allowLeadingZeros
        isAllowed={values => {
          return max ? Number(values.value) <= max : true;
        }}
        {...config}
        {...rest}
      />
      <PercentSign percentageSign={percentageSign} error={error} />
    </span>
  );
};

export default NumberInput;
