import { useGetDealerGrossQuery } from '../../gql/generated';
import useStores from '../../stores/useStores';
import LoadingPage from '../LoadingPage';
import DealerGrossComponent from './DealerGrossComponent';

const DealerGross = () => {
  const { userInfoStore, settingsStore } = useStores();

  // to get region code for now
  const regionCode = userInfoStore.getRegionCode();

  const { data, loading, error } = useGetDealerGrossQuery({
    variables: { regionCode },
  });

  if (loading || error) {
    return <LoadingPage breadCrumbs={[{ name: userInfoStore.properties.abbrName }]} error={!!error} />;
  }

  if (data) {
    settingsStore.setDealerGross(data.dealerGrossByRegionCode?.dealerGross?.toString() || '0');
  }

  return <DealerGrossComponent />;
};

export default DealerGross;
