import { observer } from 'mobx-react-lite';
import { Button, ButtonGroup } from 'oat-common-ui';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import IpBreadCrumbs from '../../components/IpComponents/IpBreadCrumbs';
import MainFooter from '../../components/MainFooter';
import MainHeader from '../../components/MainHeader';
import { SCROLL_OFFSET_PROGRAM_DETAILS, Status } from '../../constants/global';
import useAnchorScrollTo from '../../hooks/useAnchorScrollTo';
import useCompatibileAccessories from '../../hooks/useCompatibileAccessories';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';
import AddOfferCtas from '../Summary/RegionalSummary/RgnlAltSection/AddOfferCtas';
import AprCard from './components/AprCard';
import AprDefaultTfsCostShare from './components/AprCard/components/AprTier/components/AprTable/components/AprDefaultTfsCostShare';
import CashCard from './components/CashCard';
import CreateAprModal from './components/CreateAprModal';
import FetchTfsCostShareAvailabilities from './components/FetchTfsCostShareAvailabilities';
import LeaseCard from './components/LeaseCard';
import MiscCard from './components/MiscCard';
import MiscDefaultTfsCostShare from './components/MiscCard/components/MiscDefaultTfsCostShare';
import NationalNote from './components/NationalNote';
import ProgramDetailsCosts from './components/ProgramDetailsCosts';
import ProgramDetailsWrapper from './components/ProgramDetailsWrapper';
import SeriesBalanceSubHeader from './components/SeriesBalanceSubHeader';
import styles from './styles.module.scss';

const ProgramDetailsComponent = () => {
  const {
    programDetailsStore: { offering, offerCards, seriesProfile },
    userInfoStore: { isNational },
  } = useStores();

  const { region, regalt, scrollTo, period } = useUrlParams();
  const history = useHistory();

  const { compatibileAccessories } = useCompatibileAccessories(seriesProfile.series[0].series, seriesProfile.series[0].seriesYear);

  const [showTfsEnhanced, setShowTfsEnhanced] = useState(false);
  const [addOffer, setAddOffer] = useState(false);
  const [aprModal, setAprModal] = useState(false);

  useAnchorScrollTo(scrollTo, SCROLL_OFFSET_PROGRAM_DETAILS);

  const handleCompleteSeries = () => {
    const anchor = seriesProfile.id;
    history.push(`/summary/region/${region}/period/${period}/?scrollTo=${anchor}`);
  };

  const handleSetShowTfsEnhanced = () => {
    setShowTfsEnhanced(!showTfsEnhanced);
  };
  const hasAprErrors = offerCards.apr.some(apr => apr.hasError);
  const isDisabled = Boolean(isNational() && offering.isAtc);
  const hasMiscErrors = offerCards.misc.some(misc => misc.hasConquestCompetitorDetailsError || misc.hasTradeInAssitanceModelRestrictionsError || misc.hasCampaignError);

  const isCompleteSeriesDisabled = isDisabled || hasAprErrors || hasMiscErrors;
  return (
    <>
      <MainHeader
        headerTitle={seriesProfile.name}
        breadCrumbs={IpBreadCrumbs(isNational(), offering, region, seriesProfile)}
        showRightContent
        rightContent={<ProgramDetailsCosts showTfsEnhanced={showTfsEnhanced} setShowTfsEnhanced={handleSetShowTfsEnhanced} />}
      />
      <SeriesBalanceSubHeader showTfsEnhanced={showTfsEnhanced} setShowTfsEnhanced={handleSetShowTfsEnhanced} />

      {offering.status === Status.NEEDS_REVISIONS && <NationalNote offering={offering} />}

      <div className={styles.offerList}>
        {offerCards.cash.map(cash => (
          <section key={cash.uid}>
            <CashCard cashCard={cash} compatibileAccessories={compatibileAccessories} />
          </section>
        ))}
        {offerCards.apr.map(apr => (
          <section key={apr.uid}>
            <AprDefaultTfsCostShare aprCard={apr}>
              <AprCard aprCard={apr} compatibileAccessories={compatibileAccessories} />
            </AprDefaultTfsCostShare>
          </section>
        ))}
        {offerCards.lease.map(lease => (
          <section key={lease.uid}>
            <FetchTfsCostShareAvailabilities>
              <LeaseCard leaseCard={lease} />
            </FetchTfsCostShareAvailabilities>
          </section>
        ))}
        {offerCards.misc.map(misc => (
          <section key={misc.uid}>
            <MiscDefaultTfsCostShare miscCard={misc}>
              <MiscCard card={misc} compatibileAccessories={compatibileAccessories} />
            </MiscDefaultTfsCostShare>
          </section>
        ))}
        <section>
          <ProgramDetailsWrapper>
            <div className={styles.footerCta}>
              {!addOffer && (
                <ButtonGroup>
                  <Button id="add-offer-btn" variant="primary" onClick={() => setAddOffer(true)} disabled={offering.status === Status.MEETS_GUIDELINES || isDisabled}>
                    Add Offer
                  </Button>
                  <Button disabled={isCompleteSeriesDisabled} id="complete-series-btn" variant="primary" onClick={() => handleCompleteSeries()}>
                    Complete Series
                  </Button>
                </ButtonGroup>
              )}
              {addOffer && (
                <AddOfferCtas
                  isMultiSeries={seriesProfile.series.length > 1}
                  onCancel={() => setAddOffer(false)}
                  openAprModal={() => setAprModal(true)}
                  profileId={seriesProfile.id}
                  rgnlAltId={regalt}
                />
              )}
            </div>
          </ProgramDetailsWrapper>
        </section>
      </div>
      <MainFooter />
      {aprModal && <CreateAprModal onClose={() => setAprModal(false)} />}
    </>
  );
};

export default observer(ProgramDetailsComponent);
