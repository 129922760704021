import { observer } from 'mobx-react-lite';
import { Checkbox } from 'oat-common-ui';
import styles from '../styles.module.scss';

interface ReconcileHeaderProps {
  isEnhancementsOnly: boolean;
  toggleEnhanced: (enhanced: boolean) => void;
}

const ReconcileHeader = ({ isEnhancementsOnly, toggleEnhanced }: ReconcileHeaderProps) => {
  return (
    <div className={styles.enhanced}>
      <Checkbox isChecked={isEnhancementsOnly} onChange={e => toggleEnhanced(e.target.checked)} label="only show enhanced" />
    </div>
  );
};
export default observer(ReconcileHeader);
