import { OATIcon } from 'oat-common-ui';
import { Status } from '../../constants/global';
import styles from './styles.module.scss';

interface Props {
  status: string;
}

const StatusCell = ({ status }: Props) => {
  const statusStyle = status === Status.MEETS_GUIDELINES ? styles.meetsGuidelinesText : status === Status.NEEDS_REVISIONS ? styles.needsRevisionsText : '';

  return (
    <td>
      {status === 'All Guidelines Met' && <OATIcon icon="check-mark" colorTheme="green" className={styles.marginRight} />}
      <span className={statusStyle}>{status}</span>
    </td>
  );
};

export default StatusCell;
