import { AdminConstants } from 'oat-admin-common';
import { formatDollars } from 'oat-common-ui';
import AccordionModel from '../../../../../components/Accordion/models/AccordionModel';
import recursivePartialSelection from '../../../../../components/Accordion/utils/recursivePartialSelection';

export const calculateResidual = (val: number) => {
  return `${Math.round(val * 100)}%`;
};

export const formatOptionsTotal = (val: number) => {
  return formatDollars(val.toString(), true);
};

export const formatInventory = (val: number) => {
  return `${Math.round(Number(val) * 1000) / 10}%`;
};

export const formatDollar = (val: string) => {
  if (!val) {
    return '0';
  }

  return Math.round(Number(val)).toString();
};

export const mapToPortsMap = (port: string) => {
  if (!AdminConstants.PortsMap[port]) {
    return 'No ports available.';
  }

  return AdminConstants.PortsMap[port];
};

export const returnNoVehiclesError = (errorName: string, trimTitle: string) => {
  return {
    name: errorName,
    message: `There are currently no vehicles in inventory for ${trimTitle}. Please select a different trim or make the offer Non-Advertised.`,
  };
};

export const processVehiclesAccordion = (vehiclesAccordion: AccordionModel, modelCode: string, isInclusions: boolean, isNational: boolean, isEnR: boolean) => {
  recursivePartialSelection(vehiclesAccordion.items);
  for (const itemLvlOne of vehiclesAccordion.items) {
    itemLvlOne.expand = false;

    for (const itemLvlTwo of itemLvlOne.items) {
      for (const itemLvlThree of itemLvlTwo.items) {
        if (itemLvlThree.value === modelCode) {
          itemLvlThree.disabled = true;
          itemLvlThree.checked = isInclusions;
        }
        if (isNational && !isEnR) {
          itemLvlThree.disabled = true;
        }
      }
    }
  }

  return vehiclesAccordion;
};
