import { AdminConstants } from 'oat-admin-common';
import { assignBooleanValue, assignNumberValue, useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { FEATURE_OR_2477, FEATURE_OR_4154 } from '../../../../../constants/global';
import {
  LeaseDetails,
  LeaseOfferDetails,
  Offer,
  OfferingCosts,
  useAddLeaseTermMutation,
  useHideLeaseTermMutation,
  useSaveCashOfferMutation,
  useSaveCompatibilityMutation,
  useSaveLeaseOfferMutation,
  useSaveMiscOfferMutation,
  useSaveVehiclesMutation,
} from '../../../../../gql/generated';
import useOfferEligibility from '../../../../../hooks/useOfferEligibility';
import useUrlParams from '../../../../../hooks/useUrlParams';
import useStores from '../../../../../stores/useStores';
import useUserInfo from '../../../../../utils/useUserInfo';
import { getMiscOfferInputByOptionTypeName } from '../../../../createOffer/CreateMisc/getMiscOfferInputByOptionTypeName';
import LeaseCardModel from '../../../models/LeaseCardModel';
import LeaseFormModel from '../../../models/LeaseFormModel';
import LeaseTermModel from '../../../models/LeaseTermModel';
import { getCashPayload } from '../../CashCard/utils';
import leaseErrorUtils from './leaseErrorUtils';
import transformLeaseDetails, { transformLeaseCard } from './transformLeaseDetails';

const useSaveLeaseData = (leaseCard: LeaseCardModel, leaseTerm: LeaseTermModel, leaseForm: LeaseFormModel) => {
  const {
    offeringCostsStore,
    programDetailsStore,
    rcfsResidualsStore,
    seriesMappingStore: { seriesMapping },
    regionalCostShareStore,
  } = useStores();
  const { setDelay } = useInputDelay();
  const [saveLeaseOffer] = useSaveLeaseOfferMutation();
  const [saveCashOffer] = useSaveCashOfferMutation();
  const [saveMiscOffer] = useSaveMiscOfferMutation();
  const [addLeaseTerm] = useAddLeaseTermMutation();
  const [hideLeaseTerm] = useHideLeaseTermMutation();
  const [saveVehicles] = useSaveVehiclesMutation();
  const [saveCompatibility] = useSaveCompatibilityMutation();
  const { error } = useToast();

  const { region } = useUrlParams();
  const { isGSTUser, isSETUser } = useUserInfo();

  const { handleEnhCostShare, handleEnhTfsCostShare } = useOfferEligibility();

  const handleInputChange = (skipDelay = false, leaseDetails?: LeaseOfferDetails[], isVehicleChange?: boolean, checkEligibility?: boolean) => {
    setDelay(
      async () => {
        await saveLease(leaseDetails ?? [transformLeaseDetails(leaseTerm, leaseForm)], isVehicleChange, checkEligibility);
      },
      skipDelay ? 0 : undefined,
    );
  };

  const handleAdditionalCashChange = () => {
    setDelay(async () => {
      const hasError = leaseForm.hasError();

      // Throw math error message for non-gst advertised lease
      if (!leaseForm.isGST && leaseForm.inputs.isAdvertised && hasError) {
        error(leaseErrorUtils.LEASE_MATH_ERROR);
        leaseCard.resetEnhancedCostShareFields();
      }

      if (!hasError) {
        try {
          const { offerCards, offering, seriesProfile, updateAdditionalCashForLease } = programDetailsStore;
          const foundCashCard = [...offerCards.cash].find(item => item.id === leaseForm.inputs.additionalCashOfferId);
          const foundMiscCard = [...offerCards.misc].find(item => item.id === leaseForm.inputs.additionalCashOfferId);

          // save cash card
          if (foundCashCard && !foundCashCard.hasError) {
            const payload = getCashPayload(foundCashCard);
            const res = await trackPromise(saveCashOffer({ variables: { input: payload } }));

            if (res.data?.saveCashOffer.offer) {
              foundCashCard.setCardData(res.data.saveCashOffer.offer, seriesMapping, seriesProfile, offering);
            }

            if (res.data?.saveCashOffer.offers) {
              updateAdditionalCashForLease(res.data.saveCashOffer.offers);
            }

            offeringCostsStore.setData(res.data?.saveCashOffer.offeringCosts as OfferingCosts);
          }

          // save misc card
          if (foundMiscCard && foundMiscCard.canSave()) {
            const { miscProps } = getMiscOfferInputByOptionTypeName(foundMiscCard);
            const res = await trackPromise(saveMiscOffer({ variables: { input: { ...miscProps, id: foundMiscCard.id, rev: foundMiscCard.rev } } }));

            if (res.data?.saveMiscOffer.offer) {
              foundMiscCard.setCardData(res.data?.saveMiscOffer.offer, seriesMapping, seriesProfile, offering);
            }

            if (res.data?.saveMiscOffer.offers) {
              updateAdditionalCashForLease(res.data.saveMiscOffer.offers);
            }
            offeringCostsStore.setData(res.data?.saveMiscOffer.offeringCosts as OfferingCosts);
          }
        } catch (e) {
          error((e as Error).message);
        }
      }
    });
  };

  const recalculation = () => {
    if (leaseForm.inputs.isAdvertised && !isGSTUser()) {
      leaseForm.recalculate(undefined, 'tfsShare', region, programDetailsStore.offering);
      leaseTerm.syncMasterAndExamples();
    } else {
      leaseForm.calculateNonAdGstEstCosts(region, !!programDetailsStore.offering.useOldCostShareForLexus);
    }

    leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
  };

  const handleEnhCostData = () => {
    // Apply enh cost share is confirmed
    if (leaseCard.enhancedCostFields.offerId && (leaseCard.enhancedCostFields.isModalConfirmed || leaseCard.enhancedCostFields.isNotificationConfirmed)) {
      leaseCard.setEnhancedCostShare(programDetailsStore.getEnhancedCostShareById(leaseCard.enhancedCostFields.offerId));
      leaseCard.applyEnhCostShare(!!programDetailsStore.offering.useOldCostShareForLexus);
    }

    // Remove enh cost share is confirmed
    if (leaseCard.enhancedCostFields.isModalConfirmed && leaseCard.enhancedCostFields.removingReason) {
      leaseCard.removeEnhCostShare(!!programDetailsStore.offering.useOldCostShareForLexus);
    }

    // Apply/remove cost share is canceled
    if (leaseCard.enhancedCostFields.isModalConfirmed === false && (leaseCard.enhancedCostFields.offerId || leaseCard.enhancedCostFields.removingReason)) {
      leaseCard.cancelEnhCostShareAction();
    }

    recalculation();
    leaseCard.setUpToValues(
      leaseForm,
      programDetailsStore.ryoEarnings,
      rcfsResidualsStore.getStdRcfForCreateLease(
        assignNumberValue(programDetailsStore.seriesProfile.ncsRcf),
        programDetailsStore.seriesProfile.regionCode === AdminConstants.RegionCodes.SET,
      ),
    );
  };

  const handleEnhTfsCostData = () => {
    // Apply enh tfs cost share is confirmed
    if (leaseCard.enhancedTfsCostFields.offerId && (leaseCard.enhancedTfsCostFields.isModalConfirmed || leaseCard.enhancedTfsCostFields.isNotificationConfirmed)) {
      leaseCard.setEnhancedTfsCostShare(regionalCostShareStore.getEnhancedTfsCostShareById(programDetailsStore.seriesProfile.id, 'lease', leaseCard.enhancedTfsCostFields.offerId));
      leaseCard.applyEnhTfsCostShare();
    }

    // Remove enh tfs cost share is confirmed
    if (leaseCard.enhancedTfsCostFields.isModalConfirmed && leaseCard.enhancedTfsCostFields.removingReason) {
      leaseCard.removeEnhTfsCostShare();
    }

    // Apply/remove tfs cost share is canceled
    if (leaseCard.enhancedTfsCostFields.isModalConfirmed === false && (leaseCard.enhancedTfsCostFields.offerId || leaseCard.enhancedTfsCostFields.removingReason)) {
      leaseCard.cancelEnhTfsostShareAction();
    }

    recalculation();
    leaseCard.setUpToValues(
      leaseForm,
      programDetailsStore.ryoEarnings,
      rcfsResidualsStore.getStdRcfForCreateLease(
        assignNumberValue(programDetailsStore.seriesProfile.ncsRcf),
        programDetailsStore.seriesProfile.regionCode === AdminConstants.RegionCodes.SET,
      ),
    );
  };

  // Checking offer eligibility and stopping the API execution if modal is on
  const handleOfferEligibility = (): boolean | undefined => {
    const { showModal, isRemoving, reason, eligibleOfferId, isReplacing } = handleEnhCostShare(
      {
        startDate: leaseForm.inputs.startDate,
        endDate: leaseForm.inputs.endDate,
        vehicles: leaseCard.includedVehicles,
        optionTypeName: AdminConstants.OPTION_TYPE_NAMES.LEASE,
        regionCode: region,
        states: leaseCard.includedStates,
        tiers: leaseForm.inputs.rates.map(rate => rate.tier),
        terms: leaseCard.leaseTerms.map(term => term.term),
      },
      leaseCard,
    );

    if (showModal && leaseCard.enhancedCostFields.isModalConfirmed === undefined && (leaseCard.isEnhCostShareAccepted === undefined || isReplacing)) {
      leaseCard.updateEnhancedCostShareField('offerId', eligibleOfferId);
      leaseCard.updateEnhancedCostShareField('showModal', true);
      leaseCard.updateEnhancedCostShareField('isReplacing', Boolean(isReplacing));
      return true;
    }

    if (showModal && isRemoving && leaseCard.enhancedCostFields.isModalConfirmed === undefined) {
      leaseCard.updateEnhancedCostShareField('removingReason', reason);
      return true;
    }

    return false;
  };

  const handleOfferTfsCostEligibility = () => {
    const { showModal, eligibleOfferId, isRemoving, isReplacing, reason } = handleEnhTfsCostShare(
      {
        startDate: leaseForm.inputs.startDate,
        endDate: leaseForm.inputs.endDate,
        optionTypeName: AdminConstants.OPTION_TYPE_NAMES.LEASE,
        seriesProfileId: programDetailsStore.seriesProfile.id,
      },
      leaseCard,
    );

    if (showModal && leaseCard.enhancedTfsCostFields.isModalConfirmed === undefined && (leaseCard.isEnhTfsCostShareAccepted === undefined || isReplacing)) {
      leaseCard.updateEnhancedTfsCostShareField('offerId', eligibleOfferId);
      leaseCard.updateEnhancedTfsCostShareField('showModal', true);
      leaseCard.updateEnhancedTfsCostShareField('isReplacing', Boolean(isReplacing));
      return true;
    }

    if (showModal && isRemoving && leaseCard.enhancedTfsCostFields.isModalConfirmed === undefined) {
      leaseCard.updateEnhancedTfsCostShareField('removingReason', reason);
      return true;
    }

    return false;
  };

  // For sonarqube, separate this save fn to reduce complexity
  // Use handleInputChange instead of this function to save
  const saveLease = async (leaseDetails: LeaseOfferDetails[], isVehicleChange = false, checkEligibility = true) => {
    const hasError = leaseForm.hasError();

    // Throw math error message for non-gst advertised lease
    if (!leaseForm.isGST && leaseForm.inputs.isAdvertised && hasError) {
      error(leaseErrorUtils.LEASE_MATH_ERROR);
      leaseCard.resetEnhancedCostShareFields();
    }
    if (!hasError) {
      if (FEATURE_OR_2477 && checkEligibility) {
        if (handleOfferEligibility()) {
          return;
        }

        handleEnhCostData();
      }

      if (FEATURE_OR_4154 && checkEligibility && leaseForm.inputs.isStandalone && !isSETUser()) {
        if (handleOfferTfsCostEligibility()) {
          return;
        }

        handleEnhTfsCostData();
      }

      try {
        const {
          isEnhCostShareAccepted,
          isEligibleForEnhCostShare,
          enhCostShareOfferId,
          isEnhCostShareUpdated,
          isEnhTfsCostShareAccepted,
          isEligibleForEnhTfsCostShare,
          enhTfsCostShareId,
          isEnhTfsCostShareUpdated,
        } = leaseCard;

        const res = await trackPromise(
          saveLeaseOffer({
            variables: {
              input: {
                id: leaseCard.id,
                rev: leaseCard.rev,
                name: leaseCard.name,
                accessoryCodes: leaseCard.accessoryCodes,
                specialEditionPackage: leaseCard.specialEditionPackage,
                isSpecialEdition: leaseCard.isSpecialEdition,
                leaseDetails: transformLeaseCard(leaseCard, leaseDetails), // leaseCard.getLeaseOfferDetails(),
                contestNumber: leaseCard.contestNumber,
                optionTierType: leaseCard.optionTierType,
                isEnhCostShareAccepted: isEligibleForEnhCostShare ? true : isEnhCostShareAccepted,
                isEligibleForEnhCostShare,
                enhCostShareOfferId,
                isEnhCostShareUpdated,
                isEnhTfsCostShareAccepted: isEligibleForEnhTfsCostShare ? true : isEnhTfsCostShareAccepted,
                isEligibleForEnhTfsCostShare,
                enhTfsCostShareId,
                isEnhTfsCostShareUpdated,
              },
            },
          }),
        );

        leaseForm.updateInitialData();

        const savedLeaseOffer = res.data?.saveLeaseOffer?.offer;
        const savedLeaseDetails = savedLeaseOffer?.leaseDetails;

        leaseCard.updateRev(savedLeaseOffer?.rev);
        leaseCard.updateTierRates(savedLeaseOffer);
        leaseCard.setEnhanceCostShareValues(savedLeaseOffer);

        const foundDetail = savedLeaseDetails?.find(detail => detail.highTerm === leaseForm.inputs.highTerm);

        if (foundDetail) {
          leaseForm.updateInput('tfsShare', foundDetail.tfsShare);
          leaseForm.updateInput('subCashTfsShare', Number(foundDetail.subCashTfsShare));
          leaseForm.updateInput('subCashTfsCostShareCap', Number(foundDetail.subCashTfsCostShareCap));
          leaseForm.updateIsSetSubRcfApplied(assignBooleanValue(foundDetail.isSetSubRcfApplied));
        }

        if (savedLeaseOffer?.enhCostShareOfferId) {
          leaseCard.setEnhancedCostShare(programDetailsStore.getEnhancedCostShareById(savedLeaseOffer.enhCostShareOfferId));
        }

        offeringCostsStore.setData(res.data?.saveLeaseOffer.offeringCosts as OfferingCosts);

        if (leaseCard.enhancedCostFields.isSaveVehicles || isVehicleChange) {
          await handleSaveVehicles();
        }

        leaseCard.resetEnhancedCostShareFields();
      } catch (e) {
        error((e as Error).message);
        leaseCard.resetEnhancedCostShareFields();
      }
    }
  };

  // for remove notification
  const handleRemoveEnhCostShareNotification = async (leaseDetails?: LeaseOfferDetails[], isCancel = false) => {
    let enhCostSharePayload = {
      isEnhCostShareAccepted: leaseCard.enhCostShareOfferId ? true : false,
      isEnhCostShareUpdated: leaseCard.isEnhCostShareUpdated,
      isEnhCostShareRemoved: false,
    };

    if (isCancel) {
      enhCostSharePayload = {
        isEnhCostShareAccepted: false,
        isEnhCostShareUpdated: false,
        isEnhCostShareRemoved: false,
      };
    }

    try {
      const res = await trackPromise(
        saveLeaseOffer({
          variables: {
            input: {
              accessoryCodes: leaseCard.accessoryCodes,
              contestNumber: leaseCard.contestNumber,
              id: leaseCard.id,
              enhCostShareOfferId: leaseCard.enhCostShareOfferId,
              isEligibleForEnhCostShare: leaseCard.isEligibleForEnhCostShare,
              ...enhCostSharePayload,
              isSpecialEdition: leaseCard.isSpecialEdition,
              leaseDetails: leaseDetails ?? [transformLeaseDetails(leaseTerm, leaseForm)],
              name: leaseCard.name,
              optionTierType: leaseCard.optionTierType,
              rev: leaseCard.rev,
              specialEditionPackage: leaseCard.specialEditionPackage,
            },
          },
        }),
      );

      leaseCard.updateRev(res.data?.saveLeaseOffer?.offer?.rev);
      leaseCard.setEnhanceCostShareValues(res.data?.saveLeaseOffer.offer);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const isOddTermAllowedForLease = (oddTerm: number) => {
    if (!leaseCard.isDuplicate) {
      return true;
    }
    const parentRegionalLease = programDetailsStore.offerCards.lease?.find(lease => lease.id === leaseCard.parentId);
    const allowedTerms = parentRegionalLease?.leaseTerms.map(detail => detail.term) ?? [];

    return allowedTerms.includes(oddTerm);
  };

  const handleAddLeaseTerm = async (oddTerm: number) => {
    if (leaseForm.hasError()) {
      return;
    }
    try {
      if (!isOddTermAllowedForLease(oddTerm)) {
        error(leaseErrorUtils.ODD_TERMS_AVAILABILITY_ERROR);
        return;
      }
      const res = await trackPromise(addLeaseTerm({ variables: { input: { offerId: leaseCard.id, rev: leaseCard.rev, oddTerm } } }));
      if (res.data && res.data.addLeaseTerm.offer) {
        const oddTermDetail = res.data.addLeaseTerm.offer.leaseDetails?.filter(detail => detail.highTerm === oddTerm)[0];
        leaseCard.updateRev(res.data?.addLeaseTerm?.offer?.rev);
        leaseCard.addOddTermLease(res.data.addLeaseTerm.offer as Offer, oddTermDetail as LeaseDetails);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleHideLeaseTerm = async (selectedHideTerm: number, onSuccessFn: () => void) => {
    if (leaseForm.hasError()) {
      return;
    }
    try {
      const res = await trackPromise(hideLeaseTerm({ variables: { input: { offerId: leaseCard.id, rev: leaseCard.rev, oddTerm: selectedHideTerm } } }));
      if (res.data && res.data.hideLeaseTerm.offer) {
        leaseCard.updateRev(res.data?.hideLeaseTerm?.offer?.rev);
        leaseCard.hideOddTermLease(selectedHideTerm);
        offeringCostsStore.setData(res.data?.hideLeaseTerm.offeringCosts as OfferingCosts);
        onSuccessFn();
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleSaveVehicles = async () => {
    leaseCard.checkVehicleDescription();

    if (leaseForm.hasError()) {
      return;
    }
    try {
      const res = await trackPromise(
        saveVehicles({ variables: { input: { id: leaseCard.id, rev: leaseCard.rev, vehicles: [...leaseCard.excludedVehicles, ...leaseCard.includedVehicles] } } }),
      );

      leaseCard.updateRev(res.data?.saveVehicles.offer?.rev);
      leaseForm.updateInitialData([...leaseCard.excludedVehicles, ...leaseCard.includedVehicles]);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleSaveCompatibility = async () => {
    if (leaseForm.hasError()) {
      return;
    }
    try {
      const res = await trackPromise(saveCompatibility({ variables: { input: { id: leaseCard.id, rev: leaseCard.rev, compatibilityList: leaseCard.compatabilityList } } }));
      leaseCard.updateRev(res.data?.saveCompatibility.offer?.rev);
    } catch (e) {
      error((e as Error).message);
    }
  };

  // Callback for RCF, Subcash, and tfs share on change
  // The input that trigger this would be updated already
  // This should be the callback right after that
  const handleUptoChangeOrSave = (checkEligibility = false) => {
    setDelay(() => {
      try {
        const payload = leaseCard.setUpToValues(
          leaseForm,
          programDetailsStore.ryoEarnings,
          rcfsResidualsStore.getStdRcfForCreateLease(
            assignNumberValue(programDetailsStore.seriesProfile.ncsRcf),
            programDetailsStore.seriesProfile.regionCode === AdminConstants.RegionCodes.SET,
          ),
          programDetailsStore.offering,
        );
        handleInputChange(false, payload, false, checkEligibility);
      } catch (e) {
        error((e as Error).message);
      }
    });
  };

  return {
    handleInputChange,
    handleAddLeaseTerm,
    handleAdditionalCashChange,
    handleHideLeaseTerm,
    handleSaveCompatibility,
    handleEnhCostData,
    handleEnhTfsCostData,
    handleUptoChangeOrSave,
    handleOfferEligibility,
    handleRemoveEnhCostShareNotification,
  };
};

export default useSaveLeaseData;
