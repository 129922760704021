import { CombinedOffers } from '../gql/generated';

const getSortedCombinedOffers = (offers: CombinedOffers[]) => {
  const sortedOffers = offers?.slice() || [];

  // sort by created date
  sortedOffers.sort((a, b) => {
    return new Date(a.regional.created).getTime() - new Date(b.regional.created).getTime();
  });

  // sort by national (which has precedence over standalone)
  sortedOffers.sort((a, b) => {
    if (!a.national && Boolean(b.national)) {
      return 1;
    }

    if (Boolean(a.national) && !b.national) {
      return -1;
    }

    return 0;
  });

  // process EnR, group by id, then parentId
  const map = new Map<string, CombinedOffers[]>();

  const mapHelperFn = (offerId: string, offer: CombinedOffers) => {
    if (!map.has(offerId)) {
      map.set(offerId, []);
    }

    const mapItem = map.get(offerId);
    if (mapItem) {
      map.set(offerId, [...mapItem, offer]);
    }
  };

  // group main offers
  sortedOffers
    .filter(offer => !offer.regional.parentId)
    .forEach(offer => {
      mapHelperFn(offer.regional.id, offer);
    });

  // group EnR offers (has parentId)
  sortedOffers
    .filter(offer => Boolean(offer.regional.parentId))
    .forEach(offer => {
      if (offer.regional.parentId) {
        mapHelperFn(offer.regional.parentId, offer);
      }
    });

  // spread map into one array
  let retArray: CombinedOffers[] = [];
  Array.from(map.values()).forEach(offers => {
    retArray = [...retArray, ...offers];
  });

  return retArray;
};

export default getSortedCombinedOffers;
