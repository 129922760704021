import { makeAutoObservable } from 'mobx';
import { AdminConstants, getAdjustedResidualRate, getDefaultTfsShare, LeaseCalcFns, LeaseCalcValues, SubCashCalcFns } from 'oat-admin-common';
import { assignStringValue, DD365VinDetailsResponse, EfcApi, EFCVinResponse, uuidv4 } from 'oat-common-ui';
import { FEATURE_OR_5646 } from '../../../constants/global';
import { FundingSourceSplits, LeaseDetails, Offer, Offering, VehicleInput } from '../../../gql/generated';
import { EfcSeries } from '../../../models/EfcSeriesModels';
import { assignBooleanValue, assignDollarCents, assignNumberValue } from '../../../utils/assignValue';
import { convertPercentage } from '../../../utils/convertPercentage';
import dateValidator from '../../../utils/dateValidator';
import validator from '../../../utils/validator';
import { getAccessoriesFromDD365VinDetail } from '../../createOffer/CreateLease/CreateAdLease/utils/getConfigListFromDD365';
import { formatInvoiceDate } from '../components/LeaseCard/components/VehicleInfo/utils';
import CashCardModel from './CashCardModel';
import LeaseFormInputs, { setLeaseFormInputs } from './LeaseFormInputs';
import MiscCardModel from './MiscCardModel';
import ResidualValue from './ResidualValue';

type LockType = 'rcf' | 'targetPayment' | 'dealerGross' | 'subventionCash' | 'dueAtSigning';

const { DEFAULT_DOWN_PAYMENT, OPTION_TYPE_NAMES, DEFAULT_MILEAGE_TOYOTA, DEFAULT_MILEAGE_LEXUS } = AdminConstants;
class LeaseFormModel {
  uid = uuidv4();
  isGST = false;
  isSET = false;
  isLexus = false;
  isValidEnR = true;
  isSetSubRcfApplied = false;
  showEnhTfsRcf = false;
  lock: LockType = 'rcf';
  invoiceDate = '';
  dealerCode = '';
  originalDealerCode = '';
  carJelly = '';
  seriesName = '';
  withCalcs = false;
  showUpTo = false;
  vinOptions: string[] = [];
  isExample = false;
  isVinLoaded = false;

  inputs = new LeaseFormInputs();

  initialData: { startDate: string; endDate: string; vehicles: VehicleInput[] } = {
    startDate: '',
    endDate: '',
    vehicles: [],
  };

  fundingSourceSplits: FundingSourceSplits[] = [];
  offerEarnings = 0;
  offerCost = 0;
  offerBalance = 0;
  offerTfsCost = 0;
  offerEnhTfsCost = 0;

  subCashOfferCost = 0;
  subCashOfferTfsCost = 0;
  subCashOfferEnhTfsCost = 0;

  isEnhRcfEligible = false;
  areTfsEnhRatesUsed = false;
  totalOfferCost = 0;
  nationalDetails: LeaseDetails | undefined;
  defaultMileage = DEFAULT_MILEAGE_TOYOTA;

  constructor() {
    makeAutoObservable(this);
  }

  setDetails = (regionalOffer: Offer, regionalDetails: LeaseDetails, isLexus: boolean, nationalDetails?: LeaseDetails | null, residuals?: ResidualValue[]) => {
    if (nationalDetails) {
      this.inputs.setNationalDetails(nationalDetails, regionalOffer.optionTierType === 'ByTier', isLexus ? DEFAULT_MILEAGE_LEXUS : DEFAULT_MILEAGE_TOYOTA);
      this.nationalDetails = nationalDetails;
    }
    this.fundingSourceSplits = regionalDetails.fundingSourceSplits || [];
    this.defaultMileage = isLexus ? DEFAULT_MILEAGE_LEXUS : DEFAULT_MILEAGE_TOYOTA;
    setLeaseFormInputs(this, regionalOffer, regionalDetails, residuals);
    this.isValidEnR = assignBooleanValue(regionalDetails.isValidEnR, true);
    this.offerEarnings = assignNumberValue(regionalDetails.offerEarnings, 0);
    this.offerCost = assignNumberValue(regionalDetails.offerCost, 0);
    this.offerTfsCost = assignNumberValue(regionalDetails.offerTfsCost);
    this.offerEnhTfsCost = assignNumberValue(regionalDetails.offerEnhTfsCost);
    this.subCashOfferCost = assignNumberValue(regionalDetails.subCashOfferCost);
    this.subCashOfferTfsCost = assignNumberValue(regionalDetails.subCashOfferTfsCost);
    this.subCashOfferEnhTfsCost = assignNumberValue(regionalDetails.subCashOfferEnhTfsCost);
    this.isEnhRcfEligible = assignBooleanValue(regionalDetails.isEnhRcfEligible, false);
    this.areTfsEnhRatesUsed = assignBooleanValue(regionalDetails.areTfsEnhRatesUsed, false);
    this.isSetSubRcfApplied = assignBooleanValue(regionalDetails.isSetSubRcfApplied, false);
    if (regionalDetails.isEnhRcfEligible && regionalDetails.areTfsEnhRatesUsed) {
      this.setLock('targetPayment');
    }

    this.calcOfferTotalCostAndBalance();
    this.calculateDefaultPayments();

    // initialize capitalized flags for non gst advertised leases
    if (this.inputs.isAdvertised && !this.isGST) {
      this.recalculate(true);
    }

    this.initialData = {
      startDate: regionalDetails.startDate,
      endDate: regionalDetails.endDate,
      vehicles: regionalOffer.vehicles,
    };
  };

  updateInitialData = (vehicles?: VehicleInput[]) => {
    this.initialData = {
      startDate: this.inputs.startDate,
      endDate: this.inputs.endDate,
      vehicles: vehicles || this.inputs.vehicles,
    };
  };

  setSeriesName = (seriesName: string) => {
    this.seriesName = seriesName;
  };

  calcOfferTotalCostAndBalance = () => {
    this.totalOfferCost = assignDollarCents(this.offerCost + this.subCashOfferCost);
    this.offerBalance = assignDollarCents(this.offerEarnings - this.totalOfferCost);
  };

  updateOfferCosts = (earnings: number) => {
    this.offerEarnings = earnings * this.inputs.forecastedSales;
    this.offerCost = assignDollarCents(assignNumberValue(this.inputs.estimatedCost) * this.inputs.forecastedSales);

    // add sub cash parts later
    this.offerTfsCost = assignDollarCents(this.inputs.tfsEstCost * this.inputs.forecastedSales);
    this.offerEnhTfsCost = assignDollarCents(this.inputs.enhTfsEstCost * this.inputs.forecastedSales);

    this.subCashOfferCost = assignDollarCents(this.inputs.subCashEstCost * this.inputs.forecastedSales);
    this.subCashOfferTfsCost = assignDollarCents(this.inputs.subCashTfsEstCost * this.inputs.forecastedSales);
    this.subCashOfferEnhTfsCost = assignDollarCents(this.inputs.subCashEnhTfsEstCost * this.inputs.forecastedSales);

    this.calcOfferTotalCostAndBalance();
  };

  updateTfsShare = (defaultTfsShare: number) => {
    if (!this.isEnhanced) {
      this.inputs.tfsShare = defaultTfsShare;
    }
  };

  updateInput = <T extends keyof LeaseFormInputs, V extends LeaseFormInputs[T]>(field: T, value: V) => {
    if (!this.inputs.isAdvertised || this.isGST || field !== this.lock) {
      this.inputs[field] = value;
    }
  };

  updateInputWithCalc = <T extends keyof LeaseFormInputs, V extends LeaseFormInputs[T]>(field: T, value: V) => {
    if (field !== this.lock) {
      this.inputs[field] = value;
      this.recalculate(undefined, field);
    }
  };

  calculateNonAdGstEstCosts = (region?: string, useOldCostShareForLexus = false, isTfsShareChanged = false) => {
    const defaultTfsShare = getDefaultTfsShare(OPTION_TYPE_NAMES.LEASE, assignStringValue(region), this.inputs.term, useOldCostShareForLexus);
    if (!FEATURE_OR_5646 || (FEATURE_OR_5646 && assignNumberValue(this.inputs.tfsShare) !== 100)) {
      const { tfsCost, enhTfsCost } = LeaseCalcFns.calculateTfsEstimatedBuyDownCostReverse(
        assignNumberValue(this.inputs.estimatedCost),
        assignNumberValue(this.inputs.tfsShare),
        defaultTfsShare,
      );
      this.inputs.tfsEstCost = tfsCost;
      this.inputs.enhTfsEstCost = enhTfsCost;
    } else {
      this.inputs.tfsEstCost = FEATURE_OR_5646 && isTfsShareChanged ? 0 : this.inputs.tfsEstCost;
      const enhTfsCost = this.inputs.tfsEstCost - this.inputs.tfsEstCost * (defaultTfsShare / 100);

      this.inputs.enhTfsEstCost = enhTfsCost;
      this.inputs.estimatedCost = 0;
    }
  };

  /**
   * For advertised lease
   * @param skipCalc true is for initialization to apply the correct capitalization flags
   */
  recalculate = (skipCalc = false, src?: string, region?: string, offering?: Offering) => {
    //convert strings to numbers
    const converted = {
      ...this.inputs,
      dealerGross: Number(this.inputs.dealerGross),
      dealerGrossForZeroDueAtSigning: Number(this.inputs.dealerGrossForZeroDueAtSigning),
      dueAtSigning: Number(this.inputs.dueAtSigning),
      rcf: this.areTfsEnhRatesUsed ? Number(this.inputs.enhancedStdRcf) : Number(this.inputs.rcf),
      subventionCash: Number(this.inputs.subventionCash),
      term: Number(this.inputs.term),
      targetPayment: Number(this.inputs.targetPayment),
      nationalRcf: this.inputs.isStandalone ? Number(this.inputs.standardRegionalRcf) : Number(this.inputs.nationalRcf),
      residualAmount: Boolean(assignNumberValue(this.inputs.enhancedResidualRate)) ? Number(this.inputs.enhancedResidualAmount) : this.inputs.residualAmount,
      enhancedResidualRate: Number(this.inputs.enhancedResidualRate),
      enhancedResidualAmount: Number(this.inputs.enhancedResidualAmount),
      residualRate: assignNumberValue(this.inputs.residualRate),
      tfsShare: Number(this.inputs.tfsShare),
      defaultTfsShare: getDefaultTfsShare(OPTION_TYPE_NAMES.LEASE, region || '', this.inputs.term, !!offering?.useOldCostShareForLexus),
      adjustmentFactor: Number(this.inputs.adjustmentFactor),
      isAddtlCashAppliedToDueAtSigning: this.inputs.isAddtlCashAppliedToDueAtSigning,
      additionalCash: Number(this.inputs.additionalCashAmount),
    };

    const ogValues = LeaseCalcFns.getBaseValues(converted);
    let baseValues = LeaseCalcFns.getBaseValues(converted);

    if (!skipCalc) {
      // Only when user puts 0 in dueAtSigning field, lock targetPayment
      if (src === 'dueAtSigning' && assignNumberValue(this.inputs.dueAtSigning) === 0) {
        this.lock = 'targetPayment';
      }

      switch (this.lock) {
        case 'targetPayment': {
          baseValues.targetPayment = Math.round(LeaseCalcFns.calculateTargetPayment(baseValues));
          break;
        }
        case 'dueAtSigning': {
          baseValues.dueAtSigning = Math.round(LeaseCalcFns.calculateDueAtSigning(baseValues));
          break;
        }
        case 'rcf': {
          baseValues.rcf = LeaseCalcFns.calculateRcf(baseValues);
          break;
        }

        case 'dealerGross': {
          baseValues.dealerGross = Math.round(LeaseCalcFns.calculateDealerGross(baseValues));
          break;
        }

        case 'subventionCash': {
          baseValues.subventionCash = Math.round(LeaseCalcFns.calculateSubventionCash(baseValues));
          break;
        }
      }
    }

    const postCalcValues = LeaseCalcFns.postCalculation(baseValues);
    if (postCalcValues.isZeroDueAtSigning) {
      // if user changes DAS to 0, adjust dealerGross
      if (src === 'dueAtSigning') {
        postCalcValues.dealerGross = postCalcValues.dealerGross - postCalcValues.targetPayment + postCalcValues.subventionCash + postCalcValues.additionalCash;
        // if user changes dealerGross, calculate DAS on the side
        // if targetPayment
      } else if (src === 'dealerGross' && this.lock !== 'dueAtSigning') {
        baseValues.dueAtSigning =
          postCalcValues.downPayment + postCalcValues.targetPayment - postCalcValues.dealerGross - postCalcValues.subventionCash - postCalcValues.additionalCash;
      } else {
        const dgZeroDasDiff = postCalcValues.dealerGrossForZeroDueAtSigning - ogValues.dealerGrossForZeroDueAtSigning;
        postCalcValues.dealerGross = postCalcValues.dealerGross - dgZeroDasDiff;
      }
    }

    this.mapCalcValuesToInputs(postCalcValues, skipCalc);

    if (!skipCalc) {
      this.calculateDefaultPayments(); // used for display under input boxes
    }
  };

  mapCalcValuesToInputs = (baseValues: LeaseCalcValues, skipCalc = false) => {
    // locked fields
    if (!skipCalc) {
      this.inputs.targetPayment = baseValues.targetPayment;
      this.inputs.dueAtSigning = baseValues.dueAtSigning;
      this.inputs.rcf = baseValues.rcf;
      this.inputs.dealerGross = baseValues.dealerGross;
      this.inputs.subventionCash = baseValues.subventionCash;

      this.inputs.grossCapCost = baseValues.grossCapCost;
      this.inputs.netCapCost = baseValues.netCapCost;
      this.inputs.totalDealerGross = baseValues.totalDealerGross;
      this.inputs.downPayment = baseValues.downPayment;
      this.inputs.estimatedCost = this.areTfsEnhRatesUsed ? 0 : baseValues.estCost;
      this.inputs.tfsEstCost = this.areTfsEnhRatesUsed ? 0 : baseValues.tfsEstCost;
      this.inputs.enhTfsEstCost = this.areTfsEnhRatesUsed ? 0 : baseValues.enhTfsEstCost;
    }

    // These are flags that don't come from data, but must be generated initially
    // for lease card to function properly
    this.inputs.isAcquisitionFeeCapitalized = baseValues.isAcquisitionFeeCapitalized;
    this.inputs.isSubCashAppliedToDueAtSigning = baseValues.isSubCashAppliedToDueAtSigning;
    this.inputs.isAcquisitionFeeCapitalized = baseValues.isAcquisitionFeeCapitalized;
    this.inputs.isZeroDueAtSigning = baseValues.isZeroDueAtSigning;
    this.inputs.dealerGrossForZeroDueAtSigning = baseValues.dealerGrossForZeroDueAtSigning;
  };

  calculateDefaultPayments = () => {
    if (this.inputs.isAdvertised) {
      this.inputs.targetPaymentDefault = LeaseCalcFns.calculateDefaultTargetPayment(
        this.inputs.dealerCostPerInvoice,
        Number(this.inputs.dealerGrossDefault),
        DEFAULT_DOWN_PAYMENT,
        Number(this.inputs.subventionCash),
        this.inputs.residualAmountDefault,
        this.inputs.term,
        Number(this.inputs.isStandalone ? this.inputs.standardRegionalRcf : this.inputs.nationalRcf),
      );
      this.inputs.dueAtSigningDefault = LeaseCalcFns.calculateDefaultDueAtSigning(this.inputs.targetPaymentDefault, DEFAULT_DOWN_PAYMENT, this.inputs.acquisitionFee);
    }
  };

  setLock = (value: string) => {
    this.lock = value as LockType;
  };

  setTfsEnhRatesUsed = (areTfsEnhRatesUsed: boolean, tfsLabel: string) => {
    this.areTfsEnhRatesUsed = areTfsEnhRatesUsed;
    if (this.areTfsEnhRatesUsed) {
      this.updateInput('rcf', this.inputs.enhancedStdRcf);
      this.updateInput('subventionCash', 0);
      this.updateInput('estimatedCost', 0);
      this.updateInput('note', `${tfsLabel} Enhanced Standard RCF`);
    } else {
      this.updateInputWithCalc('rcf', assignStringValue(this.getRates()?.subventedRcf));
      this.updateInput('note', '');
    }
  };

  setVinDetails = (vinDetails: EFCVinResponse | undefined) => {
    if (!vinDetails) {
      return;
    }

    this.carJelly = `${process.env.REACT_APP_CAR_JELLY_URL}${EfcApi.getCarJellyFromExtColors(vinDetails.exteriorColor)}`;
    this.invoiceDate = formatInvoiceDate(vinDetails.dealerPricing.invoiceDate || '');
    this.dealerCode = vinDetails.currentDealerCode;
    this.originalDealerCode = vinDetails.wholesaleDealerCodes ? vinDetails.wholesaleDealerCodes[0] : '';
    this.vinOptions = vinDetails.accessories.map(vinDetail => `${vinDetail.title} (${vinDetail.code})`);
    this.inputs.dph = assignNumberValue(vinDetails.vehicleDestinationCharge);

    this.inputs.defaultTdaFee = assignNumberValue(vinDetails.dealerPricing.tda);
    this.inputs.defaultTotalMsrp = assignNumberValue(vinDetails.dealerPricing.totalvehmsrp);
    this.inputs.defaultDealerCostPerInvoice = Math.round(assignNumberValue(vinDetails.dealerPricing.dealercostperinvoice));

    this.isVinLoaded = true;
  };

  setVinDetailsDD365 = (vinDetails: DD365VinDetailsResponse | undefined, seriesItem: EfcSeries) => {
    if (!vinDetails) {
      return;
    }

    const { accessoriesList } = getAccessoriesFromDD365VinDetail(vinDetails);
    const efcYear = seriesItem.year.find(item => Number(item.code) === vinDetails.year);
    const efcModel = efcYear?.model.find(item => item.code === vinDetails.model.code);

    if (efcModel) {
      this.carJelly = `${process.env.REACT_APP_CAR_JELLY_URL}${EfcApi.getCarJellyFromExtColors(efcModel.exteriorcolor)}`;
    }
    this.invoiceDate = formatInvoiceDate(vinDetails.invoiceDate || '');
    this.dealerCode = vinDetails.dealerCode;
    this.vinOptions = accessoriesList;
    this.inputs.dph = Number(vinDetails.price.dph);

    // default tda is blank for dd365
    this.inputs.defaultTdaFee = assignNumberValue(vinDetails.price.tda);
    this.inputs.defaultTotalMsrp = assignNumberValue(vinDetails.price.totalMsrp);
    this.inputs.defaultDealerCostPerInvoice = Math.round(assignNumberValue(vinDetails.price.totalDealerInvoice));

    this.isVinLoaded = true;
  };

  updateMileage = (mileage: string, residuals: ResidualValue[]) => {
    this.inputs.mileage = mileage;
    const adjustedResiduals = getAdjustedResidualRate(this.inputs.mileage, residuals);
    const rvRate =
      adjustedResiduals.filter(
        residual => residual.term === this.inputs.highTerm && residual.modelCode === this.inputs.modelCode.toString() && residual.seriesYear === this.inputs.modelYear,
      )[0]?.rv || 0;

    this.updateDefaultResiduals(rvRate);
    this.updateResiduals(rvRate, true);
  };

  updateResiduals = (rvRate: number, toWhole = false) => {
    const rate = toWhole ? rvRate : convertPercentage(rvRate, toWhole);
    this.lock = 'targetPayment';
    this.inputs.residualRate = toWhole ? convertPercentage(rvRate, toWhole) : rvRate;
    const residualAmount = Math.round(LeaseCalcFns.calculateResidualAmount(Number(this.inputs.totalMsrp), rate));
    this.calculateEnhancedRvAmount();
    this.updateInput('residualAmount', residualAmount);

    if (!this.isGST) {
      this.recalculate();
    }
  };

  updateDefaultResiduals = (rvRate: number) => {
    this.inputs.residualRateDefault = rvRate;
    this.inputs.residualAmountDefault = LeaseCalcFns.calculateResidualAmount(this.inputs.totalMsrp, rvRate);
  };

  updateRvEnhancement = (rv: string) => {
    this.inputs.enhancedResidualRate = rv;
    this.calculateEnhancedRvAmount();
    this.recalculate();
  };

  calculateEnhancedRvAmount = () => {
    const rate = (assignNumberValue(this.inputs.enhancedResidualRate) + assignNumberValue(this.inputs.residualRate)) / 100.0;
    this.inputs.enhancedResidualAmount = Math.round(this.inputs.totalMsrp * rate);
  };

  updateAdditionalCash = (cashMiscOffer?: CashCardModel | MiscCardModel) => {
    this.lock = 'targetPayment';

    if (!cashMiscOffer) {
      this.resetAdditionalCash();
    } else {
      this.inputs.additionalCashAmount = cashMiscOffer.fields.combinedPerUnitCost;
      this.inputs.additionalDefaultAmount = assignNumberValue(cashMiscOffer.fields.defaultPerUnitCost);
      this.inputs.additionalCashOfferId = cashMiscOffer.id;
      this.inputs.additionalCashOptionType = cashMiscOffer.fields.name;
    }

    // run calculation
    if (!this.isGST) {
      this.recalculate();
    }
  };

  updateAdditionalCashAmount = (amount: string, ryoEarnings: number, cashMiscOffer?: CashCardModel | MiscCardModel) => {
    if (cashMiscOffer) {
      this.lock = 'targetPayment';
      this.inputs.additionalCashAmount = amount;
      this.inputs.additionalDefaultAmount = assignNumberValue(cashMiscOffer.fields.defaultPerUnitCost);

      if (cashMiscOffer.optionTypeName === OPTION_TYPE_NAMES.CUSTOMER_CASH) {
        const cashOffer = cashMiscOffer as CashCardModel;
        cashOffer.updateField('combinedPerUnitCost', amount);
        cashOffer.updateEstCosts();
        cashOffer.updateOfferCosts(ryoEarnings);
      } else {
        const miscOffer = cashMiscOffer as MiscCardModel;
        miscOffer.updateAmountOrPnv('amount', amount);
        miscOffer.updateOfferCosts(ryoEarnings);
      }

      if (!this.isGST) {
        this.recalculate();
      }
    }
  };

  updateSubCash = (subCash: string) => {
    this.updateInput('subventionCash', subCash);
    if (!Number(subCash)) {
      this.inputs.isSubCashAdvertised = false;
    }
    this.calculateSubCashEstCost();

    if (!this.isGST && this.inputs.isAdvertised) {
      this.recalculate();
    }
  };

  updateSubCashCap = (cap: number, tfsShare: number) => {
    this.inputs.subCashTfsCostShareCap = cap;
    this.inputs.subCashTfsShare = tfsShare;

    this.calculateSubCashEstCost();
  };

  calculateSubCashEstCost = () => {
    const { subCashEstCost, subCashTfsEstCost, subCashEnhTfsEstCost, subCashOfferCost, subCashOfferTfsCost, subCashOfferEnhTfsCost } =
      SubCashCalcFns.calculateSubventionCashEstimatedCost(
        assignNumberValue(this.inputs.subventionCash),
        this.inputs.nationalSubventionCash,
        0, // OR-2652 Can't find og ticket for default sub cash cost shares
        this.inputs.subCashTfsShare / 100.0,
        this.inputs.subCashTfsCostShareCap,
        this.inputs.forecastedSales,
        true,
      );

    this.inputs.subCashEstCost = subCashEstCost;
    this.inputs.subCashTfsEstCost = subCashTfsEstCost;
    this.inputs.subCashEnhTfsEstCost = subCashEnhTfsEstCost;
    this.subCashOfferCost = subCashOfferCost;
    this.subCashOfferTfsCost = subCashOfferTfsCost;
    this.subCashOfferEnhTfsCost = subCashOfferEnhTfsCost;
  };

  updateTotalMsrp = (totalMsrp: number) => {
    this.inputs.totalMsrp = totalMsrp;
    this.inputs.residualAmount = Math.round((totalMsrp * Number(this.inputs.residualRate ?? 0)) / 100.0);
    this.calculateEnhancedRvAmount();
    this.recalculate();
  };

  updateFundingSourceSplits = (fundingSourceSplits: FundingSourceSplits[]) => {
    this.fundingSourceSplits = fundingSourceSplits;
  };

  updateIsSetSubRcfApplied = (isApplied: boolean) => {
    this.isSetSubRcfApplied = isApplied;
  };

  toggleUpTo = () => {
    this.inputs.isUpToApplied = !this.inputs.isUpToApplied;
  };

  toggleAcquisitionFee = (acFee: number) => {
    const acFeeVal = this.inputs.acquisitionFee === 0 ? acFee : 0;

    if (this.isGST) {
      this.updateInput('acquisitionFee', acFeeVal);
    } else {
      this.setLock('dueAtSigning');
      this.updateInputWithCalc('acquisitionFee', acFeeVal);
    }
  };

  toggleApplyAdditionalCash = () => {
    this.inputs.isAddtlCashAppliedToDueAtSigning = !this.inputs.isAddtlCashAppliedToDueAtSigning;
    this.setLock('dueAtSigning');
    if (!this.isGST) {
      this.recalculate();
    }
  };

  getStandardNationalLabel = () => {
    return this.inputs.isStandalone ? 'Standard' : 'National';
  };

  removeCustomerCashAdditionalCash = (cashModels: CashCardModel[]) => {
    const foundCashModel = cashModels.find(c => c.id === this.inputs.additionalCashOfferId);
    if (foundCashModel && assignNumberValue(this.inputs.rcf) !== this.inputs.standardRcf) {
      this.resetAdditionalCash();
    }
  };

  resetAdditionalCash = () => {
    this.inputs.additionalCashAmount = '0';
    this.inputs.additionalDefaultAmount = 0;
    this.inputs.additionalCashOfferId = '';
    this.inputs.additionalCashOptionType = 'None';
  };

  getEnhancedRcf = () => {
    return this.inputs.tfsEnhancedRates?.find(rate => rate.tier === '1+');
  };

  getRates = () => {
    return this.inputs.rates.find(rate => rate.tier === '1+');
  };

  hasError = () => {
    if (!this.inputs.isAdvertised) {
      return (
        Boolean(this.estCostError) ||
        Boolean(this.rcfError) ||
        Boolean(this.subCashError) ||
        Boolean(this.hasDateError) ||
        this.tfsShareError?.required ||
        Boolean(this.tfsEstCostError)
      );
    } else if (this.isGST) {
      return (
        Boolean(this.vehicleDescriptionError) ||
        Boolean(this.tpError) ||
        Boolean(this.dasError) ||
        Boolean(this.gstRvAmountError) ||
        Boolean(this.gstNetCapError) ||
        Boolean(this.gstTotalMsrpError) ||
        Boolean(this.dpError) ||
        Boolean(this.gstBonusCashError) ||
        Boolean(this.gstGrossCapError) ||
        Boolean(this.subCashError) ||
        Boolean(this.hasDateError) ||
        Boolean(this.estCostError) ||
        Boolean(this.rcfError) ||
        this.tfsShareError?.required ||
        Boolean(this.noteError) ||
        Boolean(this.tfsEstCostError)
      );
    } else {
      return (
        Boolean(this.estCostError) ||
        Boolean(this.rcfError) ||
        Boolean(this.tpError) ||
        Boolean(this.dgError) ||
        Boolean(this.subCashError) ||
        Boolean(this.hasDateError) ||
        Boolean(this.dasError) ||
        Boolean(this.dpError) ||
        Boolean(this.additionalCashError) ||
        this.tfsShareError?.required ||
        Boolean(this.noteError)
      );
    }
  };

  get totalResidualRate() {
    return (assignNumberValue(this.inputs.enhancedResidualRate) + assignNumberValue(this.inputs.residualRate)).toFixed(2);
  }

  get isEnhanced() {
    return (
      (this.inputs.isStandalone ? Number(this.inputs.standardRegionalRcf) : Number(this.inputs.nationalRcf)) !== Number(this.inputs.rcf) || Number(this.inputs.estimatedCost) > 0
    );
  }

  get hasDateError() {
    return dateValidator(this.inputs.startDate, this.inputs.endDate);
  }

  get estCostError() {
    return validator(this.inputs.estimatedCost, { required: true, min: 0 });
  }

  get rcfError() {
    return validator(this.inputs.rcf, { required: true, min: 0, max: this.inputs.isStandalone ? Number(this.inputs.standardRegionalRcf) : Number(this.inputs.nationalRcf) });
  }

  get tpError() {
    return validator(this.inputs.targetPayment, { required: true, min: this.isGST ? 1 : 0 });
  }

  get dgError() {
    return validator(this.inputs.dealerGross, { required: true, min: 0 });
  }

  get subCashError() {
    return validator(this.inputs.subventionCash, { required: true, min: this.inputs.nationalSubventionCash });
  }

  get dasError() {
    return validator(this.inputs.dueAtSigning, { required: true, min: 0 });
  }

  get dpError() {
    return validator(this.inputs.downPayment, { required: true, min: 0 });
  }

  get vehicleDescriptionError() {
    return validator(this.inputs.vehicleDescription, { required: true });
  }

  get tfsShareError() {
    return validator(this.inputs.tfsShare, { required: true });
  }

  get gstRvAmountError() {
    return validator(this.inputs.residualAmount, { required: true, min: 1 });
  }

  get gstNetCapError() {
    return validator(this.inputs.netCapCost, { required: true, min: 1 });
  }

  get gstTotalMsrpError() {
    return validator(this.inputs.totalMsrp, { required: true, min: 1 });
  }

  get gstBonusCashError() {
    return validator(this.inputs.bonusCash, { required: true, min: 0 });
  }

  get gstGrossCapError() {
    return validator(this.inputs.grossCapCost, { required: true, min: 0 });
  }

  get additionalCashError() {
    return validator(this.inputs.additionalCashAmount, { min: this.inputs.additionalDefaultAmount });
  }

  get noteError() {
    return validator(this.inputs.note, { required: this.areTfsEnhRatesUsed });
  }

  get tfsEstCostError() {
    return validator(this.inputs.tfsEstCost, {
      min: assignNumberValue(this.inputs.tfsShare) === 100 && FEATURE_OR_5646 ? 1 : 0,
      required: assignNumberValue(this.inputs.tfsShare) === 100 && FEATURE_OR_5646,
    });
  }
}

export default LeaseFormModel;
