import { makeAutoObservable } from 'mobx';
import { AdminConstants, AdminModels, CashCalcFns } from 'oat-admin-common';
import { dateStringToDate, uuidv4 } from 'oat-common-ui';
import AccordionModel from '../../../components/Accordion/models/AccordionModel';
import { Offer, Offering, SeriesMapping, SeriesProfile, VehicleInput } from '../../../gql/generated';
import { stores } from '../../../stores/useStores';
import { assignNumberValue } from '../../../utils/assignValue';
import { calculateOfferBalance, calculateOfferCost, calculateOfferEarning } from '../../../utils/offerCalculations';
import validator from '../../../utils/validator';
import { createCashCard } from '../../createOffer/utils/createCashCard';

const { OfferTypes } = AdminConstants;
export class CashCardFields {
  name = `${OfferTypes.CUSTOMER_CASH}`;
  defaultPerUnitCost: number | undefined = 0;
  combinedPerUnitCost = '0';
  note = '';
  tdaNote = '';
  modelNote = '';
  isSpecialEdition = false;
  penetration = '';
  forecastedSales = 0;
  contestNumber = '';
  contestNumberOfferId = '';
  startDate: Date | undefined;
  endDate: Date | undefined;
  defaultStartDate: Date | undefined;
  defaultEndDate: Date | undefined;
  includedStates: string[] = [];
  excludedStates: string[] = [];
  compatibileOffers: string[] = [];
  compatibility: AdminModels.Compatibility = {
    defaultTypes: [],
    incompatibleTypes: [],
    optionalTypes: [],
  };
  accessoryCodes?: string;

  vehicles: VehicleInput[] = [];
  vehiclesAccordion = new AccordionModel();
  isInclusions = false;
  isValidEnR = true;

  specialEditionPackage?: string;
  includedVehicles: VehicleInput[] = [];
  excludedVehicles: VehicleInput[] = [];
  isEnhanced = false;
  tfsShare = 0;
  estimatedCost = 0;
  offerEarnings = 0;
  offerCost = 0;
  offerBalance = 0;
  offerTfsCost = 0;
  offerEnhTfsCost = 0;

  constructor() {
    makeAutoObservable(this);
  }
}

class CashCardModel {
  uid = uuidv4();
  fields = new CashCardFields();
  id = '';
  rev = '';
  parentId = '';
  optionTypeName = `${OfferTypes.CUSTOMER_CASH}`;
  isAdvertised = true;
  isStandAlone = true;
  isMultiSeries = false;
  isDuplicate = false;
  isNationalAndIsAtc = false;

  nationalOffer: Offer | undefined; // used for recreating card

  constructor() {
    makeAutoObservable(this);
  }

  // To set data within the store, not the component level to satisfy strict mode
  setCardData = (offer: Offer, seriesMapping: SeriesMapping[], seriesProfile: SeriesProfile, offering?: Offering) => {
    createCashCard(this, seriesMapping, { regional: offer, national: this.nationalOffer }, seriesProfile, offering?.startDate, offering?.endDate);
  };

  updateField = <T extends keyof CashCardFields, V extends CashCardFields[T]>(field: T, value: V) => {
    this.fields[field] = value;
  };

  updateEstCosts = () => {
    const { estCost } = CashCalcFns.calculateEstimatedBuyDownCost(assignNumberValue(this.fields.combinedPerUnitCost), assignNumberValue(this.fields.defaultPerUnitCost), 0, 0);
    this.fields.estimatedCost = estCost;
  };

  updateVehicles = (excludedList: VehicleInput[], includedList: VehicleInput[]) => {
    this.fields.includedVehicles = includedList;
    this.fields.excludedVehicles = excludedList;
  };

  updateAdvertised = () => {
    this.isAdvertised = !this.isAdvertised;
  };

  updateRev = (rev = '') => {
    this.rev = rev;
  };

  toggleIsInclusions = () => {
    this.fields.isInclusions = !this.fields.isInclusions;
  };

  updateOfferCosts = (ryoEarnings: number) => {
    this.fields.offerEarnings = calculateOfferEarning(this.fields.forecastedSales, ryoEarnings);
    this.fields.offerCost = calculateOfferCost(this.fields.forecastedSales, Number(this.fields.estimatedCost));
    this.fields.offerBalance = calculateOfferBalance(this.fields.offerEarnings, this.fields.offerCost);
  };

  get hasDateError() {
    if (!this.fields.endDate || !this.fields.startDate) {
      return true;
    }

    const endDate = dateStringToDate(this.fields.endDate);
    const startDate = dateStringToDate(this.fields.startDate);

    return endDate.getTime() < startDate.getTime();
  }

  get hasError() {
    return validator(this.fields.combinedPerUnitCost, { required: true, min: Number(this.fields.defaultPerUnitCost) }) !== undefined;
  }

  get isDisabled() {
    return this.isNationalAndIsAtc;
  }

  setIsNationalAndIsAtc = (isNationalAndIsAtc: boolean) => {
    this.isNationalAndIsAtc = isNationalAndIsAtc;
  };

  toggleIsSpecialEdition = () => {
    this.fields.isSpecialEdition = !this.fields.isSpecialEdition;
    if (!this.fields.isSpecialEdition) {
      this.fields.specialEditionPackage = '';
      this.fields.accessoryCodes = '';
    }
  };

  get isSpecialEditionValid() {
    if (!this.fields.isSpecialEdition) {
      return true;
    } else {
      return stores.userInfoStore.isGst() && Boolean(this.fields.accessoryCodes);
    }
  }

  setCompatibileOffers = (compatibileOffers?: AdminModels.Compatibility) => {
    this.fields.compatibileOffers = compatibileOffers?.defaultTypes || [];
  };

  setCompatibility = (compatability: AdminModels.Compatibility) => {
    this.fields.compatibility = compatability;
  };

  toggleCompatibileOffer = (offerName: string) => {
    const compOfferIndex = this.fields.compatibileOffers.findIndex(offer => offer === offerName);

    if (compOfferIndex === -1) {
      this.fields.compatibileOffers.push(offerName);
    } else {
      this.fields.compatibileOffers.splice(compOfferIndex, 1);
    }
  };
}

export default CashCardModel;
