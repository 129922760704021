import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { Button, Input, OATIcon, Tooltip } from 'oat-common-ui';
import { ChangeEvent, useState } from 'react';
import DollarDisplay from '../../../../../components/DollarDisplay';
import useStores from '../../../../../stores/useStores';
import { assignNumberValue, defaultToUndefined } from '../../../../../utils/assignValue';
import { getMonthAndDate } from '../../../../../utils/getDateParts';

import { InputType } from '../../../models/ReconcileDataModel';
import ReconcileOfferModel from '../../../models/ReconcileOfferModel';
import ReconcileSeriesModel from '../../../models/ReconcileSeriesModel';
import SpHeaderSummary from './Header';
import DetailsTableHeader from './Header/DetailsTableHeader';
import styles from './styles.module.scss';

interface Props {
  srs: ReconcileSeriesModel;
  onSubmit: (series: ReconcileSeriesModel) => void;
}

const Series = ({ srs, onSubmit }: Props) => {
  const {
    userInfoStore: { isNational, isSETUser },
    reconcileStore: { reconcileData },
  } = useStores();
  const { updateActuals, updateActualsByPenetration, updateActualsByCost, validateInputsForReconcile, calculateEarningsOnlySP } = reconcileData;
  const [editUnit, setEditUnit] = useState(true);
  const [editPen, setEditPen] = useState(false);
  const [editPnv, setEditPnv] = useState(true);
  const [editCost, setEditCost] = useState(false);
  const [natPnv, setNatPnv] = useState(false);
  const [natCost, setNatCost] = useState(true);

  const handleUpdateActuals = (srs: ReconcileSeriesModel, offer: ReconcileOfferModel, param = 'none', value = 0) => {
    updateActuals(srs, offer, param, value, isNational());
  };

  const handlePenetrationOnChange = (srs: ReconcileSeriesModel, offer: ReconcileOfferModel, value: InputType) => {
    updateActualsByPenetration(srs, offer, value, isNational());
  };

  const handleActualsByCostOnChange = (srs: ReconcileSeriesModel, offer: ReconcileOfferModel, value: InputType) => {
    updateActualsByCost(srs, offer, value, isNational());
  };

  const handleSalesVolumeDealerStockOnChange = (e: ChangeEvent<HTMLInputElement>, isSalesVolume = false) => {
    if (isSalesVolume) {
      srs.updateField(isNational() ? 'nationalActualSalesVolume' : 'regionalActualSalesVolume', defaultToUndefined(e.target.value));
    } else {
      srs.updateField(isNational() ? 'nationalActualDealerStock' : 'regionalActualDealerStock', defaultToUndefined(e.target.value));
    }
    calculateEarningsOnlySP(isNational());
    srs.calculateActualCosts(isNational());
    srs.calculateNvsCosts(isNational());
    validateInputsForReconcile();
  };

  const editActuals = (param: string) => {
    if (param === 'PNV') {
      setEditPnv(true);
      setEditCost(false);
    } else if (param === 'Cost') {
      setEditPnv(false);
      setEditCost(true);
    } else if (param === 'Unit') {
      setEditUnit(true);
      setEditPen(false);
    } else if (param === 'Pen') {
      setEditUnit(false);
      setEditPen(true);
    }
  };

  const togglePnvOrCost = (param: string) => {
    if (param === 'cost') {
      setNatPnv(true);
      setNatCost(false);
    } else {
      setNatPnv(false);
      setNatCost(true);
    }
  };

  const getTooltip = (id: string, excl = false) => {
    return (
      <Tooltip
        id={excl ? `${id}-exclusions` : `${id}-fourth-option`}
        message={excl ? <p>Offer contains model code exclusions.</p> : <p>Offer is a fourth offer.</p>}
        place="bottom"
        grayTheme
        className={styles.recTooltip}
      >
        <span className={styles.recLabel}>*</span>
      </Tooltip>
    );
  };
  return (
    <li id={srs.seriesProfileId} className={styles.listItem}>
      <header className={styles.header1}>
        <span className={styles.title1}>{srs.seriesProfileName}</span>
        <SpHeaderSummary series={srs} />
      </header>
      <section className={styles.actualsSection}>
        <label className={styles.actualSalesDealer}>NVS/Actuals</label>
        <Input
          className={styles.actualSalesDealerInput}
          value={isNational() ? srs.fields.nationalActualSalesVolume : srs.fields.regionalActualSalesVolume}
          error={(srs.invalidSummarySeriesDetail || srs.invalidSeriesDetail) && srs.fields.hasSalesVolumeError}
          onChange={e => handleSalesVolumeDealerStockOnChange(e, true)}
        />
        <label className={styles.actualSalesDealer}>
          Actual Dealer <br />
          Stock
        </label>
        <Input
          className={styles.actualSalesDealerInput}
          value={isNational() ? srs.fields.nationalActualDealerStock : srs.fields.regionalActualDealerStock}
          error={srs.hasFinalPay && srs.invalidSeriesDetail && srs.fields.hasDealerStockError}
          onChange={e => handleSalesVolumeDealerStockOnChange(e)}
        />
      </section>
      <table className={styles.penTable}>
        <DetailsTableHeader
          editPen={editPen}
          editUnit={editUnit}
          editCost={editCost}
          editPnv={editPnv}
          natPnv={natPnv}
          natCost={natCost}
          editActuals={editActuals}
          togglePnvOrCost={togglePnvOrCost}
        />
        <tbody>
          {srs.offers.map(offer => (
            <tr className={styles.penRow} key={offer.uid}>
              <td className={clsx(styles.seriesOffer, styles.offerName)}>
                {offer.popoverTitle}
                {offer.hasDateSplits && (
                  <div className={styles.dateSplit}>
                    {getMonthAndDate(offer.startDate)} - {getMonthAndDate(offer.endDate)}
                  </div>
                )}
                {offer.hasExclusions && getTooltip(offer.uid, true)}
                {offer.isMisc && offer.isFourthOption && getTooltip(offer.uid, false)}
              </td>
              <td className={styles.penData}>{offer.forecastedSales}</td>
              <td className={styles.penData}>{offer.penetration}%</td>
              <td className={styles.penData}>
                <div className={styles.dollarContainer}>
                  <DollarDisplay id={`rec-prjctd-pnv-${offer.uid}`} value={offer.estimatedCost} className={styles.dollarAmount} />
                </div>
              </td>
              <td className={styles.penData}>
                <div className={styles.dollarContainer}>
                  <DollarDisplay id={`rec-prjctd-cost-${offer.uid}`} value={offer.offerCost} className={styles.dollarAmount} />
                </div>
              </td>

              <td className={styles.penTableSpacer}></td>

              <td className={styles.penData}>{offer.nvsUnits}</td>
              <td className={styles.penData}>
                <div className={styles.dollarContainer}>
                  <DollarDisplay id={`rec-nvs-prjctd-cost-${offer.uid}`} value={assignNumberValue(offer.nvsOfferCost)} className={styles.dollarAmount} />
                </div>
              </td>

              <td className={styles.penTableSpacer}></td>

              {editUnit && (
                <td className={styles.penInputs}>
                  <Input
                    type="text"
                    value={isNational() ? offer.nationalActualUnits : offer.regionalActualUnits}
                    disabled={offer.optionTypeName === AdminConstants.OPTION_TYPE_NAMES.FINAL_PAY || offer.isFlatCostOfferType}
                    onChange={e => {
                      handleUpdateActuals(srs, offer, isNational() ? 'nationalUnits' : 'regionalUnits', defaultToUndefined(e.currentTarget.value));
                      validateInputsForReconcile();
                    }}
                    error={srs.invalidSeriesDetail && offer.hasActualUnitsError}
                  />
                </td>
              )}
              {!editUnit && <td className={styles.penData}>{isNational() ? offer.nationalActualUnits : offer.regionalActualUnits}</td>}
              {editPen && (
                <td className={styles.penInputs}>
                  <Input
                    type="text"
                    disabled={offer.isFlatCostOfferType}
                    value={isNational() ? offer.nationalActualPenetration : offer.regionalActualPenetration}
                    onChange={e => handlePenetrationOnChange(srs, offer, defaultToUndefined(e.target.value))}
                  />
                </td>
              )}
              {!editPen && <td className={styles.penData}>{isNational() ? offer.nationalActualPenetration : offer.regionalActualPenetration}%</td>}
              {editPnv && (
                <td className={styles.penInputs}>
                  <Input
                    type="text"
                    value={isNational() ? offer.nationalActualCost : offer.regionalActualCost}
                    error={srs.invalidSeriesDetail && offer.hasActualCostError}
                    disabled={offer.isFlatCostOfferType && offer.optionTypeName !== AdminConstants.OPTION_TYPE_NAMES.FINAL_PAY}
                    onChange={e => {
                      handleUpdateActuals(srs, offer, isNational() ? 'nationalPnv' : 'regionalPnv', defaultToUndefined(e.currentTarget.value));
                      validateInputsForReconcile();
                    }}
                  />
                </td>
              )}
              {!editPnv && (
                <td className={styles.penData}>
                  <div className={styles.dollarContainer}>
                    <DollarDisplay
                      id={`rec-actl-pnv-${offer.uid}`}
                      value={assignNumberValue(isNational() ? offer.nationalActualCost : offer.regionalActualCost)}
                      className={styles.dollarAmount}
                    />
                  </div>
                </td>
              )}
              {editCost && (
                <td className={styles.penInputs}>
                  <Input
                    type="text"
                    value={isNational() ? offer.nationalActualOfferCost : offer.regionalActualOfferCost}
                    onChange={e => handleActualsByCostOnChange(srs, offer, defaultToUndefined(e.currentTarget.value))}
                  />
                </td>
              )}
              {!editCost && (
                <td className={styles.penData}>
                  <div className={styles.dollarContainer}>
                    <DollarDisplay
                      id={`rec-actl-cost-${offer.uid}`}
                      value={assignNumberValue(isNational() ? offer.nationalActualOfferCost : offer.regionalActualOfferCost)}
                      className={styles.dollarAmount}
                    />
                  </div>
                </td>
              )}
              <td className={styles.penTableSpacer}></td>
              <td className={styles.penData}>
                <div className={styles.dollarContainer}>
                  <DollarDisplay id={`rec-cost-var-${offer.uid}`} value={isNational() ? offer.nationalCostVariance : offer.regionalCostVariance} className={styles.dollarAmount} />
                </div>
              </td>
              {!isNational() && (
                <>
                  <td className={styles.penTableSpacer}></td>
                  {!isSETUser() && <td className={styles.penData}>{offer.nationalActualUnits}</td>}
                  {natPnv && !isSETUser() && <td className={styles.penData}>{offer.nationalActualCost}</td>}
                  {natCost && !isSETUser() && <td className={styles.penData}>{offer.nationalActualCost}</td>}
                  {!isSETUser() && (
                    <td className={styles.penData}>
                      <div className={styles.dollarContainer}>
                        <DollarDisplay id={`rec-nat-cost-var-${offer.uid}`} value={offer.nationalCostVariance} className={styles.dollarAmount} />
                      </div>
                    </td>
                  )}
                </>
              )}
            </tr>
          ))}

          <tr className={styles.totalRow}>
            <td className={styles.seriesOffer}>Total</td>
            <td>{srs.totalForecastedSales}</td>
            <td>{srs.totalProjectedPenRate}%</td>
            <td>
              <div className={styles.dollarContainer}>
                <DollarDisplay id={`rec-proj-pnv-${srs.uid}`} value={srs.totalEstimatedCost} className={styles.dollarAmount} />
              </div>
            </td>
            <td>
              <div className={styles.dollarContainer}>
                <DollarDisplay id={`rec-proj-cost-${srs.uid}`} value={srs.totalOfferCost} className={styles.dollarAmount} />
              </div>
            </td>

            <td className={styles.penTableSpacer}></td>

            <td>{srs.totalNvsUnits}</td>
            <td>
              <div className={styles.dollarContainer}>
                <DollarDisplay id={`rec-ttl-nvs-proj-cost-${srs.uid}`} value={srs.totalNvsOfferCost} className={styles.dollarAmount} />
              </div>
            </td>
            <td className={styles.penTableSpacer}></td>
            <td>{isNational() ? srs.totalNationalActualUnits : srs.totalRegionalActualUnits}</td>
            <td>{isNational() ? srs.totalNationalActualPenetration : srs.totalRegionalActualPenetration}%</td>
            <td>
              <div className={styles.dollarContainer}>
                <DollarDisplay
                  id={`rec-actl-ttl-pnv-${srs.uid}`}
                  value={isNational() ? srs.totalNationalActualCosts : srs.totalRegionalActualCosts}
                  className={styles.dollarAmount}
                />
              </div>
            </td>
            <td>
              <div className={styles.dollarContainer}>
                <DollarDisplay
                  id={`rec-actl-ttl-cost-${srs.uid}`}
                  value={isNational() ? srs.totalNationalActualOfferCosts : srs.totalRegionalActualOfferCosts}
                  className={styles.dollarAmount}
                />
              </div>
            </td>
            <td className={styles.penTableSpacer}></td>
            <td>
              <div className={styles.dollarContainer}>
                <DollarDisplay id={`rec-var-ttl-${srs.uid}`} value={isNational() ? srs.nationalCostVarianceTotal : srs.regionalCostVarianceTotal} className={styles.dollarAmount} />
              </div>
            </td>
            {!isNational() && (
              <>
                <td className={styles.penTableSpacer}></td>
                {!isSETUser() && <td>{srs.totalNationalActualUnits}</td>}
                {natPnv && !isSETUser() && <td>{srs.totalNationalActualCosts}</td>}
                {natCost && !isSETUser() && <td>{srs.totalNationalActualOfferCosts}</td>}
                {!isSETUser() && (
                  <td>
                    <div className={styles.dollarContainer}>
                      <DollarDisplay id={`rec-var-ttl-${srs.uid}`} value={srs.nationalCostVarianceTotal} className={styles.dollarAmount} />
                    </div>
                  </td>
                )}
              </>
            )}
          </tr>
        </tbody>
      </table>
      {srs.invalidSummarySeriesDetail && (
        <div className={styles.recError}>
          <OATIcon icon="warning" colorTheme="red" />
          <span>Please review all highlighted fields for your series profile.</span>
        </div>
      )}
      <div className={styles.recCtaRow}>
        {!(isNational() || isSETUser()) && (
          <Button variant="primary" onClick={() => reconcileData.copyNationalActuals(srs, isNational())}>
            Copy National Actuals
          </Button>
        )}
        <Button variant="primary" onClick={() => onSubmit(srs)}>
          Save
        </Button>
      </div>
    </li>
  );
};

export default observer(Series);
