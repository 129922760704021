import LeaseFormModel from '../../../../models/LeaseFormModel';

const showResetToNationalLease = (leaseForm: LeaseFormModel, defaultTfsShare: Number) => {
  // Non-GST Advertised logic
  if (
    (leaseForm.inputs.isAdvertised && Number(leaseForm.inputs.rcf) !== Number(leaseForm.inputs.nationalRcf)) ||
    Number(leaseForm.inputs.subventionCash) !== Number(leaseForm.inputs.nationalSubventionCash) ||
    Number(leaseForm.inputs.tfsShare) !== defaultTfsShare ||
    Number(leaseForm.inputs.mileage) !== Number(leaseForm.inputs.nationalMileage) ||
    Number(leaseForm.inputs.estimatedCost) !== 0 ||
    Number(leaseForm.inputs.additionalCashAmount) !== 0 ||
    leaseForm.inputs.isAddtlCashAppliedToDueAtSigning
  ) {
    return true;
  }

  // Non Advertised logic
  if (
    (!leaseForm.inputs.isAdvertised && Number(leaseForm.inputs.rcf) !== Number(leaseForm.inputs.nationalRcf)) ||
    Number(leaseForm.inputs.subventionCash) !== Number(leaseForm.inputs.nationalSubventionCash) ||
    Number(leaseForm.inputs.tfsShare) !== defaultTfsShare ||
    Number(leaseForm.inputs.estimatedCost) !== 0
  ) {
    return true;
  }

  return false;
};

export default showResetToNationalLease;
