import { observer } from 'mobx-react-lite';
import { Dropdown, DropdownItem } from 'oat-common-ui';
import { IpNationalReports, IpRegionalReports } from '../../../../constants/global';
import { Offering } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import ReportsList from '../ReportsList';

const IpList = () => {
  const {
    userInfoStore: { isNational },
    reportsStore: { availableIpList, selectedIp, setSelectedIp, availableRegions, selectedRegion, setSelectedRegion },
  } = useStores();

  const createIpListForDropdown = () => {
    const list: { value: string; label: string }[] = [];
    availableIpList.forEach(ip => list.push({ value: ip.id, label: `${ip.name}${ip.isAtc ? ' ATC' : ''}` }));

    return list;
  };

  const handleIpChange = (ipItem: DropdownItem) => {
    const findSelectedIp = availableIpList.find(ip => ip.id === ipItem.value);
    setSelectedIp(findSelectedIp as Offering);
  };

  const handleSetSelectedRegion = (region: DropdownItem) => {
    setSelectedRegion(region);
  };

  return (
    <>
      <Dropdown
        id="reports-ip-dropdown"
        options={createIpListForDropdown()}
        value={`${selectedIp.name}${selectedIp.isAtc ? ' ATC' : ''}`}
        onChange={value => {
          handleIpChange(value);
        }}
        darkTheme
      />
      {isNational() && <ReportsList reports={IpNationalReports} />}
      {isNational() && <Dropdown id="reports-region-dropdown" options={availableRegions} value={selectedRegion} onChange={handleSetSelectedRegion} darkTheme />}
      <ReportsList reports={IpRegionalReports} />
    </>
  );
};

export default observer(IpList);
