import { formatDollarsCents } from 'oat-common-ui';
import AprTermModel from '../../../../../../models/AprTermModel';
import AprTierModel from '../../../../../../models/AprTierModel';
import styles from './styles.module.scss';

interface Props {
  aprTerms: AprTermModel[];
  selectedTierModel: AprTierModel;
}

const PnvsTooltipMessage = ({ aprTerms, selectedTierModel }: Props) => {
  return (
    <>
      <ul className={styles.pvnsList}>
        {aprTerms.map(term => {
          // term est cost is not persisted. Need to call again
          const { estCost } = term.getEstimatedCosts();
          return (
            <li key={term.fields.term}>
              <span className={styles.term}>
                {term.fields.term !== 24 && <span>+</span>} {`Weighted Cost PNVS (Term ${term.fields.term})`}
              </span>
              <span className={styles.balance}>${formatDollarsCents(estCost)}</span>
            </li>
          );
        })}
      </ul>
      <div className={styles.summaryWrapper}>
        <div className={styles.summary}>
          <span className={styles.term}>Weighted Cost PNVS</span>
          <span className={styles.balance}>${formatDollarsCents(selectedTierModel.fields.estCost)}</span>
        </div>
      </div>
    </>
  );
};

export default PnvsTooltipMessage;
