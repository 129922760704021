import { observer } from 'mobx-react-lite';
import { Button, OATIcon } from 'oat-common-ui';
import { Vehicle } from '../../../../../../../gql/generated';
import BlockText from '../../../../../../ProgramDetails/components/BlockText';
import PenRateModel from '../../../../../models/PenRateModel';
import styles from './styles.module.scss';
import { handleVehiclesRender } from './utils';

interface Props {
  model: PenRateModel;
  excludedVehicles: Vehicle[];
  includedVehicles: Vehicle[];
}

const VehiclesList = ({ model, excludedVehicles, includedVehicles }: Props) => {
  const { setShowExcluded, showExcluded } = model;
  const vehiclesToRender = !showExcluded ? excludedVehicles : includedVehicles;

  return (
    <div className={styles.vehiclesListContainer}>
      <BlockText
        component={
          <Button id={`${model.id}-vehicle-exc-inc-btn`} onClick={() => setShowExcluded()} variant="text" className={styles.excIncBtn}>
            <OATIcon icon="switch" colorTheme="blue" className={styles.switchIcon} />
            <span className={styles.expTheadTd}>{!showExcluded ? 'Excludes' : 'Includes'}</span>
          </Button>
        }
        bottomText={handleVehiclesRender(vehiclesToRender, model.id)}
      />
    </div>
  );
};

export default observer(VehiclesList);
