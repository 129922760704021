import { OfferTypes } from 'oat-common-ui';
import { ReactNode } from 'react';
import PenRateModel from '../../../../../models/PenRateModel';
import NotExpTableValues from '../NotExpTableValues';
import AprTableValues from './AprTableValues';

interface Props {
  aprModels: PenRateModel[] | undefined;
  showDates: (model: PenRateModel) => ReactNode | undefined;
}

const AprRow = ({ aprModels, showDates }: Props) => {
  return (
    <>
      {aprModels?.map((aprItem, i) => {
        return (
          <tr key={`${OfferTypes.APR}-${aprItem.id}-${i}`}>
            <NotExpTableValues model={aprItem} />
            <AprTableValues aprModel={aprItem} showDates={showDates} />
          </tr>
        );
      })}
    </>
  );
};

export default AprRow;
