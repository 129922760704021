import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  val: string | number | ReactNode;
}

const ExpTableHeadVal = ({ val }: Props) => {
  return <td className={clsx(styles.expTheadTd, styles.expTableTd)}>{val}</td>;
};

export default ExpTableHeadVal;
