import getDateParts from '../../../utils/getDateParts';
import getMonthNameFromDate from '../../../utils/getMonthNameFromDate';
import styles from './styles.module.scss';

interface Props {
  startDate: string;
  endDate: string;
}

const SubHeaderDates = ({ startDate, endDate }: Props) => {
  return (
    <div className={styles.subHeaderDates}>
      <div>
        <p className={styles.month}>{getMonthNameFromDate(startDate, true)}</p>
        <p className={styles.day}>{getDateParts(startDate).day}</p>
      </div>
      <div className={styles.separator}>
        <p>-</p>
      </div>
      <div>
        <p className={styles.month}>{getMonthNameFromDate(endDate, true)}</p>
        <p className={styles.day}>{getDateParts(endDate).day}</p>
      </div>
    </div>
  );
};

export default SubHeaderDates;
