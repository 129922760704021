import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants, FeatureFlags } from 'oat-admin-common';
import { BlockInputLabel, Dropdown, InlineInputLabel, Input } from 'oat-common-ui';
import NumberInput from '../../../../../components/NumberInput';
import RadioInput from '../../../../../components/RadioInput';
import TextArea from '../../../../../components/TextArea';
import { FEATURE_OR_5454_PHASE_2, GiftDelivery, Status } from '../../../../../constants/global';
import useStores from '../../../../../stores/useStores';
import { getDropdownItemFromEnum } from '../../../../../utils/getDropdownItemFromEnum';
import MiscCardModel from '../../../models/MiscCardModel';
import styles from '../styles.module.scss';
import useMiscOfferCard from '../useMiscOfferCard';
import InputTable from './InputTable';
import ReturningSeriesSection from './ReturningSeriesSection';

type Props = {
  card: MiscCardModel;
  onChange: () => void;
  forCreate?: boolean;
};

const AdditionalMiscCardFields = ({ card, onChange, forCreate = false }: Props) => {
  const { programDetailsStore } = useStores();
  const { fields, updateField, isStandAlone, isDuplicate, hasTargetedAudienceError } = card;
  const { isGroupTwo } = useMiscOfferCard(card);
  const { CASH_TYPES } = AdminConstants;

  const isDisabled = programDetailsStore.offering.status === Status.MEETS_GUIDELINES || card.isDisabled;
  const readOnlyTargetedAudience =
    FeatureFlags.FEATURE_OR_3782 &&
    !isStandAlone &&
    !isDuplicate &&
    (fields.type === (FEATURE_OR_5454_PHASE_2 ? CASH_TYPES.COLLEGE : CASH_TYPES.COLLEGE_GRADUATE) ||
      fields.type === CASH_TYPES.MILITARY ||
      fields.type === CASH_TYPES.LOYALTY ||
      fields.type === CASH_TYPES.TARGETED_CERTIFICATE ||
      fields.type === CASH_TYPES.CAMPAIGN ||
      fields.type === CASH_TYPES.CONQUEST);

  return (
    <>
      {fields.type === CASH_TYPES.CLOSING_CASH && (
        <BlockInputLabel label="Max. no. of Certificates" className={cx(styles.marginTop30, styles.width400)} labelClass={styles.inputLabel}>
          <NumberInput
            dark={!forCreate}
            error={!fields.maxNoOfCertificates}
            className={styles.numberInput}
            value={fields.maxNoOfCertificates}
            onValueChange={e => {
              updateField('maxNoOfCertificates', Number(e.value));
              onChange();
            }}
            disabled={isDisabled}
            units
          />
        </BlockInputLabel>
      )}
      {fields.type === CASH_TYPES.DOWN_PAYMENT_ASSISTANCE && (
        <BlockInputLabel label="Max Down Payment Amount" className={cx(styles.inputContainer, styles.marginTop70)} labelClass={styles.inputLabel}>
          <NumberInput
            dark={!forCreate}
            dollarSign
            error={!fields.maxDownPaymentAmount}
            className={styles.numberInput}
            value={fields.maxDownPaymentAmount}
            onValueChange={e => {
              updateField('maxDownPaymentAmount', Number(e.value));
              onChange();
            }}
            disabled={isDisabled}
            units
          />
        </BlockInputLabel>
      )}
      {(fields.type === CASH_TYPES.LOYALTY ||
        fields.type === CASH_TYPES.LEASE_LOYALTY ||
        fields.type === CASH_TYPES.TARGETED_CERTIFICATE ||
        fields.type === CASH_TYPES.CAMPAIGN ||
        fields.type === CASH_TYPES.CONQUEST ||
        fields.type === CASH_TYPES.MILITARY ||
        fields.type === (FEATURE_OR_5454_PHASE_2 ? CASH_TYPES.COLLEGE : CASH_TYPES.COLLEGE_GRADUATE)) && (
        <>
          <BlockInputLabel label="Targeted Audience" className={cx(styles.inputContainer, styles.marginTop70, styles.targetedAudienceInput)} labelClass={styles.inputLabel}>
            <TextArea
              darkTheme={!forCreate}
              disabled={isDisabled || readOnlyTargetedAudience}
              error={hasTargetedAudienceError}
              onChange={e => {
                updateField('targetedAudience', e.currentTarget.value);
                onChange();
              }}
              required={hasTargetedAudienceError}
              value={fields.targetedAudience}
            />
          </BlockInputLabel>
          {fields.type === CASH_TYPES.CAMPAIGN && (
            <div className={cx(styles.marginTop30, styles.displayFlex)}>
              <BlockInputLabel label="Impacted Brand" className={cx(styles.inlineInput, styles.flexBasis50)} labelClass={styles.inputLabel}>
                <Input
                  darkTheme={!forCreate}
                  required
                  error={!fields.impactedBrand}
                  value={fields.impactedBrand}
                  onChange={e => {
                    updateField('impactedBrand', e.currentTarget.value);
                    onChange();
                  }}
                  disabled={isDisabled}
                />
              </BlockInputLabel>
              <BlockInputLabel label="Impacted Model" className={styles.flexBasis50} labelClass={styles.inputLabel}>
                <Input
                  darkTheme={!forCreate}
                  required
                  error={!fields.impactedModel}
                  value={fields.impactedModel}
                  onChange={e => {
                    updateField('impactedModel', e.currentTarget.value);
                    onChange();
                  }}
                  disabled={isDisabled}
                />
              </BlockInputLabel>
            </div>
          )}
          {fields.type !== CASH_TYPES.CONQUEST && (
            <InlineInputLabel label="Transferable" contentClass={styles.radioBtnWrapper} className={styles.inputLabel} inputWrapperClass={styles.marginTop30}>
              <RadioInput
                id={`misc-transferable-${card.id}`}
                checked={fields.transferable}
                disabled={isDisabled || !card.isStandAlone}
                labelClass={styles.radioBtn}
                onClick={() => {
                  isStandAlone && updateField('transferable', !fields.transferable);
                  onChange();
                }}
                onChange={() => {}}
              />
              <span className={styles.radioBtnText}>Yes</span>
            </InlineInputLabel>
          )}
        </>
      )}
      {(fields.type === CASH_TYPES.COMPLIMENTARY_FIRST_PAYMENT || fields.type === CASH_TYPES.PAYMENT_WAIVER) && (
        <>
          <div className={cx(styles.marginTop70, styles.displayFlex)}>
            <BlockInputLabel label="No. of months" className={cx(styles.inlineInput, styles.flexBasis50)} labelClass={styles.inputLabel}>
              <NumberInput
                dark={!forCreate}
                required
                error={!fields.noOfMonths}
                className={styles.numberInput}
                value={fields.noOfMonths}
                onValueChange={e => {
                  updateField('noOfMonths', Number(e.value));
                  onChange();
                }}
                disabled={isDisabled}
              />
            </BlockInputLabel>
            <BlockInputLabel label="Max $ amount per month" className={cx(styles.inlineInput, styles.inputContainer)} labelClass={styles.inputLabel}>
              <NumberInput
                dark={!forCreate}
                dollarSign
                required
                error={!fields.maxDollarAmountPerMonth}
                className={styles.numberInput}
                value={fields.maxDollarAmountPerMonth}
                onValueChange={(e, src) => {
                  if (src.source === 'event') {
                    updateField('maxDollarAmountPerMonth', Number(e.value));
                    onChange();
                  }
                }}
                disabled={isDisabled}
                units
              />
            </BlockInputLabel>
          </div>
          {fields.type === CASH_TYPES.PAYMENT_WAIVER && <ReturningSeriesSection card={card} onChange={onChange} forCreate={forCreate} />}
        </>
      )}
      {(fields.type === CASH_TYPES.TRADE_IN_ASSISTANCE || fields.type === CASH_TYPES.CONQUEST) && (
        <div className={styles.marginTop30}>
          <InputTable card={card} forCreate={forCreate} onChange={onChange} />
        </div>
      )}
      {fields.type === CASH_TYPES.NON_CASH_CERTIFICATE && (
        <div className={styles.marginTop30}>
          <BlockInputLabel label="Gift Delivery" labelClass={styles.inputLabel}>
            <Dropdown
              darkTheme={!forCreate}
              minWidth={250}
              id="gift-delivery"
              value={fields.giftDelivery}
              error={!fields.giftDelivery}
              options={getDropdownItemFromEnum(GiftDelivery)}
              onChange={item => {
                updateField('giftDelivery', item.value);
                onChange();
              }}
              disabled={isDisabled}
            />
          </BlockInputLabel>
        </div>
      )}
      {(isGroupTwo || fields.type === CASH_TYPES.VENDOR_PURCHASE_PROGRAM) && (
        <div className={cx(styles.marginTop70, styles.displayFlex)}>
          {fields.type === CASH_TYPES.VENDOR_PURCHASE_PROGRAM && (
            <BlockInputLabel label="Vendor Codes" className={cx(styles.inlineInput, styles.flexBasis50)} labelClass={styles.inputLabel}>
              <Input
                darkTheme={!forCreate}
                required
                error={!fields.vendorCodes}
                value={fields.vendorCodes}
                onChange={e => {
                  updateField('vendorCodes', e.currentTarget.value);
                  onChange();
                }}
                disabled={isDisabled}
              />
            </BlockInputLabel>
          )}
          <BlockInputLabel label="Description" className={styles.flexBasis50} labelClass={styles.inputLabel}>
            <TextArea
              darkTheme={!forCreate}
              required
              error={!fields.description}
              id="misc-offer-description"
              value={fields.description}
              onChange={e => {
                updateField('description', e.currentTarget.value);
                onChange();
              }}
              disabled={isDisabled}
            />
          </BlockInputLabel>
        </div>
      )}
    </>
  );
};

export default observer(AdditionalMiscCardFields);
