import { AdminConstants, AdminModels, getAdjustedResidualRate, LeaseCalcFns } from 'oat-admin-common';
import { LeaseDetails, LeaseRates, ResidualValue } from '../../../../../gql/generated';
import CreateLeaseStore from '../../../../../stores/CreateLeaseStore';
import { assignNumberValue } from '../../../../../utils/assignValue';
import getDefaultAcFee from '../../../../../utils/getDefaultAcFee';
import { Fields } from '../../models/CreateAdLeaseModel';

const { DEFAULT_DOWN_PAYMENT } = AdminConstants;

// used for saving examples
export const getLeaseDetailsValues = (leaseDetails: LeaseDetails, leaseTier: LeaseRates) => {
  const {
    acquisitionFee,
    baseMsrp,
    dealerCostPerInvoice,
    dealerGross,
    downPayment,
    dueAtSigning,
    enhancedResidualAmount,
    enhancedResidualRate,
    financialReserve,
    grossCapCost,
    holdback,
    netCapCost,
    residualAmount,
    residualRate,
    subventionCash,
    targetPayment,
    totalMsrp,
    totalDealerGross,
    estimatedCost,
    configuration,
    offerCost,
    isAdvertised,
    modelCode,
    modelYear,
    highTerm,
    vin,
  } = leaseDetails;

  return {
    acquisitionFee,
    baseMsrp,
    dealerCostPerInvoice,
    dealerGross,
    downPayment,
    dueAtSigning,
    enhancedResidualAmount,
    enhancedResidualRate,
    financialReserve,
    grossCapCost,
    holdback,
    netCapCost,
    residualAmount,
    residualRate,
    subventionCash,
    targetPayment,
    totalMsrp,
    totalDealerGross,
    estimatedCost,
    configuration,
    offerCost,
    isAdvertised,
    modelCode,
    modelYear,
    vin,
    term: highTerm,
    regionalSubventionRCF: leaseTier.subventedRcf,
  };
};

export const getPayloadCalcs = (
  createLeaseStore: CreateLeaseStore,
  fields: Fields,
  residuals: ResidualValue[],
  term: number,
  dealerGross: number,
  enhancedTfsCostShare?: AdminModels.EnhancedTfsCostShare.EnhancedTfsCostShare,
  subCash?: number,
  dueAtSigning?: number,
  nationalRcf?: number,
  rcf?: number,
  enhancedResidualRate?: number,
  mileage?: string,
  downPayment?: number,
  netCapCost?: number,
  adjustmentFactor?: number,
  tfsShare?: number,
  defaultTfsShare?: number,
  rv?: number,
) => {
  const { advertisedData, offering, defaultMileage } = createLeaseStore;
  const { vin, trim } = fields;

  const adjustedResiduals: ResidualValue[] = getAdjustedResidualRate(mileage ?? defaultMileage.toString(), residuals);

  const totalMsrp = Number(vin?.msrp);
  const baseMsrp = Number(vin?.baseMsrp);
  const residualRate = rv
    ? rv
    : adjustedResiduals.filter(
        residual => residual.term === term && residual.modelCode === trim?.code.toString() && residual.seriesYear.toString() === advertisedData.seriesYear,
      )[0]?.rv || 0;

  const residualAmount = Math.round(LeaseCalcFns.calculateResidualAmount(totalMsrp, residualRate));
  const acquisitionFee = getDefaultAcFee(offering.brand, offering.regionCode);
  const dealerCostPerInvoice = Number(vin?.dealerInvoice ?? 0);
  const grossCapCost = Math.round(LeaseCalcFns.calculateGrossCapCost(dealerCostPerInvoice, dealerGross));
  const dap = assignNumberValue(Math.round(LeaseCalcFns.calculateDAP(baseMsrp)), 0);
  const holdback = assignNumberValue(vin?.holdback, 0);
  const financialReserve = assignNumberValue(vin?.financeReserve, 0);
  const totalDealerGross = Math.round(LeaseCalcFns.calculateTotalDealerGrossProceeds(dealerGross, dap, holdback, financialReserve));
  const subventionCash = subCash ?? 0;
  const rcfValue = rcf ?? createLeaseStore.nationalRcf;

  let baseValues = LeaseCalcFns.getBaseValues({
    downPayment: downPayment ?? DEFAULT_DOWN_PAYMENT,
    term,
    dealerGross: Number(dealerGross),
    holdback,
    dap,
    financeReserve: financialReserve,
    dealerCostPerInvoice: Number(vin?.dealerInvoice ?? 0),
    totalDealerGross: LeaseCalcFns.calculateTotalDealerGrossProceeds(dealerGross, dap, holdback, financialReserve),
    grossCapCost,
    nationalRcf: nationalRcf ?? createLeaseStore.nationalRcf, //standardrcf
    rcf: Number((rcfValue - Number(enhancedTfsCostShare?.value ?? 0)).toFixed(5)),
    dueAtSigning: dueAtSigning ?? 0,
    subventionCash,
    residualAmount,
    acquisitionFee,
    netCapCost,
    tfsShare,
    defaultTfsShare,
    adjustmentFactor,
  });

  const totalResidualRate = LeaseCalcFns.calculateTotalResidualRate(residualRate, assignNumberValue(enhancedResidualRate));
  const enhancedResidualAmount = Math.round(LeaseCalcFns.calculateEnhancedResidualAmount(totalResidualRate, Number(vin?.msrp)));

  baseValues.targetPayment = Math.round(
    LeaseCalcFns.calculateDefaultTargetPayment(
      baseValues.dealerCostPerInvoice,
      baseValues.dealerGross,
      baseValues.downPayment,
      baseValues.subventionCash,
      baseValues.residualAmount,
      baseValues.term,
      baseValues.rcf,
    ),
  );
  baseValues.dueAtSigning = Math.round(LeaseCalcFns.calculateDueAtSigning(baseValues));

  baseValues = LeaseCalcFns.postCalculation(baseValues);

  return {
    acquisitionFee,
    baseMsrp,
    dealerCostPerInvoice,
    dealerGross: Math.round(baseValues.dealerGross),
    downPayment: baseValues.downPayment,
    dueAtSigning: baseValues.dueAtSigning,
    enhancedResidualAmount,
    enhancedResidualRate: assignNumberValue(enhancedResidualRate),
    financialReserve,
    grossCapCost,
    holdback,
    netCapCost: Math.round(baseValues.netCapCost),
    residualAmount,
    residualRate,
    regionalSubventionRCF: baseValues.rcf,
    subventionCash: baseValues.subventionCash,
    targetPayment: Math.round(baseValues.targetPayment),
    totalMsrp,
    totalDealerGross,
    estimatedCost: baseValues.estCost,
    tfsCost: baseValues.tfsEstCost,
    enhTfsCost: baseValues.enhTfsEstCost,
  };
};

export default getPayloadCalcs;
