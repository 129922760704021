import { observer } from 'mobx-react-lite';
import { BlockInputLabel } from 'oat-common-ui';
import NumberInput from '../../../../../../components/NumberInput';
import FsvCosts from '../../../../RegionalSummary/FsvCosts';
import RgnlAltModel from '../../../../models/RgnlAltModel';
import styles from './styles.module.scss';

interface Props {
  selectedRa: RgnlAltModel | undefined;
  inputIsDisabled: boolean;
}

const ExpSummaryTableHeader = ({ selectedRa, inputIsDisabled }: Props) => {
  return (
    <section key={selectedRa?.id} className={styles.expandedSummary}>
      <div className={styles.flexLeft}>
        <BlockInputLabel labelComponent={<span className={styles.topText}>Forecasted Sales</span>}>
          <NumberInput disabled={inputIsDisabled} onChange={e => selectedRa?.updateFsv(Number(e.target.value))} value={selectedRa?.fsv} units />
        </BlockInputLabel>
        <BlockInputLabel className={styles.blockInputLeft} labelComponent={<span className={styles.topText}>Additional Region RYO Earnings</span>}>
          <span className={styles.ryoEarnings}>${selectedRa?.ryoEarnings}</span>
        </BlockInputLabel>
      </div>
      <FsvCosts rgnlAlt={selectedRa as RgnlAltModel} showOnlyDollarDisplay />
    </section>
  );
};

export default observer(ExpSummaryTableHeader);
