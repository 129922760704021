import { AdminConstants } from 'oat-admin-common';
import { defaultProperties } from '../constants/brandProperties';
import { Brand } from '../constants/global';
import { User } from '../gql/generated';

const { REGIONS, TdaCodes, RegionCodes } = AdminConstants;

class UserInfoStore {
  isLoaded = false;
  properties = defaultProperties;
  userInfo: User = {
    azureGroups: '',
    brand: '',
    email: '',
    name: '',
    objectId: '',
    regionCodes: '110',
    roles: '',
    tdaCodes: '',
    userTypes: '',
  };

  getNationalCode = () => {
    return this.isLexus() ? '390' : '190';
  };

  getRegionInfo = () => {
    return REGIONS.find(item => item.code === this.userInfo.regionCodes);
  };

  getRegionNameFull = () => {
    return `${this.getRegionInfo()?.name || ''} ${this.isLexus() ? 'Area' : 'Region'}`;
  };

  getRegionCode = () => {
    return this.getRegionInfo()?.code || this.getNationalCode();
  };

  getMarketingTeam = () => {
    return this.isLexus() ? 'LDA' : 'TDA';
  };

  getTFSRateLabel = () => {
    return this.isLexus() ? 'LFS' : 'TFS';
  };

  isNational = () => {
    return this.userInfo.regionCodes.indexOf(this.getNationalCode()) !== -1;
  };

  isLexus = () => {
    return this.userInfo.brand === Brand.LEXUS;
  };

  setUserInfo = (userInfo: User, isLoaded: boolean) => {
    this.userInfo = userInfo;
    this.isLoaded = isLoaded;
  };

  isSETUser = (user?: User) => {
    const userInfo = user || this.userInfo;
    return userInfo.userTypes.includes('Regional') && userInfo.regionCodes.includes(RegionCodes.SET);
  };

  isGst = (user?: User) => {
    const userInfo = user || this.userInfo;
    return userInfo.userTypes.includes('Regional') && userInfo.regionCodes.includes(RegionCodes.GST);
  };

  isSSNY = (tdaCode: string): boolean => {
    const ssnyCodes = [TdaCodes.PTL, TdaCodes.INW, TdaCodes.DVR, TdaCodes.CON, TdaCodes.GNY, TdaCodes.TRE, TdaCodes.UST, TdaCodes.CHI, TdaCodes.KAC, TdaCodes.TRI] as string[];
    return ssnyCodes.includes(tdaCode);
  };
}
export default UserInfoStore;
