import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import IpBreadCrumbs from '../../../../components/IpComponents/IpBreadCrumbs';
import { SeriesProfile, useGetSeriesProfilesByOfferingIdQuery } from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import ErrorPage from '../../../ErrorPage';

interface Props {
  children: ReactNode;
}

const FetchTfsCostShareAvailabilities = ({ children }: Props) => {
  const { profile, period } = useUrlParams();

  const {
    regionalCostShareStore: { setCostShareDates, costShareDatesMap },
  } = useStores();

  const { data, error } = useGetSeriesProfilesByOfferingIdQuery({ variables: { offeringId: period }, skip: !!costShareDatesMap.get(profile) });

  if (error) {
    return <ErrorPage breadCrumbs={IpBreadCrumbs(false)} />;
  }

  if (data) {
    setCostShareDates(data.seriesProfile as SeriesProfile[]);
  }

  return <>{children}</>;
};

export default observer(FetchTfsCostShareAvailabilities);
