import { DollarDisplay, formatAccounting, sortFieldsHelper } from 'oat-common-ui';
import { Labels, Status } from '../../constants/global';
import { Offering } from '../../gql/generated';
import { assignDollarCents, assignNumberValue } from '../../utils/assignValue';
import getDateParts from '../../utils/getDateParts';
import styles from './styles.module.scss';
import { getFiscalYear } from '../../utils/getFiscalYear';

export const renderPeriodBalance = (isSandBox: boolean, isReconcileComplete: boolean, periodBalance: number) => {
  if (isSandBox) {
    return '--';
  }

  return (
    <>
      <DollarDisplay value={formatAccounting(periodBalance, false)} />
      <span className={styles.balanceText}>{isReconcileComplete ? Labels.ACTUAL : Labels.ESTIMATED}</span>
    </>
  );
};

const calculateFyBalance = (offerings: Offering[]) => {
  return assignDollarCents(offerings.map(offering => processOffering(offering).balance).reduce((sum, balance) => sum + balance, 0));
};

export const processAllOfferings = (offerings: Offering[]) => {
  const currentFiscalYear = getFiscalYear();
  const prevFiscalYear = currentFiscalYear - 1;

  const processedOfferings = offerings
    .filter(offering => {
      const { year: startDateYear, month: startDateMonth } = getDateParts(offering.startDate);
      const isAtcDraft = offering.isAtc && offering.status === Status.NEW && offering.id.includes('atc');
      const datesAreValid = (Number(startDateYear) === prevFiscalYear && Number(startDateMonth) > 3) || (Number(startDateYear) === currentFiscalYear && Number(startDateMonth) < 4);

      if (!isAtcDraft && datesAreValid) {
        return true;
      }

      return false;
    })
    .slice()
    .sort(sortFieldsHelper('startDate', true));

  return { processedOfferings, fyBalance: calculateFyBalance(processedOfferings) };
};

export const processOffering = (offering: Offering) => {
  const isReconcileComplete = !!offering.isReconcileComplete;
  const offeringCosts = offering?.offeringCosts;
  const balance = assignNumberValue(!isReconcileComplete ? offeringCosts?.periodBalance : offeringCosts?.nationalReconciledPeriodBalance);

  return { isReconcileComplete, balance };
};

export const processActiveOfferings = (offerings: Offering[], isNational: boolean) => {
  return offerings.map(offering => {
    if (!isNational && offering.status === Status.NO_RESPONSE) {
      offering.status = Status.NEW;
    }

    return offering;
  });
};
