import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { BlockInputLabel, Dropdown, DropdownItem } from 'oat-common-ui';
import { PaymentMethod, PaymentToOptions, Status } from '../../../../../constants/global';
import useStores from '../../../../../stores/useStores';
import { getDropdownItemFromEnum } from '../../../../../utils/getDropdownItemFromEnum';
import { getDefaultPaymentMethod } from '../../../../../utils/getMiscOfferFieldsDefaultValue';
import MiscCardModel from '../../../models/MiscCardModel';
import styles from '../styles.module.scss';

interface Props {
  card: MiscCardModel;
  isShow: boolean;
  onChange: () => void;
  forCreate?: boolean;
}

const PaymentSection = ({ card, isShow = false, onChange, forCreate = false }: Props) => {
  const { programDetailsStore } = useStores();
  const {
    fields: { paymentTo, paymentMethod },
    updateField,
  } = card;

  const handlePaymentTo = (item: DropdownItem) => {
    updateField('paymentTo', item.value);
    updateField('paymentMethod', getDefaultPaymentMethod(item.value));
    onChange();
  };

  const isDisabled = programDetailsStore.offering.status === Status.MEETS_GUIDELINES || card.isDisabled;

  return isShow ? (
    <div className={cx(styles.marginTop30, styles.displayFlex)}>
      <BlockInputLabel label="Payment To" className={cx(styles.inlineInput, styles.flexBasis50)} labelClass={styles.inputLabel}>
        <Dropdown
          darkTheme={!forCreate}
          minWidth={250}
          id="payment-to"
          value={paymentTo}
          options={getDropdownItemFromEnum(PaymentToOptions)}
          onChange={handlePaymentTo}
          disabled={isDisabled}
        />
      </BlockInputLabel>
      <BlockInputLabel label="Payment Method" className={styles.flexBasis50} labelClass={styles.inputLabel}>
        <Dropdown
          darkTheme={!forCreate}
          minWidth={250}
          id="payment-method"
          value={paymentMethod}
          options={getDropdownItemFromEnum(PaymentMethod)}
          onChange={item => {
            updateField('paymentMethod', item.value);
            onChange();
          }}
          disabled={paymentTo === PaymentToOptions.CUSTOMER_ONLY || paymentTo === PaymentToOptions.TFS_ONLY || isDisabled}
        />
      </BlockInputLabel>
    </div>
  ) : (
    <></>
  );
};

export default observer(PaymentSection);
