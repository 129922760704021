import { observer } from 'mobx-react-lite';
import { AdminConstants, getDefaultTfsShare } from 'oat-admin-common';
import { DefaultModal, OATIcon, dateStringToDate, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import SettingsMenu from '../../../../../../components/SettingsMenu';
import { Status } from '../../../../../../constants/global';
import { LeaseOfferDetails, Offer, useRemoveLeaseExampleMutation } from '../../../../../../gql/generated';
import useUrlParams from '../../../../../../hooks/useUrlParams';
import useStores from '../../../../../../stores/useStores';
import { assignStringValue } from '../../../../../../utils/assignValue';
import getDefaultAcFee from '../../../../../../utils/getDefaultAcFee';
import LeaseCardModel from '../../../../models/LeaseCardModel';
import LeaseFormModel from '../../../../models/LeaseFormModel';
import LeaseTermModel from '../../../../models/LeaseTermModel';
import AprLeaseEnRModal from '../../../EnhanceAndRefineModal/AprLeaseEnRModal';
import PenRatesModal from '../../../PenRatesModal';
import RemoveOfferModal from '../../../RemoveOfferModal';
import ResetToNationalModal from '../../../ResetToNationalModal';
import ResetToPrevRegionalModal from '../../../ResetToPrevRegionalModal';
import {
  ItemAdjustPenRates,
  ItemAdvertised,
  ItemEnhanceAndRefine,
  ItemRemoveOffer,
  ItemResetPrevRegionalOffer,
  ItemResetToNational,
  ItemTfsContracts,
} from '../../../contextMenuItems';
import LeaseExamplesModal from '../LeaseExamplesModal';
import LeaseAdvertisedModal from './LeaseAdvertisedModal';
import LeaseEditSubCashModal from './LeaseEditSubCashModal';
import showResetToNationalLease from './showResetToNational';
import EditTiersAndTermsModal from '../../../EditTiersAndTermsModal';
import useUserInfo from '../../../../../../utils/useUserInfo';
import { createUniqueItems } from '../../../AprCard/utils';

const adLabel = 'Make Lease Advertised';
const nonAdLabel = 'Make Lease Non-Advertised';
interface Props {
  leaseCard: LeaseCardModel;
  leaseTerm: LeaseTermModel;
  leaseForm: LeaseFormModel;
  onSave: (skipDelay?: boolean, payload?: LeaseOfferDetails[]) => void;
}

const { OPTION_TYPE_NAMES } = AdminConstants;

const LeaseContextMenu = ({ leaseCard, leaseTerm, leaseForm, onSave }: Props) => {
  const { region } = useUrlParams();
  const {
    userInfoStore: {
      userInfo: { brand },
    },
    programDetailsStore,
    rcfsResidualsStore: { residuals },
  } = useStores();
  const { isLexusUser } = useUserInfo();
  const [showResetToNationalModal, setShowResetToNationalModal] = useState(false);
  const [showResetPreviousModal, setShowResetPreviousModal] = useState(false);
  const [showAdvertisedModal, setShowAdvertisedModal] = useState(false);
  const [showRemoveOfferModal, setShowRemoveOfferModal] = useState(false);
  const [showEditSubCashModal, setShowEditSubCashModal] = useState(false);
  const [showPenRatesModal, setShowPenRatesModal] = useState(false);
  const [showLeaseExampleModal, setShowLeaseExampleModal] = useState(false);
  const [showEnRModal, setShowEnRModal] = useState(false);
  const [showRemoveExampleModal, setShowRemoveExampleModal] = useState(false);
  const [showEditTiersAndTerms, setShowEditTiersAndTerms] = useState(false);
  const [removeLeaseExample] = useRemoveLeaseExampleMutation();
  const parentOfferCard = programDetailsStore.offerCards.lease.find(offer => offer.id === leaseCard.parentId);

  // EnR helper flags
  const [offerId, setOfferId] = useState(leaseCard.id);
  const [term, setTerm] = useState(leaseForm.inputs.term);

  const { error } = useToast();

  // same logic for reset previous
  const handleResetToNational = (leaseOffer: Offer) => {
    try {
      const leasePayloads = leaseCard.resetToNational(leaseOffer, residuals, region, programDetailsStore.offering);
      if (leasePayloads.length > 0) {
        onSave(false, leasePayloads);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleResetToPreviousOffer = (leaseOffer: Offer) => {
    leaseCard.updateLeasesFromResponse({ regional: leaseOffer, national: { ...leaseOffer, leaseDetails: leaseCard.nationalLeases } });
  };

  const handleToggleSubCashAdvertised = () => {
    leaseForm.updateInput('isSubCashAdvertised', !leaseForm.inputs.isSubCashAdvertised);
    onSave();
  };

  const handleToggleAcFee = () => {
    leaseForm.toggleAcquisitionFee(getDefaultAcFee(brand, region));
    onSave();
  };

  const handleToggleApplyAdditionalCash = () => {
    leaseForm.toggleApplyAdditionalCash();
    onSave();
  };

  const handleRemoveExample = async () => {
    try {
      const res = await trackPromise(
        removeLeaseExample({ variables: { input: { offerId: leaseCard.id, rev: leaseCard.rev, name: leaseForm.inputs.name, term: leaseForm.inputs.term } } }),
      );

      leaseTerm.removeExample();
      leaseCard.updateRev(res.data?.removeLeaseExample.offer.rev);
    } catch (e) {
      error((e as Error).message);
    } finally {
      setShowRemoveExampleModal(false);
    }
  };

  const updateLeasesAndHideEdit = (updatedOffer: Offer) => {
    leaseCard.setLeases({ regional: updatedOffer }, region, isLexusUser());
    setShowEditTiersAndTerms(false);
  };

  return (
    <>
      {/* Context Menu */}
      <SettingsMenu isDisabled={programDetailsStore.offering.status === Status.MEETS_GUIDELINES || leaseCard.isDisabled}>
        {/* Reset to National */}
        {!leaseForm.isExample &&
          !leaseForm.inputs.isStandalone &&
          showResetToNationalLease(
            leaseForm,
            getDefaultTfsShare(OPTION_TYPE_NAMES.LEASE, region, leaseForm.inputs.term, !!programDetailsStore.offering.useOldCostShareForLexus),
          ) && <ItemResetToNational onClick={() => setShowResetToNationalModal(true)} />}

        {/* Reset to Previous */}
        {!leaseForm.isExample && <ItemResetPrevRegionalOffer onClick={() => setShowResetPreviousModal(true)} />}

        {leaseForm.inputs.isAdvertised && (
          <li onClick={() => setShowLeaseExampleModal(true)}>
            <OATIcon icon="duplicate" />
            Add Lease Example
          </li>
        )}

        {leaseCard.isDuplicate && <ItemTfsContracts icon='duplicate' onClick={() => setShowEditTiersAndTerms(true)} />}

        {/* EnR */}
        {!leaseCard.isDuplicate && !leaseForm.isExample && <ItemEnhanceAndRefine onClick={() => setShowEnRModal(true)} />}

        {/* Make advertised */}
        {!leaseForm.isExample && (
          <ItemAdvertised advertised={leaseForm.inputs.isAdvertised} adLabel={adLabel} nonAdLabel={nonAdLabel} onClick={() => setShowAdvertisedModal(true)} />
        )}

        {/* Toggle Ac Fee */}
        {leaseForm.inputs.isAdvertised && (
          <li onClick={handleToggleAcFee}>
            <OATIcon icon="dollar_amount" />
            {leaseForm.inputs.acquisitionFee === 0 ? 'Apply' : 'Remove'} Acquisition Fee
          </li>
        )}

        {/* Edit Sub Cash */}
        {!leaseForm.isExample && (
          <li onClick={() => setShowEditSubCashModal(true)}>
            <OATIcon icon="dollar_amount" />
            Edit Subvention Cash
          </li>
        )}

        {/* Toggle Sub Cash Advertised */}
        {!leaseForm.isExample && Number(leaseForm.inputs.subventionCash) > 0 && (
          <li onClick={handleToggleSubCashAdvertised}>
            <OATIcon icon={leaseForm.inputs.isSubCashAdvertised ? 'eye-blocked' : 'eye'} />
            Make Subvention Cash {leaseForm.inputs.isSubCashAdvertised ? 'Non-Advertised' : 'Advertised'}
          </li>
        )}

        {/* Adjust Pen Rates */}
        <ItemAdjustPenRates
          onClick={() => {
            setShowPenRatesModal(true);
            setOfferId(leaseCard.id);
            setTerm(leaseForm.inputs.term);
          }}
        />

        {/* Apply Additional Cash to NCC/DAS */}
        {!leaseForm.isExample && leaseForm.inputs.isAdvertised && (
          <li onClick={handleToggleApplyAdditionalCash}>
            <OATIcon icon="dollar_amount" />
            {leaseForm.inputs.isAddtlCashAppliedToDueAtSigning ? 'Apply Additional Cash to Net Cap Cost' : 'Apply Additional Cash to Due at Signing'}
          </li>
        )}

        {/* Remove Offer */}
        {!leaseForm.isExample && (leaseForm.inputs.isStandalone || leaseCard.isDuplicate) && <ItemRemoveOffer onClick={() => setShowRemoveOfferModal(true)} />}

        {/* Remove Example */}
        {leaseForm.isExample && (
          <li onClick={() => setShowRemoveExampleModal(true)}>
            <OATIcon icon="trash" />
            Remove Current Lease Example
          </li>
        )}
      </SettingsMenu>

      {/* Modals */}
      {showResetToNationalModal && (
        <ResetToNationalModal id={leaseCard.id} rev={leaseCard.rev} onClose={() => setShowResetToNationalModal(false)} onSubmit={handleResetToNational} />
      )}

      {showResetPreviousModal && (
        <ResetToPrevRegionalModal id={leaseCard.id} rev={leaseCard.rev} onClose={() => setShowResetPreviousModal(false)} onSubmit={handleResetToPreviousOffer} />
      )}

      {showEditSubCashModal && <LeaseEditSubCashModal leaseCard={leaseCard} leaseForm={leaseForm} onClose={() => setShowEditSubCashModal(false)} onSave={onSave} />}

      {showPenRatesModal && <PenRatesModal currentOfferId={`${offerId}#${term}`} scrollTo={`lease-${offerId}`} onClose={() => setShowPenRatesModal(false)} />}

      {showAdvertisedModal && (
        <LeaseAdvertisedModal nonAdLabel={nonAdLabel} adLabel={adLabel} leaseCard={leaseCard} leaseForm={leaseForm} onClose={() => setShowAdvertisedModal(false)} />
      )}

      {showLeaseExampleModal && <LeaseExamplesModal leaseCard={leaseCard} leaseTerm={leaseTerm} leaseForm={leaseForm} onClose={() => setShowLeaseExampleModal(false)} />}

      {showEditTiersAndTerms && (
        <EditTiersAndTermsModal
          id={leaseCard.id}
          rev={leaseCard.rev}
          onSubmit={updateLeasesAndHideEdit}
          onClose={() => setShowEditTiersAndTerms(false)}
          tiers={leaseForm.inputs.rates.map(item => item.tier)}
          terms={leaseCard.terms.map(item => item.toString())}
          termOptions={createUniqueItems([...(parentOfferCard?.leaseTerms ?? []), ...leaseCard.leaseTerms], 'term')}
          tierOptions={createUniqueItems(leaseForm.nationalDetails?.rates ?? [], 'tier')}
        />
      )}

      {showEnRModal && (
        <AprLeaseEnRModal
          id={leaseCard.id}
          rev={leaseCard.rev}
          parentNationalLeases={leaseCard.nationalLeases}
          onClose={() => setShowEnRModal(false)}
          startDate={leaseForm.inputs.startDate}
          endDate={leaseForm.inputs.endDate}
          minDate={dateStringToDate(leaseForm.inputs.startDate)}
          maxDate={dateStringToDate(leaseForm.inputs.endDate)}
          openPenRateModal={setShowPenRatesModal}
          vehicles={[...leaseCard.excludedVehicles, ...leaseCard.includedVehicles]}
          nationalVehicles={leaseCard.nationalVehicles}
          setOfferId={setOfferId}
          setTerm={setTerm}
          currentRcf={assignStringValue(leaseForm.inputs.rcf)}
          tiers={leaseForm.inputs.rates}
          terms={leaseCard.terms.map(item => item.toString())}
          isInclusions={leaseCard.isInclusions}
          toggleIsInclusions={leaseCard.toggleIsInclusions}
        />
      )}

      {showRemoveOfferModal && (
        <RemoveOfferModal
          id={leaseCard.id}
          rev={leaseCard.rev}
          term={leaseForm.inputs.term}
          onClose={() => {
            setShowRemoveOfferModal(false);
          }}
          onSubmit={(_id, rev) => {
            programDetailsStore.removeLeaseOffer(leaseCard.id, rev ?? '', leaseForm.inputs.term);
          }}
        />
      )}

      {showRemoveExampleModal && (
        <DefaultModal
          open
          title="Remove Current Example"
          message="Are you sure you want to remove this Lease Example?"
          onClose={() => setShowRemoveExampleModal(false)}
          onSubmit={handleRemoveExample}
        />
      )}
    </>
  );
};

export default observer(LeaseContextMenu);
