import { AdminConstants, AdminModels, getCompatibilityForOfferType } from 'oat-admin-common';

export const getCompatibilityListByOfferType = (offerType: AdminConstants.OPTION_TYPE_NAMES): AdminModels.Compatibility => {
  return (
    getCompatibilityForOfferType(offerType) || {
      defaultTypes: [],
      incompatibleTypes: [],
      optionalTypes: [],
    }
  );
};
