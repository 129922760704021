import { observer } from 'mobx-react-lite';
import { BreadCrumbType, DefaultTable, OATWrapper, sortFieldsHelper } from 'oat-common-ui';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import MainFooter from '../../components/MainFooter';
import MainHeader from '../../components/MainHeader';
import { Status } from '../../constants/global';
import { Offering } from '../../gql/generated';
import useStores from '../../stores/useStores';
import CountsTable from './components/CountsTable';
import RegionStatusContainer from './components/RegionStatusContainer';
import RegionStatusRow from './components/RegionStatusRow';
import TableWrapper from './components/TableWrapper';
import { regionStatusColumns } from './regionStatusColumns';
import { getStatusCounts } from './utils';

const RegionStatusComponent = () => {
  const {
    regionalStatusStore: { regionalStatuses },
  } = useStores();
  const [sortField, setSortField] = useState('sortOrderByStatus');
  const [sortAsc, setSortAsc] = useState(true);
  const counts = getStatusCounts(regionalStatuses);

  const renderRow = (regions: Offering[]) => {
    return (
      <>
        {regions.map(item => {
          return <RegionStatusRow key={item.id} item={item} />;
        })}
      </>
    );
  };

  const onSort = (field: string, asc: boolean) => {
    setSortField(field);
    setSortAsc(asc);
  };

  const includedStatus = [Status.NO_RESPONSE, Status.REVISED, Status.SUBMITTED, Status.NEEDS_REVISIONS, Status.NEW, Status.ATC_SUBMITTED, Status.ATC_DRAFT];
  const regionsList = regionalStatuses
    .slice()
    .filter(rs => {
      return (rs.isAtc && includedStatus.includes(rs.status as Status)) || !rs.isAtc;
      })
    .sort(sortFieldsHelper(sortField, sortAsc));

  const offeringName = regionalStatuses?.length ? regionalStatuses[0].name : '';

  const breadCrumbs: BreadCrumbType[] = [
    {
      name: '',
      component: <Link to={`/dashboard`}>Dashboard</Link>,
    },
    {
      name: `${offeringName}`,
    },
  ];

  return (
    <>
      <MainHeader headerTitle={offeringName} breadCrumbs={breadCrumbs} />
      <OATWrapper>
        <RegionStatusContainer>
          <TableWrapper tableTitle="INCENTIVE PERIOD STATUS">
            <DefaultTable
              id={`incentive-period-status-${offeringName}`}
              columns={regionStatusColumns}
              renderRows={renderRow(regionsList)}
              onSort={onSort}
              defaultSortField={'sortOrderByStatus'}
              defaultSortAscending={sortAsc}
            />
          </TableWrapper>
          <CountsTable counts={counts} />
        </RegionStatusContainer>
      </OATWrapper>
      <MainFooter />
    </>
  );
};

export default observer(RegionStatusComponent);
