import { AdminConstants } from 'oat-admin-common';
import { DropdownItem } from 'oat-common-ui';
import { FEATURE_OR_2947_PHASE_2, FEATURE_OR_5454_PHASE_2, GiftDelivery, PaymentMethod, PaymentToOptions, ReturningBrands, SalesCodes, SystemToPay } from '../constants/global';

type DropdownNameType =
  | typeof PaymentToOptions
  | typeof PaymentMethod
  | typeof SystemToPay
  | typeof ReturningBrands
  | typeof SalesCodes
  | typeof GiftDelivery
  | typeof AdminConstants.CASH_TYPES
  | typeof AdminConstants.STANDARD_ODD_TERMS;

const filterEntries = (name: DropdownNameType, excludeTypes: string[]) => {
  return Object.entries(name).filter(entry => !excludeTypes.includes(entry[1]));
};

const processName = (name: DropdownNameType) => {
  const excludeTypes: string[] = [];

  if (FEATURE_OR_5454_PHASE_2) {
    excludeTypes.push(AdminConstants.CASH_TYPES.COLLEGE_GRADUATE);
  } else {
    excludeTypes.push(AdminConstants.CASH_TYPES.COLLEGE);
  }

  if (FEATURE_OR_2947_PHASE_2) {
    excludeTypes.push(AdminConstants.CASH_TYPES.TFS_APR_CASH);
    excludeTypes.push(AdminConstants.CASH_TYPES.DEALER_TFS_APR_SUBVENTION_CASH);
  } else {
    excludeTypes.push(AdminConstants.CASH_TYPES.TFS_FINANCE_CASH);
    excludeTypes.push(AdminConstants.CASH_TYPES.DEALER_TFS_FINANCE_SUBVENTION_CASH);
  }

  const filteredEntries = filterEntries(name, excludeTypes);

  return filteredEntries.reduce((acc, curr) => {
    return { ...acc, [curr[0]]: curr[1] };
  }, {}) as DropdownNameType;
};

export const getDropdownItemFromEnum = (name: DropdownNameType) => {
  const newItems: DropdownItem[] = [];
  Object.values(processName(name)).forEach(value => newItems.push({ label: value.toString(), value: value.toString() }));

  return newItems;
};
