import { Status } from '../../../constants/global';
import StatusItem from './StatusItem';
import styles from './styles.module.scss';

interface Props {
  counts: Record<string, number>;
}

const CountsTable = ({ counts }: Props) => {
  return (
    <div className={styles.countsTable}>
      <h3>Status Overview</h3>
      <div>
        <StatusItem statusClass={styles.revised} status={Status.REVISED} count={counts[Status.REVISED]} subText="Ready for Review" />
        <StatusItem statusClass={styles.submitted} status={Status.SUBMITTED} count={counts[Status.SUBMITTED]} subText="Ready for Review" />
        <StatusItem statusClass={styles.needsRevisions} status={Status.NEEDS_REVISIONS} count={counts[Status.NEEDS_REVISIONS]} subText="Awaiting on updates from the region" />
        <StatusItem statusClass={styles.noResponse} status={Status.NO_RESPONSE} count={counts[Status.NO_RESPONSE]} subText="Region has yet to submit" />
        <StatusItem statusClass={styles.meetsGuidelines} status={Status.MEETS_GUIDELINES} count={counts[Status.MEETS_GUIDELINES]} />
        <StatusItem status={Status.PUBLISH_IN_PROGRESS} count={counts[Status.PUBLISH_IN_PROGRESS]} />
      </div>
    </div>
  );
};

export default CountsTable;
