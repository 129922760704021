import { observer } from 'mobx-react-lite';
import { OfferTypes } from 'oat-common-ui';
import { ReactNode } from 'react';
import PenRateModel from '../../../../../models/PenRateModel';
import styles from '../styles.module.scss';
import CashDetailsRow from './CashDetailsRow';
import CashHeader from './CashHeader';
import CashValue from './CashValue';

interface Props {
  cashModels: PenRateModel[] | undefined;
  inputIsDisabled: boolean;
  showDates: (model: PenRateModel) => ReactNode;
  onSave?: () => void;
}

const ExpCashRow = ({ cashModels, inputIsDisabled, showDates, onSave }: Props) => {
  return (
    <>
      {cashModels?.map((cashItem, i) => {
        return (
          <section key={`${OfferTypes.CUSTOMER_CASH}-${cashItem.id}-${i}`} className={styles.containerList}>
            <CashHeader cashModel={cashItem} showDates={showDates} />
            <CashValue cashModel={cashItem} />
            <CashDetailsRow cashModel={cashItem} inputIsDisabled={inputIsDisabled} onSave={onSave} />
          </section>
        );
      })}
    </>
  );
};

export default observer(ExpCashRow);
