import { AdminConstants } from 'oat-admin-common';
import { dateStringToDate } from 'oat-common-ui';
import createAccordionFromVehicles from '../../../components/Accordion/utils/createAccordionFromVehicles';
import { CombinedOffers, RgnlAlt, SeriesMapping, SeriesProfile } from '../../../gql/generated';
import { assignBooleanValue, assignDollarCents, assignNumberValue, assignStringValue } from '../../../utils/assignValue';
import getDisabledVehicles from '../../../utils/getDisabledVehicles';
import { getIncludedExcludedVehicles } from '../../../utils/getIncludedExcludedVehicles';
import { getAllStatesForRegion } from '../../ProgramDetails/components/LeaseCard/components/VehicleInfo/utils';
import CashCardModel from '../../ProgramDetails/models/CashCardModel';
import { getCompatibilityListByOfferType } from './getCompatibilityListByOfferType';

/**
 * Creates Cash Card Model for Program Details and Create Offer
 * @param defaultStartDate
 * @param defaultEndDate
 * @param offers
 * @param seriesProfile
 * @returns
 */
export const createCashCardForCreate = (defaultStartDate: string, defaultEndDate: string, region: string,rgnlAlt: RgnlAlt, seriesProfile?: SeriesProfile): CashCardModel => {
  const card = new CashCardModel();

  const compatibilityList = getCompatibilityListByOfferType(AdminConstants.OPTION_TYPE_NAMES.CUSTOMER_CASH);
  const { includedVehicles, excludedVehicles } = getIncludedExcludedVehicles(seriesProfile?.vehicles);

  card.isStandAlone = true;
  card.fields.compatibility = compatibilityList;
  card.fields.defaultEndDate = dateStringToDate(defaultEndDate);
  card.fields.defaultStartDate = dateStringToDate(defaultStartDate);
  card.fields.excludedVehicles = excludedVehicles;
  card.fields.includedVehicles = includedVehicles;
  card.fields.includedStates = rgnlAlt.states ?? getAllStatesForRegion(region);

  card.fields.compatibileOffers = compatibilityList?.defaultTypes;
  card.fields.startDate = dateStringToDate(card.fields.defaultStartDate || '');
  card.fields.endDate = dateStringToDate(card.fields.defaultEndDate || '');
  card.fields.name = `${AdminConstants.OPTION_TYPE_NAMES.CUSTOMER_CASH}`;
  card.fields.vehicles = seriesProfile?.vehicles || [];

  return card;
};

export const createCashCard = (
  card: CashCardModel,
  seriesMapping: SeriesMapping[],
  offer?: CombinedOffers,
  seriesProfile?: SeriesProfile,
  defaultStartDate?: string,
  defaultEndDate?: string,
) => {
  const { OfferTypes } = AdminConstants;
  const disabledVehicles = getDisabledVehicles(seriesProfile?.vehicles || []);

  if (offer?.regional.cashDetails) {
    const { cashDetails, includedStates, excludedStates, id, rev, accessoryCodes, vehicles, contestNumber, contestNumberOfferId } = offer.regional;
    const { includedVehicles, excludedVehicles } = getIncludedExcludedVehicles(vehicles || seriesProfile?.vehicles);
    card.id = id;
    card.rev = rev;
    card.isAdvertised = assignBooleanValue(cashDetails.isAdvertised, true);
    card.fields.combinedPerUnitCost = assignStringValue(cashDetails.combinedPerUnitCost.toString());
    card.fields.includedStates = includedStates || [];
    card.fields.excludedStates = excludedStates || [];
    card.fields.note = cashDetails.note;
    card.fields.tdaNote = cashDetails.tdaNote;
    card.fields.modelNote = cashDetails.modelNote;
    card.fields.penetration = cashDetails.penetration.toString();
    card.fields.forecastedSales = assignNumberValue(cashDetails.forecastedSales);
    card.fields.startDate = dateStringToDate(cashDetails.startDate || '');
    card.fields.endDate = dateStringToDate(cashDetails.endDate || '');
    card.fields.estimatedCost = assignDollarCents(cashDetails.estimatedCost);
    card.fields.offerCost = assignDollarCents(cashDetails.offerCost);
    card.fields.offerEarnings = assignNumberValue(cashDetails.offerEarnings);
    card.fields.offerBalance = assignDollarCents(card.fields.offerEarnings - card.fields.offerCost);
    card.fields.accessoryCodes = assignStringValue(accessoryCodes);
    card.fields.isEnhanced = cashDetails.isEnhanced;
    card.fields.includedVehicles = includedVehicles;
    card.fields.excludedVehicles = excludedVehicles;
    card.fields.contestNumber = assignStringValue(contestNumber);
    card.fields.contestNumberOfferId = assignStringValue(contestNumberOfferId);
    card.fields.isValidEnR = assignBooleanValue(cashDetails.isValidEnR, true);
  }

  if (offer?.national?.cashDetails) {
    card.fields.defaultPerUnitCost = offer.national.cashDetails.combinedPerUnitCost;
    card.nationalOffer = offer.national;
  }

  card.fields.defaultStartDate = dateStringToDate(defaultStartDate || offer?.regional.cashDetails?.startDate || '');
  card.fields.defaultEndDate = dateStringToDate(defaultEndDate || offer?.regional.cashDetails?.endDate || '');
  card.isStandAlone = !Boolean(offer?.regional.nationalId);
  card.fields.accessoryCodes = assignStringValue(offer?.regional?.accessoryCodes);
  card.fields.isSpecialEdition = Boolean(offer?.regional?.isSpecialEdition);
  card.fields.specialEditionPackage = assignStringValue(offer?.regional?.specialEditionPackage);
  card.fields.name = offer?.regional.name || offer?.national?.name || OfferTypes.CUSTOMER_CASH;
  card.fields.vehicles = offer?.national?.vehicles || offer?.regional.vehicles || seriesProfile?.vehicles || [];
  card.fields.compatibileOffers = offer?.national?.compatibilityList || offer?.regional?.compatibilityList || [];
  card.fields.vehiclesAccordion.items = createAccordionFromVehicles(seriesProfile?.vehicles || [], seriesMapping, card.fields.isInclusions, disabledVehicles);
  card.isDuplicate = assignBooleanValue(offer?.regional.isDuplicate, false);
  card.parentId = assignStringValue(offer?.regional.parentId);

  return card;
};
