const dashboardColumns = [
  {
    header: 'NAME',
    field: 'name',
  },
  {
    header: 'START DATE',
    sortable: true,
    field: 'startDate',
    descendingFirst: true,
  },
  {
    header: 'END DATE',
    sortable: true,
    field: 'endDate',
    descendingFirst: true,
  },
  {
    header: 'Status',
    field: 'status',
  },
  {
    header: 'Balance',
    field: 'balance',
  },
  {
    header: 'Actions',
    field: 'actions',
  },
];

export const processDashboardColumns = (isNational: boolean, isActive: boolean) => {
  switch (true) {
    case isNational && isActive:
    case isNational && !isActive:
      return dashboardColumns.filter(column => column.field !== 'balance');
    case !isNational && !isActive:
      return dashboardColumns.filter(column => column.field !== 'actions');
    default:
      return dashboardColumns;
  }
};
