import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { useMemo } from 'react';
import { Status } from '../../../../../../constants/global';
import { LeaseOfferDetails } from '../../../../../../gql/generated';
import useStores from '../../../../../../stores/useStores';
import { handleEnhancedDates } from '../../../../../../utils/handleEnhancedDates';
import LeaseCardModel from '../../../../models/LeaseCardModel';
import LeaseFormModel from '../../../../models/LeaseFormModel';
import LeaseTermModel from '../../../../models/LeaseTermModel';
import { OfferCostTooltip, OfferEarningsTooltip } from '../../../offerCostTooltips';
import OfferHeader from '../../../OfferHeader';
import OfferHeaderCosts from '../../../OfferHeader/OfferHeaderCosts';
import { default as LeaseContextMenu } from './LeaseContextMenu';

const { OfferTypes } = AdminConstants;

const getSubTitle = (leaseForm: LeaseFormModel) => {
  const advertised = leaseForm.inputs.isAdvertised ? '' : ' (Non-Advertised)';
  let description = leaseForm.isExample ? ` (${leaseForm.inputs.name})` : advertised;
  const subCashAdvertised = leaseForm.inputs.isSubCashAdvertised ? '; Sub Cash (Advertised)' : '';
  description = `${description}${subCashAdvertised}`;

  if (leaseForm.inputs.isStandalone) {
    return `Stand Alone Offer${description}`;
  }

  return leaseForm.isEnhanced ? `Enhanced National Offer${description}` : `National Offer${description}`;
};

interface Props {
  leaseCard: LeaseCardModel;
  leaseTerm: LeaseTermModel;
  leaseForm: LeaseFormModel;
  onSave: (skip?: boolean, payload?: LeaseOfferDetails[]) => void;
}

const LeaseTitle = ({ leaseCard, leaseTerm, leaseForm, onSave }: Props) => {
  const {
    programDetailsStore,
    offeringsStore: { offering },
  } = useStores();

  const handleTitleChange = (value: string) => {
    leaseCard.updateName(value);
    onSave();
  };

  const masterLease = leaseTerm.getMaster();
  const isDisabled = programDetailsStore.offering.status === Status.MEETS_GUIDELINES || leaseCard.isDisabled;

  const enhancedDates = useMemo(() => {
    if (leaseCard.isDuplicate) {
      return handleEnhancedDates(leaseForm.inputs.startDate, leaseForm.inputs.endDate, offering.startDate, offering.endDate, false, false);
    }
  }, [leaseCard.isDuplicate, leaseForm.inputs.startDate, leaseForm.inputs.endDate, offering.endDate, offering.startDate]);

  return (
    <>
      <OfferHeader
        isTitleEditable={leaseForm.inputs.isStandalone && !isDisabled}
        offerType={OfferTypes.LEASE}
        title={`${leaseCard.name}`}
        titleSufix={`${leaseCard.term}mo`}
        offerSource={getSubTitle(leaseForm)}
        setTitle={handleTitleChange}
        nationalNote={leaseForm.nationalDetails?.note}
        enhancedDates={enhancedDates}
      >
        <OfferHeaderCosts
          id={leaseCard.id}
          earnings={masterLease.offerEarnings}
          earningsTooltip={
            <OfferEarningsTooltip forecastedSales={masterLease.inputs.forecastedSales} ryoEarnings={programDetailsStore.ryoEarnings} earnings={masterLease.offerEarnings} />
          }
          cost={masterLease.totalOfferCost}
          costTooltip={
            <OfferCostTooltip
              forecastedSales={masterLease.inputs.forecastedSales}
              estCost={masterLease.inputs.estimatedCost}
              subCashOfferCost={masterLease.inputs.subCashEstCost}
              cost={masterLease.totalOfferCost}
            />
          }
          balance={masterLease.offerBalance}
        />
        <LeaseContextMenu leaseCard={leaseCard} leaseTerm={leaseTerm} leaseForm={leaseForm} onSave={onSave} />
      </OfferHeader>
    </>
  );
};

export default observer(LeaseTitle);
