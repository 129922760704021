import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { LeaseRates, Maybe } from '../../../../../../../gql/generated';
import Rate from '../../../models/Rate';
import styles from './styles.module.scss';
import { formatValue } from '../../../../../../../components/NumberInput/utils';

type ViewRcfProps = {
  term: number;
  rates?: Rate[];
  tfsEnhancedRates?: Maybe<LeaseRates>[] | undefined;
  service: string;
};

const ViewRcfTooltipMessage = ({ term, rates, service, tfsEnhancedRates }: ViewRcfProps) => {
  return (
    <div className={styles.rcfPopoverContent}>
      <div className={styles.popoverTiers}>
        <header>
          <p>Valid for Leases of</p>
          <p>{term} months</p>
        </header>
        <article>
          <table className={styles.popoverTiersTable}>
            <tbody>
              {rates?.map(rate => (
                <tr key={rate?.tier}>
                  <td>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </td>
                  <td>Tier {rate?.tier}</td>
                  <td className={styles.popoverTiersRcfCell}>{formatValue(rate?.subventedRcf, true)}</td>
                </tr>
              ))}
              {tfsEnhancedRates?.map(rate => (
                <tr key={rate?.tier}>
                  <td>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </td>
                  <td>Tier {rate?.tier}</td>
                  <td className={styles.popoverTiersRcfCell}>{formatValue(rate?.standardRcf, true)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </article>
        <footer>
          <p>For all other rates, use</p>
          <p>{service} standards.</p>
        </footer>
      </div>
    </div>
  );
};

export default observer(ViewRcfTooltipMessage);
