import { AdminConstants } from 'oat-admin-common';
import { formatAccounting, formatDollars, formatDollarsCents } from 'oat-common-ui';
import { ReactNode } from 'react';
import useStores from '../stores/useStores';
import { assignDollarCents } from '../utils/assignValue';
import useGetTfsLabel from '../utils/useGetTfsLabel';
import TooltipList, { TooltipListItem } from './TooltipList/TooltipList';

const { OPTION_TYPE_NAMES } = AdminConstants;

export enum CostTooltipLabel {
  EST_EARNINGS = 'Est. Series Earnings',
  EST_COST = 'Est. Series Cost',
  EST_BALANCE = 'Est. Series Balance',
}

interface Props {
  rgnlAltId: string;
  breakdown?: ReactNode;
  hasBreakdownElement?: boolean;
}

interface EarningsProps extends Props {
  fsv: number;
  ryoEarnings: number;
  nationalRyo: number;
  additionalRyo: number;
  lumpSum: number;
}

export const EarningsTooltip = ({ rgnlAltId, fsv, ryoEarnings, nationalRyo, additionalRyo, lumpSum, breakdown }: EarningsProps) => {
  const { offeringCostsStore } = useStores();
  const rgnlAlt = offeringCostsStore.raCosts[rgnlAltId];
  const raEarnings = rgnlAlt?.raEarnings ?? 0;
  const remaining = assignDollarCents(raEarnings - (rgnlAlt?.offersEarnings ?? 0));

  const ryoMap = new Map<string, number>();
  if (nationalRyo) {
    ryoMap.set(OPTION_TYPE_NAMES.NATIONAL_RYO, nationalRyo);
  }

  if (additionalRyo) {
    ryoMap.set(OPTION_TYPE_NAMES.ADDITIONAL_RYO, additionalRyo);
  }

  ryoMap.set(OPTION_TYPE_NAMES.RYO_EARNINGS, ryoEarnings);

  const ryoArr = Array.from(ryoMap);

  return (
    <>
      <TooltipList>
        <TooltipListItem>
          <span>Forecasted Sales Volume</span>
          <span>{formatDollars(fsv)}</span>
        </TooltipListItem>

        {/* RYO Breakdown */}
        {ryoArr.map(([option, value], i) => {
          const index = i;
          return (
            // First item will have * to denote FSV * (ryo)
            <TooltipListItem key={index} symbol={i === 0 ? '*' : i <= ryoArr.length - 1 ? '+' : undefined}>
              {/* First item will be (National RYO */}
              {/* Last item will be RYO Earnings) */}
              {/* If only one item, it will be (RYO Earnings) */}
              <span>
                {i === 0 && '('}
                {option}
                {i === ryoArr.length - 1 && ')'}
              </span>
              <span>${formatDollars(value)}</span>
            </TooltipListItem>
          );
        })}
        {Boolean(lumpSum) && (
          <TooltipListItem symbol="+">
            <span>{OPTION_TYPE_NAMES.LUMPSUM_RYO}</span>
            <span>${formatDollars(lumpSum)}</span>
          </TooltipListItem>
        )}
      </TooltipList>

      <TooltipList divider>
        <TooltipListItem>
          <span>{CostTooltipLabel.EST_EARNINGS}</span>
          <span>${formatDollarsCents(raEarnings)}</span>
        </TooltipListItem>
      </TooltipList>

      <TooltipList divider>
        <TooltipListItem>
          <span>Earnings By Option</span>
        </TooltipListItem>
      </TooltipList>

      <TooltipList divider>
        {breakdown}
        <TooltipListItem>
          <span>Remaining</span>
          <span>{formatAccounting(remaining)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
};

export const CostTooltip = ({ rgnlAltId, breakdown, hasBreakdownElement }: Props) => {
  const { offeringCostsStore } = useStores();
  const rgnlAlt = offeringCostsStore.raCosts[rgnlAltId];

  return (
    <>
      <TooltipList>{breakdown}</TooltipList>
      <TooltipList divider={hasBreakdownElement}>
        <TooltipListItem>
          <span>{CostTooltipLabel.EST_COST}</span>
          <span>${formatDollarsCents(rgnlAlt?.raCost ?? 0)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
};

export const BalanceTooltip = ({ rgnlAltId, breakdown, hasBreakdownElement }: Props) => {
  const { offeringCostsStore } = useStores();
  const rgnlAlt = offeringCostsStore.raCosts[rgnlAltId];
  const balance = assignDollarCents((rgnlAlt?.raEarnings ?? 0) - (rgnlAlt?.raCost ?? 0));

  return (
    <>
      <TooltipList>{breakdown}</TooltipList>
      <TooltipList divider={hasBreakdownElement}>
        <TooltipListItem>
          <span>{CostTooltipLabel.EST_BALANCE}</span>
          <span>{formatAccounting(balance)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
};

interface TfsCostProps extends Props {
  enhanced: boolean;
}

export const TfsCostTooltip = ({ rgnlAltId, breakdown, enhanced }: TfsCostProps) => {
  const { offeringCostsStore } = useStores();
  const rgnlAlt = offeringCostsStore.raCosts[rgnlAltId];
  const cost = (enhanced ? rgnlAlt.raEnhTfsCost : rgnlAlt.raTfsCost) ?? 0;
  const tfsLabel = useGetTfsLabel();
  const tfsCostLabel = enhanced ? `Est. Series Enhanced ${tfsLabel} Cost` : `Est. Series ${tfsLabel} Cost`;

  return (
    <>
      <TooltipList>{breakdown}</TooltipList>
      <TooltipList divider>
        <TooltipListItem>
          <span>{tfsCostLabel}</span>
          <span>{formatAccounting(cost)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
};
