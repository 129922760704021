import { observer } from 'mobx-react-lite';
import NumberInput from '../../../../../components/NumberInput';
import { FEATURE_OR_2477 } from '../../../../../constants/global';
import useGetTfsLabel from '../../../../../utils/useGetTfsLabel';
import AprCardModel from '../../../models/AprCardModel';
import SubventionCashModel from '../SubventionCashModel';
import styles from './styles.module.scss';

type Props = {
  subventionCashModel: SubventionCashModel;
  aprCard: AprCardModel;
};

const SubventionCashInputs = ({ subventionCashModel, aprCard }: Props) => {
  const {
    fields: { subventionCash },
    getSubventionCashByHighTerm,
    lowHighTerms,
    setSubventionCash,
    updateSubventionCashInputField,
    subCashInputHasError,
  } = subventionCashModel;

  const tfsLabel = useGetTfsLabel();

  return (
    <>
      <div className={styles.inputsWrapper}>
        <div className={styles.labels}>
          <span className={styles.label}>Sub Cash {tfsLabel} Cost Share</span>
          <span className={styles.label}>Cap</span>
        </div>
        {lowHighTerms.map(term => (
          <div className={styles.inputColumn} key={term.highTerm}>
            <span className={styles.label}>
              {term.lowTerm}-{term.highTerm}
            </span>

            <div className={styles.inputParent}>
              <NumberInput
                allowNegative={false}
                id={`tfs-share-${term.highTerm}-${subventionCashModel.offerId}`}
                max={100}
                onValueChange={val => updateSubventionCashInputField('subCashTfsShare', Number(val.floatValue), term.highTerm)}
                percentageSign
                value={getSubventionCashByHighTerm(term.highTerm)?.subCashTfsShare}
                wholeNumber
                error={subCashInputHasError(term.highTerm, 'subCashTfsShare')}
              />
              {FEATURE_OR_2477 && (
                <span className={styles.inputLabel}>{aprCard.fields.subventionCashTfsCostShare ? `Enhanced: ${aprCard.fields.subventionCashTfsCostShare}%` : `Default: 0%`}</span>
              )}
            </div>
            <div className={styles.inputParent}>
              <NumberInput
                allowNegative={false}
                dollarSign
                id={`tfs-cap-${term.highTerm}-${subventionCashModel.offerId}`}
                onValueChange={val => updateSubventionCashInputField('subCashTfsCostShareCap', Number(val.floatValue), term.highTerm)}
                value={getSubventionCashByHighTerm(term.highTerm)?.subCashTfsCostShareCap}
                wholeNumber
                error={subCashInputHasError(term.highTerm, 'subCashTfsCostShareCap')}
                units
              />
              {FEATURE_OR_2477 && (
                <span className={styles.inputLabel}>
                  {aprCard.fields.subventionCashTfsCostShareCap ? `Enhanced: $${aprCard.fields.subventionCashTfsCostShareCap}` : `Default: $0`}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.amountWrapper}>
        <span className={styles.label}>Enter Amount</span>
        <NumberInput
          allowNegative={false}
          dollarSign
          id={`amount-${subventionCashModel.offerId}`}
          onValueChange={val => setSubventionCash(Number(val.floatValue))}
          value={subventionCash}
          wholeNumber
          error={subCashInputHasError(0, 'subventionCash')}
          units
        />
      </div>
    </>
  );
};

export default observer(SubventionCashInputs);
