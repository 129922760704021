import clsx from 'clsx';
import { OATIcon } from 'oat-common-ui';
import styles from './styles.module.scss';

interface Props {
  options: string[];
  id: string;
  selected: string[];
  setSelected: (items: string[]) => void;
  error?: string;
  disabled?: boolean;
  setLastSelected?: (item: string) => void;
}

const BoxSelector = ({ options, id, error, disabled, setSelected, selected, setLastSelected }: Props) => {
  const handleSelectedBox = (item: string) => {
    if (disabled) {
      return;
    }

    if (setLastSelected) {
      setLastSelected(item);
    }

    if (!selected.includes(item)) {
      setSelected([...selected, item].sort());
      return;
    }

    setSelected(selected.filter(selectedItem => selectedItem !== item));
  };

  return (
    <>
      <div className={styles.boxSelector} id={id}>
        {options.map((item, i) => {
          const selectedClass = selected.includes(item) ? styles.selectedBox : '';

          return (
            <div
              key={item}
              id={`${item}-${i}`}
              className={clsx(styles.boxItem, selectedClass, disabled && styles.disabled)}
              onClick={() => handleSelectedBox(item)}
              role="button"
              tabIndex={i}
            >
              <span className={styles.boxText}>{item}</span>
            </div>
          );
        })}
      </div>
      {error && (
        <div className={styles.errorContainer}>
          <OATIcon className={styles.errorIcon} icon="warning" size={14} />
          <span className={styles.errorText}>{error}</span>
        </div>
      )}
    </>
  );
};

export default BoxSelector;
