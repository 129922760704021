import { observer, useLocalObservable } from 'mobx-react-lite';
import { AdminModels } from 'oat-admin-common';
import { OPTION_TYPE_NAMES } from 'oat-admin-common/dist/types/constants';
import { Button } from 'oat-common-ui';
import { FC, useState } from 'react';
import AccordionModel from '../../../../components/Accordion/models/AccordionModel';
import createAccordionFromCompatibility from '../../../../components/Accordion/utils/createAccordionFromCompatibility';
import CompatibilityModal from '../CompatibilityModal';
import { getCompatabilityCount } from '../LeaseCard/components/VehicleInfo/utils';
import VehicleCompatibleInfo from '../VehicleCompatibleInfo';
import styles from '../VehicleCompatibleInfo/styles.module.scss';
import VehicleCompatibleTooltip from '../VehicleCompatibleTooltip';
import ListTooltip from '../VehicleCompatibleTooltip/ListTooltip';

type RenderModalProps = {
  compatibleOffers: string[];
  optionType: OPTION_TYPE_NAMES;
  onSubmit: (compatibility: string[]) => void;
  onClose: () => void;
};

// Helper component to reconstruct AccordionModel when modal opens. This supports compatiblity changing when misc types changes through dropdown.
const RenderModal = observer(({ compatibleOffers, optionType, onClose, onSubmit }: RenderModalProps) => {
  const { items, toggleItem, toggleAll } = useLocalObservable(() => new AccordionModel(createAccordionFromCompatibility(compatibleOffers, optionType)));

  return (
    <CompatibilityModal id="offerComp" items={items} compatibileOffers={compatibleOffers} onClose={onClose} onSubmit={onSubmit} toggleAll={toggleAll} toggleItem={toggleItem} />
  );
});

type Props = {
  compatibileOffers: string[];
  compatibility: AdminModels.Compatibility | undefined;
  optionType: OPTION_TYPE_NAMES;
  toggleCompatibileOffer: (offerName: string) => void;
  onSubmit: (compatibility: string[]) => void;
  className?: string;
  disabled?: boolean;
  isEditable?: boolean;
  id?: string;
};

const OfferCompatibleOffers: FC<Props> = ({ compatibileOffers, optionType, className, onSubmit, disabled, isEditable, id }) => {
  const [openModal, setOpenModal] = useState(false);

  const getLabel = getCompatabilityCount(compatibileOffers);

  const getTooltipMessage = () => {
    const width = `${Math.ceil(compatibileOffers?.length / 12) * 255}px`; // We want to show 12 offers per column

    return (
      <ListTooltip width={width}>
        {compatibileOffers.map(offer => (
          <li key={offer}>{offer}</li>
        ))}
      </ListTooltip>
    );
  };

  return (
    <>
      <VehicleCompatibleInfo
        title="Compatible Offers"
        content={
          <VehicleCompatibleTooltip id={`compatible-offers-${id}`} className={className} message={getTooltipMessage()} disable={compatibileOffers?.length === 0}>
            {isEditable && !disabled ? (
              <Button variant="text" onClick={() => setOpenModal(!openModal)} className={styles.openModalBtn} id="open-modal-btn">
                {getLabel}
              </Button>
            ) : (
              <p>{getLabel}</p>
            )}
          </VehicleCompatibleTooltip>
        }
        isStandalone={isEditable}
      />
      {openModal && <RenderModal compatibleOffers={compatibileOffers} optionType={optionType} onClose={() => setOpenModal(false)} onSubmit={onSubmit} />}
    </>
  );
};

export default observer(OfferCompatibleOffers);
