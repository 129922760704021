import { AdminModels } from 'oat-admin-common';
import mapVehicleDataFromAccordion from '../../../../../components/Accordion/utils/mapVehicleDataFromAccordion';
import { CreateLeaseOfferInput, ResidualValue, SaveVehicle } from '../../../../../gql/generated';
import CreateLeaseStore from '../../../../../stores/CreateLeaseStore';
import { Fields } from '../../models/CreateAdLeaseModel';
import getPayloadCalcs from './getPayloadCalcs';

export const transformCreateLeasePayload = (
  createLeaseStore: CreateLeaseStore,
  fields: Fields,
  regalt: string,
  residuals: ResidualValue[],
  dealerGross: number,
  enhCostShare?: AdminModels.EnhancedCostShare.EnhancedLeaseCostShare,
  enhTfsCostShare?: AdminModels.EnhancedTfsCostShare.EnhancedTfsCostShare,
  isEnhCostShareAccepted?: boolean,
  isEnhTfsCostShareAccepted?: boolean,
): CreateLeaseOfferInput => {
  const { advertisedData, vehicles, offering, compatibileOffers, setTerm, defaultMileage } = createLeaseStore;
  const { vin, trim, config, selectedTermsList, terms, selectedTiers, blended, vehiclesAccordion, isInclusions, name, pnv, note, tdaNote, isSpecialEdition } = fields;

  const selectedMaxTerm = Math.max(
    ...selectedTermsList.map(item => {
      return Number(item);
    }),
  );
  const defaultTerm = selectedMaxTerm ?? 36;
  setTerm(defaultTerm);

  const [excludedList, includedList] = mapVehicleDataFromAccordion(vehiclesAccordion.items, vehicles, !isInclusions);
  const vehiclesList: SaveVehicle[] = [...excludedList, ...includedList];

  const { estimatedCost, enhTfsCost, tfsCost, ...calcs } = getPayloadCalcs(createLeaseStore, fields, residuals, defaultTerm, dealerGross, enhTfsCostShare);

  return {
    ...calcs,
    advertisedTerms: terms.filter(term => term.isAdvertised).map(term => Number(term.term)),
    compatibilityList: [...compatibileOffers],
    configuration: config?.configFull,
    endDate: offering.endDate,
    isAdvertised: true,
    isCapitalized: false,
    isSpecialEdition,
    mileage: defaultMileage,
    modelCode: Number(trim?.code),
    modelYear: Number(advertisedData.seriesYear),
    name,
    note,
    optionTierType: blended ? 'Blended' : 'ByTier',
    pnv,
    port: vin?.port,
    rgnlAltId: regalt,
    series: advertisedData.series,
    startDate: offering.startDate,
    tdaFee: Number(vin?.tdaFee),
    tdaNote,
    terms: terms.map(term => Number(term.term)),
    tiers: selectedTiers,
    vehicleDescription: trim?.seriesTitle,
    vehicles: vehiclesList,
    vin: vin?.vin,
    isEligibleForEnhCostShare: !!enhCostShare,
    enhCostShareOfferId: enhCostShare?.offerId,
    isEnhCostShareAccepted: isEnhCostShareAccepted ?? undefined,
    isEligibleForEnhTfsCostShare: !!enhTfsCostShare,
    enhTfsCostShareId: enhTfsCostShare?.id,
    isEnhTfsCostShareAccepted: isEnhTfsCostShareAccepted ?? undefined,
  };
};

export default transformCreateLeasePayload;
