import clsx from 'clsx';
import { AdminConstants } from 'oat-admin-common';
import { ReactNode } from 'react';
import PenRateModel from '../../../../../models/PenRateModel';
import ExpHeader from '../ExpHeader';
import styles from '../ExpHeader/styles.module.scss';

interface Props {
  cashModel: PenRateModel;
  showDates: (model: PenRateModel) => ReactNode;
}

const CashHeader = ({ cashModel, showDates }: Props) => {
  const { OfferTypes } = AdminConstants;

  return <ExpHeader name={OfferTypes.CUSTOMER_CASH} showDates={showDates(cashModel)} headerStyles={clsx(styles.cashName, styles.cashHeader)} dividerStyle={styles.cashDivider} />;
};

export default CashHeader;
