import { observer } from 'mobx-react-lite';
import { Dropdown, DropdownItem } from 'oat-common-ui';
import { useMemo } from 'react';
import { ADDITIONAL_CASH, Status } from '../../../../../constants/global';
import useStores from '../../../../../stores/useStores';
import CashCardModel from '../../../models/CashCardModel';
import LeaseFormModel from '../../../models/LeaseFormModel';
import LeaseTermModel from '../../../models/LeaseTermModel';
import MiscCardModel from '../../../models/MiscCardModel';
import { LeaseInputLabel, LeaseInputWrapper } from '../components/LeaseInput';
import LeaseCardModel from '../../../models/LeaseCardModel';

interface Props {
  leaseTerm: LeaseTermModel;
  leaseForm: LeaseFormModel;
  leaseCard: LeaseCardModel;
  cashOffers: CashCardModel[];
  miscOffers: MiscCardModel[];
  onChange: () => void;
}

const LeaseAdditionalCashDD = ({ leaseCard, leaseTerm, leaseForm, cashOffers, miscOffers, onChange }: Props) => {
  const { programDetailsStore } = useStores();

  const options: DropdownItem[] = useMemo(() => {
    const rcf = leaseForm.areTfsEnhRatesUsed ? leaseForm.inputs.enhancedStdRcf : leaseForm.inputs.standardRcf;
    const compatibleCash: DropdownItem[] =
      leaseForm.inputs.rcf === rcf
        ? cashOffers.map(item => ({
            value: item.id,
            label: item.fields.name,
          }))
        : [];
    const compatibleMisc = miscOffers
      .filter(misc => misc.fields.compatibileOffers.includes('Lease'))
      .map(item => ({
        value: item.id,
        label: item.fields.name,
      }));

    return [{ ...ADDITIONAL_CASH[0] }, ...compatibleCash, ...compatibleMisc];
  }, [cashOffers, miscOffers, leaseForm.inputs.rcf, leaseForm.inputs.standardRcf, leaseForm.areTfsEnhRatesUsed, leaseForm.inputs.enhancedStdRcf]);

  const handleAdditionalCash = (item: DropdownItem) => {
    const found = [...cashOffers, ...miscOffers].find(offer => offer.id === item.value);
    leaseForm.updateAdditionalCash(found);
    leaseTerm.syncMasterAndExamples();
    leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
    onChange();
  };

  return (
    <LeaseInputWrapper>
      <LeaseInputLabel>Additional Cash Offer</LeaseInputLabel>
      <Dropdown
        id={`additional-cash-${leaseForm.uid}`}
        value={leaseForm.inputs.additionalCashOptionType}
        options={options}
        onChange={handleAdditionalCash}
        disabled={leaseForm.isExample || programDetailsStore.offering.status === Status.MEETS_GUIDELINES || leaseCard.isDisabled}
      />
    </LeaseInputWrapper>
  );
};

export default observer(LeaseAdditionalCashDD);
