import { CreateLeaseModels } from 'oat-common-ui';

const getConfigListFromVinItem = (data: CreateLeaseModels.VinItem) => {
  const configList: CreateLeaseModels.ConfigItem[] = [];

  configList.push({
    config: data.accessories,
    configFull: data.accessoriesListFull,
    optionsTotal: data.optionlistmsrp,
    count: 1,
    inventory: 0,
    modelCode: data.modelCode,
    vinItems: [],
  });

  return configList;
};

export default getConfigListFromVinItem;
