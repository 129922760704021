import { formatDollars } from 'oat-common-ui';
import BlockText from '../../../BlockText';
import Divider from '../Divider';
import Dollar from '../Dollar';
import styles from './styles.module.scss';

interface Props {
  targetPayment: number | string;
  term: number | string;
  dueAtSigning: number | string;
}

const MonthInfo = ({ targetPayment, term, dueAtSigning }: Props) => {
  return (
    <div className={styles.monthsContainer}>
      <Dollar />
      <BlockText topText={formatDollars(targetPayment)} bottomText="PER MO" topTextClass={styles.topTextClass} bottomTextClass={styles.bottomTextClass} />
      <Divider />
      <BlockText topText={term.toString()} bottomText="MONTHS" topTextClass={styles.topTextClass} bottomTextClass={styles.bottomTextClass} className={styles.months} />
      <Divider />
      <Dollar />
      <BlockText topText={formatDollars(dueAtSigning)} bottomText="DUE AT SIGNING" topTextClass={styles.topTextClass} bottomTextClass={styles.bottomTextClass} />
    </div>
  );
};

export default MonthInfo;
