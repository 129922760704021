import { Button, IconNames, OATIcon, Tooltip } from 'oat-common-ui';
import styles from './styles.module.scss';

interface Props {
  id: string;
  message: string;
  iconName: IconNames;
  onClickHandler: () => void;
}

const TooltipButton = ({ id, message, iconName, onClickHandler }: Props) => {
  return (
    <div className={styles.tooltipWrapper}>
      <Tooltip id={id} message={message} className={styles.test}>
        <Button onClick={onClickHandler} className={styles.iconBtn}>
          <OATIcon icon={iconName} size={14} />
        </Button>
      </Tooltip>
    </div>
  );
};

export default TooltipButton;
