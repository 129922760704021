import { observer } from 'mobx-react-lite';
import PenRateModel from '../../../../models/PenRateModel';
import styles from './styles.module.scss';

interface Props {
  model: PenRateModel;
}

const NotExpTableValues = ({ model }: Props) => {
  const { forecastedSales, estimatedCost, offerCost, penetration } = model;

  return (
    <>
      <td className={styles.tableValue}>{forecastedSales}</td>
      <td className={styles.tableValue}>{estimatedCost}</td>
      <td className={styles.tableValue}>{offerCost}</td>
      <td className={styles.tableValue}>{penetration}</td>
    </>
  );
};

export default observer(NotExpTableValues);
