import { observer, useLocalObservable } from 'mobx-react-lite';
import { BlockInputLabel, Button, ButtonGroup, Checkbox, InlineInputLabel, Input, Modal, ModalBody, ModalFooter, ModalHeader, OATIcon, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import AccordionComponent from '../../../../../../components/Accordion/AccordionComponent';
import mapVehicleModelCodeFromAccordion from '../../../../../../components/Accordion/utils/mapVehicleModelCodeFromAccordion';
import { ModelCodes, Offering, SeriesMapping, useCreateStandaloneSeriesProfileMutation, useGetAddSeriesProfileDataQuery } from '../../../../../../gql/generated';
import useUrlParams from '../../../../../../hooks/useUrlParams';
import useStores from '../../../../../../stores/useStores';
import getDateParts from '../../../../../../utils/getDateParts';
import { getAllStatesForRegion } from '../../../../../ProgramDetails/components/LeaseCard/components/VehicleInfo/utils';
import AddSeriesProfileModel from '../../AddSeriesProfileModel';
import styles from './styles.module.scss';

interface Props {
  offering: Offering;
  onClose: () => void;
}

const AddSeriesProfileModal = ({ offering, onClose }: Props) => {
  const {
    userInfoStore: {
      userInfo: { brand },
    },
    summaryStore: { profiles },
  } = useStores();
  const { period, region } = useUrlParams();

  const state = useLocalObservable(() => new AddSeriesProfileModel());

  const { year: offeringYear, month: offeringMonth } = getDateParts(offering.startDate);
  const { data, loading, error } = useGetAddSeriesProfileDataQuery({
    variables: { brand, offeringYear, offeringMonth },
  });

  const [createStandaloneSeriesProfile] = useCreateStandaloneSeriesProfileMutation();
  const { error: toastError } = useToast();

  const handleSearchInputChange = (value: string) => {
    state.setSearchInput(value);
  };

  const handleOnStateCheck = (value: string) => {
    state.setStateCheck(value);
  };

  const handleSubmit = async () => {
    const vehicles = mapVehicleModelCodeFromAccordion(state.accordion.items, state.modelCodes);
    try {
      await trackPromise(
        createStandaloneSeriesProfile({
          variables: {
            input: {
              name: state.name,
              offeringId: period,
              states: state.states,
              vehicles,
            },
          },
        }),
      );
      window.location.reload();
      onClose();
    } catch (e) {
      toastError((e as Error).message);
    } finally {
      onClose();
    }
  };

  // init data
  if (!state.loaded && data) {
    state.setData(
      data.modelCodes as ModelCodes[],
      data.seriesMapping as SeriesMapping[],
      getAllStatesForRegion(region),
      profiles.map(item => item.name),
    );
  }

  return (
    <Modal isOpen onClose={onClose} className={styles.modalWrapper}>
      <ModalHeader onClose={onClose}>Add Series Profile</ModalHeader>
      <ModalBody className={styles.body}>
        {loading && 'Loading...'}
        {!loading && data && !error && (
          <div>
            <InlineInputLabel label="Series Profile Name">
              <Input
                id="add-series-profile-name"
                className={styles.seriesProfileNameInput}
                onChange={e => {
                  state.setName(e.currentTarget.value);
                }}
                error={state.nameError}
              />
            </InlineInputLabel>
            <div className={styles.searchSeriesProfileBox}>
              <Input
                id="add-series-profile-search"
                className={styles.seriesProfileNameInput}
                placeholder="Search Models"
                value={state.searchInput}
                onChange={e => {
                  handleSearchInputChange(e.currentTarget.value);
                }}
              />
              <OATIcon icon="search" size={16} colorTheme="blue" className={styles.searchIcon} />
            </div>
            <AccordionComponent accordion={state.accordion} />
            <BlockInputLabel label="Applicable States" labelClass={styles.statesLabel}>
              {getAllStatesForRegion(region).map(stateItem => (
                <div className={styles.checkboxWrapper} key={stateItem}>
                  <Checkbox onChange={() => handleOnStateCheck(stateItem)} label={stateItem} isChecked={state.states.includes(stateItem)} />
                </div>
              ))}
            </BlockInputLabel>
            {state.nameError && <p className={styles.errorText}>Please add a series profile name</p>}
            {state.vehiclesError && <p className={styles.errorText}>Please select at least one vehicle model</p>}
            {state.statesMissingError && <p className={styles.errorText}>Please select at least one state</p>}
            {state.existingProfileError && <p className={styles.errorText}>A Series Profile with this name already exists. Please rename the profile and save your changes.</p>}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button id="add-series-profile-close" className={styles.closeBtn} onClick={onClose}>
            Close
          </Button>
          <Button id="add-series-profile-save" variant="primary" disabled={state.hasErrors} onClick={handleSubmit}>
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default observer(AddSeriesProfileModal);
