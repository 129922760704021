import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { BlockInputLabel, formatDollars } from 'oat-common-ui';
import NumberInput from '../../../../../components/NumberInput';
import { Status } from '../../../../../constants/global';
import useStores from '../../../../../stores/useStores';
import { assignStringValue } from '../../../../../utils/assignValue';
import MiscCardModel from '../../../models/MiscCardModel';
import styles from '../styles.module.scss';

type AmountFieldProps = {
  card: MiscCardModel;
  onChange: (value: string) => void;
  forCreate?: boolean;
};

const AmountField = ({ card, onChange, forCreate = false }: AmountFieldProps) => {
  const { programDetailsStore } = useStores();
  const { CASH_TYPES } = AdminConstants;
  const {
    fields: { type, combinedPerUnitCost, defaultPerUnitCost },
    hasError,
    isStandAlone,
    updateAmountOrPnv,
    updateOfferCosts,
  } = card;

  const handleInputChange = (value: string) => {
    updateAmountOrPnv('amount', value);
    updateOfferCosts(programDetailsStore.ryoEarnings);
    onChange(value);
  };

  return type !== CASH_TYPES.OTHER && type !== CASH_TYPES.DEFERRED_PAYMENT ? (
    <BlockInputLabel label="Amount" className={styles.inputContainer} labelClass={styles.inputLabel}>
      <NumberInput
        dollarSign
        dark={!forCreate}
        wholeNumber
        allowNegative={type === CASH_TYPES.FINAL_PAY}
        error={hasError}
        className={styles.numberInput}
        value={combinedPerUnitCost}
        onValueChange={value => handleInputChange(value.value)}
        disabled={programDetailsStore.offering.status === Status.MEETS_GUIDELINES || card.isDisabled}
        units
      />
      {!forCreate && <p className={styles.defaultValueLabel}>{isStandAlone ? `Default: $0` : `National: $${formatDollars(assignStringValue(defaultPerUnitCost?.toString()))}`}</p>}
    </BlockInputLabel>
  ) : (
    <></>
  );
};

export default observer(AmountField);
