import { makeAutoObservable } from 'mobx';
import { uuidv4 } from 'oat-common-ui';
import { SetContestNumbers } from '../gql/generated';

export interface SETContestNumbersByType {
  optionType: string;
  numbers: SETContestNumber[];
}

class SETContestNumber {
  uid = uuidv4();
  id = '';
  rev = '';
  isDefault = false;
  offeringId = '';
  number = '';
  optionTypeName = '';
  hasError = false;

  constructor(data: SetContestNumbers) {
    makeAutoObservable(this);

    this.id = data.id || '';
    this.rev = data.rev || '';
    this.isDefault = data.isDefault || false;
    this.offeringId = data.offeringId || '';
    this.number = data.number || '';
    this.optionTypeName = data.optionTypeName || '';
  }
}

export default SETContestNumber;
