import { DefaultModal, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { OfferingCosts, RgnlAlt, useResetSeriesProfileToNationalMutation } from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';

import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  onSubmit: (rgnAlt: RgnlAlt) => void;
  rgnlAltId: string;
}

const ResetToNationalModal = ({ rgnlAltId, onClose, onSubmit }: Props) => {
  const {
    offeringCostsStore: { setData },
  } = useStores();
  const [resetToNational] = useResetSeriesProfileToNationalMutation();
  const { error } = useToast();

  const handleSubmit = async () => {
    try {
      const res = await trackPromise(resetToNational({ variables: { input: { rgnlAltId } } }));

      if (res.data?.resetSeriesProfileToNational.success) {
        onSubmit(res.data.resetSeriesProfileToNational.rgnlAlt as RgnlAlt);
        setData(res.data.resetSeriesProfileToNational.offeringCosts as OfferingCosts);
      }

      onClose();
    } catch (e) {
      error((e as Error).message);
    } finally {
      onClose();
    }
  };

  return (
    <DefaultModal
      message="Are you sure you want to reset the series profile to national?"
      modalBodyClassName={styles.modal}
      onClose={onClose}
      onSubmit={handleSubmit}
      open
      submitText="Reset"
      title="Reset to National"
    />
  );
};

export default ResetToNationalModal;
