import { useLazyQuery } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { OATIcon } from 'oat-common-ui';
import { useEffect } from 'react';
import { GetPresignedDownloadUrlDocument, ReportResponse } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import useReportGeneration from '../../../../hooks/useReportGeneration';
import ReportsHeadline from '../ReportsHeadline';
import styles from './styles.module.scss';

const GeneratedReports = () => {
  const {
    reportsStore: { generatedReports, loadingReports, selectedIp, selectedRegion },
  } = useStores();

  const { generateReports } = useReportGeneration();

  useEffect(() => {
    generateReports(selectedIp, selectedRegion);
  }, [generateReports, selectedIp, selectedRegion]);

  const [runDownloadQuery] = useLazyQuery(GetPresignedDownloadUrlDocument, {
    onCompleted: data => {
      const a = document.createElement('a');
      a.href = data.presignedDownloadUrl.presignedUrl;
      a.click();
    },
  });

  const onOfferDownload = (report: ReportResponse) => {
    runDownloadQuery({
      variables: {
        s3Key: report.s3Key,
        fileName: report.fileName,
      },
    });
  };

  return (
    <>
      <ReportsHeadline title="Generated Reports" />
      <div>
        {loadingReports ? (
          'Fetching reports...'
        ) : (
          <ul className={styles.generatedReportsList}>
            {generatedReports.map(report => (
              <li className={styles.generatedSingleReport} key={report.fileName} onClick={() => onOfferDownload(report)}>
                <button id={`generated-report-${report.reportName}`} className={styles.generatedLink}>
                  <OATIcon icon="notebook" size={40} />
                  <p className={styles.reportName}>{report.reportName}</p>
                  <p>{report.lastModified}</p>
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default observer(GeneratedReports);
