import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Checkbox, CustomDatePicker, dateFormatISOString, dateStringToDate, formatNumber, Input } from 'oat-common-ui';
import NumberInput from '../../../../../../../../../components/NumberInput';
import { FEATURE_OR_4154, Status } from '../../../../../../../../../constants/global';
import useStores from '../../../../../../../../../stores/useStores';
import AprCardModel from '../../../../../../../models/AprCardModel';
import AprTermModel from '../../../../../../../models/AprTermModel';
import AprTierModel from '../../../../../../../models/AprTierModel';
import AprBuyRateInput from './AprBuyRateInput';

import useUserInfo from '../../../../../../../../../utils/useUserInfo';
import styles from '../styles.module.scss';

interface Props {
  card: AprCardModel;
  onChange: (skipDelay?: boolean, skipRecalc?: boolean, updateTfs?: boolean) => void;
  selectedTierModel: AprTierModel;
  term: AprTermModel;
  errorMessages: string[];
}

const AprTableRow = ({ card, selectedTierModel, term: aprTerm, onChange, errorMessages }: Props) => {
  const { programDetailsStore } = useStores();
  const { isSETUser } = useUserInfo();

  const {
    fields: {
      defaultEndDate,
      defaultStartDate,
      nationalEndDate,
      nationalStartDate,
      startDate,
      endDate,
      buyRate,
      isIncluded,
      nationalSubventionCash,
      nationalTfsShare,
      tfsCostShare,
      rate,
      subventionCash,
      term,
      termAverageAmount,
      fundingSourceSplits,
      termPen,
      tfsShare,
    },
    hasDateError,
    hasRateError,
    hasSubCashError,
    isAdvertised,
    isEnhanced,
    isPenRateInputValid,
    isRateInputValid,
    isTfsShareInputValid,
    nationalTargetPayment,
    projectedSales,
    targetPayment,
    uid,
    updateField,
  } = aprTerm;

  const skipRecalc =
    errorMessages.length > 0 || (FEATURE_OR_4154 && ((!!card.isEligibleForEnhTfsCostShare && !!card.isEnhTfsCostShareAccepted && !!card.enhTfsCostShareId) || !card.isStandalone));

  const handleTermToggle = (clickedTerm: number) => {
    selectedTierModel.toggleAdvertisedTerm(clickedTerm);
    onChange(false, skipRecalc);
  };

  const handlePenRateChange = (value: string) => {
    if (selectedTierModel.tier === '1+') {
      card.updateLoweTiersPenRate(value, term);
      updateAllTiersCosts();
    } else {
      updateField('termPen', value);
      updateCosts();
    }
  };

  const handleBuyRateChange = (value: number) => {
    if (isSETUser() && selectedTierModel.tier === '1+' && card.isStandalone) {
      card.updateLoweTiersBuyRate(value, term);
      updateAllTiersCosts();
    } else {
      updateField('rate', value);
      updateCosts();
    }
  };

  const updateAllTiersCosts = () => {
    for (const tier of card.tierMap.values()) {
      tier.updateEstCosts();
      tier.updateOfferCosts(programDetailsStore.ryoEarnings);
    }
  };

  const updateCosts = () => {
    selectedTierModel.updateEstCosts();
    selectedTierModel.updateOfferCosts(programDetailsStore.ryoEarnings);
  };

  const isDisabled = programDetailsStore.offering.status === Status.MEETS_GUIDELINES || card.isDisabled;

  const displayTfsCostShare = () => {
    return !!tfsCostShare ? `Enhanced: ${tfsCostShare}%` : `Default: ${nationalTfsShare}%`;
  };

  const handleMinMaxDate = (isMin: boolean) => {
    if (isMin) {
      if (card.isStandalone) {
        return dateStringToDate(programDetailsStore.offering.startDate);
      }

      return card.isDuplicate ? defaultStartDate : nationalStartDate;
    }

    if (card.isStandalone) {
      return dateStringToDate(programDetailsStore.offering.endDate);
    }

    return card.isDuplicate ? defaultEndDate : nationalEndDate;
  };

  return (
    <tr className={clsx(styles.rowWrapper, !isIncluded && styles.rowWrapperDisabled)}>
      {!FEATURE_OR_4154 && (
        <>
          <td className={styles.columnWrapper}>
            {/* Start Date */}
            <CustomDatePicker
              id={`apr-start-date-${card.uid}`}
              className={styles.datepicker}
              disabled={(!card.isStandalone && !card.isDuplicate) || !isIncluded || isDisabled}
              error={hasDateError || (!card.isValidEnR && card.isDuplicate)}
              maxDate={handleMinMaxDate(false)}
              minDate={handleMinMaxDate(true)}
              onChange={(value?: Date) => {
                updateField('startDate', value);
                onChange(false, skipRecalc);
              }}
              selected={startDate}
            />
            <p className={styles.inputLabel}>{`Period starts: ${dateFormatISOString(card.isStandalone ? programDetailsStore.offering.startDate : nationalStartDate)}`}</p>
          </td>
          <td className={styles.columnWrapper}>
            {/* End Date */}
            <CustomDatePicker
              id={`apr-end-date-${card.uid}`}
              className={styles.datepicker}
              disabled={(!card.isStandalone && !card.isDuplicate) || !isIncluded || isDisabled}
              error={hasDateError || (!card.isValidEnR && card.isDuplicate)}
              maxDate={handleMinMaxDate(false)}
              minDate={handleMinMaxDate(true)}
              onChange={(value?: Date) => {
                updateField('endDate', value);
                onChange(false, skipRecalc);
              }}
              selected={endDate}
            />
            <p className={styles.inputLabel}>{`Period ends: ${dateFormatISOString(card.isStandalone ? programDetailsStore.offering.endDate : nationalEndDate)}`}</p>
          </td>
        </>
      )}
      <td className={styles.columnWrapper}>
        {/* Term */}
        <Input
          id={`apr-${term}-${card.uid}`}
          readOnly
          disabled={!isIncluded || isDisabled}
          className={clsx(styles.termInput, isAdvertised && styles.termInputActive, !isIncluded && styles.termInputDisabled)}
          value={term}
          onClick={() => handleTermToggle(term)}
        />
      </td>
      <td className={styles.columnWrapper}>
        {/* Rate */}
        <NumberInput
          id={`apr-${rate}-${card.uid}`}
          className={styles.inputBgWhite}
          decimalScale={2}
          error={hasRateError || !isRateInputValid}
          disabled={!isIncluded || isDisabled}
          onChange={e => {
            handleBuyRateChange(Number(e.target.value));
            onChange(false, skipRecalc);
          }}
          percentageSign
          value={formatNumber(rate, false, true, 2)}
          wholeNumber
        />
        <AprBuyRateInput
          buyRate={buyRate}
          onChange={value => {
            updateField('buyRate', value);
            updateCosts();
            !card.isForCreate && onChange(true, skipRecalc);
          }}
          isStandalone={card.isStandalone}
          isDisabled={isDisabled}
        />
      </td>
      <td className={styles.columnWrapper}>
        {/* Target Payment */}
        <NumberInput id={`apr-${termAverageAmount}-${card.uid}`} className={styles.input} disabled dollarSign readOnly value={targetPayment(termAverageAmount)} wholeNumber units />
        <p className={styles.inputLabel}>{`${card.isStandalone ? 'Default: ' : 'National: '} $${nationalTargetPayment(termAverageAmount)}`}</p>
      </td>
      <td className={styles.columnWrapper}>
        {/* TFS Cost Share */}
        {fundingSourceSplits.length > 1 ? (
          <NumberInput id={`apr-${tfsShare}-${card.uid}`} className={styles.input} disabled percentageSign value={tfsShare} units />
        ) : (
          <NumberInput
            id={`apr-${tfsShare}-${card.uid}`}
            className={styles.input}
            disabled={!isEnhanced || fundingSourceSplits.length > 1 || (card.isForCreate && isSETUser()) || !isIncluded || isDisabled}
            error={!isTfsShareInputValid}
            onValueChange={(vals, source) => {
              const { event } = source;

              if (event) {
                updateField('tfsShare', vals.value);
                updateCosts();
                onChange(false, skipRecalc, true);
              }
            }}
            percentageSign
            value={tfsShare}
            wholeNumber
            units
          />
        )}
        <p className={styles.inputLabel}>{displayTfsCostShare()}</p>
      </td>
      <td className={styles.columnWrapper}>
        {/* Term Pen */}
        <NumberInput
          id={`apr-${termPen}-${card.uid}`}
          wholeNumber
          disabled={!isIncluded || isDisabled}
          percentageSign
          className={styles.inputBgWhite}
          value={termPen}
          onValueChange={e => {
            handlePenRateChange(e.value);
            updateCosts();
            onChange(false, skipRecalc);
          }}
          error={!isPenRateInputValid}
        />
        <p className={styles.inputLabel}>{`Projected Sales: ${projectedSales}`}</p>
      </td>
      <td className={styles.columnWrapper}>
        {/* TFS Subvention Cash */}
        <NumberInput
          id={`apr-${subventionCash}-${card.uid}`}
          wholeNumber
          className={styles.input}
          disabled={card.isForCreate || !isIncluded || isDisabled}
          error={hasSubCashError}
          dollarSign
          onValueChange={vals => {
            updateField('subventionCash', Number(vals.value));
            updateCosts();
            onChange(false, skipRecalc);
          }}
          value={subventionCash}
          units
        />
        {!card.isStandalone && <p className={styles.inputLabel}>{`National: $${nationalSubventionCash}`}</p>}
      </td>
      {card.isForCreate && isSETUser() && (
        <td className={styles.columnWrapper}>
          {/* Enhance & Refine */}
          <div className={styles.checkboxContainer}>
            <Checkbox
              id={`include-term-checkbox-${uid}`}
              isChecked={isIncluded}
              onChange={e => {
                updateField('isIncluded', e.target.checked);
                selectedTierModel.setHighestAdvertisedTerm();
                onChange();
              }}
              isDisabled={isDisabled}
            />
          </div>
        </td>
      )}
    </tr>
  );
};

export default observer(AprTableRow);
