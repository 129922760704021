import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { Button, DefaultModal } from 'oat-common-ui';
import React, { useState } from 'react';
import AnchorScrollTo from '../../../../../../components/AnchorScrollTo';
import ToggleButton from '../../../../../../components/ToggleButton';
import { SCROLL_OFFSET_PROGRAM_DETAILS, Status } from '../../../../../../constants/global';
import useStores from '../../../../../../stores/useStores';
import LeaseCardModel from '../../../../models/LeaseCardModel';
import LeaseFormModel from '../../../../models/LeaseFormModel';
import styles from '../../styles.module.scss';
import LeaseAddTerm from '../LeaseAddTerm';

const { STANDARD_ODD_TERMS } = AdminConstants;
interface Props {
  leaseCard: LeaseCardModel;
  leaseForm: LeaseFormModel;
  allowHide?: boolean;
  onAddTerm?: (term: number) => void;
  onHideTerm?: (term: number, successFn: () => void) => void;
}

const TermSelector = ({ leaseCard, leaseForm, allowHide = false, onAddTerm, onHideTerm }: Props) => {
  const { programDetailsStore } = useStores();
  const [selectedHideTerm, setSelectedHideTerm] = useState(0);
  const [openHideLeaseTermModal, setOpenHideLeaseTermModal] = useState(false);

  const oddTermsList = STANDARD_ODD_TERMS.filter(term => term > leaseCard.minTerm && term < leaseCard.maxTerm);

  const handleHideLeaseTermCb = async () => {
    if (onHideTerm) {
      onHideTerm(selectedHideTerm, () => leaseCard.hideOddTermLease(selectedHideTerm));
    }
  };

  const handleHideLeaseTermModal = (term: number) => {
    setOpenHideLeaseTermModal(!openHideLeaseTermModal);
    setSelectedHideTerm(term);
  };

  const handleTermClick = (term: number, tier: string) => {
    return () => {
      leaseCard.switchTerm(term, tier);
      AnchorScrollTo(`lease-${leaseCard.id}`, SCROLL_OFFSET_PROGRAM_DETAILS);
    };
  };

  const handleHideClick = (term: number) => {
    return () => {
      handleHideLeaseTermModal(term);
    };
  };

  return (
    <>
      <div className={styles.termWrapper}>
        {leaseCard.leaseTerms.map(term => {
          // need to get error from master and examples
          // for now just get from master
          const foundLeaseForm = term.getMaster();

          return (
            <React.Fragment key={term.uid}>
              <ToggleButton
                id={`lease-term-btn-${term.uid}`}
                className={styles.termButton}
                toggled={leaseForm?.inputs.term === term.term && term.tier === leaseCard.tier}
                onClick={handleTermClick(term.term, term.tier)}
                error={foundLeaseForm?.hasError() || !foundLeaseForm?.isValidEnR}
              >
                {term.term}
              </ToggleButton>
              {allowHide && STANDARD_ODD_TERMS.includes(term.term) && (
                <Button variant="text" onClick={handleHideClick(term.term)}>
                  Hide
                </Button>
              )}
            </React.Fragment>
          );
        })}
      </div>

      <LeaseAddTerm
        leaseForm={leaseForm}
        oddTermsList={oddTermsList}
        term={leaseForm.inputs.term}
        onChange={onAddTerm}
        isDisabled={programDetailsStore.offering.status === Status.MEETS_GUIDELINES || leaseCard.isDisabled}
      />

      {/* Hide Term Modal */}
      {openHideLeaseTermModal && (
        <DefaultModal
          title={`Hide Lease Term ${selectedHideTerm}`}
          open
          message={`Are you sure you want to hide lease term ${selectedHideTerm}?`}
          submitText="Ok"
          onClose={() => {
            setSelectedHideTerm(0);
            setOpenHideLeaseTermModal(false);
          }}
          onSubmit={() => {
            handleHideLeaseTermCb();
            setOpenHideLeaseTermModal(false);
          }}
          isWarning
        />
      )}
    </>
  );
};

export default observer(TermSelector);
