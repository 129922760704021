import { makeAutoObservable } from 'mobx';
import { SeriesSorting } from '../gql/generated';

class SeriesSortingStore {
  seriesSorting: SeriesSorting[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSeriesSorting = (seriesSorting: SeriesSorting[]) => {
    this.seriesSorting = seriesSorting;
  };
}

export default SeriesSortingStore;
