import { observer } from 'mobx-react-lite';
import { formatAccounting, formatDollars, formatDollarsCents } from 'oat-common-ui';
import React from 'react';
import DollarDisplay from '../../../../components/DollarDisplay';
import { BalanceTooltip, CostTooltip, EarningsTooltip, TfsCostTooltip } from '../../../../components/raCostTooltips';
import { SubHeaderContainer } from '../../../../components/SubHeader/SubHeaderContainer';
import SubHeaderDates from '../../../../components/SubHeader/SubHeaderDates';
import { TooltipListItem } from '../../../../components/TooltipList/TooltipList';
import useStores from '../../../../stores/useStores';
import { assignDollarCents } from '../../../../utils/assignValue';
import getOfferCostBreakdown from '../../../../utils/getOfferCostBreakdown';
import useGetTfsLabel from '../../../../utils/useGetTfsLabel';
interface Props {
  showTfsEnhanced: boolean;
  setShowTfsEnhanced: () => void;
}
const SeriesBalanceSubHeader = ({ showTfsEnhanced, setShowTfsEnhanced }: Props) => {
  const {
    programDetailsStore: {
      offering,
      rgnlAlt,
      ryoEarnings,
      nationalRyo,
      additionalRyo,
      lumpSum,
      offerCards: { cash, apr, lease, misc },
    },
    offeringCostsStore: { raCosts },
  } = useStores();

  const costs = raCosts[rgnlAlt.id];
  const earnings = costs?.raEarnings ?? 0;
  const cost = costs?.raCost ?? 0;
  const balance = assignDollarCents(earnings - cost);
  const tfsCost = (showTfsEnhanced ? costs?.raEnhTfsCost : costs?.raTfsCost) ?? 0;

  const tfsLabel = useGetTfsLabel();
  const tfsCostLabel = showTfsEnhanced ? `Series Enhanced ${tfsLabel} Cost` : `Series ${tfsLabel} Cost`;

  const offerCostBreakdown = getOfferCostBreakdown(cash, apr, lease, misc);

  const renderEarningsTooltip = () => {
    return (
      <>
        <EarningsTooltip
          rgnlAltId={rgnlAlt.id}
          ryoEarnings={ryoEarnings}
          nationalRyo={nationalRyo}
          additionalRyo={additionalRyo}
          lumpSum={lumpSum}
          fsv={rgnlAlt.forecastedSalesVolume ?? 0}
          breakdown={
            <>
              {offerCostBreakdown.map((item, index) => (
                <TooltipListItem key={item.uid} symbol={index > 0 ? '+' : undefined}>
                  <span>{item.name}</span>
                  <span>${formatDollarsCents(item.earnings)}</span>
                </TooltipListItem>
              ))}
            </>
          }
        />
      </>
    );
  };

  const renderCostTooltip = () => {
    return (
      <CostTooltip
        rgnlAltId={rgnlAlt.id}
        breakdown={
          <>
            {offerCostBreakdown.map((item, index) => (
              <React.Fragment key={item.uid}>
                <TooltipListItem symbol={index > 0 ? '+' : undefined}>
                  <span>{item.name}</span>
                  <span>${formatDollarsCents(item.cost, true)}</span>
                </TooltipListItem>
                {item.subCashCost > 0 && (
                  <TooltipListItem symbol="+">
                    <span>{item.subCashName}</span>
                    <span>${formatDollars(item.subCashCost)}</span>
                  </TooltipListItem>
                )}
              </React.Fragment>
            ))}
          </>
        }
        hasBreakdownElement={offerCostBreakdown.length > 0}
      />
    );
  };

  const renderBalanceTooltip = () => {
    return (
      <BalanceTooltip
        rgnlAltId={rgnlAlt.id}
        breakdown={
          <>
            {offerCostBreakdown.map((item, index) => (
              <TooltipListItem key={item.uid} symbol={index > 0 ? '+' : undefined}>
                <span>{item.name}</span>
                <span>{formatAccounting(item.balance)}</span>
              </TooltipListItem>
            ))}
          </>
        }
        hasBreakdownElement={offerCostBreakdown.length > 0}
      />
    );
  };

  const renderTfsCostTooltip = () => {
    return (
      <TfsCostTooltip
        enhanced={showTfsEnhanced}
        rgnlAltId={rgnlAlt.id}
        breakdown={
          <>
            {offerCostBreakdown.map((item, index) => (
              <React.Fragment key={item.uid}>
                <TooltipListItem symbol={index > 0 ? '+' : undefined}>
                  <span>{item.name}</span>
                  <span>{formatAccounting(showTfsEnhanced ? item.enhTfsCost : item.tfsCost)}</span>
                </TooltipListItem>
                {!showTfsEnhanced && item.subCashTfsCost > 0 && (
                  <TooltipListItem symbol="+">
                    <span>{item.subCashName}</span>
                    <span>${formatDollars(item.subCashTfsCost)}</span>
                  </TooltipListItem>
                )}
                {showTfsEnhanced && item.subCashEnhTfsCost > 0 && (
                  <TooltipListItem symbol="+">
                    <span>{item.subCashName}</span>
                    <span>${formatDollars(item.subCashEnhTfsCost)}</span>
                  </TooltipListItem>
                )}
              </React.Fragment>
            ))}
          </>
        }
        hasBreakdownElement={offerCostBreakdown.length > 0}
      />
    );
  };

  return (
    <SubHeaderContainer>
      <div>
        <SubHeaderDates startDate={offering.startDate} endDate={offering.endDate} />
      </div>
      <div>
        <DollarDisplay
          id="series-enh-tfs"
          value={tfsCost}
          tooltip={renderTfsCostTooltip()}
          showTooltip
          label={tfsCostLabel}
          rightBorder
          showToggle
          toggleSelected={showTfsEnhanced}
          onToggle={setShowTfsEnhanced}
        />
        <DollarDisplay id="series-earnings" value={earnings} tooltip={renderEarningsTooltip()} showTooltip label="Est. Series Earnings" />
        <DollarDisplay id="series-cost" value={cost} tooltip={renderCostTooltip()} showTooltip label="Est. Series Cost" />
        <DollarDisplay id="series-balance" value={balance} tooltip={renderBalanceTooltip()} showTooltip label="Est. Series Balance" useColors />
      </div>
    </SubHeaderContainer>
  );
};

export default observer(SeriesBalanceSubHeader);
