import { ReactNode, useEffect, useState } from 'react';
import IpBreadCrumbs from '../../../../components/IpComponents/IpBreadCrumbs';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import useUserInfo from '../../../../utils/useUserInfo';
import LoadingPage from '../../../LoadingPage';
import { getSeriesItem, setLeaseData, setLeaseDataForSET } from './utils';

interface Props {
  children: ReactNode;
}

const EFCSeriesData = ({ children }: Props) => {
  const {
    efcStore,
    dd365Store,
    userInfoStore: {
      userInfo: { brand },
      isLexus,
    },
    programDetailsStore: { seriesProfile, offerCards },
  } = useStores();
  const { region } = useUrlParams();
  const { isSETUser } = useUserInfo();
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (!fetching && loading) {
          setFetching(true);
          const efcData = await efcStore.getEfcSeriesApi(brand, region);
          const { series, seriesYear } = seriesProfile.series[0];
          const seriesItem = getSeriesItem(efcData, series);

          if (!efcStore.has3dImages(seriesYear, series)) {
            await efcStore.get3dImages(brand, seriesYear, series);
          }

          if (efcData && efcData.series && seriesItem && seriesProfile.series.length) {
            if (isSETUser()) {
              await setLeaseDataForSET(seriesItem, offerCards.lease, dd365Store, seriesYear, brand);
            } else {
              await setLeaseData(seriesItem, offerCards.lease, efcStore, isLexus(), seriesYear, brand);
            }
          }

          setLoading(false);
          setFetching(false);
        }
      } catch (e) {
        setLoading(false);
        setFetching(false);
        setError(true);
      }
    })();
  }, [brand, region, loading, fetching, seriesProfile, isLexus, offerCards.lease, efcStore, dd365Store, isSETUser]);

  if (loading || error) {
    return <LoadingPage breadCrumbs={IpBreadCrumbs(false)} error={!!error} />;
  }

  return <>{children}</>;
};

export default EFCSeriesData;
