import { observer } from 'mobx-react-lite';
import { formatDollars } from 'oat-common-ui';
import TooltipList, { TooltipListItem } from '../../../../../../components/TooltipList/TooltipList';
import { convertPercentage } from '../../../../../../utils/convertPercentage';
import LeaseFormModel from '../../../../models/LeaseFormModel';

interface Props {
  leaseDetails: LeaseFormModel;
  isEnhanced?: boolean;
}

const RVAmountTooltipMessage = ({ leaseDetails, isEnhanced }: Props) => {
  const rateLabel = isEnhanced ? 'Enhanced Residual Value Rate' : 'Residual Value Rate';
  const rateValue = isEnhanced ? leaseDetails.totalResidualRate : leaseDetails.inputs.residualRate;
  const amountLabel = isEnhanced ? 'Enhanced Residual Value Amount' : 'Residual Value Amount';
  const amountValue = isEnhanced ? leaseDetails.inputs.enhancedResidualAmount : leaseDetails.inputs.residualAmount;

  return (
    <>
      <TooltipList>
        <TooltipListItem>
          <span>Total MSRP</span>
          <span>${formatDollars(leaseDetails.inputs.totalMsrp)}</span>
        </TooltipListItem>
        <TooltipListItem symbol="*">
          <span>{rateLabel}</span>
          <span>{convertPercentage(rateValue)}</span>
        </TooltipListItem>
      </TooltipList>

      <TooltipList divider>
        <TooltipListItem>
          <span>{amountLabel}</span>
          <span>${formatDollars(amountValue)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
};

export default observer(RVAmountTooltipMessage);
