import { dateFormatISOString } from 'oat-common-ui';
import { SaveCashOfferInput } from '../../../../gql/generated';
import CashCardModel from '../../models/CashCardModel';

export const getCashPayload = (cashCard: CashCardModel): SaveCashOfferInput => {
  return {
    id: cashCard.id,
    rev: cashCard.rev,
    name: cashCard.fields.name,
    startDate: dateFormatISOString(cashCard.fields.startDate),
    endDate: dateFormatISOString(cashCard.fields.endDate),
    note: cashCard.fields.note,
    tdaNote: cashCard.fields.tdaNote,
    isSpecialEdition: cashCard.fields.isSpecialEdition,
    specialEditionPackage: cashCard.fields.specialEditionPackage,
    accessoryCodes: cashCard.fields.accessoryCodes,
    combinedPerUnitCost: Number(cashCard.fields.combinedPerUnitCost),
    contestNumber: cashCard.fields.contestNumber,
    estimatedCost: cashCard.fields.estimatedCost,
    offerCost: cashCard.fields.offerCost,
    offerEarnings: cashCard.fields.offerEarnings,
  };
};
