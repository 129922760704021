import { CreateLeaseModels, EfcApi } from 'oat-common-ui';
import { ResidualValue } from '../../../../../gql/generated';


const getTrimListFromEFC = (data: any, series: string, seriesYear: string, term: number, isLexus: boolean, residuals?: ResidualValue[]) => {
  const trimList: CreateLeaseModels.TrimItem[] = [];
  data.series.forEach((seriesItem: any) => {
    if (seriesItem.code === series) {
      seriesItem.year.forEach((year: any) => {
        if (year.code === seriesYear) {
          // transform object
          year.model.forEach((model: any) => {
            const found = residuals?.find(item => item.modelCode === model.code && item.seriesYear === Number(year.code) && item.term === term);
            const grade = !isLexus ? model.gradeTitle : `${model.title.replace(series, '')} ${model.code}`;

            const carJelly = EfcApi.getCarJellyFromExtColors(model.exteriorcolor);

            trimList.push({
              code: model.code,
              grade,
              title: model.title,
              trimTitle: `(${model.code}) ${model.title}`,
              residual: found ? `${found.rv}` : '',
              carJelly,
              count:0
            });
          });
        }
      });
    }
  });

  return trimList;
};

export default getTrimListFromEFC;
