import { Dropdown, DropdownItem, OATIcon } from 'oat-common-ui';
import { useState } from 'react';
import styles from '../ReportsList/styles.module.scss';

const FiscalYear = () => {
  const [year, setYear] = useState<DropdownItem>({ label: '2023', value: '2023' });
  const reports = ['Approved', 'Forecasted', 'Working', 'Incentive PNVS Input', 'Model Year Allocation'];

  const handleClick = () => {};

  return (
    <>
      <Dropdown id="fy-dd" value={year} options={['2023', '2022']} onChange={setYear} darkTheme />
      <ul className={styles.reportsList}>
        {reports.map(report => {
          return (
            <li className={styles.singleReport} key={report}>
              <button id={`report-${report}`} onClick={handleClick}>
                <OATIcon icon="notes" className={styles.reportIcon} />
                <span>{report}</span>
              </button>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default FiscalYear;
