import clsx from 'clsx';
import { Tooltip } from 'oat-common-ui';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  component?: ReactNode;
  topText?: string;
  bottomText?: string | ReactNode;
  className?: string;
  topTextClass?: string;
  bottomTextClass?: string;
  addTooltip?: boolean;
  tooltipMessage?: string;
}

/**
 * Used in offer cards for exclusions, compatible offers, etc...
 * @param param0
 * @returns
 */
const BlockText = ({ component, topText, bottomText, className, topTextClass, bottomTextClass, addTooltip, tooltipMessage }: Props) => {
  return (
    <div className={clsx(styles.blockText, className)}>
      <div>
        {component}
        {topText && <span className={topTextClass ? topTextClass : styles.topText}>{topText}</span>}
      </div>
      <div>
        {addTooltip && tooltipMessage ? (
          <Tooltip id="compatible-offers-tooltip" message={tooltipMessage} place="bottom">
            <span className={styles.bottomText}>{bottomText}</span>
          </Tooltip>
        ) : (
          <span className={bottomTextClass ? bottomTextClass : styles.bottomText}>{bottomText}</span>
        )}
      </div>
    </div>
  );
};

export default BlockText;
