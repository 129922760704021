import { LeaseExampleDetailsInput, LeaseOfferDetails } from '../../../../../gql/generated';
import { convertPercentage } from '../../../../../utils/convertPercentage';
import LeaseCardModel from '../../../models/LeaseCardModel';
import LeaseFormModel from '../../../models/LeaseFormModel';
import LeaseTermModel from '../../../models/LeaseTermModel';

const transformLeaseDetails = (leaseTerm: LeaseTermModel, leaseForm: LeaseFormModel): LeaseOfferDetails => {
  const leaseOfferDetails: LeaseOfferDetails = getBaseLeaseDetails(leaseForm.isExample ? leaseTerm.getMaster() : leaseForm);
  const leaseExamples = leaseTerm.leaseForms
    .filter(item => item.isExample)
    .map(item => {
      return getExampleDetails(item.uid !== leaseForm.uid ? item : leaseForm);
    });
  leaseOfferDetails.leaseExamples = leaseExamples;

  return leaseOfferDetails;
};

/**
 * Used for Reset to National
 * @param leaseCard
 * @returns
 */
export const transformLeaseCard = (leaseCard: LeaseCardModel, leaseDetails?: LeaseOfferDetails[]) => {
  return leaseCard.leaseTerms.map(leaseTerm => {
    const master = leaseTerm.getMaster();
    const examples = leaseTerm.leaseForms.filter(l => l.isExample);

    const ld = getBaseLeaseDetails(master);
    const ex = examples.map(item => getExampleDetails(item));
    ld.leaseExamples = ex;

    if (leaseDetails?.length) {
      const foundDetail = leaseDetails.find(detail => detail.tier === ld.tier && detail.term === ld.term);

      if (foundDetail) {
        ld.tfsCostShare = foundDetail.tfsCostShare;
        ld.subCashTfsShare = foundDetail.subCashTfsShare;
        ld.subCashTfsCostShareCap = foundDetail.subCashTfsCostShareCap;
      }
    }

    return ld;
  });
};

export const getBaseLeaseDetails = (leaseForm: LeaseFormModel): LeaseOfferDetails => {
  const { inputs } = leaseForm;
  return {
    acquisitionFee: inputs.acquisitionFee,
    additionalCash: Number(inputs.additionalCashAmount),
    additionalCashOfferId: inputs.additionalCashOfferId,
    additionalCashOptionType: !Boolean(inputs.additionalCashOfferId) ? '' : inputs.additionalCashOptionType,
    adjustmentFactor: Number(inputs.adjustmentFactor),
    baseMsrp: inputs.baseMsrp,
    bonusCash: Number(inputs.bonusCash),
    configuration: inputs.configuration,
    dealerCostPerInvoice: inputs.dealerCostPerInvoice,
    dealerGross: Number(inputs.dealerGross),
    downPayment: inputs.downPayment,
    dueAtSigning: Number(inputs.dueAtSigning),
    endDate: inputs.endDate,
    enhancedResidualAmount: Number(inputs.enhancedResidualAmount),
    enhancedResidualRate: Number(inputs.enhancedResidualRate),
    estimatedCost: Number(inputs.estimatedCost),
    subCashEstCost: Number(inputs.subCashEstCost),
    subCashTfsEstCost: Number(inputs.subCashTfsEstCost),
    subCashEnhTfsEstCost: Number(inputs.subCashEnhTfsEstCost),
    financialReserve: Number(inputs.financialReserve),
    grossCapCost: inputs.grossCapCost,
    holdback: Number(inputs.holdback),
    isAdvertised: inputs.isAdvertised,
    isAddtlCashAppliedToDueAtSigning: inputs.isAddtlCashAppliedToDueAtSigning,
    isCapitalized: inputs.isCapitalized,
    isSubCashAdvertised: inputs.isSubCashAdvertised,
    isUpToApplied: inputs.isUpToApplied,
    mileage: Number(inputs.mileage),
    modelCode: inputs.modelCode,
    modelYear: inputs.modelYear,
    netCapCost: inputs.netCapCost,
    note: inputs.note,
    offerEarnings: leaseForm.offerEarnings,
    offerCost: leaseForm.offerCost,
    offerTfsCost: leaseForm.offerTfsCost,
    offerEnhTfsCost: leaseForm.offerEnhTfsCost,
    subCashOfferCost: leaseForm.subCashOfferCost,
    subCashOfferTfsCost: leaseForm.subCashOfferTfsCost,
    subCashOfferEnhTfsCost: leaseForm.subCashOfferEnhTfsCost,
    port: inputs.port,
    regionalSubventionRCF: Number(inputs.rcf),
    regionalStandardRCF: Number(inputs.standardRegionalRcf),
    residualAmount: inputs.residualAmount,
    residualRate: convertPercentage(inputs.residualRate),
    series: inputs.series,
    startDate: inputs.startDate,
    subventionCash: Number(inputs.subventionCash),
    targetPayment: Number(inputs.targetPayment),
    tdaFee: Number(inputs.tdaFee),
    tdaNote: inputs.tdaNote,
    tier: inputs.tier,
    term: inputs.term,
    enhTfsCost: inputs.enhTfsEstCost,
    tfsCost: inputs.tfsEstCost,
    tfsCostShare: Number(inputs.tfsShare),
    subCashTfsShare: Number(inputs.subCashTfsShare),
    subCashTfsCostShareCap: Number(inputs.subCashTfsCostShareCap),
    totalDealerGross: inputs.totalDealerGross,
    totalMsrp: inputs.totalMsrp,
    vehicleDescription: inputs.vehicleDescription,
    vin: inputs.vin,
    areTfsEnhRatesUsed: leaseForm.areTfsEnhRatesUsed,
  };
};

const getExampleDetails = (leaseForm: LeaseFormModel): LeaseExampleDetailsInput => {
  const { inputs } = leaseForm;
  return {
    acquisitionFee: inputs.acquisitionFee,
    baseMsrp: inputs.baseMsrp,
    configuration: inputs.configuration,
    dealerCostPerInvoice: inputs.dealerCostPerInvoice,
    dealerGross: Number(inputs.dealerGross),
    downPayment: inputs.downPayment,
    dueAtSigning: Number(inputs.dueAtSigning),
    enhancedResidualAmount: Number(inputs.enhancedResidualAmount),
    enhancedResidualRate: Number(inputs.enhancedResidualRate),
    financialReserve: Number(inputs.financeReserve),
    grossCapCost: inputs.grossCapCost,
    holdback: Number(inputs.holdback),
    isBadVin: inputs.isBadVin,
    isCapitalized: inputs.isAcquisitionFeeCapitalized,
    isZeroDueAtSigning: inputs.isZeroDueAtSigning,
    mileage: Number(inputs.mileage),
    modelCode: inputs.modelCode,
    modelYear: inputs.modelYear,
    name: inputs.name,
    netCapCost: inputs.netCapCost,
    residualAmount: inputs.residualAmount,
    residualRate: convertPercentage(inputs.residualRate),
    series: inputs.series,
    targetPayment: Number(inputs.targetPayment),
    tdaFee: Number(inputs.tdaFee),
    totalDealerGross: inputs.totalDealerGross,
    totalMsrp: inputs.totalMsrp,
    vehicleDescription: inputs.vehicleDescription,
    vin: inputs.vin,
  };
};

export default transformLeaseDetails;
