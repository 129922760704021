import { formatAccounting, formatDollarsCents } from 'oat-common-ui';
import React from 'react';
import { BalanceTooltip, CostTooltip, EarningsTooltip } from '../../../../components/raCostTooltips';
import { TooltipListItem } from '../../../../components/TooltipList/TooltipList';
import { assignDollarCents } from '../../../../utils/assignValue';
import RgnlAltModel from '../../models/RgnlAltModel';

export const renderEarningsTooltip = (rgnlAlt: RgnlAltModel) => {
  return (
    <EarningsTooltip
      rgnlAltId={rgnlAlt.id}
      fsv={Number(rgnlAlt.fsv ?? 0)}
      ryoEarnings={rgnlAlt.ryoEarnings}
      nationalRyo={rgnlAlt.nationalRyo}
      additionalRyo={rgnlAlt.additionalRyo}
      lumpSum={rgnlAlt.lumpSum}
      breakdown={
        <>
          {rgnlAlt.getCashAprLeaseMiscOffers().map((penRate, i) => {
            return (
              <TooltipListItem key={penRate.uid} symbol={i > 0 ? '+' : undefined}>
                <span>{penRate.displayName}</span>
                <span>${formatDollarsCents(penRate.offerEarnings)}</span>
              </TooltipListItem>
            );
          })}
        </>
      }
    />
  );
};

export const renderCostsTooltip = (rgnlAlt: RgnlAltModel) => {
  return (
    <CostTooltip
      rgnlAltId={rgnlAlt.id}
      breakdown={
        <>
          {rgnlAlt.getCashAprLeaseMiscOffers().map((penRate, i) => {
            return (
              <React.Fragment key={penRate.uid}>
                <TooltipListItem symbol={i > 0 ? '+' : undefined}>
                  <span>{penRate.displayName}</span>
                  <span>${formatDollarsCents(penRate.offerCost)}</span>
                </TooltipListItem>
                {penRate.subCash > 0 && (
                  <TooltipListItem symbol="+">
                    <span>{penRate.subCashName}</span>
                    <span>${formatDollarsCents(penRate.subCashOfferCost)}</span>
                  </TooltipListItem>
                )}
              </React.Fragment>
            );
          })}
        </>
      }
      hasBreakdownElement={rgnlAlt.getCashAprLeaseMiscOffers().length > 0}
    />
  );
};

export const renderBalanceTooltip = (rgnlAlt: RgnlAltModel) => {
  return (
    <BalanceTooltip
      rgnlAltId={rgnlAlt.id}
      breakdown={
        <>
          {rgnlAlt.getCashAprLeaseMiscOffers().map((penRate, i) => {
            return (
              <TooltipListItem key={penRate.uid} symbol={i > 0 ? '+' : undefined}>
                <span>{penRate.displayName}</span>
                <span>{formatAccounting(assignDollarCents(penRate.offerEarnings - penRate.offerCost))}</span>
              </TooltipListItem>
            );
          })}
        </>
      }
      hasBreakdownElement={rgnlAlt.getCashAprLeaseMiscOffers().length > 0}
    />
  );
};
