import { observer, useLocalObservable } from 'mobx-react-lite';
import { Button, ButtonGroup, getDateParts, Input, Modal, ModalBody, ModalFooter, ModalHeader, OATIcon } from 'oat-common-ui';
import AccordionComponent from '../../../../../components/Accordion/AccordionComponent';
import mapVehicleModelCodeFromAccordion from '../../../../../components/Accordion/utils/mapVehicleModelCodeFromAccordion';
import { ModelCodes, Offering, SeriesMapping, useGetAddSeriesProfileDataQuery, VehicleInput } from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';
import ProfileModel from '../../../models/ProfileModel';
import AddSeriesProfileModel from '../../AddSeriesProfileSection/AddSeriesProfileModel';
import styles from '../../AddSeriesProfileSection/components/AddSeriesProfileModal/styles.module.scss';

interface Props {
  offering: Offering;
  seriesProfile: ProfileModel;
  onClose: () => void;
  onSubmit: (includedList: VehicleInput[]) => void;
}

const EditModelsModal = ({ offering, onClose, onSubmit, seriesProfile }: Props) => {
  const {
    userInfoStore: {
      userInfo: { brand },
    },
    summaryStore: { profiles },
  } = useStores();

  const { year: offeringYear, month: offeringMonth } = getDateParts(offering.startDate);

  const { data, loading, error } = useGetAddSeriesProfileDataQuery({
    variables: { brand, offeringYear, offeringMonth },
  });

  const state = useLocalObservable(() => new AddSeriesProfileModel());

  // init data
  if (!state.loaded && data) {
    state.setData(
      data.modelCodes as ModelCodes[],
      data.seriesMapping as SeriesMapping[],
      [],
      profiles.map(item => item.name),
      seriesProfile.includedVehicles,
    );
  }

  const handleSearchInputChange = (value: string) => {
    state.setSearchInput(value);
  };

  const handleOnSubmit = () => {
    const excIncModelCodes = mapVehicleModelCodeFromAccordion(state.accordion.items, data?.modelCodes as ModelCodes[]);
    onSubmit(excIncModelCodes);
  };

  return (
    <Modal isOpen onClose={onClose} className={styles.modalWrapper}>
      <ModalHeader onClose={onClose}>Edit Models</ModalHeader>
      <ModalBody className={styles.body}>
        {loading && 'Loading...'}
        {!loading && data && !error && (
          <div>
            <div className={styles.searchSeriesProfileBox}>
              <Input
                id="edit-models-search"
                className={styles.seriesProfileNameInput}
                alphaNumeric
                placeholder="Search Models"
                value={state.searchInput}
                onChange={e => {
                  handleSearchInputChange(e.currentTarget.value);
                }}
              />
              <OATIcon icon="search" size={16} colorTheme="blue" className={styles.searchIcon} />
            </div>
            <AccordionComponent accordion={state.accordion} />
            {state.vehiclesError && <p className={styles.errorText}>Please select at least one vehicle model before saving</p>}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button id="edit-models-close" className={styles.closeBtn} onClick={onClose}>
            Close
          </Button>
          <Button id="edit-models-save" variant="primary" disabled={state.vehiclesError} onClick={handleOnSubmit}>
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default observer(EditModelsModal);
