import { observer } from 'mobx-react-lite';
import { dateFormatISOString } from 'oat-common-ui';
import { useState } from 'react';
import SettingsMenu from '../../../../../../components/SettingsMenu';
import { Status } from '../../../../../../constants/global';
import { AprDetails, Offer } from '../../../../../../gql/generated';
import useUrlParams from '../../../../../../hooks/useUrlParams';
import useStores from '../../../../../../stores/useStores';
import AprCardModel from '../../../../models/AprCardModel';
import AdvertiseOfferModal from '../../../AdvertiseOfferModal';
import AprLeaseEnRModal from '../../../EnhanceAndRefineModal/AprLeaseEnRModal';
import PenRatesModal from '../../../PenRatesModal';
import RemoveOfferModal from '../../../RemoveOfferModal';
import ResetToNationalModal from '../../../ResetToNationalModal';
import ResetToPrevRegionalModal from '../../../ResetToPrevRegionalModal';
import SubventionCashModal from '../../../SubventionCashModal';
import {
  ItemAdjustPenRates,
  ItemAdvertised,
  ItemEditSubventionCash,
  ItemEnhanceAndRefine,
  ItemRemoveOffer,
  ItemResetPrevRegionalOffer,
  ItemResetToNational,
  ItemTfsContracts,
} from '../../../contextMenuItems';
import EditTiersAndTermsModal from '../../../EditTiersAndTermsModal';
import { createUniqueItems } from '../../utils';

interface Props {
  aprCard: AprCardModel;
  saveApr: () => void;
}

const AprContextMenu = ({ aprCard, saveApr }: Props) => {
  const {
    programDetailsStore: { removeAprOffer, offering, seriesProfile, offerCards },
    seriesMappingStore: { seriesMapping },
  } = useStores();
  const { region } = useUrlParams();

  const [showAdvertisedModal, setShowAdvertisedModal] = useState(false);
  const [showResetToNationaldModal, setShowResetToNationaldModal] = useState(false);
  const [showResetToPrevRegionalModel, setShowResetToPrevRegionalModel] = useState(false);
  const [showRemoveOfferModal, setShowRemoveOfferModal] = useState(false);
  const [showPenRatesModal, setShowPenRatesModal] = useState(false);
  const [showSubventionCashModal, setShowSubventionCashModal] = useState(false);
  const [showEnhanceAndRefineModal, setShowEnhanceAndRefineModal] = useState(false);
  const [showEditTiersAndTerms, setShowEditTiersAndTerms] = useState(false);
  const [offerId, setOfferId] = useState(aprCard.id);
  const parentOfferCard = offerCards.apr.find(offer => offer.id === aprCard.parentId);
  const updateAPROnEditTiersAndTerms = (updatedOffer: Offer) => {
    aprCard.initData({ offer: { regional: updatedOffer }, seriesMapping, region, seriesProfile, offering });
    setShowEditTiersAndTerms(false);
  };

  return (
    <>
      {/* Context Menu */}
      <SettingsMenu isDisabled={offering.status === Status.MEETS_GUIDELINES || aprCard.isDisabled}>
        {/* Only show if Rate or SubventionCash are changed */}
        {aprCard.showResetToNational && !aprCard.isStandalone && <ItemResetToNational onClick={() => setShowResetToNationaldModal(true)} />}
        <ItemResetPrevRegionalOffer onClick={() => setShowResetToPrevRegionalModel(true)} />
        {!aprCard.isDuplicate && <ItemEnhanceAndRefine onClick={() => setShowEnhanceAndRefineModal(true)} />}
        {aprCard.isDuplicate && <ItemTfsContracts icon='duplicate' onClick={() => setShowEditTiersAndTerms(true)} />  }
        <ItemAdvertised advertised={aprCard.isAdvertised()} onClick={() => setShowAdvertisedModal(true)} />
        <ItemEditSubventionCash onClick={() => setShowSubventionCashModal(true)} />
        <ItemAdjustPenRates
          onClick={() => {
            setShowPenRatesModal(true);
            setOfferId(aprCard.id);
          }}
        />
        {(aprCard.isStandalone || aprCard.isDuplicate) && <ItemRemoveOffer onClick={() => setShowRemoveOfferModal(true)} />}
      </SettingsMenu>

      {showAdvertisedModal && (
        <AdvertiseOfferModal
          id={aprCard.id}
          rev={aprCard.rev}
          tier={aprCard.selectedTier}
          isAdvertised={aprCard.isAdvertised()}
          onClose={() => setShowAdvertisedModal(false)}
          onSubmit={offer => {
            aprCard.updateRev(offer.rev);
            aprCard.selectedTierModel?.updateTerms(offer.aprDetails as AprDetails[]);
          }}
        />
      )}
      {showResetToNationaldModal && (
        <ResetToNationalModal
          id={aprCard.id}
          onClose={() => setShowResetToNationaldModal(false)}
          onSubmit={offer => {
            aprCard.updateRev(offer.rev);
            aprCard.initData({ offer: { regional: offer, national: aprCard.nationalOffer }, seriesMapping, region, offering });
          }}
          rev={aprCard.rev}
        />
      )}
      {showRemoveOfferModal && (
        <RemoveOfferModal
          id={aprCard.id}
          rev={aprCard.rev}
          tier={aprCard.selectedTier}
          onClose={() => {
            setShowRemoveOfferModal(false);
          }}
          onSubmit={(_id, rev) => {
            removeAprOffer(aprCard.id, rev ?? '');
          }}
        />
      )}
      {showResetToPrevRegionalModel && (
        <ResetToPrevRegionalModal
          id={aprCard.id}
          onClose={() => setShowResetToPrevRegionalModel(false)}
          onSubmit={offer => {
            aprCard.updateRev(offer.rev);
            aprCard.initData({ offer: { regional: offer, national: aprCard.nationalOffer }, seriesMapping, region, offering });
          }}
          rev={aprCard.rev}
        />
      )}
      {showPenRatesModal && <PenRatesModal currentOfferId={`${offerId}#${aprCard.selectedTier}`} onClose={() => setShowPenRatesModal(false)} />}
      {showSubventionCashModal && <SubventionCashModal onClose={() => setShowSubventionCashModal(false)} aprCard={aprCard} saveApr={saveApr} />}
      {showEnhanceAndRefineModal && (
        <AprLeaseEnRModal
          id={aprCard.id}
          onClose={() => setShowEnhanceAndRefineModal(false)}
          rev={aprCard.rev}
          startDate={dateFormatISOString(aprCard.initialDates.startDate)}
          endDate={dateFormatISOString(aprCard.initialDates.endDate)}
          minDate={aprCard.initialDates.startDate}
          maxDate={aprCard.initialDates.endDate}
          openPenRateModal={setShowPenRatesModal}
          vehicles={aprCard.fields.vehicles}
          setOfferId={setOfferId}
          tiers={[]}
          terms={aprCard.termsList}
          aprCard={aprCard}
          nationalVehicles={aprCard.nationalOffer?.vehicles || []}
          isInclusions={aprCard.fields.isInclusions}
          toggleIsInclusions={aprCard.toggleIsInclusions}
        />
      )}
      {showEditTiersAndTerms && (
        <EditTiersAndTermsModal
          id={aprCard.id}
          rev={aprCard.rev}
          onSubmit={updateAPROnEditTiersAndTerms}
          onClose={() => setShowEditTiersAndTerms(false)}
          tiers={aprCard.tiersList}
          terms={aprCard.termsList}
          termOptions={createUniqueItems([...(parentOfferCard?.termsList ?? []), ...aprCard.termsList], undefined, term => `${term} Term`)}
          tierOptions={createUniqueItems([...(parentOfferCard?.tiersList ?? []), ...aprCard.tiersList])}
          />
      )}
    </>
  );
};

export default observer(AprContextMenu);
