import { observer } from 'mobx-react-lite';
import { Dropdown, OATIcon, SeriesCodeToNameMap, formatDollars } from 'oat-common-ui';
import { useState } from 'react';
import NumberInput from '../../../../../components/NumberInput';
import { FEATURE_OR_2477, FEATURE_OR_4154, FEATURE_OR_5646, Status } from '../../../../../constants/global';
import useUrlParams from '../../../../../hooks/useUrlParams';
import useStores from '../../../../../stores/useStores';
import getAnnualMileagesList from '../../../../../utils/getAnnualMileagesList';
import useGetTfsLabel from '../../../../../utils/useGetTfsLabel';
import useUserInfo from '../../../../../utils/useUserInfo';
import LeaseCardModel from '../../../models/LeaseCardModel';
import LeaseFormModel from '../../../models/LeaseFormModel';
import LeaseTermModel from '../../../models/LeaseTermModel';
import EnhCostShareModal from '../../EnhCostShareModal';
import useSaveLeaseData from '../LeaseAdForm/useSaveLeaseData';
import LeaseCardBase from '../LeaseCardBase';
import LeaseDates from '../components/LeaseDates';
import { LeaseFormLayoutColumn } from '../components/LeaseFormLayout';
import LeaseFundingSplitModal from '../components/LeaseFundingSplitModal';
import LeaseInput, { LeaseInputDescription, LeaseInputLabel, LeaseInputWrapper } from '../components/LeaseInput';
import LeaseSpecialEdition from '../components/LeaseSpecialEdition';
import RcfPnv from '../components/RcfPnv';
import TermSelector from '../components/TermSelector';
import VehicleInfo from '../components/VehicleInfo';
import { getDefaultLeaseTfsShare } from '../utils/getDefaultLeaseTfsShare';

type Props = {
  leaseCard: LeaseCardModel;
  leaseTerm: LeaseTermModel;
  leaseForm: LeaseFormModel;
};

const LeaseNonAdForm = ({ leaseCard, leaseTerm, leaseForm }: Props) => {
  const { programDetailsStore, userInfoStore } = useStores();
  const { id, term } = leaseCard;
  const { inputs, updateTfsShare, seriesName } = leaseForm;
  const { handleAddLeaseTerm, handleHideLeaseTerm, handleSaveCompatibility, handleInputChange, handleUptoChangeOrSave, handleEnhCostData, handleEnhTfsCostData } = useSaveLeaseData(
    leaseCard,
    leaseTerm,
    leaseForm,
  );
  const [fundingSplitOpen, setFundingSplitOpen] = useState(false);
  const [standardRegionalRcf, setStandardRegionalRcf] = useState<string | number>(inputs.standardRegionalRcf);
  const { region } = useUrlParams();

  const { isLexusUser, isGSTUser } = useUserInfo();
  const tfsLabel = useGetTfsLabel();
  const mileages = getAnnualMileagesList(userInfoStore.userInfo.brand, region, term > 30, seriesName.toLowerCase().includes(SeriesCodeToNameMap.supra.toLowerCase()));

  const defaultTfsShare = getDefaultLeaseTfsShare(
    !!programDetailsStore.offering.useOldCostShareForLexus,
    region,
    term,
    leaseCard.isEnhCostShareAccepted,
    leaseCard.enhancedCostShare,
  );

  const isDisabled = programDetailsStore.offering.status === Status.MEETS_GUIDELINES || leaseCard.isDisabled;

  return (
    <LeaseCardBase
      leaseCard={leaseCard}
      leaseTerm={leaseTerm}
      leaseForm={leaseForm}
      onInputChange={handleInputChange}
      onIsUpToApplied={handleUptoChangeOrSave}
      areTfsEnhRatesUsed={leaseForm.areTfsEnhRatesUsed}
      leftContent={
        <>
          <VehicleInfo
            leaseCard={leaseCard}
            isAdvertised={Boolean(leaseForm.inputs.vin)}
            leaseForm={leaseForm}
            onSaveCompatibility={handleSaveCompatibility}
            onChange={(skipDelay, isVehicleChange) => {
              handleInputChange(skipDelay, undefined, isVehicleChange);
            }}
            isDisabled={isDisabled}
          />
          <LeaseDates leaseCard={leaseCard} leaseForm={leaseForm} onChange={() => handleInputChange(true)} isDisabled={isDisabled} />
          {(isLexusUser() || isGSTUser()) && <LeaseSpecialEdition leaseCard={leaseCard} leaseForm={leaseForm} onChange={handleInputChange} />}
          <TermSelector leaseCard={leaseCard} leaseForm={leaseForm} allowHide onAddTerm={handleAddLeaseTerm} onHideTerm={handleHideLeaseTerm} />
        </>
      }
      rightHeaderContent={
        <>
          <RcfPnv
            leaseCard={leaseCard}
            leaseForm={leaseForm}
            onInputChange={handleInputChange}
            onUpToSave={handleUptoChangeOrSave}
            onRcfChange={() => updateTfsShare(defaultTfsShare)}
            standardRegionalRcf={standardRegionalRcf}
            setStandardRegionalRcf={setStandardRegionalRcf}
          />
        </>
      }
      rightBodyContent={
        <>
          <LeaseFormLayoutColumn />
          <LeaseFormLayoutColumn rightSection>
            {/* TFS Share */}
            <LeaseInput
              id={`tfs-share-${id}`}
              label={
                <>
                  {leaseForm.isEnhanced && <OATIcon id={`funding-split-${id}`} icon="split" colorTheme="blue" pointer onClick={() => setFundingSplitOpen(true)} />}
                  {leaseForm.isEnhanced && leaseForm.fundingSourceSplits.length > 0 ? 'Blended Share' : `${tfsLabel} Share`}
                </>
              }
              text={`${leaseCard.enhancedCostShare?.tfsCostShare ? `Enhanced: ${leaseCard.enhancedCostShare?.tfsCostShare}%` : `Default: ${defaultTfsShare}%`}`}
              percentageSign
              value={inputs.tfsShare}
              wholeNumber
              error={Boolean(leaseForm.tfsShareError)}
              disabled={!leaseForm.isEnhanced || leaseForm.fundingSourceSplits.length > 0 || isDisabled}
              onValueChange={vals => {
                leaseForm.updateInput('tfsShare', vals.value);
                leaseForm.calculateNonAdGstEstCosts(region, !!programDetailsStore.offering.useOldCostShareForLexus, true);
                leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
                handleUptoChangeOrSave(true);
              }}
            />
            {fundingSplitOpen && (
              <LeaseFundingSplitModal
                leaseCard={leaseCard}
                leaseForm={leaseForm}
                onClose={() => setFundingSplitOpen(false)}
                onSubmit={val => {
                  leaseForm.updateInput('tfsShare', val);
                  leaseForm.calculateNonAdGstEstCosts(region, !!programDetailsStore.offering.useOldCostShareForLexus, true);
                  leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
                  handleUptoChangeOrSave(true);
                }}
              />
            )}
            {FEATURE_OR_5646 && Number(inputs.tfsShare) === 100 && (
              <LeaseInput
                label={<>{tfsLabel} PNV</>}
                dollarSign
                wholeNumber
                value={inputs.tfsEstCost}
                onValueChange={vals => {
                  leaseForm.updateInput('tfsEstCost', Number(vals.value));
                  leaseForm.calculateNonAdGstEstCosts(region, !!programDetailsStore.offering.useOldCostShareForLexus);
                  leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
                  handleInputChange();
                }}
                error={Boolean(leaseForm.tfsEstCostError)}
              />
            )}
          </LeaseFormLayoutColumn>

          <LeaseFormLayoutColumn>
            {/* Term */}
            <LeaseInputWrapper>
              <LeaseInputLabel>Term</LeaseInputLabel>
              <NumberInput id={`term-${id}`} value={leaseCard.term} disabled />
              <LeaseInputDescription>{leaseForm.inputs.isStandalone ? `Default: 36` : `National: ${inputs.nationalTerm}`}</LeaseInputDescription>
            </LeaseInputWrapper>
          </LeaseFormLayoutColumn>

          <LeaseFormLayoutColumn>
            {/* Annual Mileage */}
            <LeaseInputWrapper>
              <LeaseInputLabel>Annual Mileage</LeaseInputLabel>
              <Dropdown
                id="mileage"
                value={formatDollars(inputs.mileage)}
                options={mileages.mileages}
                onChange={val => {
                  leaseForm.updateInput('mileage', val.value);
                  handleInputChange();
                }}
                disabled={isDisabled}
              />
              <LeaseInputDescription>
                {leaseForm.inputs.isStandalone ? `Default: ${formatDollars(leaseForm.defaultMileage)}` : `National: ${formatDollars(leaseForm.inputs.nationalMileage)}`}
              </LeaseInputDescription>
            </LeaseInputWrapper>

            {/* Subvention Cash */}
            <LeaseInput
              label={
                <>
                  {tfsLabel} Subvention Cash {inputs.isSubCashAdvertised && <OATIcon icon="active_offer" colorTheme="orange" />}
                </>
              }
              field="subventionCash"
              value={inputs.subventionCash}
              wholeNumber
              error={Boolean(leaseForm.subCashError)}
              dollarSign
              text={leaseForm.inputs.isStandalone ? undefined : `National: $${formatDollars(leaseForm.inputs.nationalSubventionCash)}`}
              onValueChange={val => {
                leaseForm.updateSubCash(val.value);
                leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
                handleUptoChangeOrSave();
              }}
              disabled={isDisabled || leaseForm.areTfsEnhRatesUsed}
            />
          </LeaseFormLayoutColumn>
          {FEATURE_OR_2477 && leaseCard.enhancedCostFields.showModal && (
            <EnhCostShareModal
              onButtonPress={isConfirmed => {
                leaseCard.updateEnhancedCostShareField('isModalConfirmed', isConfirmed);

                if (isConfirmed) {
                  handleEnhCostData();
                  leaseForm.calculateNonAdGstEstCosts(region, !!programDetailsStore.offering.useOldCostShareForLexus);
                  leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
                }

                handleUptoChangeOrSave(true);
              }}
            />
          )}
          {FEATURE_OR_2477 && leaseCard.enhancedCostFields.removingReason && (
            <EnhCostShareModal
              onButtonPress={isConfirmed => {
                leaseCard.updateEnhancedCostShareField('isModalConfirmed', isConfirmed);

                handleEnhCostData();
                leaseForm.calculateNonAdGstEstCosts(region, !!programDetailsStore.offering.useOldCostShareForLexus);
                leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);

                handleUptoChangeOrSave(true);
              }}
              submitBtnText="OK"
              text={`${leaseCard.enhancedCostFields.removingReason} National Enhanced Cost Share will be removed. Do you want to continue?`}
              title="Remove Enhanced Cost Share"
            />
          )}
          {FEATURE_OR_4154 && leaseCard.enhancedTfsCostFields.showModal && (
            <EnhCostShareModal
              title={`Enhanced ${tfsLabel} Cost Share Available`}
              text={`Offer is eligible for National Enhanced ${tfsLabel} Cost Share. Do you want to apply?`}
              onButtonPress={isConfirmed => {
                leaseCard.updateEnhancedTfsCostShareField('isModalConfirmed', isConfirmed);
                handleEnhTfsCostData();

                if (isConfirmed) {
                  setStandardRegionalRcf(leaseForm.inputs.standardRegionalRcf);
                  leaseForm.calculateNonAdGstEstCosts(region, !!programDetailsStore.offering.useOldCostShareForLexus);
                  leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);
                }

                handleUptoChangeOrSave(false);
              }}
            />
          )}
          {FEATURE_OR_4154 && leaseCard.enhancedTfsCostFields.removingReason && (
            <EnhCostShareModal
              onButtonPress={isConfirmed => {
                leaseCard.updateEnhancedTfsCostShareField('isModalConfirmed', isConfirmed);

                handleEnhTfsCostData();
                leaseForm.calculateNonAdGstEstCosts(region, !!programDetailsStore.offering.useOldCostShareForLexus);
                leaseForm.updateOfferCosts(programDetailsStore.ryoEarnings);

                handleUptoChangeOrSave(true);
              }}
              text={`Dates selected are ineligible for National Enhanced ${tfsLabel} Cost Share. National Enhanced ${tfsLabel} Cost Share will be removed. Do you want to continue?`}
              submitBtnText="OK"
              title={`Enhanced ${tfsLabel} Cost Share Removed`}
            />
          )}
        </>
      }
    />
  );
};
export default observer(LeaseNonAdForm);
