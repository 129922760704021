export const getLeaseConfigDisplayList = (config: string) => {
  if (!config) {
    return [];
  }

  return config.split('|').map(item => {
    const itemSplit = item.split('#');
    return itemSplit.length === 1 ? itemSplit[0] : `${itemSplit[1]} (${itemSplit[0]})`;
  });
};

export const getLeaseConfigList = (config: string) => {
  const codes = config.split('|').map(item => {
    return item.split('#')[0];
  });

  codes.sort();
  return codes.join(', ');
};
