import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children?: ReactNode;
}

export const LeaseFormLayout = ({ children }: Props) => {
  return <div className={styles.leaseFormLayout}>{children}</div>;
};

export const LeaseFormLayoutHeader = ({ children }: Props) => {
  return <div className={styles.header}>{children}</div>;
};

export const LeaseFormLayoutBody = ({ children }: Props) => {
  return <div className={styles.body}>{children}</div>;
};

export const LeaseFormLayoutContent = ({ children }: Props) => {
  return <div className={styles.content}>{children}</div>;
};

interface ColumnProps extends Props {
  rightSection?: boolean;
}

export const LeaseFormLayoutColumn = ({ children, rightSection = false }: ColumnProps) => {
  return <div className={clsx(styles.column, rightSection && styles.rightSection)}>{children}</div>;
};
