import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { AdminConstants } from 'oat-admin-common';
import { Button, formatDollars, OATIcon } from 'oat-common-ui';
import { ChangeEvent, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import NumberInput from '../../../../../../components/NumberInput';
import useStores from '../../../../../../stores/useStores';
import validator from '../../../../../../utils/validator';
import LeaseFormModel from '../../../../models/LeaseFormModel';
import GrossCapCostTooltipMessage from '../Tooltips/GrossCapCostTooltipMessage';
import NetCapCostTooltipMessage from '../Tooltips/NetCapCostTooltipMessage';
import PortsTooltipMessage from '../Tooltips/PortsTooltipMessage';
import RVAmountTooltipMessage from '../Tooltips/RVAmountTooltipMessage';
import TotalDealerGrossProceedsTooltipMessage from '../Tooltips/TotalDealerGrossProceedsTooltipMessage';
import styles from './styles.module.scss';

interface Props {
  leaseForm: LeaseFormModel;
  isSET: boolean;
  valueOnChange: (skipDelay?: boolean) => void;
  isDisabled?: boolean;
}

const CostInfo = ({ leaseForm, isSET, valueOnChange, isDisabled }: Props) => {
  const { userInfoStore } = useStores();
  const { uid, inputs, updateResiduals, updateTotalMsrp, updateInput, updateInputWithCalc } = leaseForm;
  const [editRV, setEditRV] = useState(false);
  const [rv, setRv] = useState(inputs.residualRate);
  const [editTotalMsrp, setEditTotalMsrp] = useState(false);
  const [totalMsrp, setTotalMsrp] = useState(inputs.totalMsrp.toString());
  const [editDealerInvoice, setEditDealerInvoice] = useState(false);
  const [dealerInvoice, setDealerInvoice] = useState(inputs.dealerCostPerInvoice.toString());
  const [showPorts, setShowPorts] = useState(false);
  const target = useRef(null);
  const ports = inputs.port
    .split(', ')
    .map(item => AdminConstants.PortsMap[item])
    .join(', ');

  const rvError = validator(rv, { required: true, min: 0 });
  const totalMsrpError = validator(totalMsrp, { required: true, min: 0 });
  const dealerInvoiceError = validator(dealerInvoice, { required: true, min: 0 });

  const handlePortsClose = () => {
    setShowPorts(false);
    updateInput('port', inputs.port);
    valueOnChange();
  };

  const handleToggleTotalMsrp = () => {
    return () => {
      setTotalMsrp(inputs.totalMsrp.toString());
      setEditTotalMsrp(true);
    };
  };

  const handleTotalMsrpChange = (val: string) => {
    setTotalMsrp(val);
  };

  const submitTotalMsrp = () => {
    if (!totalMsrpError) {
      setEditTotalMsrp(false);
      if (totalMsrp !== inputs.totalMsrp.toString()) {
        updateTotalMsrp(Number(totalMsrp));
        valueOnChange();
      }
    }
  };

  const handleToggleDealerInvoice = () => {
    return () => {
      setDealerInvoice(inputs.dealerCostPerInvoice.toString());
      setEditDealerInvoice(true);
    };
  };

  const handleDealerInvoiceChange = (val: string) => {
    setDealerInvoice(val);
  };

  const submitDealerInvoice = () => {
    if (!dealerInvoiceError) {
      setEditDealerInvoice(false);
      if (dealerInvoice !== inputs.dealerCostPerInvoice.toString()) {
        updateInputWithCalc('dealerCostPerInvoice', Number(dealerInvoice));
        valueOnChange();
      }
    }
  };

  const handleToggleRv = () => {
    return () => {
      setRv(inputs.residualRate);
      setEditRV(true);
    };
  };

  const handleRvChange = () => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      setRv(e.currentTarget.value);
    };
  };

  const submitRv = () => {
    if (!rvError) {
      setEditRV(false);
      if (rv !== inputs.residualRate) {
        updateResiduals(Number(rv));
        valueOnChange();
      }
    }
  };

  return (
    <div className={styles.costsPanel}>
      {!isSET && (
        <div className={styles.costInfo}>
          <label htmlFor="ports" className={styles.costLabel}>
            Ports
          </label>

          <span className={clsx(styles.values, ports.length > 100 && styles.portsValues)}>
            {
              <div ref={target} className={inputs.port ? styles.portsDisplay : ''}>
                <Button variant="text" onClick={() => setShowPorts(!showPorts)} className={styles.iconBtn} disabled={isDisabled}>
                  <OATIcon icon="edit" colorTheme="blue" />
                </Button>
                <span className={styles.valueLabel}>{!inputs.port ? 'None' : <div>{ports}</div>}</span>
                <Overlay target={target.current} show={showPorts} placement="bottom" onHide={handlePortsClose} rootClose>
                  <Popover id={`ports-${uid}`} className={styles.portsTooltip}>
                    <PortsTooltipMessage leaseDetails={leaseForm} />
                  </Popover>
                </Overlay>
              </div>
            }
          </span>
        </div>
      )}

      <div className={styles.costInfo}>
        <label htmlFor="totalMsrp" className={styles.costLabel}>
          Total MSRP
        </label>
        <span className={styles.values}>
          {editTotalMsrp ? (
            <>
              <Button variant="text" onClick={submitTotalMsrp} className={styles.iconBtn}>
                <FontAwesomeIcon icon={faCheckCircle} className={styles.circleCheck} />
              </Button>
              <NumberInput
                id={`total-msrp-input-${uid}`}
                wholeNumber
                value={totalMsrp}
                className={styles.costInput}
                onValueChange={val => handleTotalMsrpChange(val.value)}
                error={Boolean(totalMsrpError)}
                autoFocus
                selectOnFocus
                units
              />
            </>
          ) : (
            <>
              {isSET && (
                <Button onClick={handleToggleTotalMsrp()} className={styles.iconBtn} disabled={isDisabled}>
                  <OATIcon icon="edit" colorTheme="blue" />
                </Button>
              )}
              <span className={styles.valueLabel}>${formatDollars(inputs.totalMsrp)}</span>
            </>
          )}
        </span>
      </div>

      <div className={styles.costInfo}>
        <label htmlFor="rvRate" className={styles.costLabel}>
          Residual Value Rate
        </label>
        <span className={styles.values}>
          {editRV ? (
            <>
              <FontAwesomeIcon icon={faCheckCircle} onClick={submitRv} className={styles.circleCheck} />
              <NumberInput
                id={`rv-rate-input-${uid}`}
                wholeNumber
                value={rv}
                className={styles.costInput}
                onChange={handleRvChange()}
                error={Boolean(rvError)}
                autoFocus
                selectOnFocus
                max={100}
              />
            </>
          ) : (
            <>
              {!isSET && (
                <Button onClick={handleToggleRv()} className={styles.iconBtn} disabled={isDisabled}>
                  <OATIcon icon="edit" colorTheme="blue" />
                </Button>
              )}
              <span className={styles.valueLabel}>{inputs.residualRate}</span>
              <span>%</span>
            </>
          )}
        </span>
      </div>

      <div className={styles.costInfo}>
        <span className={styles.tooltipContainer}>
          <InfoTooltip id={`rv-amt-tip-${uid}`} message={<RVAmountTooltipMessage leaseDetails={leaseForm} />} />
          <span className={styles.valueLabel}>Residual Value Amount</span>
        </span>
        <span className={styles.values}>
          <span className={styles.valueLabel}>${formatDollars(inputs.residualAmount)}</span>
        </span>
      </div>

      {isSET && (
        <div className={styles.costInfo}>
          <span className={styles.tooltipContainer}>
            <span className={styles.valueLabel}>Enhanced Residual Value Rate</span>
          </span>
          <span className={styles.values}>
            <span className={styles.valueLabel}>{formatDollars(leaseForm.totalResidualRate)}%</span>
          </span>
        </div>
      )}

      {isSET && (
        <div className={styles.costInfo}>
          <span className={styles.tooltipContainer}>
            <InfoTooltip id={`rv-amt-tip-${uid}`} message={<RVAmountTooltipMessage leaseDetails={leaseForm} isEnhanced />} />
            <span className={styles.valueLabel}>Enhanced Residual Value Amount</span>
          </span>
          <span className={styles.values}>
            <span className={styles.valueLabel}>${formatDollars(inputs.enhancedResidualAmount)}</span>
          </span>
        </div>
      )}

      {isSET && (
        <div className={styles.costInfo}>
          <span className={styles.tooltipContainer}>
            <span className={styles.valueLabel}>Dealer Cost Per Invoice</span>
          </span>
          <span className={styles.values}>
            {editDealerInvoice ? (
              <>
                <FontAwesomeIcon icon={faCheckCircle} onClick={submitDealerInvoice} className={styles.circleCheck} />
                <NumberInput
                  id={`dealer-invoice-input-${uid}`}
                  wholeNumber
                  value={dealerInvoice}
                  className={styles.costInput}
                  onValueChange={val => handleDealerInvoiceChange(val.value)}
                  error={Boolean(dealerInvoiceError)}
                  autoFocus
                  selectOnFocus
                  units
                />
              </>
            ) : (
              <>
                {!editTotalMsrp && (
                  <Button onClick={handleToggleDealerInvoice()} disabled={isDisabled} className={styles.iconBtn}>
                    <OATIcon icon="edit" colorTheme="blue" />
                  </Button>
                )}
                <span className={styles.valueLabel}>${formatDollars(inputs.dealerCostPerInvoice)}</span>
              </>
            )}
          </span>
        </div>
      )}

      <div className={styles.costInfo}>
        <span className={styles.tooltipContainer}>
          <InfoTooltip id={`gcc-${uid}`} message={<GrossCapCostTooltipMessage leaseDetails={leaseForm} />} />
          <span className={styles.valueLabel}>Gross Cap Cost</span>
        </span>
        <span className={styles.values}>
          <span className={styles.valueLabel}>${formatDollars(inputs.grossCapCost)}</span>
        </span>
      </div>

      <div className={styles.costInfo}>
        <span className={styles.tooltipContainer}>
          <InfoTooltip id={`ncc-${uid}`} message={<NetCapCostTooltipMessage leaseDetails={leaseForm} />} />
          <span className={styles.valueLabel}>Net Cap Cost</span>
        </span>
        <span className={styles.values}>
          <span className={styles.valueLabel}>${formatDollars(inputs.netCapCost)}</span>
        </span>
      </div>

      <div className={styles.costInfo}>
        <span className={styles.tooltipContainer}>
          <InfoTooltip id={`tot-dg-proceeds-${uid}`} message={<TotalDealerGrossProceedsTooltipMessage leaseDetails={leaseForm} />} />
          <span className={styles.valueLabel}>Total Dealer Gross/Proceeds</span>
        </span>
        <span className={styles.values}>
          <span className={styles.valueLabel}>${formatDollars(inputs.totalDealerGross)}</span>
        </span>
      </div>

      <div className={styles.costInfo}>
        <label htmlFor="rvRate" className={styles.costLabel}>
          {userInfoStore.getMarketingTeam()}
        </label>
        <span className={styles.values}>
          <span className={styles.valueLabel}>${formatDollars(inputs.tdaFee)}</span>
        </span>
      </div>

      <div className={styles.costInfo}>
        <label htmlFor="dph" className={styles.costLabel}>
          DPH
        </label>
        <span className={styles.values}>
          <span className={styles.valueLabel}>${formatDollars(inputs.dph)}</span>
        </span>
      </div>
    </div>
  );
};

export default CostInfo;
