import { DefaultModal, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { Offer, OfferingCosts, useResetToPreviousRegionalOfferMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import styles from './styles.module.scss';

interface Props {
  id: string;
  rev: string;
  onClose: () => void;
  onSubmit: (offer: Offer) => void;
}

const modalMessage =
  "Resetting to Previous Regional Offer will return the offer back to Previous Regional Offer state, but will also remove any modifications you've entered for the offer";

const ResetToPrevRegionalModal = ({ id, rev, onClose, onSubmit }: Props) => {
  const {
    offeringCostsStore: { setData },
  } = useStores();
  const [resetToPrevious] = useResetToPreviousRegionalOfferMutation();
  const { error } = useToast();

  const handleSubmit = async () => {
    try {
      const res = await trackPromise(resetToPrevious({ variables: { input: { id, rev } } }));
      setData(res.data?.resetToPreviousRegionalOffer.offeringCosts as OfferingCosts);
      onSubmit(res.data?.resetToPreviousRegionalOffer?.offer as Offer);
    } catch (e) {
      error((e as Error).message);
    } finally {
      onClose();
    }
  };

  return (
    <DefaultModal
      isWarning
      message={modalMessage}
      modalBodyClassName={styles.modal}
      onClose={onClose}
      onSubmit={handleSubmit}
      open
      submitText="Reset"
      title="Reset to Previous Regional Offer"
    />
  );
};

export default ResetToPrevRegionalModal;
