import cx from 'clsx';
import { BlockInputLabel, CustomDatePicker, dateFormatISOString } from 'oat-common-ui';
import styles from './styles.module.scss';

interface StartEndDateProps {
  startDate: Date | undefined;
  endDate: Date | undefined;
  setStartDate: (value: Date | undefined) => void;
  setEndDate: (value: Date | undefined) => void;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  datePickerClass?: string;
  isDark?: boolean;
  startDateError?: boolean;
  endDateError?: boolean;
  labelClass?: string;
  dateWrapperClass?: string;
  disabled?: boolean;
  dateClass?: string;
  isFullWidth?: boolean;
}

const StartEndDate = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  minDate,
  maxDate,
  datePickerClass,
  isDark = false,
  startDateError = false,
  endDateError = false,
  labelClass,
  dateWrapperClass,
  disabled,
  dateClass,
  isFullWidth,
}: StartEndDateProps) => {
  return (
    <div className={cx(styles.dateWrapper, dateWrapperClass)}>
      <div className={cx(styles.date, dateClass)}>
        <BlockInputLabel label="Start Date:" className={labelClass}>
          <CustomDatePicker
            className={datePickerClass}
            darkTheme={isDark}
            id="offer-start-date-picker"
            minDate={minDate}
            maxDate={maxDate}
            selected={startDate}
            onChange={setStartDate}
            error={startDateError}
            disabled={disabled}
            isFullWidth={isFullWidth}
          />
        </BlockInputLabel>
        <p className={styles.defaultValueLabel}>Period starts: {dateFormatISOString(minDate)}</p>
      </div>
      <div className={cx(styles.date, dateClass)}>
        <BlockInputLabel label="End Date:" className={labelClass}>
          <CustomDatePicker
            className={datePickerClass}
            darkTheme={isDark}
            id="offer-end-date-picker"
            minDate={minDate}
            maxDate={maxDate}
            selected={endDate}
            onChange={setEndDate}
            error={endDateError}
            disabled={disabled}
            isFullWidth={isFullWidth}
          />
        </BlockInputLabel>
        <p className={styles.defaultValueLabel}>Period ends: {dateFormatISOString(maxDate)}</p>
      </div>
    </div>
  );
};

export default StartEndDate;
