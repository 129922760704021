import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { Input, OATIcon } from 'oat-common-ui';
import { ChangeEvent, KeyboardEvent, ReactNode, useRef, useState } from 'react';
import useOutsideClick from '../../../../components/SettingsMenu/useOutsideClick';
import styles from './styles.module.scss';

const { OfferTypes } = AdminConstants;

type Props = {
  className?: string;
  enhancedDates?: string; // show when dates differ from national
  offerSource: string;
  offerType: string; // TODO: use enum for offerTypes
  children?: ReactNode; // pass down balances and context menu
  nationalNote?: string;
  titleOnly?: boolean;
  isTitleEditable?: boolean;
  title?: string; // lease/apr needs to show more than just offer type
  titleSufix?: string; // lease/apr needs to show more than just offer type
  setTitle?: (title: string) => void;
};

const OfferHeader = ({ title, titleSufix, className, offerType, offerSource, children, nationalNote, enhancedDates, titleOnly, isTitleEditable, setTitle }: Props) => {
  const [titleInput, setTitleInput] = useState(title || '');
  const [isEditMode, setIsEditMode] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const titleError = !titleInput;

  const closeInput = () => {
    const trimTitle = titleInput.trim();
    if (setTitle && title !== trimTitle) {
      setTitle(trimTitle);
    }
    setIsEditMode(false);
  };

  useOutsideClick(ref, () => {
    if (isEditMode && !titleError) {
      closeInput();
    }
  });

  const closeOnEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !titleError) {
      event.preventDefault();
      closeInput();
    }
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setTitleInput(e.target.value);
  };

  const getHeaderStyles = (offerType: string) => {
    let offerStyles = undefined;
    switch (offerType) {
      case OfferTypes.CUSTOMER_CASH:
      case OfferTypes.MISCELLANEOUS: {
        offerStyles = styles.cash;
        break;
      }
      case OfferTypes.APR: {
        offerStyles = styles.apr;
        break;
      }
      case OfferTypes.LEASE: {
        offerStyles = styles.lease;
        break;
      }
      default: {
        offerStyles = undefined;
      }
    }

    return cx(isEditMode ? styles.paddingNone : undefined, styles.offerHeader, offerStyles, className);
  };

  // renders input box
  const getHeaderTitle = () => {
    if (isEditMode) {
      return <Input autoFocus onKeyPress={closeOnEnterPress} type="text" value={titleInput} onChange={onChangeHandler} error={titleError} />;
    }

    return <h3>{title}</h3>;
  };

  return (
    <div>
      <div className={getHeaderStyles(offerType)}>
        {/* editable section (ex: stand alone offer) */}
        {isTitleEditable ? (
          <div className={styles.title} onClick={() => setIsEditMode(true)} ref={ref}>
            {/* toggle icon */}
            <OATIcon icon="edit" className={cx(styles.icon, isEditMode && styles.hidden)} />

            {/* input box */}
            {getHeaderTitle()}

            <div className={cx(styles.titleDetails, isEditMode && styles.titleDetailsGreyed)}>
              <span>{titleSufix}</span>
              {offerSource && (
                <>
                  <span className={styles.divider}>|</span>
                  <span className={styles.offerSource}>{offerSource}</span>
                </>
              )}
              {enhancedDates && (
                <>
                  <span className={styles.divider}>|</span>
                  <span className={styles.offerSource}>{enhancedDates}</span>
                </>
              )}
            </div>
          </div>
        ) : (
          //  non editable title (ex: national offer)
          <div className={styles.LeftSide}>
            <h3>{`${title} ${titleSufix || ''}` ?? offerType}</h3>
            <span>{offerSource}</span>
            {enhancedDates && <span>{enhancedDates}</span>}
          </div>
        )}

        {/* financial data */}
        {!titleOnly && <div className={styles.rightSide}>{children}</div>}
      </div>
      <div>
        <p className={styles.headerNote}>{nationalNote}</p>
      </div>
    </div>
  );
};

export default observer(OfferHeader);
