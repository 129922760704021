import { SeriesProfile } from '../../../../gql/generated';
import ProfileModel from '../../models/ProfileModel';
import RgnlAltModel from '../../models/RgnlAltModel';

export const getSeriesProfileHeaderStatus = (selectedRa: RgnlAltModel) => {
  let hasNationalEnhanced = false;
  let hasNationalAdvertised = false;
  let hasRegionalEnhanced = false;
  let hasRegionalAdvertised = false;
  let hasNationalOffers = false;

  [...selectedRa.cash, ...selectedRa.lease, ...selectedRa.apr, ...selectedRa.misc].forEach(offer => {
    if (offer.isStandAlone) {
      if (offer.isEnhanced) {
        hasRegionalEnhanced = true;
      } else if (offer.isAdvertised) {
        hasRegionalAdvertised = true;
      }
    } else {
      hasNationalOffers = true;

      if (offer.isEnhanced) {
        hasNationalEnhanced = true;
      }
      if (offer.isAdvertised) {
        hasNationalAdvertised = true;
      }
    }
  });

  if (hasNationalOffers) {
    return `${hasNationalAdvertised ? 'Advertised' : 'Non-Advertised'} ${hasNationalEnhanced ? 'Enhanced' : ''} National Offer`;
  } else {
    return `${hasRegionalAdvertised ? 'Advertised' : 'Non-Advertised'} ${hasRegionalEnhanced ? 'Enhanced' : ''} Regional Offer`;
  }
};

export const updateAllRgnlAltRevs = (seriesProfile: SeriesProfile, profileModel: ProfileModel) => {
  const resRgnlAlts = seriesProfile.rgnlAlts;
  const modelRgnlAlts = profileModel.rgnlAlts;

  if (resRgnlAlts && resRgnlAlts.length > 0) {
    for (const resRgnlAlt of resRgnlAlts) {
      const foundRgnlAlt = modelRgnlAlts.find(m => m.id === resRgnlAlt.id);

      if (foundRgnlAlt) {
        foundRgnlAlt.updateRev(resRgnlAlt.rev);
      }
    }
  }
};
