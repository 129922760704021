import { AdminConstants } from 'oat-admin-common';
import { LeaseDetails, LeaseRates } from '../../../gql/generated';
import { assignNumberValue } from '../../../utils/assignValue';

const { STANDARD_TERMS } = AdminConstants;

export const parseRcfsFromRates = (rates: LeaseRates[], isByTier = false) => {
  const found = rates.find(i => i.tier === '1+');

  if (isByTier) {
    return {
      standardRcf: assignNumberValue(rates[0].standardRcf),
      subventedRcf: assignNumberValue(rates[0].subventedRcf),
    };
  }

  if (found) {
    return {
      standardRcf: assignNumberValue(found.standardRcf),
      subventedRcf: assignNumberValue(found.subventedRcf),
    };
  }

  return {
    standardRcf: 0.00001,
    subventedRcf: 0.00001,
  };
};

/**
 * If regionalTerm is standard or odd term, just compare to national's high term
 * If national not found and regionalTerm is odd term, only find national leases that are between national's low/high term.
 * @param nationalLeases
 * @param regionalTerm
 * @returns
 */
export const findNationalLeaseByTerm = (nationalLeases: LeaseDetails[], regionalTerm: number) => {
  const found = nationalLeases.find(item => regionalTerm >= item.lowTerm && regionalTerm <= item.highTerm);
  if (!found && !STANDARD_TERMS.includes(regionalTerm)) {
    return nationalLeases.find(item => regionalTerm >= item.lowTerm && regionalTerm <= item.highTerm);
  }

  return found;
};
