import { observer } from 'mobx-react-lite';
import { BlockInputLabel, Dropdown, OATWrapper, Textarea, useInputDelay, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { Status } from '../../../../constants/global';
import { Offering, useSaveOfferingNationalNoteMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import styles from './styles.module.scss';

interface Props {
  offering: Offering;
}

const NotesHeader = ({ offering }: Props) => {
  const { summaryStore } = useStores();
  const [saveOfferingNationalNote] = useSaveOfferingNationalNoteMutation();
  const { setDelay } = useInputDelay();
  const { error } = useToast();

  const handleSaveOfferingNationalNote = (nationalNote: string) => {
    const { id, rev } = offering;
    offering.nationalNote = nationalNote;

    if (nationalNote) {
      setDelay(async () => {
        try {
          const res = await trackPromise(
            saveOfferingNationalNote({
              variables: {
                input: {
                  id,
                  rev,
                  nationalNote: offering.nationalNote || '',
                },
              },
            }),
          );
          summaryStore.updateOfferingRev(id, res.data?.saveOfferingNationalNote.offering?.rev || '');
        } catch (e) {
          error((e as Error).message);
        }
      });
    }
  };

  return (
    <OATWrapper>
      <div className={styles.leftSide}>
        <BlockInputLabel labelClass={styles.labelClass} labelComponent={<span className={styles.inputLabel}>National Note</span>}>
          <Textarea
            id={`${offering.id}-national-note-input`}
            className={styles.noteInput}
            value={offering.nationalNote || ''}
            onChange={e => handleSaveOfferingNationalNote(e.target.value)}
          />
        </BlockInputLabel>
        <div className={styles.regionalNoteContainer}>
          <BlockInputLabel labelClass={styles.labelClass} labelComponent={<span className={styles.inputLabel}>Regional Note</span>}>
            <span className={styles.nationalNote}>{offering.note || 'Regional Note'}</span>
          </BlockInputLabel>
        </div>
      </div>

      <div className={styles.rightSide}>
        {offering.status !== Status.NO_RESPONSE && (
          <div className={styles.dropdownContainer}>
            <Dropdown options={summaryStore.dropdownOptions} id="statusDropdown" value={summaryStore.dropdownValue} onChange={summaryStore.selectDropdownOption} />
          </div>
        )}
      </div>

      <div className={styles.line} />
    </OATWrapper>
  );
};

export default observer(NotesHeader);
