import { observer } from 'mobx-react-lite';
import { ENR_ERROR_MESSAGE } from '../../../../../constants/global';
import useGetTfsLabel from '../../../../../utils/useGetTfsLabel';
import LeaseCardModel from '../../../models/LeaseCardModel';
import LeaseFormModel from '../../../models/LeaseFormModel';
import CardError from '../../CardError';
import leaseErrorUtils from './leaseErrorUtils';

const { DAS_DP_ERROR, noteError, rcfMinError, rcfMaxError, tpMinError, dgMinError, subCashMinError } = leaseErrorUtils;

interface Props {
  leaseCard: LeaseCardModel;
  leaseForm: LeaseFormModel;
}

const LeaseErrorMessages = ({ leaseCard, leaseForm }: Props) => {
  const tfsLabel = useGetTfsLabel();
  const isNonGstAdvertised = !leaseForm.isGST && leaseForm.inputs.isAdvertised;

  return (
    <>
      {leaseForm.rcfError?.min && <CardError message={rcfMinError(leaseForm.inputs.term)} />}
      {leaseForm.rcfError?.max && <CardError message={rcfMaxError(leaseForm.inputs.term)} />}
      {leaseForm.subCashError?.min && <CardError message={subCashMinError(leaseForm.inputs.term)} />}
      {leaseForm.areTfsEnhRatesUsed && leaseForm.inputs.note === '' && <CardError message={noteError(tfsLabel)} />}

      {/* GST and Advertised handles target payment */}
      {leaseForm.inputs.isAdvertised && leaseForm.tpError?.min && <CardError message={tpMinError(leaseForm.inputs.term)} />}

      {/* Non-Gst Advertised only */}
      {isNonGstAdvertised && leaseForm.dgError?.min && <CardError message={dgMinError(leaseForm.inputs.term)} />}
      {isNonGstAdvertised && leaseForm.dpError?.min && <CardError message={DAS_DP_ERROR} />}
      {!leaseForm.isValidEnR && leaseCard.isDuplicate && <CardError message={ENR_ERROR_MESSAGE} />}
    </>
  );
};

export default observer(LeaseErrorMessages);
