import { makeAutoObservable } from 'mobx';
import { uuidv4 } from 'oat-common-ui';
import { FundingSourceSplitsInput } from '../../../../../../gql/generated';
import { assignStringValue } from '../../../../../../utils/assignValue';
import { Rate } from '../../../../models/AprCardModel';
import AprTermModel from '../../../../models/AprTermModel';
import AprTierModel from '../../../../models/AprTierModel';
import FundingSplitTerm, { FundingSplitItem } from '../../../FundingSplitsModal/FundingSplitTerm';
import { calculateBlendedShare } from '../../../FundingSplitsModal/utils';

class AprFundingModel {
  uid = uuidv4();
  enhancedTerms: AprTermModel[] = [];

  activeTerm: number = 24;
  activeTierModel: AprTierModel;
  activeFundingTerm: FundingSplitTerm | undefined;

  fundingTerms: FundingSplitTerm[] = [];

  constructor(tier: AprTierModel, standardRates: Rate[]) {
    makeAutoObservable(this);

    this.activeTierModel = tier;
    this.initData(standardRates);
  }

  initData(standardRates: Rate[]) {
    const enhancedTerms = this.activeTierModel?.terms.filter(term => term.isEnhanced);
    this.enhancedTerms = enhancedTerms;

    const fundingTerms = enhancedTerms.map(
      term => new FundingSplitTerm(term.fields.term, this.getSplits(term, this.getStandardRateForTerm(term.fields.term, standardRates)), this.activeTierModel.tier),
    );

    this.fundingTerms = fundingTerms;
    this.activeTerm = enhancedTerms[0].fields.term;
    this.activeFundingTerm = fundingTerms.find(fundingTerm => fundingTerm.term === enhancedTerms[0].fields.term);
  }

  getSplits(term: AprTermModel, standardRate: string) {
    const splits: FundingSplitItem[] = [];

    // Adding standard Rate row
    const stdSplit = new FundingSplitItem();
    stdSplit.from = assignStringValue(standardRate);
    stdSplit.to = assignStringValue(term.fields.buyRate);
    stdSplit.tfsShare = '100';
    stdSplit.disableRateInput = true;
    stdSplit.disableTfsShare = true;
    splits.push(stdSplit);

    if (term.fields.fundingSourceSplits.length > 0) {
      term.fields.fundingSourceSplits.forEach((item, index) => {
        const newSplit = new FundingSplitItem();
        newSplit.from = assignStringValue(item.from);
        newSplit.to = assignStringValue(item.to);
        newSplit.tfsShare = assignStringValue(item.tfsShare);
        newSplit.disableRateInput = index === term.fields.fundingSourceSplits.length - 1;

        splits.push(newSplit);
      });
    } else {
      const newSplit = new FundingSplitItem();
      newSplit.from = assignStringValue(term.fields.buyRate);
      newSplit.to = assignStringValue(term.fields.rate);
      newSplit.tfsShare = assignStringValue(term.fields.tfsShare);
      newSplit.disableRateInput = true;

      splits.push(newSplit);
    }

    return splits;
  }

  setActiveTerm(term: AprTermModel) {
    this.activeTerm = term.fields.term;

    const activeTerm = this.fundingTerms.find(fundingItem => fundingItem.term === term.fields.term);
    this.activeFundingTerm = activeTerm;
  }

  getFundingSplitsPayloadData = (
    id: string,
    rev: string,
  ): {
    payload: {
      id: string;
      rev: string;
      fundingSourceSplits: FundingSourceSplitsInput[];
    };
    tfsShares: Array<{
      term: number;
      tfsShare: number;
      tier: string;
    }>;
  } => {
    const tier = this.activeTierModel.tier;

    const payload = {
      id,
      rev,
      fundingSourceSplits: this.fundingTerms
        // If there is no added splits, send nothing
        .filter(term => term.splits.length > 2)
        .map(fundingTerm => {
          return {
            splits: fundingTerm.splits
              // We don't want to save default split
              .filter(split => !split.disableTfsShare)
              .map(split => {
                return { to: Number(split.to), from: Number(split.from), tfsShare: Number(split.tfsShare) };
              }),
            term: fundingTerm.term,
            tier,
          };
        }),
    };

    const tfsShares = this.fundingTerms.map(fundingTerm => {
      return {
        term: fundingTerm.term,
        tfsShare: fundingTerm.splits.length > 2 ? calculateBlendedShare(fundingTerm.splits) : Number(fundingTerm.splits[1].tfsShare),
        tier,
      };
    });

    return {
      tfsShares,
      payload,
    };
  };

  getStandardRateForTerm(term: number, standardRates: Rate[]): string {
    const rate = standardRates.find(rate => rate.term === term && rate.tier === this.activeTierModel.tier)?.rate;
    return rate?.toFixed(2) || '0';
  }

  isTermBlended(term: number) {
    const inspectedTerm = this.fundingTerms.find(fundingTerm => fundingTerm.term === term);
    return inspectedTerm && inspectedTerm.splits.length > 2;
  }

  get hasError() {
    return this.fundingTerms.some(item => item.hasError);
  }
}

export default AprFundingModel;
