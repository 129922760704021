import { SideNav, SideNavLayout, SideNavLogo } from 'oat-common-ui';
import { ReactNode, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { HELP_URL } from '../../constants/global';
import getNationalLink from '../../utils/getNationalLink';
import useUserInfo from '../../utils/useUserInfo';

type Props = {
  children?: ReactNode;
};

const MainSideNav = ({ children }: Props) => {
  const { isLexusUser } = useUserInfo();
  const isLowerEnv = useMemo(() => {
    return window.location.hostname.indexOf('nonprod') !== -1 || window.location.hostname.indexOf('localhost') !== -1;
  }, []);

  const sideNav = (
    <SideNav isProd={!isLowerEnv}>
      <SideNavLogo isLexus={isLexusUser()} />
      <nav>
        <p>FY Rollup</p>
        <nav>
          <a href={getNationalLink('ngc/approved')}>Approved</a>
          <a href={getNationalLink('ngc/forecasted')}>Forecasted</a>
          <a href={getNationalLink('ngc/working')}>Working</a>
          <a href={getNationalLink('ngc/settings/bosu')}>BOSU</a>
          <a href={getNationalLink('ngc/settings/assumptions')}>Assumptions</a>
          <a href={getNationalLink('ngc/settings/multi_series_offers')}>Multi-Series Offers</a>
        </nav>
        <a href={getNationalLink('dashboard')}>National Programs</a>
        <NavLink to="/dashboard">Regional Review</NavLink>
        <p>Reports</p>
        <nav>
          <a href={getNationalLink('reports')}>National Planning</a>
          <NavLink to="/reports/incentivePeriods">Incentive Periods</NavLink>
        </nav>
        <p>Settings</p>
        <nav>
          <a href={getNationalLink('settings/personal')}>Personal</a>
          <a href={getNationalLink('ngc/settings/pnvs_threshold')}>PNVS Threshold</a>
          <a href={getNationalLink('ngc/settings/series_sorting')}>Series Sorting</a>
          <a href={getNationalLink('ngc/settings/series_mapping')}>Series Mapping</a>
          <a href={getNationalLink('ngc/settings/state_apr_caps')}>State APR Caps</a>
          <a href={getNationalLink('ngc/settings/incentive_calender')}>Incentive Calendar</a>
        </nav>
        <a href={HELP_URL} target="_blank" rel="noreferrer">
          Help
        </a>
      </nav>
    </SideNav>
  );

  return (
    <SideNavLayout sideNav={sideNav}>
      {/* content */}
      {children}
    </SideNavLayout>
  );
};

export default MainSideNav;
