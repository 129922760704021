import { Link, NavLink } from 'react-router-dom';

export const SettingsNav = (isNational = false) => {
  const settingsNavList = [{ name: '', linkComponent: <NavLink to={`/settings/personal`}>Personal Settings</NavLink> }];

  if (!isNational) {
    settingsNavList.push({ name: '', linkComponent: <NavLink to={`/settings/dealerGross`}>Dealer Gross</NavLink> });
    settingsNavList.push({ name: '', linkComponent: <NavLink to={`/settings/seriesSorting`}>Series Sorting</NavLink> });
  }

  return settingsNavList;
};

export const SettingsBreadCrumbs = (title: string, tdaCode: string) => [{ name: '', component: <Link to={`/${tdaCode}/dashboard`}>{title}</Link> }, { name: 'Settings' }];
