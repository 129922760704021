import { observer } from 'mobx-react-lite';
import { BlockInputLabel, Button, Input, useInputDelay, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import GetSETcontestNumbers from '../../../../components/GetSETContestNumbers';
import { Status } from '../../../../constants/global';
import { ContestNumbersResponse, Offering, useSaveOfferingNoteMutation } from '../../../../gql/generated';
import { SETContestNumbersByType } from '../../../../models/SETContestNumber';
import useStores from '../../../../stores/useStores';
import useUserInfo from '../../../../utils/useUserInfo';
import ContestNumbersModal from '../../ContestNumbersModal';
import ActionButtons from './ActionButtons';
import ResetAllCostSharesModal from './ResetAllCostSharesModal';
import styles from './styles.module.scss';

type Props = {
  offering: Offering;
};

const NotesHeader = ({ offering }: Props) => {
  const [saveOfferingNote] = useSaveOfferingNoteMutation();
  const { setDelay } = useInputDelay();
  const { error } = useToast();
  const {
    userInfoStore: { isNational },
    summaryStore: { setSETContestNumbersByType, profiles, updateOfferingRev },
  } = useStores();
  const [contestNumbersModal, setContestNumbersModal] = useState(false);
  const { isSETUser } = useUserInfo();
  const [showResetCostShareModal, setShowResetCostShareModal] = useState(false);

  // callback for after getting rcfs and residuals
  const handleSETContestNumbersLoad = (contestNumbersResponse: ContestNumbersResponse) => {
    setSETContestNumbersByType(contestNumbersResponse.setContestNumbers as SETContestNumbersByType[]);
  };

  const isDisabled = Boolean(isNational() && offering.isAtc);
  const showResetAllCostSharesBtn = offering.status !== Status.MEETS_GUIDELINES && !isDisabled;
  const offeringMeetsGuidelines = offering.status === Status.MEETS_GUIDELINES;
  const disableIfMeetsGuidelines = offeringMeetsGuidelines || isDisabled;
  const disableConfirmAllSeries = !profiles.some(profile => !profile.isConfirmedSeries && profile.isValid && !!profile.getSelectedRgnAlt()) || isDisabled;

  const handleSaveRegionalOfferingNote = (note: string) => {
    const { id, rev } = offering;

    if (note) {
      setDelay(async () => {
        try {
          const res = await trackPromise(
            saveOfferingNote({
              variables: {
                input: {
                  id,
                  rev,
                  note,
                },
              },
            }),
          );
          updateOfferingRev(id, res.data?.saveOfferingNote.offering?.rev || '');
        } catch (e) {
          error((e as Error).message);
        }
      });
    }
  };

  return (
    <>
      <section className={styles.actionBar}>
        <div className={styles.actionBarWrapper}>
          <div className={styles.actionBarRow}>
            <div className={styles.flexRow}>
              <div className={styles.childFlexRow}>
                <section className={styles.regionalView}>
                  {isSETUser() ? (
                    <Button variant="primary" onClick={() => setContestNumbersModal(true)} disabled={offeringMeetsGuidelines}>
                      Contest Numbers
                    </Button>
                  ) : (
                    <>
                      <div className={styles.nationalNoteWrapper}>
                        <span className={styles.inputLabel}>National Note</span>
                        <span className={styles.nationalNote}>{offering.nationalNote}</span>
                      </div>
                      <div className={styles.regionalNote}>
                        <BlockInputLabel labelComponent={<span className={styles.inputLabel}>Regional Note</span>}>
                          <Input
                            type="text"
                            id={`${offering.id}-regional-note-input`}
                            className={styles.noteInput}
                            defaultValue={offering.note}
                            disabled={disableIfMeetsGuidelines}
                            onChange={e => handleSaveRegionalOfferingNote(e.target.value)}
                          />
                        </BlockInputLabel>
                      </div>
                    </>
                  )}
                </section>
                <ActionButtons
                  showCostShareModal={setShowResetCostShareModal}
                  showResetAllCostSharesBtn={showResetAllCostSharesBtn}
                  isDisableConfirmAllSeries={disableIfMeetsGuidelines || disableConfirmAllSeries}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {contestNumbersModal && isSETUser() && (
        <GetSETcontestNumbers offeringId={offering.id} onLoad={handleSETContestNumbersLoad}>
          <ContestNumbersModal onClose={() => setContestNumbersModal(false)} offeringId={offering.id} />
        </GetSETcontestNumbers>
      )}
      {showResetCostShareModal && <ResetAllCostSharesModal onClose={() => setShowResetCostShareModal(false)} />}
    </>
  );
};

export default observer(NotesHeader);
