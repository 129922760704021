import { VehicleInput } from '../../../gql/generated';
import AccordionItemModel from '../models/AccordionItemModel';

const mapVehicleDataFromAccordion = (accItems: AccordionItemModel[], vehicleInputs: VehicleInput[], isExclusion: boolean): [VehicleInput[], VehicleInput[]] => {
  const excludedList: VehicleInput[] = [];
  const includedList: VehicleInput[] = [];

  vehicleInputs.forEach(v => {
    const yearItem = accItems.find(i => i.value === v.seriesYear);
    if (yearItem) {
      const seriesItem = yearItem.items.find(si => si.value === v.series);
      if (seriesItem) {
        const modelItem = seriesItem.items.find(mi => mi.value === v.modelCode);
        if (modelItem) {
          if ((isExclusion && modelItem.checked) || (!isExclusion && !modelItem.checked)) {
            excludedList.push({
              ...v,
              isInclusion: false,
            });
          } else {
            includedList.push({
              ...v,
              isInclusion: true,
            });
          }
        }
      }
    }
  });

  return [excludedList, includedList];
};

export default mapVehicleDataFromAccordion;
