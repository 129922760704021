import clsx from 'clsx';
import { formatDollarsCents } from 'oat-common-ui';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  value: string | number;
  label: string;
  error?: boolean;
  tooltip?: ReactNode;
}

const EstCost = ({ value, label, error = false, tooltip }: Props) => (
  <div className={styles.estCost}>
    <div className={clsx(styles.value, error && styles.error)}>
      <sup>$</sup>
      {formatDollarsCents(value)}
    </div>
    <div>
      {tooltip && <span className={styles.tooltip}>{tooltip}</span>}
      <span className={styles.label}>{label}</span>
    </div>
  </div>
);

export default EstCost;
