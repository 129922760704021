import { AdminConstants, AdminModels } from 'oat-admin-common';
import { LeaseDetails, LeaseExample, LeaseOfferDetails, ResidualValue } from '../../../../../gql/generated';
import CreateLeaseStore from '../../../../../stores/CreateLeaseStore';
import { assignDollarCents, assignNumberValue } from '../../../../../utils/assignValue';
import getLowestTierFromLeaseDetails from '../../../../../utils/getLowestTierFromLeaseDetails';
import { Fields } from '../../models/CreateAdLeaseModel';
import getPayloadCalcs, { getLeaseDetailsValues } from './getPayloadCalcs';

const { DEFAULT_DOWN_PAYMENT } = AdminConstants;

export const transformChangeVinPayload = (
  createLeaseStore: CreateLeaseStore,
  fields: Fields,
  residuals: ResidualValue[],
  leaseDetails: LeaseDetails,
  nationalDetails?: LeaseDetails,
  example?: LeaseExample,
  defaultTfsShare?: number,
  enhancedCostShare?: AdminModels.EnhancedCostShare.EnhancedLeaseCostShare,
  enhancedTfsCostShare?: AdminModels.EnhancedTfsCostShare.EnhancedTfsCostShare,
): LeaseOfferDetails => {
  const { advertisedData } = createLeaseStore;
  const { vin, trim, config } = fields;

  const leaseTier = getLowestTierFromLeaseDetails(leaseDetails);
  const nationalTier = nationalDetails ? getLowestTierFromLeaseDetails(nationalDetails) : leaseTier;
  const rv = example
    ? example.vin === vin?.vin // oldVin === newVin
      ? assignNumberValue(example.residualRate)
      : undefined
    : leaseDetails.vin === vin?.vin // oldVin === newVin
    ? assignNumberValue(leaseDetails.residualRate)
    : undefined;

  // OR-2540
  // If offer is originally non-advertised, use default dealerGross
  // else, use the offer's dealer gross
  const dealerGross = !leaseDetails.isAdvertised ? createLeaseStore.dealerGross : example ? example.dealerGross : leaseDetails.dealerGross;
  const dueAtSigning = example ? example.dueAtSigning : leaseDetails.dueAtSigning;

  const calcs = getPayloadCalcs(
    createLeaseStore,
    fields,
    residuals,
    leaseDetails.highTerm,
    assignNumberValue(dealerGross),
    enhancedTfsCostShare,
    assignNumberValue(leaseDetails.subventionCash),
    assignNumberValue(dueAtSigning),
    nationalDetails ? nationalTier.subventedRcf : leaseTier.standardRcf,
    leaseTier.subventedRcf,
    assignNumberValue(leaseDetails.enhancedResidualRate),
    leaseDetails.mileage?.toString(),
    leaseDetails.downPayment ?? DEFAULT_DOWN_PAYMENT,
    leaseDetails.netCapCost ?? 0,
    leaseDetails.adjustmentFactor ?? 0,
    leaseDetails.tfsShare,
    defaultTfsShare,
    rv,
  );

  const sales = assignNumberValue(leaseDetails.forecastedSales);

  // Lease details base. If example, call function that convert details into the payload
  // If not example, update lease details with selecte fields
  const props: LeaseOfferDetails = example
    ? getLeaseDetailsValues(leaseDetails, leaseTier)
    : {
        ...calcs,
        configuration: config?.configFull,
        offerCost: assignDollarCents(calcs.estimatedCost * sales),
        offerTfsCost: assignDollarCents(calcs.tfsCost * sales),
        offerEnhTfsCost: assignDollarCents(calcs.enhTfsCost * sales),
        isAdvertised: true,
        isSubCashAdvertised: false,
        series: advertisedData.series,
        modelCode: Number(trim?.code) || undefined,
        modelYear: Number(advertisedData.seriesYear),
        port: vin?.port,
        term: leaseDetails.highTerm,
        vehicleDescription: trim?.seriesTitle,
        vin: vin?.vin,
        tdaFee: Number(vin?.tdaFee),
        isBadVin: false,
        regionalSubventionRCF: enhancedTfsCostShare?.value ? leaseTier.standardRcf - enhancedTfsCostShare?.value : leaseTier.subventedRcf,
        subCashTfsShare: enhancedCostShare?.subventionCashTfsCostShare ?? leaseDetails.subCashTfsShare,
        subCashTfsCostShareCap: enhancedCostShare?.subventionCashTfsCostShareCap ?? leaseDetails.subCashTfsCostShareCap,
      };

  // destructure out properties that don't go into examples
  const { estimatedCost, subventionCash, regionalSubventionRCF, ...restCalcs } = calcs;

  // Populate Lease Examples. Update example if necessary, otherwise if should just
  // copy the db values
  const leaseExamples = leaseDetails.leaseExamples?.map(ex => {
    return ex.name === example?.name
      ? {
          ...ex,
          ...restCalcs,
          configuration: config?.configFull,
          series: advertisedData.series,
          modelCode: Number(trim?.code) || undefined,
          modelYear: Number(advertisedData.seriesYear),
          port: vin?.port,
          tfsCost: undefined,
          enhTfsCost: undefined,
          vehicleDescription: trim?.seriesTitle,
          vin: vin?.vin,
          tdaFee: Number(vin?.tdaFee),
        }
      : ex;
  });

  return {
    ...props,
    leaseExamples,
  };
};

export default transformChangeVinPayload;
