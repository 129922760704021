import { observer } from 'mobx-react-lite';
import RgnlAltModel from '../../../../models/RgnlAltModel';
import FsvCosts from '../../../../RegionalSummary/FsvCosts';
import styles from './styles.module.scss';

interface Props {
  selectedRa: RgnlAltModel | undefined;
}

const NotExpTableHeader = ({ selectedRa }: Props) => {
  return (
    <div className={styles.notExpandedSummary}>
      <div className={styles.flexLeft}>
        <span className={styles.tableHeader}>
          Est.
          <br /> Sales
        </span>
        <span className={styles.tableHeader}>PNV</span>
        <span className={styles.tableHeader}>
          Total
          <br /> $
        </span>
        <span className={styles.tableHeader}>
          Pen <br />
          Rate
        </span>
      </div>
      <FsvCosts rgnlAlt={selectedRa as RgnlAltModel} showOnlyDollarDisplay />
    </div>
  );
};

export default observer(NotExpTableHeader);
