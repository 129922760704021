import React from 'react';
import { EnhancedCostShare } from '../../../../gql/generated';
import { processTfsContractByTerm, processTfsContractByTier, processTierMap } from './utils';

interface Props {
  enhancedCostShare: EnhancedCostShare;
}

const MiscTfsContracts = ({ enhancedCostShare }: Props) => {
  const { tfsContracts } = enhancedCostShare;

  if (tfsContracts?.length) {
    return (
      <>
        {tfsContracts?.map((tfsContract, i) => {
          const byTermMap = processTfsContractByTerm(tfsContract?.tierTerms || '');
          const byTierMap = processTfsContractByTier(byTermMap);
          const tiersArr = Object.keys(byTierMap);

          const contractType = `${tfsContract?.contractType} ${tfsContract?.isSubvented ? `Subvented` : `Non-Subvented`}`;

          return (
            <React.Fragment key={`${tfsContract?.contractType}-${i}`}>
              <>{contractType}</>
              <>
                {tiersArr.map((tier, i) => {
                  const { firstTier, tierArr, termsArrByTier } = processTierMap(byTierMap, tier);

                  return (
                    <div key={`${tier}-${i}`}>
                      <>{tierArr.length > 1 ? `Tier ${firstTier} - Tier ${tierArr[tierArr.length - 1]}, ` : `Tier ${firstTier}, `}</>
                      <>{termsArrByTier.length > 1 ? `Terms ${termsArrByTier[0]} - Terms ${termsArrByTier[termsArrByTier.length - 1]}` : `Terms ${termsArrByTier[0]}`}</>
                    </div>
                  );
                })}
              </>
            </React.Fragment>
          );
        })}
      </>
    );
  }

  return <></>;
};

export default MiscTfsContracts;
