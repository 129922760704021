import clsx from 'clsx';
import { Tooltip } from 'oat-common-ui';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  id: string;
  message: ReactNode;
  className?: string;
  disable?: boolean;
  children?: ReactNode;
}

const VehicleCompatibleTooltip = ({ id, message, className, disable, children }: Props) => {
  return (
    <Tooltip className={clsx(styles.vcTooltip, className)} id={id} message={message} place="bottom" disable={disable} grayTheme>
      {children}
    </Tooltip>
  );
};

export default VehicleCompatibleTooltip;
