import { DefaultModal, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { Offer, OfferingCosts, useResetToNationalOfferMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import styles from './styles.module.scss';

interface Props {
  id: string;
  onClose: () => void;
  onSubmit: (offer: Offer) => void;
  rev: string;
}

const ResetToNationalModal = ({ id, rev, onClose, onSubmit }: Props) => {
  const {
    offeringCostsStore: { setData },
  } = useStores();
  const [resetToNational] = useResetToNationalOfferMutation();
  const { error } = useToast();

  const handleSubmit = async () => {
    try {
      const res = await trackPromise(resetToNational({ variables: { input: { id, rev } } }));
      setData(res.data?.resetToNationalOffer.offeringCosts as OfferingCosts);
      onSubmit(res.data?.resetToNationalOffer?.offer as Offer);
    } catch (e) {
      error((e as Error).message);
    } finally {
      onClose();
    }
  };

  return (
    <DefaultModal
      isWarning
      message="Resetting to National will return the offer back to its original state, but will also remove any data you've entered for the offer"
      modalBodyClassName={styles.modal}
      onClose={onClose}
      onSubmit={handleSubmit}
      open
      title="Reset to National"
    />
  );
};

export default ResetToNationalModal;
