import { AdminConstants } from 'oat-admin-common';
import { Status } from '../../constants/global';
import { Offering } from '../../gql/generated';
import { assignNumberValue } from '../../utils/assignValue';
import FSVModel, { SeriesProfileForecastedSales } from './models/FSVModel';
import ProfileModel from './models/ProfileModel';

const { VEHICLE_GROUPS } = AdminConstants;

export const returnOffset = (id: string, offset: number) => {
  return Math.max(document.getElementById(id)?.offsetTop || 0) - offset;
};

export const getScrollTopFromClick = (id: string) => {
  const offset = 185;

  return returnOffset(id, offset);
};

export const getVehicleGroup = (profile: ProfileModel) => {
  if (profile.isEarningsOnly) {
    return 'Earnings Only';
  }

  return VEHICLE_GROUPS.get(Number(profile.groups[0]));
};

export const buildForecastedSales = (profiles: ProfileModel[], offering: Offering, totalFsv: number, hasEarningsOnly: boolean) => {
  const seriesProfileForecastedSales: SeriesProfileForecastedSales[] = [];

  profiles.forEach(profile => {
    const selectedRa = profile.getSelectedRgnAlt() || profile.rgnlAlts[0];
    const group = getVehicleGroup(profile) || '';

    if ((group === 'Earnings Only' && hasEarningsOnly) || group !== 'Earnings Only') {
      seriesProfileForecastedSales.push({
        seriesProfileId: profile.id,
        forecastedSales: assignNumberValue(selectedRa?.fsv, 0),
        group,
        series: profile.name,
        isEarningsOnly: profile.isEarningsOnly,
        isMultiSeries: profile.series.length > 1,
        selectedRaId: selectedRa?.id,
      });
    }

    // moving Earnings Only profile to the end of FSV modal
    seriesProfileForecastedSales.push(
      seriesProfileForecastedSales.splice(
        seriesProfileForecastedSales.findIndex(profile => profile.group === 'Earnings Only'),
        1,
      )[0],
    );
  });

  const fsvModel = new FSVModel(offering, totalFsv, seriesProfileForecastedSales);
  fsvModel.calculateEarningsOnlyFsv();

  return fsvModel;
};

export const invalidFsv = (fsvModel: FSVModel, hasEarningsOnly: boolean) => {
  const { singleSeriesFsvTotal, seriesProfileForecastedSales, totalForecastedSales } = fsvModel;

  let invalid = false;
  const isEmpty = seriesProfileForecastedSales.some(profile => profile.forecastedSales === undefined);

  if (hasEarningsOnly) {
    if (singleSeriesFsvTotal > Number(totalForecastedSales)) {
      invalid = true;
    }
  } else {
    if (singleSeriesFsvTotal !== Number(totalForecastedSales)) {
      invalid = true;
    }
  }

  return { invalidSum: invalid, empty: isEmpty };
};

export const xformFsvs = (fsvModel: FSVModel, profiles: ProfileModel[]) => {
  profiles.forEach(profile => {
    const selectedRa = profile.getSelectedRgnAlt() || profile.rgnlAlts[0];

    if (selectedRa) {
      const fsvSelectedRa = fsvModel.seriesProfileForecastedSales.filter(sp => sp.selectedRaId === selectedRa.id)[0];
      selectedRa.updateFsv(fsvSelectedRa.forecastedSales);
      // OR-3676 system should always calculate sales when series sales are updated from modal at the top or from series it self
      selectedRa.calculateSales();
    }
  });
};

export const getSelectedProfileIdByRa = (profiles: ProfileModel[], raId = '') => {
  let profileId = '';
  if (raId !== '') {
    profiles.forEach(profile => {
      const filteredProfile = profile.rgnlAlts.filter(ra => ra.id === raId)[0];
      if (filteredProfile) {
        profileId = filteredProfile.id;
      }
    });
  }
  return profileId;
};

// for RegionalSummary
export const processStatusToSubmitRegional = (offering: Offering, isSET: boolean) => {
  switch (true) {
    case offering.status === Status.SUBMITTED:
      return Status.NEW;
    case offering.status === Status.NEEDS_REVISIONS:
      return Status.REVISED;
    case offering.isAtc && offering.status === Status.NEW && !isSET:
      return Status.SUBMITTED;
    default:
      return Status.SUBMITTED;
  }
};

// for RegionalSummary
export const getRegionalSummaryCtaStatuses = (offering: Offering, profiles: ProfileModel[]) => {
  const oneIsNeedsRevisions = profiles.some(profile => profile.getSelectedRgnAlt()?.status === Status.NEEDS_REVISIONS);
  const allIsConfirmed = profiles.every(profile => profile.isConfirmedSeries);
  const oneIsUnconfirmed = profiles.some(profile => !profile.isConfirmedSeries);
  const offeringStatus = offering.status;

  const showSubmitAllOffersToNationalBtn =
    offeringStatus === Status.NO_RESPONSE ||
    offeringStatus === Status.NEEDS_REVISIONS ||
    offeringStatus === Status.REVISED ||
    offeringStatus === Status.NEW ||
    oneIsNeedsRevisions ||
    (offeringStatus !== Status.MEETS_GUIDELINES && allIsConfirmed);
  const showReconcileBtn = offeringStatus === Status.MEETS_GUIDELINES;
  const disableSubmitAllOffersToNational = offeringStatus === Status.SUBMITTED || oneIsUnconfirmed;
  const showDownloadIncentivePeriodDocsBtn = allIsConfirmed;

  return { showSubmitAllOffersToNationalBtn, showReconcileBtn, disableSubmitAllOffersToNational, showDownloadIncentivePeriodDocsBtn };
};

// for NationalApproval
export const processStatusToSubmitNational = (profiles: ProfileModel[], offering: Offering) => {
  const allIsMeetsGuidelines = profiles.every(profile => {
    return profile.getSelectedRgnAlt()?.status === Status.MEETS_GUIDELINES;
  });

  const oneIsNeedsRevisions = profiles.some(profile => {
    return profile.getSelectedRgnAlt()?.status === Status.NEEDS_REVISIONS;
  });

  switch (true) {
    case offering.isAtc && offering.status === Status.SUBMITTED && allIsMeetsGuidelines:
    case offering.status === Status.MEETS_GUIDELINES && allIsMeetsGuidelines:
    case allIsMeetsGuidelines && !oneIsNeedsRevisions:
      return Status.MEETS_GUIDELINES;
    case offering.status === Status.REVISED && oneIsNeedsRevisions:
    case oneIsNeedsRevisions:
    case offering.isAtc && offering.status === Status.SUBMITTED && oneIsNeedsRevisions:
      return Status.NEEDS_REVISIONS;
    default:
      return Status.SUBMITTED;
  }
};

// for NationalApproval
export const getNationalApprovalCtaStatuses = (offering: Offering, profiles: ProfileModel[]) => {
  const allIsMeetsGuidelinesOrNeedsRevisions = profiles.every(profile => [Status.MEETS_GUIDELINES, Status.NEEDS_REVISIONS].includes(profile.getSelectedRgnAlt()?.status as Status));
  const isReadyToPublish = profiles.every(profile => profile.getSelectedRgnAlt()?.status === Status.MEETS_GUIDELINES && profile.isConfirmedSeries && profile.isConfirmedView);
  const offeringStatus = offering.status;

  const showUpdateOfferingStatusBtn = ((offeringStatus === Status.SUBMITTED || offeringStatus === Status.REVISED) && allIsMeetsGuidelinesOrNeedsRevisions);
  const showReconcileBtn = offeringStatus === Status.MEETS_GUIDELINES;
  const showPublishBtn = !!offering.isDirty && offeringStatus === Status.MEETS_GUIDELINES && isReadyToPublish;

  return {
    showUpdateOfferingStatusBtn,
    showReconcileBtn,
    showPublishBtn,
  };
};
