import { OfferTypes } from 'oat-common-ui';
import { ReactNode } from 'react';
import PenRateModel from '../../../../../models/PenRateModel';
import NotExpTableValues from '../NotExpTableValues';
import MiscTableValues from './MiscTableValues';

interface Props {
  miscModels: PenRateModel[] | undefined;
  showDates: (model: PenRateModel) => ReactNode | undefined;
  onToggle?: (model: PenRateModel) => void;
}

const MiscRow = ({ miscModels, showDates, onToggle }: Props) => {
  return (
    <>
      {miscModels?.map((miscItem, i) => {
        return (
          <tr key={`${OfferTypes.MISCELLANEOUS}-${miscItem.id}-${i}`}>
            <NotExpTableValues model={miscItem} />
            <MiscTableValues miscModel={miscItem} showDates={showDates} onToggle={onToggle} />
          </tr>
        );
      })}
    </>
  );
};

export default MiscRow;
