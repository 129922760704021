import { observer } from 'mobx-react-lite';
import { OfferTypes } from 'oat-common-ui';
import { ReactNode } from 'react';
import { Vehicle } from '../../../../../../../gql/generated';
import PenRateModel from '../../../../../models/PenRateModel';
import OfferLayout from '../OfferLayout';
import AprHeader from './AprHeader';
import AprTableHeaderValues from './AprTableHeaderValues';
import ExpAprSubvenValues from './ExpAprSubvenValues';
import ExpAprTableValues from './ExpAprTableValues';

interface Props {
  aprModels: PenRateModel[] | undefined;
  inputIsDisabled: boolean;
  showDates: (model: PenRateModel) => ReactNode;
  excludedVehicles: Vehicle[];
  includedVehicles: Vehicle[];
  onSave?: () => void;
}

const ExpAprRow = ({ aprModels, inputIsDisabled, showDates, excludedVehicles, includedVehicles, onSave }: Props) => {
  return (
    <>
      {aprModels?.map((aprItem, i) => {
        const aprTerms = Object.values(aprItem.terms);

        return (
          <OfferLayout
            key={`${OfferTypes.APR}-${aprItem.id}-${i}`}
            offerHeader={<AprHeader aprModel={aprItem} showDates={showDates} />}
            offerContent={
              <>
                <AprTableHeaderValues aprTerms={aprTerms} />
                <ExpAprTableValues aprModel={aprItem} inputIsDisabled={inputIsDisabled} onSave={onSave} />
                <ExpAprSubvenValues aprModel={aprItem} />
              </>
            }
            model={aprItem}
            excludedVehicles={excludedVehicles}
            includedVehicles={includedVehicles}
          />
        );
      })}
    </>
  );
};

export default observer(ExpAprRow);
