import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  title: ReactNode;
  content: ReactNode;
  children?: ReactNode;
  isStandalone?: boolean;
}

const VehicleCompabitibleInfo = ({ title, content, children, isStandalone }: Props) => {
  return (
    <div className={styles.vehicleCompatibleInfo}>
      <div className={styles.title}>{title}</div>
      <div className={clsx(styles.content, isStandalone && styles.isStandalone)}>{content}</div>
      {children}
    </div>
  );
};

export default VehicleCompabitibleInfo;
