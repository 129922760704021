import { useEffect, useState } from 'react';
import AnchorScrollTo from '../components/AnchorScrollTo';

const useAnchorScrollTo = (eID: string, offset?: number) => {
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    if (!scrolled) {
      AnchorScrollTo(eID, offset);
      setScrolled(scrolled);
    }
  }, [eID, offset, scrolled]);
};

export default useAnchorScrollTo;
