import { AdminConstants } from 'oat-admin-common';
import { OPTION_TYPE_NAMES } from 'oat-admin-common/dist/types/constants';
import { dateStringToDate } from 'oat-common-ui';
import createAccordionFromVehicles from '../../../components/Accordion/utils/createAccordionFromVehicles';
import { defaultTfsContracts } from '../../../constants/global';
import { CombinedOffers, SeriesMapping, SeriesProfile, TfsContract } from '../../../gql/generated';
import { assignBooleanValue, assignDollarCents, assignNumberValue, assignStringValue, defaultBooleanToUndefined } from '../../../utils/assignValue';
import getDisabledVehicles from '../../../utils/getDisabledVehicles';
import { getIncludedExcludedVehicles } from '../../../utils/getIncludedExcludedVehicles';
import { getDefaultPaymentMethod, getDefaultPaymentTo, getDefaultSystemToPay, tfsContractDefaultChecked } from '../../../utils/getMiscOfferFieldsDefaultValue';
import { getAllStatesForRegion } from '../../ProgramDetails/components/LeaseCard/components/VehicleInfo/utils';
import { calculatePnvAmountValues } from '../../ProgramDetails/components/MiscCard/utils/calculatePnvAmountValues';
import MiscCardModel from '../../ProgramDetails/models/MiscCardModel';
import { getCompatibilityListByOfferType } from './getCompatibilityListByOfferType';

export const createMiscCardForCreate = (defaultStartDate: string, defaultEndDate: string, region: string, seriesProfile?: SeriesProfile): MiscCardModel => {
  const card = new MiscCardModel();

  const compatibilityList = getCompatibilityListByOfferType(card.fields.type as OPTION_TYPE_NAMES);
  const { includedVehicles, excludedVehicles } = getIncludedExcludedVehicles(seriesProfile?.vehicles);
  const rgnlAltStates = seriesProfile?.rgnlAlts?.filter(rgnl => rgnl.isSelected)[0].states;

  card.isStandAlone = true;
  card.optionTypeName = card.fields.type;
  card.seriesProfileNationalId = assignStringValue(seriesProfile?.nationalId);
  card.fields.compatibility = compatibilityList;
  card.fields.defaultStartDate = dateStringToDate(defaultStartDate);
  card.fields.defaultEndDate = dateStringToDate(defaultEndDate);
  card.fields.startDate = dateStringToDate(defaultStartDate);
  card.fields.endDate = dateStringToDate(defaultEndDate);
  card.fields.excludedVehicles = excludedVehicles;
  card.fields.includedVehicles = includedVehicles;
  card.fields.includedStates = rgnlAltStates ?? getAllStatesForRegion(region);
  card.fields.salesCode = ['1', '3', '4', '5', '6'];
  card.fields.vehicles = seriesProfile?.vehicles || [];
  card.fields.compatibileOffers = compatibilityList?.defaultTypes;
  card.fields.name = card.fields.type;
  card.fields.paymentTo = getDefaultPaymentTo(card.fields.type);
  card.fields.paymentMethod = getDefaultPaymentMethod(card.fields.paymentTo);
  card.fields.systemToPay = getDefaultSystemToPay(card.fields.type);
  card.fields.tfsContractRequired = tfsContractDefaultChecked(card.fields.type);
  card.fields.tfsContracts = defaultTfsContracts;

  return card;
};

export const createMiscCard = (
  card: MiscCardModel,
  seriesMapping: SeriesMapping[],
  offer?: CombinedOffers,
  seriesProfile?: SeriesProfile,
  defaultStartDate?: string,
  defaultEndDate?: string,
) => {
  card.isStandAlone = !Boolean(offer?.regional.nationalId);
  card.fields.defaultStartDate = dateStringToDate(defaultStartDate || offer?.regional.cashDetails?.startDate || '');
  card.fields.defaultEndDate = dateStringToDate(defaultEndDate || offer?.regional.cashDetails?.endDate || '');
  card.fields.name = offer?.regional.name || offer?.national?.name || card.fields.type;
  card.fields.defaultPerUnitCost = offer?.national?.cashDetails?.combinedPerUnitCost;
  card.fields.vehicles = offer?.national?.vehicles || offer?.regional.vehicles || seriesProfile?.vehicles || [];
  card.fields.compatibileOffers = offer?.national?.compatibilityList || offer?.regional?.compatibilityList || [];
  card.fields.contestNumber = offer?.regional?.contestNumber || '';
  card.fields.contestNumberOfferId = offer?.regional?.contestNumberOfferId || '';
  card.isDuplicate = assignBooleanValue(offer?.regional.isDuplicate, false);
  card.parentId = assignStringValue(offer?.regional.parentId);
  card.isGst = seriesProfile?.regionCode === AdminConstants.RegionCodes.GST;
  card.isEligibleForEnhCostShare = defaultBooleanToUndefined(offer?.regional.isEligibleForEnhCostShare);
  card.enhCostShareOfferId = assignStringValue(
    offer?.regional.enhCostShareOfferId,
    typeof card.isEligibleForEnhCostShare === 'boolean' && !card.isEligibleForEnhCostShare ? undefined : '',
  );
  card.isEnhCostShareAccepted = defaultBooleanToUndefined(offer?.regional.isEnhCostShareAccepted);
  card.isEnhCostShareUpdated = defaultBooleanToUndefined(offer?.regional.isEnhCostShareUpdated);
  card.isEnhCostShareRemoved = defaultBooleanToUndefined(offer?.regional.isEnhCostShareRemoved);

  if (offer?.national) {
    card.nationalOffer = offer.national;
  }

  if (offer?.regional.cashDetails) {
    const { accessoryCodes, id, rev, cashDetails, excludedStates, includedStates, isSpecialEdition, vehicles, isTransferable, optionTypeName, specialEditionPackage, systemToPay } =
      offer.regional;
    const { includedVehicles, excludedVehicles } = getIncludedExcludedVehicles(vehicles || seriesProfile?.vehicles);
    const disabledVehicles = getDisabledVehicles(seriesProfile?.vehicles || []);
    const hasTfsContracts = cashDetails.tfsContracts?.find(tfs => tfs.tierTerms);

    if (cashDetails.typeSalesCodes) {
      card.fields.salesCode = cashDetails.typeSalesCodes.split(',');
    }

    card.fields.startDate = dateStringToDate(cashDetails.startDate || '');
    card.fields.endDate = dateStringToDate(cashDetails.endDate || '');
    card.fields.estimatedCost = assignDollarCents(cashDetails.estimatedCost);
    card.fields.offerCost = assignDollarCents(cashDetails.offerCost);
    card.fields.offerEarnings = assignNumberValue(cashDetails.offerEarnings);
    card.fields.offerBalance = assignDollarCents(card.fields.offerEarnings - card.fields.offerCost);
    card.fields.offerTfsCost = assignDollarCents(cashDetails.offerTfsCost);
    card.fields.offerEnhTfsCost = assignDollarCents(cashDetails.offerEnhTfsCost);
    card.fields.excludedStates = excludedStates || [];
    card.fields.includedStates = includedStates || [];
    card.fields.note = cashDetails.note;
    card.fields.tdaNote = cashDetails.tdaNote;
    card.fields.modelNote = cashDetails.modelNote;
    card.fields.description = assignStringValue(cashDetails.vendorDescription);
    card.fields.type = optionTypeName;
    card.fields.paymentTo = assignStringValue(cashDetails.paymentTo);
    card.fields.paymentMethod = assignStringValue(cashDetails.paymentMethod);
    card.fields.systemToPay = assignStringValue(systemToPay);
    card.fields.tfsCostShare = assignStringValue(cashDetails.tfsShare?.toString(), '0');
    card.fields.defaultTfsCostShare = assignStringValue(offer?.national?.cashDetails?.tfsShare?.toString(), '0');
    card.fields.tfsCostShareCap = assignStringValue(cashDetails.tfsCostShareCap?.toString(), '0');
    card.fields.defaultTfsCostShareCap = assignStringValue(offer?.national?.cashDetails?.tfsCostShareCap?.toString(), '0');
    card.fields.tfsCost = assignNumberValue(cashDetails.tfsCost);
    card.fields.enhTfsCost = assignNumberValue(cashDetails.enhTfsCost);
    card.fields.maxNoOfCertificates = assignNumberValue(cashDetails.maxCertificates);
    card.fields.maxDownPaymentAmount = assignNumberValue(cashDetails.maxDownPayment);
    card.fields.transferable = isTransferable || false;
    card.fields.targetedAudience = assignStringValue(cashDetails.targetedAudience);
    card.fields.impactedBrand = assignStringValue(cashDetails.impactedBrands);
    card.fields.impactedModel = assignStringValue(cashDetails.impactedSeries);
    card.fields.vendorCodes = assignStringValue(cashDetails.vendorCodes);
    card.fields.noOfMonths = assignNumberValue(cashDetails.noOfMonths);
    card.fields.maxDollarAmountPerMonth = assignNumberValue(cashDetails.maxAmountPerMonth);
    card.fields.returningBrands = assignStringValue(cashDetails.returningBrands);
    card.fields.returningSeries = assignStringValue(cashDetails.returningSeries);
    card.fields.forecastedSales = assignNumberValue(cashDetails.forecastedSales);
    card.fields.competitorDetails = cashDetails.competitorDetails || [
      {
        make: '',
        model: '',
        years: '',
      },
    ];
    card.fields.modelRestrictions = cashDetails.competitorDetails || [];
    card.fields.giftDelivery = assignStringValue(cashDetails.giftDeliveryOption);
    card.fields.maxNoOfDays = assignStringValue(cashDetails.maxDaysNo?.toString());
    const { combinedPerUnitCost, pnv } = calculatePnvAmountValues('amount', cashDetails.combinedPerUnitCost.toString(), assignNumberValue(card.fields.defaultPerUnitCost));
    card.fields.combinedPerUnitCost = combinedPerUnitCost;
    card.fields.pnv = pnv;
    card.fields.specialEditionPackage = assignStringValue(specialEditionPackage);
    card.fields.isSpecialEdition = isSpecialEdition;
    card.fields.accessoryCodes = assignStringValue(accessoryCodes);
    card.isAdvertised = Boolean(cashDetails.isAdvertised);
    card.id = id;
    card.rev = rev;
    card.isEligibleForEnhCostShare = defaultBooleanToUndefined(offer?.regional.isEligibleForEnhCostShare);
    card.enhCostShareOfferId = assignStringValue(offer.regional.enhCostShareOfferId);
    card.isEnhCostShareAccepted = defaultBooleanToUndefined(offer?.regional.isEnhCostShareAccepted);
    card.fields.tfsContracts = getTfsContracts(cashDetails.tfsContracts);
    card.fields.tfsContractRequired = hasTfsContracts ? true : false;
    card.fields.isEnhanced = cashDetails.isEnhanced;
    card.fields.includedVehicles = includedVehicles;
    card.fields.excludedVehicles = excludedVehicles;
    card.fields.isValidEnR = assignBooleanValue(cashDetails.isValidEnR, true);
    card.fields.vehiclesAccordion.items = createAccordionFromVehicles(seriesProfile?.vehicles || [], seriesMapping, card.fields.isInclusions, disabledVehicles);
  }

  return card;
};

const getTfsContracts = (tfsContracts?: TfsContract[] | null) => {
  const localTfs: TfsContract[] = JSON.parse(JSON.stringify(defaultTfsContracts));

  if (tfsContracts === undefined) {
    return [];
  }

  localTfs.forEach(defTfs => {
    const currentTFS = tfsContracts?.find(tfs => defTfs.contractType === tfs.contractType && defTfs.isSubvented === tfs.isSubvented && tfs.tierTerms);
    defTfs.tierTerms = assignStringValue(currentTFS?.tierTerms);
  });

  return localTfs;
};
