import { observer } from 'mobx-react-lite';
import { Status } from '../../../constants/global';
import PenRateModel from '../../../pages/Summary/models/PenRateModel';
import RgnlAltModel from '../../../pages/Summary/models/RgnlAltModel';
import useStores from '../../../stores/useStores';
import NumberInput from '../../NumberInput';
import styles from '../styles.module.scss';

interface Props {
  editPenRate: boolean;
  isDisabled?: boolean;
  onSave?: () => void;
  penRate: PenRateModel;
  rgnlAlt: RgnlAltModel;
}

const SalesCell = ({ rgnlAlt, penRate, editPenRate, onSave, isDisabled = false }: Props) => {
  const { summaryStore } = useStores();

  const handleOnChange = (val: string) => {
    penRate.updateSales(val);
    rgnlAlt.postCalculatePenAndSales();
    if (onSave) {
      onSave();
    }
  };

  return (
    <td>
      <NumberInput
        allowNegative={false}
        className={styles.input}
        disabled={editPenRate || summaryStore.offering.status === Status.MEETS_GUIDELINES || isDisabled}
        id={`fs-${penRate.id}`}
        onValueChange={val => handleOnChange(val.value)}
        value={penRate.forecastedSales}
        wholeNumber
        units
      />
    </td>
  );
};

export default observer(SalesCell);
