import { useCallback } from 'react';
import apolloClient from '../apolloClient';
import {
  GetAvailableNationalReportsDocument,
  GetAvailableNationalReportsQuery,
  GetAvailableRegionalReportsDocument,
  GetAvailableRegionalReportsQuery,
  GetRegionalOfferingsByNationalIdDocument,
  GetRegionalOfferingsByNationalIdQuery,
  Offering,
  ReportResponse,
} from '../gql/generated';
import useStores from '../stores/useStores';

const useReportGeneration = () => {
  const {
    reportsStore: { regionalOfferings, setRegionalOfferings, setGeneratedReports, setLoadingReports, getRegionalOfferingId },
    userInfoStore: { userInfo, isNational },
  } = useStores();

  const generateReports = useCallback(
    async (selectedIp: Offering, showLoading = true, isReconcile = false, parentOfferingId = '') => {
      setLoadingReports(showLoading);
      let reports: ReportResponse[] = [];

      try {
        if (isNational()) {
          // Grabs regional offerings and store in record by national Id
          // Only calls if nationalId doesn't have data yet
          if (!Boolean(regionalOfferings[selectedIp.id]) && !isReconcile) {
            const rOfferings = await apolloClient.query<GetRegionalOfferingsByNationalIdQuery>({
              query: GetRegionalOfferingsByNationalIdDocument,
              variables: {
                nationalId: selectedIp.id,
              },
            });

            setRegionalOfferings(selectedIp.id, rOfferings.data.regionalOfferingsByNationalId as Offering[]);
          }

          // Get national reports
          const nationalReports = await apolloClient.query<GetAvailableNationalReportsQuery>({
            query: GetAvailableNationalReportsDocument,
            variables: {
              brand: selectedIp.brand,
              nationalOfferingId: isReconcile ? parentOfferingId : selectedIp.id,
            },
          });
          reports = nationalReports.data.availableNationalReports as ReportResponse[];
        }

        // Get regional reports
        if (Boolean(getRegionalOfferingId()) || isReconcile) {
          const regionalReports = await apolloClient.query<GetAvailableRegionalReportsQuery>({
            query: GetAvailableRegionalReportsDocument,
            variables: {
              brand: selectedIp.brand,
              userRegionCode: userInfo.regionCodes,
              regionalOfferingId: isReconcile ? selectedIp.id : getRegionalOfferingId(),
            },
          });

          reports = [...reports, ...(regionalReports.data.availableRegionalReports as ReportResponse[])];
        }
      } catch (e) {
        console.info((e as Error).message);
      }

      setLoadingReports(false);
      setGeneratedReports(reports);
    },
    [getRegionalOfferingId, isNational, setGeneratedReports, setRegionalOfferings, setLoadingReports, regionalOfferings, userInfo],
  );

  return {
    generateReports,
  };
};

export default useReportGeneration;
