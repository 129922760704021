import { OATWrapper } from 'oat-common-ui';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  fixed?: boolean;
}

const ProgramDetailsWrapper = ({ children, fixed = false }: Props) => {
  return (
    <OATWrapper className={styles.pdWrapper} withPadding fixed={fixed}>
      {children}
    </OATWrapper>
  );
};

export default ProgramDetailsWrapper;
