import { dateFormatISOString, dateStringToDate } from 'oat-common-ui';
import StartEndDate from '../../../../../../components/StartEndDate';
import useStores from '../../../../../../stores/useStores';
import LeaseCardModel from '../../../../models/LeaseCardModel';
import LeaseFormModel from '../../../../models/LeaseFormModel';
import styles from './styles.module.scss';

interface Props {
  leaseCard: LeaseCardModel;
  leaseForm: LeaseFormModel;
  onChange: () => void;
  isDisabled?: boolean;
}

const LeaseDates = ({ leaseCard, leaseForm, onChange, isDisabled }: Props) => {
  const {
    programDetailsStore: { offering },
  } = useStores();
  const { startDate, endDate } = offering;

  return (
    <StartEndDate
      minDate={dateStringToDate(leaseCard.isDuplicate && leaseForm.inputs.defaultStartDate ? leaseForm.inputs.defaultStartDate : startDate)}
      maxDate={dateStringToDate(leaseCard.isDuplicate && leaseForm.inputs.defaultEndDate ? leaseForm.inputs.defaultEndDate : endDate)}
      isDark
      startDate={dateStringToDate(leaseForm.inputs.startDate)}
      endDate={dateStringToDate(leaseForm.inputs.endDate)}
      datePickerClass={styles.date}
      setStartDate={value => {
        leaseForm.updateInput('startDate', dateFormatISOString(value));
        onChange();
      }}
      setEndDate={value => {
        leaseForm.updateInput('endDate', dateFormatISOString(value));
        onChange();
      }}
      startDateError={leaseForm.hasDateError || (!leaseForm.isValidEnR && leaseCard.isDuplicate)}
      endDateError={leaseForm.hasDateError || (!leaseForm.isValidEnR && leaseCard.isDuplicate)}
      disabled={(!leaseForm.inputs.isStandalone && !leaseCard.isDuplicate) || isDisabled}
    />
  );
};

export default LeaseDates;
