import { makeAutoObservable } from 'mobx';
import { AdminConstants } from 'oat-admin-common';
import { uuidv4 } from 'oat-common-ui';
import { ReconcileOfferResponse, ReconcileSeriesResponse } from '../../../gql/generated';
import { assignDollarCents, assignNumberValue, defaultToUndefined } from '../../../utils/assignValue';
import validator from '../../../utils/validator';
import { InputType } from './ReconcileDataModel';
import ReconcileOfferModel from './ReconcileOfferModel';

const { OPTION_TYPE_NAMES } = AdminConstants;

export class ReconcileSeriesFields {
  nvsUnits: InputType = undefined;
  nationalActualSalesVolume: InputType = undefined;
  regionalActualSalesVolume: InputType = undefined;
  nationalActualDealerStock: InputType = undefined;
  regionalActualDealerStock: InputType = undefined;
  isNational = false;

  constructor() {
    makeAutoObservable(this);
  }

  get hasNvsUnitsError() {
    return validator(this.nvsUnits, { required: true }) !== undefined;
  }

  get hasSalesVolumeError() {
    return validator(this.isNational ? this.nationalActualSalesVolume : this.regionalActualSalesVolume, { required: true }) !== undefined;
  }

  get hasDealerStockError() {
    return validator(this.isNational ? this.nationalActualSalesVolume : this.regionalActualSalesVolume, { required: true }) !== undefined;
  }
}

class ReconcileSeriesModel {
  uid = uuidv4();
  seriesProfileName = '';
  seriesProfileId = '';
  rgnlAltId = '';
  fields = new ReconcileSeriesFields();
  raEarnings = 0;
  raCost = 0;
  raBalance = 0;
  forecastedSalesVolume = 0;
  ryoEarningsAmount = 0;
  isEarningsOnly = false;
  isMultiSeries = false;
  isEnhanced = false;
  offers: ReconcileOfferModel[] = [];
  allOffers: ReconcileOfferModel[] = [];
  enhancedOffers: ReconcileOfferModel[] = [];
  allRyos: ReconcileOfferModel[] = [];
  lumpsumOptions: ReconcileOfferModel[] = [];
  totalCustomerCash = 0;
  isTripleChoice = false;
  invalidCustomerCash = false;
  isInvalid = true;
  isAdjustment = false;
  hasFinalPay = false;
  // validation for Complete Nvs
  invalidSummarySeries = false;
  invalidSummarySeriesDetail = false;

  // validation for Complete Reconcile
  invalidSeriesDetail = false;

  totalForecastedSales = 0;
  totalEstimatedCost = 0;
  totalOfferCost = 0;
  totalProjectedPenRate = 0;

  remainingProjectedEarnings = 0;

  totalNvsUnits = 0;
  totalNvsEarnings = 0;
  totalNvsOfferCost = 0;
  totalNvsBalance = 0;

  totalNationalActualSalesVolume = 0;
  totalRegionalActualSalesVolume = 0;

  totalRegionalActualUnits = 0;
  totalRegionalActualPenetration = 0;
  totalRegionalActualCosts = 0;
  totalRegionalActualOfferCosts = 0;
  totalRegionalActualEarnings = 0;
  totalRegionalActualBalance = 0;
  regionalEarningsVariance = 0;
  regionalBalanceVariance = 0;
  remainingRegionalActualEarnings = 0;
  regionalCostVarianceTotal = 0;

  totalNationalActualUnits = 0;
  totalNationalActualPenetration = 0;
  totalNationalActualCosts = 0;
  totalNationalActualOfferCosts = 0;
  totalNationalActualEarnings = 0;
  totalNationalActualBalance = 0;
  // this is for Projected Cost Vs Actual Cost in the series table
  nationalEarningsVariance = 0;
  nationalBalanceVariance = 0;
  // this is for Actual Cost Vs Projected Cost in the series header table
  regionalProgramCostVarianceTotal = 0;
  nationalProgramCostVarianceTotal = 0;

  remainingNationalActualEarnings = 0;
  nationalCostVarianceTotal = 0;

  constructor(series: ReconcileSeriesResponse, isNational: boolean, isAdjustment = false) {
    this.seriesProfileName = series.seriesProfileName;
    this.seriesProfileId = series.seriesProfileId;
    this.rgnlAltId = series.rgnlAltId;
    this.fields.nvsUnits = defaultToUndefined(series.nvsUnits);
    this.fields.nationalActualSalesVolume = defaultToUndefined(series.nationalActualSalesVolume);
    this.fields.regionalActualSalesVolume = defaultToUndefined(series.regionalActualSalesVolume);
    this.fields.nationalActualDealerStock = defaultToUndefined(series.nationalActualDealerStock);
    this.fields.regionalActualDealerStock = defaultToUndefined(series.regionalActualDealerStock);
    this.raEarnings = series.raEarnings;
    this.raCost = series.raCost;
    this.raBalance = assignDollarCents(series.raBalance);
    this.ryoEarningsAmount = series.ryoEarningsAmount;
    this.forecastedSalesVolume = series.forecastedSalesVolume;
    this.isEarningsOnly = series.isEarningsOnly;
    this.isMultiSeries = series.isMultiSeries;
    this.isEnhanced = series.isEnhanced;
    this.isAdjustment = isAdjustment;
    this.hasFinalPay = series.reconcileOfferResponses.filter(offer => offer?.optionTypeName === OPTION_TYPE_NAMES.FINAL_PAY).length > 0;
    this.fields.isNational = isNational;
    this.allOffers = series.reconcileOfferResponses.map(offer => {
      return new ReconcileOfferModel(offer as ReconcileOfferResponse, series.ryoEarningsAmount, isNational);
    });
    this.offers = this.allOffers.filter(offer => {
      return !offer.optionTypeName.includes('RYO');
    });
    this.enhancedOffers = this.allOffers.filter(offer => offer.isEnhanced);
    this.allRyos = this.allOffers.filter(offer => {
      return offer.optionTypeName === OPTION_TYPE_NAMES.NATIONAL_RYO || offer.optionTypeName === OPTION_TYPE_NAMES.ADDITIONAL_RYO;
    });
    this.lumpsumOptions = this.allOffers.filter(offer => {
      return offer.optionTypeName === OPTION_TYPE_NAMES.LUMPSUM_RYO;
    });
    this.trippleChoice();
    this.calculateRemainingProjectedEarnings();
    this.calculateNvsCosts(isNational);
    this.calculateActualCosts(isNational);

    makeAutoObservable(this);
  }

  updateField = <T extends keyof ReconcileSeriesFields, V extends ReconcileSeriesFields[T]>(field: T, value: V) => {
    this.fields[field] = value;
  };

  toggleEnhanced = (isEnhancementsOnly: boolean) => {
    const allOffers = this.allOffers.filter(offer => {
      return !offer.optionTypeName.includes('RYO');
    });
    this.offers = isEnhancementsOnly ? this.enhancedOffers : allOffers;
  };

  trippleChoice = () => {
    let totalCustomerCash = 0;
    let hasApr = false;
    let hasLease = false;

    this.offers.forEach(offer => {
      if (offer.optionTypeName === OPTION_TYPE_NAMES.CUSTOMER_CASH) {
        totalCustomerCash++;
      } else if (offer.optionTypeName === OPTION_TYPE_NAMES.APR) {
        hasApr = true;
      } else if (offer.optionTypeName === OPTION_TYPE_NAMES.LEASE) {
        hasLease = true;
      }
    });
    this.totalCustomerCash = totalCustomerCash;
    this.isTripleChoice = totalCustomerCash > 0 && hasApr && hasLease;
  };

  calculateRemainingProjectedEarnings = () => {
    let remainingProjectedEarnings = 0;
    let offerEarningsTotal = 0;

    this.allOffers.forEach(offer => {
      offerEarningsTotal += Number(offer.offerEarnings);
    });

    remainingProjectedEarnings = this.raEarnings - offerEarningsTotal;
    this.remainingProjectedEarnings = remainingProjectedEarnings;
  };

  calculateNvsCosts = (isNational: boolean) => {
    let nvsTotalEarnings = 0;
    let totalNvsOfferCost = 0;
    let nvsTotalBalance = 0;
    let totalNvsUnits = 0;

    this.allOffers.forEach(offer => {
      offer.nvsUnits = Math.round((assignNumberValue(this.fields.nvsUnits) * offer.penetration) / 100);
      let units = offer.nvsUnits;
      if (offer.optionTypeName === OPTION_TYPE_NAMES.FINAL_PAY) {
        if (isNational) {
          units = !this.fields.nationalActualDealerStock ? offer.nvsUnits : this.fields.nationalActualDealerStock;
        } else {
          units = !this.fields.regionalActualDealerStock ? offer.nvsUnits : this.fields.regionalActualDealerStock;
        }
      }
      if (offer.optionTypeName === OPTION_TYPE_NAMES.NATIONAL_RYO || offer.optionTypeName === OPTION_TYPE_NAMES.ADDITIONAL_RYO) {
        nvsTotalEarnings += offer.combinedPerUnitCost * assignNumberValue(this.fields.nvsUnits);
      } else {
        offer.nvsOfferCost = assignNumberValue(offer.isFlatCostOfferType ? offer.offerCost : assignDollarCents(units * offer.estimatedCost));
        totalNvsOfferCost += offer.nvsOfferCost;
        totalNvsUnits += offer.nvsUnits && offer.isCustomerChoice ? offer.nvsUnits : 0;
      }
    });
    this.lumpsumOptions.forEach(offer => {
      nvsTotalEarnings += offer.combinedPerUnitCost;
    });
    nvsTotalEarnings += this.ryoEarningsAmount * assignNumberValue(this.fields.nvsUnits);
    nvsTotalBalance = nvsTotalEarnings - totalNvsOfferCost;

    this.totalNvsUnits = totalNvsUnits;
    this.totalNvsEarnings = nvsTotalEarnings;
    this.totalNvsOfferCost = assignDollarCents(totalNvsOfferCost);
    this.totalNvsBalance = assignDollarCents(nvsTotalBalance);
  };

  calculateMiscAdjustmentActualValues = (isNational: boolean, amount = 0) => {
    if (isNational) {
      this.totalNationalActualEarnings = amount;
      this.totalNationalActualBalance = this.totalNationalActualEarnings - this.totalNationalActualOfferCosts;
      this.nationalBalanceVariance = assignDollarCents(this.totalNationalActualBalance - this.raBalance);
    } else {
      this.totalRegionalActualEarnings = amount;
      this.totalRegionalActualBalance = this.totalRegionalActualEarnings - this.totalRegionalActualOfferCosts;
      this.regionalBalanceVariance = assignDollarCents(this.totalRegionalActualBalance - this.raBalance);
    }
  };

  calculateActualCosts = (isNational: boolean) => {
    let totalRegionalActualUnits = 0;
    let totalRegionalActualPenetration = 0;
    let totalRegionalActualCosts = 0;
    let totalRegionalActualOfferCosts = 0;
    let totalRegionalActualEarnings = 0;

    let totalNationalActualUnits = 0;
    let totalNationalActualPenetration = 0;
    let totalNationalActualCosts = 0;
    let totalNationalActualOfferCosts = 0;
    let totalNationalActualEarnings = 0;

    // this is for Projected Cost Vs Actual Cost in the series table
    let regionalCostVarianceTotal = 0;
    let nationalCostVarianceTotal = 0;
    // this is for Actual Cost Vs Projected Cost in the series header table
    let regionalProgramCostVarianceTotal = 0;
    let nationalProgramCostVarianceTotal = 0;
    let offerEarningsTotal = 0;

    if (!isNational) {
      totalRegionalActualEarnings = this.ryoEarningsAmount * assignNumberValue(this.fields.regionalActualSalesVolume);
      totalNationalActualEarnings = this.ryoEarningsAmount * assignNumberValue(this.fields.nationalActualSalesVolume);

      this.allRyos.forEach(ryo => {
        totalRegionalActualEarnings += ryo.combinedPerUnitCost * assignNumberValue(this.fields.regionalActualSalesVolume);
        totalNationalActualEarnings += ryo.combinedPerUnitCost * assignNumberValue(this.fields.nationalActualSalesVolume);
      });

      this.allOffers.forEach(offer => {
        const units = assignNumberValue(offer.optionTypeName === OPTION_TYPE_NAMES.FINAL_PAY ? this.fields.regionalActualDealerStock : offer.regionalActualUnits);
        totalRegionalActualUnits += offer.isCustomerChoice || (offer.isMisc && offer.isFourthOption) ? assignNumberValue(offer.regionalActualUnits) : 0;
        totalNationalActualUnits += offer.isCustomerChoice || (offer.isMisc && offer.isFourthOption) ? assignNumberValue(offer.nationalActualUnits) : 0;

        if (offer.calcRegCost && units && assignNumberValue(offer.regionalActualCost)) {
          offer.regionalActualOfferCost = assignDollarCents(units * assignNumberValue(offer.regionalActualCost));
        }

        totalRegionalActualCosts += assignNumberValue(offer.regionalActualCost);
        totalRegionalActualOfferCosts += assignNumberValue(offer.regionalActualOfferCost);
        totalNationalActualCosts += assignNumberValue(offer.nationalActualCost);
        offer.regionalCostVariance = offer.offerCost - assignNumberValue(offer.regionalActualOfferCost);
        offer.nationalCostVariance = offer.offerCost - assignNumberValue(offer.nationalActualOfferCost);
        regionalCostVarianceTotal += assignNumberValue(offer.regionalCostVariance);
        nationalCostVarianceTotal += assignNumberValue(offer.nationalCostVariance);
        regionalProgramCostVarianceTotal += assignNumberValue(offer.regionalActualOfferCost) - offer.offerCost;
        nationalProgramCostVarianceTotal += assignNumberValue(offer.nationalActualOfferCost) - offer.offerCost;
      });

      this.lumpsumOptions.forEach(offer => {
        totalRegionalActualEarnings += offer.combinedPerUnitCost;
        totalNationalActualEarnings += offer.combinedPerUnitCost;
      });

      /* reloop to find actual pen rates */
      this.allOffers.forEach(offer => {
        const salesVolumeAndDealerStock = offer.optionTypeName === OPTION_TYPE_NAMES.FINAL_PAY ? this.fields.regionalActualDealerStock : this.fields.regionalActualSalesVolume;
        if (salesVolumeAndDealerStock) {
          offer.regionalActualPenetration = 0;
          const penRate = assignNumberValue(offer.regionalActualUnits) / assignNumberValue(salesVolumeAndDealerStock);
          offer.regionalActualPenetration = assignNumberValue(!penRate ? 0 : Math.round(penRate * 100));
        }
        totalRegionalActualPenetration += offer.isCustomerChoice ? assignNumberValue(offer.regionalActualPenetration) : 0;
      });
    } else {
      totalNationalActualEarnings = assignNumberValue(this.ryoEarningsAmount) * assignNumberValue(this.fields.nationalActualSalesVolume);

      this.allRyos.forEach(ryo => {
        totalNationalActualEarnings += assignNumberValue(ryo.combinedPerUnitCost) * assignNumberValue(this.fields.nationalActualSalesVolume);
      });

      this.allOffers.forEach(offer => {
        const units = assignNumberValue(offer.optionTypeName === OPTION_TYPE_NAMES.FINAL_PAY ? this.fields.nationalActualDealerStock : offer.nationalActualUnits);
        totalNationalActualUnits += offer.isCustomerChoice || (offer.isMisc && offer.isFourthOption) ? assignNumberValue(offer.nationalActualUnits) : 0;
        if (offer.calcNatCost && units && assignNumberValue(offer.nationalActualCost)) {
          offer.nationalActualOfferCost = assignDollarCents(units * assignNumberValue(offer.nationalActualCost));
        }

        totalNationalActualCosts += assignNumberValue(offer.nationalActualCost);
        totalNationalActualOfferCosts += assignNumberValue(offer.nationalActualOfferCost);
        offer.regionalCostVariance = offer.offerCost - assignNumberValue(offer.regionalActualOfferCost);
        offer.nationalCostVariance = offer.offerCost - assignNumberValue(offer.nationalActualOfferCost);
        nationalCostVarianceTotal += assignNumberValue(offer.nationalCostVariance);
        regionalCostVarianceTotal += assignNumberValue(offer.regionalCostVariance);
        regionalProgramCostVarianceTotal += assignNumberValue(offer.regionalActualOfferCost) - assignNumberValue(offer.offerCost);
        nationalProgramCostVarianceTotal += assignNumberValue(offer.nationalActualOfferCost) - assignNumberValue(offer.offerCost);
      });

      this.lumpsumOptions.forEach(offer => {
        totalNationalActualEarnings += assignNumberValue(offer.combinedPerUnitCost);
      });

      /* reloop to find actual pen rates */
      this.allOffers.forEach(offer => {
        const salesVolumeAndDealerStock = offer.optionTypeName === OPTION_TYPE_NAMES.FINAL_PAY ? this.fields.nationalActualDealerStock : this.fields.nationalActualSalesVolume;
        if (salesVolumeAndDealerStock) {
          offer.nationalActualPenetration = 0;
          const penRate = assignNumberValue(offer.nationalActualUnits) / assignNumberValue(salesVolumeAndDealerStock);
          offer.nationalActualPenetration = !penRate ? 0 : Math.round(penRate * 100);
        }
        totalNationalActualPenetration += offer.isCustomerChoice ? assignNumberValue(offer.nationalActualPenetration) : 0;
      });
    }

    this.offers.forEach(offer => {
      offerEarningsTotal += Number(offer.offerEarnings);
    });

    this.totalRegionalActualUnits = totalRegionalActualUnits;
    this.totalRegionalActualPenetration = totalRegionalActualPenetration;
    this.totalRegionalActualCosts = assignDollarCents(totalRegionalActualCosts);
    this.totalRegionalActualOfferCosts = assignDollarCents(totalRegionalActualOfferCosts);
    this.totalRegionalActualEarnings = totalRegionalActualEarnings;
    this.regionalEarningsVariance = this.totalRegionalActualEarnings - this.raEarnings;
    this.regionalCostVarianceTotal = regionalCostVarianceTotal;
    this.regionalProgramCostVarianceTotal = regionalProgramCostVarianceTotal;
    this.totalRegionalActualBalance = this.totalRegionalActualEarnings - this.totalRegionalActualOfferCosts;
    this.regionalBalanceVariance = assignDollarCents(this.totalRegionalActualBalance - this.raBalance);
    this.remainingRegionalActualEarnings = this.totalRegionalActualEarnings - offerEarningsTotal;

    this.totalNationalActualUnits = totalNationalActualUnits;
    this.totalNationalActualPenetration = totalNationalActualPenetration;
    this.totalNationalActualCosts = assignDollarCents(totalNationalActualCosts);
    this.totalNationalActualOfferCosts = assignDollarCents(totalNationalActualOfferCosts);

    this.totalNationalActualEarnings = totalNationalActualEarnings;
    this.nationalEarningsVariance = this.totalNationalActualEarnings - this.raEarnings;
    this.nationalCostVarianceTotal = nationalCostVarianceTotal;
    this.nationalProgramCostVarianceTotal = nationalProgramCostVarianceTotal;
    this.totalNationalActualBalance = this.totalNationalActualEarnings - this.totalNationalActualOfferCosts;
    this.nationalBalanceVariance = assignDollarCents(this.totalNationalActualBalance - this.raBalance);
    this.remainingNationalActualEarnings = this.totalNationalActualEarnings - offerEarningsTotal;
  };

  validateActualUnits = (isNational: boolean) => {
    let actualTotalUnits = 0;
    const salesVolume = isNational ? this.fields.nationalActualSalesVolume : this.fields.regionalActualSalesVolume;

    this.offers.forEach(offer => {
      actualTotalUnits +=
        offer.isCustomerChoice || (offer.isMisc && offer.isFourthOption) ? assignNumberValue(isNational ? offer.nationalActualUnits : offer.regionalActualUnits) : 0;

      if (this.isTripleChoice && Number(this.totalCustomerCash) === 1 && offer.optionTypeName === OPTION_TYPE_NAMES.CUSTOMER_CASH) {
        this.invalidCustomerCash = isNational ? Number(offer.nationalActualUnits) < 0 : Number(offer.regionalActualUnits) < 0;
      }
    });

    this.totalRegionalActualUnits = actualTotalUnits;
    this.totalNationalActualUnits = actualTotalUnits;

    this.isInvalid = isNational
      ? (this.isTripleChoice && this.totalCustomerCash === 1 && this.invalidCustomerCash) || assignNumberValue(this.totalNationalActualUnits) !== salesVolume
      : (this.isTripleChoice && this.totalCustomerCash === 1 && this.invalidCustomerCash) || assignNumberValue(this.totalRegionalActualUnits) !== salesVolume;
  };

  /* update lease subvention cash act units, penetration, cost */
  updateSubCash = (isNational: boolean, offer: ReconcileOfferModel) => {
    /* match sub cash id with lease subvention cash and assign lease actual units to lease subvention cash actual units */
    if (offer.optionTypeName === AdminConstants.OPTION_TYPE_NAMES.LEASE) {
      this.totalNationalActualOfferCosts = 0;
      this.totalRegionalActualOfferCosts = 0;
      this.offers.forEach(opt => {
        if (
          opt.optionTypeName === AdminConstants.OPTION_TYPE_NAMES.TFS_LEASE_SUBVENTION_CASH &&
          offer.offerId === opt.offerId &&
          offer.tier === opt.tier &&
          offer.term === opt.term
        ) {
          if (isNational) {
            opt.nationalActualUnits = offer.nationalActualUnits;
            opt.nationalActualPenetration = offer.nationalActualPenetration;
            opt.nationalActualOfferCost = assignDollarCents(assignNumberValue(opt.nationalActualUnits) * assignNumberValue(opt.nationalActualCost));
          } else {
            opt.regionalActualUnits = offer.regionalActualUnits;
            opt.regionalActualPenetration = offer.regionalActualPenetration;
            opt.regionalActualOfferCost = assignDollarCents(assignNumberValue(opt.regionalActualUnits) * assignNumberValue(opt.regionalActualCost));
          }
        }
        this.totalNationalActualOfferCosts += assignNumberValue(opt.nationalActualOfferCost);
        this.totalRegionalActualOfferCosts += assignNumberValue(opt.regionalActualOfferCost);
      });
    }
  };
}

export default ReconcileSeriesModel;
