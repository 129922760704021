import { makeAutoObservable } from 'mobx';
import { AdminConstants, FeatureFlags, isFlatCostOfferType } from 'oat-admin-common';
import { assignBooleanValue, uuidv4 } from 'oat-common-ui';
import { FEATURE_OR_5454_PHASE_2, miscRequiredFields } from '../../../constants/global';
import { CashDetails, CombinedOffers, CompetitorDetails, LeaseDetails } from '../../../gql/generated';
import { assignNumberValue, assignStringValue } from '../../../utils/assignValue';
import { findNationalLeaseByTerm, parseRcfsFromRates } from '../../ProgramDetails/models/leaseUtils';

const { OPTION_TYPE_NAMES, CASH_TYPES } = AdminConstants;

export interface AprTerm {
  term: number;
  rate: number;
  subCash: number;
  isEnhanced: boolean;
  isSubCashEnhanced: boolean;
  subCashEstCost: number;
  subCashTfsCost: number;
  subCashEnhTfsCost: number;
  distribution: number;
  modelNote?: string;
  buyRate?: number;
}

class PenRateModel {
  uid = uuidv4();
  id = '';
  rev = '';
  name = '';
  displayName = '';
  subCashName = '';
  optionTypeName = '';
  isAdvertised = false;
  isStandAlone = true;
  isSubCashAdvertised = false;
  canGoOver100 = false;
  startDate = '';
  endDate = '';
  showExcluded = false;
  isFlatCost = false;
  parentId = '';

  penetration = '0';
  forecastedSales = '0';
  offerEarnings = 0;
  offerCost = 0; // this is calculated from sales * offer buydown cost (estimatedCost)
  estimatedCost = 0;
  subCashEstCost = 0;
  offerTfsCost = 0;
  offerEnhTfsCost = 0;
  subCashOfferCost = 0;
  subCashOfferTfsCost = 0;
  subCashOfferEnhTfsCost = 0;

  tfsCost = 0;
  enhTfsCost = 0;
  subCashTfsCost = 0;
  subCashEnhTfsCost = 0;

  priorPenRate1 = 0;
  priorPenRate2 = 0;
  priorPenRate3 = 0;

  exclusions = '';
  modelNote = '';
  nationalNote = '';

  // Cash Fields
  nationalCash = '0';
  cash = '0';

  // APR Fields
  tier = '';
  terms: number[] = [];
  advTerms: number[] = [];
  nationalAprDetails: AprTerm[] = [];
  aprDetails: AprTerm[] = [];

  // Lease Fields
  term = '';
  tiers: string[] = [];
  nationalRcf = '';
  rcf = '';

  nationalSubCash = 0;
  subCash = 0;

  showToggle = false;
  isFourthOption = false;

  isEnhanced = false;
  isSubCashEnhanced = false;
  isBadVin = false;
  isVinAutoSelected = false;
  isVinStopSale = false;
  isVinError = false;
  isMissingRequiredFields = false;

  createdDate = '';
  contestNumber = '';
  leaseNoteError = false;
  optionTierType = '';
  areTfsEnhRatesUsed = false;

  constructor() {
    makeAutoObservable(this);
  }

  setCommonValues = (offer: CombinedOffers) => {
    this.id = offer.regional.id;
    this.rev = offer.regional.rev;
    this.name = offer.regional.name;
    this.displayName = offer.regional.name;
    this.optionTypeName = offer.regional.optionTypeName;
    this.createdDate = offer.regional.created;
    this.exclusions = offer.regional.vehicles
      .filter(v => !v.isInclusion)
      .map(v => v.modelCode)
      .join(', ');
    this.contestNumber = offer.regional.contestNumber || '';
    this.optionTierType = assignStringValue(offer.regional.optionTierType);
    this.leaseNoteError = assignBooleanValue(
      offer.regional.leaseDetails && offer.regional.leaseDetails.filter(item => item.areTfsEnhRatesUsed && item.note === '').length > 0,
      false,
    );
  };

  setCash = (offer?: CombinedOffers) => {
    if (offer) {
      this.setCommonValues(offer);
      this.isAdvertised = offer.regional.cashDetails?.isAdvertised ?? false;
      this.isStandAlone = !offer.national;
      this.startDate = assignStringValue(offer.regional.cashDetails?.startDate);
      this.endDate = assignStringValue(offer.regional.cashDetails?.endDate);

      this.nationalCash = assignStringValue(offer.national?.cashDetails?.combinedPerUnitCost, '0');
      this.cash = assignStringValue(offer.regional.cashDetails?.combinedPerUnitCost, '0');
      this.isEnhanced = this.isStandAlone || this.nationalCash !== this.cash;
      this.modelNote = assignStringValue(offer.regional.cashDetails?.modelNote);
      this.nationalNote = assignStringValue(offer.national?.cashDetails?.note);

      this.penetration = assignStringValue(offer.regional.cashDetails?.penetration, '0');
      this.priorPenRate1 = assignNumberValue(offer.regional.cashDetails?.priorPenetration);
      this.priorPenRate2 = assignNumberValue(offer.regional.cashDetails?.priorPenetration2);
      this.priorPenRate3 = assignNumberValue(offer.regional.cashDetails?.priorPenetration3);
      this.forecastedSales = assignStringValue(offer.regional.cashDetails?.forecastedSales, '0');
      this.offerEarnings = offer.regional.cashDetails?.offerEarnings ?? 0;
      this.offerCost = offer.regional.cashDetails?.offerCost ?? 0;
      this.estimatedCost = offer.regional.cashDetails?.estimatedCost ?? 0;
    }
  };

  setMisc = (offer?: CombinedOffers) => {
    if (offer) {
      this.setCash(offer);
      this.isFlatCost = isFlatCostOfferType(this.optionTypeName);

      const requiredFields = miscRequiredFields.find(misc => misc.type.includes(offer.regional.optionTypeName as AdminConstants.CASH_TYPES));
      requiredFields?.fields.forEach(field => {
        if (offer.regional.cashDetails) {
          const cashField = offer.regional.cashDetails[field as keyof CashDetails];
          if (cashField instanceof Array<CompetitorDetails>) {
            if (cashField.length === 0 && [CASH_TYPES.CONQUEST, CASH_TYPES.TRADE_IN_ASSISTANCE].includes(offer.regional.optionTypeName as AdminConstants.CASH_TYPES)) {
              this.isMissingRequiredFields = true;
            }

            (cashField as Array<CompetitorDetails>).forEach(detail => {
              if (!detail.make || !detail.model || !detail.years) {
                this.isMissingRequiredFields = true;
              }
            });
          }

          if (cashField === undefined || cashField === null || cashField === '') {
            this.isMissingRequiredFields = true;
          }
          this.tfsCost = assignNumberValue(offer.regional.cashDetails.tfsCost);
          this.enhTfsCost = assignNumberValue(offer.regional.cashDetails.enhTfsCost);
          this.offerEarnings = assignNumberValue(offer.regional.cashDetails.offerEarnings);
          this.offerCost = assignNumberValue(offer.regional.cashDetails.offerCost);
          this.offerTfsCost = assignNumberValue(offer.regional.cashDetails.offerTfsCost);
        }
        if (
          FeatureFlags.FEATURE_OR_3782 &&
          offer.regional.isStandalone &&
          !offer.regional.cashDetails?.targetedAudience &&
          (offer.regional.optionTypeName === (FEATURE_OR_5454_PHASE_2 ? CASH_TYPES.COLLEGE : CASH_TYPES.COLLEGE_GRADUATE) || offer.regional.optionTypeName === CASH_TYPES.MILITARY)
        ) {
          this.isMissingRequiredFields = true;
        }
      });

      this.parentId = offer.regional.parentId || '';
      this.showToggle = true;
      this.isFourthOption = offer.regional.isFourthOption ?? false;
      this.canGoOver100 = offer.regional.optionTypeName === OPTION_TYPE_NAMES.FINAL_PAY || offer.regional.optionTypeName === OPTION_TYPE_NAMES.CLOSING_CASH;
    }
  };

  setRyo = (offer?: CombinedOffers) => {
    if (offer) {
      this.setCash(offer);
      if (offer.regional.optionTypeName === OPTION_TYPE_NAMES.NATIONAL_RYO) {
        this.name = 'Regional RYO';
      }
    }
  };

  setLease = (offer?: CombinedOffers, leaseDetails?: LeaseDetails) => {
    if (offer && leaseDetails) {
      const { subventedRcf, standardRcf } = parseRcfsFromRates(
        leaseDetails.areTfsEnhRatesUsed && leaseDetails.tfsEnhancedRates ? leaseDetails.tfsEnhancedRates : leaseDetails.rates,
        offer.regional.optionTierType === 'ByTier',
      );
      this.setCommonValues(offer);
      this.id = offer.regional.id;
      this.rev = offer.regional.rev;
      this.name = offer.regional.name;
      this.displayName = `${offer.regional.name} ${leaseDetails.highTerm}mo`;
      this.subCashName = 'TFS Lease Subvention Cash';
      this.isAdvertised = leaseDetails.isAdvertised ?? false;
      this.isStandAlone = !offer.national;
      this.isSubCashAdvertised = leaseDetails.isSubCashAdvertised;
      this.startDate = leaseDetails.startDate;
      this.endDate = leaseDetails.endDate;
      this.subCash = leaseDetails.subventionCash ?? 0;
      this.modelNote = leaseDetails.modelNote;
      this.nationalNote = assignStringValue(offer.national?.leaseDetails?.find(detail => detail.note)?.note);

      this.term = assignStringValue(leaseDetails.highTerm);
      this.tiers = leaseDetails.rates.map(i => i.tier);

      this.penetration = assignStringValue(leaseDetails.penetration, '0');
      this.priorPenRate1 = assignNumberValue(leaseDetails.priorPenetration);
      this.priorPenRate2 = assignNumberValue(leaseDetails.priorPenetration2);
      this.priorPenRate3 = assignNumberValue(leaseDetails.priorPenetration3);

      this.forecastedSales = assignStringValue(leaseDetails.forecastedSales, '0');

      this.estimatedCost = assignNumberValue(leaseDetails.estimatedCost);
      this.subCashEstCost = assignNumberValue(leaseDetails.subCashEstCost);
      this.tfsCost = assignNumberValue(leaseDetails.tfsCost);
      this.enhTfsCost = assignNumberValue(leaseDetails.enhTfsCost);
      this.subCashTfsCost = assignNumberValue(leaseDetails.subCashTfsEstCost);
      this.subCashEnhTfsCost = assignNumberValue(leaseDetails.subCashEnhTfsEstCost);

      this.offerEarnings = assignNumberValue(leaseDetails.offerEarnings);
      this.offerCost = assignNumberValue(leaseDetails.offerCost);
      this.offerTfsCost = assignNumberValue(leaseDetails.offerTfsCost);
      this.offerEnhTfsCost = assignNumberValue(leaseDetails.offerEnhTfsCost);
      this.subCashOfferCost = assignNumberValue(leaseDetails.subCashOfferCost);
      this.subCashOfferTfsCost = assignNumberValue(leaseDetails.subCashOfferTfsCost);
      this.subCashOfferEnhTfsCost = assignNumberValue(leaseDetails.subCashOfferEnhTfsCost);

      if (leaseDetails.rates.length) {
        this.rcf = assignStringValue(subventedRcf);
      }

      if (offer.national) {
        const found = findNationalLeaseByTerm(offer.national.leaseDetails ?? [], leaseDetails.highTerm);
        if (found && found.rates.length) {
          this.nationalRcf = assignStringValue(offer.national.optionTierType === 'ByTier' ? found.rates[0].subventedRcf : found.rates.find(i => i.tier === '1+')?.subventedRcf);
          this.nationalSubCash = found.subventionCash ?? 0;
        }
      }

      if (this.optionTierType === 'ByTier') {
        this.tier = leaseDetails.rates[0].tier;
      }

      this.isEnhanced = leaseDetails.isEnhanced;
      this.isSubCashEnhanced = Boolean(this.subCash) && (this.isStandAlone || this.subCash !== this.nationalSubCash);
      this.isBadVin = leaseDetails.isBadVin;
      this.isVinAutoSelected = leaseDetails.isVinAutoSelected;
      this.isVinStopSale = leaseDetails.isVinStopSale;
      this.areTfsEnhRatesUsed = assignBooleanValue(leaseDetails.areTfsEnhRatesUsed, false);

      if (this.isStandAlone && this.isEnhanced) {
        this.nationalRcf = standardRcf.toString();
      }
    }
  };

  updatePenRate = (val: string) => {
    this.penetration = val;
  };

  updateSales = (val: string) => {
    this.forecastedSales = val;
  };

  setShowExcluded = () => {
    this.showExcluded = !this.showExcluded;
  };

  updateExclusions = (newExclusions: string) => {
    this.exclusions = newExclusions;
  };

  get hasPenRateError() {
    return !this.canGoOver100 && Number(this.penetration) > 100;
  }
}

export default PenRateModel;
