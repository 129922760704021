import { AdminConstants } from 'oat-admin-common';
import { FEATURE_OR_2947_PHASE_2, FEATURE_OR_5454_PHASE_2 } from '../../../../constants/global';
import useUrlParams from '../../../../hooks/useUrlParams';
import MiscCardModel from '../../models/MiscCardModel';
import { TermDataModel } from './components/TfsContractModal';

export const useGetMiscTiers = () => {
  const { RegionCodes } = AdminConstants;
  const { region } = useUrlParams();

  return ['1+', '1', '2', '3', '4', '5', '6', '7', ...(region === RegionCodes.SET ? '8' : []), ...(region === RegionCodes.SET ? '9' : [])];
};

export const useGetMiscTerms = (): TermDataModel[] => {
  const tiers = useGetMiscTiers();

  return [
    {
      term: 24,
      tiers,
    },
    {
      term: 36,
      tiers,
    },
    {
      term: 48,
      tiers,
    },
    {
      term: 60,
      tiers,
    },
    {
      term: 72,
      tiers,
    },
    {
      term: 84,
      tiers,
    },
  ];
};

const useMiscOfferCard = (card: MiscCardModel) => {
  const { CASH_TYPES } = AdminConstants;

  const hasPNV = [
    CASH_TYPES.DEALER_WHOLESALE_PROGRAM.valueOf(),
    CASH_TYPES.DEALER_UNIT_STAIR_STEP.valueOf(),
    CASH_TYPES.DEALER_SALES_TRACKING.valueOf(),
    CASH_TYPES.OTHER_DEALERSHIP_PERSONNEL_FLAT_CASH.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_STAIR_STEP.valueOf(),
    CASH_TYPES.DEALERSHIP_PRESONNEL_SALES_TRACKING.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_OVERRIDE.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_TFS_CONTRACT_REWARD.valueOf(),
    CASH_TYPES.RETENTION_PROGRAM.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_PERCENT_STAIR_STEP_SALES.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_PERCENT_STAIR_STEP_PERSONNEL_SALES.valueOf(),
    CASH_TYPES.FINAL_PAY.valueOf(),
    CASH_TYPES.SALES_PERSON_FLAT_CASH.valueOf(),
    CASH_TYPES.SALES_MANAGER_FLAT_CASH.valueOf(),
    CASH_TYPES.NON_CASH_CERTIFICATE.valueOf(),
    CASH_TYPES.OTHER.valueOf(),
    CASH_TYPES.DEFERRED_PAYMENT.valueOf(),
  ].includes(card.fields.type);

  const isGroupOne = [
    FEATURE_OR_5454_PHASE_2 ? CASH_TYPES.COLLEGE.valueOf() : CASH_TYPES.COLLEGE_GRADUATE.valueOf(),
    CASH_TYPES.MILITARY.valueOf(),
    CASH_TYPES.BONUS.valueOf(),
    CASH_TYPES.EVENT.valueOf(),
    CASH_TYPES.VSPP.valueOf(),
    CASH_TYPES.DEALER_TFS_CASH.valueOf(),
    CASH_TYPES.DEALER_TFS_SUBVENTION_CASH.valueOf(),
    FEATURE_OR_2947_PHASE_2 ? CASH_TYPES.DEALER_TFS_FINANCE_SUBVENTION_CASH.valueOf() : CASH_TYPES.DEALER_TFS_APR_SUBVENTION_CASH.valueOf(),
    CASH_TYPES.DEALER_TFS_LEASE_SUBVENTION_CASH.valueOf(),
    FEATURE_OR_2947_PHASE_2 ? CASH_TYPES.TFS_FINANCE_CASH.valueOf() : CASH_TYPES.TFS_APR_CASH.valueOf(),
    CASH_TYPES.TFS_LEASE_CASH.valueOf(),
    CASH_TYPES.CLOSING_CASH.valueOf(),
    CASH_TYPES.DOWN_PAYMENT_ASSISTANCE.valueOf(),
    CASH_TYPES.LOYALTY.valueOf(),
    CASH_TYPES.LEASE_LOYALTY.valueOf(),
    CASH_TYPES.TARGETED_CERTIFICATE.valueOf(),
    CASH_TYPES.CAMPAIGN.valueOf(),
    CASH_TYPES.VENDOR_PURCHASE_PROGRAM.valueOf(),
    CASH_TYPES.DEALER_CASH.valueOf(),
    CASH_TYPES.COMPLIMENTARY_FIRST_PAYMENT.valueOf(),
    CASH_TYPES.PAYMENT_WAIVER.valueOf(),
    CASH_TYPES.TRADE_IN_ASSISTANCE.valueOf(),
    CASH_TYPES.CONQUEST.valueOf(),
  ].includes(card.fields.type);

  const isGroupTwo = [
    CASH_TYPES.DEALER_POINTS.valueOf(),
    CASH_TYPES.DEALER_PERCENT_STAIR_STEP.valueOf(),
    CASH_TYPES.DEALER_HIT_AND_WIN.valueOf(),
    CASH_TYPES.DEALER_POT_OF_GOLD.valueOf(),
    CASH_TYPES.DEALER_TRIP_INCENTIVE.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_VARIABLE_PAYMENT.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_POINTS.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_NON_VIN.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_CASH_BASH.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_HIT_AND_WIN.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_POT_OF_GOLD.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_TRIP.valueOf(),
    CASH_TYPES.DEALER_WHOLESALE_PROGRAM.valueOf(),
    CASH_TYPES.DEALER_UNIT_STAIR_STEP.valueOf(),
    CASH_TYPES.DEALER_SALES_TRACKING.valueOf(),
    CASH_TYPES.OTHER_DEALERSHIP_PERSONNEL_FLAT_CASH.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_STAIR_STEP.valueOf(),
    CASH_TYPES.DEALERSHIP_PRESONNEL_SALES_TRACKING.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_OVERRIDE.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_TFS_CONTRACT_REWARD.valueOf(),
    CASH_TYPES.RETENTION_PROGRAM.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_PERCENT_STAIR_STEP_SALES.valueOf(),
    CASH_TYPES.DEALERSHIP_PERSONNEL_PERCENT_STAIR_STEP_PERSONNEL_SALES.valueOf(),
    CASH_TYPES.FINAL_PAY.valueOf(),
    CASH_TYPES.SALES_PERSON_FLAT_CASH.valueOf(),
    CASH_TYPES.SALES_MANAGER_FLAT_CASH.valueOf(),
    CASH_TYPES.NON_CASH_CERTIFICATE.valueOf(),
    CASH_TYPES.OTHER.valueOf(),
  ].includes(card.fields.type);

  return {
    hasPNV,
    isGroupOne,
    isGroupTwo,
  };
};

export default useMiscOfferCard;
