import { makeAutoObservable } from 'mobx';
import { uuidv4 } from 'oat-common-ui';
import { FundingSourceSplits } from '../../../../gql/generated';
import { assignStringValue } from '../../../../utils/assignValue';

export class FundingSplitItem {
  uid = uuidv4();
  from = '';
  to = '';
  tfsShare = '';
  rateError: boolean | undefined = undefined;
  tfsShareError: boolean | undefined = undefined;
  disableRateInput = false;
  disableTfsShare = false;

  constructor(fundingSourceSplit?: FundingSourceSplits) {
    makeAutoObservable(this);

    if (fundingSourceSplit) {
      this.from = assignStringValue(fundingSourceSplit.from);
      this.to = assignStringValue(fundingSourceSplit.to);
      this.tfsShare = assignStringValue(fundingSourceSplit.tfsShare);
    }
  }
}

class FundingSplitTerm {
  tier = '';
  term = 0;
  splits: FundingSplitItem[] = [];

  constructor(term: number, splits: FundingSplitItem[], tier?: string) {
    makeAutoObservable(this);

    this.splits = splits;
    this.term = term;
    this.tier = tier || '';
  }

  removeSplits = () => {
    const noneditSplits = this.splits.filter(item => item.disableTfsShare);
    const editableSplits = this.splits.filter(item => !item.disableTfsShare);

    const newItem = new FundingSplitItem();
    newItem.from = editableSplits[0].from;
    newItem.to = editableSplits[editableSplits.length - 1].to;
    newItem.tfsShare = editableSplits[editableSplits.length - 1].tfsShare;
    newItem.disableRateInput = true;
    newItem.disableTfsShare = false;

    this.splits = [...noneditSplits, newItem];
  };

  /**
   * When adding, the added item will always be
   * from: 0, to: regional rate, tfsShare: index - 1's tfsShare
   *
   * The item before the added item needs to change to
   * if non-blended
   * from: national rate, else previous's to, to: 0, tfsShare: 0
   *
   * else if blended
   * from: previous's to, to: 0, tfsShare: 0
   *
   */
  addSplit = () => {
    const copy = this.splits.slice();

    // insert new element second to last
    /*
    Insert new element second to last
    Non blended: 
       5 - 2 - 20  goes to

       5 - 0 - 0
       0 - 2 - 20

    Already Blended:
      5 - 4 - 15
      4 - 2 - 20  goes to

      5 - 4 - 15
      4 - 0 -  0
      0 - 2  - 20

    */
    const lastIndex = this.splits.length - 1;

    // this means 2 or more tfsShare equals a blended lease
    const isBlended = this.splits.filter(item => !item.disableTfsShare).length > 1;

    const itemToAdd = {
      uid: uuidv4(),
      from: isBlended ? this.splits[lastIndex].from : this.splits[lastIndex - 1].to,
      to: '0',
      tfsShare: '0',
      disableRateInput: false,
      disableTfsShare: false,
      rateError: true,
      tfsShareError: false,
    };

    copy.splice(lastIndex, 0, itemToAdd);

    // edit last element
    copy[copy.length - 1].from = '0';

    this.splits = copy;
  };

  editRate = (item: FundingSplitItem, value: string) => {
    item.to = value;
    const index = this.splits.findIndex(i => item.uid === i.uid);
    this.splits[index + 1].from = value;
    item.rateError = Number(value) >= Number(item.from) || Number(value) <= Number(this.splits[index + 1].to);
  };

  editTfsShare = (item: FundingSplitItem, value: string) => {
    item.tfsShareError = Number(value) > 100;
    item.tfsShare = value;
  };

  setSplits = (splits: FundingSplitItem[]) => {
    this.splits = splits;
  };

  get hasError() {
    return this.splits.some(item => item.rateError || item.tfsShareError);
  }
}

export default FundingSplitTerm;
