export const getOfferHeaderSource = (isStandAlone: boolean, forCreate: boolean, isAdvertised: boolean, isEnhanced: boolean) => {
  let source = '';
  const nonAdv = '(Non-Advertised)';

  if (forCreate) {
    return source;
  }

  if (isStandAlone) {
    source = isAdvertised ? 'Stand Alone Offer' : `Stand Alone Offer ${nonAdv}`;
  } else {
    if (isAdvertised) {
      source = isEnhanced ? 'Enhanced National Offer' : 'National Offer';
    } else {
      source = isEnhanced ? `Enhanced National Offer ${nonAdv}` : `National Offer ${nonAdv}`;
    }
  }

  return source;
};
