import { IconNames, OATIcon } from 'oat-common-ui';
import { ReactNode } from 'react';

interface ItemProps {
  children?: ReactNode;
  icon?: IconNames;
  onClick?: () => void;
}

export const ItemResetToNational = ({ onClick }: ItemProps) => {
  return (
    <li onClick={onClick}>
      <OATIcon icon="reset" />
      Reset to National Offer
    </li>
  );
};

export const ItemResetPrevRegionalOffer = ({ onClick }: ItemProps) => {
  return (
    <li onClick={onClick}>
      <OATIcon icon="reset" />
      Reset to Previous Regional Offer
    </li>
  );
};

export const ItemEnhanceAndRefine = ({ onClick }: ItemProps) => {
  const label = 'Enhance & Refine';
  return (
    <li onClick={onClick}>
      <OATIcon icon="duplicate" />
      {label}
    </li>
  );
};

export const ItemEditSubventionCash = ({ onClick }: ItemProps) => {
  return (
    <li onClick={onClick}>
      <OATIcon icon="dollar_amount" />
      {'Edit Subvention Cash'}
    </li>
  );
};

interface AdvertisedItemProps extends ItemProps {
  advertised: boolean;
  nonAdLabel?: string;
  adLabel?: string;
}

export const ItemAdvertised = ({ advertised, nonAdLabel = 'Make Non-Advertised', adLabel = 'Make Advertised', onClick }: AdvertisedItemProps) => {
  return (
    <li onClick={onClick}>
      <OATIcon icon={advertised ? 'eye-blocked' : 'eye'} />
      {advertised ? nonAdLabel : adLabel}
    </li>
  );
};

export const ItemAdjustPenRates = ({ onClick }: ItemProps) => {
  return (
    <li onClick={onClick}>
      <OATIcon icon="duplicate" />
      Adjust Pen. Rates/FSV
    </li>
  );
};

export const ItemRemoveOffer = ({ onClick }: ItemProps) => {
  return (
    <li onClick={onClick}>
      <OATIcon icon="trash" />
      Remove Offer
    </li>
  );
};

export const ItemTfsContracts = ({ onClick, icon = "calendar" }: ItemProps) => {
  return (
    <li onClick={onClick}>
      <OATIcon icon={icon} />
      Edit Tiers & Terms
    </li>
  );
};

export const ItemEditModels = ({ onClick }: ItemProps) => {
  return (
    <li onClick={onClick}>
      <OATIcon icon="duplicate" />
      Edit Models
    </li>
  );
};

export const ItemRemoveSeriesProfile = ({ onClick }: ItemProps) => {
  return (
    <li onClick={onClick}>
      <OATIcon icon="trash" />
      Remove Custom Series
    </li>
  );
};
