import { observer } from 'mobx-react-lite';
import { dateStringToDate, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import IpBreadCrumbs from '../../../components/IpComponents/IpBreadCrumbs';
import MainFooter from '../../../components/MainFooter';
import MainHeader from '../../../components/MainHeader';
import { FEATURE_OR_2477, FEATURE_OR_4154 } from '../../../constants/global';
import { CreateAprOfferInput, useCreateAprOfferMutation } from '../../../gql/generated';
import useAprEnhCostShareUtils from '../../../hooks/useAprEnhCostShareUtils';
import useAprEnhTfsCostShareUtils from '../../../hooks/useAprEnhTfsCostShareUtils';
import useOfferEligibility from '../../../hooks/useOfferEligibility';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import AprCard from '../../ProgramDetails/components/AprCard';
import { buildSaveAprInput } from '../../ProgramDetails/components/AprCard/utils';
import DualEnhCostShareModal from '../../ProgramDetails/components/DualEnhCostShareModal';
import EnhCostShareModal from '../../ProgramDetails/components/EnhCostShareModal';
import CreateOfferContent from '../CreateOfferContent';
import CreateOfferCta from '../CreateOfferCta';
import { transformAprCardData } from './utils';

const CreateAprComponent = () => {
  const {
    userInfoStore: { isNational, isGst, isSETUser, isLexus },
    createOfferStore: { offering, seriesProfile, rgnlAlt, aprCard },
  } = useStores();

  const { region, period, profile, regalt } = useUrlParams();
  const { error: toastError } = useToast();

  const history = useHistory();

  const [createAprOffer] = useCreateAprOfferMutation();

  const breadCrumbs = IpBreadCrumbs(isNational(), offering, offering.regionCode, seriesProfile, rgnlAlt, 'Create APR Offer', '', false, profile);

  const userInfo = { isGst: isGst(), isSet: isSETUser(), isLexus: isLexus() };

  const { handleEnhCostShare, handleEnhTfsCostShare } = useOfferEligibility();
  const { handleCheckForEnhCostShares, handleUpdateAttributesOnCreate, buildEnhCostSharePayloadOnCreate, showEnhCostShareModal, setShowEnhCostShareModal } =
    useAprEnhCostShareUtils(aprCard, handleEnhCostShare, offering);
  const {
    handleCheckForEnhTfsCostShares,
    buildEnhTfsCostSharePayloadOnCreate,
    handleUpdateTfsAttributesOnUpdate,
    showEnhTfsCostShareModal,
    setShowEnhTfsCostShareModal,
    handleResetDates,
  } = useAprEnhTfsCostShareUtils(aprCard, handleEnhTfsCostShare, offering);

  const handleOnEnhCostShareModalSubmit = (isEnhCostShareAccepted = false) => {
    if (isEnhCostShareAccepted) {
      aprCard.setIsEligibleForEnhCostShare(!!aprCard.enhancedCostShare);
      aprCard.setIsEnhCostShareAccepted(true);
      aprCard.setEnhCostShareOfferId(aprCard.enhancedCostShare?.offerId ?? '');
    } else {
      aprCard.setIsEligibleForEnhCostShare(false);
      aprCard.setEnhancedCostShare(undefined);
      aprCard.setIsEnhCostShareAccepted(false);
    }
  };

  const handleCreateOffer = async (
    checkForEnhCostShares = false,
    isEnhCostShareAccepted = false,
    checkForEnhTfsCostShares = false,
    isEnhTfsCostShareAccepted = false,
    onSecondModal = false,
  ) => {
    if (checkForEnhCostShares) {
      const { showModal } = handleCheckForEnhCostShares();

      if (checkForEnhTfsCostShares && !isSETUser()) {
        const { showModal: showTfsModal } = handleCheckForEnhTfsCostShares();

        if (showModal || showTfsModal) {
          return;
        }
      } else {
        if (showModal) {
          return;
        }
      }
    }

    // ** onSecondModal param used regarding ticket 5826,
    // ** If eligible for both enh cost share & enhtfs & selecting cancel on EITHER modal --> nothing is applied, dates reverted

    // For cost share, handling recalculations, & reverting dates or model codes if user clicks cancel on modal
    const isEnhAcceptedParam = onSecondModal ? true : isEnhCostShareAccepted;
    handleUpdateAttributesOnCreate(isEnhAcceptedParam);

    // For buy rate, handling recalculations, & reverting dates if user clicks cancel on modal
    const isEnhTfsAcceptedParam = onSecondModal ? true : isEnhTfsCostShareAccepted;
    handleUpdateTfsAttributesOnUpdate(isEnhTfsAcceptedParam, !isEnhTfsAcceptedParam);

    const enhCostSharePayload = buildEnhCostSharePayloadOnCreate(rgnlAlt.id, isEnhCostShareAccepted, onSecondModal);
    const enhTfsCostSharePayload = buildEnhTfsCostSharePayloadOnCreate(enhCostSharePayload, isEnhTfsAcceptedParam, onSecondModal);

    const createAprPayload: CreateAprOfferInput = {
      rgnlAltId: rgnlAlt.id,
      ...transformAprCardData(aprCard, userInfo, true),
    };

    try {
      const res = await trackPromise(
        createAprOffer({
          variables: {
            input: buildSaveAprInput(createAprPayload, enhCostSharePayload, enhTfsCostSharePayload),
          },
        }),
      );

      if (res.data?.createAprOffer.success) {
        if (res.data?.createAprOffer.offer.aprDetails) {
          aprCard.setInitialDates(
            dateStringToDate(res.data.createAprOffer.offer.aprDetails[0].startDate),
            dateStringToDate(res?.data?.createAprOffer?.offer?.aprDetails[0].endDate),
          );
        }

        const anchor = 'apr-' + res.data?.createAprOffer.offer?.id;
        history.push(`/details/region/${region}/period/${period}/profile/${profile}/regalt/${regalt}?scrollTo=${anchor}`);
      }
    } catch (e) {
      toastError((e as Error).message);
    }
  };

  const displayEnhCostShareModal = FEATURE_OR_2477 && aprCard.enhCostShareOfferId;
  const displayEnhTfsCostShareModal = FEATURE_OR_4154 && aprCard.enhTfsCostShareId;

  return (
    <>
      {/* For enhanced cost share */}
      {displayEnhCostShareModal && (
        <EnhCostShareModal
          id={`${aprCard.id}-enh-cost-share`}
          onButtonPress={isApplied => {
            handleCreateOffer(false, isApplied);
          }}
        />
      )}

      {/* For enhanced TFS cost share */}
      {displayEnhTfsCostShareModal && (
        <EnhCostShareModal
          id={`${aprCard.id}-enh-tfs-cost-share`}
          title="Enhanced TFS Cost Share Available"
          text="Offer is eligible for National Enhanced TFS Cost Share. Do you want to apply?"
          onButtonPress={isApplied => {
            if (!isApplied) {
              handleResetDates(true);
            }

            handleCreateOffer(false, false, false, isApplied);
          }}
        />
      )}

      {/* For enhanced cost share & enhanced tfs cost share - OR-5826 */}
      {displayEnhCostShareModal && displayEnhTfsCostShareModal && (
        <DualEnhCostShareModal
          idOne={`${aprCard.id}-dual-enh-cost-share`}
          idTwo={`${aprCard.id}-dual-enh-tfs-cost-share`}
          onButtonPressOne={isApplied => {
            handleOnEnhCostShareModalSubmit(isApplied);
          }}
          onButtonPressTwo={isApplied => {
            if (!isApplied || !aprCard.isEnhCostShareAccepted) {
              handleResetDates(true);
              aprCard.setIsEligibleForEnhTfsCostShare(false);
              aprCard.setEnhTfsCostShare(undefined);
              aprCard.setIsEnhTfsCostShareAccepted(undefined);
              handleCreateOffer();
              return;
            }

            handleCreateOffer(false, false, false, isApplied, true);
          }}
          showEnhCostShareModal={showEnhCostShareModal}
          showEnhTfsCostShareModal={showEnhTfsCostShareModal}
          setShowEnhCostShareModal={setShowEnhCostShareModal}
          setShowEnhTfsCostShareModal={setShowEnhTfsCostShareModal}
        />
      )}

      <MainHeader breadCrumbs={breadCrumbs} headerTitle="Create APR Offer" />
      <CreateOfferContent>
        <AprCard aprCard={aprCard} />
        <CreateOfferCta
          id={`${aprCard.id}-create-apr-btn`}
          onClick={() => handleCreateOffer(!!FEATURE_OR_2477, false, !!FEATURE_OR_4154, false, false)}
          disabled={!aprCard.isAvgAmountValid || (isGst() && !aprCard.isSpecialEditionValid) || aprCard.hasError}
        />
      </CreateOfferContent>
      <MainFooter />
    </>
  );
};

export default observer(CreateAprComponent);
