import { ReactNode } from 'react';
import IpBreadCrumbs from '../../../components/IpComponents/IpBreadCrumbs';
import { SetStandardRate, StdAprRate, useGetSetStdAprRatesQuery, useGetStandardAprRatesQuery } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import useUserInfo from '../../../utils/useUserInfo';
import LoadingPage from '../../LoadingPage';

interface Props {
  children?: ReactNode;
}

const GetStandardAprRates = ({ children }: Props) => {
  const {
    userInfoStore: {
      userInfo: { brand },
    },
    offeringsStore: { offering },
    rcfsResidualsStore,
  } = useStores();
  const { isSETUser } = useUserInfo();

  const { data, loading, error } = useGetStandardAprRatesQuery({
    variables: {
      brand,
      offeringYear: offering.startDate.split('-')[0],
      offeringMonth: offering.startDate.split('-')[1],
      regionCode: Number(offering.regionCode),
    },
    skip: isSETUser(),
  });

  const {
    loading: SETLoading,
    error: SETError,
    data: SETData,
  } = useGetSetStdAprRatesQuery({
    variables: {
      offeringId: offering.id,
    },
    skip: !isSETUser(),
  });

  if (loading || error || SETLoading || SETError) {
    return <LoadingPage breadCrumbs={IpBreadCrumbs(false)} error={!!error} />;
  }

  if (data) {
    rcfsResidualsStore.setStandardRates(data.stdAprRates as StdAprRate[]);
  }

  if (SETData) {
    rcfsResidualsStore.setStandardRates(SETData.setStandardRates as SetStandardRate[]);
  }

  return <>{children}</>;
};

export default GetStandardAprRates;
