import { OATIcon } from 'oat-common-ui';
import useStores from '../../../../../../stores/useStores';
import styles from './styles.module.scss';

interface Props {
  editPen: boolean;
  editUnit: boolean;
  editCost: boolean;
  editPnv: boolean;
  natPnv: boolean;
  natCost: boolean;
  editActuals: (param: string) => void;
  togglePnvOrCost: (param: string) => void;
}

const DetailsTableHeader = ({ editPen, editUnit, editCost, editPnv, natPnv, natCost, editActuals, togglePnvOrCost }: Props) => {
  const {
    userInfoStore: { isNational, isSETUser },
  } = useStores();
  return (
    <thead>
      <tr className={styles.labelRow}>
        <th />
        <td colSpan={4} className={styles.labelCol}>
          Regional Estimates
        </td>
        <td className={styles.penTableSpacer}></td>
        <td colSpan={2} className={styles.labelCol}>
          NVS
        </td>
        <td className={styles.penTableSpacer}></td>
        <td colSpan={6} className={styles.labelCol}>
          Regional Actuals
        </td>
        <td className={styles.penTableSpacer}></td>
        {!isSETUser() && !isNational() && (
          <td colSpan={3} className={styles.labelCol}>
            National
          </td>
        )}
      </tr>
      <tr>
        <td />
        <td>Projected Units</td>
        <td>Projected Pen %</td>
        <td>Projected PNV</td>
        <td>Projected Cost</td>

        <td className={styles.penTableSpacer}></td>

        <td>NVS Units</td>
        <td>Projected Cost</td>
        <td className={styles.penTableSpacer}></td>
        <td>Actual Units {editPen && <OATIcon icon="edit" colorTheme="blue" className={styles.editActuals} onClick={() => editActuals('Unit')} />}</td>
        <td>Actual Pen % {editUnit && <OATIcon icon="edit" colorTheme="blue" className={styles.editActuals} onClick={() => editActuals('Pen')} />}</td>
        <td>Actual PNV {editCost && <OATIcon icon="edit" colorTheme="blue" className={styles.editActuals} onClick={() => editActuals('PNV')} />}</td>
        <td>Actual Cost {editPnv && <OATIcon icon="edit" colorTheme="blue" className={styles.editActuals} onClick={() => editActuals('Cost')} />}</td>
        <td className={styles.penTableSpacer}></td>
        <td>
          Projected Cost <br /> Vs Actual Cost
        </td>
        {!isNational() && (
          <>
            <td className={styles.penTableSpacer}></td>
            {!isSETUser() && (
              <td>
                National <br />
                Units
              </td>
            )}
            {natPnv && !isSETUser() && (
              <td>
                National <br />
                PNV <OATIcon icon="switch" colorTheme="blue" className={styles.editActuals} onClick={() => togglePnvOrCost('pnv')} />
              </td>
            )}
            {natCost && !isSETUser() && (
              <td>
                National <br />
                Cost <OATIcon icon="switch" colorTheme="blue" className={styles.editActuals} onClick={() => togglePnvOrCost('cost')} />
              </td>
            )}
            {!isSETUser() && (
              <td>
                Projected Cost <br /> Vs Actual Cost
              </td>
            )}
          </>
        )}
      </tr>
    </thead>
  );
};

export default DetailsTableHeader;
