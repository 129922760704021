import { Button, OATWrapper } from 'oat-common-ui';
import styles from './styles.module.scss';

interface Props {
  onClick: () => void;
  disabled?: boolean;
  label?: string;
  className?: string;
  id?: string;
}

const CreateOfferCta = ({ onClick, disabled, label = 'Create Offer', className, id }: Props) => {
  return (
    <OATWrapper className={className}>
      <section className={styles.createOfferCta}>
        <Button variant="primary" onClick={onClick} disabled={disabled} id={id}>
          {label}
        </Button>
      </section>
    </OATWrapper>
  );
};

export default CreateOfferCta;
