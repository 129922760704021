import clsx from 'clsx';
import { OATIcon } from 'oat-common-ui';
import { ReactNode } from 'react';
import SlideToggle from '../../../../../../../components/SlideToggle';
import styles from './styles.module.scss';

interface Props {
  name: string | ReactNode;
  showDates: ReactNode | undefined;
  headerStyles: string;
  optionTypeName?: string;
  isSubCashEnhanced?: boolean;
  dividerStyle?: string;
  showToggle?: boolean;
  toggleIsDisabled?: boolean;
  toggleSelected?: () => void;
  selected?: boolean;
}

const ExpHeader = ({ name, isSubCashEnhanced, showDates, headerStyles, optionTypeName, dividerStyle, showToggle, toggleIsDisabled, toggleSelected, selected }: Props) => {
  return (
    <header className={clsx(styles.header, headerStyles)}>
      <h3>{name}</h3>
      {isSubCashEnhanced && (
        <div className={styles.tfs}>
          <OATIcon icon="plus" size={10} />
          <span className={styles.tfsText}>{optionTypeName}</span>
        </div>
      )}
      {showDates && <div className={clsx(styles.divider, dividerStyle, styles.headerLeft)} />}
      {showDates}
      {showToggle && toggleSelected && (
        <span className={styles.iconLeft}>
          <SlideToggle disabled={toggleIsDisabled} selected={selected || false} toggleSelected={toggleSelected} containerClass={styles.slider} />
        </span>
      )}
    </header>
  );
};

export default ExpHeader;
