import { observer } from 'mobx-react-lite';
import { formatRcf } from 'oat-common-ui';
import TooltipList, { TooltipListItem } from '../../../../../../components/TooltipList/TooltipList';
import useGetTfsLabel from '../../../../../../utils/useGetTfsLabel';
import LeaseFormModel from '../../../../models/LeaseFormModel';

interface Props {
  leaseDetails: LeaseFormModel;
}

const TfsRcfTooltipMessage = ({ leaseDetails }: Props) => {
  const tfsLabel = useGetTfsLabel();

  return (
    <TooltipList>
      <TooltipListItem>
        <span>Standard {tfsLabel} RCF </span>
        <span>{formatRcf(leaseDetails.inputs.standardRcf)}</span>
      </TooltipListItem>
    </TooltipList>
  );
};

export default observer(TfsRcfTooltipMessage);
