import { AdminConstants } from 'oat-admin-common';
import useUrlParams from '../hooks/useUrlParams';
import useStores from '../stores/useStores';

const useUserInfo = () => {
  const { RegionCodes } = AdminConstants;
  const { region } = useUrlParams();
  const {
    userInfoStore: { isLexus },
  } = useStores();

  const isGSTUser = () => {
    return region === RegionCodes.GST;
  };

  const isSETUser = () => {
    return region === RegionCodes.SET;
  };

  const isLexusUser = () => {
    return isLexus();
  };

  return {
    isGSTUser,
    isLexusUser,
    isSETUser,
  };
};

export default useUserInfo;
