import { LeaseDetails, LeaseExample, Offer, RgnlAlt } from '../../../../../gql/generated';
import { findNationalLeaseByTerm } from '../../../../ProgramDetails/models/leaseUtils';

interface RetType {
  leaseOffer: Offer;
  leaseDetails: LeaseDetails;
  nationalLeaseOffer?: Offer;
  nationalLeaseDetails?: LeaseDetails;
  leaseExample?: LeaseExample;
}

const getLeaseOfferFromRgnlAlt = (rgnlAlt: RgnlAlt, offerId: string, offerTerm: string, exampleName?: string): RetType => {
  let leaseOffer: Offer | undefined;
  let leaseDetails: LeaseDetails | undefined;
  let nationalLeaseOffer: Offer | undefined;
  let nationalLeaseDetails: LeaseDetails | undefined;
  let leaseExample: LeaseExample | undefined;
  const regionalTerm = Number(offerTerm);

  rgnlAlt.offers?.forEach(offer => {
    if (offer.regional.id === offerId) {
      leaseOffer = offer.regional;

      if (offer.national) {
        nationalLeaseOffer = offer.national;
      }

      offer.regional.leaseDetails?.forEach(detail => {
        if (detail.highTerm === regionalTerm) {
          leaseDetails = detail;
          leaseExample = detail.leaseExamples?.find(ex => ex.name === exampleName);
        }
      });

      if (offer.national) {
        const nationalDetail = findNationalLeaseByTerm(offer.national.leaseDetails ?? [], regionalTerm);
        if (nationalDetail) {
          nationalLeaseDetails = nationalDetail;
        }
      }
    }
  });

  if (leaseOffer && leaseDetails) {
    return { leaseOffer, leaseDetails, nationalLeaseOffer, nationalLeaseDetails, leaseExample };
  } else {
    throw new Error();
  }
};

export default getLeaseOfferFromRgnlAlt;
