import { BlockInputLabel, OATWrapper, Textarea } from 'oat-common-ui';
import { Offering } from '../../../../gql/generated';
import styles from './styles.module.scss';

interface Props {
  offering: Offering;
}

const NationalNote = ({ offering }: Props) => {
  return (
    <OATWrapper fixed>
      <div className={styles.nationalNoteWrapper}>
        <BlockInputLabel label="Note">
          <Textarea id={`${offering?.id}-national-note`} readOnly value={offering?.nationalNote || ''} className={styles.textArea} />
        </BlockInputLabel>
      </div>
    </OATWrapper>
  );
};

export default NationalNote;
