import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { Status } from '../../../../../../../constants/global';
import useStores from '../../../../../../../stores/useStores';
import PenRateModel from '../../../../../models/PenRateModel';
import ExpHeader from '../ExpHeader';
import styles from '../ExpHeader/styles.module.scss';

interface Props {
  miscModel: PenRateModel;
  showDates: (model: PenRateModel) => ReactNode;
  onToggle?: (model: PenRateModel) => void;
}

const MiscHeader = ({ miscModel, showDates, onToggle }: Props) => {
  const { summaryStore } = useStores();

  const handleOnToggle = () => {
    if (onToggle) {
      onToggle(miscModel);
    }
  };

  return (
    <ExpHeader
      name={miscModel.name}
      showDates={showDates(miscModel)}
      headerStyles={clsx(styles.miscName, styles.miscHeader)}
      dividerStyle={styles.miscDivider}
      showToggle={miscModel.showToggle}
      toggleIsDisabled={summaryStore.offering.status === Status.MEETS_GUIDELINES}
      selected={miscModel.isFourthOption}
      toggleSelected={() => handleOnToggle()}
    />
  );
};

export default observer(MiscHeader);
