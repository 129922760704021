import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import PenRateModel from '../../../../../models/PenRateModel';
import styles from '../styles.module.scss';
import { handleTableValueRender } from '../utils';

interface Props {
  aprModel: PenRateModel;
}

const ExpAprSubvenValues = ({ aprModel }: Props) => {
  const { aprDetails, nationalAprDetails, subCash } = aprModel;

  return (
    <tr className={clsx(styles.offerRow, styles.detailsRow)}>
      {subCash > 0 && (
        <>
          <td className={clsx(styles.expTableTd, styles.expTableTdValue)}>Subv.</td>
          {aprDetails.map((aprDetail, i) => {
            const nationalSubCash = nationalAprDetails.find(natApr => natApr.term === aprDetail.term)?.subCash;

            return (
              <td key={`${aprDetail.subCash}-${i}`} className={styles.expTableTd}>
                {handleTableValueRender(aprDetail.isSubCashEnhanced, `${aprModel.id}-subcash-enhanced`, aprDetail.subCash.toString(), nationalSubCash?.toString() || '', true)}
              </td>
            );
          })}
        </>
      )}
    </tr>
  );
};

export default observer(ExpAprSubvenValues);
