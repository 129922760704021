import { AdminConstants } from 'oat-admin-common';
import { calcRegionalSubNational } from '../pages/ProgramDetails/components/MiscCard/utils/calculatePnvAmountValues';
import MiscCardModel from '../pages/ProgramDetails/models/MiscCardModel';
import { assignDollarCents, assignNumberValue } from './assignValue';

export const calculateOfferCost = (forecastedSales: number, estimatedCost: number, totalSubCashEstCost = 0) => {
  return assignDollarCents(assignDollarCents(forecastedSales * estimatedCost) + totalSubCashEstCost);
};

export const calculateEstCost = (card: MiscCardModel) => {
  const {
    fields: { combinedPerUnitCost, defaultPerUnitCost, tfsCostShare, isEnhanced, type },
    isStandAlone,
  } = card;
  const { CASH_TYPES } = AdminConstants;
  const tfsCostSharePercentage = assignNumberValue(tfsCostShare) / 100;
  const combinedPerUnitCostNumber = assignNumberValue(combinedPerUnitCost);
  const regionalSubNational = calcRegionalSubNational(combinedPerUnitCost, defaultPerUnitCost); //No tfsShare

  if (type === CASH_TYPES.DEFERRED_PAYMENT) {
    return regionalSubNational;
  }

  if (isEnhanced && !isStandAlone) {
    return assignDollarCents(regionalSubNational * (1 - tfsCostSharePercentage));
  } else {
    return assignDollarCents(combinedPerUnitCostNumber - combinedPerUnitCostNumber * tfsCostSharePercentage);
  }
};

export const calculateOfferEarning = (forecastedSales: number, ryoEarnings: number, difference?: number) => {
  if (difference) {
    return Math.round((ryoEarnings + difference) * forecastedSales);
  }

  return Math.round(forecastedSales * ryoEarnings);
};

export const calculateOfferBalance = (earning: number, cost: number) => {
  return assignDollarCents(earning - cost);
};
