import { AdminConstants } from 'oat-admin-common';
import { FEATURE_OR_2947_PHASE_2, FEATURE_OR_5454_PHASE_2, PaymentMethod, PaymentToOptions, SystemToPay } from '../constants/global';

const {
  COLLEGE_GRADUATE,
  COLLEGE,
  MILITARY,
  BONUS,
  EVENT,
  VSPP,
  DEALER_TFS_APR_SUBVENTION_CASH,
  DEALER_TFS_FINANCE_SUBVENTION_CASH,
  DEALER_TFS_CASH,
  DEALER_TFS_LEASE_SUBVENTION_CASH,
  DEALER_TFS_SUBVENTION_CASH,
  TFS_APR_CASH,
  TFS_FINANCE_CASH,
  TFS_LEASE_CASH,
  CLOSING_CASH,
  DOWN_PAYMENT_ASSISTANCE,
  LOYALTY,
  LEASE_LOYALTY,
  TARGETED_CERTIFICATE,
  CAMPAIGN,
  VENDOR_PURCHASE_PROGRAM,
  DEALER_CASH,
  COMPLIMENTARY_FIRST_PAYMENT,
  PAYMENT_WAIVER,
  TRADE_IN_ASSISTANCE,
  CONQUEST,
  DEALERSHIP_PERSONNEL_VARIABLE_PAYMENT,
  DEALERSHIP_PERSONNEL_POINTS,
  DEALERSHIP_PERSONNEL_NON_VIN,
  DEALERSHIP_PERSONNEL_CASH_BASH,
  DEALERSHIP_PERSONNEL_HIT_AND_WIN,
  DEALERSHIP_PERSONNEL_POT_OF_GOLD,
  DEALERSHIP_PERSONNEL_TRIP,
  DEALER_POINTS,
  DEALER_HIT_AND_WIN,
  DEALER_PERCENT_STAIR_STEP,
  DEALER_POT_OF_GOLD,
  DEALER_TRIP_INCENTIVE,
  DEALER_WHOLESALE_PROGRAM,
  DEALER_UNIT_STAIR_STEP,
  DEALER_SALES_TRACKING,
  OTHER_DEALERSHIP_PERSONNEL_FLAT_CASH,
  DEALERSHIP_PERSONNEL_STAIR_STEP,
  DEALERSHIP_PRESONNEL_SALES_TRACKING,
  DEALERSHIP_PERSONNEL_OVERRIDE,
  DEALERSHIP_PERSONNEL_TFS_CONTRACT_REWARD,
  RETENTION_PROGRAM,
  DEALERSHIP_PERSONNEL_PERCENT_STAIR_STEP_SALES,
  DEALERSHIP_PERSONNEL_PERCENT_STAIR_STEP_PERSONNEL_SALES,
  FINAL_PAY,
  SALES_PERSON_FLAT_CASH,
  SALES_MANAGER_FLAT_CASH,
  NON_CASH_CERTIFICATE,
  OTHER,
  DEFERRED_PAYMENT,
} = AdminConstants.CASH_TYPES;

const defaultDealerForPaymentTo = [
  BONUS.valueOf(),
  EVENT.valueOf(),
  VSPP.valueOf(),
  DEALER_TFS_SUBVENTION_CASH.valueOf(),
  DEALER_TFS_CASH.valueOf(),
  FEATURE_OR_2947_PHASE_2 ? DEALER_TFS_FINANCE_SUBVENTION_CASH.valueOf() : DEALER_TFS_APR_SUBVENTION_CASH.valueOf(),
  DEALER_TFS_LEASE_SUBVENTION_CASH.valueOf(),
  FEATURE_OR_2947_PHASE_2 ? TFS_FINANCE_CASH.valueOf() : TFS_APR_CASH.valueOf(),
  TFS_LEASE_CASH.valueOf(),
  CLOSING_CASH.valueOf(),
  DOWN_PAYMENT_ASSISTANCE.valueOf(),
  LOYALTY.valueOf(),
  LEASE_LOYALTY.valueOf(),
  TARGETED_CERTIFICATE.valueOf(),
  CAMPAIGN.valueOf(),
  VENDOR_PURCHASE_PROGRAM.valueOf(),
  DEALER_CASH.valueOf(),
  PAYMENT_WAIVER.valueOf(),
  TRADE_IN_ASSISTANCE.valueOf(),
  CONQUEST.valueOf(),
  FEATURE_OR_5454_PHASE_2 ? COLLEGE.valueOf() : COLLEGE_GRADUATE.valueOf(),
  MILITARY.valueOf(),
];

export const tfsContractDefaultChecked = (type: string) => {
  return (
    type === DEALER_TFS_SUBVENTION_CASH ||
    type === DEALER_TFS_CASH ||
    type === (FEATURE_OR_2947_PHASE_2 ? DEALER_TFS_FINANCE_SUBVENTION_CASH : DEALER_TFS_APR_SUBVENTION_CASH) ||
    type === DEALER_TFS_LEASE_SUBVENTION_CASH ||
    type === (FEATURE_OR_2947_PHASE_2 ? TFS_FINANCE_CASH : TFS_APR_CASH) ||
    type === TFS_LEASE_CASH ||
    type === COMPLIMENTARY_FIRST_PAYMENT ||
    type === PAYMENT_WAIVER ||
    type === DEFERRED_PAYMENT
  );
};

export const getDefaultPaymentTo = (type: string) => {
  if (type === COMPLIMENTARY_FIRST_PAYMENT) {
    return PaymentToOptions.TFS_ONLY;
  } else if (defaultDealerForPaymentTo.includes(type)) {
    return PaymentToOptions.DEALER_ONLY;
  }

  return PaymentToOptions.CUSTOMER_OR_DEALER;
};

export const getDefaultPaymentMethod = (_paymentTo: string) => {
  if (_paymentTo === PaymentToOptions.TFS_ONLY) {
    return 'GL Intercompany';
  }

  return PaymentMethod.ACH_CHECK;
};

export const getDefaultSystemToPay = (type: string) => {
  if (
    type === BONUS ||
    type === (FEATURE_OR_2947_PHASE_2 ? TFS_FINANCE_CASH : TFS_APR_CASH) ||
    type === TFS_LEASE_CASH ||
    type === DEALER_CASH ||
    type === OTHER ||
    type === DEFERRED_PAYMENT ||
    type === (FEATURE_OR_5454_PHASE_2 ? COLLEGE : COLLEGE_GRADUATE) ||
    type === MILITARY ||
    type === LOYALTY ||
    type === LEASE_LOYALTY ||
    type === TARGETED_CERTIFICATE
  ) {
    return SystemToPay.IPLUS;
  } else if (
    type === EVENT ||
    type === VSPP ||
    type === CLOSING_CASH ||
    type === DOWN_PAYMENT_ASSISTANCE ||
    type === CAMPAIGN ||
    type === VENDOR_PURCHASE_PROGRAM ||
    type === TRADE_IN_ASSISTANCE ||
    type === CONQUEST ||
    type === DEALER_POINTS ||
    type === DEALER_HIT_AND_WIN ||
    type === DEALER_WHOLESALE_PROGRAM
  ) {
    return SystemToPay.ICAT;
  } else if (
    type === DEALER_TFS_SUBVENTION_CASH ||
    type === DEALER_TFS_CASH ||
    type === (FEATURE_OR_2947_PHASE_2 ? DEALER_TFS_FINANCE_SUBVENTION_CASH : DEALER_TFS_APR_SUBVENTION_CASH) ||
    type === DEALER_TFS_LEASE_SUBVENTION_CASH ||
    type === DEALER_PERCENT_STAIR_STEP ||
    type === DEALER_UNIT_STAIR_STEP ||
    type === DEALER_SALES_TRACKING
  ) {
    return SystemToPay.NISS;
  } else if (
    type === DEALERSHIP_PERSONNEL_VARIABLE_PAYMENT ||
    type === DEALERSHIP_PERSONNEL_POINTS ||
    type === DEALERSHIP_PERSONNEL_NON_VIN ||
    type === DEALERSHIP_PERSONNEL_CASH_BASH ||
    type === DEALERSHIP_PERSONNEL_HIT_AND_WIN ||
    type === DEALERSHIP_PERSONNEL_POT_OF_GOLD ||
    type === DEALERSHIP_PERSONNEL_TRIP ||
    type === OTHER_DEALERSHIP_PERSONNEL_FLAT_CASH ||
    type === DEALERSHIP_PERSONNEL_STAIR_STEP ||
    type === DEALERSHIP_PRESONNEL_SALES_TRACKING ||
    type === DEALERSHIP_PERSONNEL_OVERRIDE ||
    type === DEALERSHIP_PERSONNEL_TFS_CONTRACT_REWARD ||
    type === RETENTION_PROGRAM ||
    type === DEALERSHIP_PERSONNEL_PERCENT_STAIR_STEP_PERSONNEL_SALES ||
    type === DEALERSHIP_PERSONNEL_PERCENT_STAIR_STEP_SALES ||
    type === SALES_MANAGER_FLAT_CASH ||
    type === SALES_PERSON_FLAT_CASH
  ) {
    return SystemToPay.FISS;
  } else if (type === DEALER_POT_OF_GOLD || type === NON_CASH_CERTIFICATE || type === FINAL_PAY) {
    return SystemToPay.MANUAL;
  } else if (type === DEALER_TRIP_INCENTIVE) {
    return SystemToPay.NA;
  }

  return SystemToPay.TFS;
};
