import clsx from 'clsx';
import { BlockInputLabel, Button, Input } from 'oat-common-ui';
import { useRef, useState } from 'react';
import styles from './styles.module.scss';

interface Props {
  id?: string;
  label?: string;
  file?: string;
  inputClass?: string;
  onChange?: (file: File) => void;
}

const FileUpload = ({ id, label, inputClass, file = '', onChange }: Props) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState(file);

  const handleClick = () => hiddenFileInput.current?.click();

  const handleChange = () => {
    if (onChange) {
      const files = hiddenFileInput.current?.files as FileList;
      setFileName(files[0].name);
      onChange(files[0]);
    }
  };

  return (
    <BlockInputLabel label={label}>
      <div className={styles.inputContainer}>
        <Input value={fileName} inputWrapperClass={styles.inputWrapper} className={clsx(styles.input, inputClass)} readOnly disabled />
        <Button variant="primary" onClick={handleClick} id={`browse-btn-${id}`}>
          Browse
        </Button>
      </div>
      <Input type="file" name="file" style={{ display: 'none' }} ref={hiddenFileInput} onChange={handleChange} />
    </BlockInputLabel>
  );
};

export default FileUpload;
