import { ReactNode } from 'react';
import DollarDisplayCosts from '../../../../components/DollarDisplayCosts';
import styles from './styles.module.scss';

interface Props {
  id: string;
  earnings: number;
  earningsTooltip: ReactNode;
  cost: number;
  costTooltip: ReactNode;
  balance: number;
}

const OfferHeaderCosts = ({ id, earnings, earningsTooltip, cost, costTooltip, balance }: Props) => {
  return (
    <DollarDisplayCosts containerStyles={styles.offerHeaderCosts} id={id} earnings={earnings} earningsTooltip={earningsTooltip} cost={cost} costTooltip={costTooltip} balance={balance} showOperations showRightBorder />
  );
};

export default OfferHeaderCosts;
