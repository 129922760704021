import { OfferTypes } from 'oat-common-ui';
import PenRateModel from '../../../../../models/PenRateModel';
import OfferValue from '../OfferValue';

interface Props {
  cashModel: PenRateModel;
}

const CashValue = ({ cashModel }: Props) => {
  const { cash, isEnhanced } = cashModel;

  return <OfferValue cash={cash} isEnhanced={isEnhanced} name={OfferTypes.CUSTOMER_CASH} />;
};

export default CashValue;
