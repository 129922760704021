import { observer } from 'mobx-react-lite';
import RgnlAltModel from '../../pages/Summary/models/RgnlAltModel';
import { getSeriesProfileHeaderStatus } from '../../pages/Summary/RegionalSummary/SeriesProfileSection/utils';
import styles from './styles.module.scss';

interface Props {
  selectedRa: RgnlAltModel | undefined;
}

const ProfileTitle = ({ selectedRa }: Props) => {
  return (
    <>
      <p className={styles.detail}>{selectedRa?.name}</p>
      {selectedRa && <p className={styles.detail}>{getSeriesProfileHeaderStatus(selectedRa)}</p>}
    </>
  );
};

export default observer(ProfileTitle);
