import { FC, useState } from 'react';
import AprCardModel from '../../../../../../models/AprCardModel';
import AprTierModel from '../../../../../../models/AprTierModel';
import AprFundingSplitModal from '../../../AprFundingSplitModal';
import AprTableHeader from './components/AprTableHeader';
import AprTableRow from './components/AprTableRow';

interface Props {
  card: AprCardModel;
  onChange: (skipDelay?: boolean, skipRecalc?: boolean, updateTfs?: boolean) => void;
  errorMessages: string[];
}

const AprTable: FC<Props> = ({ card, onChange, errorMessages }) => {
  const [fundingSplitOpen, setFundingSplitOpen] = useState(false);

  return (
    <>
      <table>
        <AprTableHeader
          forCreate={card.isForCreate}
          hasBlended={Boolean(card.selectedTierModel?.hasTermWithBlendedCost)}
          isEnhanced={Boolean(card.selectedTierModel?.hasEnhancedTerm)}
          openFundingSplit={() => setFundingSplitOpen(true)}
        />
        <tbody>
          {card.selectedTierModel?.terms.map(term => (
            <AprTableRow key={term.uid} card={card} selectedTierModel={card.selectedTierModel as AprTierModel} term={term} onChange={onChange} errorMessages={errorMessages} />
          ))}
        </tbody>
      </table>
      {fundingSplitOpen && card.selectedTierModel && (
        <AprFundingSplitModal selectedTier={card.selectedTierModel} aprCard={card} onClose={() => setFundingSplitOpen(false)} onSubmit={onChange} />
      )}
    </>
  );
};

export default AprTable;
