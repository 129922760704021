import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { OATIcon, Tooltip } from 'oat-common-ui';
import { FC } from 'react';
import PenRateModel from '../../../pages/Summary/models/PenRateModel';

import styles from './styles.module.scss';

type Props = {
  isModalView?: boolean;
  penRateModel: PenRateModel;
  showAdTerms?: boolean;
};

const PenRateExclusions: FC<Props> = ({ isModalView, penRateModel, showAdTerms = false }) => {
  const active = Boolean(penRateModel.exclusions);

  const getLabel = () => {
    return active ? 'Exclusions' : 'No Exclusions';
  };

  const getTooltipMessage = () => {
    return (
      <div>
        {!isModalView && <span>Models</span>}
        <ul className={styles.modalTooltipMessage}>
          <li className={styles.excludedModels}>{penRateModel.exclusions}</li>
        </ul>
      </div>
    );
  };

  const getTerms = () => {
    return (
      <ul className={isModalView ? styles.modalTooltipMessage : styles.list}>
        {!isModalView ? (
          penRateModel.terms.map(term => {
            return (
              <li key={term}>
                <OATIcon className={styles.icon} icon="check-mark" colorTheme="green" /> {term} mos
              </li>
            );
          })
        ) : (
          <span>{penRateModel.terms.join(',')}</span>
        )}
      </ul>
    );
  };

  const getTiers = () => {
    return (
      <ul className={isModalView ? styles.modalTooltipMessage : styles.list}>
        {!isModalView ? (
          penRateModel.tiers.map(tier => {
            return (
              <li key={tier}>
                <OATIcon className={styles.icon} icon="check-mark" colorTheme="green" /> Tier {tier}
              </li>
            );
          })
        ) : (
          <span>{penRateModel.tiers.join(',')}</span>
        )}
      </ul>
    );
  };

  return (
    <div className={styles.penRateExcl}>
      <Tooltip className={styles.toolTip} id={`penrate-offer-excl-${penRateModel.uid}`} message={getTooltipMessage()} clickable={!isModalView} place="bottom" disable={!active}>
        <p className={clsx(styles.label, active && styles.pointer)}>{getLabel()}</p>
      </Tooltip>

      {penRateModel.terms.length > 0 && (
        <>
          <Tooltip className={styles.toolTip} id={`penrate-offer-terms-${penRateModel.uid}`} message={getTerms()} clickable={!isModalView} place="bottom">
            <p className={clsx(styles.pipedLabel, styles.pointer)}>Terms</p>
          </Tooltip>
          {showAdTerms && penRateModel.advTerms.length > 0 && <p className={styles.pipedLabel}>Adv: {penRateModel.advTerms.join(', ')}</p>}
        </>
      )}

      {penRateModel.tiers.length > 0 && (
        <Tooltip className={styles.toolTip} id={`penrate-offer-tiers-${penRateModel.uid}`} message={getTiers()} clickable={!isModalView} place="bottom">
          <p className={clsx(styles.pipedLabel, styles.pointer)}>Tiers</p>
        </Tooltip>
      )}
      {penRateModel.nationalNote && (
        <Tooltip className={styles.toolTip} id={`penrate-offer-tiers-${penRateModel.nationalNote}`} message={penRateModel.nationalNote} clickable={!isModalView} place="bottom">
          <p className={clsx(styles.pipedLabel, styles.pointer)}>National Note</p>
        </Tooltip>
      )}
    </div>
  );
};

export default observer(PenRateExclusions);
