import { makeAutoObservable } from 'mobx';
import { SeriesMapping } from '../gql/generated';

class SeriesSortingStore {
  seriesMapping: SeriesMapping[] = [];
  seriesMappingLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  setSeriesMapping = (seriesMapping: SeriesMapping[]) => {
    this.seriesMapping = seriesMapping;
    this.seriesMappingLoaded = true;
  };
}

export default SeriesSortingStore;
