import { formatAccounting } from 'oat-common-ui';
import { Offering } from '../../../gql/generated';
import { processOffering } from '../../../pages/Dashboard/util';
import TooltipList, { TooltipListItem } from '../../TooltipList/TooltipList';
import styles from './styles.module.scss';

interface Props {
  balances: Offering[];
}

const FYBalanceTooltip = ({ balances }: Props) => {
  return (
    <TooltipList>
      {balances.map((balance, i) => {
        return (
          <TooltipListItem key={balance.id} symbol={i > 0 ? '+' : ''}>
            <span className={styles.program}>{balance.name}</span>
            <span className={styles.balance}>{formatAccounting(processOffering(balance).balance || 0)}</span>
          </TooltipListItem>
        );
      })}
    </TooltipList>
  );
};

export default FYBalanceTooltip;
