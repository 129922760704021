import { observer } from 'mobx-react-lite';
import { OATIcon } from 'oat-common-ui';
import { useMemo } from 'react';
import Note from '../../../../../../components/Note';
import { ENR_ERROR_MESSAGE } from '../../../../../../constants/global';
import useStores from '../../../../../../stores/useStores';
import AprCardModel from '../../../../models/AprCardModel';
import AprTierModel from '../../../../models/AprTierModel';
import AprTable from './components/AprTable';
import styles from './styles.module.scss';

type Props = {
  card: AprCardModel;
  onChange: (skipDelay?: boolean, skipRecalc?: boolean, updateTfs?: boolean) => void;
  selectedTierModel: AprTierModel;
};

const AprTier = ({ card, selectedTierModel, onChange }: Props) => {
  const {
    fields: { note, tdaNote },
    updateField,
    tier,
  } = selectedTierModel;

  const {
    userInfoStore: { isLexus },
  } = useStores();

  const errorMessages = useMemo(() => {
    const errors = [];

    for (const invalidTier of card.invalidTiers.values()) {
      invalidTier.errorMessage && errors.push(invalidTier.errorMessage);
    }

    return errors;
  }, [card.invalidTiers]);

  return (
    <>
      <div className={styles.aprTermTableWrapper}>
        <AprTable card={card} onChange={onChange} errorMessages={errorMessages} />
        {errorMessages.map((errorMessage, index) => (
          <p className={styles.tierErrorMsg} key={`${errorMessage}_${index}`}>
            <OATIcon className={styles.errorIcon} icon="warning" size={14} />
            {errorMessage}
          </p>
        ))}
        {card.hasEnRError && (
          <p className={styles.tierErrorMsg}>
            <OATIcon className={styles.errorIcon} icon="warning" size={14} />
            {`Tier ${selectedTierModel.tier} - ${ENR_ERROR_MESSAGE}`}
          </p>
        )}
      </div>
      <div className={styles.notesWrapper}>
        <Note
          key={`${tier}-note`}
          label="Note"
          disabled={card.isDisabled}
          value={note}
          onChange={value => {
            updateField('note', value);
            onChange();
          }}
        />
        <Note
          key={`${tier}-Tdanote`}
          label={isLexus() ? 'LDA Note' : 'TDA Note'}
          disabled={card.isDisabled}
          value={tdaNote}
          onChange={value => {
            updateField('tdaNote', value);
            onChange();
          }}
        />
      </div>
    </>
  );
};

export default observer(AprTier);
