import clsx from 'clsx';
import { ReactNode } from 'react';
import DollarDisplay from '../DollarDisplay';
import styles from './styles.module.scss';

interface Props {
  id: string;
  earnings: number;
  earningsLabel?: string;
  earningsTooltip?: ReactNode;
  cost: number;
  costLabel?: string;
  costTooltip?: ReactNode;
  balance: number;
  balanceLabel?: string;
  balanceTooltip?: ReactNode;
  showBalanceTooltip?: boolean;
  containerStyles?: string;
  lightText?: boolean;
  showOperations?: boolean;
  showRightBorder?: boolean;
  leftContent?: ReactNode;
  showTooltips?: boolean;
}

const DollarDisplayCosts = ({
  id,
  earnings,
  earningsLabel,
  earningsTooltip,
  cost,
  costLabel,
  costTooltip,
  balance,
  balanceLabel,
  balanceTooltip,
  showBalanceTooltip,
  containerStyles,
  lightText,
  showOperations,
  showRightBorder,
  leftContent,
  showTooltips = true,
}: Props) => {
  return (
    <div className={clsx(containerStyles || styles.dollarDisplayCosts, showRightBorder && styles.rightBorder)}>
      {leftContent}
      <DollarDisplay
        id={`earnings-${id}`}
        value={earnings.toString()}
        className={styles.dollarAmount}
        lightText={lightText}
        tooltip={earningsTooltip}
        showTooltip={showTooltips}
        label={earningsLabel}
      />

      <DollarDisplay
        id={`cost-${id}`}
        value={cost.toString()}
        className={styles.dollarAmount}
        lightText={lightText}
        tooltip={costTooltip}
        showTooltip={showTooltips}
        label={costLabel}
        showMinus={showOperations}
      />

      <DollarDisplay
        value={balance.toString()}
        useColors
        className={styles.dollarAmount}
        lightText={lightText}
        label={balanceLabel}
        showEquals={showOperations}
        tooltip={balanceTooltip}
        showTooltip={showBalanceTooltip}
      />
    </div>
  );
};

export default DollarDisplayCosts;
