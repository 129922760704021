import clsx from 'clsx';
import { Button } from 'oat-common-ui';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  id?: string;
  className?: string;
  toggled?: boolean;
  dark?: boolean;
  children?: ReactNode;
  onClick?: () => void;
  error?: boolean;
}

const ToggleButton = ({ id, className, toggled, dark, children, onClick, error }: Props) => {
  return (
    <Button id={id} className={clsx(styles.toggleBtn, className, toggled ? styles.toggled : styles.default, dark && styles.dark, error && styles.error)} onClick={onClick}>
      {children}
    </Button>
  );
};

export default ToggleButton;
