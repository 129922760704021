import { AdminConstants, AdminModels } from 'oat-admin-common';
import { dateFormatISOString } from 'oat-common-ui';
import { LeaseDetails, LeaseOfferDetails } from '../../../../../gql/generated';
import getDefaultAcFee from '../../../../../utils/getDefaultAcFee';
import CreateNonAdLeaseModel from '../../models/CreateNonAdLeaseModel';

export const transformUpdateGSTLeasePayload = (
  nonAdvertisedData: CreateNonAdLeaseModel,
  leaseDetails: LeaseDetails,
  enhancedCostShare?: AdminModels.EnhancedCostShare.EnhancedLeaseCostShare,
  enhancedTfsCostShare?: AdminModels.EnhancedTfsCostShare.EnhancedTfsCostShare,
): LeaseOfferDetails => {
  return {
    regionalSubventionRCF: enhancedTfsCostShare?.value ? Number(nonAdvertisedData.fields.rcf) - Number(enhancedTfsCostShare?.value) : Number(nonAdvertisedData.fields.rcf),
    term: leaseDetails.highTerm,
    bonusCash: Number(nonAdvertisedData.fields.bonusCash),
    targetPayment: Number(nonAdvertisedData.fields.targetPayment),
    dueAtSigning: Number(nonAdvertisedData.fields.dueAtSigning),
    netCapCost: Number(nonAdvertisedData.fields.netCapCost),
    endDate: dateFormatISOString(nonAdvertisedData.fields.endDate),
    isAdvertised: true,
    modelCode: Number(nonAdvertisedData.fields.modelCode),
    modelYear: Number(nonAdvertisedData.fields.modelYear),
    series: nonAdvertisedData.fields.series,
    vehicleDescription: nonAdvertisedData.fields.vehicleDescription,
    mileage: Number(nonAdvertisedData.fields.selectedMileage.value),
    totalMsrp: Number(nonAdvertisedData.fields.totalMsrp),
    note: nonAdvertisedData.fields.note,
    subventionCash: Number(nonAdvertisedData.fields.subventionCash),
    residualAmount: Number(nonAdvertisedData.fields.residualValueAmount),
    estimatedCost: Number(nonAdvertisedData.fields.pnv),
    grossCapCost: Number(nonAdvertisedData.fields.grossCapCost),
    startDate: dateFormatISOString(nonAdvertisedData.fields.startDate),
    tdaNote: nonAdvertisedData.fields.tdaNote,
    tfsCostShare: enhancedCostShare?.tfsCostShare ?? Number(nonAdvertisedData.fields.tfsShare),
    downPayment: Number(nonAdvertisedData.fields.downPayment),
    acquisitionFee: getDefaultAcFee(AdminConstants.BRAND_TOYOTA, AdminConstants.RegionCodes.GST),
    subCashTfsShare: enhancedCostShare?.subventionCashTfsCostShare ?? leaseDetails.subCashTfsShare,
    subCashTfsCostShareCap: enhancedCostShare?.subventionCashTfsCostShareCap ?? leaseDetails.subCashTfsCostShareCap,
  };
};

export default transformUpdateGSTLeasePayload;
