import { ReportTypes } from '../constants/global';
import {
  Offering,
  useGenerateDealerHotSheetMutation,
  useGenerateFyReconcileMutation,
  useGenerateGmApprovalMutation,
  useGenerateIagExtractMutation,
  useGenerateLeaseSummaryMutation,
  useGenerateNationalReconcileSummaryMutation,
  useGenerateProgramMatrixMutation,
  useGenerateRaSelectionMutation,
  useGenerateRegionalEnhancementsMutation,
  useGenerateRegionalProposedIncentivesMutation,
  useGenerateRegionalReconcileSummaryMutation,
  useGenerateTfsCostShareReportMutation,
} from '../gql/generated';
import useStores from '../stores/useStores';

interface AllReports {
  [id: string]: () => Promise<unknown>;
}

const useReportMutations = (selectedIp: Offering, isNational: boolean, isReconcile = false): AllReports => {
  const {
    reportsStore: { getRegionalOfferingId },
    reconcileStore: { offering },
  } = useStores();

  const useRegionalParams = () => {
    return {
      variables: { input: { brand: selectedIp.brand, regionalOfferingId: isReconcile ? offering.id : getRegionalOfferingId(), isNational } },
    };
  };

  const useNationalParams = () => {
    return {
      variables: {
        input: { brand: selectedIp.brand, nationalOfferingId: selectedIp.id },
      },
    };
  };

  const [generateIAGExtract] = useGenerateIagExtractMutation(useNationalParams());

  const [generateRASelection] = useGenerateRaSelectionMutation(useNationalParams());

  const [generateProgramMatrix] = useGenerateProgramMatrixMutation(useNationalParams());

  const [generateRegionalEnhancements] = useGenerateRegionalEnhancementsMutation(useNationalParams());

  const [generateLeaseSummary] = useGenerateLeaseSummaryMutation(useRegionalParams());

  const [generateTfsCostShareReport] = useGenerateTfsCostShareReportMutation(useRegionalParams());

  const [generateGMApproval] = useGenerateGmApprovalMutation(useRegionalParams());

  const [generateRegionalReconcileSummary] = useGenerateRegionalReconcileSummaryMutation(useRegionalParams());

  const [generateNationalReconcileSummary] = useGenerateNationalReconcileSummaryMutation(useRegionalParams());

  const [generateFYReconcile] = useGenerateFyReconcileMutation(useRegionalParams());

  const [generateDealerHotSheet] = useGenerateDealerHotSheetMutation(useRegionalParams());

  const [generateRegionalProposedIncentives] = useGenerateRegionalProposedIncentivesMutation(useRegionalParams());

  return {
    [ReportTypes.IAG_EXTRACT]: generateIAGExtract,
    [ReportTypes.RA_SELECTION]: generateRASelection,
    [ReportTypes.PROGRAM_MATRIX]: generateProgramMatrix,
    [ReportTypes.REGIONAL_ENHANCEMENTS]: generateRegionalEnhancements,
    [ReportTypes.LEASE_SUMMARY]: generateLeaseSummary,
    [ReportTypes.TFS_COST_SHARE]: generateTfsCostShareReport,
    [ReportTypes.GM_APPROVAL_REPORT]: generateGMApproval,
    [ReportTypes.RECONCILE_SUMMARY]: isNational ? generateNationalReconcileSummary : generateRegionalReconcileSummary,
    [ReportTypes.FY_RECONCILE]: generateFYReconcile,
    [ReportTypes.DEALER_HOTSHEET]: generateDealerHotSheet,
    [ReportTypes.REGIONAL_PROPOSED_INCENTIVES]: generateRegionalProposedIncentives,
  };
};

export default useReportMutations;
