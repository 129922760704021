import { makeAutoObservable } from 'mobx';
import { AdminConstants } from 'oat-admin-common';
import { LEASE_BAD_VIN_ERROR, LEASE_VIN_AUTOSELECTED_ERROR, LEASE_VIN_STOP_SALE_ERROR, Status } from '../../../constants/global';
import { CombinedOffers, Offering, RgnlAlt } from '../../../gql/generated';
import { assignDollarCents, assignNumberValue } from '../../../utils/assignValue';
import PenRateModel from './PenRateModel';
import processRgnlAltOffers from './utils/processRgnlAltOffers';

const { OPTION_TYPE_NAMES, RegionCodes } = AdminConstants;

class RgnlAltModel {
  id = '';
  rev = '';
  name = '';
  isSelected = false;
  isStandalone = false;
  isNationalRYO = false;
  fsv = 0;
  ryoEarnings = 0; // RA RYO Earnings
  nationalRyo = 0;
  additionalRyo = 0;
  totalRyoEarnings = 0; // Total RYO Earnings (RA RYO Earnings + National RYO Earnings + Additional RYO Earnings)
  lumpSum = 0;
  editPenRate = true;
  seriesProfileId = '';
  isSeriesConfirmed = false;
  isSelectedPrior = false;
  status = '';
  note = '';

  cash: PenRateModel[] = [];
  lease: PenRateModel[] = [];
  apr: PenRateModel[] = [];
  misc: PenRateModel[] = [];
  ryo: PenRateModel[] = [];

  totalPenRates = 0;
  totalSales = 0;

  priorRates = new Map<string, PenRateModel>();

  hasErrors = false;
  errors: string[] = [];
  hasVinErrors = false;
  vinErrors: string[] = [];
  regionCode = '';
  contestNumberError = '';
  hasLeaseNoteError = false;

  constructor(rgnlAlt?: RgnlAlt | null, offering?: Offering) {
    makeAutoObservable(this);

    if (rgnlAlt) {
      this.isSeriesConfirmed = rgnlAlt.isSeriesConfirmed;
      this.isSelectedPrior = rgnlAlt.isSelectedPrior;
      this.ryoEarnings = assignNumberValue(rgnlAlt.ryoEarnings);
      this.totalRyoEarnings = this.ryoEarnings;
      this.note = rgnlAlt.nationalNote || '';

      if (offering?.status === Status.MEETS_GUIDELINES) {
        this.status = Status.MEETS_GUIDELINES;
      }

      processRgnlAltOffers(rgnlAlt, this);
    }
  }

  setPriorRates = (offerId: string, priorRates: PenRateModel) => {
    this.priorRates.set(offerId, priorRates);
  };

  hasStandAloneOffers = () => {
    return [...this.cash, ...this.lease, ...this.apr, ...this.misc].some(offer => offer.isStandAlone);
  };

  hasNonRYOOffersAvailable = () => {
    return [this.cash.length, this.lease.length, this.apr.length, this.misc.length].some(val => val > 0);
  };

  isCustomerChoice = () => {
    return this.cash.length > 0 && this.apr.length > 0 && this.lease.length > 0;
  };

  isPartialCustomerChoice = () => {
    return this.cash.length > 0 || this.apr.length > 0 || this.lease.length > 0;
  };

  updateRev = (rev: string) => {
    this.rev = rev;
  };

  updatePenRateRevs = (revs: CombinedOffers[]) => {
    [...this.apr, ...this.cash, ...this.lease, ...this.misc].forEach(item => {
      const found = revs.find(i => i.regional.id === item.id);
      if (found) {
        item.rev = found.regional.rev;
      }
    });
  };

  updateFsv = (fsv: number) => {
    this.fsv = fsv;
  };

  updateRyoEarnings = (ryo: number) => {
    this.ryoEarnings = ryo;
  };

  toggleEditPenRate = () => {
    this.editPenRate = !this.editPenRate;
    this.runValidation();
  };

  toggleFourthOption = (id: string, value: boolean) => {
    const miscOffer = this.misc.find(offer => offer.id === id);

    if (!miscOffer) {
      return;
    }

    const currId = miscOffer.parentId || miscOffer.id;

    this.misc.forEach(offer => {
      if (offer.id === currId || offer.parentId === currId) {
        offer.isFourthOption = value;
      } else {
        if (value) {
          offer.isFourthOption = false;
        }
      }
    });

    this.runValidation();
  };

  calculateSales = () => {
    this.getCashAprLeaseMiscOffers().forEach(item => {
      const newSales = (Number(item.penetration ?? '0') / 100) * Number(this.fsv ?? 0);
      item.forecastedSales = Math.round(newSales).toString();
    });

    this.runValidation();
  };

  postCalculatePenAndSales = () => {
    // calculate based on edit pen rate
    this.getCashAprLeaseMiscOffers().forEach(item => {
      if (this.editPenRate) {
        const newSales = (Number(item.penetration ?? '0') / 100) * Number(this.fsv ?? 0);
        item.forecastedSales = Math.round(newSales).toString();
      } else {
        const newPen = !this.fsv ? 0 : Number(item.forecastedSales ?? '0') / Number(this.fsv);
        item.penetration = Math.round(newPen * 100).toString();
      }
    });

    this.runValidation();
  };

  getHasMidMonthEnhancement = (offeringStartDate: string, offeringEndDate: string) => {
    return [...this.cash, ...this.lease, ...this.apr, ...this.misc].some(offer => offeringStartDate !== offer.startDate || offer.endDate !== offeringEndDate);
  };

  runValidation = () => {
    // calculate sums
    const fourthOptions = this.misc.filter(item => item.isFourthOption);
    const fourthOptionName = fourthOptions.length > 0 ? fourthOptions[0].name : '';
    let totalPenRates = 0;
    let totalSales = 0;
    [...this.cash, ...this.apr, ...this.lease, ...fourthOptions].forEach(item => {
      const forecastedSales = Number(item.forecastedSales ?? 0);
      totalPenRates += Number(item.penetration);
      totalSales += forecastedSales;
    });
    let areTfsEnhRatesUsed = false;
    this.getCashAprLeaseMiscOffers().forEach(item => {
      const forecastedSales = Number(item.forecastedSales ?? 0);
      const earningsCost = item.estimatedCost < 0 ? Math.abs(item.estimatedCost) : 0;
      const cost = item.estimatedCost < 0 ? 0 : item.estimatedCost;
      item.offerEarnings = assignDollarCents((this.ryoEarnings + earningsCost) * forecastedSales);
      item.offerCost = item.isFlatCost ? item.offerCost : assignDollarCents(cost * forecastedSales);
      item.offerTfsCost = assignDollarCents(item.tfsCost * forecastedSales);
      item.offerEnhTfsCost = assignDollarCents(item.enhTfsCost * forecastedSales);

      if (item.aprDetails.length > 0) {
        item.subCashOfferCost = assignDollarCents(item.aprDetails.map(item => ((forecastedSales * item.distribution) / 100.0) * item.subCashEstCost).reduce((a, b) => a + b, 0));
        item.subCashOfferTfsCost = assignDollarCents(item.aprDetails.map(item => ((forecastedSales * item.distribution) / 100.0) * item.subCashTfsCost).reduce((a, b) => a + b, 0));
        item.subCashOfferEnhTfsCost = assignDollarCents(
          item.aprDetails.map(item => ((forecastedSales * item.distribution) / 100.0) * item.subCashEnhTfsCost).reduce((a, b) => a + b, 0),
        );
      } else {
        item.subCashOfferCost = assignDollarCents(item.subCashEstCost * forecastedSales);
        item.subCashOfferTfsCost = assignDollarCents(item.subCashTfsCost * forecastedSales);
        item.subCashOfferEnhTfsCost = assignDollarCents(item.subCashEnhTfsCost * forecastedSales);
      }

      if (this.isSelected && item.areTfsEnhRatesUsed) {
        areTfsEnhRatesUsed = true;
      }
    });

    this.totalPenRates = totalPenRates;
    this.totalSales = totalSales;

    // validate

    const errors: string[] = [];
    const isFinalPay = fourthOptionName === OPTION_TYPE_NAMES.FINAL_PAY;

    if (!areTfsEnhRatesUsed && this.editPenRate && this.cash.length > 0 && !fourthOptions.length && this.totalPenRates !== 100) {
      errors.push('Sum of Customer Cash, APR and Lease Penetration Rates must equal 100.');
    } else if (!areTfsEnhRatesUsed && !this.editPenRate && this.cash.length > 0 && !fourthOptions.length && this.totalSales !== Number(this.fsv)) {
      errors.push('Sum of Customer Cash, APR and Lease Forecasted Sales must equal the Series Forecasted Sales Volume.');
    }

    if (
      !areTfsEnhRatesUsed &&
      this.editPenRate &&
      this.cash.length > 0 &&
      ((!isFinalPay && fourthOptions.length > 0 && this.totalPenRates !== 100) || (isFinalPay && this.totalPenRates < 100))
    ) {
      errors.push(`Sum of Customer Cash, APR, Lease, and ${fourthOptionName} Penetration Rates must equal 100.`);
    } else if (
      !this.editPenRate &&
      this.cash.length > 0 &&
      ((!isFinalPay && fourthOptions.length > 0 && this.totalSales < Number(this.fsv)) || (isFinalPay && this.totalSales < Number(this.fsv)))
    ) {
      errors.push(`Sum of Customer Cash, APR, Lease, and ${fourthOptionName} Forecasted Sales must equal the Series Forecasted Sales Volume.`);
    }

    this.lease.forEach(item => {
      if (item.leaseNoteError) {
        this.hasLeaseNoteError = true;
      }
    });
    if (this.hasLeaseNoteError) {
      errors.push('Please fill out required note field in Program Details.');
    }

    if (!areTfsEnhRatesUsed && this.editPenRate && !this.cash.length && !fourthOptions.length && this.totalPenRates >= 100) {
      errors.push('Sum of APR and Lease Penetration Rates must be less than 100.');
    } else if (!this.editPenRate && !this.cash.length && !fourthOptions.length && this.totalSales > Number(this.fsv)) {
      errors.push(' Sum of APR and Lease Forecasted Sales must be less than the Series Forecasted Sales Volume.');
    }

    if (!areTfsEnhRatesUsed && this.editPenRate && !this.cash.length && fourthOptions.length > 0 && this.totalPenRates >= 100) {
      errors.push(`Sum of APR, Lease, and ${fourthOptionName} Penetration Rates must be less than 100.`);
    } else if (!areTfsEnhRatesUsed && !this.editPenRate && !this.cash.length && fourthOptions.length > 0 && this.totalSales >= Number(this.fsv)) {
      errors.push(`Sum of APR, Lease, and ${fourthOptionName} Forecasted Sales must be less than the Series Forecasted Sales Volume.`);
    }
    this.checkContestNumberError();
    this.errors = errors;
    this.hasErrors = errors.length > 0 || [...this.cash, ...this.apr, ...this.lease, ...this.misc].some(item => item.hasPenRateError);
  };

  checkVinError = () => {
    const vinErrors: string[] = [];

    if (this.lease.find(lease => lease.isBadVin)) {
      vinErrors.push(LEASE_BAD_VIN_ERROR);
    }

    if (this.lease.find(lease => lease.isVinAutoSelected)) {
      vinErrors.push(LEASE_VIN_AUTOSELECTED_ERROR);
    }

    if (this.lease.find(lease => lease.isVinStopSale)) {
      vinErrors.push(LEASE_VIN_STOP_SALE_ERROR);
    }

    this.vinErrors = vinErrors;
    this.hasVinErrors = vinErrors.length > 0;
  };

  checkContestNumberError = () => {
    this.contestNumberError = '';
    if (
      this.regionCode === RegionCodes.SET &&
      ((this.cash.length > 0 && this.cash.filter(offer => !offer.contestNumber).length > 0) ||
        (this.apr.length > 0 && this.apr.filter(offer => !offer.contestNumber).length > 0) ||
        (this.lease.length > 0 && this.lease.filter(offer => !offer.contestNumber).length > 0) ||
        (this.misc.length > 0 && this.misc.filter(offer => !offer.contestNumber).length > 0))
    ) {
      this.contestNumberError = 'All offers must have an assigned Contest Number';
    }
  };

  removeAprLeaseCashOffers = () => {
    this.apr = [];
    this.lease = [];
    this.cash = [];
  };

  getCashAprLeaseMiscOffers = () => {
    return [...this.cash, ...this.apr, ...this.lease, ...this.misc];
  };

  updateStatus = (status: string) => {
    this.status = status;
  };
}

export default RgnlAltModel;
