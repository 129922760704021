import { makeAutoObservable } from 'mobx';
import { AdminConstants, AdminModels } from 'oat-admin-common';
import { CreateLeaseModels, dateStringToDate, formatDollars } from 'oat-common-ui';
import createAccordionFromVehicles from '../components/Accordion/utils/createAccordionFromVehicles';
import { DEFAULT_TIERS, LEASE_TERMS } from '../constants/global';
import defaultOffering from '../defaultObjects/defaultOffering';
import defaultRgnlAlt from '../defaultObjects/defaultRgnlAlt';
import defaultSeriesProfile from '../defaultObjects/defaultSeriesProfile';
import { Offering, RgnlAlt, SeriesMapping, SeriesProfile, Vehicle, VehicleInput } from '../gql/generated';
import StdLeaseRcfs from '../models/StdLeaseRcfs';
import getLeaseOfferFromRgnlAlt from '../pages/createOffer/CreateLease/CreateAdLease/utils/getLeaseOfferFromRgnlAlt';
import { processVehiclesAccordion } from '../pages/createOffer/CreateLease/CreateAdLease/utils/utils';
import CreateAdLeaseModel from '../pages/createOffer/CreateLease/models/CreateAdLeaseModel';
import CreateNonAdLeaseModel from '../pages/createOffer/CreateLease/models/CreateNonAdLeaseModel';
import { getCompatibilityListByOfferType } from '../pages/createOffer/utils/getCompatibilityListByOfferType';
import { assignStringValue } from '../utils/assignValue';
import getDisabledVehicles from '../utils/getDisabledVehicles';
import searchRcfByTierTerm from '../utils/searchRcfByTierTerm';
import sortTiers from '../utils/sortTiers';

const DEFAULT_TERM = 36;

class CreateLeaseStore {
  offering: Offering = defaultOffering;
  seriesProfile: SeriesProfile = defaultSeriesProfile;
  rgnlAlt: RgnlAlt = defaultRgnlAlt;
  dealerGross = 500;
  tab = 1;
  termsList = LEASE_TERMS;
  tiersList = DEFAULT_TIERS;
  advertisedData = new CreateAdLeaseModel();
  nonAdvertisedData = new CreateNonAdLeaseModel();
  compatibileOffers: string[] = [];
  compatibility: AdminModels.Compatibility = {
    defaultTypes: [],
    incompatibleTypes: [],
    optionalTypes: [],
  };
  nationalRcfs: StdLeaseRcfs[] = [];
  nationalRcf = 0.00001;
  vehicles: VehicleInput[] = []; // Used for GST leases
  term = DEFAULT_TERM;
  blockUIText = '';
  isChangeVin = false;
  defaultMileage = AdminConstants.DEFAULT_MILEAGE_TOYOTA;
  isEligibleForEnhCostShare = false;
  enhCostShareOfferId = '';
  isEnhCostShareAccepted = false;
  nationalOfferId: string | undefined = '';
  isEnhanced = false;
  selectedTrim: CreateLeaseModels.TrimItem = {} as CreateLeaseModels.TrimItem;

  constructor() {
    makeAutoObservable(this);
  }

  setTab = (tab: number) => {
    this.tab = tab;
  };

  setNationalRcfs = (rcfs: StdLeaseRcfs[], ncsRcf: number, isSET = false) => {
    this.nationalRcfs = rcfs;
    this.nationalRcf = this.getNationalRcf(ncsRcf, isSET);
    this.nonAdvertisedData.fields.rcf = this.nonAdvertisedData.fields.rcf ? this.nonAdvertisedData.fields.rcf : this.nationalRcf.toString();
    this.nonAdvertisedData.fields.nationalRcf = this.nonAdvertisedData.fields.rcf ? this.nonAdvertisedData.fields.rcf : this.nationalRcf.toString();
  };

  getNationalRcf = (ncsRcf: number, isSET = false) => {
    // For SET: defaulting to 36 term because create lease follows same logic.
    // Secondly, we should have functional requirement with UX to handle it by term dynamically for all leases.
    const rcf = searchRcfByTierTerm(this.nationalRcfs, '1+', isSET ? 36 : undefined);
    const ncs = isSET ? 0 : ncsRcf;

    return Number((rcf - ncs).toFixed(5));
  };

  setTerm = (term: number) => {
    this.term = term;
  };

  setBlockUIText = (text: string) => {
    this.blockUIText = text;
  };

  setSelectedTrim = (trim: CreateLeaseModels.TrimItem) => {
    this.selectedTrim = trim;
  };

  setCompatibileOffers = (compatibileOffers: string[]) => {
    this.compatibileOffers = compatibileOffers;
  }

  setInitVehicles = (vehicles: Vehicle[], seriesMapping: SeriesMapping[], disabledVehicles: string[]) => {
    this.vehicles = vehicles;
    this.nonAdvertisedData.fields.vehiclesAccordion.items = createAccordionFromVehicles(vehicles, seriesMapping, true, disabledVehicles);
    this.advertisedData.selectTrimFields.vehiclesAccordion.items = createAccordionFromVehicles(vehicles, seriesMapping, true, disabledVehicles);
    this.advertisedData.enterVinFields.vehiclesAccordion.items = createAccordionFromVehicles(vehicles, seriesMapping, true, disabledVehicles);

    this.nonAdvertisedData.fields.vehiclesAccordion = processVehiclesAccordion(
      this.nonAdvertisedData.fields.vehiclesAccordion,
      this.selectedTrim.code,
      this.nonAdvertisedData.fields.isInclusions,
      Boolean(this.nationalOfferId),
      this.isEnhanced,
    );
  };

  initData = (
    offering: Offering,
    seriesProfile: SeriesProfile,
    isLexus: boolean,
    seriesMapping: SeriesMapping[],
    dealerGross?: number | null,
    offerId?: string,
    offerTerm?: string,
  ) => {
    this.defaultMileage = isLexus ? AdminConstants.DEFAULT_MILEAGE_LEXUS : AdminConstants.DEFAULT_MILEAGE_TOYOTA;
    if (offering) {
      this.offering = offering;
      this.nonAdvertisedData.fields.startDate = dateStringToDate(offering.startDate);
      this.nonAdvertisedData.fields.endDate = dateStringToDate(offering.endDate);
    }

    if (seriesProfile) {
      this.seriesProfile = seriesProfile;

      const rgnlAlt = seriesProfile.rgnlAlts?.find(item => item.isSelected);

      if (rgnlAlt) {
        this.rgnlAlt = rgnlAlt;
      }
    }

    if (dealerGross !== undefined && dealerGross !== null) {
      this.dealerGross = dealerGross;
    }

    const compatibilityList = getCompatibilityListByOfferType(AdminConstants.OPTION_TYPE_NAMES.LEASE);
    const disabledVehicles = getDisabledVehicles(seriesProfile.vehicles);

    this.tab = 1;
    this.compatibileOffers = compatibilityList.defaultTypes;
    this.compatibility = compatibilityList;

    this.nonAdvertisedData = new CreateNonAdLeaseModel(); // resets data
    this.nonAdvertisedData.isLexus = isLexus;
    this.nonAdvertisedData.fields.tfsShare = isLexus ? '0' : '15';
    this.nonAdvertisedData.fields.startDate = dateStringToDate(offering.startDate);
    this.nonAdvertisedData.fields.endDate = dateStringToDate(offering.endDate);
    if (seriesProfile && seriesProfile.series?.length) {
      this.nonAdvertisedData.fields.series = seriesProfile.series[0].series;
      this.nonAdvertisedData.fields.modelYear = seriesProfile.series[0].seriesYear;
    }
    this.nonAdvertisedData.fields.selectedMileage = {
      value: assignStringValue(this.defaultMileage),
      label: formatDollars(this.defaultMileage),
    };

    // Advertised Data Mapping
    this.advertisedData.initSelectTrim();
    this.advertisedData.initEnterVin();

    this.term = DEFAULT_TERM;
    this.termsList = LEASE_TERMS;
    this.tiersList = DEFAULT_TIERS;

    // Change Vin mode
    this.isChangeVin = Boolean(offerId);

    try {
      const { leaseOffer, leaseDetails, nationalLeaseOffer } = getLeaseOfferFromRgnlAlt(this.rgnlAlt, offerId ?? '', offerTerm ?? '');
      if (this.isChangeVin && leaseOffer && leaseDetails) {
        const tiersList = sortTiers(
          leaseDetails.rates.map(r => r.tier),
          DEFAULT_TIERS,
        );

        // set tiers
        this.tiersList = tiersList;
        this.nonAdvertisedData.fields.selectedTiers = tiersList;

        // set terms
        this.term = Number(offerTerm);
        this.termsList = [offerTerm ?? ''];
        this.nonAdvertisedData.fields.selectedTermsList = [offerTerm ?? ''];

        // set data
        this.nonAdvertisedData.fields.startDate = dateStringToDate(leaseDetails.startDate);
        this.nonAdvertisedData.fields.endDate = dateStringToDate(leaseDetails.endDate);
        this.nonAdvertisedData.fields.selectedMileage = { value: assignStringValue(leaseDetails.mileage), label: formatDollars(leaseDetails.mileage) };
        this.nonAdvertisedData.fields.rcf = assignStringValue(leaseDetails.rates[0].subventedRcf);
        this.nonAdvertisedData.fields.pnv = assignStringValue(leaseDetails.estimatedCost, '0');
        this.nonAdvertisedData.fields.tfsShare = assignStringValue(leaseDetails.tfsShare);
        this.nonAdvertisedData.fields.targetPayment = assignStringValue(leaseDetails.targetPayment);
        this.nonAdvertisedData.fields.dueAtSigning = assignStringValue(leaseDetails.dueAtSigning);
        this.nonAdvertisedData.fields.netCapCost = assignStringValue(leaseDetails.netCapCost);
        this.nonAdvertisedData.fields.totalMsrp = assignStringValue(leaseDetails.totalMsrp);
        this.nonAdvertisedData.fields.subventionCash = assignStringValue(leaseDetails.subventionCash);
        this.nonAdvertisedData.fields.bonusCash = assignStringValue(leaseDetails.bonusCash);
        this.nonAdvertisedData.fields.residualValueAmount = assignStringValue(leaseDetails.residualAmount);
        this.nonAdvertisedData.fields.downPayment = assignStringValue(leaseDetails.downPayment);
        this.nonAdvertisedData.fields.grossCapCost = assignStringValue(leaseDetails.grossCapCost);
        this.nonAdvertisedData.fields.isBlended = leaseDetails.rates.length > 1;
        this.nonAdvertisedData.fields.vehicleDescription = assignStringValue(leaseDetails.vehicleDescription);
        this.nonAdvertisedData.fields.modelCode = assignStringValue(leaseDetails.modelCode);
        this.nonAdvertisedData.fields.isSpecialEdition = leaseOffer.isSpecialEdition;
        this.nonAdvertisedData.fields.note = leaseDetails.note;
        this.nonAdvertisedData.fields.tdaNote = leaseDetails.tdaNote;
        this.isEnhanced = leaseDetails.isEnhanced;
        this.nationalOfferId = nationalLeaseOffer?.id;

        // set data for enhanced cost share
        this.isEligibleForEnhCostShare = Boolean(leaseOffer.isEligibleForEnhCostShare);
        this.enhCostShareOfferId = assignStringValue(leaseOffer.enhCostShareOfferId);
        this.isEnhCostShareAccepted = Boolean(leaseOffer.isEnhCostShareAccepted);

        // set vehicles from offer
        this.setInitVehicles(leaseOffer.vehicles, seriesMapping, nationalLeaseOffer ? getDisabledVehicles(nationalLeaseOffer.vehicles) : disabledVehicles);
      } else {
        // set vehicles from series profile
        this.setInitVehicles(seriesProfile.vehicles, seriesMapping, nationalLeaseOffer ? getDisabledVehicles(nationalLeaseOffer.vehicles) : disabledVehicles);
      }
    } catch (e) {
      // set vehicles from series profile
      this.setInitVehicles(seriesProfile.vehicles, seriesMapping, disabledVehicles);
    }

    return null;
  };
}

export default CreateLeaseStore;
