import { useLocation, useParams } from 'react-router-dom';

type PathParamType = {
  region: string;
  period: string;
  profile: string;
  program: string;
  regalt: string;
  offerId: string;
  offerTerm: string;
};

const useUrlParams = () => {
  const { region, period, profile, program, regalt, offerId, offerTerm } = useParams<PathParamType>();
  const query = new URLSearchParams(useLocation().search);
  const example = query.get('example') ?? '';
  const lang = query.get('lang') ?? '';
  const site = query.get('site') ?? '';
  const tiers = query.get('tiers') ?? '';
  const scrollTo = query.get('scrollTo') ?? '';
  const scrollToTerm = query.get('scrollToTerm') ?? '';
  const scrollToTier = query.get('scrollToTier') ?? '';
  const parentOfferingId = query.get('parentOfferingId') ?? '';

  return {
    region,
    site,
    tiers,
    period,
    profile,
    program,
    regalt,
    offerId,
    offerTerm,
    example,
    lang,
    scrollTo,
    scrollToTerm,
    scrollToTier,
    parentOfferingId,
  };
};

export default useUrlParams;
