import { useEffect, useState } from 'react';

export const useShowScrollToTop = () => {
  const [showScrollToTop, setShowScrollTopCta] = useState(false);

  useEffect(() => {
    const showScrollCta = () => {
      const docElem = document.documentElement;
      const secondPartOfPage = (docElem.scrollHeight - docElem.clientHeight) / 2;

      if (docElem.scrollTop > secondPartOfPage) {
        setShowScrollTopCta(true);
      } else {
        setShowScrollTopCta(false);
      }
    };

    window.addEventListener('scroll', showScrollCta, true);

    return () => {
      window.removeEventListener('scroll', showScrollCta, true);
    };
  }, [showScrollToTop]);

  return { showScrollToTop };
};
