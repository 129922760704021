import { observer } from 'mobx-react-lite';
import useStores from '../../../../../../stores/useStores';
import useUserInfo from '../../../../../../utils/useUserInfo';
import LeaseCardModel from '../../../../models/LeaseCardModel';
import LeaseFormModel from '../../../../models/LeaseFormModel';
import SpecialEdition from '../../../SpecialEdition';
import styles from './styles.module.scss';

interface Props {
  leaseCard: LeaseCardModel;
  leaseForm: LeaseFormModel;
  onChange: (skipDelay: boolean) => void;
}

const LeaseSpecialEdition = ({ leaseCard, leaseForm, onChange }: Props) => {
  const {
    compatibleAccessoriesStore: { compatibleAccessories },
  } = useStores();
  const { isLexusUser } = useUserInfo();

  return (
    <SpecialEdition
      id={leaseCard.id}
      className={styles.specialEditionWrapper}
      accessoryCodes={leaseCard.accessoryCodes}
      compatibileAccessories={compatibleAccessories}
      label="Special edition"
      inputLabel="Package"
      isChecked={leaseCard.isSpecialEdition}
      isLexus={isLexusUser()}
      onChange={value => {
        leaseCard.updateSpecialEditionPackage(value);
        onChange(false);
      }}
      onSubmit={value => {
        leaseCard.updateAccessoryCodes(value.join());
        onChange(true);
      }}
      onSelect={() => {
        leaseCard.updateIsSpecialEdition(!leaseCard.isSpecialEdition);
        if (!leaseCard.isSpecialEdition) {
          leaseCard.updateSpecialEditionPackage('');
          leaseCard.updateAccessoryCodes('');
        }
        onChange(false);
      }}
      value={leaseCard.specialEditionPackage}
      vehicles={leaseForm.inputs.vehicles}
      forCreate={leaseCard.isMultiSeries} // hides package and compat accessories fields if multi series
      disabled={leaseCard.isDisabled}
    />
  );
};

export default observer(LeaseSpecialEdition);
