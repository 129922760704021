import { observer } from 'mobx-react-lite';
import { Button, OATIcon } from 'oat-common-ui';
import { FC, useState } from 'react';
import AccordionItemModel from '../../../../components/Accordion/models/AccordionItemModel';
import AccordionModel from '../../../../components/Accordion/models/AccordionModel';
import createAccordionFromVehicles from '../../../../components/Accordion/utils/createAccordionFromVehicles';
import mapVehicleDataFromAccordion from '../../../../components/Accordion/utils/mapVehicleDataFromAccordion';
import { VehicleInput } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import { processVehicleData } from '../../../Summary/NationalApproval/SummaryTable/components/Expanded/VehiclesList/utils';
import VehicleCompabitibleInfo from '../VehicleCompatibleInfo';
import vehCompStyles from '../VehicleCompatibleInfo/styles.module.scss';
import VehicleCompatibleTooltip from '../VehicleCompatibleTooltip';
import ListTooltip from '../VehicleCompatibleTooltip/ListTooltip';
import ExclusionsModal from './ExclusionsModal';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  disabled?: boolean;
  disabledVehicles?: string[];
  excludedModels: VehicleInput[];
  id?: string;
  includedModels: VehicleInput[];
  isEditEnabled?: boolean;
  modelCode?: string;
  onSubmit: (excludedList: VehicleInput[], includedLIst: VehicleInput[]) => void;
};

const OfferExclusions: FC<Props> = ({ id, includedModels, excludedModels, onSubmit, disabledVehicles, isEditEnabled, disabled, modelCode }) => {
  const {
    seriesMappingStore: { seriesMapping },
  } = useStores();
  const [isExclusionsActive, setIsExclusionsActive] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const accordion = new AccordionModel(createAccordionFromVehicles([...includedModels, ...excludedModels], seriesMapping, isExclusionsActive, disabledVehicles, modelCode));

  const toggleExlusions = () => {
    setIsExclusionsActive(!isExclusionsActive);
  };

  const handleOnSubmit = (exclusions: boolean, items: AccordionItemModel[]) => {
    onSubmit(...mapVehicleDataFromAccordion(items, [...includedModels, ...excludedModels], exclusions));
    setOpenModal(!openModal);
  };

  const vehicleList = isExclusionsActive ? excludedModels : includedModels;

  const getLabel = () => {
    const label = isExclusionsActive ? 'Excluded' : 'Included';
    const count = isExclusionsActive ? excludedModels.length : includedModels.length;

    if (count === 0) {
      return 'None';
    } else if (count === 1) {
      return `1 Model ${label}`;
    } else {
      return `${count} Models ${label}`;
    }
  };

  const getTooltipMessage = () => {
    return <ListTooltip>{vehicleList.length > 0 ? vehicleList.map(vehicle => <li key={vehicle.modelCode}>{processVehicleData(vehicle)}</li>) : <li>None</li>}</ListTooltip>;
  };

  const handleOpenModal = () => {
    return () => {
      setOpenModal(!openModal);
    };
  };

  return (
    <>
      <VehicleCompabitibleInfo
        title={
          <>
            <OATIcon className={styles.icon} icon="switch" colorTheme="blue" size={14} onClick={toggleExlusions} />
            <span>{isExclusionsActive ? 'Exclusions' : 'Inclusions'}</span>
          </>
        }
        content={
          <VehicleCompatibleTooltip id={`${id}_offer_exclusions`} message={getTooltipMessage()} className={styles.vehicleCompatibleTooltip}>
            {isEditEnabled && !disabled ? (
              <Button variant="text" onClick={handleOpenModal()} className={vehCompStyles.openModalBtn} id="open-modal-btn">
                {getLabel()}
              </Button>
            ) : (
              <p>{getLabel()}</p>
            )}
          </VehicleCompatibleTooltip>
        }
        isStandalone={isEditEnabled}
      />
      {openModal && (
        <ExclusionsModal
          accordion={accordion}
          id={'offer'}
          isExclusionsActive={isExclusionsActive}
          labelExcluded="Exclusions"
          labelIncluded="Inclusions"
          onClose={() => setOpenModal(!openModal)}
          onSubmit={handleOnSubmit}
        />
      )}
    </>
  );
};

export default observer(OfferExclusions);
