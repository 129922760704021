import { formatAccounting, formatDollarsCents } from 'oat-common-ui';
import TooltipList, { TooltipListItem } from '../../../../components/TooltipList/TooltipList';
import { OfferingRaCosts } from '../../../../gql/generated';
import { assignDollarCents } from '../../../../utils/assignValue';
import useGetTfsLabel from '../../../../utils/useGetTfsLabel';
import ProfileModel from '../../models/ProfileModel';

interface Props {
  profiles: ProfileModel[];
  raCosts: Record<string, OfferingRaCosts>;
  periodCost: number;
  enhanced?: boolean;
}

const showAddSymbol = (index: number) => {
  return index > 0 ? '+' : undefined;
};

export const EarningsTooltip = ({ profiles, raCosts, periodCost }: Props) => {
  return (
    <>
      <TooltipList>
        {profiles.map((profile, i) => {
          const ra = profile.getSelectedRgnAlt();
          const raCost = raCosts[ra?.id ?? ''];
          const earnings = profile.isEarningsOnly ? Number(ra?.ryoEarnings ?? 0) * Number(ra?.fsv ?? 0) : raCost?.raEarnings ?? 0;

          return (
            <TooltipListItem key={profile.id} symbol={showAddSymbol(i)}>
              <span>{profile.name}</span>
              <span>${formatDollarsCents(earnings)}</span>
            </TooltipListItem>
          );
        })}
      </TooltipList>
      <TooltipList divider>
        <TooltipListItem>
          <span>Est. Period Earnings</span>
          <span>${formatDollarsCents(periodCost)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
};

export const CostTooltip = ({ profiles, raCosts, periodCost }: Props) => {
  return (
    <>
      <TooltipList>
        {profiles.map((profile, i) => {
          const raCost = raCosts[profile.getSelectedRgnAlt()?.id ?? ''];
          const cost = raCost?.raCost ?? 0;

          return (
            <TooltipListItem key={profile.id} symbol={showAddSymbol(i)}>
              <span>{profile.name}</span>
              <span>${formatDollarsCents(cost)}</span>
            </TooltipListItem>
          );
        })}
      </TooltipList>
      <TooltipList divider>
        <TooltipListItem>
          <span>Est. Period Cost</span>
          <span>${formatDollarsCents(periodCost)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
};

export const BalanceTooltip = ({ profiles, raCosts, periodCost }: Props) => {
  return (
    <>
      <TooltipList>
        {profiles.map((profile, i) => {
          const raCost = raCosts[profile.getSelectedRgnAlt()?.id ?? ''];
          const earnings = raCost?.raEarnings ?? 0;
          const cost = raCost?.raCost ?? 0;
          const balance = assignDollarCents(earnings - cost);

          return (
            <TooltipListItem key={profile.id} symbol={showAddSymbol(i)}>
              <span>{profile.name}</span>
              <span>{formatAccounting(balance)}</span>
            </TooltipListItem>
          );
        })}
      </TooltipList>
      <TooltipList divider>
        <TooltipListItem>
          <span>Est. Period Balance</span>
          <span>{formatAccounting(periodCost)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
};

export const TfsCostTooltip = ({ profiles, raCosts, periodCost, enhanced }: Props) => {
  const tfsLabel = useGetTfsLabel();

  return (
    <>
      <TooltipList>
        {profiles.map((profile, i) => {
          const raCost = raCosts[profile.getSelectedRgnAlt()?.id ?? ''];
          const tfsCost = (enhanced ? raCost?.raEnhTfsCost : raCost?.raTfsCost) ?? 0;

          return (
            <TooltipListItem key={profile.id} symbol={showAddSymbol(i)}>
              <span>{profile.name}</span>
              <span>{formatAccounting(tfsCost)}</span>
            </TooltipListItem>
          );
        })}
      </TooltipList>
      <TooltipList divider>
        <TooltipListItem>
          <span>
            Est. Period {enhanced && 'Enhanced '}
            {tfsLabel} Cost
          </span>
          <span>{formatAccounting(periodCost)}</span>
        </TooltipListItem>
      </TooltipList>
    </>
  );
};
