import { formatDollars, formatDollarsCents, formatRcf, formatStdRate } from 'oat-common-ui';

type ValueType = string | number | undefined | null;

/**
 * Ideally, this should conform to any decimal scale, but we are restricting to the app's only number types
 * @param value
 * @param rcf
 * @param dollar
 * @param whole
 * @returns
 */
export const formatValue = (value: ValueType, rcf = false, dollar = false, whole = false, stdRate = false) => {
  if (!value !== undefined && value !== null) {
    if (rcf) {
      return formatRcf(value);
    }
    if (dollar) {
      return formatDollarsCents(value, true);
    }
    if (whole) {
      return formatDollars(value);
    }
    if (stdRate) {
      return formatStdRate(value);
    }
  }

  return value?.toString() || '';
};
