import { Tooltip } from 'oat-common-ui';
import { Vehicle } from '../../../../../../../gql/generated';
import styles from './styles.module.scss';

export const processVehicleData = (vehicle: Vehicle) =>
  !vehicle.vehicleDescription.includes(vehicle.modelCode) ? `(${vehicle.modelCode}) ${vehicle.vehicleDescription}` : `${vehicle.vehicleDescription}`;

export const handleVehiclesRender = (vehiclesToRender: Vehicle[], modelId: string) => {
  const vehiclesLength = vehiclesToRender.length;
  const maxLength = 8;
  const vehiclesListTooltipMessage = vehiclesToRender.map(v => `(${v.modelCode}) ${v.vehicleDescription}`).join(', ');

  if (vehiclesLength >= maxLength) {
    return (
      <div className={styles.vehiclesList}>
        <Tooltip id={`${modelId}-vehicles-list-tooltip`} place="bottom" message={<div className={styles.vehiclesTooltipMsg}>{vehiclesListTooltipMessage}</div>}>
          {vehiclesToRender.slice(0, maxLength).map((vehicle, i) => {
            if (i === maxLength - 1) {
              return <span key={vehicle.modelCode}>...</span>;
            }

            return (
              <span key={vehicle.modelCode} className={styles.vehiclesListItem}>
                {processVehicleData(vehicle)}
              </span>
            );
          })}
        </Tooltip>
      </div>
    );
  }

  return (
    <div className={styles.vehiclesList}>
      {vehiclesToRender.map((vehicle, i) => {
        const isLastItem = i === vehiclesLength - 1;
        const vehicleItemClass = isLastItem ? styles.lastItem : styles.vehiclesListItem;

        return (
          <span key={vehicle.modelCode} className={vehicleItemClass}>
            {processVehicleData(vehicle)}
          </span>
        );
      })}
    </div>
  );
};
