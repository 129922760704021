export const FUNDING_SOURCE_LFS = 'LFS';
export const FUNDING_SOURCE_SETF = 'SETF';

export const getFoundingSourceLabel = (isLexus: boolean, isSet: boolean): string => {
  if (isLexus) {
    return 'LFS';
  } else {
    return isSet ? 'SETF' : 'TFS';
  }
};
