import { AdminConstants } from 'oat-admin-common';
import { ReactNode } from 'react';
import IpBreadCrumbs from '../../../components/IpComponents/IpBreadCrumbs';
import { FEATURE_OR_2477 } from '../../../constants/global';
import { EnhancedCostShare, useFetchEnhancedCostShareByNspIdQuery } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import LoadingPage from '../../LoadingPage';

interface Props {
  children: ReactNode;
  optionTypeName: string;
}

const { OPTION_TYPE_NAMES } = AdminConstants;

const GetEnhCostShare = ({ children, optionTypeName }: Props) => {
  const {
    programDetailsStore: { setEnhancedCostShares, offering, seriesProfile },
    userInfoStore: { isNational },
    createOfferStore: { miscCard },
    createLeaseStore,
  } = useStores();
  const { region } = useUrlParams();

  const idByOptionTypeName = () => {
    if (optionTypeName === OPTION_TYPE_NAMES.APR) {
      return seriesProfile.nationalId;
    }

    if (optionTypeName === OPTION_TYPE_NAMES.LEASE) {
      return createLeaseStore.seriesProfile.nationalId;
    }

    return miscCard.seriesProfileNationalId;
  };

  const nationalSeriesProfileId = idByOptionTypeName() ?? '';

  const { data, loading, error } = useFetchEnhancedCostShareByNspIdQuery({
    variables: { nationalSeriesProfileId, regionCode: region },
    skip: !FEATURE_OR_2477 || !nationalSeriesProfileId,
  });

  if (loading || error) {
    return <LoadingPage breadCrumbs={IpBreadCrumbs(isNational(), offering, region)} error={!!error} />;
  }

  if (data) {
    setEnhancedCostShares(data.enhancedCostShareByNSPId as EnhancedCostShare[]);
  }

  return <>{children}</>;
};

export default GetEnhCostShare;
