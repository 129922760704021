import { observer } from 'mobx-react-lite';
import { DefaultModal, OATIcon, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { IpNationalReports, IpRegionalReports, NationalPlanningReports } from '../../../../constants/global';
import useStores from '../../../../stores/useStores';
import useReportGeneration from '../../../../hooks/useReportGeneration';
import useReportMutations from '../../../../hooks/useReportMutations';
import styles from './styles.module.scss';

type ReportListType = typeof IpNationalReports | typeof IpRegionalReports | typeof NationalPlanningReports;

interface Props {
  reports: ReportListType;
}

const ReportsList = ({ reports }: Props) => {
  const {
    reportsStore: { selectedIp, doesReportExist, setGenerating },
    userInfoStore: { isNational, isLexus },
  } = useStores();

  const [existingReportModal, setExistingReportModal] = useState('');

  const { generateReports } = useReportGeneration();

  const { error } = useToast();
  const allReports = useReportMutations(selectedIp, isNational());

  const handleClick = (report: string) => {
    if (doesReportExist(report)) {
      setExistingReportModal(report);
    } else {
      generateReport(report);
    }
  };

  const generateReport = async (report: string) => {
    setGenerating(true);
    try {
      const res = allReports[report];
      await res();
      setGenerating(false);
      generateReports(selectedIp, false);
    } catch (e) {
      setGenerating(false);
      error((e as Error).message);
    }
  };

  return (
    <>
      <ul className={styles.reportsList}>
        {Object.values<string>(reports).map(report => (
          <li className={styles.singleReport} key={report}>
            <button id={`report-${report}`} onClick={() => handleClick(report)}>
              <OATIcon icon="notes" className={styles.reportIcon} />
              <span>{report.replace('TFS', isLexus() ? 'LFS' : 'TFS')}</span>
            </button>
          </li>
        ))}
      </ul>
      <DefaultModal
        title="Regenerate Report"
        message={`Are you sure you want to remove and regenerate the ${existingReportModal} report?`}
        open={Boolean(existingReportModal)}
        onClose={() => setExistingReportModal('')}
        onSubmit={async () => {
          setExistingReportModal('');
          await generateReport(existingReportModal);
        }}
      />
    </>
  );
};

export default observer(ReportsList);
