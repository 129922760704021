import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import NumberInput from '../../../../../../../components/NumberInput';
import PenRateModel from '../../../../../models/PenRateModel';
import styles from '../styles.module.scss';
import { handleTableValueRender } from '../utils';
import { returnLowestTier } from './utils';

interface Props {
  leaseModel: PenRateModel;
  inputIsDisabled: boolean;
  onSave?: () => void;
}

const ExpLeaseTableValues = ({ leaseModel, inputIsDisabled, onSave }: Props) => {
  const { rcf, nationalRcf, isEnhanced, isSubCashEnhanced, subCash, nationalSubCash, tiers, term, penetration, estimatedCost, updatePenRate } = leaseModel;

  const handleOnChange = (val: string) => {
    updatePenRate(val);

    if (onSave) {
      onSave();
    }
  };

  return (
    <tr className={clsx(styles.offerRow, styles.detailsRow)}>
      <td className={clsx(styles.expTableTd, styles.expTableTdValue)}>{term} mo</td>
      <td className={styles.expTableTd}>{returnLowestTier(tiers)}</td>
      <td className={clsx(styles.expTableTd, styles.rcfTableTd)}>{handleTableValueRender(isEnhanced, `${leaseModel.id}-enhanced-rcf`, rcf, nationalRcf)}</td>
      <td className={styles.expTableTd}>{handleTableValueRender(isSubCashEnhanced, `${leaseModel.id}-enhanced-subCash`, subCash.toString(), nationalSubCash.toString(), true)}</td>
      <td className={styles.expTableTd}>
        <NumberInput value={penetration} percentageSign onChange={e => handleOnChange(e.target.value)} className={styles.penRateInput} disabled={inputIsDisabled} units />
      </td>
      <td className={styles.expTableTd}>${estimatedCost}</td>
    </tr>
  );
};

export default observer(ExpLeaseTableValues);
