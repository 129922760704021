import GetRcfsRatesResidualsData from '../../components/GetRcfsRatesResidual';
import IpBreadCrumbs from '../../components/IpComponents/IpBreadCrumbs';
import {
  Offering,
  OfferingCosts,
  ResidualValue,
  SeriesMapping,
  SeriesProfile,
  SetResidualValue,
  SetStandardRate,
  SetStandardRcf,
  StdAprRate,
  useGetProgramDetailsDataQuery,
  useGetProgramDetailsSetDataQuery,
  useGetSeriesMappingQuery,
} from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import { SETContestNumbersByType } from '../../models/SETContestNumber';
import StdLeaseRcfs from '../../models/StdLeaseRcfs';
import useStores from '../../stores/useStores';
import { assignNumberValue } from '../../utils/assignValue';
import getDateParts from '../../utils/getDateParts';
import getLowestTier from '../../utils/getLowestTier';
import useUserInfo from '../../utils/useUserInfo';
import LoadingPage from '../LoadingPage';
import EFCSeriesData from './components/EFCSeriesData';
import GetEnhCostShare from './GetEnhCostShare';
import LeaseCardModel from './models/LeaseCardModel';
import ProgramDetailsComponent from './ProgramDetailsComponent';

/**
 * Util fn to map lease rcfs
 */
const setLeaseRcfsResiduals = (leases: LeaseCardModel[], rcfs: StdLeaseRcfs[], residualValues: ResidualValue[], isSET = false) => {
  leases.forEach(lease => {
    lease.leaseTerms.forEach(leaseTerm => {
      leaseTerm.leaseForms.forEach(item => {
        // process rcf
        const found = rcfs.find(
          r => r.tier === getLowestTier(rcfs.map(r => r.tier)) && (!isSET || (leaseTerm.term >= assignNumberValue(r.minTerm) && leaseTerm.term <= assignNumberValue(r.maxTerm))),
        );
        item.inputs.standardRcf = found?.rcf || item.inputs.standardRcf;

        // process rv
        const rvFound = residualValues.find(r => Number(r.modelCode) === item.inputs.modelCode && r.seriesYear === item.inputs.modelYear && r.term === item.inputs.term);
        if (rvFound) {
          item.updateDefaultResiduals(rvFound.rv);
        }
      });
    });
  });
};

const setDefaultDealerGross = (leases: LeaseCardModel[], defaultDealerGross: number) => {
  leases.forEach(lease => {
    lease.leaseTerms.forEach(leaseTerm => {
      leaseTerm.leaseForms.forEach(item => {
        item.inputs.dealerGrossDefault = defaultDealerGross;
      });
    });
  });
};

/**
 * Main function
 * @returns
 */
const ProgramDetails = () => {
  const {
    programDetailsStore,
    userInfoStore: { userInfo, isNational },
    rcfsResidualsStore,
    offeringsStore,
    offeringCostsStore,
    seriesMappingStore,
  } = useStores();
  const { isLexusUser, isSETUser } = useUserInfo();

  const { region, period, profile, scrollTo, scrollToTerm, scrollToTier } = useUrlParams();

  const {
    data: setData,
    loading: setLoading,
    error: setError,
  } = useGetProgramDetailsSetDataQuery({ variables: { offeringId: period, seriesProfileId: profile, regionCode: region }, skip: !isSETUser() });

  const { data, loading, error } = useGetProgramDetailsDataQuery({ variables: { offeringId: period, seriesProfileId: profile, regionCode: region }, skip: isSETUser() });

  const {
    data: seriesMappingData,
    loading: seriesMappingLoading,
    error: seriesMappingError,
  } = useGetSeriesMappingQuery({ variables: { brand: userInfo.brand }, skip: seriesMappingStore.seriesMappingLoaded });

  const isLoading = setLoading || loading || seriesMappingLoading;
  const hasError = setError || error || seriesMappingError;
  const response = setData || data;

  if (isLoading || hasError) {
    return <LoadingPage breadCrumbs={IpBreadCrumbs(isNational(), programDetailsStore.offering, region)} error={!!error} />;
  }

  if (seriesMappingData) {
    seriesMappingStore.setSeriesMapping(seriesMappingData?.seriesMapping as SeriesMapping[]);
  }

  if (response) {
    if (setData?.contestNumbers) {
      programDetailsStore.setSETContestNumbersByType(setData.contestNumbers.setContestNumbers as SETContestNumbersByType[]);
    }
    programDetailsStore.setOffering(response.offering as Offering);
    offeringsStore.setOffering(response.offering as Offering);
    offeringCostsStore.setData(response.offeringCosts as OfferingCosts);
    programDetailsStore.setSeriesProfile(
      response.seriesProfile as SeriesProfile,
      region,
      isLexusUser(),
      seriesMappingStore.seriesMapping,
      scrollTo,
      scrollToTerm,
      scrollToTier,
      isNational(),
    );
    setDefaultDealerGross(programDetailsStore.offerCards.lease, response.dealerGrossByRegionCode?.dealerGross || 0);
  }

  // callback for after getting rcfs and residuals
  const handleRcfsResidualsLoad = (stdLeaseRcfs: StdLeaseRcfs[], stdAprRates: StdAprRate[], residualValues: ResidualValue[]) => {
    rcfsResidualsStore.setStandardRcfs(stdLeaseRcfs);
    rcfsResidualsStore.setStandardRates(stdAprRates);
    rcfsResidualsStore.setResiduals(residualValues);
    setLeaseRcfsResiduals(programDetailsStore.offerCards.lease, stdLeaseRcfs, residualValues);
  };

  const handleSETRcfsResidualsLoad = (stdLeaseRcfs: SetStandardRcf[], stdAprRates: SetStandardRate[], residualValues: SetResidualValue[]) => {
    rcfsResidualsStore.setStandardRcfs(stdLeaseRcfs);
    rcfsResidualsStore.setStandardRates(stdAprRates);
    rcfsResidualsStore.setResiduals(residualValues);
    setLeaseRcfsResiduals(programDetailsStore.offerCards.lease, rcfsResidualsStore.stdLeaseRcfs, rcfsResidualsStore.residuals, true);
  };

  const { year, month } = getDateParts(programDetailsStore.offering.startDate);

  return (
    <GetRcfsRatesResidualsData
      brand={userInfo.brand}
      offeringYear={year}
      offeringMonth={month}
      offeringId={offeringsStore.offering.id}
      regionCode={Number(offeringsStore.offering.regionCode)}
      onLoad={handleRcfsResidualsLoad}
      onSETLoad={handleSETRcfsResidualsLoad}
    >
      <EFCSeriesData>
        <GetEnhCostShare optionTypeName="APR">
          <ProgramDetailsComponent />
        </GetEnhCostShare>
      </EFCSeriesData>
    </GetRcfsRatesResidualsData>
  );
};

export default ProgramDetails;
