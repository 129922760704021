import { AdminConstants, getDefaultTfsShare } from 'oat-admin-common';
import { EnhancedCostShare } from '../../../../../gql/generated';

export const getDefaultLeaseTfsShare = (
  useOldCostShareForLexus: boolean,
  regionCode: string,
  term?: number,
  isEnhCostShareAccepted?: boolean,
  enhCostShare?: EnhancedCostShare,
): number => {
  if (isEnhCostShareAccepted && enhCostShare?.tfsCostShare) {
    return enhCostShare.tfsCostShare;
  } else {
    return getDefaultTfsShare(AdminConstants.OPTION_TYPE_NAMES.LEASE, regionCode, term, useOldCostShareForLexus);
  }
};
