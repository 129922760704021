import { Offering, SeriesMapping, SeriesProfile, useGetProgramDetailsDataQuery, useGetSeriesMappingQuery } from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';

const useCreateOffer = () => {
  const { period, profile, region } = useUrlParams();
  const {
    createOfferStore,
    offeringsStore,
    userInfoStore: {
      userInfo: { brand },
    },
    seriesMappingStore: { seriesMappingLoaded, setSeriesMapping },
  } = useStores();

  const { data, loading, error } = useGetProgramDetailsDataQuery({ variables: { offeringId: period, seriesProfileId: profile, regionCode: region } });

  const { data: seriesMappingData, loading: seriesMappingLoading, error: seriesMappingError } = useGetSeriesMappingQuery({ variables: { brand }, skip: seriesMappingLoaded });

  if (data) {
    createOfferStore.setOffering(data.offering as Offering);
    offeringsStore.setOffering(data.offering as Offering);
    createOfferStore.setSeriesProfileData(data.seriesProfile as SeriesProfile);
  }

  if (seriesMappingData) {
    setSeriesMapping(seriesMappingData.seriesMapping as SeriesMapping[]);
  }

  const isLoading = loading || seriesMappingLoading;
  const hasError = error || seriesMappingError;

  return { loading: isLoading, error: hasError, data };
};

export default useCreateOffer;
