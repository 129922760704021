import { observer } from 'mobx-react-lite';
import { Button, ButtonGroup, OATIcon, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { Link } from 'react-router-dom';
import { Status } from '../../../../../../../constants/global';
import { useUpdateSeriesProfileStatusMutation } from '../../../../../../../gql/generated';
import useUrlParams from '../../../../../../../hooks/useUrlParams';
import ProfileModel from '../../../../../models/ProfileModel';
import RgnlAltModel from '../../../../../models/RgnlAltModel';
import { getScrollTopFromClick } from '../../../../../utils';
import styles from './styles.module.scss';

interface Props {
  profile: ProfileModel;
  selectedRa: RgnlAltModel | undefined;
  isExpanded?: boolean;
}

const FooterButtons = ({ profile, selectedRa, isExpanded }: Props) => {
  const [updateSeriesProfileStatus] = useUpdateSeriesProfileStatusMutation();
  const { region, period } = useUrlParams();
  const { error } = useToast();

  const updateSeriesProfileStatusHandler = async (status: string) => {
    try {
      const res = await trackPromise(
        updateSeriesProfileStatus({
          variables: {
            input: {
              rgnlAltId: selectedRa?.id || '',
              rgnlAltRev: selectedRa?.rev || '',
              status,
            },
          },
        }),
      );

      profile.setIsExpanded(false);
      selectedRa?.updateRev(res.data?.updateSeriesProfileStatus.rgnlAlt.rev as string);
      selectedRa?.updateStatus(status);
      window.scrollTo({
        top: getScrollTopFromClick(profile.id),
        behavior: 'smooth',
      });
    } catch (e) {
      error((e as Error).message);
    }
  };

  const needsRevisionBtnHandler = async () => {
    if (!profile.addNote) {
      profile.setAddNote(true);
      return;
    }

    if (profile.note && profile.addNote) {
      await updateSeriesProfileStatusHandler(Status.NEEDS_REVISIONS);
    }
  };

  const meetsGuidelinesBtnHandler = async () => {
    await updateSeriesProfileStatusHandler(Status.MEETS_GUIDELINES);
  };

  return (
    <div className={styles.footerButtons}>
      {isExpanded && profile.addNote && !profile.note && (
        <div className={styles.noteWarning}>
          <OATIcon icon="warning" colorTheme="red" className={styles.warningIcon} />
          <span className={styles.warningText}>Please enter a note.</span>
        </div>
      )}
      <ButtonGroup>
        <Link to={`/details/region/${region}/period/${period}/profile/${profile.id}/regalt/${selectedRa?.id}`} style={{ textDecoration: 'none' }}>
          <Button variant="primary" id="program-details-btn">
            Program Details
          </Button>
        </Link>
        <div className={styles.btnDivider} />
        <Button onClick={() => needsRevisionBtnHandler()} variant="primary" id="needs-revisions-btn" className={styles.needsRevisionBtn}>
          {Status.NEEDS_REVISIONS}
        </Button>
        <Button onClick={() => meetsGuidelinesBtnHandler()} variant="primary" id="meets-guidelines-btn">
          {Status.MEETS_GUIDELINES}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default observer(FooterButtons);
