import { OATIcon } from 'oat-common-ui';
import styles from './styles.module.scss';

interface Props {
  message: string;
}

const CardError = ({ message }: Props) => {
  return (
    <p className={styles.error}>
      <OATIcon className={styles.errorIcon} icon="warning" size={14} />
      <span>{message}</span>
    </p>
  );
};

export default CardError;
