import clsx from 'clsx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { BlockInputLabel, Button, Modal, ModalBody, ModalFooter, ModalHeader, Table, useToast } from 'oat-common-ui';
import { useEffect, useMemo, useState } from 'react';
import { ModalTitle } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import NumberInput from '../../../../components/NumberInput';
import { ForecastedSalesResponse, Offering, OfferingCosts, useSaveForecastedSalesMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import useUserInfo from '../../../../utils/useUserInfo';
import { SeriesProfileForecastedSales } from '../../models/FSVModel';
import ProfileModel from '../../models/ProfileModel';
import { buildForecastedSales, invalidFsv, xformFsvs } from '../../utils';
import styles from './styles.module.scss';

interface Props {
  raId?: string;
  seriesProfileId?: string;
  profiles: ProfileModel[];
  offering: Offering;
  totalForecastedSales: number;
  onClose: () => void;
}

const FsvModal = ({ raId = '', seriesProfileId = '', profiles, offering, totalForecastedSales, onClose }: Props) => {
  const { summaryStore, offeringCostsStore } = useStores();
  const { isGSTUser, isSETUser, isLexusUser } = useUserInfo();
  const [saving, setSaving] = useState(false);
  const { error } = useToast();
  const [saveForecastedSales] = useSaveForecastedSalesMutation();
  const [touchedProfilesIds, setTouchedProfilesIds] = useState<string[]>([]);

  const hasEarningsOnly = useMemo(() => {
    if (isGSTUser() || isSETUser()) {
      return false;
    }

    return profiles.some(profile => profile.isEarningsOnly);
  }, [isGSTUser, isSETUser, profiles]);

  const fsvModel = useLocalObservable(() => buildForecastedSales(profiles, offering, totalForecastedSales, hasEarningsOnly));

  const { invalidSum, empty } = invalidFsv(fsvModel, hasEarningsOnly);

  const errorMessage = hasEarningsOnly
    ? 'The sum of your forecasted sales cannot exceed the Total Forecasted Sales.'
    : "The total forecasted sales must equal to the sum of all profile's forecasted sales volume.";

  const handleSave = async () => {
    const touchedFsvs = fsvModel.seriesProfileForecastedSales.filter(
      fsvModel => touchedProfilesIds.includes(fsvModel.seriesProfileId) || (hasEarningsOnly && fsvModel.isEarningsOnly),
    );

    if (!touchedFsvs.length) {
      onClose();
      return;
    }

    const seriesProfileForecastedSales = touchedFsvs?.map(item => {
      return {
        seriesProfileId: item.seriesProfileId,
        forecastedSales: Number(item.forecastedSales),
      };
    });
    try {
      setSaving(true);
      const resp = await trackPromise(
        saveForecastedSales({
          variables: {
            input: {
              offeringId: fsvModel.offeringId,
              offeringRev: fsvModel.offeringRev,
              totalForecastedSales: Number(fsvModel.totalForecastedSales),
              seriesProfileForecastedSales,
            },
          },
        }),
      );
      if (resp.data && resp.data?.saveForecastedSales) {
        summaryStore.setTotalForecastedSales(fsvModel.totalForecastedSales);
        summaryStore.unconfirmChangedFsvProfiles(touchedFsvs);
        summaryStore.updateOfferingRev(summaryStore.offering.id, resp.data?.saveForecastedSales.offeringRev);
        summaryStore.updateAllRgnlAltRevs(resp.data?.saveForecastedSales.forecastedSales as ForecastedSalesResponse[]);
        offeringCostsStore.setData(resp.data?.saveForecastedSales.offeringCosts as OfferingCosts);
        xformFsvs(fsvModel, summaryStore.profiles);
      }
    } catch (e) {
      error((e as Error).message);
    } finally {
      setSaving(false);
    }
    onClose();
  };

  const updateChangedFsvProfileIds = (id: string) => {
    const touchedIds = [...touchedProfilesIds];

    const touchedIndex = touchedIds.findIndex(touchedId => touchedId === id);

    if (touchedIndex === -1) {
      touchedIds.push(id);
    }

    setTouchedProfilesIds(touchedIds);
  };

  const handleFsvOnChange = (profile: SeriesProfileForecastedSales, value: string) => {
    fsvModel.updateProfileSales(profile, Number(value));
    updateChangedFsvProfileIds(profile.seriesProfileId);
  };

  useEffect(() => {
    const element = document.querySelector(`#fsv-profile-${seriesProfileId}`);
    element?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  });

  return (
    <Modal isOpen onClose={onClose} className={styles.fsvModal}>
      <ModalHeader onClose={onClose}>
        <ModalTitle>Forecasted {`${isLexusUser() ? 'Area' : 'Region'}`} Sales</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div>
          <BlockInputLabel label="Total Forecasted Sales" className={styles.inputContainer}>
            <NumberInput
              error={!fsvModel.totalForecastedSales}
              wholeNumber
              value={fsvModel.totalForecastedSales}
              onValueChange={val => {
                fsvModel.updateTotalFsv(val.value);
                fsvModel.calculateEarningsOnlyFsv();
              }}
              units
            />
          </BlockInputLabel>
        </div>
        <div className={styles.fsvTableContainer}>
          <Table className={styles.fsvTable}>
            <thead>
              <tr>
                <th>GROUP</th>
                <th>SERIES</th>
                <th>SALES</th>
              </tr>
            </thead>
            <tbody>
              {fsvModel.seriesProfileForecastedSales.map((item, ind) => (
                <tr key={item.seriesProfileId} className={clsx(raId === item.selectedRaId && styles.highLight)} id={`fsv-profile-${item.seriesProfileId}`}>
                  <td>{item.group}</td>
                  <td>{item.series}</td>
                  <td>
                    <NumberInput
                      id={`fsv-modal-sales-input-${item.seriesProfileId}`}
                      value={item.forecastedSales}
                      className={clsx(styles.numInput, raId === item.selectedRaId && styles.highlightBorder, ind % 2 === 0 && item.forecastedSales && styles.dark)}
                      error={item.forecastedSales === undefined}
                      onValueChange={val => handleFsvOnChange(item, val.value)}
                      disabled={item.isEarningsOnly}
                      wholeNumber
                      units
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {invalidSum && <div className={styles.error}>{errorMessage}</div>}
        {empty && <div className={styles.error}>Field is required.</div>}
      </ModalBody>
      <ModalFooter>
        <Button id="fsv-modal-close-btn" variant="text" onClick={() => onClose()}>
          Close
        </Button>
        <Button id="fsv-modal-save-btn" variant="primary" onClick={handleSave} disabled={invalidSum || empty || saving}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default observer(FsvModal);
