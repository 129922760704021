import AprCardModel from '../pages/ProgramDetails/models/AprCardModel';
import CashCardModel from '../pages/ProgramDetails/models/CashCardModel';
import LeaseCardModel from '../pages/ProgramDetails/models/LeaseCardModel';
import MiscCardModel from '../pages/ProgramDetails/models/MiscCardModel';

export const setCashDefaultStartEndDates = (cashCards: CashCardModel[], cashObj: CashCardModel) => {
  const parentCashOffer = cashCards.find(c => c.id === cashObj.parentId);

  cashObj.fields.defaultStartDate = parentCashOffer?.fields.startDate;
  cashObj.fields.defaultEndDate = parentCashOffer?.fields.endDate;
};

export const setAprDefaultStartEndDates = (aprCards: AprCardModel[], aprObj: AprCardModel) => {
  const parentAprOffer = aprCards.find(a => a.id === aprObj.parentId);
  aprObj.tierMap.forEach(tier => {
    tier.terms.forEach(aTerm => {
      const parentAprTerm = parentAprOffer?.selectedTierModel?.terms.find(pTerm => pTerm.fields.term === aTerm.fields.term);
  
      aTerm.fields.defaultStartDate = parentAprTerm?.fields.startDate;
      aTerm.fields.defaultEndDate = parentAprTerm?.fields.endDate;
    });
  })
};

export const setMiscDefaultStartEndDates = (miscCards: MiscCardModel[], miscObj: MiscCardModel) => {
  const parentMiscOffer = miscCards.find(m => m.id === miscObj.parentId);

  miscObj.fields.defaultStartDate = parentMiscOffer?.fields.startDate;
  miscObj.fields.defaultEndDate = parentMiscOffer?.fields.endDate;
};

export const setLeaseDefaultStartEndDates = (leaseCards: LeaseCardModel[], leaseObj: LeaseCardModel) => {
  const parentLeaseOffer = leaseCards.find(l => l.id === leaseObj.parentId);

  leaseCards.forEach(leaseCard => {
    leaseCard.leaseTerms.forEach(leaseTerm => {
      const parentLeaseTerm = parentLeaseOffer?.leaseTerms.find(pTerm => pTerm.term === leaseTerm.term);

      leaseTerm.leaseForms.forEach(leaseForm => {
        const parentLeaseForm = parentLeaseTerm?.leaseForms.find(pForm => pForm.uid === leaseTerm.currentFormUid);

        leaseForm.inputs.defaultStartDate = parentLeaseForm?.inputs.startDate || '';
        leaseForm.inputs.defaultEndDate = parentLeaseForm?.inputs.endDate || '';
      });
    });
  });
};
