import IpBreadCrumbs from '../../components/IpComponents/IpBreadCrumbs';
import { FEATURE_OR_4154, Status } from '../../constants/global';
import { Offering, OfferingCosts, SeriesProfile, useGetRegionalSummaryDataQuery } from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';
import LoadingPage from '../LoadingPage';
import NationalApproval from './NationalApproval';
import RegionalSummary from './RegionalSummary';
import SeriesProfilesData from './SeriesProfilesData';

const Summary = () => {
  const { summaryStore, offeringCostsStore, userInfoStore, regionalCostShareStore } = useStores();

  const { period, region } = useUrlParams();

  const { data, loading, error } = useGetRegionalSummaryDataQuery({ variables: { offeringId: period, regionCode: region } });

  if (loading || error) {
    return <LoadingPage breadCrumbs={IpBreadCrumbs(false)} error={!!error} />;
  }

  if (data) {
    summaryStore.setOffering(data.offering as Offering, userInfoStore.isNational());
    summaryStore.setShowAllOffers(data.offering.status !== Status.REVISED && data.offering.status !== Status.NEEDS_REVISIONS);
    offeringCostsStore.setData(data.offeringCosts as OfferingCosts);

    if (FEATURE_OR_4154) {
      regionalCostShareStore.setCostShareDates(data.seriesProfile as SeriesProfile[]);
    }
  }

  const showNationalApproval = userInfoStore.isNational() && ![Status.DRAFT, Status.NEEDS_REVISIONS, Status.NO_RESPONSE].includes(summaryStore.offering.status as Status);

  return (
    <SeriesProfilesData seriesProfileIds={data?.seriesProfile.map(item => item?.id || '') || []}>
      {showNationalApproval ? <NationalApproval /> : <RegionalSummary />}
    </SeriesProfilesData>
  );
};

export default Summary;
