import { observer } from 'mobx-react-lite';
import { Button, useToast } from 'oat-common-ui';
import { useMemo, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Link } from 'react-router-dom';
import EnhCostShareNotification from '../../../../components/EnhCostNotification/EnhCosShareNotification';
import PenRateTable from '../../../../components/PenRateTable';
import { FEATURE_OR_2477, Status } from '../../../../constants/global';
import { OfferingCosts, PenetrationDetails, SavePenetrationResult, useConfirmSeriesMutation } from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import nationalRYOHasRYOOffers from '../../../../utils/nationalRYOHasRYOOffers';
import useUserInfo from '../../../../utils/useUserInfo';
import CreateAprModal from '../../../ProgramDetails/components/CreateAprModal';
import ProfileModel from '../../models/ProfileModel';
import RgnlAltModel from '../../models/RgnlAltModel';
import FsvCosts from '../FsvCosts';
import OffersTable from '../OffersTable';
import AddOfferCtas from './AddOfferCtas';
import styles from './styles.module.scss';

interface Props {
  profile: ProfileModel;
  rgnlAlt: RgnlAltModel;
  selected?: boolean;
  onSelect: (rgnlalt: RgnlAltModel) => void;
}

const RgnlAltSection = ({ profile, rgnlAlt, selected, onSelect }: Props) => {
  const { region, period } = useUrlParams();
  const { error } = useToast();
  const { isSETUser } = useUserInfo();
  const {
    offeringCostsStore,
    summaryStore,
    userInfoStore: { isNational },
  } = useStores();

  const [showCreateOffer, setShowCreateOffer] = useState(false);
  const [aprModal, setAprModal] = useState(false);
  const [confirmSeries] = useConfirmSeriesMutation();

  const hasStandalones = rgnlAlt.hasStandAloneOffers();
  const showProgramDetailsCta = rgnlAlt.hasNonRYOOffersAvailable();
  const showAddOfferCta = (rgnlAlt.isStandalone && !hasStandalones) || (rgnlAlt.isNationalRYO && !hasStandalones && nationalRYOHasRYOOffers(rgnlAlt));
  const showPenRateTbl = rgnlAlt.apr.length > 0 || rgnlAlt.cash.length > 0 || rgnlAlt.lease.length > 0 || rgnlAlt.misc.length > 0;

  const handlePostSave = (_penetrationDetails: PenetrationDetails[], res?: SavePenetrationResult) => {
    offeringCostsStore.setData(res?.offeringCosts as OfferingCosts);
  };

  const handleConfirmSeries = async () => {
    try {
      if (!rgnlAlt.hasErrors) {
        const res = await trackPromise(confirmSeries({ variables: { input: { rgnlAltId: rgnlAlt.id, rev: rgnlAlt.rev } } }));

        if (res.data?.confirmSeries.success) {
          rgnlAlt.updateRev(res.data.confirmSeries.rgnlAlt.rev);
          profile.setIsConfirmedView(true);
          profile.setIsConfirmedSeries(true);
        }
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const hasUpdatedEnhCostShare = useMemo(() => {
    const rgnlAltOffers = profile.rgnAltOffersMap.get(rgnlAlt.id);

    return rgnlAltOffers?.some(offer => offer.regional.isEnhCostShareUpdated || offer.regional.isEnhCostShareRemoved);
  }, [profile.rgnAltOffersMap, rgnlAlt.id]);

  const isDisabled = summaryStore.offering.status === Status.MEETS_GUIDELINES || Boolean(isNational() && summaryStore.offering.isAtc);
  const hasMissingRequiredFields = rgnlAlt.misc.find(misc => misc.isMissingRequiredFields)?.isMissingRequiredFields;
  const hasAprSubCashLessThanNational = rgnlAlt.apr.some(apr => apr.aprDetails.some((detail, index) => detail.subCash < (apr.nationalAprDetails[index]?.subCash ?? 0)));
  const disableConfirm = profile.isEarningsOnly
    ? isDisabled
    : rgnlAlt.hasErrors ||
      isDisabled ||
      !profile.isValid ||
      rgnlAlt.hasVinErrors ||
      rgnlAlt.contestNumberError.length > 0 ||
      hasMissingRequiredFields ||
      hasUpdatedEnhCostShare ||
      hasAprSubCashLessThanNational;

  const rgnlAltError = rgnlAlt.hasLeaseNoteError ? rgnlAlt.errors.length !== 1 : rgnlAlt.errors.length > 0;
  return (
    <div className={styles.raSection}>
      {!profile.isEarningsOnly && <OffersTable profile={profile} rgnlAlt={rgnlAlt} selected={selected} onSelect={onSelect} />}
      {selected && (
        <>
          {/* FSV and Costs */}
          <FsvCosts rgnlAlt={rgnlAlt} />

          {/* Pen Rate Table */}
          {showPenRateTbl && <PenRateTable rgnlAlt={rgnlAlt} onSave={handlePostSave} isDisabled={isDisabled} />}

          {FEATURE_OR_2477 && hasUpdatedEnhCostShare && <EnhCostShareNotification isSummary id={rgnlAlt.id} />}

          {/* CTAs */}
          <div className={styles.ctas}>
            {!profile.isEarningsOnly && (
              <>
                {/* Add Offer Cta */}
                {showAddOfferCta && !showCreateOffer && (
                  <Button id={`add-offer-cta-${rgnlAlt.id}`} variant="primary" onClick={() => setShowCreateOffer(true)} disabled={isDisabled}>
                    Add Offer
                  </Button>
                )}
                {/* Create Offer Ctas */}
                {showCreateOffer && (
                  <AddOfferCtas
                    isMultiSeries={profile.series.length > 1}
                    onCancel={() => setShowCreateOffer(false)}
                    openAprModal={() => setAprModal(true)}
                    profileId={profile.id}
                    rgnlAltId={rgnlAlt.id}
                  />
                )}
                {/* Program Details CTA */}
                {showProgramDetailsCta && (
                  <Link to={`/details/region/${region}/period/${period}/profile/${profile.id}/regalt/${rgnlAlt.id}`}>
                    <Button id={`program-details-cta-${rgnlAlt.id}`} variant="primary" disabled={!isSETUser() && rgnlAltError}>
                      Program Details
                    </Button>
                  </Link>
                )}
              </>
            )}
            {/* Confirm Series CTA */}
            {(!showCreateOffer || profile.isEarningsOnly) && (
              <Button className={styles.confirmButton} disabled={disableConfirm} id={`confirm-cta-${rgnlAlt.id}`} onClick={handleConfirmSeries} variant="primary">
                Confirm Series
              </Button>
            )}
          </div>
        </>
      )}
      {aprModal && <CreateAprModal onClose={() => setAprModal(false)} regaltId={rgnlAlt.id} profileId={profile.id} />}
    </div>
  );
};

export default observer(RgnlAltSection);
