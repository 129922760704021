import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { OPTION_TYPE_NAMES } from 'oat-admin-common/dist/types/constants';
import DollarDisplay from '../../../../../components/DollarDisplay';
import StartEndDate from '../../../../../components/StartEndDate';
import { Status } from '../../../../../constants/global';
import { VehicleInput } from '../../../../../gql/generated';
import useUrlParams from '../../../../../hooks/useUrlParams';
import useStores from '../../../../../stores/useStores';
import { assignStringValue } from '../../../../../utils/assignValue';
import getDisabledVehicles from '../../../../../utils/getDisabledVehicles';
import useUserInfo from '../../../../../utils/useUserInfo';
import MiscCardModel from '../../../models/MiscCardModel';
import BlockText from '../../BlockText';
import ContestNumber from '../../ContestNumber';
import { returnStatesList } from '../../LeaseCard/components/VehicleInfo/utils';
import OfferCompatibleOffers from '../../OfferCompatibleOffers';
import OfferExclusions from '../../OfferExclusions';
import SpecialEdition from '../../SpecialEdition';
import styles from '../styles.module.scss';

type Props = {
  card: MiscCardModel;
  forCreate?: boolean;
  compatibileAccessories?: string[];
  handleInputChange: () => void;
  handleSaveCompatibility: () => void;
};

const MiscOfferCardLeftContent = ({ card, forCreate = false, compatibileAccessories, handleInputChange, handleSaveCompatibility }: Props) => {
  const {
    uid,
    id,
    fields: {
      accessoryCodes,
      combinedPerUnitCost,
      compatibileOffers,
      compatibility,
      defaultEndDate,
      defaultStartDate,
      endDate,
      excludedVehicles,
      includedVehicles,
      includedStates,
      isSpecialEdition,
      specialEditionPackage,
      startDate,
      type,
      vehicles,
      pnv,
      contestNumber,
      isValidEnR,
      maxNoOfDays,
    },
    hasDateError,
    isDuplicate,
    isStandAlone,
    nationalOffer,
    toggleCompatibileOffer,
    toggleIsSpecialEdition,
    updateField,
    updateVehicles,
  } = card;

  const {
    programDetailsStore: { seriesProfile, offering },
  } = useStores();
  const { isGSTUser, isLexusUser, isSETUser } = useUserInfo();
  const { region } = useUrlParams();
  const { CASH_TYPES } = AdminConstants;
  const disabledVehicles = getDisabledVehicles(nationalOffer?.vehicles || seriesProfile.vehicles);
  const isDisabled = offering.status === Status.MEETS_GUIDELINES || card.isDisabled;
  const isDateDisabled = (!isStandAlone && !isDuplicate) || isDisabled;

  const handleChangeDate = (fieldName: 'startDate' | 'endDate', val?: Date) => {
    if (val) {
      updateField(fieldName, val);
      handleInputChange();
    }
  };

  const handleExclusions = (excludedList: VehicleInput[], includedList: VehicleInput[]) => {
    updateVehicles(excludedList, includedList);
    handleInputChange();
  };

  const handleCompatibility = (_compatibility: string[]) => {
    updateField('compatibileOffers', _compatibility);
    handleInputChange();
    handleSaveCompatibility();
  };

  const handleOnUpdate = (contestNumberId: string, contestNumber: string, isUpdate = false) => {
    updateField('contestNumber', contestNumber);
    updateField('contestNumberOfferId', contestNumberId);
    if (isUpdate && !forCreate) {
      handleInputChange();
    }
  };

  const getCashBoxValue = () => {
    switch (type) {
      case CASH_TYPES.OTHER:
        return assignStringValue(pnv, '0');
      case CASH_TYPES.DEFERRED_PAYMENT:
        return assignStringValue(maxNoOfDays, '0');
      default:
        return assignStringValue(combinedPerUnitCost, '0');
    }
  };

  return (
    <>
      <div className={styles.offerInfoDetails}>
        <div className={styles.cashBasicInfo}>
          <div className={styles.cashBack}>
            <div>
              <DollarDisplay className={styles.cashBackAmount} value={getCashBoxValue()} wholeNumber />
            </div>
            <p className={styles.cashBackLabel}>{type === CASH_TYPES.DEFERRED_PAYMENT ? 'Maximum No. of Days' : 'CASH BACK'}</p>
          </div>
        </div>
        <div className={styles.detailsContainer}>
          {isSETUser() && <ContestNumber optionTypeName={card.fields.name} contestNumber={contestNumber} id={uid} handleUpdate={handleOnUpdate} forCreate={forCreate} />}
          <OfferExclusions
            disabledVehicles={disabledVehicles}
            excludedModels={excludedVehicles}
            id={uid}
            includedModels={includedVehicles}
            isEditEnabled={isStandAlone || isDuplicate}
            onSubmit={handleExclusions}
            disabled={card.isDisabled}
          />
          <BlockText topText="Compatible States" bottomText={returnStatesList(region, includedStates)} />
          <OfferCompatibleOffers
            optionType={type as OPTION_TYPE_NAMES}
            onSubmit={handleCompatibility}
            compatibileOffers={compatibileOffers}
            compatibility={compatibility}
            toggleCompatibileOffer={toggleCompatibileOffer}
            isEditable={isStandAlone || isDuplicate}
            id={id}
            disabled={card.isDisabled}
          />
        </div>
      </div>
      <div className={styles.datesContainer}>
        <StartEndDate
          datePickerClass={styles.datePicker}
          dateWrapperClass={styles.dateWrapper}
          maxDate={defaultEndDate}
          minDate={defaultStartDate}
          setStartDate={value => handleChangeDate('startDate', value)}
          setEndDate={value => handleChangeDate('endDate', value)}
          startDate={startDate}
          endDate={endDate}
          isDark
          labelClass={styles.labelWrapper}
          startDateError={hasDateError || (!isValidEnR && isDuplicate)}
          endDateError={hasDateError || (!isValidEnR && isDuplicate)}
          disabled={isDateDisabled}
        />
      </div>

      {(isLexusUser() || isGSTUser()) && (
        <SpecialEdition
          id={uid}
          accessoryCodes={accessoryCodes}
          className={styles.marginTop30}
          compatibileAccessories={compatibileAccessories}
          inputLabel="Package"
          isChecked={isSpecialEdition}
          isLexus={isLexusUser()}
          label="Special Edition"
          onChange={value => {
            updateField('specialEditionPackage', value);
            handleInputChange();
          }}
          onSubmit={value => {
            updateField('accessoryCodes', value.join());
            handleInputChange();
          }}
          onSelect={() => {
            toggleIsSpecialEdition();
            handleInputChange();
          }}
          value={specialEditionPackage}
          vehicles={vehicles}
          forCreate={forCreate || card.isMultiSeries}
          disabled={card.isDisabled}
        />
      )}
    </>
  );
};

export default observer(MiscOfferCardLeftContent);
