/**
 * Takes in array of tiers and returns the lowest one
 * @param availableTiers
 */
const getLowestTier = (availableTiers: string[]) => {
  const tiers = ['1+', '1', '2', '3', '4', '5', '6', '7', '7+', '8'];
  return tiers.find(t => availableTiers.indexOf(t) !== -1) || '';
};

export default getLowestTier;
