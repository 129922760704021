import { VehicleInput } from '../gql/generated';

const getDisabledVehicles = (vehicles: VehicleInput[]): string[] => {
  return vehicles
    .filter(vehicle => !vehicle.isInclusion)
    .map(code => {
      return code.modelCode;
    });
};

export default getDisabledVehicles;
