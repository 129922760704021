import { observer } from 'mobx-react-lite';
import { BreadCrumbType } from 'oat-common-ui';
import { Offering, useGetOfferingsByRegionActiveQuery } from '../../gql/generated';
import useStores from '../../stores/useStores';
import LoadingPage from '../LoadingPage';
import DashboardComponent from './DashboardComponent';
import { processAllOfferings } from './util';

const Dashboard = () => {
  const { dashboardStore, userInfoStore } = useStores();

  // to get region code for now
  const regionCode = userInfoStore.getRegionCode();

  const { data, loading, error } = useGetOfferingsByRegionActiveQuery({
    variables: { regionCode, includeCosts: !userInfoStore.isNational() },
  });

  const breadCrumbs: BreadCrumbType[] = [{ name: userInfoStore.getRegionNameFull() }];

  if (loading || error) {
    return <LoadingPage breadCrumbs={breadCrumbs} error={!!error} />;
  }

  if (data) {
    dashboardStore.initData(data, userInfoStore.isNational());

    if (!userInfoStore.isNational()) {
      const { processedOfferings, fyBalance } = processAllOfferings([...(data.active as Offering[]), ...(data.archived as Offering[])]);
      dashboardStore.processedOfferings = processedOfferings;
      dashboardStore.fyBalance = fyBalance;
    }
  }

  return <DashboardComponent />;
};

export default observer(Dashboard);
