import { observer } from 'mobx-react-lite';
import { OATWrapper } from 'oat-common-ui';
import { Link } from 'react-router-dom';
import MainHeader from '../../components/MainHeader';
import useStores from '../../stores/useStores';
import FiscalYear from './components/FiscalYear';
import GeneratedReports from './components/GeneratedReports';
import IpList from './components/IpList';
import NationalPlanning from './components/NationalPlanning';
import ReportsHeadline from './components/ReportsHeadline';
import styles from './styles.module.scss';

interface Props {
  isNationalPlanning?: boolean;
}

const ReportsComponent = ({ isNationalPlanning = false }: Props) => {
  const reportsBreadCrumb = [{ name: 'Dashboard', component: <Link to={`/dashboard`}>Dashboard</Link> }, { name: 'Reports' }];
  const {
    reportsStore: { generating },
  } = useStores();

  return (
    <>
      <MainHeader breadCrumbs={reportsBreadCrumb} headerTitle="Reports" />
      <OATWrapper className={styles.reportsSection}>
        <div className={styles.reportsLeftSection}>
          {isNationalPlanning ? (
            <>
              <ReportsHeadline title="Fiscal Year" />
              <FiscalYear />
              <ReportsHeadline title="National Planning" />
              <NationalPlanning />
            </>
          ) : (
            <>
              <ReportsHeadline title="Incentive Period Documents" />
              <div className={styles.ipReportsList}>
                <IpList />
              </div>
            </>
          )}
        </div>
        <div className={styles.reportsRightSection}>
          {generating && <p>Your reports are being generated.</p>}
          <GeneratedReports />
        </div>
      </OATWrapper>
    </>
  );
};

export default observer(ReportsComponent);
