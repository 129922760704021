import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { BlockInputLabel, Button, OATIcon } from 'oat-common-ui';
import { useState } from 'react';
import { Status } from '../../constants/global';
import useStores from '../../stores/useStores';
import TextArea from '../TextArea';

import styles from './styles.module.scss';

type NoteProps = {
  label: string;
  onChange: (val: string) => void;
  value: string;
  hasError?: boolean;
  labelClass?: string;
  isRequired?: boolean;
  disabled?: boolean;
};

const Note = ({ label, value, onChange, hasError = false, labelClass, isRequired, disabled }: NoteProps) => {
  const { summaryStore } = useStores();
  const [showNote, setShowNote] = useState(Boolean(value?.length));

  const handleChangeNote = (value: string) => {
    onChange(value);
  };

  const handleCloseNote = () => {
    onChange('');
    setShowNote(false);
  };

  const isDisabled = summaryStore.offering.status === Status.MEETS_GUIDELINES || disabled;

  return (
    <div className={styles.noteWrapper}>
      {!showNote && !isRequired && (
        <Button
          variant="text"
          id={`${label}-show-note-btn`}
          onClick={() => setShowNote(!showNote)}
          className={clsx(styles.addNoteWrapper, styles.addNoteBtn)}
          disabled={isDisabled}
        >
          <p className={styles.addNoteText}>
            <OATIcon icon="notes" className={styles.noteIcon} colorTheme="blue" />
            Add {label}
          </p>
        </Button>
      )}
      {(showNote || isRequired) && (
        <>
          <div className={styles.noteInputWrapper}>
            <BlockInputLabel label={label} className={styles.label} labelClass={labelClass} />
            <div className={styles.noteInput}>
              <TextArea
                className={styles.noteTextArea}
                error={hasError || (isRequired && value?.length === 0 ? true : false)}
                id={label}
                value={value}
                onChange={e => handleChangeNote(e.target.value)}
                disabled={isDisabled}
              />
              <Button id={`${label}-hide-note-btn`} variant="text" onClick={handleCloseNote} className={styles.cancelIcon} disabled={isDisabled}>
                <OATIcon icon="close" colorTheme="blue" size={16} />
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(Note);
