import { makeAutoObservable } from 'mobx';
import { DropdownItem, sortFieldsHelper } from 'oat-common-ui';
import { REPORT_NAME_BY_TYPE, Status } from '../constants/global';
import { Offering, ReportResponse } from '../gql/generated';

class ReportsStore {
  generatedReports: ReportResponse[] = [];
  availableIpList: Offering[] = [];
  regionalIpList: Record<string, Offering[]> = {};
  selectedIp: Offering = {} as Offering;
  regionalOfferings: Record<string, Offering[]> = {}; // used for national user creating regional reports
  availableRegions: DropdownItem[] = [];
  selectedRegion: DropdownItem = {} as DropdownItem;
  generating = false;
  loadingReports = false;
  isNational = false;

  constructor() {
    makeAutoObservable(this);
  }

  setGeneratedReports = (reports: ReportResponse[]) => {
    this.generatedReports = reports;
  };

  setAvailableIpList = (offerings: Offering[]) => {
    const sortedOfferings = offerings.slice().sort(sortFieldsHelper('startDate', false));
    this.availableIpList = sortedOfferings;
  };

  setSelectedIp = (offering: Offering) => {
    this.selectedIp = offering;
  };

  setAvailableRegions = (regions: DropdownItem[]) => {
    this.availableRegions = regions;
  };

  setSelectedRegion = (region: DropdownItem) => {
    this.selectedRegion = region;
  };

  setGenerating = (generating: boolean) => {
    this.generating = generating;
  };

  setLoadingReports = (loadingReports: boolean) => {
    this.loadingReports = loadingReports;
  };

  setIsNational = (isNational: boolean) => {
    this.isNational = isNational;
  };

  setRegionalOfferings = (nationalId: string, offerings: Offering[] | undefined) => {
    this.regionalOfferings = {
      [nationalId]: offerings ?? [],
    };
  };

  getRegionalOfferingId = () => {
    if (!this.isNational) {
      return this.selectedIp.id;
    }

    const regionalIps = this.regionalOfferings[this.selectedIp.id ?? ''] ?? [];
    const regionalOfferings = regionalIps.filter(item => item.regionCode === this.selectedRegion.value);
    const originalOffering = regionalOfferings.find(item => !item.isAtc);
    const atcOffering = regionalOfferings.find(item => item.isAtc);

    if (atcOffering && [Status.SUBMITTED, Status.NEEDS_REVISIONS, Status.REVISED].includes(atcOffering.status as Status)) {
      return atcOffering.id;
    }

    return originalOffering?.id ?? '';
  };

  doesReportExist = (report: string) => {
    // a generated report may have a different name that the UI uses, hence the REPORT_NAME_BY_TYPE enum.
    const reportName = REPORT_NAME_BY_TYPE[report] ?? report;
    return Boolean(this.generatedReports.find(item => item.reportName === reportName));
  };
}

export default ReportsStore;
