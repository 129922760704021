import { EfcSeries, EfcSeriesData } from '../../../../models/EfcSeriesModels';
import DD365Store from '../../../../stores/DD365Store';
import EFCStore from '../../../../stores/EFCStore';
import LeaseCardModel from '../../models/LeaseCardModel';

const getSeriesName = (seriesItem: EfcSeries, seriesYear: string, modelCode: number) => {
  let seriesNameFinal = seriesItem.title;

  seriesItem.year.forEach(year => {
    if (year.code === seriesYear) {
      year.model.forEach(model => {
        if (model.code === modelCode.toString()) {
          seriesNameFinal += ` ${model.gradeTitle}`;
        }
      });
    }
  });

  return seriesNameFinal;
};

export const getSeriesItem = (efcData: EfcSeriesData, series: string) => {
  return efcData.series.find(seriesItem => seriesItem.code === series);
};

export const setLeaseData = async (seriesItem: EfcSeries, leaseCards: LeaseCardModel[], efcStore: EFCStore, isLexus: boolean, seriesYear: string, brand: string) => {
  const promises = [];
  const vins = new Set<string>();

  // Put all vin requests into a promise array
  for (const leaseCard of leaseCards) {
    for (const leaseTerm of leaseCard.leaseTerms) {
      for (const leaseForm of leaseTerm.leaseForms) {
        // Lexus uses name from EFC
        const seriesName = !isLexus && leaseForm.inputs.modelCode ? getSeriesName(seriesItem, seriesYear, leaseForm.inputs.modelCode) : seriesItem.title;
        leaseForm.setSeriesName(seriesName);

        if (!leaseForm.isGST && leaseForm.inputs.vin && leaseForm.inputs.isAdvertised && !vins.has(leaseForm.inputs.vin)) {
          vins.add(leaseForm.inputs.vin);
          promises.push(efcStore.getEfcVinDetails(leaseForm.inputs.vin, brand));
        }
      }
    }
  }

  // loop through again to set vin details
  const res = await Promise.all(promises);
  leaseCards.forEach(leaseCard => {
    leaseCard.leaseTerms.forEach(leaseTerm => {
      leaseTerm.leaseForms.forEach(leaseForm => {
        if (!leaseForm.isGST && leaseForm.inputs.vin) {
          const vinDetails = res.find(item => item && item.vin === leaseForm.inputs.vin);
          leaseForm.setVinDetails(vinDetails);
        }
      });
    });
  });
};

export const setLeaseDataForSET = async (seriesItem: EfcSeries, leaseCards: LeaseCardModel[], dd365Store: DD365Store, seriesYear: string, brand: string) => {
  const promises = [];
  const vins = new Set<string>();

  // Put all vin requests into a promise array
  for (const leaseCard of leaseCards) {
    for (const leaseTerm of leaseCard.leaseTerms) {
      for (const leaseForm of leaseTerm.leaseForms) {
        if (leaseForm.inputs.vin && leaseForm.inputs.isAdvertised && !vins.has(leaseForm.inputs.vin)) {
          vins.add(leaseForm.inputs.vin);
          promises.push(dd365Store.getDD365VinDetails(leaseForm.inputs.vin, brand));
        }

        // default series name
        const seriesName = leaseForm.inputs.modelCode ? getSeriesName(seriesItem, seriesYear, leaseForm.inputs.modelCode) : seriesItem.title;
        leaseForm.setSeriesName(seriesName);
      }
    }
  }

  // loop through again to set vin details
  const res = await Promise.all(promises);
  leaseCards.forEach(leaseCard => {
    leaseCard.leaseTerms.forEach(leaseTerm => {
      leaseTerm.leaseForms.forEach(leaseForm => {
        if (leaseForm.inputs.vin) {
          // overwrite series name with vin marketing series if applicable
          const vinDetails = res.find(item => item && item.vin === leaseForm.inputs.vin);
          leaseForm.setSeriesName(vinDetails?.marketingSeries ?? leaseForm.seriesName);
          leaseForm.setVinDetailsDD365(vinDetails, seriesItem);
        }
      });
    });
  });
};
