import { OATLink } from 'oat-common-ui';
import { Link } from 'react-router-dom';
import useUrlParams from '../../../hooks/useUrlParams';
import { RegionalStatus } from '../../../stores/RegionalStatusStore';
import useStores from '../../../stores/useStores';
import RegionStatus from './RegionStatus';
import { processStatus } from './utils';
interface Props {
  item: RegionalStatus;
}

const RegionStatusRow = ({ item }: Props) => {
  const { period } = useUrlParams();
  const { userInfoStore } = useStores();
  const summaryLink = `/summary/region/${item.regionCode}/period/${item.id}?parentOfferingId=${period}`;

  return (
    <tr key={item.regionCode}>
      <td>{item.regionCode}</td>
      <td>
        <Link component={OATLink} to={summaryLink}>
          {item?.regionName}
        </Link>
      </td>
      <RegionStatus status={processStatus(item, userInfoStore.isNational())} />
    </tr>
  );
};

export default RegionStatusRow;
