import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { ReactNode } from 'react';
import { FEATURE_OR_2477 } from '../../../../../../constants/global';
import useOfferEligibility from '../../../../../../hooks/useOfferEligibility';
import useUrlParams from '../../../../../../hooks/useUrlParams';
import LeaseCardModel from '../../../../models/LeaseCardModel';
import LeaseFormModel from '../../../../models/LeaseFormModel';

interface Props {
  children: ReactNode;
  leaseCard: LeaseCardModel;
  leaseForm: LeaseFormModel;
}

const { OPTION_TYPE_NAMES } = AdminConstants;

const LeaseDefaultTfsCostShare = ({ children, leaseCard, leaseForm }: Props) => {
  const { handleEnhCostShare } = useOfferEligibility();
  const { region } = useUrlParams();

  const processDefaultTfsCostShareValues = () => {
    const { enhancedCostShare, reason, isReplacing } = handleEnhCostShare({
      startDate: leaseForm.inputs.startDate,
      endDate: leaseForm.inputs.endDate,
      vehicles: leaseCard.includedVehicles,
      optionTypeName: OPTION_TYPE_NAMES.LEASE,
      regionCode: region,
      states: leaseCard.includedStates,
      tiers: leaseForm.inputs.rates.map(rate => rate.tier),
      terms: leaseCard.leaseTerms.map(term => term.term),
    });

    const isEligible = enhancedCostShare && (!reason || !isReplacing);
    leaseCard.setEnhancedCostShare(isEligible ? enhancedCostShare : undefined);
  };

  if (FEATURE_OR_2477) {
    processDefaultTfsCostShareValues();
  }

  return <>{children}</>;
};

export default observer(LeaseDefaultTfsCostShare);
