import { MonthShortNamesConstant } from 'oat-common-ui';

export const getDateParts = (date: string) => {
  const split = date.split('-');

  return {
    year: split[0],
    month: split[1],
    day: split[2],
  };
};

export const getMonthAndDate = (date: string) => {
  const { month, day } = getDateParts(date);
  return `${MonthShortNamesConstant[Number(month)]} ${day}`;
};

export default getDateParts;
