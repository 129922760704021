import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Button, formatDollars, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import {
  Offering,
  SaveMiscAdjustmentInput,
  SaveReconcileDetailsInput,
  SaveReconcileOfferInput,
  SaveReconcileSeriesInput,
  useSaveMiscAdjustmentMutation,
  useSaveReconcileDetailsMutation,
} from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';
import { assignNumberValue, assignStringValue, defaultToUndefined } from '../../../../../utils/assignValue';
import { InputType } from '../../../models/ReconcileDataModel';
import ReconcileSeriesModel from '../../../models/ReconcileSeriesModel';
import Series from './Series';
import styles from './styles.module.scss';

const Details = () => {
  const {
    userInfoStore: { isNational, isSETUser },
    reconcileStore: { reconcileData, offering, setOffering },
  } = useStores();
  const [saveReconcileDetails] = useSaveReconcileDetailsMutation();
  const { error } = useToast();
  const { series, fields, calculateEarningsOnlySP, calculateTotalsForSummaryTable, updateField } = reconcileData;
  const [saveMiscAdjustment] = useSaveMiscAdjustmentMutation();

  const handleSaveMiscAdjustment = async (srs: ReconcileSeriesModel, amount: InputType) => {
    try {
      const miscAdjInput: SaveMiscAdjustmentInput = {
        regionalOfferingId: offering.id,
        regionalOfferingRev: offering.rev,
        miscAdjustment: assignStringValue(amount),
        note: isNational() ? fields.nationalMiscAdjNote : fields.regionalMiscAdjNote,
        isNational: isNational(),
      };

      const resp = await trackPromise(saveMiscAdjustment({ variables: { input: miscAdjInput } }));
      setOffering(resp.data?.saveMiscAdjustment.offering as Offering);
      reconcileData.updateData(resp.data?.saveMiscAdjustment.offering as Offering);
      srs.calculateMiscAdjustmentActualValues(isNational(), assignNumberValue(amount));
      calculateEarningsOnlySP(isNational());
      calculateTotalsForSummaryTable();
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleSubmit = async (selectedSeries?: ReconcileSeriesModel) => {
    try {
      const update = selectedSeries ? [selectedSeries] : series;
      const saveReconcileDetailsInput = {} as SaveReconcileDetailsInput;
      const reconcileSeriesInputs: SaveReconcileSeriesInput[] = [];
      update.forEach(srs => {
        if (!srs.isAdjustment) {
          const reconcileOfferInputs: SaveReconcileOfferInput[] = [];
          srs.offers.forEach(ofr => {
            reconcileOfferInputs.push({
              offerId: ofr.offerId,
              optionTypeName: ofr.optionTypeName,
              tier: assignStringValue(ofr.tier),
              term: ofr.term,
              actualUnits: assignNumberValue(isNational() ? ofr.nationalActualUnits : ofr.regionalActualUnits),
              actualPenetration: assignNumberValue(isNational() ? ofr.nationalActualPenetration : ofr.regionalActualPenetration),
              actualCost: assignNumberValue(isNational() ? ofr.nationalActualCost : ofr.regionalActualCost),
              actualOfferCost: assignNumberValue(isNational() ? ofr.nationalActualOfferCost : ofr.regionalActualOfferCost),
              optionTierType: ofr.optionTierType,
            });
          });

          reconcileSeriesInputs.push({
            actualDealerStock: assignNumberValue(isNational() ? srs.fields.nationalActualDealerStock : srs.fields.regionalActualDealerStock),
            actualSalesVolume: assignNumberValue(isNational() ? srs.fields.nationalActualSalesVolume : srs.fields.regionalActualSalesVolume),
            rgnlAltId: srs.rgnlAltId,
            seriesProfileId: srs.seriesProfileId,
            reconcileOfferInputs,
          });
        }
      });
      saveReconcileDetailsInput.isNational = isNational();
      saveReconcileDetailsInput.regionalOfferingId = offering.id;
      saveReconcileDetailsInput.regionalOfferingRev = offering.rev;
      saveReconcileDetailsInput.reconcileSeriesInputs = reconcileSeriesInputs;

      const resp = await trackPromise(saveReconcileDetails({ variables: { input: saveReconcileDetailsInput } }));
      setOffering(resp.data?.saveReconcileDetails.offering as Offering);
      reconcileData.updateData(resp.data?.saveReconcileDetails.offering as Offering);
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <>
      <div className={styles.detailsTableContainer}>
        {!isNational() && (
          <div className={styles.recCtas}>
            <Button variant="primary" onClick={() => handleSubmit()}>
              Save All
            </Button>
            {!isSETUser() && (
              <Button variant="primary" onClick={() => reconcileData.copyAllNationalActuals(isNational())}>
                Copy All National Actuals
              </Button>
            )}
          </div>
        )}
        <ul className={styles.natReconcileList}>
          {series.map(srs =>
            !srs.isAdjustment ? (
              <Series srs={srs} onSubmit={handleSubmit} key={srs.uid} />
            ) : (
              <li className={clsx(styles.listItem, styles.miscAdjustments)} key={srs.uid}>
                <header className={styles.header1}>
                  <span className={styles.title1} />
                </header>
                <div className={clsx(styles.recCtaRow, styles.miscContainer)}>
                  <div className={styles.adjContainer}>
                    <label>{isNational() ? 'Misc' : 'National'} Adj.</label>
                    {isNational() ? (
                      <input defaultValue={fields.nationalMiscAdjAmount} onChange={e => updateField('nationalMiscAdjAmount', defaultToUndefined(e.target.value))} />
                    ) : (
                      <span>${formatDollars(fields.nationalMiscAdjAmount)}</span>
                    )}
                  </div>
                  <div className={styles.adjContainer}>
                    {isNational() ? (
                      <>
                        <label>Note *</label>
                        <input className={styles.notesInput} defaultValue={fields.nationalMiscAdjNote} onChange={e => updateField('nationalMiscAdjNote', e.target.value)} />
                      </>
                    ) : (
                      <>
                        <label>Note</label>
                        <span>{fields.nationalMiscAdjNote}</span>
                      </>
                    )}
                  </div>
                  {isNational() && (
                    <div className={styles.recCtas}>
                      <Button variant="primary" onClick={() => handleSaveMiscAdjustment(srs, defaultToUndefined(fields.nationalMiscAdjAmount))}>
                        Save
                      </Button>
                    </div>
                  )}
                </div>
                {!isNational() && (
                  <div className={clsx(styles.recCtaRow, styles.miscContainer)}>
                    <div className={styles.adjContainer}>
                      <label>Regional Adj.</label>
                      <input defaultValue={fields.regionalMiscAdjAmount} onChange={e => updateField('regionalMiscAdjAmount', defaultToUndefined(e.target.value))} />
                    </div>
                    <div className={styles.adjContainer}>
                      <label>Note *</label>
                      <input className={styles.notesInput} defaultValue={fields.regionalMiscAdjNote} onChange={e => updateField('regionalMiscAdjNote', e.target.value)} />
                    </div>
                    <div className={styles.recCtas}>
                      <Button variant="primary" onClick={() => handleSaveMiscAdjustment(srs, defaultToUndefined(fields.regionalMiscAdjAmount))}>
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </li>
            ),
          )}
        </ul>
      </div>
    </>
  );
};

export default observer(Details);
