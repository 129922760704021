import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import useOfferEligibility from '../../../../../../../../../hooks/useOfferEligibility';
import useUrlParams from '../../../../../../../../../hooks/useUrlParams';
import useStores from '../../../../../../../../../stores/useStores';
import { buildEnhCostShareCheckPayload } from '../../../../../../../../createOffer/CreateApr/enhCostShareUtils';
import AprCardModel from '../../../../../../../models/AprCardModel';

interface Props {
  children: ReactNode;
  aprCard: AprCardModel;
}

const AprDefaultTfsCostShare = ({ children, aprCard }: Props) => {
  const {
    userInfoStore: { isGst, isSETUser, isLexus },
    programDetailsStore: { getEnhancedCostShareById },
  } = useStores();

  const { handleEnhCostShare } = useOfferEligibility();
  const { region } = useUrlParams();
  const userInfo = { isGst: isGst(), isSet: isSETUser(), isLexus: isLexus() };

  const { checkPayload, existingOfferPayload } = buildEnhCostShareCheckPayload(aprCard, region, userInfo);
  const { reason, enhancedCostShare, isReplacing } = handleEnhCostShare(checkPayload, existingOfferPayload);

  const processDefaultTfsCostShareValues = () => {
    const terms = aprCard.selectedTierModel?.terms;
    const foundEnhancedCostShare = getEnhancedCostShareById(aprCard.enhCostShareOfferId) ?? enhancedCostShare;

    if (terms) {
      for (const term of terms) {
        const value = foundEnhancedCostShare?.tierTerms?.find(
          tierTerm => tierTerm?.highTerm === term.fields.term && tierTerm?.tier === aprCard.selectedTierModel?.tier && !!tierTerm.tfsCostShare,
        );

        term.updateField('tfsCostShare', !reason || !isReplacing ? value?.tfsCostShare ?? undefined : undefined);
        aprCard.updateField('subventionCashTfsCostShare', !reason || !isReplacing ? foundEnhancedCostShare?.subventionCashTfsCostShare ?? undefined : undefined);
        aprCard.updateField('subventionCashTfsCostShareCap', !reason || !isReplacing ? foundEnhancedCostShare?.subventionCashTfsCostShareCap ?? undefined : undefined);
      }
    }
  };

  processDefaultTfsCostShareValues();

  return <>{children}</>;
};

export default observer(AprDefaultTfsCostShare);
