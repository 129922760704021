import { createContext, useContext } from 'react';
import CompatibileAccessoriesStore from './CompatibleAccessoriesStore';
import CreateLeaseStore from './CreateLeaseStore';
import CreateOfferStore from './CreateOfferStore';
import DD365Store from './DD365Store';
import DashboardStore from './DashboardStore';
import EFCStore from './EFCStore';
import OfferingCostsStore from './OfferingCostsStore';
import OfferingsStore from './OfferingsStore';
import ProgramDetailsStore from './ProgramDetailsStore';
import RcfsResidualsStore from './RcfsResidualsStore';
import ReconcileStore from './ReconcileStore';
import RegionalCostShareStore from './RegionalCostShareStore';
import RegionalStatusStore from './RegionalStatusStore';
import RegionsStore from './RegionsStore';
import ReportsStore from './ReportsStore';
import SeriesMappingStore from './SeriesMappingStore';
import SeriesSortingStore from './SeriesSortingStore';
import SettingsStore from './SettingsStore';
import SummaryStore from './SummaryStore';
import UserInfoStore from './UserInfoStore';

const createStores = () => {
  return {
    createLeaseStore: new CreateLeaseStore(),
    createOfferStore: new CreateOfferStore(),
    compatibleAccessoriesStore: new CompatibileAccessoriesStore(),
    dashboardStore: new DashboardStore(),
    dd365Store: new DD365Store(),
    efcStore: new EFCStore(),
    summaryStore: new SummaryStore(),
    offeringsStore: new OfferingsStore(),
    offeringCostsStore: new OfferingCostsStore(),
    rcfsResidualsStore: new RcfsResidualsStore(),
    reconcileStore: new ReconcileStore(),
    regionsStore: new RegionsStore(),
    reportsStore: new ReportsStore(),
    programDetailsStore: new ProgramDetailsStore(),
    settingsStore: new SettingsStore(),
    seriesMappingStore: new SeriesMappingStore(),
    seriesSortingStore: new SeriesSortingStore(),
    regionalStatusStore: new RegionalStatusStore(),
    regionalCostShareStore: new RegionalCostShareStore(),
    userInfoStore: new UserInfoStore(),
  };
};

export const stores = createStores();
export type StoresType = typeof stores;

const storesContext = createContext(stores);

const useStores = () => useContext(storesContext);

export default useStores;
