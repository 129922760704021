import { observer } from 'mobx-react-lite';
import { OPTION_TYPE_NAMES } from 'oat-admin-common/dist/types/constants';
import { ReactNode } from 'react';
import { FEATURE_OR_2477 } from '../../../../../constants/global';
import useOfferEligibility from '../../../../../hooks/useOfferEligibility';
import useUrlParams from '../../../../../hooks/useUrlParams';
import MiscCardModel from '../../../models/MiscCardModel';

interface Props {
  children: ReactNode;
  miscCard: MiscCardModel;
}

const MiscDefaultTfsCostShare = ({ children, miscCard }: Props) => {
  const { handleEnhCostShare } = useOfferEligibility();
  const { region } = useUrlParams();

  const processDefaultTfsCostShareValues = () => {
    const { enhancedCostShare, reason, isReplacing } = handleEnhCostShare({
      startDate: miscCard.fields.startDate,
      endDate: miscCard.fields.endDate,
      vehicles: miscCard.fields.includedVehicles,
      optionTypeName: miscCard.fields.type as OPTION_TYPE_NAMES,
      regionCode: region,
      states: miscCard.fields.includedStates,
      tfsContractRequired: miscCard.fields.tfsContractRequired,
      tfsContracts: miscCard.fields.tfsContracts,
    });

    const isEligible = enhancedCostShare && (!reason || !isReplacing);
    miscCard.setEnhancedCostShare(isEligible ? enhancedCostShare : undefined);
  };

  if (FEATURE_OR_2477) {
    processDefaultTfsCostShareValues();
  }

  return <>{children}</>;
};

export default observer(MiscDefaultTfsCostShare);
