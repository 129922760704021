import { makeAutoObservable } from 'mobx';
import { AdminConstants, LeaseCalcFns } from 'oat-admin-common';
import { AdvanceRates, LeaseDetails, LeaseExample, LeaseRates, Maybe, Offer, VehicleInput } from '../../../gql/generated';
import { assignNumberValue, assignStringValue } from '../../../utils/assignValue';
import { convertPercentage } from '../../../utils/convertPercentage';
import Rate from '../components/LeaseCard/models/Rate';
import LeaseFormModel from './LeaseFormModel';
import ResidualValue from './ResidualValue';
import { parseRcfsFromRates } from './leaseUtils';

const { DEFAULT_DOWN_PAYMENT, TOYOTA_AC_FEE } = AdminConstants;

// Moved this out of the LeaseFormInput object. Values would not update if LeaseFormInput is recalling setDetails (reset to national)
export const setLeaseFormInputs = (leaseForm: LeaseFormModel, regionalOffer: Offer, regionalDetails: LeaseDetails, residuals?: ResidualValue[]) => {
  const { subventedRcf, standardRcf } = parseRcfsFromRates(regionalDetails.rates, regionalOffer.optionTierType === 'ByTier');
  const { subventedRcf: enhSubventedRcf } = parseRcfsFromRates(regionalDetails.tfsEnhancedRates ?? [], regionalOffer.optionTierType === 'ByTier');
  leaseForm.inputs.term = regionalDetails.highTerm || 36;
  leaseForm.inputs.tier = regionalDetails.rates.length ? regionalDetails.rates[0].tier : '1+';
  leaseForm.inputs.betterThanPrior = regionalDetails.betterThanPrior;
  leaseForm.inputs.endDate = regionalDetails.endDate;
  leaseForm.inputs.highTerm = regionalDetails.highTerm;
  leaseForm.inputs.isAddtlCashAppliedToDueAtSigning = regionalDetails.isAddtlCashAppliedToDueAtSigning;
  leaseForm.inputs.isAdvertised = regionalDetails.isAdvertised;
  leaseForm.inputs.isBadVin = regionalDetails.isBadVin;
  leaseForm.inputs.isEnhanced = regionalDetails.isEnhanced;
  leaseForm.inputs.isExample = regionalDetails.isExample;
  leaseForm.inputs.isLeaseRecalculated = regionalDetails.isLeaseRecalculated;
  leaseForm.inputs.isNcsApplied = regionalDetails.isNcsApplied;
  leaseForm.inputs.isStandalone = regionalOffer.isStandalone;
  leaseForm.inputs.isStandardRateRcfChanged = regionalDetails.isStandardRateRcfChanged;
  leaseForm.inputs.isSubCashAdvertised = regionalDetails.isSubCashAdvertised;
  leaseForm.inputs.isTfsEnhRcf = regionalDetails.isTfsEnhRcf;
  leaseForm.inputs.isUpToApplied = regionalDetails.isUpToApplied;
  leaseForm.inputs.isVinAutoSelected = regionalDetails.isVinAutoSelected;
  leaseForm.inputs.isVinCopiedOver = regionalDetails.isVinCopiedOver;
  leaseForm.inputs.isVinStopSale = regionalDetails.isVinStopSale;
  leaseForm.inputs.isZeroDueAtSigning = regionalDetails.isZeroDueAtSigning;

  leaseForm.inputs.lowTerm = regionalDetails.lowTerm;
  leaseForm.inputs.modelNote = regionalDetails.modelNote;
  leaseForm.inputs.note = regionalDetails.note;
  leaseForm.inputs.penetration = regionalDetails.penetration;
  leaseForm.inputs.forecastedSales = assignNumberValue(regionalDetails.forecastedSales);
  leaseForm.inputs.rates = regionalDetails.rates;
  leaseForm.inputs.tfsEnhancedRates = regionalDetails.tfsEnhancedRates;
  leaseForm.inputs.advanceRates = regionalDetails.advanceRates;
  leaseForm.inputs.rcf = regionalDetails.areTfsEnhRatesUsed ? enhSubventedRcf : subventedRcf;
  leaseForm.inputs.standardRegionalRcf = standardRcf;
  leaseForm.inputs.enhancedStdRcf = parseRcfsFromRates(regionalDetails.tfsEnhancedRates ?? [], regionalOffer.optionTierType === 'ByTier').standardRcf;
  leaseForm.inputs.startDate = regionalDetails.startDate;
  leaseForm.inputs.tdaNote = regionalDetails.tdaNote;
  leaseForm.inputs.tfsShare = regionalDetails.tfsShare;
  leaseForm.inputs.vehicles = regionalOffer.vehicles;
  leaseForm.inputs.worseThanPrior = regionalDetails.worseThanPrior;
  leaseForm.inputs.additionalCashOfferId = assignStringValue(regionalDetails.additionalCashOfferId);
  leaseForm.inputs.additionalCashOptionType = assignStringValue(regionalDetails.additionalCashOptionType) || 'None';
  leaseForm.inputs.additionalDefaultAmount = 0; // this will change to correct amount as user types
  leaseForm.inputs.configuration = assignStringValue(regionalDetails.configuration);
  leaseForm.inputs.enhancedResidualAmount = assignStringValue(regionalDetails.enhancedResidualAmount?.toString());
  leaseForm.inputs.enhancedResidualRate = assignStringValue(regionalDetails.enhancedResidualRate?.toString());
  leaseForm.inputs.vehicleDescription = assignStringValue(regionalDetails.vehicleDescription);
  leaseForm.inputs.vin = assignStringValue(regionalDetails.vin);
  leaseForm.inputs.distribution = assignStringValue(regionalDetails.distribution?.toString());
  leaseForm.inputs.port = assignStringValue(regionalDetails.port);
  leaseForm.inputs.series = assignStringValue(regionalDetails.series);
  leaseForm.inputs.adjustmentFactor = assignStringValue(regionalDetails.adjustmentFactor?.toString(), '10');
  leaseForm.inputs.contestNumberOfferId = assignStringValue(regionalOffer.contestNumberOfferId);
  leaseForm.inputs.contestNumber = assignStringValue(regionalOffer.contestNumber, '0');

  leaseForm.inputs.acquisitionFee = assignNumberValue(regionalDetails.acquisitionFee);
  leaseForm.inputs.additionalCashAmount = assignStringValue(regionalDetails.additionalCash, '0');
  leaseForm.inputs.baseMsrp = assignNumberValue(regionalDetails.baseMsrp);
  leaseForm.inputs.bonusCash = assignNumberValue(regionalDetails.bonusCash);
  leaseForm.inputs.dealerCostPerInvoice = assignNumberValue(Math.round(regionalDetails.dealerCostPerInvoice || 0));
  leaseForm.inputs.dealerGross = assignNumberValue(regionalDetails.dealerGross);
  leaseForm.inputs.downPayment = assignNumberValue(regionalDetails.downPayment);
  leaseForm.inputs.dueAtSigning = assignNumberValue(regionalDetails.dueAtSigning);
  leaseForm.inputs.estimatedCost = assignNumberValue(regionalDetails.estimatedCost, 0);
  leaseForm.inputs.subCashEstCost = assignNumberValue(regionalDetails.subCashEstCost, 0);
  leaseForm.inputs.subCashTfsEstCost = assignNumberValue(regionalDetails.subCashTfsEstCost, 0);
  leaseForm.inputs.subCashEnhTfsEstCost = assignNumberValue(regionalDetails.subCashEnhTfsEstCost, 0);
  leaseForm.inputs.tfsEstCost = assignNumberValue(regionalDetails.tfsCost, 0);
  leaseForm.inputs.enhTfsEstCost = assignNumberValue(regionalDetails.enhTfsCost, 0);
  leaseForm.inputs.financialReserve = assignNumberValue(regionalDetails.financialReserve);
  leaseForm.inputs.grossCapCost = assignNumberValue(regionalDetails.grossCapCost);
  leaseForm.inputs.holdback = assignNumberValue(regionalDetails.holdback);
  leaseForm.inputs.modelCode = assignNumberValue(regionalDetails.modelCode);
  leaseForm.inputs.modelYear = assignNumberValue(regionalDetails.modelYear);
  leaseForm.inputs.netCapCost = assignNumberValue(regionalDetails.netCapCost);

  leaseForm.inputs.residualAmount = assignNumberValue(regionalDetails.residualAmount);
  leaseForm.inputs.residualRate = convertPercentage(regionalDetails.residualRate || 0, true);
  leaseForm.inputs.residualRateDefault = leaseForm.inputs.residualRate;
  leaseForm.inputs.residualAmountDefault = leaseForm.inputs.residualAmount;

  if (residuals) {
    const found = residuals.find(
      item => Number(item.modelCode) === leaseForm.inputs.modelCode && item.seriesYear === leaseForm.inputs.modelYear && item.term === leaseForm.inputs.term,
    );
    if (found) {
      leaseForm.inputs.residualRateDefault = found.rv;
      if (found.rv !== leaseForm.inputs.residualRate) {
        leaseForm.inputs.residualAmountDefault = Math.round(leaseForm.inputs.totalMsrp * convertPercentage(found.rv));
      }
    }
  }

  leaseForm.inputs.subventionCash = assignStringValue(regionalDetails.subventionCash, '0');
  leaseForm.inputs.targetPayment = assignNumberValue(regionalDetails.targetPayment);
  leaseForm.inputs.tdaFee = assignStringValue(regionalDetails.tdaFee, '0');
  leaseForm.inputs.tfsCostShareCap = assignNumberValue(regionalDetails.tfsCostShareCap);
  leaseForm.inputs.totalDealerGross = assignNumberValue(regionalDetails.totalDealerGross);
  leaseForm.inputs.totalMsrp = assignNumberValue(regionalDetails.totalMsrp);
  leaseForm.inputs.financeReserve = Number(regionalDetails.financialReserve);
  leaseForm.inputs.dap = Math.round(LeaseCalcFns.calculateDAP(leaseForm.inputs.baseMsrp));
  leaseForm.inputs.mileage = assignStringValue(regionalDetails.mileage, leaseForm.defaultMileage.toString());
  leaseForm.inputs.subCashTfsShare = assignNumberValue(regionalDetails.subCashTfsShare);
  leaseForm.inputs.subCashTfsCostShareCap = assignNumberValue(regionalDetails.subCashTfsCostShareCap);

  leaseForm.inputs.isCapitalized = regionalDetails.isCapitalized || false;
  leaseForm.inputs.isTransferable = regionalOffer.isTransferable || false;
};

export const setLeaseExampleInputs = (leaseForm: LeaseFormModel, example: LeaseExample) => {
  leaseForm.inputs.acquisitionFee = assignNumberValue(example.acquisitionFee);
  leaseForm.inputs.baseMsrp = assignNumberValue(example.baseMsrp);
  leaseForm.inputs.configuration = assignStringValue(example.configuration);
  leaseForm.inputs.dealerCostPerInvoice = assignNumberValue(Math.round(example.dealerCostPerInvoice || 0));
  leaseForm.inputs.dealerGross = assignNumberValue(example.dealerGross);
  leaseForm.inputs.downPayment = assignNumberValue(example.downPayment);
  leaseForm.inputs.dueAtSigning = assignNumberValue(example.dueAtSigning);
  leaseForm.inputs.enhancedResidualAmount = assignStringValue(example.enhancedResidualAmount?.toString());
  leaseForm.inputs.enhancedResidualRate = assignStringValue(example.enhancedResidualRate?.toString(), '0');
  leaseForm.inputs.financialReserve = assignNumberValue(example.financialReserve);
  leaseForm.inputs.grossCapCost = assignNumberValue(example.grossCapCost);
  leaseForm.inputs.holdback = assignNumberValue(example.holdback);
  leaseForm.inputs.isBadVin = Boolean(example.isBadVin);
  leaseForm.inputs.isAcquisitionFeeCapitalized = Boolean(example.isCapitalized);
  leaseForm.inputs.isZeroDueAtSigning = Boolean(example.isZeroDueAtSigning);
  leaseForm.inputs.mileage = assignStringValue(example.mileage, leaseForm.defaultMileage.toString());
  leaseForm.inputs.modelCode = assignNumberValue(example.modelCode);
  leaseForm.inputs.modelYear = assignNumberValue(example.modelYear);
  leaseForm.inputs.name = assignStringValue(example.name);
  leaseForm.inputs.residualAmount = assignNumberValue(example.residualAmount);
  leaseForm.inputs.residualRate = convertPercentage(example.residualRate || 0, true);
  leaseForm.inputs.series = assignStringValue(example.series);
  leaseForm.inputs.targetPayment = assignNumberValue(example.targetPayment);
  leaseForm.inputs.tdaFee = assignStringValue(example.tdaFee, '0');
  leaseForm.inputs.totalDealerGross = assignNumberValue(example.totalDealerGross);
  leaseForm.inputs.totalMsrp = assignNumberValue(example.totalMsrp);
  leaseForm.inputs.vehicleDescription = assignStringValue(example.vehicleDescription);
  leaseForm.inputs.vin = assignStringValue(example.vin);
  leaseForm.lock = 'targetPayment';
  leaseForm.isExample = true;
  leaseForm.inputs.isUpToApplied = false;
};

class LeaseFormInputs {
  additionalCashOfferId = '';
  additionalCashOptionType = 'None';
  additionalCashAmount = '0';
  additionalDefaultAmount = 0;
  defaultStartDate = '';
  defaultEndDate = '';
  startDate = '';
  endDate = '';
  modelNote = '';
  configuration = '';
  note = '';
  tdaNote = '';
  port = '';
  nationalPort = '';
  series = '';
  tier = '';
  vehicleDescription = '';
  vin = '';
  name = '';

  dap = 0;
  dph = 0;
  holdback = 0;
  financeReserve = 0;
  acquisitionFee = TOYOTA_AC_FEE;
  defaultDealerCostPerInvoice = 0;
  dealerCostPerInvoice = 0;
  residualAmount = 0;
  residualAmountDefault = 0;
  totalDealerGross = 0;
  downPayment: number = DEFAULT_DOWN_PAYMENT;
  grossCapCost = 0;
  netCapCost = 0;
  netCapSubCash = 0;
  netCapAcquisitionFee = 0;
  dasSubCash = 0;
  dasAcquisitionFee = 0;
  dealerGrossForZeroDueAtSigning = 0;
  baseMsrp = 0;
  bonusCash = 0;
  financialReserve = 0;
  highTerm = 0;
  term = 36;
  lowTerm = 24;
  nationalTerm = 0;
  modelCode = 0;
  modelYear = 0;
  penetration = 0;
  forecastedSales = 0;
  defaultTdaFee = 0;
  tdaFee = '0';
  defaultTotalMsrp = 0;
  totalMsrp = 0;
  targetPayment: string | number = '0';
  targetPaymentDefault = 0; // represents standalone or national targetpayment
  dueAtSigningDefault = 0; // represents standalone or national due at signing
  subventionCash: string | number = '0';
  nationalSubventionCash = 0;
  subCashAdvertised = false;
  dueAtSigning: string | number = '0';
  dealerGross: string | number = '0';
  dealerGrossDefault = 0;
  rcf: string | number = '0.00001';
  nationalRcf: string | number = '0.00001';
  standardRcf = 0.00001; // non-editable
  standardRegionalRcf: string | number = '0.00001'; // editable if standalone
  standardNationalRcf = 0.00001; // non-editable
  enhancedStdRcf = 0.00001; // non-editable
  mileage = '';
  nationalMileage = '';
  adjustmentFactor: string | number = '0.1';
  tfsShare: string | number = '0.2';
  contestNumberOfferId: string | null = '';
  distribution: string | number = '';
  enhancedResidualAmount: string | number = 0;
  tfsCostShareCap: string | number = '';
  contestNumber: string | number = 0;
  residualRateDefault = 0;
  residualRate: string | number = 0;
  enhancedResidualRate: string | number = 0;
  estimatedCost = 0;
  tfsEstCost = 0;
  enhTfsEstCost = 0;
  subCashEstCost = 0;
  subCashTfsEstCost = 0;
  subCashEnhTfsEstCost = 0;
  subCashTfsCostShareCap = 0;
  subCashTfsShare = 0;

  isAdvertised = true;
  isStandalone = true;
  isUpToApplied = true;
  isAcquisitionFeeCapitalized = false;
  isSubCashAppliedToDueAtSigning = false;
  betterThanPrior = false;
  isAddtlCashAppliedToDueAtSigning = false;
  isBadVin = false;
  isCapitalized = false;
  isEnhanced = false;
  isExample = false;
  isLeaseRecalculated = false;
  isNcsApplied = false;
  isStandardRateRcfChanged = false;
  isSubCashAdvertised = false;
  isTfsEnhRcf = false;
  isTransferable = false;
  isVinAutoSelected = false;
  isVinCopiedOver = false;
  isVinStopSale = false;
  isZeroDueAtSigning = false;
  worseThanPrior = false;
  rates: Rate[] = [];
  tfsEnhancedRates: Maybe<LeaseRates[]> | undefined = [];
  advanceRates: null | undefined | Maybe<AdvanceRates>[] = [];
  vehicles: VehicleInput[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setNationalDetails = (nationalDetails: LeaseDetails, isByTier = false, defaulMileage: number) => {
    const { subventedRcf, standardRcf } = parseRcfsFromRates(nationalDetails.rates, isByTier);
    this.nationalRcf = subventedRcf;
    this.standardNationalRcf = standardRcf;
    this.nationalMileage = assignStringValue(nationalDetails.mileage, defaulMileage.toString());
    this.nationalSubventionCash = assignNumberValue(nationalDetails.subventionCash);
    this.nationalTerm = assignNumberValue(nationalDetails.highTerm);
    this.dealerGross = assignNumberValue(nationalDetails.dealerGross);
    this.financeReserve = assignNumberValue(nationalDetails.financialReserve);
    this.holdback = assignNumberValue(nationalDetails.holdback);
    this.baseMsrp = assignNumberValue(nationalDetails.baseMsrp);
    this.dap = Math.round(LeaseCalcFns.calculateDAP(this.baseMsrp));
    this.totalDealerGross = assignNumberValue(nationalDetails.totalDealerGross);
    this.nationalPort = assignStringValue(nationalDetails.port);
  };
}

export default LeaseFormInputs;
