import { observer, useLocalObservable } from 'mobx-react-lite';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader, OfferTypes, dateFormatISOString, useToast } from 'oat-common-ui';
import React, { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import AccordionModel from '../../../../components/Accordion/models/AccordionModel';
import createAccordionFromVehicles from '../../../../components/Accordion/utils/createAccordionFromVehicles';
import mapVehicleDataFromAccordion from '../../../../components/Accordion/utils/mapVehicleDataFromAccordion';
import StartEndDate from '../../../../components/StartEndDate';
import { VehicleInput, useEnhanceAndRefineMutation } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import dateWithinDateRangeValidator from '../../../../utils/dateWithinDateRangeValidator';
import getDisabledVehicles from '../../../../utils/getDisabledVehicles';
import getInclExclLabel from '../../../../utils/getInclExclLabel';
import { createCashCard } from '../../../createOffer/utils/createCashCard';
import { createMiscCard } from '../../../createOffer/utils/createMiscCard';
import CashCardModel from '../../models/CashCardModel';
import MiscCardModel from '../../models/MiscCardModel';
import ExclusionInclusion from '../ExclusionInclusion';
import EnRErrorMsg from './EnRErrorMsg';
import styles from './styles.module.scss';

interface Props {
  id: string;
  onClose: () => void;
  rev: string;
  toggleIsInclusions: () => void;
  isInclusions: boolean;
  startDate: Date | undefined;
  endDate: Date | undefined;
  minDate: Date | undefined;
  maxDate: Date | undefined;
  openPenRateModal: React.Dispatch<React.SetStateAction<boolean>>;
  vehicles: VehicleInput[];
  setOfferId: React.Dispatch<React.SetStateAction<string>>;
  cashCard?: CashCardModel;
  miscCard?: MiscCardModel;
}

const EnhanceAndRefineModal = ({
  id,
  rev,
  onClose,
  isInclusions,
  toggleIsInclusions,
  startDate,
  endDate,
  minDate,
  maxDate,
  openPenRateModal,
  vehicles,
  setOfferId,
  cashCard,
  miscCard,
}: Props) => {
  const [startDateInput, setStartDateInput] = useState<Date | undefined>(startDate);
  const [endDateInput, setEndDateInput] = useState<Date | undefined>(endDate);
  const btnsLabel = getInclExclLabel(isInclusions);
  const [enhanceAndRefine] = useEnhanceAndRefineMutation();
  const { error } = useToast();
  const {
    programDetailsStore: { seriesProfile, offering, insertCashCard, insertMiscCard },
    seriesMappingStore: { seriesMapping },
  } = useStores();

  const nationalVehicles = cashCard?.nationalOffer?.vehicles || miscCard?.nationalOffer?.vehicles;
  const disabledVehicles = getDisabledVehicles(nationalVehicles || []);
  const accordionModel = useLocalObservable(() => new AccordionModel(createAccordionFromVehicles(vehicles, seriesMapping, !isInclusions, disabledVehicles)));

  const handleSelectAll = () => {
    accordionModel.toggleAll(true);
  };

  const handleRemoveAll = () => {
    accordionModel.toggleAll(false);
  };

  const handleSubmit = async () => {
    const stringStartDate = dateFormatISOString(startDateInput);
    const stringEndDate = dateFormatISOString(endDateInput);
    const [excludedList, includedList] = mapVehicleDataFromAccordion(accordionModel.items, vehicles, !isInclusions);

    const payload = {
      id,
      rev,
      startDate: stringStartDate,
      endDate: stringEndDate,
      vehicles: [...excludedList, ...includedList],
    };

    try {
      const res = await trackPromise(enhanceAndRefine({ variables: { input: payload } }));

      if (res.data?.enhanceAndRefine.success) {
        const offer = res.data.enhanceAndRefine.offer;
        setOfferId(offer.id);
        switch (offer.offerType) {
          case OfferTypes.CUSTOMER_CASH: {
            const card = createCashCard(
              new CashCardModel(),
              seriesMapping,
              { regional: offer, national: cashCard?.nationalOffer },
              seriesProfile,
              offering.startDate,
              offering.endDate,
            );
            insertCashCard(card, id);
            break;
          }
          case OfferTypes.MISCELLANEOUS: {
            const card = createMiscCard(
              new MiscCardModel(),
              seriesMapping,
              { regional: offer, national: miscCard?.nationalOffer },
              seriesProfile,
              offering.startDate,
              offering.endDate,
            );
            insertMiscCard(card, id);
            break;
          }
        }
        openPenRateModal(true);
      }
      onClose();
    } catch (e) {
      error((e as Error).message);
    }
  };

  const hasDateError = !dateWithinDateRangeValidator(startDateInput, minDate, maxDate) || !dateWithinDateRangeValidator(endDateInput, minDate, maxDate);
  const hasExclusionError = accordionModel.isAllSelected(!isInclusions);
  const hasError = hasDateError || hasExclusionError;

  return (
    <Modal onClose={onClose} isOpen>
      <ModalHeader title="Enhance & Refine" onClose={onClose} />
      <ModalBody className={styles.enhanceModalBody}>
        <StartEndDate
          dateWrapperClass={styles.enhanceDates}
          dateClass={styles.enhanceDate}
          startDate={startDateInput}
          endDate={endDateInput}
          minDate={minDate}
          maxDate={maxDate}
          setStartDate={setStartDateInput}
          setEndDate={setEndDateInput}
          isFullWidth
        />
        <ExclusionInclusion toggleIsInclusions={toggleIsInclusions} isInclusions={isInclusions} vehiclesAccordion={accordionModel} />
        <ButtonGroup>
          <Button id={`select-all-cta-${id}`} variant="text" className={styles.button} onClick={handleSelectAll}>{`Select All ${btnsLabel}`}</Button>
          <Button id={`remove-all-cta-${id}`} variant="text" className={styles.button} onClick={handleRemoveAll}>{`Remove All ${btnsLabel}`}</Button>
        </ButtonGroup>
        <EnRErrorMsg hasDateError={hasDateError} hasExclusionError={hasExclusionError} isInclusions={isInclusions} />
      </ModalBody>
      <ModalFooter className={styles.enhanceModalFooter}>
        <Button id="enhance-refine-modal-submit" variant="primary" onClick={handleSubmit} disabled={hasError}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default observer(EnhanceAndRefineModal);
